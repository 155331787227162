import React from 'react';
import styled, { css } from 'styled-components';
import { PanelExpand } from './PanelIcon';
import { RelativeTarget } from 'components/common/RelativeTarget';

export function PanelToggleButton({
  sidebarClosed,
  sidebarWidth,
  topOffset,
  handleButtonClick,
}: {
  sidebarClosed: boolean;
  sidebarWidth: number;
  topOffset: number;
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const buttonIconRef = React.useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <StyledSidebarPanelToggleButton
      className="panel-button-toggle"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={handleButtonClick}
      $sidebarClosed={sidebarClosed}
      $sidebarWidth={sidebarWidth}
      $topOffset={topOffset}
    >
      <span ref={buttonIconRef}>
        <PanelExpand />

        <RelativeTarget
          target={buttonIconRef}
          hAnchor="right"
          vAnchor="middle"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: 0,
          }}
        >
          {showTooltip && sidebarClosed && (
            <StyledPanelButtonTooltip className="panel-button-tooltip">
              Restore Sidebar
            </StyledPanelButtonTooltip>
          )}
        </RelativeTarget>
      </span>
    </StyledSidebarPanelToggleButton>
  );
}
const StyledSidebarPanelToggleButton = styled.button<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
  $topOffset: number;
}>`
  display: none;

  @media screen and (min-width: 1164px) {
    display: flex;
    justify-content: center;
    left: calc(${({ $sidebarWidth }) => $sidebarWidth}px - 36px / 2);
    position: fixed;
    top: ${({ $topOffset }) => $topOffset}px;
    right: auto;
    ${({ $sidebarClosed, $topOffset }) =>
      $sidebarClosed &&
      css`
        left: 42px;
        height: calc(100vh - 144px * 2);
        position: fixed;
        top: ${$topOffset}px;
        &:after {
          content: '';
          height: 96px;
          width: 1px;
          background: linear-gradient(
            to bottom,
            var(--COLOR_VERY_LIGHT_PINK),
            #c8c8c8 49%,
            var(--COLOR_VERY_LIGHT_PINK)
          );
          position: absolute;
          top: -25px;
          z-index: -1;
          left: 18px;
        }
      `}
  }
  span {
    box-sizing: border-box;
    padding: 11px 7px 10px 7px;
    border-radius: 18px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e4e4e4;
    background-color: white;
    width: 36px;
    height: 36px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: ${({ $sidebarClosed }) =>
      $sidebarClosed ? 'scaleX(-1)' : 'none'};
  }
  i {
    position: relative;
    box-sizing: border-box;
    font-size: 15px;
    color: var(--COLOR_BLUEY_GRAY);
    + i {
      margin-left: 4px;
    }
  }
  &:focus {
    outline: none;
    span {
      outline: 1px solid
        color-mix(in srgb, var(--COLOR_NICE_BLUE) 5%, transparent);
      box-shadow: 0 2px 12px 0
        color-mix(in srgb, var(--COLOR_NICE_BLUE) 5%, rgba(0, 0, 0, 0.05));
    }
  }
`;
const StyledPanelButtonTooltip = styled.div`
  background-color: white;
  border: solid 1px var(--COLOR_MERCURY);
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  color: var(--COLOR_COOL_GRAY);
  font-family: var(--FONT_SANS_SERIF);
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  margin-left: 5px;
  padding: 11px 9px 9px 16px;
  position: relative;
  &:before {
    content: '';
    border: 5px solid transparent;
    border-right-color: white;
    display: block;
    left: -10px;
    position: absolute;
    top: calc(50% - 5px);
  }
`;
