import styled from 'styled-components';
import { TrackedLink, TrackedLinkProps } from 'components/common/TrackedLink';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export interface StyledCourseButtonProps extends Omit<TrackedLinkProps, 'to'> {
  background?: string;
  fontSize?: number | string;
}

export const StyledCourseButton = styled(TrackedLink)<StyledCourseButtonProps>`
  background: ${({ background }) => background || CEB_COLOR('NICE_BLUE')};
  border-radius: 3px;
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-weight: normal;
  font-size: ${({ fontSize = 16 }) => fontSize}px;
  padding: 10px 20px;
  &,
  &:visited {
    color: ${({ color }) => color || 'white'};
  }
  &[disabled] {
    cursor: default;
  }
  i {
    margin-right: 0.5em;
    vertical-align: -1px;
  }
`;
