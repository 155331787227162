/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Banking account number is a string of 5 to 17 alphanumeric values for representing an generic account number */
  AccountNumber: { input: any; output: any };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: { input: any; output: any };
  /** Accepts 1, 2, 3 or Null */
  ComplianceGroupOverrideId: { input: any; output: any };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  ContentfulDateTime: { input: any; output: any };
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  ContentfulDimension: { input: any; output: any };
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  ContentfulHexColor: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  ContentfulJSON: { input: any; output: any };
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  ContentfulQuality: { input: any; output: any };
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: any; output: any };
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: { input: any; output: any };
  /** A field whose value conforms to the standard DID format as specified in did-core: https://www.w3.org/TR/did-core/. */
  DID: { input: any; output: any };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any };
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: { input: any; output: any };
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: { input: any; output: any };
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: { input: any; output: any };
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: { input: any; output: any };
  /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
  Hexadecimal: { input: any; output: any };
  /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
  IBAN: { input: any; output: any };
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: { input: any; output: any };
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: { input: any; output: any };
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: { input: any; output: any };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: { input: any; output: any };
  /** Date string in standard iso-8601 format (YYYY-MM-DD) */
  IsoDate: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: any; output: any };
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: any; output: any };
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: any; output: any };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day.  This is useful when using the scalar to represent the exclusive upper bound of a time block. */
  LocalEndTime: { input: any; output: any };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: { input: any; output: any };
  /** The locale in the format of a BCP 47 (RFC 5646) standard string */
  Locale: { input: any; output: any };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: { input: any; output: any };
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: any; output: any };
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: { input: any; output: any };
  MatterServiceDate: { input: any; output: any };
  MatterServiceJsonb: { input: any; output: any };
  MatterServiceTimestamptz: { input: any; output: any };
  MatterServiceUuid: { input: any; output: any };
  /** Floats that will have a value less than 0. */
  NegativeFloat: { input: any; output: any };
  /** Integers that will have a value less than 0. */
  NegativeInt: { input: any; output: any };
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: any; output: any };
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: any; output: any };
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: { input: any; output: any };
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: { input: any; output: any };
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: any; output: any };
  /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
  Port: { input: any; output: any };
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: any; output: any };
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: any; output: any };
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: any; output: any };
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: { input: any; output: any };
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: { input: any; output: any };
  /** In the US, an ABA routing transit number (`ABA RTN`) is a nine-digit code to identify the financial institution. */
  RoutingNumber: { input: any; output: any };
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: any; output: any };
  SecondarySourcesBigint: { input: any; output: any };
  SecondarySourcesJson: { input: any; output: any };
  SecondarySourcesTimestamptz: { input: any; output: any };
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: any; output: any };
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: { input: any; output: any };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any };
  /** ENUM including True Docs and Forms Subtypes */
  TrueFormSubtype: { input: any; output: any };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any };
  /** A currency string, such as $21.25 */
  USCurrency: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any };
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: { input: any; output: any };
  /** ENUM including pickable primary/secondary specialties for users */
  UserSpecialty: { input: any; output: any };
  /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  UtcOffset: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
  /** Four digit INTEGER representing a year */
  Year: { input: any; output: any };
};

export type Address = {
  __typename?: 'Address';
  addressType: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countryId: Maybe<Scalars['ID']['output']>;
  /** Fusebill customer ID */
  customerAddressPreferenceId: Maybe<Scalars['ID']['output']>;
  /** Address ID */
  id: Maybe<Scalars['ID']['output']>;
  line1: Maybe<Scalars['String']['output']>;
  line2: Maybe<Scalars['String']['output']>;
  postalZip: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  stateId: Maybe<Scalars['ID']['output']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  billingAddress: Maybe<Address>;
  contactName: Maybe<Scalars['String']['output']>;
  /** Fusebill customer ID */
  id: Maybe<Scalars['ID']['output']>;
  shippingAddress: Maybe<Address>;
  shippingInstructions: Maybe<Scalars['String']['output']>;
  uri: Maybe<Scalars['String']['output']>;
  useBillingAddressAsShippingAddress: Maybe<Scalars['Boolean']['output']>;
};

export type AddressInfoInput = {
  billingAddress: InputMaybe<AddressInput>;
  contactName: InputMaybe<Scalars['String']['input']>;
  shippingAddress: InputMaybe<AddressInput>;
  shippingInstructions: InputMaybe<Scalars['String']['input']>;
  useBillingAddressAsShippingAddress: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  companyName: InputMaybe<Scalars['String']['input']>;
  /** An abbreviated country code, e.g. US, USA, CA */
  countryCode: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2: InputMaybe<Scalars['String']['input']>;
  postalZip: Scalars['String']['input'];
  /** An abbreviated state/provide code, e.g. CA (for California), BC (for British Columbia) */
  stateCode: Scalars['String']['input'];
};

export type Asset = {
  __typename?: 'Asset';
  accountId: Maybe<Scalars['String']['output']>;
  installDate: Maybe<Scalars['String']['output']>;
  isFreeTrial: Maybe<Scalars['Boolean']['output']>;
  onLawId: Maybe<Scalars['String']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  purchaseDate: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Float']['output']>;
  usageEndDate: Maybe<Scalars['String']['output']>;
};

export type Author = {
  __typename?: 'Author';
  contentfulId: Maybe<Scalars['ID']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  headshotUrl: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  shortBio: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  webDisplayName: Maybe<Scalars['String']['output']>;
};

export type BarNumberCheckResult = {
  __typename?: 'BarNumberCheckResult';
  barNumberExists: Scalars['Boolean']['output'];
  isMatch: Scalars['Boolean']['output'];
  recordFirstName: Maybe<Scalars['String']['output']>;
  recordLastName: Maybe<Scalars['String']['output']>;
};

export type Bookmark = {
  __typename?: 'Bookmark';
  createdAt: Maybe<Scalars['String']['output']>;
  folderId: Maybe<Scalars['ID']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  path: Maybe<Scalars['String']['output']>;
  subtype: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['JSONObject']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<HistoryType>;
};

export type BookmarkFolder = {
  __typename?: 'BookmarkFolder';
  bookmarks: Maybe<Array<Maybe<Bookmark>>>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type CleComplianceDeadline = {
  __typename?: 'CLEComplianceDeadline';
  complianceGroupId: Maybe<Scalars['Int']['output']>;
  periodEndDate: Maybe<Scalars['String']['output']>;
  periodStartDate: Maybe<Scalars['String']['output']>;
  reportingDate: Maybe<Scalars['String']['output']>;
};

export type CleContributor = {
  __typename?: 'CLEContributor';
  bio: Maybe<Scalars['JSONObject']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contributorOrganization: Maybe<CleContributorOrganization>;
  counties: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  courseItemsCount: Maybe<Scalars['Int']['output']>;
  currentAwarenessItemsCount: Maybe<Scalars['Int']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  headshotUrl: Maybe<Scalars['String']['output']>;
  judge: Maybe<Scalars['String']['output']>;
  knowHowDocumentItemsCount: Maybe<Scalars['Int']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedInUrl: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  publicationContributionBooks: Maybe<
    Array<Maybe<PublicationContributionBook>>
  >;
  publicationItemsCount: Maybe<Scalars['Int']['output']>;
  region: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  webDisplayName: Maybe<Scalars['String']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

export type CleContributorOrganization = {
  __typename?: 'CLEContributorOrganization';
  contributorCount: Maybe<Scalars['Int']['output']>;
  contributors: Maybe<Array<Maybe<CleContributor>>>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};

export type CleCourse = {
  __typename?: 'CLECourse';
  active: Maybe<Scalars['Boolean']['output']>;
  collections: Maybe<Array<Maybe<CoursesCollection>>>;
  contentfulId: Maybe<Scalars['ID']['output']>;
  courseMaterialsFileName: Maybe<Scalars['String']['output']>;
  courseMaterialsUrl: Maybe<Scalars['String']['output']>;
  dateRecorded: Maybe<Scalars['String']['output']>;
  featureImageUrl: Maybe<Scalars['String']['output']>;
  longDescription: Maybe<Scalars['JSONObject']['output']>;
  panoptoId: Maybe<Scalars['ID']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  slug: Maybe<Scalars['String']['output']>;
  speakers: Maybe<Array<Maybe<Author>>>;
  specialRequirementsCredits: Maybe<Array<Maybe<CleCourseSubcredits>>>;
  specializationCredits: Maybe<Array<Maybe<CleCourseSubcredits>>>;
  title: Maybe<Scalars['String']['output']>;
  totalCredits: Maybe<Scalars['Float']['output']>;
};

export type CleCourseCredit = {
  __typename?: 'CLECourseCredit';
  certificatePdfLink: Maybe<Scalars['String']['output']>;
  completedDate: Maybe<Scalars['String']['output']>;
  courseName: Maybe<Scalars['String']['output']>;
  creditAreaHours: Maybe<Array<Maybe<CleCreditAreaHour>>>;
  salesforceId: Maybe<Scalars['ID']['output']>;
  specializationHours: Maybe<Array<Maybe<CleSpecializationHour>>>;
  totalCreditHours: Maybe<Scalars['Float']['output']>;
};

export type CleCourseSubcredits = {
  __typename?: 'CLECourseSubcredits';
  contentfulId: Maybe<Scalars['ID']['output']>;
  credits: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  parentContentfulId: Maybe<Scalars['ID']['output']>;
  parentName: Maybe<Scalars['String']['output']>;
};

export type CleCreditArea = {
  __typename?: 'CLECreditArea';
  cleCreditSubArea: Maybe<CleCreditArea>;
  completed: Maybe<Scalars['Boolean']['output']>;
  completedCredits: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  requiredCredits: Maybe<Scalars['Int']['output']>;
};

export type CleCreditAreaHour = {
  __typename?: 'CLECreditAreaHour';
  creditAreaName: Maybe<Scalars['String']['output']>;
  creditHours: Maybe<Scalars['Float']['output']>;
};

export type CleSpecializationHour = {
  __typename?: 'CLESpecializationHour';
  category: Maybe<Scalars['String']['output']>;
  creditHours: Maybe<Scalars['Float']['output']>;
  subcategory: Maybe<Scalars['String']['output']>;
};

export type CleSpecializationSummaryArea = {
  __typename?: 'CLESpecializationSummaryArea';
  completed: Maybe<Scalars['Boolean']['output']>;
  completedCredits: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  requiredCredits: Maybe<Scalars['Int']['output']>;
};

export type CleTrueDocsAndForms = {
  __typename?: 'CLETrueDocsAndForms';
  cebGuidance: Maybe<Scalars['JSONObject']['output']>;
  contentfulId: Maybe<Scalars['ID']['output']>;
  county: Maybe<Scalars['String']['output']>;
  docspringTemplateId: Maybe<Scalars['String']['output']>;
  documentTemplateUrl: Maybe<Scalars['String']['output']>;
  formNumber: Maybe<Scalars['String']['output']>;
  mandatoryJcForm: Maybe<Scalars['Boolean']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  shortDescription: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  trueFormSubtype: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedDate: Maybe<Scalars['String']['output']>;
};

export type CleTrueDocsAndFormsCollections = {
  __typename?: 'CLETrueDocsAndFormsCollections';
  contentfulId: Maybe<Scalars['ID']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  shortDescription: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  trueDocsAndForms: Maybe<Array<Maybe<CleTrueDocsAndForms>>>;
};

export type CaConstitutionCode = {
  __typename?: 'CaConstitutionCode';
  abbreviatedName: Maybe<Scalars['String']['output']>;
  judicataId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  tableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
};

export type CaConstitutionHeading = {
  __typename?: 'CaConstitutionHeading';
  codeSections: Maybe<Array<Maybe<CaConstitutionSection>>>;
  parentTableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
  sectionBegin: Maybe<Scalars['String']['output']>;
  sectionEnd: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CaConstitutionSection = {
  __typename?: 'CaConstitutionSection';
  abbreviatedSectionTitle: Maybe<Scalars['String']['output']>;
  article: Maybe<Scalars['String']['output']>;
  citationCount: Maybe<Scalars['Int']['output']>;
  codeJudicataId: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  history: Maybe<Scalars['String']['output']>;
  judicataId: Maybe<Scalars['String']['output']>;
  judicataNumericId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Maybe<Scalars['String']['output']>;
  parentTableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
  searchHighlightTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title: Maybe<Scalars['String']['output']>;
};

export type Case = {
  __typename?: 'Case';
  citationCount: Maybe<Scalars['Int']['output']>;
  citationTemplate: Maybe<Array<Maybe<CitationTemplateEntry>>>;
  cite: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  courtName: Maybe<Scalars['String']['output']>;
  date: Maybe<Scalars['String']['output']>;
  exampleTreatingCase: Maybe<Case>;
  judicataId: Maybe<Scalars['String']['output']>;
  lastCitedOn: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  nameWithCite: Maybe<Scalars['String']['output']>;
  searchHighlightTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  treatmentAttitudeName: Maybe<Scalars['String']['output']>;
  treatmentDescription: Maybe<Scalars['String']['output']>;
};

export type CaseCitationTemplateArgs = {
  format: InputMaybe<CitationTemplateFormat>;
};

export type CaseCitatorTypeaheadResult = {
  __typename?: 'CaseCitatorTypeaheadResult';
  value: Scalars['String']['output'];
};

export type CaseDocumentTypeaheadResult = {
  __typename?: 'CaseDocumentTypeaheadResult';
  caseId: Scalars['String']['output'];
  cite: Scalars['String']['output'];
  date: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Types of publication statues that can be filtered on in a Case search */
export enum CasePublicationStatus {
  Both = 'BOTH',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type CaseSearchEdge = {
  __typename?: 'CaseSearchEdge';
  citingTreatment: Maybe<CitingTreatment>;
  missingTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  node: Case;
  snippets: Maybe<Array<Maybe<Snippet>>>;
};

export type CitationTemplateEntry = {
  __typename?: 'CitationTemplateEntry';
  format: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
  typeName: Maybe<Scalars['String']['output']>;
};

export enum CitationTemplateFormat {
  California = 'CALIFORNIA',
  Standard = 'STANDARD',
}

export type CitingTreatment = {
  __typename?: 'CitingTreatment';
  attitudeName: Maybe<Scalars['String']['output']>;
  levelOfDiscussion: Maybe<Scalars['Int']['output']>;
  treatmentDescription: Maybe<Scalars['String']['output']>;
};

export type Code = {
  __typename?: 'Code';
  abbreviatedName: Maybe<Scalars['String']['output']>;
  judicataId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  tableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
};

export type CodeHeading = {
  __typename?: 'CodeHeading';
  codeSections: Maybe<Array<Maybe<CodeSection>>>;
  parentTableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
  sectionBegin: Maybe<Scalars['String']['output']>;
  sectionEnd: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CodeOutlineTypeaheadResult = {
  __typename?: 'CodeOutlineTypeaheadResult';
  actionText: Scalars['String']['output'];
  codeId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CodeSection = {
  __typename?: 'CodeSection';
  abbreviatedSectionTitle: Maybe<Scalars['String']['output']>;
  citationCount: Maybe<Scalars['Int']['output']>;
  citationTemplate: Maybe<Array<Maybe<CitationTemplateEntry>>>;
  codeJudicataId: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  history: Maybe<Scalars['String']['output']>;
  judicataId: Maybe<Scalars['String']['output']>;
  judicataNumericId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Maybe<Scalars['String']['output']>;
  parentTableOfContents: Maybe<Array<Maybe<TableOfContentsEntry>>>;
  searchHighlightTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title: Maybe<Scalars['String']['output']>;
};

export type CodeSectionCitationTemplateArgs = {
  format: InputMaybe<CitationTemplateFormat>;
};

export type CodeSectionCitatorTypeaheadResult = {
  __typename?: 'CodeSectionCitatorTypeaheadResult';
  actionText: Scalars['String']['output'];
  articleId: Maybe<Scalars['String']['output']>;
  codeId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Scalars['String']['output'];
  sectionId: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type CodeSectionDocumentTypeaheadResult = {
  __typename?: 'CodeSectionDocumentTypeaheadResult';
  articleId: Maybe<Scalars['String']['output']>;
  codeId: Maybe<Scalars['String']['output']>;
  jurisdictionId: Scalars['String']['output'];
  sectionId: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export enum CompliancePeriod {
  Current = 'CURRENT',
  Previous = 'PREVIOUS',
}

export type ContentFavorite = {
  __typename?: 'ContentFavorite';
  contentItemId: Scalars['ID']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  type: ContentFavoriteType;
};

export enum ContentFavoriteType {
  SecondarySourcesTitle = 'SECONDARY_SOURCES_TITLE',
}

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgency = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulAgency';
    _id: Scalars['ID']['output'];
    address: Maybe<Scalars['String']['output']>;
    agencyName: Maybe<Scalars['String']['output']>;
    checkUtility: Maybe<Scalars['Boolean']['output']>;
    comment: Maybe<ContentfulAgencyComment>;
    contactPerson: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    court: Maybe<ContentfulCourt>;
    dateFlagReset: Maybe<Scalars['ContentfulDateTime']['output']>;
    efPackage: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    formsIndexPage: Maybe<Scalars['String']['output']>;
    formsProvider: Maybe<ContentfulFormsProvider>;
    importForms: Maybe<Scalars['Boolean']['output']>;
    lastFidoFormUpdate: Maybe<Scalars['ContentfulDateTime']['output']>;
    linkedFrom: Maybe<ContentfulAgencyLinkingCollections>;
    nameAbbreviation: Maybe<Scalars['String']['output']>;
    phone: Maybe<Scalars['String']['output']>;
    source: Maybe<ContentfulFormSource>;
    sys: ContentfulSys;
    website: Maybe<Scalars['String']['output']>;
  };

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyAddressArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyAgencyNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyCheckUtilityArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyCommentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyContactPersonArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyCourtArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulCourtFilter>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyDateFlagResetArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyEfPackageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyFormsIndexPageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyFormsProviderArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulFormsProviderFilter>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyImportFormsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyLastFidoFormUpdateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyNameAbbreviationArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyPhoneArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencySourceArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulFormSourceFilter>;
};

/** Agency for forms [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agency) */
export type ContentfulAgencyWebsiteArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulAgencyCollection = {
  __typename?: 'ContentfulAgencyCollection';
  items: Array<Maybe<ContentfulAgency>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulAgencyComment = {
  __typename?: 'ContentfulAgencyComment';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulAgencyCommentLinks;
};

export type ContentfulAgencyCommentAssets = {
  __typename?: 'ContentfulAgencyCommentAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulAgencyCommentEntries = {
  __typename?: 'ContentfulAgencyCommentEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulAgencyCommentLinks = {
  __typename?: 'ContentfulAgencyCommentLinks';
  assets: ContentfulAgencyCommentAssets;
  entries: ContentfulAgencyCommentEntries;
  resources: ContentfulAgencyCommentResources;
};

export type ContentfulAgencyCommentResources = {
  __typename?: 'ContentfulAgencyCommentResources';
  block: Array<ContentfulAgencyCommentResourcesBlock>;
  hyperlink: Array<ContentfulAgencyCommentResourcesHyperlink>;
  inline: Array<ContentfulAgencyCommentResourcesInline>;
};

export type ContentfulAgencyCommentResourcesBlock = ContentfulResourceLink & {
  __typename?: 'ContentfulAgencyCommentResourcesBlock';
  sys: ContentfulResourceSys;
};

export type ContentfulAgencyCommentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulAgencyCommentResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulAgencyCommentResourcesInline = ContentfulResourceLink & {
  __typename?: 'ContentfulAgencyCommentResourcesInline';
  sys: ContentfulResourceSys;
};

export type ContentfulAgencyFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulAgencyFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulAgencyFilter>>>;
  address: InputMaybe<Scalars['String']['input']>;
  address_contains: InputMaybe<Scalars['String']['input']>;
  address_exists: InputMaybe<Scalars['Boolean']['input']>;
  address_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address_not: InputMaybe<Scalars['String']['input']>;
  address_not_contains: InputMaybe<Scalars['String']['input']>;
  address_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agencyName: InputMaybe<Scalars['String']['input']>;
  agencyName_contains: InputMaybe<Scalars['String']['input']>;
  agencyName_exists: InputMaybe<Scalars['Boolean']['input']>;
  agencyName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agencyName_not: InputMaybe<Scalars['String']['input']>;
  agencyName_not_contains: InputMaybe<Scalars['String']['input']>;
  agencyName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checkUtility: InputMaybe<Scalars['Boolean']['input']>;
  checkUtility_exists: InputMaybe<Scalars['Boolean']['input']>;
  checkUtility_not: InputMaybe<Scalars['Boolean']['input']>;
  comment_contains: InputMaybe<Scalars['String']['input']>;
  comment_exists: InputMaybe<Scalars['Boolean']['input']>;
  comment_not_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson: InputMaybe<Scalars['String']['input']>;
  contactPerson_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson_exists: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactPerson_not: InputMaybe<Scalars['String']['input']>;
  contactPerson_not_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  court: InputMaybe<ContentfulCfCourtNestedFilter>;
  court_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateFlagReset: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateFlagReset_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateFlagReset_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPackage_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_exists: InputMaybe<Scalars['Boolean']['input']>;
  formsIndexPage: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_contains: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_exists: InputMaybe<Scalars['Boolean']['input']>;
  formsIndexPage_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formsIndexPage_not: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_not_contains: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formsProvider: InputMaybe<ContentfulCfFormsProviderNestedFilter>;
  formsProvider_exists: InputMaybe<Scalars['Boolean']['input']>;
  importForms: InputMaybe<Scalars['Boolean']['input']>;
  importForms_exists: InputMaybe<Scalars['Boolean']['input']>;
  importForms_not: InputMaybe<Scalars['Boolean']['input']>;
  lastFidoFormUpdate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastFidoFormUpdate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  lastFidoFormUpdate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  nameAbbreviation: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_contains: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_exists: InputMaybe<Scalars['Boolean']['input']>;
  nameAbbreviation_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  nameAbbreviation_not: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_not_contains: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_contains: InputMaybe<Scalars['String']['input']>;
  phone_exists: InputMaybe<Scalars['Boolean']['input']>;
  phone_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_not: InputMaybe<Scalars['String']['input']>;
  phone_not_contains: InputMaybe<Scalars['String']['input']>;
  phone_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source: InputMaybe<ContentfulCfFormSourceNestedFilter>;
  source_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulAgencyLinkingCollections = {
  __typename?: 'ContentfulAgencyLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  formCollection: Maybe<ContentfulFormCollection>;
};

export type ContentfulAgencyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAgencyLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulAgencyLinkingCollectionsFormCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulAgencyLinkingCollectionsFormCollectionOrder {
  AdoptedMandatoryAllCourtsAsc = 'adoptedMandatoryAllCourts_ASC',
  AdoptedMandatoryAllCourtsDesc = 'adoptedMandatoryAllCourts_DESC',
  AutoProcessAsc = 'autoProcess_ASC',
  AutoProcessDesc = 'autoProcess_DESC',
  DateIssuedByAgencyAsc = 'dateIssuedByAgency_ASC',
  DateIssuedByAgencyDesc = 'dateIssuedByAgency_DESC',
  DateRevisedAsc = 'dateRevised_ASC',
  DateRevisedDesc = 'dateRevised_DESC',
  EfPubDateAsc = 'efPubDate_ASC',
  EfPubDateDesc = 'efPubDate_DESC',
  EffectiveDateAsc = 'effectiveDate_ASC',
  EffectiveDateDesc = 'effectiveDate_DESC',
  EssentialFormsProductionStatusAsc = 'essentialFormsProductionStatus_ASC',
  EssentialFormsProductionStatusDesc = 'essentialFormsProductionStatus_DESC',
  FormNameAsc = 'formName_ASC',
  FormNameDesc = 'formName_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  FormStatusAsc = 'formStatus_ASC',
  FormStatusDesc = 'formStatus_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  JcFormNumberAsc = 'jcFormNumber_ASC',
  JcFormNumberDesc = 'jcFormNumber_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NeededByAsc = 'neededBy_ASC',
  NeededByDesc = 'neededBy_DESC',
  NeedsReviewAsc = 'needsReview_ASC',
  NeedsReviewDesc = 'needsReview_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SourceUrlAsc = 'sourceUrl_ASC',
  SourceUrlDesc = 'sourceUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormAsc = 'trueForm_ASC',
  TrueFormDesc = 'trueForm_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum ContentfulAgencyOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AgencyNameAsc = 'agencyName_ASC',
  AgencyNameDesc = 'agencyName_DESC',
  CheckUtilityAsc = 'checkUtility_ASC',
  CheckUtilityDesc = 'checkUtility_DESC',
  ContactPersonAsc = 'contactPerson_ASC',
  ContactPersonDesc = 'contactPerson_DESC',
  DateFlagResetAsc = 'dateFlagReset_ASC',
  DateFlagResetDesc = 'dateFlagReset_DESC',
  FormsIndexPageAsc = 'formsIndexPage_ASC',
  FormsIndexPageDesc = 'formsIndexPage_DESC',
  ImportFormsAsc = 'importForms_ASC',
  ImportFormsDesc = 'importForms_DESC',
  LastFidoFormUpdateAsc = 'lastFidoFormUpdate_ASC',
  LastFidoFormUpdateDesc = 'lastFidoFormUpdate_DESC',
  NameAbbreviationAsc = 'nameAbbreviation_ASC',
  NameAbbreviationDesc = 'nameAbbreviation_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicenses = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulAgreementsAndLicenses';
    _id: Scalars['ID']['output'];
    agreementLevel: Maybe<Scalars['String']['output']>;
    content: Maybe<ContentfulAgreementsAndLicensesContent>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulAgreementsAndLicensesLinkingCollections>;
    planCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    productCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    updatedDate: Maybe<Scalars['ContentfulDateTime']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesAgreementLevelArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesContentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesPlanCodesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesProductCodesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/agreementsAndLicenses) */
export type ContentfulAgreementsAndLicensesUpdatedDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulAgreementsAndLicensesCollection = {
  __typename?: 'ContentfulAgreementsAndLicensesCollection';
  items: Array<Maybe<ContentfulAgreementsAndLicenses>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulAgreementsAndLicensesContent = {
  __typename?: 'ContentfulAgreementsAndLicensesContent';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulAgreementsAndLicensesContentLinks;
};

export type ContentfulAgreementsAndLicensesContentAssets = {
  __typename?: 'ContentfulAgreementsAndLicensesContentAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulAgreementsAndLicensesContentEntries = {
  __typename?: 'ContentfulAgreementsAndLicensesContentEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulAgreementsAndLicensesContentLinks = {
  __typename?: 'ContentfulAgreementsAndLicensesContentLinks';
  assets: ContentfulAgreementsAndLicensesContentAssets;
  entries: ContentfulAgreementsAndLicensesContentEntries;
  resources: ContentfulAgreementsAndLicensesContentResources;
};

export type ContentfulAgreementsAndLicensesContentResources = {
  __typename?: 'ContentfulAgreementsAndLicensesContentResources';
  block: Array<ContentfulAgreementsAndLicensesContentResourcesBlock>;
  hyperlink: Array<ContentfulAgreementsAndLicensesContentResourcesHyperlink>;
  inline: Array<ContentfulAgreementsAndLicensesContentResourcesInline>;
};

export type ContentfulAgreementsAndLicensesContentResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulAgreementsAndLicensesContentResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulAgreementsAndLicensesContentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulAgreementsAndLicensesContentResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulAgreementsAndLicensesContentResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulAgreementsAndLicensesContentResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulAgreementsAndLicensesFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulAgreementsAndLicensesFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulAgreementsAndLicensesFilter>>>;
  agreementLevel: InputMaybe<Scalars['String']['input']>;
  agreementLevel_contains: InputMaybe<Scalars['String']['input']>;
  agreementLevel_exists: InputMaybe<Scalars['Boolean']['input']>;
  agreementLevel_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agreementLevel_not: InputMaybe<Scalars['String']['input']>;
  agreementLevel_not_contains: InputMaybe<Scalars['String']['input']>;
  agreementLevel_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  content_contains: InputMaybe<Scalars['String']['input']>;
  content_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  planCodes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  planCodes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  planCodes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  planCodes_exists: InputMaybe<Scalars['Boolean']['input']>;
  productCodes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

export type ContentfulAgreementsAndLicensesLinkingCollections = {
  __typename?: 'ContentfulAgreementsAndLicensesLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulAgreementsAndLicensesLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulAgreementsAndLicensesOrder {
  AgreementLevelAsc = 'agreementLevel_ASC',
  AgreementLevelDesc = 'agreementLevel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAsset = {
  __typename?: 'ContentfulAsset';
  contentType: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulContentfulMetadata;
  description: Maybe<Scalars['String']['output']>;
  fileName: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['Int']['output']>;
  linkedFrom: Maybe<ContentfulAssetLinkingCollections>;
  size: Maybe<Scalars['Int']['output']>;
  sys: ContentfulSys;
  title: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetContentTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetFileNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetHeightArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetSizeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  transform: InputMaybe<ContentfulImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetWidthArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulAssetCollection = {
  __typename?: 'ContentfulAssetCollection';
  items: Array<Maybe<ContentfulAsset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulAssetFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  contentType: InputMaybe<Scalars['String']['input']>;
  contentType_contains: InputMaybe<Scalars['String']['input']>;
  contentType_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains: InputMaybe<Scalars['String']['input']>;
  contentType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName: InputMaybe<Scalars['String']['input']>;
  fileName_contains: InputMaybe<Scalars['String']['input']>;
  fileName_exists: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains: InputMaybe<Scalars['String']['input']>;
  fileName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height: InputMaybe<Scalars['Int']['input']>;
  height_exists: InputMaybe<Scalars['Boolean']['input']>;
  height_gt: InputMaybe<Scalars['Int']['input']>;
  height_gte: InputMaybe<Scalars['Int']['input']>;
  height_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt: InputMaybe<Scalars['Int']['input']>;
  height_lte: InputMaybe<Scalars['Int']['input']>;
  height_not: InputMaybe<Scalars['Int']['input']>;
  height_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size: InputMaybe<Scalars['Int']['input']>;
  size_exists: InputMaybe<Scalars['Boolean']['input']>;
  size_gt: InputMaybe<Scalars['Int']['input']>;
  size_gte: InputMaybe<Scalars['Int']['input']>;
  size_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt: InputMaybe<Scalars['Int']['input']>;
  size_lte: InputMaybe<Scalars['Int']['input']>;
  size_not: InputMaybe<Scalars['Int']['input']>;
  size_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url: InputMaybe<Scalars['String']['input']>;
  url_contains: InputMaybe<Scalars['String']['input']>;
  url_exists: InputMaybe<Scalars['Boolean']['input']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not: InputMaybe<Scalars['String']['input']>;
  url_not_contains: InputMaybe<Scalars['String']['input']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width: InputMaybe<Scalars['Int']['input']>;
  width_exists: InputMaybe<Scalars['Boolean']['input']>;
  width_gt: InputMaybe<Scalars['Int']['input']>;
  width_gte: InputMaybe<Scalars['Int']['input']>;
  width_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt: InputMaybe<Scalars['Int']['input']>;
  width_lte: InputMaybe<Scalars['Int']['input']>;
  width_not: InputMaybe<Scalars['Int']['input']>;
  width_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContentfulAssetLinkingCollections = {
  __typename?: 'ContentfulAssetLinkingCollections';
  chapterCollection: Maybe<ContentfulChapterCollection>;
  contributorCollection: Maybe<ContentfulContributorCollection>;
  courseCollection: Maybe<ContentfulCourseCollection>;
  coursesCollectionCollection: Maybe<ContentfulCoursesCollectionCollection>;
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  endMatterCollection: Maybe<ContentfulEndMatterCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  formCollection: Maybe<ContentfulFormCollection>;
  frontMatterCollection: Maybe<ContentfulFrontMatterCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  looseleafBookCollection: Maybe<ContentfulLooseleafBookCollection>;
  looseleafChapterCollection: Maybe<ContentfulLooseleafChapterCollection>;
  looseleafEndMatterCollection: Maybe<ContentfulLooseleafEndMatterCollection>;
  looseleafFrontMatterCollection: Maybe<ContentfulLooseleafFrontMatterCollection>;
  planOfferingCollection: Maybe<ContentfulPlanOfferingCollection>;
  planOfferingOptionCollection: Maybe<ContentfulPlanOfferingOptionCollection>;
  programCollection: Maybe<ContentfulProgramCollection>;
  trueDocsAndFormsCollection: Maybe<ContentfulTrueDocsAndFormsCollection>;
};

export type ContentfulAssetLinkingCollectionsChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsContributorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsCoursesCollectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsCurrentAwarenessCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsEndMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsFrontMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsKnowHowDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsLooseleafBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsLooseleafChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsLooseleafEndMatterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulAssetLinkingCollectionsLooseleafFrontMatterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulAssetLinkingCollectionsPlanOfferingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsPlanOfferingOptionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulAssetLinkingCollectionsProgramCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulAssetLinkingCollectionsTrueDocsAndFormsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulAssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBook = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulBook';
    _id: Scalars['ID']['output'];
    bookYear: Maybe<Scalars['String']['output']>;
    chaptersCollection: Maybe<ContentfulBookChaptersCollection>;
    contentAttorney: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    contentfulMetadata: ContentfulContentfulMetadata;
    copyEditors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    copyrightDate: Maybe<Scalars['String']['output']>;
    copyrightHolder: Maybe<Scalars['String']['output']>;
    displayTitle: Maybe<Scalars['String']['output']>;
    documentWorkflowState: Maybe<Scalars['String']['output']>;
    editionNumber: Maybe<Scalars['String']['output']>;
    endMatterCollection: Maybe<ContentfulBookEndMatterCollection>;
    focd: Maybe<Scalars['Boolean']['output']>;
    frontMatterCollection: Maybe<ContentfulBookFrontMatterCollection>;
    isbn: Maybe<Scalars['String']['output']>;
    legalContentSpecialist: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    libraryOfCongressCatalogCardNumber: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulBookLinkingCollections>;
    onLawId: Maybe<Scalars['String']['output']>;
    printLocation: Maybe<Scalars['String']['output']>;
    productNumber: Maybe<Scalars['String']['output']>;
    pubId: Maybe<Scalars['String']['output']>;
    publicationDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    publicationType: Maybe<Scalars['String']['output']>;
    runningHeadTitle: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    titleId: Maybe<Scalars['String']['output']>;
    totalVolumeCount: Maybe<Scalars['Int']['output']>;
    trimSize: Maybe<Scalars['String']['output']>;
  };

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookBookYearArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookChaptersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulBookChaptersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulChapterFilter>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookContentAttorneyArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookCopyEditorsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookCopyrightDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookCopyrightHolderArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookDocumentWorkflowStateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookEditionNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookEndMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulBookEndMatterCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulEndMatterFilter>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookFocdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookFrontMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulBookFrontMatterCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFrontMatterFilter>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookIsbnArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookLegalContentSpecialistArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookLibraryOfCongressCatalogCardNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookOnLawIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookPrintLocationArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookProductNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookPubIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookPublicationDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookPublicationTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookRunningHeadTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookTitleIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookTotalVolumeCountArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic Book with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/book) */
export type ContentfulBookTrimSizeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulBookChaptersCollection = {
  __typename?: 'ContentfulBookChaptersCollection';
  items: Array<Maybe<ContentfulChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulBookChaptersCollectionOrder {
  ChapterNumberAsc = 'chapterNumber_ASC',
  ChapterNumberDesc = 'chapterNumber_DESC',
  ChapterRunningHeadTitleAsc = 'chapterRunningHeadTitle_ASC',
  ChapterRunningHeadTitleDesc = 'chapterRunningHeadTitle_DESC',
  ChapterTitleAsc = 'chapterTitle_ASC',
  ChapterTitleDesc = 'chapterTitle_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VolumeAsc = 'volume_ASC',
  VolumeDesc = 'volume_DESC',
}

export type ContentfulBookCollection = {
  __typename?: 'ContentfulBookCollection';
  items: Array<Maybe<ContentfulBook>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulBookEndMatterCollection = {
  __typename?: 'ContentfulBookEndMatterCollection';
  items: Array<Maybe<ContentfulEndMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulBookEndMatterCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  EndMatterTypeAsc = 'endMatterType_ASC',
  EndMatterTypeDesc = 'endMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulBookFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulBookFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulBookFilter>>>;
  bookYear: InputMaybe<Scalars['String']['input']>;
  bookYear_contains: InputMaybe<Scalars['String']['input']>;
  bookYear_exists: InputMaybe<Scalars['Boolean']['input']>;
  bookYear_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookYear_not: InputMaybe<Scalars['String']['input']>;
  bookYear_not_contains: InputMaybe<Scalars['String']['input']>;
  bookYear_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapters: InputMaybe<ContentfulCfChapterNestedFilter>;
  chaptersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentAttorney_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentAttorney_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentAttorney_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentAttorney_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  copyEditors_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightDate: InputMaybe<Scalars['String']['input']>;
  copyrightDate_contains: InputMaybe<Scalars['String']['input']>;
  copyrightDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightDate_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightDate_not: InputMaybe<Scalars['String']['input']>;
  copyrightDate_not_contains: InputMaybe<Scalars['String']['input']>;
  copyrightDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyrightHolder: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_contains: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightHolder_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightHolder_not: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_not_contains: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentWorkflowState: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_contains: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_exists: InputMaybe<Scalars['Boolean']['input']>;
  documentWorkflowState_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentWorkflowState_not: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_not_contains: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  editionNumber: InputMaybe<Scalars['String']['input']>;
  editionNumber_contains: InputMaybe<Scalars['String']['input']>;
  editionNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  editionNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  editionNumber_not: InputMaybe<Scalars['String']['input']>;
  editionNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  editionNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatter: InputMaybe<ContentfulCfEndMatterNestedFilter>;
  endMatterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  focd: InputMaybe<Scalars['Boolean']['input']>;
  focd_exists: InputMaybe<Scalars['Boolean']['input']>;
  focd_not: InputMaybe<Scalars['Boolean']['input']>;
  frontMatter: InputMaybe<ContentfulCfFrontMatterNestedFilter>;
  frontMatterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  isbn: InputMaybe<Scalars['String']['input']>;
  isbn_contains: InputMaybe<Scalars['String']['input']>;
  isbn_exists: InputMaybe<Scalars['Boolean']['input']>;
  isbn_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isbn_not: InputMaybe<Scalars['String']['input']>;
  isbn_not_contains: InputMaybe<Scalars['String']['input']>;
  isbn_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legalContentSpecialist_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_exists: InputMaybe<Scalars['Boolean']['input']>;
  libraryOfCongressCatalogCardNumber: InputMaybe<Scalars['String']['input']>;
  libraryOfCongressCatalogCardNumber_contains: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  libraryOfCongressCatalogCardNumber_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  libraryOfCongressCatalogCardNumber_not: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printLocation: InputMaybe<Scalars['String']['input']>;
  printLocation_contains: InputMaybe<Scalars['String']['input']>;
  printLocation_exists: InputMaybe<Scalars['Boolean']['input']>;
  printLocation_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printLocation_not: InputMaybe<Scalars['String']['input']>;
  printLocation_not_contains: InputMaybe<Scalars['String']['input']>;
  printLocation_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productNumber: InputMaybe<Scalars['String']['input']>;
  productNumber_contains: InputMaybe<Scalars['String']['input']>;
  productNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  productNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productNumber_not: InputMaybe<Scalars['String']['input']>;
  productNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  productNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pubId: InputMaybe<Scalars['String']['input']>;
  pubId_contains: InputMaybe<Scalars['String']['input']>;
  pubId_exists: InputMaybe<Scalars['Boolean']['input']>;
  pubId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pubId_not: InputMaybe<Scalars['String']['input']>;
  pubId_not_contains: InputMaybe<Scalars['String']['input']>;
  pubId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publicationDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publicationDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publicationType: InputMaybe<Scalars['String']['input']>;
  publicationType_contains: InputMaybe<Scalars['String']['input']>;
  publicationType_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publicationType_not: InputMaybe<Scalars['String']['input']>;
  publicationType_not_contains: InputMaybe<Scalars['String']['input']>;
  publicationType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  runningHeadTitle: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  runningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  runningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  titleId: InputMaybe<Scalars['String']['input']>;
  titleId_contains: InputMaybe<Scalars['String']['input']>;
  titleId_exists: InputMaybe<Scalars['Boolean']['input']>;
  titleId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleId_not: InputMaybe<Scalars['String']['input']>;
  titleId_not_contains: InputMaybe<Scalars['String']['input']>;
  titleId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  totalVolumeCount: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_exists: InputMaybe<Scalars['Boolean']['input']>;
  totalVolumeCount_gt: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_gte: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  totalVolumeCount_lt: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_lte: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_not: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  trimSize: InputMaybe<Scalars['String']['input']>;
  trimSize_contains: InputMaybe<Scalars['String']['input']>;
  trimSize_exists: InputMaybe<Scalars['Boolean']['input']>;
  trimSize_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trimSize_not: InputMaybe<Scalars['String']['input']>;
  trimSize_not_contains: InputMaybe<Scalars['String']['input']>;
  trimSize_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulBookFrontMatterCollection = {
  __typename?: 'ContentfulBookFrontMatterCollection';
  items: Array<Maybe<ContentfulFrontMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulBookFrontMatterCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  FrontMatterTypeAsc = 'frontMatterType_ASC',
  FrontMatterTypeDesc = 'frontMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulBookLinkingCollections = {
  __typename?: 'ContentfulBookLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulBookLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulBookOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  PublicationTypeAsc = 'publicationType_ASC',
  PublicationTypeDesc = 'publicationType_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export type ContentfulCfAgencyNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfAgencyNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfAgencyNestedFilter>>>;
  address: InputMaybe<Scalars['String']['input']>;
  address_contains: InputMaybe<Scalars['String']['input']>;
  address_exists: InputMaybe<Scalars['Boolean']['input']>;
  address_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address_not: InputMaybe<Scalars['String']['input']>;
  address_not_contains: InputMaybe<Scalars['String']['input']>;
  address_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agencyName: InputMaybe<Scalars['String']['input']>;
  agencyName_contains: InputMaybe<Scalars['String']['input']>;
  agencyName_exists: InputMaybe<Scalars['Boolean']['input']>;
  agencyName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agencyName_not: InputMaybe<Scalars['String']['input']>;
  agencyName_not_contains: InputMaybe<Scalars['String']['input']>;
  agencyName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checkUtility: InputMaybe<Scalars['Boolean']['input']>;
  checkUtility_exists: InputMaybe<Scalars['Boolean']['input']>;
  checkUtility_not: InputMaybe<Scalars['Boolean']['input']>;
  comment_contains: InputMaybe<Scalars['String']['input']>;
  comment_exists: InputMaybe<Scalars['Boolean']['input']>;
  comment_not_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson: InputMaybe<Scalars['String']['input']>;
  contactPerson_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson_exists: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactPerson_not: InputMaybe<Scalars['String']['input']>;
  contactPerson_not_contains: InputMaybe<Scalars['String']['input']>;
  contactPerson_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  court_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateFlagReset: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateFlagReset_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateFlagReset_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateFlagReset_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPackage_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  efPackage_exists: InputMaybe<Scalars['Boolean']['input']>;
  formsIndexPage: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_contains: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_exists: InputMaybe<Scalars['Boolean']['input']>;
  formsIndexPage_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formsIndexPage_not: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_not_contains: InputMaybe<Scalars['String']['input']>;
  formsIndexPage_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formsProvider_exists: InputMaybe<Scalars['Boolean']['input']>;
  importForms: InputMaybe<Scalars['Boolean']['input']>;
  importForms_exists: InputMaybe<Scalars['Boolean']['input']>;
  importForms_not: InputMaybe<Scalars['Boolean']['input']>;
  lastFidoFormUpdate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastFidoFormUpdate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  lastFidoFormUpdate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  lastFidoFormUpdate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  nameAbbreviation: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_contains: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_exists: InputMaybe<Scalars['Boolean']['input']>;
  nameAbbreviation_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  nameAbbreviation_not: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_not_contains: InputMaybe<Scalars['String']['input']>;
  nameAbbreviation_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_contains: InputMaybe<Scalars['String']['input']>;
  phone_exists: InputMaybe<Scalars['Boolean']['input']>;
  phone_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_not: InputMaybe<Scalars['String']['input']>;
  phone_not_contains: InputMaybe<Scalars['String']['input']>;
  phone_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfChapterNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfChapterNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfChapterNestedFilter>>>;
  chapterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber: InputMaybe<Scalars['String']['input']>;
  chapterNumber_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterNumber_not: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterRunningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterTitle: InputMaybe<Scalars['String']['input']>;
  chapterTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  forms_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  volume: InputMaybe<Scalars['Int']['input']>;
  volume_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume_gt: InputMaybe<Scalars['Int']['input']>;
  volume_gte: InputMaybe<Scalars['Int']['input']>;
  volume_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  volume_lt: InputMaybe<Scalars['Int']['input']>;
  volume_lte: InputMaybe<Scalars['Int']['input']>;
  volume_not: InputMaybe<Scalars['Int']['input']>;
  volume_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContentfulCfContentPublisherNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfContentPublisherNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfContentPublisherNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfContributorNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfContributorNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfContributorNestedFilter>>>;
  additionalSearchTerms: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_exists: InputMaybe<Scalars['Boolean']['input']>;
  additionalSearchTerms_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  additionalSearchTerms_not: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bio_contains: InputMaybe<Scalars['String']['input']>;
  bio_exists: InputMaybe<Scalars['Boolean']['input']>;
  bio_not_contains: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  city_contains: InputMaybe<Scalars['String']['input']>;
  city_exists: InputMaybe<Scalars['Boolean']['input']>;
  city_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_not: InputMaybe<Scalars['String']['input']>;
  city_not_contains: InputMaybe<Scalars['String']['input']>;
  city_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  contributorOrganization_exists: InputMaybe<Scalars['Boolean']['input']>;
  counties_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory_not: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  email_contains: InputMaybe<Scalars['String']['input']>;
  email_exists: InputMaybe<Scalars['Boolean']['input']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_not: InputMaybe<Scalars['String']['input']>;
  email_not_contains: InputMaybe<Scalars['String']['input']>;
  email_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: InputMaybe<Scalars['String']['input']>;
  firstName_contains: InputMaybe<Scalars['String']['input']>;
  firstName_exists: InputMaybe<Scalars['Boolean']['input']>;
  firstName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_not: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains: InputMaybe<Scalars['String']['input']>;
  firstName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headshot_exists: InputMaybe<Scalars['Boolean']['input']>;
  judge: InputMaybe<Scalars['String']['input']>;
  judge_contains: InputMaybe<Scalars['String']['input']>;
  judge_exists: InputMaybe<Scalars['Boolean']['input']>;
  judge_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  judge_not: InputMaybe<Scalars['String']['input']>;
  judge_not_contains: InputMaybe<Scalars['String']['input']>;
  judge_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName: InputMaybe<Scalars['String']['input']>;
  lastName_contains: InputMaybe<Scalars['String']['input']>;
  lastName_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_not: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains: InputMaybe<Scalars['String']['input']>;
  lastName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkedInUrl: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_contains: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  linkedInUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkedInUrl_not: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  phoneNumber_contains: InputMaybe<Scalars['String']['input']>;
  phoneNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber_not: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
  region_contains: InputMaybe<Scalars['String']['input']>;
  region_exists: InputMaybe<Scalars['Boolean']['input']>;
  region_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not: InputMaybe<Scalars['String']['input']>;
  region_not_contains: InputMaybe<Scalars['String']['input']>;
  region_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforceContactLink: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_contains: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceContactLink_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceContactLink_not: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortBio: InputMaybe<Scalars['String']['input']>;
  shortBio_contains: InputMaybe<Scalars['String']['input']>;
  shortBio_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortBio_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shortBio_not: InputMaybe<Scalars['String']['input']>;
  shortBio_not_contains: InputMaybe<Scalars['String']['input']>;
  shortBio_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  state: InputMaybe<Scalars['String']['input']>;
  state_contains: InputMaybe<Scalars['String']['input']>;
  state_exists: InputMaybe<Scalars['Boolean']['input']>;
  state_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  state_not: InputMaybe<Scalars['String']['input']>;
  state_not_contains: InputMaybe<Scalars['String']['input']>;
  state_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddress_contains: InputMaybe<Scalars['String']['input']>;
  streetAddress_exists: InputMaybe<Scalars['Boolean']['input']>;
  streetAddress_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  streetAddress_not: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_contains: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webDisplayName: InputMaybe<Scalars['String']['input']>;
  webDisplayName_contains: InputMaybe<Scalars['String']['input']>;
  webDisplayName_exists: InputMaybe<Scalars['Boolean']['input']>;
  webDisplayName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webDisplayName_not: InputMaybe<Scalars['String']['input']>;
  webDisplayName_not_contains: InputMaybe<Scalars['String']['input']>;
  webDisplayName_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode: InputMaybe<Scalars['String']['input']>;
  zipCode_contains: InputMaybe<Scalars['String']['input']>;
  zipCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  zipCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode_not: InputMaybe<Scalars['String']['input']>;
  zipCode_not_contains: InputMaybe<Scalars['String']['input']>;
  zipCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfContributorOrganizationNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfContributorOrganizationNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfContributorOrganizationNestedFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfCourseCreditCategoryNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfCourseCreditCategoryNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfCourseCreditCategoryNestedFilter>>
  >;
  childCategoriesCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfCourseNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfCourseNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfCourseNestedFilter>>>;
  active: InputMaybe<Scalars['Boolean']['input']>;
  active_exists: InputMaybe<Scalars['Boolean']['input']>;
  active_not: InputMaybe<Scalars['Boolean']['input']>;
  cebCoordinator_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  courseMaterials_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRecorded_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  difficultyLevel: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_contains: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_exists: InputMaybe<Scalars['Boolean']['input']>;
  difficultyLevel_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficultyLevel_not: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_not_contains: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId: InputMaybe<Scalars['String']['input']>;
  panoptoId_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId_exists: InputMaybe<Scalars['Boolean']['input']>;
  panoptoId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  panoptoId_not: InputMaybe<Scalars['String']['input']>;
  panoptoId_not_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  participatoryCreditCode: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  participatoryCreditCode_gt: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_gte: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  participatoryCreditCode_lt: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_lte: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_not: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publisher_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId: InputMaybe<Scalars['String']['input']>;
  salesforceId_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforceId_not: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceStagingId: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_contains: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceStagingId_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceStagingId_not: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  speakersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  specialRequirementsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  specialRequirementsCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  specializationCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  specializationsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  stateBarCourseFormatCode: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  stateBarCourseFormatCode_gt: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_gte: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  stateBarCourseFormatCode_lt: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_lte: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_not: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  totalCredits: InputMaybe<Scalars['Float']['input']>;
  totalCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  totalCredits_gt: InputMaybe<Scalars['Float']['input']>;
  totalCredits_gte: InputMaybe<Scalars['Float']['input']>;
  totalCredits_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalCredits_lt: InputMaybe<Scalars['Float']['input']>;
  totalCredits_lte: InputMaybe<Scalars['Float']['input']>;
  totalCredits_not: InputMaybe<Scalars['Float']['input']>;
  totalCredits_not_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type ContentfulCfCourtNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfCourtNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfCourtNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfCurrentAwarenessNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfCurrentAwarenessNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfCurrentAwarenessNestedFilter>>>;
  authorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_contains: InputMaybe<Scalars['String']['input']>;
  content_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  featuredImageCaption: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_contains: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredImageCaption_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuredImageCaption_not: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_not_contains: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuredImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost_not: InputMaybe<Scalars['Boolean']['input']>;
  legalTasksCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  originalPublishDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  postResourceType: InputMaybe<Scalars['String']['input']>;
  postResourceType_contains: InputMaybe<Scalars['String']['input']>;
  postResourceType_exists: InputMaybe<Scalars['Boolean']['input']>;
  postResourceType_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  postResourceType_not: InputMaybe<Scalars['String']['input']>;
  postResourceType_not_contains: InputMaybe<Scalars['String']['input']>;
  postResourceType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publisher_exists: InputMaybe<Scalars['Boolean']['input']>;
  relatedContentCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading: InputMaybe<Scalars['String']['input']>;
  subheading_contains: InputMaybe<Scalars['String']['input']>;
  subheading_exists: InputMaybe<Scalars['Boolean']['input']>;
  subheading_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_not: InputMaybe<Scalars['String']['input']>;
  subheading_not_contains: InputMaybe<Scalars['String']['input']>;
  subheading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle: InputMaybe<Scalars['String']['input']>;
  subtitle_contains: InputMaybe<Scalars['String']['input']>;
  subtitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topicsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

export type ContentfulCfDocumentTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfDocumentTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfDocumentTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfEndMatterNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfEndMatterNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfEndMatterNestedFilter>>>;
  cIndex_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatterType: InputMaybe<Scalars['String']['input']>;
  endMatterType_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  endMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endMatterType_not: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfFormNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfFormNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfFormNestedFilter>>>;
  adoptedMandatoryAllCourts: InputMaybe<Scalars['Boolean']['input']>;
  adoptedMandatoryAllCourts_exists: InputMaybe<Scalars['Boolean']['input']>;
  adoptedMandatoryAllCourts_not: InputMaybe<Scalars['Boolean']['input']>;
  agencyCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess_exists: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess_not: InputMaybe<Scalars['Boolean']['input']>;
  contentItem_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  dateIssuedByAgency: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateIssuedByAgency_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateIssuedByAgency_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRevised: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRevised_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRevised_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPubDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  efPubDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPubDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  effectiveDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  effectiveDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  essentialFormsProductionStatus: InputMaybe<Scalars['String']['input']>;
  essentialFormsProductionStatus_contains: InputMaybe<
    Scalars['String']['input']
  >;
  essentialFormsProductionStatus_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  essentialFormsProductionStatus_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  essentialFormsProductionStatus_not: InputMaybe<Scalars['String']['input']>;
  essentialFormsProductionStatus_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  essentialFormsProductionStatus_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formName: InputMaybe<Scalars['String']['input']>;
  formName_contains: InputMaybe<Scalars['String']['input']>;
  formName_exists: InputMaybe<Scalars['Boolean']['input']>;
  formName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formName_not: InputMaybe<Scalars['String']['input']>;
  formName_not_contains: InputMaybe<Scalars['String']['input']>;
  formName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber: InputMaybe<Scalars['String']['input']>;
  formNumber_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber_not: InputMaybe<Scalars['String']['input']>;
  formNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formSource_exists: InputMaybe<Scalars['Boolean']['input']>;
  formStatus: InputMaybe<Scalars['String']['input']>;
  formStatus_contains: InputMaybe<Scalars['String']['input']>;
  formStatus_exists: InputMaybe<Scalars['Boolean']['input']>;
  formStatus_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formStatus_not: InputMaybe<Scalars['String']['input']>;
  formStatus_not_contains: InputMaybe<Scalars['String']['input']>;
  formStatus_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formType: InputMaybe<Scalars['String']['input']>;
  formType_contains: InputMaybe<Scalars['String']['input']>;
  formType_exists: InputMaybe<Scalars['Boolean']['input']>;
  formType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formType_not: InputMaybe<Scalars['String']['input']>;
  formType_not_contains: InputMaybe<Scalars['String']['input']>;
  formType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hash: InputMaybe<Scalars['String']['input']>;
  hash_contains: InputMaybe<Scalars['String']['input']>;
  hash_exists: InputMaybe<Scalars['Boolean']['input']>;
  hash_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hash_not: InputMaybe<Scalars['String']['input']>;
  hash_not_contains: InputMaybe<Scalars['String']['input']>;
  hash_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagesCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  jcFormNumber: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_contains: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  jcFormNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jcFormNumber_not: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  neededBy: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_exists: InputMaybe<Scalars['Boolean']['input']>;
  neededBy_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  neededBy_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReview_exists: InputMaybe<Scalars['Boolean']['input']>;
  needsReview_not: InputMaybe<Scalars['Boolean']['input']>;
  notes_contains: InputMaybe<Scalars['String']['input']>;
  notes_exists: InputMaybe<Scalars['Boolean']['input']>;
  notes_not_contains: InputMaybe<Scalars['String']['input']>;
  pdfMasterFile_exists: InputMaybe<Scalars['Boolean']['input']>;
  practiceArea_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceFileCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl: InputMaybe<Scalars['String']['input']>;
  sourceUrl_contains: InputMaybe<Scalars['String']['input']>;
  sourceUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl_not: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueForm: InputMaybe<Scalars['Boolean']['input']>;
  trueForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  trueForm_not: InputMaybe<Scalars['Boolean']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  url_contains: InputMaybe<Scalars['String']['input']>;
  url_exists: InputMaybe<Scalars['Boolean']['input']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not: InputMaybe<Scalars['String']['input']>;
  url_not_contains: InputMaybe<Scalars['String']['input']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfFormSourceNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfFormSourceNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfFormSourceNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfFormsProviderNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfFormsProviderNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfFormsProviderNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Scalars['String']['input']>;
  id_contains: InputMaybe<Scalars['String']['input']>;
  id_exists: InputMaybe<Scalars['Boolean']['input']>;
  id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not: InputMaybe<Scalars['String']['input']>;
  id_not_contains: InputMaybe<Scalars['String']['input']>;
  id_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfFrontMatterNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfFrontMatterNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfFrontMatterNestedFilter>>>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  frontMatterType: InputMaybe<Scalars['String']['input']>;
  frontMatterType_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  frontMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frontMatterType_not: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfKnowHowDocumentNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfKnowHowDocumentNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfKnowHowDocumentNestedFilter>>>;
  authorities_exists: InputMaybe<Scalars['Boolean']['input']>;
  authorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  court_exists: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legalTasksCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  originalPublishDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  practitionerDocumentType_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedContent_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedWordDocumentNoNotes_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedWordDocument_exists: InputMaybe<Scalars['Boolean']['input']>;
  relatedContentCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceWordDocument_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  validatorProblems_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulCfLegalTaskNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfLegalTaskNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfLegalTaskNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfLooseleafChapterNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfLooseleafChapterNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfLooseleafChapterNestedFilter>>>;
  chapterNumber: InputMaybe<Scalars['String']['input']>;
  chapterNumber_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterNumber_not: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterRunningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterTitle: InputMaybe<Scalars['String']['input']>;
  chapterTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  forms_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  uploadWord_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume: InputMaybe<Scalars['Int']['input']>;
  volume_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume_gt: InputMaybe<Scalars['Int']['input']>;
  volume_gte: InputMaybe<Scalars['Int']['input']>;
  volume_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  volume_lt: InputMaybe<Scalars['Int']['input']>;
  volume_lte: InputMaybe<Scalars['Int']['input']>;
  volume_not: InputMaybe<Scalars['Int']['input']>;
  volume_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContentfulCfLooseleafEndMatterNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfLooseleafEndMatterNestedFilter>>
  >;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfLooseleafEndMatterNestedFilter>>>;
  cIndex_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatterType: InputMaybe<Scalars['String']['input']>;
  endMatterType_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  endMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endMatterType_not: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfLooseleafFrontMatterNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfLooseleafFrontMatterNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfLooseleafFrontMatterNestedFilter>>
  >;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  frontMatterType: InputMaybe<Scalars['String']['input']>;
  frontMatterType_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  frontMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frontMatterType_not: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfPlanOfferingOptionNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPlanOfferingOptionNestedFilter>>
  >;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfPlanOfferingOptionNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayBestValueStyle: InputMaybe<Scalars['Boolean']['input']>;
  displayBestValueStyle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayBestValueStyle_not: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink_not: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsContents_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsContents_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsContents_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsLinkText_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsLinkText_not: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsPopupHeader: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsPopupHeader_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsPopupHeader_not: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  longDescription_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  optionLogo_exists: InputMaybe<Scalars['Boolean']['input']>;
  planCode: InputMaybe<Scalars['String']['input']>;
  planCode_contains: InputMaybe<Scalars['String']['input']>;
  planCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  planCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planCode_not: InputMaybe<Scalars['String']['input']>;
  planCode_not_contains: InputMaybe<Scalars['String']['input']>;
  planCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCodes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfPracticeAreaNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfPracticeAreaNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfPracticeAreaNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceAreaSubtopicsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfPractitionerWorkflowSectionGroupNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPractitionerWorkflowSectionGroupNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfPractitionerWorkflowSectionGroupNestedFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  sectionItemsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sectionName: InputMaybe<Scalars['String']['input']>;
  sectionName_contains: InputMaybe<Scalars['String']['input']>;
  sectionName_exists: InputMaybe<Scalars['Boolean']['input']>;
  sectionName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionName_not: InputMaybe<Scalars['String']['input']>;
  sectionName_not_contains: InputMaybe<Scalars['String']['input']>;
  sectionName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  timeframe: InputMaybe<Scalars['String']['input']>;
  timeframe_contains: InputMaybe<Scalars['String']['input']>;
  timeframe_exists: InputMaybe<Scalars['Boolean']['input']>;
  timeframe_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeframe_not: InputMaybe<Scalars['String']['input']>;
  timeframe_not_contains: InputMaybe<Scalars['String']['input']>;
  timeframe_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfPractitionerWorkflowSectionItemsNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPractitionerWorkflowSectionItemsNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfPractitionerWorkflowSectionItemsNestedFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practitionerDocumentsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfPublicationBookNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfPublicationBookNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfPublicationBookNestedFilter>>>;
  additionalPracticeAreaLibrariesCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  archivedEditionsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  live: InputMaybe<Scalars['Boolean']['input']>;
  liveEditionsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  live_exists: InputMaybe<Scalars['Boolean']['input']>;
  live_not: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onlawProProductCode: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_contains: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  onlawProProductCode_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProProductCode_not: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_not_contains: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProductCode: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_contains: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  onlawProductCode_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProductCode_not: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_not_contains: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  primaryPracticeAreaLibrary_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationResourceType_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortName: InputMaybe<Scalars['String']['input']>;
  shortName_contains: InputMaybe<Scalars['String']['input']>;
  shortName_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shortName_not: InputMaybe<Scalars['String']['input']>;
  shortName_not_contains: InputMaybe<Scalars['String']['input']>;
  shortName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  titleId: InputMaybe<Scalars['String']['input']>;
  titleId_contains: InputMaybe<Scalars['String']['input']>;
  titleId_exists: InputMaybe<Scalars['Boolean']['input']>;
  titleId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleId_not: InputMaybe<Scalars['String']['input']>;
  titleId_not_contains: InputMaybe<Scalars['String']['input']>;
  titleId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfPublicationBookTypeNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPublicationBookTypeNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfPublicationBookTypeNestedFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCfPublicationEditionChapterNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPublicationEditionChapterNestedFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulCfPublicationEditionChapterNestedFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  updateAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulCfPublicationEditionNestedFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulCfPublicationEditionNestedFilter>>
  >;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfPublicationEditionNestedFilter>>>;
  chaptersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  edition: InputMaybe<Scalars['String']['input']>;
  edition_contains: InputMaybe<Scalars['String']['input']>;
  edition_exists: InputMaybe<Scalars['Boolean']['input']>;
  edition_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  edition_not: InputMaybe<Scalars['String']['input']>;
  edition_not_contains: InputMaybe<Scalars['String']['input']>;
  edition_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  issueId: InputMaybe<Scalars['String']['input']>;
  issueId_contains: InputMaybe<Scalars['String']['input']>;
  issueId_exists: InputMaybe<Scalars['Boolean']['input']>;
  issueId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  issueId_not: InputMaybe<Scalars['String']['input']>;
  issueId_not_contains: InputMaybe<Scalars['String']['input']>;
  issueId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publicationDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publication_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  updateAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulCfTopicNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfTopicNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfTopicNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCfTrueDocsAndFormsNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCfTrueDocsAndFormsNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCfTrueDocsAndFormsNestedFilter>>>;
  additionalSearchTerms: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_exists: InputMaybe<Scalars['Boolean']['input']>;
  additionalSearchTerms_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  additionalSearchTerms_not: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cebGuidance_contains: InputMaybe<Scalars['String']['input']>;
  cebGuidance_exists: InputMaybe<Scalars['Boolean']['input']>;
  cebGuidance_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  countyCourt_exists: InputMaybe<Scalars['Boolean']['input']>;
  docspringTemplateId: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_contains: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_exists: InputMaybe<Scalars['Boolean']['input']>;
  docspringTemplateId_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  docspringTemplateId_not: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_not_contains: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentTemplate_exists: InputMaybe<Scalars['Boolean']['input']>;
  enabledEnvironments_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_exists: InputMaybe<Scalars['Boolean']['input']>;
  fidoForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber: InputMaybe<Scalars['String']['input']>;
  formNumber_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber_not: InputMaybe<Scalars['String']['input']>;
  formNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mandatoryJcForm: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryJcForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryJcForm_not: InputMaybe<Scalars['Boolean']['input']>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportedMatterTypes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueFormSubtype: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_contains: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_exists: InputMaybe<Scalars['Boolean']['input']>;
  trueFormSubtype_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueFormSubtype_not: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_not_contains: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulChapter';
    _id: Scalars['ID']['output'];
    chapterCollection: Maybe<ContentfulAssetCollection>;
    chapterNumber: Maybe<Scalars['String']['output']>;
    chapterRunningHeadTitle: Maybe<Scalars['String']['output']>;
    chapterTitle: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    forms: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulChapterLinkingCollections>;
    sys: ContentfulSys;
    volume: Maybe<Scalars['Int']['output']>;
  };

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterChapterNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterChapterRunningHeadTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterChapterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterFormsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Generic chapter with multiple types [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/chapter) */
export type ContentfulChapterVolumeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulChapterCollection = {
  __typename?: 'ContentfulChapterCollection';
  items: Array<Maybe<ContentfulChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulChapterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulChapterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulChapterFilter>>>;
  chapterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber: InputMaybe<Scalars['String']['input']>;
  chapterNumber_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterNumber_not: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterRunningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterTitle: InputMaybe<Scalars['String']['input']>;
  chapterTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  forms_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  volume: InputMaybe<Scalars['Int']['input']>;
  volume_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume_gt: InputMaybe<Scalars['Int']['input']>;
  volume_gte: InputMaybe<Scalars['Int']['input']>;
  volume_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  volume_lt: InputMaybe<Scalars['Int']['input']>;
  volume_lte: InputMaybe<Scalars['Int']['input']>;
  volume_not: InputMaybe<Scalars['Int']['input']>;
  volume_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContentfulChapterLinkingCollections = {
  __typename?: 'ContentfulChapterLinkingCollections';
  bookCollection: Maybe<ContentfulBookCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulChapterLinkingCollectionsBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulChapterLinkingCollectionsBookCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulChapterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulChapterLinkingCollectionsBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  PublicationTypeAsc = 'publicationType_ASC',
  PublicationTypeDesc = 'publicationType_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulChapterOrder {
  ChapterNumberAsc = 'chapterNumber_ASC',
  ChapterNumberDesc = 'chapterNumber_DESC',
  ChapterRunningHeadTitleAsc = 'chapterRunningHeadTitle_ASC',
  ChapterRunningHeadTitleDesc = 'chapterRunningHeadTitle_DESC',
  ChapterTitleAsc = 'chapterTitle_ASC',
  ChapterTitleDesc = 'chapterTitle_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VolumeAsc = 'volume_ASC',
  VolumeDesc = 'volume_DESC',
}

/** Used to identify 3rd-party content sources for Daily News and Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contentPublisher) */
export type ContentfulContentPublisher = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulContentPublisher';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulContentPublisherLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** Used to identify 3rd-party content sources for Daily News and Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contentPublisher) */
export type ContentfulContentPublisherLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Used to identify 3rd-party content sources for Daily News and Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contentPublisher) */
export type ContentfulContentPublisherNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Used to identify 3rd-party content sources for Daily News and Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contentPublisher) */
export type ContentfulContentPublisherSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulContentPublisherCollection = {
  __typename?: 'ContentfulContentPublisherCollection';
  items: Array<Maybe<ContentfulContentPublisher>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulContentPublisherFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulContentPublisherFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulContentPublisherFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulContentPublisherLinkingCollections = {
  __typename?: 'ContentfulContentPublisherLinkingCollections';
  courseCollection: Maybe<ContentfulCourseCollection>;
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulContentPublisherLinkingCollectionsCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulContentPublisherLinkingCollectionsCourseCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulContentPublisherLinkingCollectionsCurrentAwarenessCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContentPublisherLinkingCollectionsCurrentAwarenessCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContentPublisherLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulContentPublisherLinkingCollectionsCourseCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export enum ContentfulContentPublisherLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulContentPublisherOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulContentfulMetadata = {
  __typename?: 'ContentfulContentfulMetadata';
  concepts: Array<Maybe<ContentfulTaxonomyConcept>>;
  tags: Array<Maybe<ContentfulContentfulTag>>;
};

export type ContentfulContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulContentfulMetadataConceptsFilter = {
  descendants: InputMaybe<ContentfulContentfulMetadataConceptsDescendantsFilter>;
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulContentfulMetadataFilter = {
  concepts: InputMaybe<ContentfulContentfulMetadataConceptsFilter>;
  concepts_exists: InputMaybe<Scalars['Boolean']['input']>;
  tags: InputMaybe<ContentfulContentfulMetadataTagsFilter>;
  tags_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulContentfulMetadataTagsFilter = {
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulContentfulTag = {
  __typename?: 'ContentfulContentfulTag';
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributor = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulContributor';
    _id: Scalars['ID']['output'];
    additionalSearchTerms: Maybe<Scalars['String']['output']>;
    bio: Maybe<ContentfulContributorBio>;
    city: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    contributorOrganization: Maybe<ContentfulContributorOrganization>;
    counties: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    displayInContributorDirectory: Maybe<Scalars['Boolean']['output']>;
    email: Maybe<Scalars['String']['output']>;
    firstName: Maybe<Scalars['String']['output']>;
    headshot: Maybe<ContentfulAsset>;
    judge: Maybe<Scalars['String']['output']>;
    lastName: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulContributorLinkingCollections>;
    linkedInUrl: Maybe<Scalars['String']['output']>;
    phoneNumber: Maybe<Scalars['String']['output']>;
    practiceAreasCollection: Maybe<ContentfulContributorPracticeAreasCollection>;
    region: Maybe<Scalars['String']['output']>;
    salesforceContactLink: Maybe<Scalars['String']['output']>;
    shortBio: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    state: Maybe<Scalars['String']['output']>;
    streetAddress: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    webDisplayName: Maybe<Scalars['String']['output']>;
    website: Maybe<Scalars['String']['output']>;
    zipCode: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorAdditionalSearchTermsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorBioArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorCityArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorContributorOrganizationArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulContributorOrganizationFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorCountiesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorDisplayInContributorDirectoryArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorEmailArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorFirstNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorHeadshotArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorJudgeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorLastNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorLinkedInUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorPhoneNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulContributorPracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorRegionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorSalesforceContactLinkArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorShortBioArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorStateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorStreetAddressArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorWebDisplayNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorWebsiteArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributor) */
export type ContentfulContributorZipCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulContributorBio = {
  __typename?: 'ContentfulContributorBio';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulContributorBioLinks;
};

export type ContentfulContributorBioAssets = {
  __typename?: 'ContentfulContributorBioAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulContributorBioEntries = {
  __typename?: 'ContentfulContributorBioEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulContributorBioLinks = {
  __typename?: 'ContentfulContributorBioLinks';
  assets: ContentfulContributorBioAssets;
  entries: ContentfulContributorBioEntries;
  resources: ContentfulContributorBioResources;
};

export type ContentfulContributorBioResources = {
  __typename?: 'ContentfulContributorBioResources';
  block: Array<ContentfulContributorBioResourcesBlock>;
  hyperlink: Array<ContentfulContributorBioResourcesHyperlink>;
  inline: Array<ContentfulContributorBioResourcesInline>;
};

export type ContentfulContributorBioResourcesBlock = ContentfulResourceLink & {
  __typename?: 'ContentfulContributorBioResourcesBlock';
  sys: ContentfulResourceSys;
};

export type ContentfulContributorBioResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulContributorBioResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulContributorBioResourcesInline = ContentfulResourceLink & {
  __typename?: 'ContentfulContributorBioResourcesInline';
  sys: ContentfulResourceSys;
};

export type ContentfulContributorCollection = {
  __typename?: 'ContentfulContributorCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorDirectory) */
export type ContentfulContributorDirectory = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulContributorDirectory';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    entryName: Maybe<Scalars['String']['output']>;
    featuredContributorsCollection: Maybe<ContentfulContributorDirectoryFeaturedContributorsCollection>;
    linkedFrom: Maybe<ContentfulContributorDirectoryLinkingCollections>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorDirectory) */
export type ContentfulContributorDirectoryEntryNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorDirectory) */
export type ContentfulContributorDirectoryFeaturedContributorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulContributorDirectoryFeaturedContributorsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorDirectory) */
export type ContentfulContributorDirectoryLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulContributorDirectoryCollection = {
  __typename?: 'ContentfulContributorDirectoryCollection';
  items: Array<Maybe<ContentfulContributorDirectory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulContributorDirectoryFeaturedContributorsCollection = {
  __typename?: 'ContentfulContributorDirectoryFeaturedContributorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulContributorDirectoryFeaturedContributorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulContributorDirectoryFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulContributorDirectoryFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulContributorDirectoryFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  entryName: InputMaybe<Scalars['String']['input']>;
  entryName_contains: InputMaybe<Scalars['String']['input']>;
  entryName_exists: InputMaybe<Scalars['Boolean']['input']>;
  entryName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entryName_not: InputMaybe<Scalars['String']['input']>;
  entryName_not_contains: InputMaybe<Scalars['String']['input']>;
  entryName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  featuredContributors: InputMaybe<ContentfulCfContributorNestedFilter>;
  featuredContributorsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulContributorDirectoryLinkingCollections = {
  __typename?: 'ContentfulContributorDirectoryLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulContributorDirectoryLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulContributorDirectoryOrder {
  EntryNameAsc = 'entryName_ASC',
  EntryNameDesc = 'entryName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulContributorFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulContributorFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulContributorFilter>>>;
  additionalSearchTerms: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_exists: InputMaybe<Scalars['Boolean']['input']>;
  additionalSearchTerms_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  additionalSearchTerms_not: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bio_contains: InputMaybe<Scalars['String']['input']>;
  bio_exists: InputMaybe<Scalars['Boolean']['input']>;
  bio_not_contains: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  city_contains: InputMaybe<Scalars['String']['input']>;
  city_exists: InputMaybe<Scalars['Boolean']['input']>;
  city_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_not: InputMaybe<Scalars['String']['input']>;
  city_not_contains: InputMaybe<Scalars['String']['input']>;
  city_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  contributorOrganization: InputMaybe<ContentfulCfContributorOrganizationNestedFilter>;
  contributorOrganization_exists: InputMaybe<Scalars['Boolean']['input']>;
  counties_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  counties_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayInContributorDirectory_not: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  email_contains: InputMaybe<Scalars['String']['input']>;
  email_exists: InputMaybe<Scalars['Boolean']['input']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_not: InputMaybe<Scalars['String']['input']>;
  email_not_contains: InputMaybe<Scalars['String']['input']>;
  email_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: InputMaybe<Scalars['String']['input']>;
  firstName_contains: InputMaybe<Scalars['String']['input']>;
  firstName_exists: InputMaybe<Scalars['Boolean']['input']>;
  firstName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_not: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains: InputMaybe<Scalars['String']['input']>;
  firstName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headshot_exists: InputMaybe<Scalars['Boolean']['input']>;
  judge: InputMaybe<Scalars['String']['input']>;
  judge_contains: InputMaybe<Scalars['String']['input']>;
  judge_exists: InputMaybe<Scalars['Boolean']['input']>;
  judge_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  judge_not: InputMaybe<Scalars['String']['input']>;
  judge_not_contains: InputMaybe<Scalars['String']['input']>;
  judge_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName: InputMaybe<Scalars['String']['input']>;
  lastName_contains: InputMaybe<Scalars['String']['input']>;
  lastName_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_not: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains: InputMaybe<Scalars['String']['input']>;
  lastName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkedInUrl: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_contains: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  linkedInUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkedInUrl_not: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  linkedInUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  phoneNumber_contains: InputMaybe<Scalars['String']['input']>;
  phoneNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber_not: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
  region_contains: InputMaybe<Scalars['String']['input']>;
  region_exists: InputMaybe<Scalars['Boolean']['input']>;
  region_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region_not: InputMaybe<Scalars['String']['input']>;
  region_not_contains: InputMaybe<Scalars['String']['input']>;
  region_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforceContactLink: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_contains: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceContactLink_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceContactLink_not: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceContactLink_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortBio: InputMaybe<Scalars['String']['input']>;
  shortBio_contains: InputMaybe<Scalars['String']['input']>;
  shortBio_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortBio_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shortBio_not: InputMaybe<Scalars['String']['input']>;
  shortBio_not_contains: InputMaybe<Scalars['String']['input']>;
  shortBio_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  state: InputMaybe<Scalars['String']['input']>;
  state_contains: InputMaybe<Scalars['String']['input']>;
  state_exists: InputMaybe<Scalars['Boolean']['input']>;
  state_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  state_not: InputMaybe<Scalars['String']['input']>;
  state_not_contains: InputMaybe<Scalars['String']['input']>;
  state_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddress_contains: InputMaybe<Scalars['String']['input']>;
  streetAddress_exists: InputMaybe<Scalars['Boolean']['input']>;
  streetAddress_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  streetAddress_not: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_contains: InputMaybe<Scalars['String']['input']>;
  streetAddress_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webDisplayName: InputMaybe<Scalars['String']['input']>;
  webDisplayName_contains: InputMaybe<Scalars['String']['input']>;
  webDisplayName_exists: InputMaybe<Scalars['Boolean']['input']>;
  webDisplayName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webDisplayName_not: InputMaybe<Scalars['String']['input']>;
  webDisplayName_not_contains: InputMaybe<Scalars['String']['input']>;
  webDisplayName_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode: InputMaybe<Scalars['String']['input']>;
  zipCode_contains: InputMaybe<Scalars['String']['input']>;
  zipCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  zipCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode_not: InputMaybe<Scalars['String']['input']>;
  zipCode_not_contains: InputMaybe<Scalars['String']['input']>;
  zipCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulContributorLinkingCollections = {
  __typename?: 'ContentfulContributorLinkingCollections';
  contributorDirectoryCollection: Maybe<ContentfulContributorDirectoryCollection>;
  courseCollection: Maybe<ContentfulCourseCollection>;
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  programCollection: Maybe<ContentfulProgramCollection>;
  publicationEditionChapterCollection: Maybe<ContentfulPublicationEditionChapterCollection>;
  publicationEditionCollection: Maybe<ContentfulPublicationEditionCollection>;
};

export type ContentfulContributorLinkingCollectionsContributorDirectoryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorLinkingCollectionsContributorDirectoryCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContributorLinkingCollectionsCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulContributorLinkingCollectionsCourseCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulContributorLinkingCollectionsCurrentAwarenessCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorLinkingCollectionsCurrentAwarenessCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContributorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulContributorLinkingCollectionsKnowHowDocumentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorLinkingCollectionsKnowHowDocumentCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContributorLinkingCollectionsProgramCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulContributorLinkingCollectionsProgramCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulContributorLinkingCollectionsPublicationEditionChapterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorLinkingCollectionsPublicationEditionChapterCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContributorLinkingCollectionsPublicationEditionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorLinkingCollectionsPublicationEditionCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulContributorLinkingCollectionsContributorDirectoryCollectionOrder {
  EntryNameAsc = 'entryName_ASC',
  EntryNameDesc = 'entryName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulContributorLinkingCollectionsCourseCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export enum ContentfulContributorLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulContributorLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulContributorLinkingCollectionsProgramCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  LitmosIdAsc = 'litmosId_ASC',
  LitmosIdDesc = 'litmosId_DESC',
  LmsUrlAsc = 'lmsUrl_ASC',
  LmsUrlDesc = 'lmsUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProgramCodeAsc = 'programCode_ASC',
  ProgramCodeDesc = 'programCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalMcleCreditsAsc = 'totalMcleCredits_ASC',
  TotalMcleCreditsDesc = 'totalMcleCredits_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
}

export enum ContentfulContributorLinkingCollectionsPublicationEditionChapterCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulContributorLinkingCollectionsPublicationEditionCollectionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulContributorOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorOrganization) */
export type ContentfulContributorOrganization = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulContributorOrganization';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulContributorOrganizationLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    website: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorOrganization) */
export type ContentfulContributorOrganizationLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorOrganization) */
export type ContentfulContributorOrganizationNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorOrganization) */
export type ContentfulContributorOrganizationSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/contributorOrganization) */
export type ContentfulContributorOrganizationWebsiteArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulContributorOrganizationCollection = {
  __typename?: 'ContentfulContributorOrganizationCollection';
  items: Array<Maybe<ContentfulContributorOrganization>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulContributorOrganizationFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulContributorOrganizationFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulContributorOrganizationFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  website: InputMaybe<Scalars['String']['input']>;
  website_contains: InputMaybe<Scalars['String']['input']>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_not: InputMaybe<Scalars['String']['input']>;
  website_not_contains: InputMaybe<Scalars['String']['input']>;
  website_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulContributorOrganizationLinkingCollections = {
  __typename?: 'ContentfulContributorOrganizationLinkingCollections';
  contributorCollection: Maybe<ContentfulContributorCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulContributorOrganizationLinkingCollectionsContributorCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulContributorOrganizationLinkingCollectionsContributorCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulContributorOrganizationLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulContributorOrganizationLinkingCollectionsContributorCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export enum ContentfulContributorOrganizationOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export type ContentfulContributorPracticeAreasCollection = {
  __typename?: 'ContentfulContributorPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulContributorPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourse = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulCourse';
    _id: Scalars['ID']['output'];
    active: Maybe<Scalars['Boolean']['output']>;
    cebCoordinator: Maybe<ContentfulContributor>;
    contentfulMetadata: ContentfulContentfulMetadata;
    courseMaterials: Maybe<ContentfulAsset>;
    dateRecorded: Maybe<Scalars['ContentfulDateTime']['output']>;
    difficultyLevel: Maybe<Scalars['String']['output']>;
    featureImage: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulCourseLinkingCollections>;
    longDescription: Maybe<ContentfulCourseLongDescription>;
    panoptoId: Maybe<Scalars['String']['output']>;
    participatoryCreditCode: Maybe<Scalars['Int']['output']>;
    practiceAreasCollection: Maybe<ContentfulCoursePracticeAreasCollection>;
    publisher: Maybe<ContentfulContentPublisher>;
    salesforceId: Maybe<Scalars['String']['output']>;
    salesforceStagingId: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    speakersCollection: Maybe<ContentfulCourseSpeakersCollection>;
    specialRequirementsCollection: Maybe<ContentfulCourseSpecialRequirementsCollection>;
    specialRequirementsCredits: Maybe<Scalars['ContentfulJSON']['output']>;
    specializationCredits: Maybe<Scalars['ContentfulJSON']['output']>;
    specializationsCollection: Maybe<ContentfulCourseSpecializationsCollection>;
    stateBarCourseFormatCode: Maybe<Scalars['Int']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    totalCredits: Maybe<Scalars['Float']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseActiveArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseCebCoordinatorArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseCourseMaterialsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseDateRecordedArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseDifficultyLevelArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseFeatureImageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseLongDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCoursePanoptoIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseParticipatoryCreditCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCoursePracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCoursePracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCoursePublisherArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulContentPublisherFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSalesforceIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSalesforceStagingIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSpeakersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCourseSpeakersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSpecialRequirementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCourseSpecialRequirementsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseCreditCategoryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSpecialRequirementsCreditsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSpecializationCreditsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseSpecializationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCourseSpecializationsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseCreditCategoryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseStateBarCourseFormatCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/course) */
export type ContentfulCourseTotalCreditsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulCourseCollection = {
  __typename?: 'ContentfulCourseCollection';
  items: Array<Maybe<ContentfulCourse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategory = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulCourseCreditCategory';
    _id: Scalars['ID']['output'];
    childCategoriesCollection: Maybe<ContentfulCourseCreditCategoryChildCategoriesCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulCourseCreditCategoryLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    type: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategoryChildCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulCourseCreditCategoryChildCategoriesCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseCreditCategoryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategoryLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategoryNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategorySlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/courseCreditCategory) */
export type ContentfulCourseCreditCategoryTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulCourseCreditCategoryChildCategoriesCollection = {
  __typename?: 'ContentfulCourseCreditCategoryChildCategoriesCollection';
  items: Array<Maybe<ContentfulCourseCreditCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCourseCreditCategoryChildCategoriesCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulCourseCreditCategoryCollection = {
  __typename?: 'ContentfulCourseCreditCategoryCollection';
  items: Array<Maybe<ContentfulCourseCreditCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulCourseCreditCategoryFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCourseCreditCategoryFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCourseCreditCategoryFilter>>>;
  childCategories: InputMaybe<ContentfulCfCourseCreditCategoryNestedFilter>;
  childCategoriesCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCourseCreditCategoryLinkingCollections = {
  __typename?: 'ContentfulCourseCreditCategoryLinkingCollections';
  courseCollection: Maybe<ContentfulCourseCollection>;
  courseCreditCategoryCollection: Maybe<ContentfulCourseCreditCategoryCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCourseCreditCategoryLinkingCollectionsCourseCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulCourseCreditCategoryLinkingCollectionsCourseCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulCourseCreditCategoryLinkingCollectionsCourseCreditCategoryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulCourseCreditCategoryLinkingCollectionsCourseCreditCategoryCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulCourseCreditCategoryLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulCourseCreditCategoryLinkingCollectionsCourseCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export enum ContentfulCourseCreditCategoryLinkingCollectionsCourseCreditCategoryCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ContentfulCourseCreditCategoryOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulCourseFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCourseFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCourseFilter>>>;
  active: InputMaybe<Scalars['Boolean']['input']>;
  active_exists: InputMaybe<Scalars['Boolean']['input']>;
  active_not: InputMaybe<Scalars['Boolean']['input']>;
  cebCoordinator: InputMaybe<ContentfulCfContributorNestedFilter>;
  cebCoordinator_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  courseMaterials_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRecorded_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  difficultyLevel: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_contains: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_exists: InputMaybe<Scalars['Boolean']['input']>;
  difficultyLevel_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficultyLevel_not: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_not_contains: InputMaybe<Scalars['String']['input']>;
  difficultyLevel_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId: InputMaybe<Scalars['String']['input']>;
  panoptoId_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId_exists: InputMaybe<Scalars['Boolean']['input']>;
  panoptoId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  panoptoId_not: InputMaybe<Scalars['String']['input']>;
  panoptoId_not_contains: InputMaybe<Scalars['String']['input']>;
  panoptoId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  participatoryCreditCode: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  participatoryCreditCode_gt: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_gte: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  participatoryCreditCode_lt: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_lte: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_not: InputMaybe<Scalars['Int']['input']>;
  participatoryCreditCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publisher: InputMaybe<ContentfulCfContentPublisherNestedFilter>;
  publisher_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId: InputMaybe<Scalars['String']['input']>;
  salesforceId_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforceId_not: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceStagingId: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_contains: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceStagingId_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  salesforceStagingId_not: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceStagingId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  speakers: InputMaybe<ContentfulCfContributorNestedFilter>;
  speakersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  specialRequirements: InputMaybe<ContentfulCfCourseCreditCategoryNestedFilter>;
  specialRequirementsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  specialRequirementsCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  specializationCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  specializations: InputMaybe<ContentfulCfCourseCreditCategoryNestedFilter>;
  specializationsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  stateBarCourseFormatCode: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  stateBarCourseFormatCode_gt: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_gte: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  stateBarCourseFormatCode_lt: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_lte: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_not: InputMaybe<Scalars['Int']['input']>;
  stateBarCourseFormatCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  totalCredits: InputMaybe<Scalars['Float']['input']>;
  totalCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  totalCredits_gt: InputMaybe<Scalars['Float']['input']>;
  totalCredits_gte: InputMaybe<Scalars['Float']['input']>;
  totalCredits_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalCredits_lt: InputMaybe<Scalars['Float']['input']>;
  totalCredits_lte: InputMaybe<Scalars['Float']['input']>;
  totalCredits_not: InputMaybe<Scalars['Float']['input']>;
  totalCredits_not_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type ContentfulCourseLinkingCollections = {
  __typename?: 'ContentfulCourseLinkingCollections';
  coursesCollectionCollection: Maybe<ContentfulCoursesCollectionCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCourseLinkingCollectionsCoursesCollectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulCourseLinkingCollectionsCoursesCollectionCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulCourseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulCourseLinkingCollectionsCoursesCollectionCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CompliancePackageProductCodeAsc = 'compliancePackageProductCode_ASC',
  CompliancePackageProductCodeDesc = 'compliancePackageProductCode_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulCourseLongDescription = {
  __typename?: 'ContentfulCourseLongDescription';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulCourseLongDescriptionLinks;
};

export type ContentfulCourseLongDescriptionAssets = {
  __typename?: 'ContentfulCourseLongDescriptionAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCourseLongDescriptionEntries = {
  __typename?: 'ContentfulCourseLongDescriptionEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCourseLongDescriptionLinks = {
  __typename?: 'ContentfulCourseLongDescriptionLinks';
  assets: ContentfulCourseLongDescriptionAssets;
  entries: ContentfulCourseLongDescriptionEntries;
  resources: ContentfulCourseLongDescriptionResources;
};

export type ContentfulCourseLongDescriptionResources = {
  __typename?: 'ContentfulCourseLongDescriptionResources';
  block: Array<ContentfulCourseLongDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulCourseLongDescriptionResourcesHyperlink>;
  inline: Array<ContentfulCourseLongDescriptionResourcesInline>;
};

export type ContentfulCourseLongDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCourseLongDescriptionResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulCourseLongDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCourseLongDescriptionResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulCourseLongDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCourseLongDescriptionResourcesInline';
    sys: ContentfulResourceSys;
  };

export enum ContentfulCourseOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export type ContentfulCoursePracticeAreasCollection = {
  __typename?: 'ContentfulCoursePracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCoursePracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulCourseSpeakersCollection = {
  __typename?: 'ContentfulCourseSpeakersCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCourseSpeakersCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulCourseSpecialRequirementsCollection = {
  __typename?: 'ContentfulCourseSpecialRequirementsCollection';
  items: Array<Maybe<ContentfulCourseCreditCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCourseSpecialRequirementsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulCourseSpecializationsCollection = {
  __typename?: 'ContentfulCourseSpecializationsCollection';
  items: Array<Maybe<ContentfulCourseCreditCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCourseSpecializationsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollection = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulCoursesCollection';
    _id: Scalars['ID']['output'];
    active: Maybe<Scalars['Boolean']['output']>;
    collectionImage: Maybe<ContentfulAsset>;
    collectionMaterials: Maybe<ContentfulAsset>;
    compliancePackageProductCode: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    coursesCollection: Maybe<ContentfulCoursesCollectionCoursesCollection>;
    description: Maybe<ContentfulCoursesCollectionDescription>;
    linkedFrom: Maybe<ContentfulCoursesCollectionLinkingCollections>;
    practiceAreasCollection: Maybe<ContentfulCoursesCollectionPracticeAreasCollection>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionActiveArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionCollectionImageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionCollectionMaterialsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionCompliancePackageProductCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionCoursesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCoursesCollectionCoursesCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCoursesCollectionPracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/coursesCollection) */
export type ContentfulCoursesCollectionTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulCoursesCollectionCollection = {
  __typename?: 'ContentfulCoursesCollectionCollection';
  items: Array<Maybe<ContentfulCoursesCollection>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulCoursesCollectionCoursesCollection = {
  __typename?: 'ContentfulCoursesCollectionCoursesCollection';
  items: Array<Maybe<ContentfulCourse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCoursesCollectionCoursesCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export type ContentfulCoursesCollectionDescription = {
  __typename?: 'ContentfulCoursesCollectionDescription';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulCoursesCollectionDescriptionLinks;
};

export type ContentfulCoursesCollectionDescriptionAssets = {
  __typename?: 'ContentfulCoursesCollectionDescriptionAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCoursesCollectionDescriptionEntries = {
  __typename?: 'ContentfulCoursesCollectionDescriptionEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCoursesCollectionDescriptionLinks = {
  __typename?: 'ContentfulCoursesCollectionDescriptionLinks';
  assets: ContentfulCoursesCollectionDescriptionAssets;
  entries: ContentfulCoursesCollectionDescriptionEntries;
  resources: ContentfulCoursesCollectionDescriptionResources;
};

export type ContentfulCoursesCollectionDescriptionResources = {
  __typename?: 'ContentfulCoursesCollectionDescriptionResources';
  block: Array<ContentfulCoursesCollectionDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulCoursesCollectionDescriptionResourcesHyperlink>;
  inline: Array<ContentfulCoursesCollectionDescriptionResourcesInline>;
};

export type ContentfulCoursesCollectionDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCoursesCollectionDescriptionResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulCoursesCollectionDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCoursesCollectionDescriptionResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulCoursesCollectionDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCoursesCollectionDescriptionResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulCoursesCollectionFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCoursesCollectionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCoursesCollectionFilter>>>;
  active: InputMaybe<Scalars['Boolean']['input']>;
  active_exists: InputMaybe<Scalars['Boolean']['input']>;
  active_not: InputMaybe<Scalars['Boolean']['input']>;
  collectionImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  collectionMaterials_exists: InputMaybe<Scalars['Boolean']['input']>;
  compliancePackageProductCode: InputMaybe<Scalars['String']['input']>;
  compliancePackageProductCode_contains: InputMaybe<Scalars['String']['input']>;
  compliancePackageProductCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  compliancePackageProductCode_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  compliancePackageProductCode_not: InputMaybe<Scalars['String']['input']>;
  compliancePackageProductCode_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  compliancePackageProductCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  courses: InputMaybe<ContentfulCfCourseNestedFilter>;
  coursesCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCoursesCollectionLinkingCollections = {
  __typename?: 'ContentfulCoursesCollectionLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCoursesCollectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulCoursesCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CompliancePackageProductCodeAsc = 'compliancePackageProductCode_ASC',
  CompliancePackageProductCodeDesc = 'compliancePackageProductCode_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulCoursesCollectionPracticeAreasCollection = {
  __typename?: 'ContentfulCoursesCollectionPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCoursesCollectionPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/court) */
export type ContentfulCourt = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulCourt';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulCourtLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    type: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/court) */
export type ContentfulCourtLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/court) */
export type ContentfulCourtNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/court) */
export type ContentfulCourtSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/court) */
export type ContentfulCourtTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulCourtCollection = {
  __typename?: 'ContentfulCourtCollection';
  items: Array<Maybe<ContentfulCourt>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulCourtFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCourtFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCourtFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulCourtLinkingCollections = {
  __typename?: 'ContentfulCourtLinkingCollections';
  agencyCollection: Maybe<ContentfulAgencyCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  trueDocsAndFormsCollection: Maybe<ContentfulTrueDocsAndFormsCollection>;
  trueDocsFormsCollectionsCollection: Maybe<ContentfulTrueDocsFormsCollectionsCollection>;
};

export type ContentfulCourtLinkingCollectionsAgencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCourtLinkingCollectionsAgencyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulCourtLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulCourtLinkingCollectionsKnowHowDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulCourtLinkingCollectionsKnowHowDocumentCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulCourtLinkingCollectionsTrueDocsAndFormsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulCourtLinkingCollectionsTrueDocsAndFormsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulCourtLinkingCollectionsTrueDocsFormsCollectionsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulCourtLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulCourtLinkingCollectionsAgencyCollectionOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AgencyNameAsc = 'agencyName_ASC',
  AgencyNameDesc = 'agencyName_DESC',
  CheckUtilityAsc = 'checkUtility_ASC',
  CheckUtilityDesc = 'checkUtility_DESC',
  ContactPersonAsc = 'contactPerson_ASC',
  ContactPersonDesc = 'contactPerson_DESC',
  DateFlagResetAsc = 'dateFlagReset_ASC',
  DateFlagResetDesc = 'dateFlagReset_DESC',
  FormsIndexPageAsc = 'formsIndexPage_ASC',
  FormsIndexPageDesc = 'formsIndexPage_DESC',
  ImportFormsAsc = 'importForms_ASC',
  ImportFormsDesc = 'importForms_DESC',
  LastFidoFormUpdateAsc = 'lastFidoFormUpdate_ASC',
  LastFidoFormUpdateDesc = 'lastFidoFormUpdate_DESC',
  NameAbbreviationAsc = 'nameAbbreviation_ASC',
  NameAbbreviationDesc = 'nameAbbreviation_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export enum ContentfulCourtLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulCourtLinkingCollectionsTrueDocsAndFormsCollectionOrder {
  DocspringTemplateIdAsc = 'docspringTemplateId_ASC',
  DocspringTemplateIdDesc = 'docspringTemplateId_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  MandatoryJcFormAsc = 'mandatoryJcForm_ASC',
  MandatoryJcFormDesc = 'mandatoryJcForm_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormSubtypeAsc = 'trueFormSubtype_ASC',
  TrueFormSubtypeDesc = 'trueFormSubtype_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulCourtLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulCourtOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwareness = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulCurrentAwareness';
    _id: Scalars['ID']['output'];
    authorsCollection: Maybe<ContentfulCurrentAwarenessAuthorsCollection>;
    content: Maybe<ContentfulCurrentAwarenessContent>;
    contentfulMetadata: ContentfulContentfulMetadata;
    featuredImage: Maybe<ContentfulAsset>;
    featuredImageCaption: Maybe<Scalars['String']['output']>;
    featuredPost: Maybe<Scalars['Boolean']['output']>;
    legalTasksCollection: Maybe<ContentfulCurrentAwarenessLegalTasksCollection>;
    linkedFrom: Maybe<ContentfulCurrentAwarenessLinkingCollections>;
    originalPublishDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    postResourceType: Maybe<Scalars['String']['output']>;
    practiceAreasCollection: Maybe<ContentfulCurrentAwarenessPracticeAreasCollection>;
    publisher: Maybe<ContentfulContentPublisher>;
    relatedContentCollection: Maybe<ContentfulCurrentAwarenessRelatedContentCollection>;
    slug: Maybe<Scalars['String']['output']>;
    subheading: Maybe<Scalars['String']['output']>;
    subtitle: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    topicsCollection: Maybe<ContentfulCurrentAwarenessTopicsCollection>;
    updatedDate: Maybe<Scalars['ContentfulDateTime']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCurrentAwarenessAuthorsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessContentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessFeaturedImageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessFeaturedImageCaptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessFeaturedPostArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessLegalTasksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCurrentAwarenessLegalTasksCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLegalTaskFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessOriginalPublishDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessPostResourceTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCurrentAwarenessPracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessPublisherArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulContentPublisherFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCurrentAwarenessRelatedContentCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCurrentAwarenessFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessSubheadingArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessSubtitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulCurrentAwarenessTopicsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulTopicFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/currentAwareness) */
export type ContentfulCurrentAwarenessUpdatedDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulCurrentAwarenessAuthorsCollection = {
  __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCurrentAwarenessAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulCurrentAwarenessCollection = {
  __typename?: 'ContentfulCurrentAwarenessCollection';
  items: Array<Maybe<ContentfulCurrentAwareness>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulCurrentAwarenessContent = {
  __typename?: 'ContentfulCurrentAwarenessContent';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulCurrentAwarenessContentLinks;
};

export type ContentfulCurrentAwarenessContentAssets = {
  __typename?: 'ContentfulCurrentAwarenessContentAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCurrentAwarenessContentEntries = {
  __typename?: 'ContentfulCurrentAwarenessContentEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCurrentAwarenessContentLinks = {
  __typename?: 'ContentfulCurrentAwarenessContentLinks';
  assets: ContentfulCurrentAwarenessContentAssets;
  entries: ContentfulCurrentAwarenessContentEntries;
  resources: ContentfulCurrentAwarenessContentResources;
};

export type ContentfulCurrentAwarenessContentResources = {
  __typename?: 'ContentfulCurrentAwarenessContentResources';
  block: Array<ContentfulCurrentAwarenessContentResourcesBlock>;
  hyperlink: Array<ContentfulCurrentAwarenessContentResourcesHyperlink>;
  inline: Array<ContentfulCurrentAwarenessContentResourcesInline>;
};

export type ContentfulCurrentAwarenessContentResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCurrentAwarenessContentResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulCurrentAwarenessContentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCurrentAwarenessContentResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulCurrentAwarenessContentResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulCurrentAwarenessContentResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulCurrentAwarenessFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulCurrentAwarenessFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulCurrentAwarenessFilter>>>;
  authors: InputMaybe<ContentfulCfContributorNestedFilter>;
  authorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_contains: InputMaybe<Scalars['String']['input']>;
  content_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  featuredImageCaption: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_contains: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredImageCaption_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuredImageCaption_not: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_not_contains: InputMaybe<Scalars['String']['input']>;
  featuredImageCaption_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuredImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost_exists: InputMaybe<Scalars['Boolean']['input']>;
  featuredPost_not: InputMaybe<Scalars['Boolean']['input']>;
  legalTasks: InputMaybe<ContentfulCfLegalTaskNestedFilter>;
  legalTasksCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  originalPublishDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  postResourceType: InputMaybe<Scalars['String']['input']>;
  postResourceType_contains: InputMaybe<Scalars['String']['input']>;
  postResourceType_exists: InputMaybe<Scalars['Boolean']['input']>;
  postResourceType_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  postResourceType_not: InputMaybe<Scalars['String']['input']>;
  postResourceType_not_contains: InputMaybe<Scalars['String']['input']>;
  postResourceType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publisher: InputMaybe<ContentfulCfContentPublisherNestedFilter>;
  publisher_exists: InputMaybe<Scalars['Boolean']['input']>;
  relatedContent: InputMaybe<ContentfulCfCurrentAwarenessNestedFilter>;
  relatedContentCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading: InputMaybe<Scalars['String']['input']>;
  subheading_contains: InputMaybe<Scalars['String']['input']>;
  subheading_exists: InputMaybe<Scalars['Boolean']['input']>;
  subheading_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_not: InputMaybe<Scalars['String']['input']>;
  subheading_not_contains: InputMaybe<Scalars['String']['input']>;
  subheading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle: InputMaybe<Scalars['String']['input']>;
  subtitle_contains: InputMaybe<Scalars['String']['input']>;
  subtitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_not: InputMaybe<Scalars['String']['input']>;
  subtitle_not_contains: InputMaybe<Scalars['String']['input']>;
  subtitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics: InputMaybe<ContentfulCfTopicNestedFilter>;
  topicsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

export type ContentfulCurrentAwarenessLegalTasksCollection = {
  __typename?: 'ContentfulCurrentAwarenessLegalTasksCollection';
  items: Array<Maybe<ContentfulLegalTask>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCurrentAwarenessLegalTasksCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulCurrentAwarenessLinkingCollections = {
  __typename?: 'ContentfulCurrentAwarenessLinkingCollections';
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCurrentAwarenessLinkingCollectionsCurrentAwarenessCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulCurrentAwarenessLinkingCollectionsCurrentAwarenessCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulCurrentAwarenessLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulCurrentAwarenessLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulCurrentAwarenessOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulCurrentAwarenessPracticeAreasCollection = {
  __typename?: 'ContentfulCurrentAwarenessPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCurrentAwarenessPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulCurrentAwarenessRelatedContentCollection = {
  __typename?: 'ContentfulCurrentAwarenessRelatedContentCollection';
  items: Array<Maybe<ContentfulCurrentAwareness>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCurrentAwarenessRelatedContentCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulCurrentAwarenessTopicsCollection = {
  __typename?: 'ContentfulCurrentAwarenessTopicsCollection';
  items: Array<Maybe<ContentfulTopic>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulCurrentAwarenessTopicsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/documentType) */
export type ContentfulDocumentType = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulDocumentType';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulDocumentTypeLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/documentType) */
export type ContentfulDocumentTypeLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/documentType) */
export type ContentfulDocumentTypeNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulDocumentTypeCollection = {
  __typename?: 'ContentfulDocumentTypeCollection';
  items: Array<Maybe<ContentfulDocumentType>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulDocumentTypeFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulDocumentTypeFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulDocumentTypeFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulDocumentTypeLinkingCollections = {
  __typename?: 'ContentfulDocumentTypeLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  practitionerWorkflowCollection: Maybe<ContentfulPractitionerWorkflowCollection>;
};

export type ContentfulDocumentTypeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulDocumentTypeLinkingCollectionsKnowHowDocumentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulDocumentTypeLinkingCollectionsKnowHowDocumentCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulDocumentTypeLinkingCollectionsPractitionerWorkflowCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulDocumentTypeLinkingCollectionsPractitionerWorkflowCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulDocumentTypeLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulDocumentTypeLinkingCollectionsPractitionerWorkflowCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulDocumentTypeOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulEndMatter';
    _id: Scalars['ID']['output'];
    cIndex: Maybe<ContentfulAsset>;
    chapter: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    endMatterType: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulEndMatterLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterCIndexArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterChapterArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterEndMatterTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/endMatter) */
export type ContentfulEndMatterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulEndMatterCollection = {
  __typename?: 'ContentfulEndMatterCollection';
  items: Array<Maybe<ContentfulEndMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulEndMatterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulEndMatterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulEndMatterFilter>>>;
  cIndex_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatterType: InputMaybe<Scalars['String']['input']>;
  endMatterType_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  endMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endMatterType_not: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulEndMatterLinkingCollections = {
  __typename?: 'ContentfulEndMatterLinkingCollections';
  bookCollection: Maybe<ContentfulBookCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulEndMatterLinkingCollectionsBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulEndMatterLinkingCollectionsBookCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulEndMatterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulEndMatterLinkingCollectionsBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  PublicationTypeAsc = 'publicationType_ASC',
  PublicationTypeDesc = 'publicationType_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulEndMatterOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  EndMatterTypeAsc = 'endMatterType_ASC',
  EndMatterTypeDesc = 'endMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulEntry = {
  contentfulMetadata: ContentfulContentfulMetadata;
  sys: ContentfulSys;
};

export type ContentfulEntryCollection = {
  __typename?: 'ContentfulEntryCollection';
  items: Array<Maybe<ContentfulEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulEntryFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulEntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulForm = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulForm';
    _id: Scalars['ID']['output'];
    adoptedMandatoryAllCourts: Maybe<Scalars['Boolean']['output']>;
    agencyCollection: Maybe<ContentfulFormAgencyCollection>;
    autoProcess: Maybe<Scalars['Boolean']['output']>;
    contentItem: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    dateIssuedByAgency: Maybe<Scalars['ContentfulDateTime']['output']>;
    dateRevised: Maybe<Scalars['ContentfulDateTime']['output']>;
    efPubDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    effectiveDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    essentialFormsProductionStatus: Maybe<Scalars['String']['output']>;
    formName: Maybe<Scalars['String']['output']>;
    formNumber: Maybe<Scalars['String']['output']>;
    formSource: Maybe<ContentfulFormSource>;
    formStatus: Maybe<Scalars['String']['output']>;
    formType: Maybe<Scalars['String']['output']>;
    hash: Maybe<Scalars['String']['output']>;
    imagesCollection: Maybe<ContentfulAssetCollection>;
    jcFormNumber: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulFormLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    neededBy: Maybe<Scalars['ContentfulDateTime']['output']>;
    needsReview: Maybe<Scalars['Boolean']['output']>;
    notes: Maybe<ContentfulFormNotes>;
    pdfMasterFile: Maybe<ContentfulAsset>;
    practiceArea: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    slug: Maybe<Scalars['String']['output']>;
    sourceFileCollection: Maybe<ContentfulAssetCollection>;
    sourceUrl: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    trueForm: Maybe<Scalars['Boolean']['output']>;
    url: Maybe<Scalars['String']['output']>;
  };

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormAdoptedMandatoryAllCourtsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormAgencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFormAgencyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulAgencyFilter>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormAutoProcessArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormContentItemArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormDateIssuedByAgencyArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormDateRevisedArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormEfPubDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormEffectiveDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormEssentialFormsProductionStatusArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormFormNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormFormNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormFormSourceArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulFormSourceFilter>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormFormStatusArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormFormTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormHashArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormJcFormNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormNeededByArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormNeedsReviewArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormNotesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormPdfMasterFileArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormPracticeAreaArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormSourceFileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormSourceUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormTrueFormArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** JCForms, Attorney Drafted Form, Graphic Forms  [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/form) */
export type ContentfulFormUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulFormAgencyCollection = {
  __typename?: 'ContentfulFormAgencyCollection';
  items: Array<Maybe<ContentfulAgency>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulFormAgencyCollectionOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AgencyNameAsc = 'agencyName_ASC',
  AgencyNameDesc = 'agencyName_DESC',
  CheckUtilityAsc = 'checkUtility_ASC',
  CheckUtilityDesc = 'checkUtility_DESC',
  ContactPersonAsc = 'contactPerson_ASC',
  ContactPersonDesc = 'contactPerson_DESC',
  DateFlagResetAsc = 'dateFlagReset_ASC',
  DateFlagResetDesc = 'dateFlagReset_DESC',
  FormsIndexPageAsc = 'formsIndexPage_ASC',
  FormsIndexPageDesc = 'formsIndexPage_DESC',
  ImportFormsAsc = 'importForms_ASC',
  ImportFormsDesc = 'importForms_DESC',
  LastFidoFormUpdateAsc = 'lastFidoFormUpdate_ASC',
  LastFidoFormUpdateDesc = 'lastFidoFormUpdate_DESC',
  NameAbbreviationAsc = 'nameAbbreviation_ASC',
  NameAbbreviationDesc = 'nameAbbreviation_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export type ContentfulFormCollection = {
  __typename?: 'ContentfulFormCollection';
  items: Array<Maybe<ContentfulForm>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainer = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulFormContainer';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    formHtml: Maybe<Scalars['String']['output']>;
    formIntroText: Maybe<ContentfulFormContainerFormIntroText>;
    formPostSubmitText: Maybe<ContentfulFormContainerFormPostSubmitText>;
    formTitle: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulFormContainerLinkingCollections>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerFormHtmlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerFormIntroTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerFormPostSubmitTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerFormTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formContainer) */
export type ContentfulFormContainerSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulFormContainerCollection = {
  __typename?: 'ContentfulFormContainerCollection';
  items: Array<Maybe<ContentfulFormContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulFormContainerFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulFormContainerFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulFormContainerFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  formHtml: InputMaybe<Scalars['String']['input']>;
  formHtml_contains: InputMaybe<Scalars['String']['input']>;
  formHtml_exists: InputMaybe<Scalars['Boolean']['input']>;
  formHtml_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formHtml_not: InputMaybe<Scalars['String']['input']>;
  formHtml_not_contains: InputMaybe<Scalars['String']['input']>;
  formHtml_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formIntroText_contains: InputMaybe<Scalars['String']['input']>;
  formIntroText_exists: InputMaybe<Scalars['Boolean']['input']>;
  formIntroText_not_contains: InputMaybe<Scalars['String']['input']>;
  formPostSubmitText_contains: InputMaybe<Scalars['String']['input']>;
  formPostSubmitText_exists: InputMaybe<Scalars['Boolean']['input']>;
  formPostSubmitText_not_contains: InputMaybe<Scalars['String']['input']>;
  formTitle: InputMaybe<Scalars['String']['input']>;
  formTitle_contains: InputMaybe<Scalars['String']['input']>;
  formTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  formTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formTitle_not: InputMaybe<Scalars['String']['input']>;
  formTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  formTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulFormContainerFormIntroText = {
  __typename?: 'ContentfulFormContainerFormIntroText';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulFormContainerFormIntroTextLinks;
};

export type ContentfulFormContainerFormIntroTextAssets = {
  __typename?: 'ContentfulFormContainerFormIntroTextAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulFormContainerFormIntroTextEntries = {
  __typename?: 'ContentfulFormContainerFormIntroTextEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulFormContainerFormIntroTextLinks = {
  __typename?: 'ContentfulFormContainerFormIntroTextLinks';
  assets: ContentfulFormContainerFormIntroTextAssets;
  entries: ContentfulFormContainerFormIntroTextEntries;
  resources: ContentfulFormContainerFormIntroTextResources;
};

export type ContentfulFormContainerFormIntroTextResources = {
  __typename?: 'ContentfulFormContainerFormIntroTextResources';
  block: Array<ContentfulFormContainerFormIntroTextResourcesBlock>;
  hyperlink: Array<ContentfulFormContainerFormIntroTextResourcesHyperlink>;
  inline: Array<ContentfulFormContainerFormIntroTextResourcesInline>;
};

export type ContentfulFormContainerFormIntroTextResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormIntroTextResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerFormIntroTextResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormIntroTextResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerFormIntroTextResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormIntroTextResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerFormPostSubmitText = {
  __typename?: 'ContentfulFormContainerFormPostSubmitText';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulFormContainerFormPostSubmitTextLinks;
};

export type ContentfulFormContainerFormPostSubmitTextAssets = {
  __typename?: 'ContentfulFormContainerFormPostSubmitTextAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulFormContainerFormPostSubmitTextEntries = {
  __typename?: 'ContentfulFormContainerFormPostSubmitTextEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulFormContainerFormPostSubmitTextLinks = {
  __typename?: 'ContentfulFormContainerFormPostSubmitTextLinks';
  assets: ContentfulFormContainerFormPostSubmitTextAssets;
  entries: ContentfulFormContainerFormPostSubmitTextEntries;
  resources: ContentfulFormContainerFormPostSubmitTextResources;
};

export type ContentfulFormContainerFormPostSubmitTextResources = {
  __typename?: 'ContentfulFormContainerFormPostSubmitTextResources';
  block: Array<ContentfulFormContainerFormPostSubmitTextResourcesBlock>;
  hyperlink: Array<ContentfulFormContainerFormPostSubmitTextResourcesHyperlink>;
  inline: Array<ContentfulFormContainerFormPostSubmitTextResourcesInline>;
};

export type ContentfulFormContainerFormPostSubmitTextResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormPostSubmitTextResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerFormPostSubmitTextResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormPostSubmitTextResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerFormPostSubmitTextResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulFormContainerFormPostSubmitTextResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulFormContainerLinkingCollections = {
  __typename?: 'ContentfulFormContainerLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulFormContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulFormContainerOrder {
  FormTitleAsc = 'formTitle_ASC',
  FormTitleDesc = 'formTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulFormFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulFormFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulFormFilter>>>;
  adoptedMandatoryAllCourts: InputMaybe<Scalars['Boolean']['input']>;
  adoptedMandatoryAllCourts_exists: InputMaybe<Scalars['Boolean']['input']>;
  adoptedMandatoryAllCourts_not: InputMaybe<Scalars['Boolean']['input']>;
  agency: InputMaybe<ContentfulCfAgencyNestedFilter>;
  agencyCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess_exists: InputMaybe<Scalars['Boolean']['input']>;
  autoProcess_not: InputMaybe<Scalars['Boolean']['input']>;
  contentItem_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  dateIssuedByAgency: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateIssuedByAgency_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateIssuedByAgency_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateIssuedByAgency_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRevised: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRevised_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRevised_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRevised_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPubDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  efPubDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  efPubDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  efPubDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  effectiveDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  effectiveDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  effectiveDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  essentialFormsProductionStatus: InputMaybe<Scalars['String']['input']>;
  essentialFormsProductionStatus_contains: InputMaybe<
    Scalars['String']['input']
  >;
  essentialFormsProductionStatus_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  essentialFormsProductionStatus_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  essentialFormsProductionStatus_not: InputMaybe<Scalars['String']['input']>;
  essentialFormsProductionStatus_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  essentialFormsProductionStatus_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formName: InputMaybe<Scalars['String']['input']>;
  formName_contains: InputMaybe<Scalars['String']['input']>;
  formName_exists: InputMaybe<Scalars['Boolean']['input']>;
  formName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formName_not: InputMaybe<Scalars['String']['input']>;
  formName_not_contains: InputMaybe<Scalars['String']['input']>;
  formName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber: InputMaybe<Scalars['String']['input']>;
  formNumber_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber_not: InputMaybe<Scalars['String']['input']>;
  formNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formSource: InputMaybe<ContentfulCfFormSourceNestedFilter>;
  formSource_exists: InputMaybe<Scalars['Boolean']['input']>;
  formStatus: InputMaybe<Scalars['String']['input']>;
  formStatus_contains: InputMaybe<Scalars['String']['input']>;
  formStatus_exists: InputMaybe<Scalars['Boolean']['input']>;
  formStatus_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formStatus_not: InputMaybe<Scalars['String']['input']>;
  formStatus_not_contains: InputMaybe<Scalars['String']['input']>;
  formStatus_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formType: InputMaybe<Scalars['String']['input']>;
  formType_contains: InputMaybe<Scalars['String']['input']>;
  formType_exists: InputMaybe<Scalars['Boolean']['input']>;
  formType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formType_not: InputMaybe<Scalars['String']['input']>;
  formType_not_contains: InputMaybe<Scalars['String']['input']>;
  formType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hash: InputMaybe<Scalars['String']['input']>;
  hash_contains: InputMaybe<Scalars['String']['input']>;
  hash_exists: InputMaybe<Scalars['Boolean']['input']>;
  hash_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hash_not: InputMaybe<Scalars['String']['input']>;
  hash_not_contains: InputMaybe<Scalars['String']['input']>;
  hash_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imagesCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  jcFormNumber: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_contains: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  jcFormNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jcFormNumber_not: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  jcFormNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  neededBy: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_exists: InputMaybe<Scalars['Boolean']['input']>;
  neededBy_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  neededBy_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  neededBy_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReview_exists: InputMaybe<Scalars['Boolean']['input']>;
  needsReview_not: InputMaybe<Scalars['Boolean']['input']>;
  notes_contains: InputMaybe<Scalars['String']['input']>;
  notes_exists: InputMaybe<Scalars['Boolean']['input']>;
  notes_not_contains: InputMaybe<Scalars['String']['input']>;
  pdfMasterFile_exists: InputMaybe<Scalars['Boolean']['input']>;
  practiceArea_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  practiceArea_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceFileCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl: InputMaybe<Scalars['String']['input']>;
  sourceUrl_contains: InputMaybe<Scalars['String']['input']>;
  sourceUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  sourceUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceUrl_not: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  sourceUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueForm: InputMaybe<Scalars['Boolean']['input']>;
  trueForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  trueForm_not: InputMaybe<Scalars['Boolean']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  url_contains: InputMaybe<Scalars['String']['input']>;
  url_exists: InputMaybe<Scalars['Boolean']['input']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not: InputMaybe<Scalars['String']['input']>;
  url_not_contains: InputMaybe<Scalars['String']['input']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulFormLinkingCollections = {
  __typename?: 'ContentfulFormLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  trueDocsAndFormsCollection: Maybe<ContentfulTrueDocsAndFormsCollection>;
};

export type ContentfulFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulFormLinkingCollectionsTrueDocsAndFormsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulFormLinkingCollectionsTrueDocsAndFormsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulFormLinkingCollectionsTrueDocsAndFormsCollectionOrder {
  DocspringTemplateIdAsc = 'docspringTemplateId_ASC',
  DocspringTemplateIdDesc = 'docspringTemplateId_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  MandatoryJcFormAsc = 'mandatoryJcForm_ASC',
  MandatoryJcFormDesc = 'mandatoryJcForm_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormSubtypeAsc = 'trueFormSubtype_ASC',
  TrueFormSubtypeDesc = 'trueFormSubtype_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulFormNotes = {
  __typename?: 'ContentfulFormNotes';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulFormNotesLinks;
};

export type ContentfulFormNotesAssets = {
  __typename?: 'ContentfulFormNotesAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulFormNotesEntries = {
  __typename?: 'ContentfulFormNotesEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulFormNotesLinks = {
  __typename?: 'ContentfulFormNotesLinks';
  assets: ContentfulFormNotesAssets;
  entries: ContentfulFormNotesEntries;
  resources: ContentfulFormNotesResources;
};

export type ContentfulFormNotesResources = {
  __typename?: 'ContentfulFormNotesResources';
  block: Array<ContentfulFormNotesResourcesBlock>;
  hyperlink: Array<ContentfulFormNotesResourcesHyperlink>;
  inline: Array<ContentfulFormNotesResourcesInline>;
};

export type ContentfulFormNotesResourcesBlock = ContentfulResourceLink & {
  __typename?: 'ContentfulFormNotesResourcesBlock';
  sys: ContentfulResourceSys;
};

export type ContentfulFormNotesResourcesHyperlink = ContentfulResourceLink & {
  __typename?: 'ContentfulFormNotesResourcesHyperlink';
  sys: ContentfulResourceSys;
};

export type ContentfulFormNotesResourcesInline = ContentfulResourceLink & {
  __typename?: 'ContentfulFormNotesResourcesInline';
  sys: ContentfulResourceSys;
};

export enum ContentfulFormOrder {
  AdoptedMandatoryAllCourtsAsc = 'adoptedMandatoryAllCourts_ASC',
  AdoptedMandatoryAllCourtsDesc = 'adoptedMandatoryAllCourts_DESC',
  AutoProcessAsc = 'autoProcess_ASC',
  AutoProcessDesc = 'autoProcess_DESC',
  DateIssuedByAgencyAsc = 'dateIssuedByAgency_ASC',
  DateIssuedByAgencyDesc = 'dateIssuedByAgency_DESC',
  DateRevisedAsc = 'dateRevised_ASC',
  DateRevisedDesc = 'dateRevised_DESC',
  EfPubDateAsc = 'efPubDate_ASC',
  EfPubDateDesc = 'efPubDate_DESC',
  EffectiveDateAsc = 'effectiveDate_ASC',
  EffectiveDateDesc = 'effectiveDate_DESC',
  EssentialFormsProductionStatusAsc = 'essentialFormsProductionStatus_ASC',
  EssentialFormsProductionStatusDesc = 'essentialFormsProductionStatus_DESC',
  FormNameAsc = 'formName_ASC',
  FormNameDesc = 'formName_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  FormStatusAsc = 'formStatus_ASC',
  FormStatusDesc = 'formStatus_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  JcFormNumberAsc = 'jcFormNumber_ASC',
  JcFormNumberDesc = 'jcFormNumber_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NeededByAsc = 'neededBy_ASC',
  NeededByDesc = 'neededBy_DESC',
  NeedsReviewAsc = 'needsReview_ASC',
  NeedsReviewDesc = 'needsReview_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SourceUrlAsc = 'sourceUrl_ASC',
  SourceUrlDesc = 'sourceUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormAsc = 'trueForm_ASC',
  TrueFormDesc = 'trueForm_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

/** ie. Judicial Council or Local Courts [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formSource) */
export type ContentfulFormSource = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulFormSource';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulFormSourceLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** ie. Judicial Council or Local Courts [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formSource) */
export type ContentfulFormSourceLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** ie. Judicial Council or Local Courts [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formSource) */
export type ContentfulFormSourceNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulFormSourceCollection = {
  __typename?: 'ContentfulFormSourceCollection';
  items: Array<Maybe<ContentfulFormSource>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulFormSourceFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulFormSourceFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulFormSourceFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulFormSourceLinkingCollections = {
  __typename?: 'ContentfulFormSourceLinkingCollections';
  agencyCollection: Maybe<ContentfulAgencyCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  formCollection: Maybe<ContentfulFormCollection>;
};

export type ContentfulFormSourceLinkingCollectionsAgencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulFormSourceLinkingCollectionsAgencyCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulFormSourceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulFormSourceLinkingCollectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulFormSourceLinkingCollectionsFormCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulFormSourceLinkingCollectionsAgencyCollectionOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AgencyNameAsc = 'agencyName_ASC',
  AgencyNameDesc = 'agencyName_DESC',
  CheckUtilityAsc = 'checkUtility_ASC',
  CheckUtilityDesc = 'checkUtility_DESC',
  ContactPersonAsc = 'contactPerson_ASC',
  ContactPersonDesc = 'contactPerson_DESC',
  DateFlagResetAsc = 'dateFlagReset_ASC',
  DateFlagResetDesc = 'dateFlagReset_DESC',
  FormsIndexPageAsc = 'formsIndexPage_ASC',
  FormsIndexPageDesc = 'formsIndexPage_DESC',
  ImportFormsAsc = 'importForms_ASC',
  ImportFormsDesc = 'importForms_DESC',
  LastFidoFormUpdateAsc = 'lastFidoFormUpdate_ASC',
  LastFidoFormUpdateDesc = 'lastFidoFormUpdate_DESC',
  NameAbbreviationAsc = 'nameAbbreviation_ASC',
  NameAbbreviationDesc = 'nameAbbreviation_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export enum ContentfulFormSourceLinkingCollectionsFormCollectionOrder {
  AdoptedMandatoryAllCourtsAsc = 'adoptedMandatoryAllCourts_ASC',
  AdoptedMandatoryAllCourtsDesc = 'adoptedMandatoryAllCourts_DESC',
  AutoProcessAsc = 'autoProcess_ASC',
  AutoProcessDesc = 'autoProcess_DESC',
  DateIssuedByAgencyAsc = 'dateIssuedByAgency_ASC',
  DateIssuedByAgencyDesc = 'dateIssuedByAgency_DESC',
  DateRevisedAsc = 'dateRevised_ASC',
  DateRevisedDesc = 'dateRevised_DESC',
  EfPubDateAsc = 'efPubDate_ASC',
  EfPubDateDesc = 'efPubDate_DESC',
  EffectiveDateAsc = 'effectiveDate_ASC',
  EffectiveDateDesc = 'effectiveDate_DESC',
  EssentialFormsProductionStatusAsc = 'essentialFormsProductionStatus_ASC',
  EssentialFormsProductionStatusDesc = 'essentialFormsProductionStatus_DESC',
  FormNameAsc = 'formName_ASC',
  FormNameDesc = 'formName_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  FormStatusAsc = 'formStatus_ASC',
  FormStatusDesc = 'formStatus_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  JcFormNumberAsc = 'jcFormNumber_ASC',
  JcFormNumberDesc = 'jcFormNumber_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NeededByAsc = 'neededBy_ASC',
  NeededByDesc = 'neededBy_DESC',
  NeedsReviewAsc = 'needsReview_ASC',
  NeedsReviewDesc = 'needsReview_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SourceUrlAsc = 'sourceUrl_ASC',
  SourceUrlDesc = 'sourceUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormAsc = 'trueForm_ASC',
  TrueFormDesc = 'trueForm_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum ContentfulFormSourceOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Registers Forms Provider IDs to be used for agencies forms processing by Terrace Consulting utility [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formsProvider) */
export type ContentfulFormsProvider = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulFormsProvider';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    description: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulFormsProviderLinkingCollections>;
    sys: ContentfulSys;
  };

/** Registers Forms Provider IDs to be used for agencies forms processing by Terrace Consulting utility [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formsProvider) */
export type ContentfulFormsProviderDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Registers Forms Provider IDs to be used for agencies forms processing by Terrace Consulting utility [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formsProvider) */
export type ContentfulFormsProviderIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Registers Forms Provider IDs to be used for agencies forms processing by Terrace Consulting utility [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/formsProvider) */
export type ContentfulFormsProviderLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulFormsProviderCollection = {
  __typename?: 'ContentfulFormsProviderCollection';
  items: Array<Maybe<ContentfulFormsProvider>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulFormsProviderFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulFormsProviderFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulFormsProviderFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Scalars['String']['input']>;
  id_contains: InputMaybe<Scalars['String']['input']>;
  id_exists: InputMaybe<Scalars['Boolean']['input']>;
  id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not: InputMaybe<Scalars['String']['input']>;
  id_not_contains: InputMaybe<Scalars['String']['input']>;
  id_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulFormsProviderLinkingCollections = {
  __typename?: 'ContentfulFormsProviderLinkingCollections';
  agencyCollection: Maybe<ContentfulAgencyCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulFormsProviderLinkingCollectionsAgencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulFormsProviderLinkingCollectionsAgencyCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulFormsProviderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulFormsProviderLinkingCollectionsAgencyCollectionOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AgencyNameAsc = 'agencyName_ASC',
  AgencyNameDesc = 'agencyName_DESC',
  CheckUtilityAsc = 'checkUtility_ASC',
  CheckUtilityDesc = 'checkUtility_DESC',
  ContactPersonAsc = 'contactPerson_ASC',
  ContactPersonDesc = 'contactPerson_DESC',
  DateFlagResetAsc = 'dateFlagReset_ASC',
  DateFlagResetDesc = 'dateFlagReset_DESC',
  FormsIndexPageAsc = 'formsIndexPage_ASC',
  FormsIndexPageDesc = 'formsIndexPage_DESC',
  ImportFormsAsc = 'importForms_ASC',
  ImportFormsDesc = 'importForms_DESC',
  LastFidoFormUpdateAsc = 'lastFidoFormUpdate_ASC',
  LastFidoFormUpdateDesc = 'lastFidoFormUpdate_DESC',
  NameAbbreviationAsc = 'nameAbbreviation_ASC',
  NameAbbreviationDesc = 'nameAbbreviation_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export enum ContentfulFormsProviderOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulFrontMatter';
    _id: Scalars['ID']['output'];
    chapter: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    frontMatterType: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulFrontMatterLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatterChapterArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatterFrontMatterTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/frontMatter) */
export type ContentfulFrontMatterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulFrontMatterCollection = {
  __typename?: 'ContentfulFrontMatterCollection';
  items: Array<Maybe<ContentfulFrontMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulFrontMatterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulFrontMatterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulFrontMatterFilter>>>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  frontMatterType: InputMaybe<Scalars['String']['input']>;
  frontMatterType_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  frontMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frontMatterType_not: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulFrontMatterLinkingCollections = {
  __typename?: 'ContentfulFrontMatterLinkingCollections';
  bookCollection: Maybe<ContentfulBookCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulFrontMatterLinkingCollectionsBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulFrontMatterLinkingCollectionsBookCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulFrontMatterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulFrontMatterLinkingCollectionsBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  PublicationTypeAsc = 'publicationType_ASC',
  PublicationTypeDesc = 'publicationType_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulFrontMatterOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  FrontMatterTypeAsc = 'frontMatterType_ASC',
  FrontMatterTypeDesc = 'frontMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/graphAuthentication) */
export type ContentfulGraphAuthentication = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulGraphAuthentication';
    _id: Scalars['ID']['output'];
    accessToken: Maybe<Scalars['ContentfulJSON']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulGraphAuthenticationLinkingCollections>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/graphAuthentication) */
export type ContentfulGraphAuthenticationAccessTokenArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/graphAuthentication) */
export type ContentfulGraphAuthenticationLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulGraphAuthenticationCollection = {
  __typename?: 'ContentfulGraphAuthenticationCollection';
  items: Array<Maybe<ContentfulGraphAuthentication>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulGraphAuthenticationFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulGraphAuthenticationFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulGraphAuthenticationFilter>>>;
  accessToken_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulGraphAuthenticationLinkingCollections = {
  __typename?: 'ContentfulGraphAuthenticationLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulGraphAuthenticationLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulGraphAuthenticationOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ContentfulImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ContentfulImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ContentfulImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor: InputMaybe<Scalars['ContentfulHexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format: InputMaybe<ContentfulImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height: InputMaybe<Scalars['ContentfulDimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality: InputMaybe<Scalars['ContentfulQuality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus: InputMaybe<ContentfulImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy: InputMaybe<ContentfulImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width: InputMaybe<Scalars['ContentfulDimension']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocument = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulKnowHowDocument';
    _id: Scalars['ID']['output'];
    authorities: Maybe<Scalars['ContentfulJSON']['output']>;
    authorsCollection: Maybe<ContentfulKnowHowDocumentAuthorsCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    court: Maybe<ContentfulCourt>;
    description: Maybe<Scalars['String']['output']>;
    legalTasksCollection: Maybe<ContentfulKnowHowDocumentLegalTasksCollection>;
    linkedFrom: Maybe<ContentfulKnowHowDocumentLinkingCollections>;
    originalPublishDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    practiceAreasCollection: Maybe<ContentfulKnowHowDocumentPracticeAreasCollection>;
    practitionerDocumentType: Maybe<ContentfulDocumentType>;
    processedContent: Maybe<Scalars['ContentfulJSON']['output']>;
    processedWordDocument: Maybe<ContentfulAsset>;
    processedWordDocumentNoNotes: Maybe<ContentfulAsset>;
    relatedContentCollection: Maybe<ContentfulKnowHowDocumentRelatedContentCollection>;
    slug: Maybe<Scalars['String']['output']>;
    sourceWordDocument: Maybe<ContentfulAsset>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    updatedDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    validatorProblems: Maybe<Scalars['ContentfulJSON']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentAuthoritiesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulKnowHowDocumentAuthorsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentCourtArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulCourtFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentLegalTasksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulKnowHowDocumentLegalTasksCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLegalTaskFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentOriginalPublishDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulKnowHowDocumentPracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentPractitionerDocumentTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulDocumentTypeFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentProcessedContentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentProcessedWordDocumentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentProcessedWordDocumentNoNotesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulKnowHowDocumentRelatedContentCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulKnowHowDocumentFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentSourceWordDocumentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentUpdatedDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowDocument) */
export type ContentfulKnowHowDocumentValidatorProblemsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulKnowHowDocumentAuthorsCollection = {
  __typename?: 'ContentfulKnowHowDocumentAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulKnowHowDocumentAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulKnowHowDocumentCollection = {
  __typename?: 'ContentfulKnowHowDocumentCollection';
  items: Array<Maybe<ContentfulKnowHowDocument>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulKnowHowDocumentFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulKnowHowDocumentFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulKnowHowDocumentFilter>>>;
  authorities_exists: InputMaybe<Scalars['Boolean']['input']>;
  authors: InputMaybe<ContentfulCfContributorNestedFilter>;
  authorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  court: InputMaybe<ContentfulCfCourtNestedFilter>;
  court_exists: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legalTasks: InputMaybe<ContentfulCfLegalTaskNestedFilter>;
  legalTasksCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  originalPublishDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  practitionerDocumentType: InputMaybe<ContentfulCfDocumentTypeNestedFilter>;
  practitionerDocumentType_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedContent_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedWordDocumentNoNotes_exists: InputMaybe<Scalars['Boolean']['input']>;
  processedWordDocument_exists: InputMaybe<Scalars['Boolean']['input']>;
  relatedContent: InputMaybe<ContentfulCfKnowHowDocumentNestedFilter>;
  relatedContentCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceWordDocument_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  validatorProblems_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulKnowHowDocumentLegalTasksCollection = {
  __typename?: 'ContentfulKnowHowDocumentLegalTasksCollection';
  items: Array<Maybe<ContentfulLegalTask>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulKnowHowDocumentLegalTasksCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulKnowHowDocumentLinkingCollections = {
  __typename?: 'ContentfulKnowHowDocumentLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  practitionerWorkflowSectionItemsCollection: Maybe<ContentfulPractitionerWorkflowSectionItemsCollection>;
};

export type ContentfulKnowHowDocumentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulKnowHowDocumentLinkingCollectionsKnowHowDocumentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulKnowHowDocumentLinkingCollectionsKnowHowDocumentCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulKnowHowDocumentLinkingCollectionsPractitionerWorkflowSectionItemsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulKnowHowDocumentLinkingCollectionsPractitionerWorkflowSectionItemsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulKnowHowDocumentLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulKnowHowDocumentLinkingCollectionsPractitionerWorkflowSectionItemsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulKnowHowDocumentOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulKnowHowDocumentPracticeAreasCollection = {
  __typename?: 'ContentfulKnowHowDocumentPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulKnowHowDocumentPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulKnowHowDocumentRelatedContentCollection = {
  __typename?: 'ContentfulKnowHowDocumentRelatedContentCollection';
  items: Array<Maybe<ContentfulKnowHowDocument>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulKnowHowDocumentRelatedContentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowProduct) */
export type ContentfulKnowHowProduct = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulKnowHowProduct';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulKnowHowProductLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    practiceAreaCollection: Maybe<ContentfulKnowHowProductPracticeAreaCollection>;
    productCode: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowProduct) */
export type ContentfulKnowHowProductLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowProduct) */
export type ContentfulKnowHowProductNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowProduct) */
export type ContentfulKnowHowProductPracticeAreaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulKnowHowProductPracticeAreaCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/knowHowProduct) */
export type ContentfulKnowHowProductProductCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulKnowHowProductCollection = {
  __typename?: 'ContentfulKnowHowProductCollection';
  items: Array<Maybe<ContentfulKnowHowProduct>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulKnowHowProductFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulKnowHowProductFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulKnowHowProductFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceArea: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreaCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  productCode: InputMaybe<Scalars['String']['input']>;
  productCode_contains: InputMaybe<Scalars['String']['input']>;
  productCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  productCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCode_not: InputMaybe<Scalars['String']['input']>;
  productCode_not_contains: InputMaybe<Scalars['String']['input']>;
  productCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulKnowHowProductLinkingCollections = {
  __typename?: 'ContentfulKnowHowProductLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulKnowHowProductLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulKnowHowProductOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProductCodeAsc = 'productCode_ASC',
  ProductCodeDesc = 'productCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulKnowHowProductPracticeAreaCollection = {
  __typename?: 'ContentfulKnowHowProductPracticeAreaCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulKnowHowProductPracticeAreaCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/legalTask) */
export type ContentfulLegalTask = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulLegalTask';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulLegalTaskLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    type: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/legalTask) */
export type ContentfulLegalTaskLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/legalTask) */
export type ContentfulLegalTaskNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/legalTask) */
export type ContentfulLegalTaskTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulLegalTaskCollection = {
  __typename?: 'ContentfulLegalTaskCollection';
  items: Array<Maybe<ContentfulLegalTask>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulLegalTaskFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulLegalTaskFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulLegalTaskFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulLegalTaskLinkingCollections = {
  __typename?: 'ContentfulLegalTaskLinkingCollections';
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
};

export type ContentfulLegalTaskLinkingCollectionsCurrentAwarenessCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulLegalTaskLinkingCollectionsCurrentAwarenessCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulLegalTaskLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulLegalTaskLinkingCollectionsKnowHowDocumentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulLegalTaskLinkingCollectionsKnowHowDocumentCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulLegalTaskLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulLegalTaskLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulLegalTaskOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBook = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulLooseleafBook';
    _id: Scalars['ID']['output'];
    attorneyEditors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    bookYear: Maybe<Scalars['String']['output']>;
    chaptersCollection: Maybe<ContentfulLooseleafBookChaptersCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    copyEditors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    copyrightDate: Maybe<Scalars['String']['output']>;
    copyrightHolder: Maybe<Scalars['String']['output']>;
    displayTitle: Maybe<Scalars['String']['output']>;
    documentWorkflowState: Maybe<Scalars['String']['output']>;
    editionNumber: Maybe<Scalars['String']['output']>;
    endMatterCollection: Maybe<ContentfulLooseleafBookEndMatterCollection>;
    focd: Maybe<Scalars['Boolean']['output']>;
    formsCollection: Maybe<ContentfulAssetCollection>;
    frontMatterCollection: Maybe<ContentfulLooseleafBookFrontMatterCollection>;
    isbn: Maybe<Scalars['String']['output']>;
    legalContentSpecialist: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    libraryOfCongressCatalogCardNumber: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulLooseleafBookLinkingCollections>;
    onLawId: Maybe<Scalars['String']['output']>;
    printLocation: Maybe<Scalars['String']['output']>;
    productNumber: Maybe<Scalars['String']['output']>;
    pubId: Maybe<Scalars['String']['output']>;
    publicationDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    runningHeadTitle: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    titleId: Maybe<Scalars['String']['output']>;
    totalVolumeCount: Maybe<Scalars['Int']['output']>;
    trimSize: Maybe<Scalars['String']['output']>;
  };

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookAttorneyEditorsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookBookYearArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookChaptersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulLooseleafBookChaptersCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafChapterFilter>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookCopyEditorsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookCopyrightDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookCopyrightHolderArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookDocumentWorkflowStateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookEditionNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookEndMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulLooseleafBookEndMatterCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafEndMatterFilter>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookFocdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookFormsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookFrontMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulLooseleafBookFrontMatterCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafFrontMatterFilter>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookIsbnArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookLegalContentSpecialistArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookLibraryOfCongressCatalogCardNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookOnLawIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookPrintLocationArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookProductNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookPubIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookPublicationDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookRunningHeadTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookTitleIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookTotalVolumeCountArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Publication Book [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafBook) */
export type ContentfulLooseleafBookTrimSizeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulLooseleafBookChaptersCollection = {
  __typename?: 'ContentfulLooseleafBookChaptersCollection';
  items: Array<Maybe<ContentfulLooseleafChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulLooseleafBookChaptersCollectionOrder {
  ChapterNumberAsc = 'chapterNumber_ASC',
  ChapterNumberDesc = 'chapterNumber_DESC',
  ChapterRunningHeadTitleAsc = 'chapterRunningHeadTitle_ASC',
  ChapterRunningHeadTitleDesc = 'chapterRunningHeadTitle_DESC',
  ChapterTitleAsc = 'chapterTitle_ASC',
  ChapterTitleDesc = 'chapterTitle_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VolumeAsc = 'volume_ASC',
  VolumeDesc = 'volume_DESC',
}

export type ContentfulLooseleafBookCollection = {
  __typename?: 'ContentfulLooseleafBookCollection';
  items: Array<Maybe<ContentfulLooseleafBook>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulLooseleafBookEndMatterCollection = {
  __typename?: 'ContentfulLooseleafBookEndMatterCollection';
  items: Array<Maybe<ContentfulLooseleafEndMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulLooseleafBookEndMatterCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  EndMatterTypeAsc = 'endMatterType_ASC',
  EndMatterTypeDesc = 'endMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulLooseleafBookFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulLooseleafBookFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulLooseleafBookFilter>>>;
  attorneyEditors_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  attorneyEditors_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  attorneyEditors_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  attorneyEditors_exists: InputMaybe<Scalars['Boolean']['input']>;
  bookYear: InputMaybe<Scalars['String']['input']>;
  bookYear_contains: InputMaybe<Scalars['String']['input']>;
  bookYear_exists: InputMaybe<Scalars['Boolean']['input']>;
  bookYear_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookYear_not: InputMaybe<Scalars['String']['input']>;
  bookYear_not_contains: InputMaybe<Scalars['String']['input']>;
  bookYear_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapters: InputMaybe<ContentfulCfLooseleafChapterNestedFilter>;
  chaptersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  copyEditors_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyEditors_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightDate: InputMaybe<Scalars['String']['input']>;
  copyrightDate_contains: InputMaybe<Scalars['String']['input']>;
  copyrightDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightDate_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightDate_not: InputMaybe<Scalars['String']['input']>;
  copyrightDate_not_contains: InputMaybe<Scalars['String']['input']>;
  copyrightDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  copyrightHolder: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_contains: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_exists: InputMaybe<Scalars['Boolean']['input']>;
  copyrightHolder_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyrightHolder_not: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_not_contains: InputMaybe<Scalars['String']['input']>;
  copyrightHolder_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentWorkflowState: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_contains: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_exists: InputMaybe<Scalars['Boolean']['input']>;
  documentWorkflowState_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentWorkflowState_not: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_not_contains: InputMaybe<Scalars['String']['input']>;
  documentWorkflowState_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  editionNumber: InputMaybe<Scalars['String']['input']>;
  editionNumber_contains: InputMaybe<Scalars['String']['input']>;
  editionNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  editionNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  editionNumber_not: InputMaybe<Scalars['String']['input']>;
  editionNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  editionNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatter: InputMaybe<ContentfulCfLooseleafEndMatterNestedFilter>;
  endMatterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  focd: InputMaybe<Scalars['Boolean']['input']>;
  focd_exists: InputMaybe<Scalars['Boolean']['input']>;
  focd_not: InputMaybe<Scalars['Boolean']['input']>;
  formsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  frontMatter: InputMaybe<ContentfulCfLooseleafFrontMatterNestedFilter>;
  frontMatterCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  isbn: InputMaybe<Scalars['String']['input']>;
  isbn_contains: InputMaybe<Scalars['String']['input']>;
  isbn_exists: InputMaybe<Scalars['Boolean']['input']>;
  isbn_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isbn_not: InputMaybe<Scalars['String']['input']>;
  isbn_not_contains: InputMaybe<Scalars['String']['input']>;
  isbn_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legalContentSpecialist_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalContentSpecialist_exists: InputMaybe<Scalars['Boolean']['input']>;
  libraryOfCongressCatalogCardNumber: InputMaybe<Scalars['String']['input']>;
  libraryOfCongressCatalogCardNumber_contains: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  libraryOfCongressCatalogCardNumber_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  libraryOfCongressCatalogCardNumber_not: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  libraryOfCongressCatalogCardNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printLocation: InputMaybe<Scalars['String']['input']>;
  printLocation_contains: InputMaybe<Scalars['String']['input']>;
  printLocation_exists: InputMaybe<Scalars['Boolean']['input']>;
  printLocation_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printLocation_not: InputMaybe<Scalars['String']['input']>;
  printLocation_not_contains: InputMaybe<Scalars['String']['input']>;
  printLocation_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productNumber: InputMaybe<Scalars['String']['input']>;
  productNumber_contains: InputMaybe<Scalars['String']['input']>;
  productNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  productNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productNumber_not: InputMaybe<Scalars['String']['input']>;
  productNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  productNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pubId: InputMaybe<Scalars['String']['input']>;
  pubId_contains: InputMaybe<Scalars['String']['input']>;
  pubId_exists: InputMaybe<Scalars['Boolean']['input']>;
  pubId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pubId_not: InputMaybe<Scalars['String']['input']>;
  pubId_not_contains: InputMaybe<Scalars['String']['input']>;
  pubId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publicationDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publicationDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  runningHeadTitle: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  runningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  runningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  runningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  titleId: InputMaybe<Scalars['String']['input']>;
  titleId_contains: InputMaybe<Scalars['String']['input']>;
  titleId_exists: InputMaybe<Scalars['Boolean']['input']>;
  titleId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleId_not: InputMaybe<Scalars['String']['input']>;
  titleId_not_contains: InputMaybe<Scalars['String']['input']>;
  titleId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  totalVolumeCount: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_exists: InputMaybe<Scalars['Boolean']['input']>;
  totalVolumeCount_gt: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_gte: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  totalVolumeCount_lt: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_lte: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_not: InputMaybe<Scalars['Int']['input']>;
  totalVolumeCount_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  trimSize: InputMaybe<Scalars['String']['input']>;
  trimSize_contains: InputMaybe<Scalars['String']['input']>;
  trimSize_exists: InputMaybe<Scalars['Boolean']['input']>;
  trimSize_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trimSize_not: InputMaybe<Scalars['String']['input']>;
  trimSize_not_contains: InputMaybe<Scalars['String']['input']>;
  trimSize_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulLooseleafBookFrontMatterCollection = {
  __typename?: 'ContentfulLooseleafBookFrontMatterCollection';
  items: Array<Maybe<ContentfulLooseleafFrontMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulLooseleafBookFrontMatterCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  FrontMatterTypeAsc = 'frontMatterType_ASC',
  FrontMatterTypeDesc = 'frontMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulLooseleafBookLinkingCollections = {
  __typename?: 'ContentfulLooseleafBookLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulLooseleafBookLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulLooseleafBookOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulLooseleafChapter';
    _id: Scalars['ID']['output'];
    chapterNumber: Maybe<Scalars['String']['output']>;
    chapterRunningHeadTitle: Maybe<Scalars['String']['output']>;
    chapterTitle: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    forms: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulLooseleafChapterLinkingCollections>;
    sys: ContentfulSys;
    uploadWord: Maybe<ContentfulAsset>;
    volume: Maybe<Scalars['Int']['output']>;
  };

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterChapterNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterChapterRunningHeadTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterChapterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterFormsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterUploadWordArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** Loose Leaf Book Chapter's [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleafChapter) */
export type ContentfulLooseleafChapterVolumeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulLooseleafChapterCollection = {
  __typename?: 'ContentfulLooseleafChapterCollection';
  items: Array<Maybe<ContentfulLooseleafChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulLooseleafChapterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulLooseleafChapterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulLooseleafChapterFilter>>>;
  chapterNumber: InputMaybe<Scalars['String']['input']>;
  chapterNumber_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterNumber_not: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterNumber_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterRunningHeadTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterRunningHeadTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterRunningHeadTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  chapterTitle: InputMaybe<Scalars['String']['input']>;
  chapterTitle_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapterTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chapterTitle_not: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  chapterTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  forms_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  uploadWord_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume: InputMaybe<Scalars['Int']['input']>;
  volume_exists: InputMaybe<Scalars['Boolean']['input']>;
  volume_gt: InputMaybe<Scalars['Int']['input']>;
  volume_gte: InputMaybe<Scalars['Int']['input']>;
  volume_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  volume_lt: InputMaybe<Scalars['Int']['input']>;
  volume_lte: InputMaybe<Scalars['Int']['input']>;
  volume_not: InputMaybe<Scalars['Int']['input']>;
  volume_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ContentfulLooseleafChapterLinkingCollections = {
  __typename?: 'ContentfulLooseleafChapterLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  looseleafBookCollection: Maybe<ContentfulLooseleafBookCollection>;
};

export type ContentfulLooseleafChapterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulLooseleafChapterLinkingCollectionsLooseleafBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulLooseleafChapterLinkingCollectionsLooseleafBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulLooseleafChapterLinkingCollectionsLooseleafBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulLooseleafChapterOrder {
  ChapterNumberAsc = 'chapterNumber_ASC',
  ChapterNumberDesc = 'chapterNumber_DESC',
  ChapterRunningHeadTitleAsc = 'chapterRunningHeadTitle_ASC',
  ChapterRunningHeadTitleDesc = 'chapterRunningHeadTitle_DESC',
  ChapterTitleAsc = 'chapterTitle_ASC',
  ChapterTitleDesc = 'chapterTitle_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VolumeAsc = 'volume_ASC',
  VolumeDesc = 'volume_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulLooseleafEndMatter';
    _id: Scalars['ID']['output'];
    cIndex: Maybe<ContentfulAsset>;
    chapter: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    endMatterType: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulLooseleafEndMatterLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterCIndexArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterChapterArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterEndMatterTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-endMatter) */
export type ContentfulLooseleafEndMatterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulLooseleafEndMatterCollection = {
  __typename?: 'ContentfulLooseleafEndMatterCollection';
  items: Array<Maybe<ContentfulLooseleafEndMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulLooseleafEndMatterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulLooseleafEndMatterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulLooseleafEndMatterFilter>>>;
  cIndex_exists: InputMaybe<Scalars['Boolean']['input']>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  endMatterType: InputMaybe<Scalars['String']['input']>;
  endMatterType_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  endMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endMatterType_not: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  endMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulLooseleafEndMatterLinkingCollections = {
  __typename?: 'ContentfulLooseleafEndMatterLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  looseleafBookCollection: Maybe<ContentfulLooseleafBookCollection>;
};

export type ContentfulLooseleafEndMatterLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulLooseleafEndMatterLinkingCollectionsLooseleafBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulLooseleafEndMatterLinkingCollectionsLooseleafBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulLooseleafEndMatterLinkingCollectionsLooseleafBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulLooseleafEndMatterOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  EndMatterTypeAsc = 'endMatterType_ASC',
  EndMatterTypeDesc = 'endMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulLooseleafFrontMatter';
    _id: Scalars['ID']['output'];
    chapter: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    displayTitle: Maybe<Scalars['String']['output']>;
    frontMatterType: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulLooseleafFrontMatterLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatterChapterArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatterDisplayTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatterFrontMatterTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Front matter for Looseleaf book. [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/looseleaf-frontMatter) */
export type ContentfulLooseleafFrontMatterTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulLooseleafFrontMatterCollection = {
  __typename?: 'ContentfulLooseleafFrontMatterCollection';
  items: Array<Maybe<ContentfulLooseleafFrontMatter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulLooseleafFrontMatterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulLooseleafFrontMatterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulLooseleafFrontMatterFilter>>>;
  chapter_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayTitle: InputMaybe<Scalars['String']['input']>;
  displayTitle_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayTitle_not: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  displayTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  frontMatterType: InputMaybe<Scalars['String']['input']>;
  frontMatterType_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_exists: InputMaybe<Scalars['Boolean']['input']>;
  frontMatterType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frontMatterType_not: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_contains: InputMaybe<Scalars['String']['input']>;
  frontMatterType_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulLooseleafFrontMatterLinkingCollections = {
  __typename?: 'ContentfulLooseleafFrontMatterLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  looseleafBookCollection: Maybe<ContentfulLooseleafBookCollection>;
};

export type ContentfulLooseleafFrontMatterLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulLooseleafFrontMatterLinkingCollectionsLooseleafBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulLooseleafFrontMatterLinkingCollectionsLooseleafBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulLooseleafFrontMatterLinkingCollectionsLooseleafBookCollectionOrder {
  BookYearAsc = 'bookYear_ASC',
  BookYearDesc = 'bookYear_DESC',
  CopyrightDateAsc = 'copyrightDate_ASC',
  CopyrightDateDesc = 'copyrightDate_DESC',
  CopyrightHolderAsc = 'copyrightHolder_ASC',
  CopyrightHolderDesc = 'copyrightHolder_DESC',
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  DocumentWorkflowStateAsc = 'documentWorkflowState_ASC',
  DocumentWorkflowStateDesc = 'documentWorkflowState_DESC',
  EditionNumberAsc = 'editionNumber_ASC',
  EditionNumberDesc = 'editionNumber_DESC',
  FocdAsc = 'focd_ASC',
  FocdDesc = 'focd_DESC',
  IsbnAsc = 'isbn_ASC',
  IsbnDesc = 'isbn_DESC',
  LibraryOfCongressCatalogCardNumberAsc = 'libraryOfCongressCatalogCardNumber_ASC',
  LibraryOfCongressCatalogCardNumberDesc = 'libraryOfCongressCatalogCardNumber_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  PrintLocationAsc = 'printLocation_ASC',
  PrintLocationDesc = 'printLocation_DESC',
  ProductNumberAsc = 'productNumber_ASC',
  ProductNumberDesc = 'productNumber_DESC',
  PubIdAsc = 'pubId_ASC',
  PubIdDesc = 'pubId_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  RunningHeadTitleAsc = 'runningHeadTitle_ASC',
  RunningHeadTitleDesc = 'runningHeadTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalVolumeCountAsc = 'totalVolumeCount_ASC',
  TotalVolumeCountDesc = 'totalVolumeCount_DESC',
  TrimSizeAsc = 'trimSize_ASC',
  TrimSizeDesc = 'trimSize_DESC',
}

export enum ContentfulLooseleafFrontMatterOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  FrontMatterTypeAsc = 'frontMatterType_ASC',
  FrontMatterTypeDesc = 'frontMatterType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOffering = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPlanOffering';
    _id: Scalars['ID']['output'];
    backLinkTitle: Maybe<Scalars['String']['output']>;
    backLinkUrl: Maybe<Scalars['String']['output']>;
    billingType: Maybe<Scalars['String']['output']>;
    confirmationPageButtonLink: Maybe<Scalars['String']['output']>;
    confirmationPageButtonText: Maybe<Scalars['String']['output']>;
    confirmationPageText: Maybe<ContentfulPlanOfferingConfirmationPageText>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPlanOfferingLinkingCollections>;
    multipleSelectPlanOptionsCollection: Maybe<ContentfulPlanOfferingMultipleSelectPlanOptionsCollection>;
    name: Maybe<Scalars['String']['output']>;
    optionSelectionPrompt: Maybe<Scalars['String']['output']>;
    pageHeading: Maybe<Scalars['String']['output']>;
    priceIntervalText: Maybe<Scalars['String']['output']>;
    providesCle: Maybe<Scalars['Boolean']['output']>;
    providesLegalResearch: Maybe<Scalars['Boolean']['output']>;
    shortDescription: Maybe<Scalars['String']['output']>;
    showEquivalentToMonthlyPricing: Maybe<Scalars['Boolean']['output']>;
    sidebarAnnualBillingNote: Maybe<Scalars['String']['output']>;
    sidebarInfoBoxText: Maybe<ContentfulPlanOfferingSidebarInfoBoxText>;
    sidebarInfoBoxTitle: Maybe<Scalars['String']['output']>;
    sidebarMonthlyBillingNote: Maybe<Scalars['String']['output']>;
    sidebarPlanOfferingBulletpoints: Maybe<ContentfulPlanOfferingSidebarPlanOfferingBulletpoints>;
    sidebarPlanOfferingLogo: Maybe<ContentfulAsset>;
    sidebarPlanSelectionPrompt: Maybe<Scalars['String']['output']>;
    singleSelectPlanOptionsCollection: Maybe<ContentfulPlanOfferingSingleSelectPlanOptionsCollection>;
    slug: Maybe<Scalars['String']['output']>;
    startingAtPrice: Maybe<Scalars['Float']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingBackLinkTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingBackLinkUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingBillingTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingConfirmationPageButtonLinkArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingConfirmationPageButtonTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingConfirmationPageTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingMultipleSelectPlanOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPlanOfferingMultipleSelectPlanOptionsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPlanOfferingOptionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingOptionSelectionPromptArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingPageHeadingArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingPriceIntervalTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingProvidesCleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingProvidesLegalResearchArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingShortDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingShowEquivalentToMonthlyPricingArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarAnnualBillingNoteArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarInfoBoxTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarInfoBoxTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarMonthlyBillingNoteArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarPlanOfferingLogoArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSidebarPlanSelectionPromptArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSingleSelectPlanOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPlanOfferingSingleSelectPlanOptionsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPlanOfferingOptionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOffering) */
export type ContentfulPlanOfferingStartingAtPriceArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPlanOfferingCollection = {
  __typename?: 'ContentfulPlanOfferingCollection';
  items: Array<Maybe<ContentfulPlanOffering>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPlanOfferingConfirmationPageText = {
  __typename?: 'ContentfulPlanOfferingConfirmationPageText';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulPlanOfferingConfirmationPageTextLinks;
};

export type ContentfulPlanOfferingConfirmationPageTextAssets = {
  __typename?: 'ContentfulPlanOfferingConfirmationPageTextAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulPlanOfferingConfirmationPageTextEntries = {
  __typename?: 'ContentfulPlanOfferingConfirmationPageTextEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulPlanOfferingConfirmationPageTextLinks = {
  __typename?: 'ContentfulPlanOfferingConfirmationPageTextLinks';
  assets: ContentfulPlanOfferingConfirmationPageTextAssets;
  entries: ContentfulPlanOfferingConfirmationPageTextEntries;
  resources: ContentfulPlanOfferingConfirmationPageTextResources;
};

export type ContentfulPlanOfferingConfirmationPageTextResources = {
  __typename?: 'ContentfulPlanOfferingConfirmationPageTextResources';
  block: Array<ContentfulPlanOfferingConfirmationPageTextResourcesBlock>;
  hyperlink: Array<ContentfulPlanOfferingConfirmationPageTextResourcesHyperlink>;
  inline: Array<ContentfulPlanOfferingConfirmationPageTextResourcesInline>;
};

export type ContentfulPlanOfferingConfirmationPageTextResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingConfirmationPageTextResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingConfirmationPageTextResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingConfirmationPageTextResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingConfirmationPageTextResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingConfirmationPageTextResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingFilter>>>;
  backLinkTitle: InputMaybe<Scalars['String']['input']>;
  backLinkTitle_contains: InputMaybe<Scalars['String']['input']>;
  backLinkTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  backLinkTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backLinkTitle_not: InputMaybe<Scalars['String']['input']>;
  backLinkTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  backLinkTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  backLinkUrl: InputMaybe<Scalars['String']['input']>;
  backLinkUrl_contains: InputMaybe<Scalars['String']['input']>;
  backLinkUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  backLinkUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  backLinkUrl_not: InputMaybe<Scalars['String']['input']>;
  backLinkUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  backLinkUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingType: InputMaybe<Scalars['String']['input']>;
  billingType_contains: InputMaybe<Scalars['String']['input']>;
  billingType_exists: InputMaybe<Scalars['Boolean']['input']>;
  billingType_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingType_not: InputMaybe<Scalars['String']['input']>;
  billingType_not_contains: InputMaybe<Scalars['String']['input']>;
  billingType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  confirmationPageButtonLink: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonLink_contains: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonLink_exists: InputMaybe<Scalars['Boolean']['input']>;
  confirmationPageButtonLink_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  confirmationPageButtonLink_not: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonLink_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  confirmationPageButtonLink_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  confirmationPageButtonText: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonText_contains: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonText_exists: InputMaybe<Scalars['Boolean']['input']>;
  confirmationPageButtonText_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  confirmationPageButtonText_not: InputMaybe<Scalars['String']['input']>;
  confirmationPageButtonText_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  confirmationPageButtonText_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  confirmationPageText_contains: InputMaybe<Scalars['String']['input']>;
  confirmationPageText_exists: InputMaybe<Scalars['Boolean']['input']>;
  confirmationPageText_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  multipleSelectPlanOptions: InputMaybe<ContentfulCfPlanOfferingOptionNestedFilter>;
  multipleSelectPlanOptionsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  optionSelectionPrompt: InputMaybe<Scalars['String']['input']>;
  optionSelectionPrompt_contains: InputMaybe<Scalars['String']['input']>;
  optionSelectionPrompt_exists: InputMaybe<Scalars['Boolean']['input']>;
  optionSelectionPrompt_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  optionSelectionPrompt_not: InputMaybe<Scalars['String']['input']>;
  optionSelectionPrompt_not_contains: InputMaybe<Scalars['String']['input']>;
  optionSelectionPrompt_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  pageHeading: InputMaybe<Scalars['String']['input']>;
  pageHeading_contains: InputMaybe<Scalars['String']['input']>;
  pageHeading_exists: InputMaybe<Scalars['Boolean']['input']>;
  pageHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageHeading_not: InputMaybe<Scalars['String']['input']>;
  pageHeading_not_contains: InputMaybe<Scalars['String']['input']>;
  pageHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceIntervalText: InputMaybe<Scalars['String']['input']>;
  priceIntervalText_contains: InputMaybe<Scalars['String']['input']>;
  priceIntervalText_exists: InputMaybe<Scalars['Boolean']['input']>;
  priceIntervalText_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  priceIntervalText_not: InputMaybe<Scalars['String']['input']>;
  priceIntervalText_not_contains: InputMaybe<Scalars['String']['input']>;
  priceIntervalText_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  providesCLE: InputMaybe<Scalars['Boolean']['input']>;
  providesCLE_exists: InputMaybe<Scalars['Boolean']['input']>;
  providesCLE_not: InputMaybe<Scalars['Boolean']['input']>;
  providesLegalResearch: InputMaybe<Scalars['Boolean']['input']>;
  providesLegalResearch_exists: InputMaybe<Scalars['Boolean']['input']>;
  providesLegalResearch_not: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showEquivalentToMonthlyPricing: InputMaybe<Scalars['Boolean']['input']>;
  showEquivalentToMonthlyPricing_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  showEquivalentToMonthlyPricing_not: InputMaybe<Scalars['Boolean']['input']>;
  sidebarAnnualBillingNote: InputMaybe<Scalars['String']['input']>;
  sidebarAnnualBillingNote_contains: InputMaybe<Scalars['String']['input']>;
  sidebarAnnualBillingNote_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarAnnualBillingNote_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarAnnualBillingNote_not: InputMaybe<Scalars['String']['input']>;
  sidebarAnnualBillingNote_not_contains: InputMaybe<Scalars['String']['input']>;
  sidebarAnnualBillingNote_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarInfoBoxText_contains: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxText_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarInfoBoxText_not_contains: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxTitle: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxTitle_contains: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxTitle_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarInfoBoxTitle_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarInfoBoxTitle_not: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxTitle_not_contains: InputMaybe<Scalars['String']['input']>;
  sidebarInfoBoxTitle_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarMonthlyBillingNote: InputMaybe<Scalars['String']['input']>;
  sidebarMonthlyBillingNote_contains: InputMaybe<Scalars['String']['input']>;
  sidebarMonthlyBillingNote_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarMonthlyBillingNote_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarMonthlyBillingNote_not: InputMaybe<Scalars['String']['input']>;
  sidebarMonthlyBillingNote_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  sidebarMonthlyBillingNote_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarPlanOfferingBulletpoints_contains: InputMaybe<
    Scalars['String']['input']
  >;
  sidebarPlanOfferingBulletpoints_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  sidebarPlanOfferingBulletpoints_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  sidebarPlanOfferingLogo_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarPlanSelectionPrompt: InputMaybe<Scalars['String']['input']>;
  sidebarPlanSelectionPrompt_contains: InputMaybe<Scalars['String']['input']>;
  sidebarPlanSelectionPrompt_exists: InputMaybe<Scalars['Boolean']['input']>;
  sidebarPlanSelectionPrompt_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sidebarPlanSelectionPrompt_not: InputMaybe<Scalars['String']['input']>;
  sidebarPlanSelectionPrompt_not_contains: InputMaybe<
    Scalars['String']['input']
  >;
  sidebarPlanSelectionPrompt_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  singleSelectPlanOptions: InputMaybe<ContentfulCfPlanOfferingOptionNestedFilter>;
  singleSelectPlanOptionsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startingAtPrice: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_exists: InputMaybe<Scalars['Boolean']['input']>;
  startingAtPrice_gt: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_gte: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startingAtPrice_lt: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_lte: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_not: InputMaybe<Scalars['Float']['input']>;
  startingAtPrice_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulPlanOfferingLinkingCollections = {
  __typename?: 'ContentfulPlanOfferingLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulPlanOfferingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulPlanOfferingMultipleSelectPlanOptionsCollection = {
  __typename?: 'ContentfulPlanOfferingMultipleSelectPlanOptionsCollection';
  items: Array<Maybe<ContentfulPlanOfferingOption>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPlanOfferingMultipleSelectPlanOptionsCollectionOrder {
  DisplayBestValueStyleAsc = 'displayBestValueStyle_ASC',
  DisplayBestValueStyleDesc = 'displayBestValueStyle_DESC',
  DisplayIncludedItemsLinkAsc = 'displayIncludedItemsLink_ASC',
  DisplayIncludedItemsLinkDesc = 'displayIncludedItemsLink_DESC',
  IncludedItemsLinkTextAsc = 'includedItemsLinkText_ASC',
  IncludedItemsLinkTextDesc = 'includedItemsLinkText_DESC',
  IncludedItemsPopupHeaderAsc = 'includedItemsPopupHeader_ASC',
  IncludedItemsPopupHeaderDesc = 'includedItemsPopupHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlanCodeAsc = 'planCode_ASC',
  PlanCodeDesc = 'planCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOption = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPlanOfferingOption';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    displayBestValueStyle: Maybe<Scalars['Boolean']['output']>;
    displayIncludedItemsLink: Maybe<Scalars['Boolean']['output']>;
    includedItemsContents: Maybe<ContentfulPlanOfferingOptionIncludedItemsContents>;
    includedItemsLinkText: Maybe<Scalars['String']['output']>;
    includedItemsPopupHeader: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulPlanOfferingOptionLinkingCollections>;
    longDescription: Maybe<ContentfulPlanOfferingOptionLongDescription>;
    name: Maybe<Scalars['String']['output']>;
    optionLogo: Maybe<ContentfulAsset>;
    planCode: Maybe<Scalars['String']['output']>;
    productCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    shortDescription: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionDisplayBestValueStyleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionDisplayIncludedItemsLinkArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionIncludedItemsContentsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionIncludedItemsLinkTextArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionIncludedItemsPopupHeaderArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionLongDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionOptionLogoArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionPlanCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionProductCodesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/planOfferingOption) */
export type ContentfulPlanOfferingOptionShortDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPlanOfferingOptionCollection = {
  __typename?: 'ContentfulPlanOfferingOptionCollection';
  items: Array<Maybe<ContentfulPlanOfferingOption>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPlanOfferingOptionFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingOptionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingOptionFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayBestValueStyle: InputMaybe<Scalars['Boolean']['input']>;
  displayBestValueStyle_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayBestValueStyle_not: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink_exists: InputMaybe<Scalars['Boolean']['input']>;
  displayIncludedItemsLink_not: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsContents_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsContents_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsContents_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsLinkText_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsLinkText_not: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsLinkText_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsPopupHeader: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_exists: InputMaybe<Scalars['Boolean']['input']>;
  includedItemsPopupHeader_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  includedItemsPopupHeader_not: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_not_contains: InputMaybe<Scalars['String']['input']>;
  includedItemsPopupHeader_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  longDescription_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  optionLogo_exists: InputMaybe<Scalars['Boolean']['input']>;
  planCode: InputMaybe<Scalars['String']['input']>;
  planCode_contains: InputMaybe<Scalars['String']['input']>;
  planCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  planCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planCode_not: InputMaybe<Scalars['String']['input']>;
  planCode_not_contains: InputMaybe<Scalars['String']['input']>;
  planCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCodes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  productCodes_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulPlanOfferingOptionIncludedItemsContents = {
  __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContents';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulPlanOfferingOptionIncludedItemsContentsLinks;
};

export type ContentfulPlanOfferingOptionIncludedItemsContentsAssets = {
  __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulPlanOfferingOptionIncludedItemsContentsEntries = {
  __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulPlanOfferingOptionIncludedItemsContentsLinks = {
  __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsLinks';
  assets: ContentfulPlanOfferingOptionIncludedItemsContentsAssets;
  entries: ContentfulPlanOfferingOptionIncludedItemsContentsEntries;
  resources: ContentfulPlanOfferingOptionIncludedItemsContentsResources;
};

export type ContentfulPlanOfferingOptionIncludedItemsContentsResources = {
  __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsResources';
  block: Array<ContentfulPlanOfferingOptionIncludedItemsContentsResourcesBlock>;
  hyperlink: Array<ContentfulPlanOfferingOptionIncludedItemsContentsResourcesHyperlink>;
  inline: Array<ContentfulPlanOfferingOptionIncludedItemsContentsResourcesInline>;
};

export type ContentfulPlanOfferingOptionIncludedItemsContentsResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingOptionIncludedItemsContentsResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingOptionIncludedItemsContentsResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionIncludedItemsContentsResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingOptionLinkingCollections = {
  __typename?: 'ContentfulPlanOfferingOptionLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  planOfferingCollection: Maybe<ContentfulPlanOfferingCollection>;
};

export type ContentfulPlanOfferingOptionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPlanOfferingOptionLinkingCollectionsPlanOfferingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPlanOfferingOptionLinkingCollectionsPlanOfferingCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPlanOfferingOptionLinkingCollectionsPlanOfferingCollectionOrder {
  BackLinkTitleAsc = 'backLinkTitle_ASC',
  BackLinkTitleDesc = 'backLinkTitle_DESC',
  BackLinkUrlAsc = 'backLinkUrl_ASC',
  BackLinkUrlDesc = 'backLinkUrl_DESC',
  BillingTypeAsc = 'billingType_ASC',
  BillingTypeDesc = 'billingType_DESC',
  ConfirmationPageButtonLinkAsc = 'confirmationPageButtonLink_ASC',
  ConfirmationPageButtonLinkDesc = 'confirmationPageButtonLink_DESC',
  ConfirmationPageButtonTextAsc = 'confirmationPageButtonText_ASC',
  ConfirmationPageButtonTextDesc = 'confirmationPageButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptionSelectionPromptAsc = 'optionSelectionPrompt_ASC',
  OptionSelectionPromptDesc = 'optionSelectionPrompt_DESC',
  PageHeadingAsc = 'pageHeading_ASC',
  PageHeadingDesc = 'pageHeading_DESC',
  PriceIntervalTextAsc = 'priceIntervalText_ASC',
  PriceIntervalTextDesc = 'priceIntervalText_DESC',
  ProvidesCleAsc = 'providesCLE_ASC',
  ProvidesCleDesc = 'providesCLE_DESC',
  ProvidesLegalResearchAsc = 'providesLegalResearch_ASC',
  ProvidesLegalResearchDesc = 'providesLegalResearch_DESC',
  ShowEquivalentToMonthlyPricingAsc = 'showEquivalentToMonthlyPricing_ASC',
  ShowEquivalentToMonthlyPricingDesc = 'showEquivalentToMonthlyPricing_DESC',
  SidebarAnnualBillingNoteAsc = 'sidebarAnnualBillingNote_ASC',
  SidebarAnnualBillingNoteDesc = 'sidebarAnnualBillingNote_DESC',
  SidebarInfoBoxTitleAsc = 'sidebarInfoBoxTitle_ASC',
  SidebarInfoBoxTitleDesc = 'sidebarInfoBoxTitle_DESC',
  SidebarMonthlyBillingNoteAsc = 'sidebarMonthlyBillingNote_ASC',
  SidebarMonthlyBillingNoteDesc = 'sidebarMonthlyBillingNote_DESC',
  SidebarPlanSelectionPromptAsc = 'sidebarPlanSelectionPrompt_ASC',
  SidebarPlanSelectionPromptDesc = 'sidebarPlanSelectionPrompt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StartingAtPriceAsc = 'startingAtPrice_ASC',
  StartingAtPriceDesc = 'startingAtPrice_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPlanOfferingOptionLongDescription = {
  __typename?: 'ContentfulPlanOfferingOptionLongDescription';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulPlanOfferingOptionLongDescriptionLinks;
};

export type ContentfulPlanOfferingOptionLongDescriptionAssets = {
  __typename?: 'ContentfulPlanOfferingOptionLongDescriptionAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulPlanOfferingOptionLongDescriptionEntries = {
  __typename?: 'ContentfulPlanOfferingOptionLongDescriptionEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulPlanOfferingOptionLongDescriptionLinks = {
  __typename?: 'ContentfulPlanOfferingOptionLongDescriptionLinks';
  assets: ContentfulPlanOfferingOptionLongDescriptionAssets;
  entries: ContentfulPlanOfferingOptionLongDescriptionEntries;
  resources: ContentfulPlanOfferingOptionLongDescriptionResources;
};

export type ContentfulPlanOfferingOptionLongDescriptionResources = {
  __typename?: 'ContentfulPlanOfferingOptionLongDescriptionResources';
  block: Array<ContentfulPlanOfferingOptionLongDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulPlanOfferingOptionLongDescriptionResourcesHyperlink>;
  inline: Array<ContentfulPlanOfferingOptionLongDescriptionResourcesInline>;
};

export type ContentfulPlanOfferingOptionLongDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionLongDescriptionResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingOptionLongDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionLongDescriptionResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingOptionLongDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingOptionLongDescriptionResourcesInline';
    sys: ContentfulResourceSys;
  };

export enum ContentfulPlanOfferingOptionOrder {
  DisplayBestValueStyleAsc = 'displayBestValueStyle_ASC',
  DisplayBestValueStyleDesc = 'displayBestValueStyle_DESC',
  DisplayIncludedItemsLinkAsc = 'displayIncludedItemsLink_ASC',
  DisplayIncludedItemsLinkDesc = 'displayIncludedItemsLink_DESC',
  IncludedItemsLinkTextAsc = 'includedItemsLinkText_ASC',
  IncludedItemsLinkTextDesc = 'includedItemsLinkText_DESC',
  IncludedItemsPopupHeaderAsc = 'includedItemsPopupHeader_ASC',
  IncludedItemsPopupHeaderDesc = 'includedItemsPopupHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlanCodeAsc = 'planCode_ASC',
  PlanCodeDesc = 'planCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulPlanOfferingOrder {
  BackLinkTitleAsc = 'backLinkTitle_ASC',
  BackLinkTitleDesc = 'backLinkTitle_DESC',
  BackLinkUrlAsc = 'backLinkUrl_ASC',
  BackLinkUrlDesc = 'backLinkUrl_DESC',
  BillingTypeAsc = 'billingType_ASC',
  BillingTypeDesc = 'billingType_DESC',
  ConfirmationPageButtonLinkAsc = 'confirmationPageButtonLink_ASC',
  ConfirmationPageButtonLinkDesc = 'confirmationPageButtonLink_DESC',
  ConfirmationPageButtonTextAsc = 'confirmationPageButtonText_ASC',
  ConfirmationPageButtonTextDesc = 'confirmationPageButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptionSelectionPromptAsc = 'optionSelectionPrompt_ASC',
  OptionSelectionPromptDesc = 'optionSelectionPrompt_DESC',
  PageHeadingAsc = 'pageHeading_ASC',
  PageHeadingDesc = 'pageHeading_DESC',
  PriceIntervalTextAsc = 'priceIntervalText_ASC',
  PriceIntervalTextDesc = 'priceIntervalText_DESC',
  ProvidesCleAsc = 'providesCLE_ASC',
  ProvidesCleDesc = 'providesCLE_DESC',
  ProvidesLegalResearchAsc = 'providesLegalResearch_ASC',
  ProvidesLegalResearchDesc = 'providesLegalResearch_DESC',
  ShowEquivalentToMonthlyPricingAsc = 'showEquivalentToMonthlyPricing_ASC',
  ShowEquivalentToMonthlyPricingDesc = 'showEquivalentToMonthlyPricing_DESC',
  SidebarAnnualBillingNoteAsc = 'sidebarAnnualBillingNote_ASC',
  SidebarAnnualBillingNoteDesc = 'sidebarAnnualBillingNote_DESC',
  SidebarInfoBoxTitleAsc = 'sidebarInfoBoxTitle_ASC',
  SidebarInfoBoxTitleDesc = 'sidebarInfoBoxTitle_DESC',
  SidebarMonthlyBillingNoteAsc = 'sidebarMonthlyBillingNote_ASC',
  SidebarMonthlyBillingNoteDesc = 'sidebarMonthlyBillingNote_DESC',
  SidebarPlanSelectionPromptAsc = 'sidebarPlanSelectionPrompt_ASC',
  SidebarPlanSelectionPromptDesc = 'sidebarPlanSelectionPrompt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StartingAtPriceAsc = 'startingAtPrice_ASC',
  StartingAtPriceDesc = 'startingAtPrice_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPlanOfferingSidebarInfoBoxText = {
  __typename?: 'ContentfulPlanOfferingSidebarInfoBoxText';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulPlanOfferingSidebarInfoBoxTextLinks;
};

export type ContentfulPlanOfferingSidebarInfoBoxTextAssets = {
  __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulPlanOfferingSidebarInfoBoxTextEntries = {
  __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulPlanOfferingSidebarInfoBoxTextLinks = {
  __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextLinks';
  assets: ContentfulPlanOfferingSidebarInfoBoxTextAssets;
  entries: ContentfulPlanOfferingSidebarInfoBoxTextEntries;
  resources: ContentfulPlanOfferingSidebarInfoBoxTextResources;
};

export type ContentfulPlanOfferingSidebarInfoBoxTextResources = {
  __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextResources';
  block: Array<ContentfulPlanOfferingSidebarInfoBoxTextResourcesBlock>;
  hyperlink: Array<ContentfulPlanOfferingSidebarInfoBoxTextResourcesHyperlink>;
  inline: Array<ContentfulPlanOfferingSidebarInfoBoxTextResourcesInline>;
};

export type ContentfulPlanOfferingSidebarInfoBoxTextResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSidebarInfoBoxTextResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSidebarInfoBoxTextResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarInfoBoxTextResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpoints = {
  __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpoints';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulPlanOfferingSidebarPlanOfferingBulletpointsLinks;
};

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsAssets = {
  __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsEntries = {
  __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsLinks = {
  __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsLinks';
  assets: ContentfulPlanOfferingSidebarPlanOfferingBulletpointsAssets;
  entries: ContentfulPlanOfferingSidebarPlanOfferingBulletpointsEntries;
  resources: ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResources;
};

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResources = {
  __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResources';
  block: Array<ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesBlock>;
  hyperlink: Array<ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesHyperlink>;
  inline: Array<ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesInline>;
};

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulPlanOfferingSidebarPlanOfferingBulletpointsResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulPlanOfferingSingleSelectPlanOptionsCollection = {
  __typename?: 'ContentfulPlanOfferingSingleSelectPlanOptionsCollection';
  items: Array<Maybe<ContentfulPlanOfferingOption>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPlanOfferingSingleSelectPlanOptionsCollectionOrder {
  DisplayBestValueStyleAsc = 'displayBestValueStyle_ASC',
  DisplayBestValueStyleDesc = 'displayBestValueStyle_DESC',
  DisplayIncludedItemsLinkAsc = 'displayIncludedItemsLink_ASC',
  DisplayIncludedItemsLinkDesc = 'displayIncludedItemsLink_DESC',
  IncludedItemsLinkTextAsc = 'includedItemsLinkText_ASC',
  IncludedItemsLinkTextDesc = 'includedItemsLinkText_DESC',
  IncludedItemsPopupHeaderAsc = 'includedItemsPopupHeader_ASC',
  IncludedItemsPopupHeaderDesc = 'includedItemsPopupHeader_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlanCodeAsc = 'planCode_ASC',
  PlanCodeDesc = 'planCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeArea = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPracticeArea';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    description: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulPracticeAreaLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    practiceAreaSubtopicsCollection: Maybe<ContentfulPracticeAreaPracticeAreaSubtopicsCollection>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeAreaDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeAreaLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeAreaNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeAreaPracticeAreaSubtopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPracticeAreaPracticeAreaSubtopicsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practiceArea) */
export type ContentfulPracticeAreaSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPracticeAreaCollection = {
  __typename?: 'ContentfulPracticeAreaCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPracticeAreaFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPracticeAreaFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPracticeAreaFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceAreaSubtopics: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreaSubtopicsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulPracticeAreaLinkingCollections = {
  __typename?: 'ContentfulPracticeAreaLinkingCollections';
  contributorCollection: Maybe<ContentfulContributorCollection>;
  courseCollection: Maybe<ContentfulCourseCollection>;
  coursesCollectionCollection: Maybe<ContentfulCoursesCollectionCollection>;
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  knowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  knowHowProductCollection: Maybe<ContentfulKnowHowProductCollection>;
  practiceAreaCollection: Maybe<ContentfulPracticeAreaCollection>;
  practitionerWorkflowCollection: Maybe<ContentfulPractitionerWorkflowCollection>;
  programCollection: Maybe<ContentfulProgramCollection>;
  publicationBookCollection: Maybe<ContentfulPublicationBookCollection>;
  trueDocsAndFormsCollection: Maybe<ContentfulTrueDocsAndFormsCollection>;
  trueDocsFormsCollectionsCollection: Maybe<ContentfulTrueDocsFormsCollectionsCollection>;
};

export type ContentfulPracticeAreaLinkingCollectionsContributorCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsContributorCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPracticeAreaLinkingCollectionsCourseCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulPracticeAreaLinkingCollectionsCoursesCollectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsCoursesCollectionCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsCurrentAwarenessCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsCurrentAwarenessCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulPracticeAreaLinkingCollectionsKnowHowDocumentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsKnowHowDocumentCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsKnowHowProductCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsKnowHowProductCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsPracticeAreaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsPracticeAreaCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsPractitionerWorkflowCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsPractitionerWorkflowCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsProgramCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPracticeAreaLinkingCollectionsProgramCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulPracticeAreaLinkingCollectionsPublicationBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsPublicationBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsTrueDocsAndFormsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsTrueDocsAndFormsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPracticeAreaLinkingCollectionsTrueDocsFormsCollectionsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPracticeAreaLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPracticeAreaLinkingCollectionsContributorCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsCourseCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  DifficultyLevelAsc = 'difficultyLevel_ASC',
  DifficultyLevelDesc = 'difficultyLevel_DESC',
  PanoptoIdAsc = 'panoptoId_ASC',
  PanoptoIdDesc = 'panoptoId_DESC',
  ParticipatoryCreditCodeAsc = 'participatoryCreditCode_ASC',
  ParticipatoryCreditCodeDesc = 'participatoryCreditCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SalesforceStagingIdAsc = 'salesforceStagingId_ASC',
  SalesforceStagingIdDesc = 'salesforceStagingId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateBarCourseFormatCodeAsc = 'stateBarCourseFormatCode_ASC',
  StateBarCourseFormatCodeDesc = 'stateBarCourseFormatCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalCreditsAsc = 'totalCredits_ASC',
  TotalCreditsDesc = 'totalCredits_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsCoursesCollectionCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CompliancePackageProductCodeAsc = 'compliancePackageProductCode_ASC',
  CompliancePackageProductCodeDesc = 'compliancePackageProductCode_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsKnowHowDocumentCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsKnowHowProductCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProductCodeAsc = 'productCode_ASC',
  ProductCodeDesc = 'productCode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsPracticeAreaCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsPractitionerWorkflowCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsProgramCollectionOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  LitmosIdAsc = 'litmosId_ASC',
  LitmosIdDesc = 'litmosId_DESC',
  LmsUrlAsc = 'lmsUrl_ASC',
  LmsUrlDesc = 'lmsUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProgramCodeAsc = 'programCode_ASC',
  ProgramCodeDesc = 'programCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalMcleCreditsAsc = 'totalMcleCredits_ASC',
  TotalMcleCreditsDesc = 'totalMcleCredits_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsPublicationBookCollectionOrder {
  LiveAsc = 'live_ASC',
  LiveDesc = 'live_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  OnlawProProductCodeAsc = 'onlawProProductCode_ASC',
  OnlawProProductCodeDesc = 'onlawProProductCode_DESC',
  OnlawProductCodeAsc = 'onlawProductCode_ASC',
  OnlawProductCodeDesc = 'onlawProductCode_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsTrueDocsAndFormsCollectionOrder {
  DocspringTemplateIdAsc = 'docspringTemplateId_ASC',
  DocspringTemplateIdDesc = 'docspringTemplateId_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  MandatoryJcFormAsc = 'mandatoryJcForm_ASC',
  MandatoryJcFormDesc = 'mandatoryJcForm_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormSubtypeAsc = 'trueFormSubtype_ASC',
  TrueFormSubtypeDesc = 'trueFormSubtype_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulPracticeAreaLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulPracticeAreaOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPracticeAreaPracticeAreaSubtopicsCollection = {
  __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPracticeAreaPracticeAreaSubtopicsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflow = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPractitionerWorkflow';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    description: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulPractitionerWorkflowLinkingCollections>;
    originalPublishDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    practiceAreasBrowseCollection: Maybe<ContentfulPractitionerWorkflowPracticeAreasBrowseCollection>;
    practiceAreasCollection: Maybe<ContentfulPractitionerWorkflowPracticeAreasCollection>;
    practitionerDocumentType: Maybe<ContentfulDocumentType>;
    sectionGroupsCollection: Maybe<ContentfulPractitionerWorkflowSectionGroupsCollection>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowOriginalPublishDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowPracticeAreasBrowseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPractitionerWorkflowPracticeAreasBrowseCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPractitionerWorkflowPracticeAreasCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowPractitionerDocumentTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulDocumentTypeFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowSectionGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPractitionerWorkflowSectionGroupsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPractitionerWorkflowSectionGroupFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflow) */
export type ContentfulPractitionerWorkflowTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPractitionerWorkflowCollection = {
  __typename?: 'ContentfulPractitionerWorkflowCollection';
  items: Array<Maybe<ContentfulPractitionerWorkflow>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPractitionerWorkflowFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPractitionerWorkflowFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPractitionerWorkflowFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']['input']>;
  description_contains: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not: InputMaybe<Scalars['String']['input']>;
  description_not_contains: InputMaybe<Scalars['String']['input']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalPublishDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  originalPublishDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  originalPublishDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  originalPublishDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasBrowse: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasBrowseCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  practitionerDocumentType: InputMaybe<ContentfulCfDocumentTypeNestedFilter>;
  practitionerDocumentType_exists: InputMaybe<Scalars['Boolean']['input']>;
  sectionGroups: InputMaybe<ContentfulCfPractitionerWorkflowSectionGroupNestedFilter>;
  sectionGroupsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulPractitionerWorkflowLinkingCollections = {
  __typename?: 'ContentfulPractitionerWorkflowLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulPractitionerWorkflowLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPractitionerWorkflowOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulPractitionerWorkflowPracticeAreasBrowseCollection = {
  __typename?: 'ContentfulPractitionerWorkflowPracticeAreasBrowseCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPractitionerWorkflowPracticeAreasBrowseCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPractitionerWorkflowPracticeAreasCollection = {
  __typename?: 'ContentfulPractitionerWorkflowPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPractitionerWorkflowPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionGroup) */
export type ContentfulPractitionerWorkflowSectionGroup = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPractitionerWorkflowSectionGroup';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPractitionerWorkflowSectionGroupLinkingCollections>;
    sectionItemsCollection: Maybe<ContentfulPractitionerWorkflowSectionGroupSectionItemsCollection>;
    sectionName: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    timeframe: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionGroup) */
export type ContentfulPractitionerWorkflowSectionGroupLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionGroup) */
export type ContentfulPractitionerWorkflowSectionGroupSectionItemsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPractitionerWorkflowSectionGroupSectionItemsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where: InputMaybe<ContentfulPractitionerWorkflowSectionItemsFilter>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionGroup) */
export type ContentfulPractitionerWorkflowSectionGroupSectionNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionGroup) */
export type ContentfulPractitionerWorkflowSectionGroupTimeframeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPractitionerWorkflowSectionGroupCollection = {
  __typename?: 'ContentfulPractitionerWorkflowSectionGroupCollection';
  items: Array<Maybe<ContentfulPractitionerWorkflowSectionGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPractitionerWorkflowSectionGroupFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionGroupFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionGroupFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  sectionItems: InputMaybe<ContentfulCfPractitionerWorkflowSectionItemsNestedFilter>;
  sectionItemsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sectionName: InputMaybe<Scalars['String']['input']>;
  sectionName_contains: InputMaybe<Scalars['String']['input']>;
  sectionName_exists: InputMaybe<Scalars['Boolean']['input']>;
  sectionName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionName_not: InputMaybe<Scalars['String']['input']>;
  sectionName_not_contains: InputMaybe<Scalars['String']['input']>;
  sectionName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  timeframe: InputMaybe<Scalars['String']['input']>;
  timeframe_contains: InputMaybe<Scalars['String']['input']>;
  timeframe_exists: InputMaybe<Scalars['Boolean']['input']>;
  timeframe_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeframe_not: InputMaybe<Scalars['String']['input']>;
  timeframe_not_contains: InputMaybe<Scalars['String']['input']>;
  timeframe_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulPractitionerWorkflowSectionGroupLinkingCollections = {
  __typename?: 'ContentfulPractitionerWorkflowSectionGroupLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  practitionerWorkflowCollection: Maybe<ContentfulPractitionerWorkflowCollection>;
};

export type ContentfulPractitionerWorkflowSectionGroupLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPractitionerWorkflowSectionGroupLinkingCollectionsPractitionerWorkflowCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPractitionerWorkflowSectionGroupLinkingCollectionsPractitionerWorkflowCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPractitionerWorkflowSectionGroupLinkingCollectionsPractitionerWorkflowCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulPractitionerWorkflowSectionGroupOrder {
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeframeAsc = 'timeframe_ASC',
  TimeframeDesc = 'timeframe_DESC',
}

export type ContentfulPractitionerWorkflowSectionGroupSectionItemsCollection = {
  __typename?: 'ContentfulPractitionerWorkflowSectionGroupSectionItemsCollection';
  items: Array<Maybe<ContentfulPractitionerWorkflowSectionItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPractitionerWorkflowSectionGroupSectionItemsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPractitionerWorkflowSectionGroupsCollection = {
  __typename?: 'ContentfulPractitionerWorkflowSectionGroupsCollection';
  items: Array<Maybe<ContentfulPractitionerWorkflowSectionGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPractitionerWorkflowSectionGroupsCollectionOrder {
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeframeAsc = 'timeframe_ASC',
  TimeframeDesc = 'timeframe_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionItems) */
export type ContentfulPractitionerWorkflowSectionItems = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPractitionerWorkflowSectionItems';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPractitionerWorkflowSectionItemsLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    practitionerDocumentsCollection: Maybe<ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollection>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionItems) */
export type ContentfulPractitionerWorkflowSectionItemsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionItems) */
export type ContentfulPractitionerWorkflowSectionItemsNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/practitionerWorkflowSectionItems) */
export type ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where: InputMaybe<ContentfulKnowHowDocumentFilter>;
  };

export type ContentfulPractitionerWorkflowSectionItemsCollection = {
  __typename?: 'ContentfulPractitionerWorkflowSectionItemsCollection';
  items: Array<Maybe<ContentfulPractitionerWorkflowSectionItems>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPractitionerWorkflowSectionItemsFilter = {
  AND: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionItemsFilter>>
  >;
  OR: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionItemsFilter>>
  >;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practitionerDocuments: InputMaybe<ContentfulCfKnowHowDocumentNestedFilter>;
  practitionerDocumentsCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulPractitionerWorkflowSectionItemsLinkingCollections = {
  __typename?: 'ContentfulPractitionerWorkflowSectionItemsLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  practitionerWorkflowSectionGroupCollection: Maybe<ContentfulPractitionerWorkflowSectionGroupCollection>;
};

export type ContentfulPractitionerWorkflowSectionItemsLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPractitionerWorkflowSectionItemsLinkingCollectionsPractitionerWorkflowSectionGroupCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPractitionerWorkflowSectionItemsLinkingCollectionsPractitionerWorkflowSectionGroupCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPractitionerWorkflowSectionItemsLinkingCollectionsPractitionerWorkflowSectionGroupCollectionOrder {
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeframeAsc = 'timeframe_ASC',
  TimeframeDesc = 'timeframe_DESC',
}

export enum ContentfulPractitionerWorkflowSectionItemsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollection =
  {
    __typename?: 'ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollection';
    items: Array<Maybe<ContentfulKnowHowDocument>>;
    limit: Scalars['Int']['output'];
    skip: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
  };

export enum ContentfulPractitionerWorkflowSectionItemsPractitionerDocumentsCollectionOrder {
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgram = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulProgram';
    _id: Scalars['ID']['output'];
    active: Maybe<Scalars['Boolean']['output']>;
    contentfulMetadata: ContentfulContentfulMetadata;
    contributorsCollection: Maybe<ContentfulProgramContributorsCollection>;
    dateRecorded: Maybe<Scalars['ContentfulDateTime']['output']>;
    heroImage: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulProgramLinkingCollections>;
    litmosId: Maybe<Scalars['Int']['output']>;
    lmsUrl: Maybe<Scalars['String']['output']>;
    longDescription: Maybe<Scalars['String']['output']>;
    name: Maybe<Scalars['String']['output']>;
    practiceAreaCollection: Maybe<ContentfulProgramPracticeAreaCollection>;
    programCode: Maybe<Scalars['String']['output']>;
    programMaterialsCollection: Maybe<ContentfulAssetCollection>;
    salesforceId: Maybe<Scalars['String']['output']>;
    shortDescription: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    totalMcleCredits: Maybe<Scalars['Float']['output']>;
    videoUrl: Maybe<Scalars['String']['output']>;
  };

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramActiveArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramContributorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulProgramContributorsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramDateRecordedArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramHeroImageArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramLitmosIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramLmsUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramLongDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramPracticeAreaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulProgramPracticeAreaCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramProgramCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramProgramMaterialsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramSalesforceIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramShortDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramTotalMcleCreditsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Litmose Courses [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/program) */
export type ContentfulProgramVideoUrlArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulProgramCollection = {
  __typename?: 'ContentfulProgramCollection';
  items: Array<Maybe<ContentfulProgram>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulProgramContributorsCollection = {
  __typename?: 'ContentfulProgramContributorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulProgramContributorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulProgramFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulProgramFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulProgramFilter>>>;
  active: InputMaybe<Scalars['Boolean']['input']>;
  active_exists: InputMaybe<Scalars['Boolean']['input']>;
  active_not: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  contributors: InputMaybe<ContentfulCfContributorNestedFilter>;
  contributorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_exists: InputMaybe<Scalars['Boolean']['input']>;
  dateRecorded_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  dateRecorded_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  dateRecorded_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  heroImage_exists: InputMaybe<Scalars['Boolean']['input']>;
  litmosId: InputMaybe<Scalars['Int']['input']>;
  litmosId_exists: InputMaybe<Scalars['Boolean']['input']>;
  litmosId_gt: InputMaybe<Scalars['Int']['input']>;
  litmosId_gte: InputMaybe<Scalars['Int']['input']>;
  litmosId_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  litmosId_lt: InputMaybe<Scalars['Int']['input']>;
  litmosId_lte: InputMaybe<Scalars['Int']['input']>;
  litmosId_not: InputMaybe<Scalars['Int']['input']>;
  litmosId_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lmsUrl: InputMaybe<Scalars['String']['input']>;
  lmsUrl_contains: InputMaybe<Scalars['String']['input']>;
  lmsUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  lmsUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lmsUrl_not: InputMaybe<Scalars['String']['input']>;
  lmsUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  lmsUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longDescription: InputMaybe<Scalars['String']['input']>;
  longDescription_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  longDescription_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longDescription_not: InputMaybe<Scalars['String']['input']>;
  longDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  longDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  practiceArea: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreaCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  programCode: InputMaybe<Scalars['String']['input']>;
  programCode_contains: InputMaybe<Scalars['String']['input']>;
  programCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  programCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programCode_not: InputMaybe<Scalars['String']['input']>;
  programCode_not_contains: InputMaybe<Scalars['String']['input']>;
  programCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programMaterialsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId: InputMaybe<Scalars['String']['input']>;
  salesforceId_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_exists: InputMaybe<Scalars['Boolean']['input']>;
  salesforceId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforceId_not: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_contains: InputMaybe<Scalars['String']['input']>;
  salesforceId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys: InputMaybe<ContentfulSysFilter>;
  totalMcleCredits: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_exists: InputMaybe<Scalars['Boolean']['input']>;
  totalMcleCredits_gt: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_gte: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalMcleCredits_lt: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_lte: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_not: InputMaybe<Scalars['Float']['input']>;
  totalMcleCredits_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  videoUrl: InputMaybe<Scalars['String']['input']>;
  videoUrl_contains: InputMaybe<Scalars['String']['input']>;
  videoUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  videoUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  videoUrl_not: InputMaybe<Scalars['String']['input']>;
  videoUrl_not_contains: InputMaybe<Scalars['String']['input']>;
  videoUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulProgramLinkingCollections = {
  __typename?: 'ContentfulProgramLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulProgramLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulProgramOrder {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateRecordedAsc = 'dateRecorded_ASC',
  DateRecordedDesc = 'dateRecorded_DESC',
  LitmosIdAsc = 'litmosId_ASC',
  LitmosIdDesc = 'litmosId_DESC',
  LmsUrlAsc = 'lmsUrl_ASC',
  LmsUrlDesc = 'lmsUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProgramCodeAsc = 'programCode_ASC',
  ProgramCodeDesc = 'programCode_DESC',
  SalesforceIdAsc = 'salesforceId_ASC',
  SalesforceIdDesc = 'salesforceId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TotalMcleCreditsAsc = 'totalMcleCredits_ASC',
  TotalMcleCreditsDesc = 'totalMcleCredits_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
}

export type ContentfulProgramPracticeAreaCollection = {
  __typename?: 'ContentfulProgramPracticeAreaCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulProgramPracticeAreaCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBook = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPublicationBook';
    _id: Scalars['ID']['output'];
    additionalPracticeAreaLibrariesCollection: Maybe<ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollection>;
    archivedEditionsCollection: Maybe<ContentfulPublicationBookArchivedEditionsCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPublicationBookLinkingCollections>;
    live: Maybe<Scalars['Boolean']['output']>;
    liveEditionsCollection: Maybe<ContentfulPublicationBookLiveEditionsCollection>;
    name: Maybe<Scalars['String']['output']>;
    onLawId: Maybe<Scalars['String']['output']>;
    onlawProProductCode: Maybe<Scalars['String']['output']>;
    onlawProductCode: Maybe<Scalars['String']['output']>;
    primaryPracticeAreaLibrary: Maybe<ContentfulPracticeArea>;
    publicationResourceType: Maybe<ContentfulPublicationBookType>;
    shortName: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    titleId: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
    where: InputMaybe<ContentfulPracticeAreaFilter>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookArchivedEditionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPublicationBookArchivedEditionsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationEditionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookLiveArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookLiveEditionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPublicationBookLiveEditionsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationEditionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookOnLawIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookOnlawProProductCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookOnlawProductCodeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookPrimaryPracticeAreaLibraryArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookPublicationResourceTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulPublicationBookTypeFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookShortNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBook) */
export type ContentfulPublicationBookTitleIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollection =
  {
    __typename?: 'ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollection';
    items: Array<Maybe<ContentfulPracticeArea>>;
    limit: Scalars['Int']['output'];
    skip: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
  };

export enum ContentfulPublicationBookAdditionalPracticeAreaLibrariesCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationBookArchivedEditionsCollection = {
  __typename?: 'ContentfulPublicationBookArchivedEditionsCollection';
  items: Array<Maybe<ContentfulPublicationEdition>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationBookArchivedEditionsCollectionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationBookCollection = {
  __typename?: 'ContentfulPublicationBookCollection';
  items: Array<Maybe<ContentfulPublicationBook>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPublicationBookFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPublicationBookFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPublicationBookFilter>>>;
  additionalPracticeAreaLibraries: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  additionalPracticeAreaLibrariesCollection_exists: InputMaybe<
    Scalars['Boolean']['input']
  >;
  archivedEditions: InputMaybe<ContentfulCfPublicationEditionNestedFilter>;
  archivedEditionsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  live: InputMaybe<Scalars['Boolean']['input']>;
  liveEditions: InputMaybe<ContentfulCfPublicationEditionNestedFilter>;
  liveEditionsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  live_exists: InputMaybe<Scalars['Boolean']['input']>;
  live_not: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onlawProProductCode: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_contains: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  onlawProProductCode_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProProductCode_not: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_not_contains: InputMaybe<Scalars['String']['input']>;
  onlawProProductCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProductCode: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_contains: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  onlawProductCode_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  onlawProductCode_not: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_not_contains: InputMaybe<Scalars['String']['input']>;
  onlawProductCode_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  primaryPracticeAreaLibrary: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  primaryPracticeAreaLibrary_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationResourceType: InputMaybe<ContentfulCfPublicationBookTypeNestedFilter>;
  publicationResourceType_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortName: InputMaybe<Scalars['String']['input']>;
  shortName_contains: InputMaybe<Scalars['String']['input']>;
  shortName_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shortName_not: InputMaybe<Scalars['String']['input']>;
  shortName_not_contains: InputMaybe<Scalars['String']['input']>;
  shortName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  titleId: InputMaybe<Scalars['String']['input']>;
  titleId_contains: InputMaybe<Scalars['String']['input']>;
  titleId_exists: InputMaybe<Scalars['Boolean']['input']>;
  titleId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleId_not: InputMaybe<Scalars['String']['input']>;
  titleId_not_contains: InputMaybe<Scalars['String']['input']>;
  titleId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulPublicationBookLinkingCollections = {
  __typename?: 'ContentfulPublicationBookLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  publicationEditionCollection: Maybe<ContentfulPublicationEditionCollection>;
};

export type ContentfulPublicationBookLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulPublicationBookLinkingCollectionsPublicationEditionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPublicationBookLinkingCollectionsPublicationEditionCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPublicationBookLinkingCollectionsPublicationEditionCollectionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationBookLiveEditionsCollection = {
  __typename?: 'ContentfulPublicationBookLiveEditionsCollection';
  items: Array<Maybe<ContentfulPublicationEdition>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationBookLiveEditionsCollectionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulPublicationBookOrder {
  LiveAsc = 'live_ASC',
  LiveDesc = 'live_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  OnlawProProductCodeAsc = 'onlawProProductCode_ASC',
  OnlawProProductCodeDesc = 'onlawProProductCode_DESC',
  OnlawProductCodeAsc = 'onlawProductCode_ASC',
  OnlawProductCodeDesc = 'onlawProductCode_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBookType) */
export type ContentfulPublicationBookType = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPublicationBookType';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPublicationBookTypeLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBookType) */
export type ContentfulPublicationBookTypeLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBookType) */
export type ContentfulPublicationBookTypeNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationBookType) */
export type ContentfulPublicationBookTypeSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulPublicationBookTypeCollection = {
  __typename?: 'ContentfulPublicationBookTypeCollection';
  items: Array<Maybe<ContentfulPublicationBookType>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPublicationBookTypeFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPublicationBookTypeFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPublicationBookTypeFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulPublicationBookTypeLinkingCollections = {
  __typename?: 'ContentfulPublicationBookTypeLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  publicationBookCollection: Maybe<ContentfulPublicationBookCollection>;
};

export type ContentfulPublicationBookTypeLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPublicationBookTypeLinkingCollectionsPublicationBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPublicationBookTypeLinkingCollectionsPublicationBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPublicationBookTypeLinkingCollectionsPublicationBookCollectionOrder {
  LiveAsc = 'live_ASC',
  LiveDesc = 'live_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  OnlawProProductCodeAsc = 'onlawProProductCode_ASC',
  OnlawProProductCodeDesc = 'onlawProProductCode_DESC',
  OnlawProductCodeAsc = 'onlawProductCode_ASC',
  OnlawProductCodeDesc = 'onlawProductCode_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
}

export enum ContentfulPublicationBookTypeOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEdition = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPublicationEdition';
    _id: Scalars['ID']['output'];
    chaptersCollection: Maybe<ContentfulPublicationEditionChaptersCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    edition: Maybe<Scalars['String']['output']>;
    issueId: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulPublicationEditionLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    originalAuthorsCollection: Maybe<ContentfulPublicationEditionOriginalAuthorsCollection>;
    publication: Maybe<ContentfulPublicationBook>;
    publicationDate: Maybe<Scalars['ContentfulDateTime']['output']>;
    sys: ContentfulSys;
    updateAuthorsCollection: Maybe<ContentfulPublicationEditionUpdateAuthorsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionChaptersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPublicationEditionChaptersCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationEditionChapterFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionEditionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionIssueIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionOriginalAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPublicationEditionOriginalAuthorsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionPublicationArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulPublicationBookFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionPublicationDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEdition) */
export type ContentfulPublicationEditionUpdateAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPublicationEditionUpdateAuthorsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapter = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulPublicationEditionChapter';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulPublicationEditionChapterLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    onLawId: Maybe<Scalars['String']['output']>;
    originalAuthorsCollection: Maybe<ContentfulPublicationEditionChapterOriginalAuthorsCollection>;
    sys: ContentfulSys;
    updateAuthorsCollection: Maybe<ContentfulPublicationEditionChapterUpdateAuthorsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapterLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapterNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapterOnLawIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapterOriginalAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPublicationEditionChapterOriginalAuthorsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/publicationEditionChapter) */
export type ContentfulPublicationEditionChapterUpdateAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulPublicationEditionChapterUpdateAuthorsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

export type ContentfulPublicationEditionChapterCollection = {
  __typename?: 'ContentfulPublicationEditionChapterCollection';
  items: Array<Maybe<ContentfulPublicationEditionChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPublicationEditionChapterFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPublicationEditionChapterFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPublicationEditionChapterFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId: InputMaybe<Scalars['String']['input']>;
  onLawId_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_exists: InputMaybe<Scalars['Boolean']['input']>;
  onLawId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onLawId_not: InputMaybe<Scalars['String']['input']>;
  onLawId_not_contains: InputMaybe<Scalars['String']['input']>;
  onLawId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalAuthors: InputMaybe<ContentfulCfContributorNestedFilter>;
  originalAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  updateAuthors: InputMaybe<ContentfulCfContributorNestedFilter>;
  updateAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulPublicationEditionChapterLinkingCollections = {
  __typename?: 'ContentfulPublicationEditionChapterLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  publicationEditionCollection: Maybe<ContentfulPublicationEditionCollection>;
};

export type ContentfulPublicationEditionChapterLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPublicationEditionChapterLinkingCollectionsPublicationEditionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPublicationEditionChapterLinkingCollectionsPublicationEditionCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPublicationEditionChapterLinkingCollectionsPublicationEditionCollectionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ContentfulPublicationEditionChapterOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationEditionChapterOriginalAuthorsCollection = {
  __typename?: 'ContentfulPublicationEditionChapterOriginalAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationEditionChapterOriginalAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulPublicationEditionChapterUpdateAuthorsCollection = {
  __typename?: 'ContentfulPublicationEditionChapterUpdateAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationEditionChapterUpdateAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulPublicationEditionChaptersCollection = {
  __typename?: 'ContentfulPublicationEditionChaptersCollection';
  items: Array<Maybe<ContentfulPublicationEditionChapter>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationEditionChaptersCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationEditionCollection = {
  __typename?: 'ContentfulPublicationEditionCollection';
  items: Array<Maybe<ContentfulPublicationEdition>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulPublicationEditionFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulPublicationEditionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulPublicationEditionFilter>>>;
  chapters: InputMaybe<ContentfulCfPublicationEditionChapterNestedFilter>;
  chaptersCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  edition: InputMaybe<Scalars['String']['input']>;
  edition_contains: InputMaybe<Scalars['String']['input']>;
  edition_exists: InputMaybe<Scalars['Boolean']['input']>;
  edition_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  edition_not: InputMaybe<Scalars['String']['input']>;
  edition_not_contains: InputMaybe<Scalars['String']['input']>;
  edition_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  issueId: InputMaybe<Scalars['String']['input']>;
  issueId_contains: InputMaybe<Scalars['String']['input']>;
  issueId_exists: InputMaybe<Scalars['Boolean']['input']>;
  issueId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  issueId_not: InputMaybe<Scalars['String']['input']>;
  issueId_not_contains: InputMaybe<Scalars['String']['input']>;
  issueId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalAuthors: InputMaybe<ContentfulCfContributorNestedFilter>;
  originalAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  publication: InputMaybe<ContentfulCfPublicationBookNestedFilter>;
  publicationDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  publicationDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publicationDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publicationDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publication_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  updateAuthors: InputMaybe<ContentfulCfContributorNestedFilter>;
  updateAuthorsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulPublicationEditionLinkingCollections = {
  __typename?: 'ContentfulPublicationEditionLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  publicationBookCollection: Maybe<ContentfulPublicationBookCollection>;
};

export type ContentfulPublicationEditionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type ContentfulPublicationEditionLinkingCollectionsPublicationBookCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulPublicationEditionLinkingCollectionsPublicationBookCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulPublicationEditionLinkingCollectionsPublicationBookCollectionOrder {
  LiveAsc = 'live_ASC',
  LiveDesc = 'live_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OnLawIdAsc = 'onLawId_ASC',
  OnLawIdDesc = 'onLawId_DESC',
  OnlawProProductCodeAsc = 'onlawProProductCode_ASC',
  OnlawProProductCodeDesc = 'onlawProProductCode_DESC',
  OnlawProductCodeAsc = 'onlawProductCode_ASC',
  OnlawProductCodeDesc = 'onlawProductCode_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleIdAsc = 'titleId_ASC',
  TitleIdDesc = 'titleId_DESC',
}

export enum ContentfulPublicationEditionOrder {
  EditionAsc = 'edition_ASC',
  EditionDesc = 'edition_DESC',
  IssueIdAsc = 'issueId_ASC',
  IssueIdDesc = 'issueId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulPublicationEditionOriginalAuthorsCollection = {
  __typename?: 'ContentfulPublicationEditionOriginalAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationEditionOriginalAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulPublicationEditionUpdateAuthorsCollection = {
  __typename?: 'ContentfulPublicationEditionUpdateAuthorsCollection';
  items: Array<Maybe<ContentfulContributor>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulPublicationEditionUpdateAuthorsCollectionOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  DisplayInContributorDirectoryAsc = 'displayInContributorDirectory_ASC',
  DisplayInContributorDirectoryDesc = 'displayInContributorDirectory_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  JudgeAsc = 'judge_ASC',
  JudgeDesc = 'judge_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SalesforceContactLinkAsc = 'salesforceContactLink_ASC',
  SalesforceContactLinkDesc = 'salesforceContactLink_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebDisplayNameAsc = 'webDisplayName_ASC',
  WebDisplayNameDesc = 'webDisplayName_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

export type ContentfulResourceLink = {
  sys: ContentfulResourceSys;
};

export type ContentfulResourceSys = {
  __typename?: 'ContentfulResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

/** Simple content pages (terms & conditions, privacy policy, etc) [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/simplePage) */
export type ContentfulSimplePage = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulSimplePage';
    _id: Scalars['ID']['output'];
    content: Maybe<ContentfulSimplePageContent>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulSimplePageLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    updatedDate: Maybe<Scalars['ContentfulDateTime']['output']>;
  };

/** Simple content pages (terms & conditions, privacy policy, etc) [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/simplePage) */
export type ContentfulSimplePageContentArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Simple content pages (terms & conditions, privacy policy, etc) [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/simplePage) */
export type ContentfulSimplePageLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Simple content pages (terms & conditions, privacy policy, etc) [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/simplePage) */
export type ContentfulSimplePageTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** Simple content pages (terms & conditions, privacy policy, etc) [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/simplePage) */
export type ContentfulSimplePageUpdatedDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulSimplePageCollection = {
  __typename?: 'ContentfulSimplePageCollection';
  items: Array<Maybe<ContentfulSimplePage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulSimplePageContent = {
  __typename?: 'ContentfulSimplePageContent';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulSimplePageContentLinks;
};

export type ContentfulSimplePageContentAssets = {
  __typename?: 'ContentfulSimplePageContentAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulSimplePageContentEntries = {
  __typename?: 'ContentfulSimplePageContentEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulSimplePageContentLinks = {
  __typename?: 'ContentfulSimplePageContentLinks';
  assets: ContentfulSimplePageContentAssets;
  entries: ContentfulSimplePageContentEntries;
  resources: ContentfulSimplePageContentResources;
};

export type ContentfulSimplePageContentResources = {
  __typename?: 'ContentfulSimplePageContentResources';
  block: Array<ContentfulSimplePageContentResourcesBlock>;
  hyperlink: Array<ContentfulSimplePageContentResourcesHyperlink>;
  inline: Array<ContentfulSimplePageContentResourcesInline>;
};

export type ContentfulSimplePageContentResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulSimplePageContentResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulSimplePageContentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulSimplePageContentResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulSimplePageContentResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulSimplePageContentResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulSimplePageFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulSimplePageFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulSimplePageFilter>>>;
  content_contains: InputMaybe<Scalars['String']['input']>;
  content_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

export type ContentfulSimplePageLinkingCollections = {
  __typename?: 'ContentfulSimplePageLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulSimplePageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulSimplePageOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulSys = {
  __typename?: 'ContentfulSys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt: Maybe<Scalars['ContentfulDateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested. */
  locale: Maybe<Scalars['String']['output']>;
  publishedAt: Maybe<Scalars['ContentfulDateTime']['output']>;
  publishedVersion: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type ContentfulSysFilter = {
  firstPublishedAt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  firstPublishedAt_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  firstPublishedAt_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  id: InputMaybe<Scalars['String']['input']>;
  id_contains: InputMaybe<Scalars['String']['input']>;
  id_exists: InputMaybe<Scalars['Boolean']['input']>;
  id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not: InputMaybe<Scalars['String']['input']>;
  id_not_contains: InputMaybe<Scalars['String']['input']>;
  id_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publishedAt_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  publishedAt_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  publishedVersion: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  publishedVersion_lt: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type ContentfulTaxonomyConcept = {
  __typename?: 'ContentfulTaxonomyConcept';
  id: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/topic) */
export type ContentfulTopic = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulTopic';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulTopicLinkingCollections>;
    name: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    type: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/topic) */
export type ContentfulTopicLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/topic) */
export type ContentfulTopicNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/topic) */
export type ContentfulTopicSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/topic) */
export type ContentfulTopicTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulTopicCollection = {
  __typename?: 'ContentfulTopicCollection';
  items: Array<Maybe<ContentfulTopic>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulTopicFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  name: InputMaybe<Scalars['String']['input']>;
  name_contains: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not: InputMaybe<Scalars['String']['input']>;
  name_not_contains: InputMaybe<Scalars['String']['input']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentfulTopicLinkingCollections = {
  __typename?: 'ContentfulTopicLinkingCollections';
  currentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulTopicLinkingCollectionsCurrentAwarenessCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulTopicLinkingCollectionsCurrentAwarenessCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulTopicLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentfulTopicLinkingCollectionsCurrentAwarenessCollectionOrder {
  FeaturedPostAsc = 'featuredPost_ASC',
  FeaturedPostDesc = 'featuredPost_DESC',
  OriginalPublishDateAsc = 'originalPublishDate_ASC',
  OriginalPublishDateDesc = 'originalPublishDate_DESC',
  PostResourceTypeAsc = 'postResourceType_ASC',
  PostResourceTypeDesc = 'postResourceType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum ContentfulTopicOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndForms = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulTrueDocsAndForms';
    _id: Scalars['ID']['output'];
    additionalSearchTerms: Maybe<Scalars['String']['output']>;
    cebGuidance: Maybe<ContentfulTrueDocsAndFormsCebGuidance>;
    contentfulMetadata: ContentfulContentfulMetadata;
    countyCourt: Maybe<ContentfulCourt>;
    docspringTemplateId: Maybe<Scalars['String']['output']>;
    documentTemplate: Maybe<ContentfulAsset>;
    enabledEnvironments: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    fidoForm: Maybe<ContentfulForm>;
    formNumber: Maybe<Scalars['String']['output']>;
    linkedFrom: Maybe<ContentfulTrueDocsAndFormsLinkingCollections>;
    mandatoryJcForm: Maybe<Scalars['Boolean']['output']>;
    practiceAreasCollection: Maybe<ContentfulTrueDocsAndFormsPracticeAreasCollection>;
    shortDescription: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    supportedMatterTypes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    trueFormSubtype: Maybe<Scalars['String']['output']>;
    type: Maybe<Scalars['String']['output']>;
    updatedDate: Maybe<Scalars['ContentfulDateTime']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsAdditionalSearchTermsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsCebGuidanceArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsCountyCourtArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulCourtFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsDocspringTemplateIdArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsDocumentTemplateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsEnabledEnvironmentsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsFidoFormArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulFormFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsFormNumberArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsMandatoryJcFormArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulTrueDocsAndFormsPracticeAreasCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsShortDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsSupportedMatterTypesArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsTrueFormSubtypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsTypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsAndForms) */
export type ContentfulTrueDocsAndFormsUpdatedDateArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulTrueDocsAndFormsCebGuidance = {
  __typename?: 'ContentfulTrueDocsAndFormsCebGuidance';
  json: Scalars['ContentfulJSON']['output'];
  links: ContentfulTrueDocsAndFormsCebGuidanceLinks;
};

export type ContentfulTrueDocsAndFormsCebGuidanceAssets = {
  __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceAssets';
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulTrueDocsAndFormsCebGuidanceEntries = {
  __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceEntries';
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulTrueDocsAndFormsCebGuidanceLinks = {
  __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceLinks';
  assets: ContentfulTrueDocsAndFormsCebGuidanceAssets;
  entries: ContentfulTrueDocsAndFormsCebGuidanceEntries;
  resources: ContentfulTrueDocsAndFormsCebGuidanceResources;
};

export type ContentfulTrueDocsAndFormsCebGuidanceResources = {
  __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceResources';
  block: Array<ContentfulTrueDocsAndFormsCebGuidanceResourcesBlock>;
  hyperlink: Array<ContentfulTrueDocsAndFormsCebGuidanceResourcesHyperlink>;
  inline: Array<ContentfulTrueDocsAndFormsCebGuidanceResourcesInline>;
};

export type ContentfulTrueDocsAndFormsCebGuidanceResourcesBlock =
  ContentfulResourceLink & {
    __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceResourcesBlock';
    sys: ContentfulResourceSys;
  };

export type ContentfulTrueDocsAndFormsCebGuidanceResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceResourcesHyperlink';
    sys: ContentfulResourceSys;
  };

export type ContentfulTrueDocsAndFormsCebGuidanceResourcesInline =
  ContentfulResourceLink & {
    __typename?: 'ContentfulTrueDocsAndFormsCebGuidanceResourcesInline';
    sys: ContentfulResourceSys;
  };

export type ContentfulTrueDocsAndFormsCollection = {
  __typename?: 'ContentfulTrueDocsAndFormsCollection';
  items: Array<Maybe<ContentfulTrueDocsAndForms>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulTrueDocsAndFormsFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulTrueDocsAndFormsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulTrueDocsAndFormsFilter>>>;
  additionalSearchTerms: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_exists: InputMaybe<Scalars['Boolean']['input']>;
  additionalSearchTerms_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  additionalSearchTerms_not: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_contains: InputMaybe<Scalars['String']['input']>;
  additionalSearchTerms_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cebGuidance_contains: InputMaybe<Scalars['String']['input']>;
  cebGuidance_exists: InputMaybe<Scalars['Boolean']['input']>;
  cebGuidance_not_contains: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  countyCourt: InputMaybe<ContentfulCfCourtNestedFilter>;
  countyCourt_exists: InputMaybe<Scalars['Boolean']['input']>;
  docspringTemplateId: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_contains: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_exists: InputMaybe<Scalars['Boolean']['input']>;
  docspringTemplateId_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  docspringTemplateId_not: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_not_contains: InputMaybe<Scalars['String']['input']>;
  docspringTemplateId_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  documentTemplate_exists: InputMaybe<Scalars['Boolean']['input']>;
  enabledEnvironments_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_exists: InputMaybe<Scalars['Boolean']['input']>;
  fidoForm: InputMaybe<ContentfulCfFormNestedFilter>;
  fidoForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber: InputMaybe<Scalars['String']['input']>;
  formNumber_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  formNumber_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formNumber_not: InputMaybe<Scalars['String']['input']>;
  formNumber_not_contains: InputMaybe<Scalars['String']['input']>;
  formNumber_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mandatoryJcForm: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryJcForm_exists: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryJcForm_not: InputMaybe<Scalars['Boolean']['input']>;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportedMatterTypes_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  supportedMatterTypes_exists: InputMaybe<Scalars['Boolean']['input']>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueFormSubtype: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_contains: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_exists: InputMaybe<Scalars['Boolean']['input']>;
  trueFormSubtype_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueFormSubtype_not: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_not_contains: InputMaybe<Scalars['String']['input']>;
  trueFormSubtype_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  type: InputMaybe<Scalars['String']['input']>;
  type_contains: InputMaybe<Scalars['String']['input']>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not: InputMaybe<Scalars['String']['input']>;
  type_not_contains: InputMaybe<Scalars['String']['input']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedDate: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedDate_gt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_gte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
  updatedDate_lt: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_lte: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not: InputMaybe<Scalars['ContentfulDateTime']['input']>;
  updatedDate_not_in: InputMaybe<
    Array<InputMaybe<Scalars['ContentfulDateTime']['input']>>
  >;
};

export type ContentfulTrueDocsAndFormsLinkingCollections = {
  __typename?: 'ContentfulTrueDocsAndFormsLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
  trueDocsFormsCollectionsCollection: Maybe<ContentfulTrueDocsFormsCollectionsCollection>;
};

export type ContentfulTrueDocsAndFormsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentfulTrueDocsAndFormsLinkingCollectionsTrueDocsFormsCollectionsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    order: InputMaybe<
      Array<
        InputMaybe<ContentfulTrueDocsAndFormsLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder>
      >
    >;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulTrueDocsAndFormsLinkingCollectionsTrueDocsFormsCollectionsCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ContentfulTrueDocsAndFormsOrder {
  DocspringTemplateIdAsc = 'docspringTemplateId_ASC',
  DocspringTemplateIdDesc = 'docspringTemplateId_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  MandatoryJcFormAsc = 'mandatoryJcForm_ASC',
  MandatoryJcFormDesc = 'mandatoryJcForm_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormSubtypeAsc = 'trueFormSubtype_ASC',
  TrueFormSubtypeDesc = 'trueFormSubtype_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulTrueDocsAndFormsPracticeAreasCollection = {
  __typename?: 'ContentfulTrueDocsAndFormsPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulTrueDocsAndFormsPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollections = ContentfulEntry &
  Contentful_Node & {
    __typename?: 'ContentfulTrueDocsFormsCollections';
    _id: Scalars['ID']['output'];
    collectionItemsCollection: Maybe<ContentfulTrueDocsFormsCollectionsCollectionItemsCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    countyCourt: Maybe<ContentfulCourt>;
    enabledEnvironments: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    linkedFrom: Maybe<ContentfulTrueDocsFormsCollectionsLinkingCollections>;
    practiceAreasCollection: Maybe<ContentfulTrueDocsFormsCollectionsPracticeAreasCollection>;
    shortDescription: Maybe<Scalars['String']['output']>;
    slug: Maybe<Scalars['String']['output']>;
    sys: ContentfulSys;
    title: Maybe<Scalars['String']['output']>;
    trueFormSubtype: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsCollectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulTrueDocsFormsCollectionsCollectionItemsCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulTrueDocsAndFormsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsCountyCourtArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  where: InputMaybe<ContentfulCourtFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsEnabledEnvironmentsArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsPracticeAreasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<
      InputMaybe<ContentfulTrueDocsFormsCollectionsPracticeAreasCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsShortDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsSlugArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsTitleArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/gcwac57vok7r/content_types/trueDocsFormsCollections) */
export type ContentfulTrueDocsFormsCollectionsTrueFormSubtypeArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};

export type ContentfulTrueDocsFormsCollectionsCollection = {
  __typename?: 'ContentfulTrueDocsFormsCollectionsCollection';
  items: Array<Maybe<ContentfulTrueDocsFormsCollections>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentfulTrueDocsFormsCollectionsCollectionItemsCollection = {
  __typename?: 'ContentfulTrueDocsFormsCollectionsCollectionItemsCollection';
  items: Array<Maybe<ContentfulTrueDocsAndForms>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulTrueDocsFormsCollectionsCollectionItemsCollectionOrder {
  DocspringTemplateIdAsc = 'docspringTemplateId_ASC',
  DocspringTemplateIdDesc = 'docspringTemplateId_DESC',
  FormNumberAsc = 'formNumber_ASC',
  FormNumberDesc = 'formNumber_DESC',
  MandatoryJcFormAsc = 'mandatoryJcForm_ASC',
  MandatoryJcFormDesc = 'mandatoryJcForm_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrueFormSubtypeAsc = 'trueFormSubtype_ASC',
  TrueFormSubtypeDesc = 'trueFormSubtype_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type ContentfulTrueDocsFormsCollectionsFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentfulTrueDocsFormsCollectionsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentfulTrueDocsFormsCollectionsFilter>>>;
  collectionItems: InputMaybe<ContentfulCfTrueDocsAndFormsNestedFilter>;
  collectionItemsCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata: InputMaybe<ContentfulContentfulMetadataFilter>;
  countyCourt: InputMaybe<ContentfulCfCourtNestedFilter>;
  countyCourt_exists: InputMaybe<Scalars['Boolean']['input']>;
  enabledEnvironments_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  enabledEnvironments_exists: InputMaybe<Scalars['Boolean']['input']>;
  practiceAreas: InputMaybe<ContentfulCfPracticeAreaNestedFilter>;
  practiceAreasCollection_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription: InputMaybe<Scalars['String']['input']>;
  shortDescription_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_exists: InputMaybe<Scalars['Boolean']['input']>;
  shortDescription_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  shortDescription_not: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_contains: InputMaybe<Scalars['String']['input']>;
  shortDescription_not_in: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug: InputMaybe<Scalars['String']['input']>;
  slug_contains: InputMaybe<Scalars['String']['input']>;
  slug_exists: InputMaybe<Scalars['Boolean']['input']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not: InputMaybe<Scalars['String']['input']>;
  slug_not_contains: InputMaybe<Scalars['String']['input']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys: InputMaybe<ContentfulSysFilter>;
  title: InputMaybe<Scalars['String']['input']>;
  title_contains: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not: InputMaybe<Scalars['String']['input']>;
  title_not_contains: InputMaybe<Scalars['String']['input']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trueFormSubtype_contains_all: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trueFormSubtype_contains_none: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trueFormSubtype_contains_some: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trueFormSubtype_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulTrueDocsFormsCollectionsLinkingCollections = {
  __typename?: 'ContentfulTrueDocsFormsCollectionsLinkingCollections';
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulTrueDocsFormsCollectionsLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale: InputMaybe<Scalars['String']['input']>;
    preview: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export enum ContentfulTrueDocsFormsCollectionsOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulTrueDocsFormsCollectionsPracticeAreasCollection = {
  __typename?: 'ContentfulTrueDocsFormsCollectionsPracticeAreasCollection';
  items: Array<Maybe<ContentfulPracticeArea>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContentfulTrueDocsFormsCollectionsPracticeAreasCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Contentful_Node = {
  _id: Scalars['ID']['output'];
};

export type ContributorSearchFilterInput = {
  practiceAreaIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  regions: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CouponValidation = {
  __typename?: 'CouponValidation';
  reason: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type CourseProgress = {
  __typename?: 'CourseProgress';
  completedPercentage: Maybe<Scalars['Int']['output']>;
  contentfulCourseId: Scalars['String']['output'];
  course: Maybe<CleCourse>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currentPlayheadInMs: Maybe<Scalars['Int']['output']>;
  highestPlayheadInMs: Maybe<Scalars['Int']['output']>;
  salesforceAccountId: Scalars['String']['output'];
  salesforceCourseResultId: Maybe<Scalars['String']['output']>;
  salesforceUserId: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  videoDurationInMs: Maybe<Scalars['Int']['output']>;
};

export type CourseSearchFilterInput = {
  contentfulIds_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contentfulIds_not_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dateRecorded_gte: InputMaybe<Scalars['DateTime']['input']>;
  dateRecorded_lte: InputMaybe<Scalars['DateTime']['input']>;
  practiceAreaIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  specialRequirementsIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  specializationIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CourseTypeaheadResult = {
  __typename?: 'CourseTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CoursesCollection = {
  __typename?: 'CoursesCollection';
  active: Maybe<Scalars['Boolean']['output']>;
  collectionImageUrl: Maybe<Scalars['String']['output']>;
  compliancePackageProductCode: Maybe<Scalars['String']['output']>;
  contentfulId: Maybe<Scalars['ID']['output']>;
  courses: Maybe<Array<Maybe<CleCourse>>>;
  coursesCount: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['JSONObject']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  slug: Maybe<Scalars['String']['output']>;
  specialRequirementsCredits: Maybe<Array<Maybe<CleCourseSubcredits>>>;
  specializationCredits: Maybe<Array<Maybe<CleCourseSubcredits>>>;
  title: Maybe<Scalars['String']['output']>;
  totalCredits: Maybe<Scalars['Float']['output']>;
};

export type CoursesCollectionSearchFilterInput = {
  compliancePackage: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoursesCollectionTypeaheadResult = {
  __typename?: 'CoursesCollectionTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CreateSubscriptionInput = {
  couponCode: InputMaybe<Scalars['String']['input']>;
  daysInFutureToActivate: InputMaybe<Scalars['Int']['input']>;
  interval: SubscriptionInterval;
  planCode: Scalars['String']['input'];
  productCodes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCodesWithQuantities: InputMaybe<
    Array<InputMaybe<ProductCodeWithQuantityInput>>
  >;
};

export type CurrentAwareness = {
  __typename?: 'CurrentAwareness';
  authors: Maybe<Array<Maybe<Author>>>;
  content: Maybe<Scalars['String']['output']>;
  contentfulId: Maybe<Scalars['ID']['output']>;
  featureImageUrl: Maybe<Scalars['String']['output']>;
  featuredPost: Maybe<Scalars['Boolean']['output']>;
  originalPublishDate: Maybe<Scalars['String']['output']>;
  postResourceType: Maybe<Scalars['String']['output']>;
  practiceAreas: Maybe<Array<Maybe<PracticeArea>>>;
  slug: Maybe<Scalars['String']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  topics: Maybe<Array<Maybe<CurrentAwarenessTopic>>>;
  updatedDate: Maybe<Scalars['String']['output']>;
};

export type CurrentAwarenessSearchFilterInput = {
  FeaturedPost: InputMaybe<Scalars['Boolean']['input']>;
  /** Current options: "Law Alert", "In Practice", "Legal News" */
  PostResourceTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  PracticeAreaIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  TopicIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contentfulIds_not_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  originalPublishDate: InputMaybe<Scalars['String']['input']>;
};

export type CurrentAwarenessTopic = {
  __typename?: 'CurrentAwarenessTopic';
  contentfulId: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

export type CurrentAwarenessTypeaheadResult = {
  __typename?: 'CurrentAwarenessTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type FilterOption = {
  __typename?: 'FilterOption';
  categoryId: Maybe<Scalars['String']['output']>;
  children: Maybe<Array<Maybe<FilterOption>>>;
  count: Maybe<Scalars['Int']['output']>;
  filterValue: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type Firm = {
  __typename?: 'Firm';
  accountNumber: Maybe<Scalars['String']['output']>;
  addressInfo: Maybe<AddressInfo>;
  administrators: Maybe<Array<Maybe<FirmAdministrator>>>;
  /** the firm's Fusebill customer ID */
  customerId: Maybe<Scalars['ID']['output']>;
  /** DEPRECATED: use customerId instead */
  fusebillId: Maybe<Scalars['ID']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  invitations: Maybe<Array<Maybe<FirmInvitation>>>;
  invoice: Maybe<Invoice>;
  invoices: Maybe<PagedInvoiceConnection>;
  name: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<PaymentMethod>;
  subscriptions: Maybe<Array<Maybe<Subscription>>>;
  users: Maybe<Array<Maybe<FirmUser>>>;
  website: Maybe<Scalars['String']['output']>;
};

export type FirmInvitationsArgs = {
  pendingOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type FirmInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type FirmInvoicesArgs = {
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type FirmSubscriptionsArgs = {
  statuses: InputMaybe<Array<InputMaybe<SubscriptionStatus>>>;
};

export type FirmAdministrator = {
  __typename?: 'FirmAdministrator';
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
};

export type FirmInvitation = {
  __typename?: 'FirmInvitation';
  accepted: Maybe<Scalars['Boolean']['output']>;
  firmName: Maybe<Scalars['String']['output']>;
  grantAdmin: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  inviteCode: Maybe<Scalars['String']['output']>;
  inviteeBarNumber: Maybe<Scalars['String']['output']>;
  inviteeEmail: Maybe<Scalars['String']['output']>;
  inviteeFirstName: Maybe<Scalars['String']['output']>;
  inviteeLastName: Maybe<Scalars['String']['output']>;
  organizationType: Maybe<Scalars['String']['output']>;
};

export type FirmPermissions = {
  __typename?: 'FirmPermissions';
  isBillingAccessOnlyFirmAdmin: Maybe<Scalars['Boolean']['output']>;
  isFirmAdmin: Maybe<Scalars['Boolean']['output']>;
};

export type FirmUser = {
  __typename?: 'FirmUser';
  accountId: Maybe<Scalars['ID']['output']>;
  barNumber: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  isFirmAdmin: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
};

export type Generic = {
  __typename?: 'Generic';
  documentId: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  secondarySourcesDocumentParts: Maybe<
    Array<Maybe<SecondarySourcesDocumentParts>>
  >;
  titleId: Maybe<Scalars['String']['output']>;
};

export type GenericSearchEdge = {
  __typename?: 'GenericSearchEdge';
  missingTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  node: Generic;
  snippets: Maybe<Array<Maybe<Snippet>>>;
};

/** Types of History Actions */
export enum HistoryAction {
  Browse = 'BROWSE',
  Search = 'SEARCH',
  View = 'VIEW',
}

export type HistoryItem = {
  __typename?: 'HistoryItem';
  action: Maybe<HistoryAction>;
  basePath: Maybe<Scalars['String']['output']>;
  children: Maybe<Array<Maybe<HistoryItem>>>;
  createdAt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  params: Maybe<Scalars['JSONObject']['output']>;
  parentId: Maybe<Scalars['ID']['output']>;
  path: Maybe<Scalars['String']['output']>;
  subtype: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
  type: Maybe<HistoryType>;
};

/** Types of History Item / Bookmarks */
export enum HistoryType {
  Case = 'CASE',
  CurrentAwareness = 'CURRENT_AWARENESS',
  Practitioner = 'PRACTITIONER',
  SecondarySources = 'SECONDARY_SOURCES',
  StatuteRule = 'STATUTE_RULE',
}

export type Invoice = {
  __typename?: 'Invoice';
  /** Total amount of invoice */
  amount: Maybe<Scalars['Float']['output']>;
  /** Outstanding balance on invoice */
  balance: Maybe<Scalars['Float']['output']>;
  /** Fusebill Customer ID */
  customerId: Maybe<Scalars['ID']['output']>;
  /** Date of invoice */
  date: Maybe<Scalars['String']['output']>;
  /** Invoice in html format */
  html: Maybe<Scalars['String']['output']>;
  /** Fusebill Invoice ID */
  id: Maybe<Scalars['ID']['output']>;
  /** Fusebill invoice number */
  invoiceNumber: Maybe<Scalars['Int']['output']>;
  /** Information about the Fusebil invoice's payment status */
  paymentSchedules: Maybe<Array<Maybe<PaymentSchedule>>>;
  /** Terms for this invoice */
  terms: Maybe<Scalars['String']['output']>;
};

export type InvoiceAllocation = {
  __typename?: 'InvoiceAllocation';
  amount: Maybe<Scalars['Float']['output']>;
  invoiceId: Maybe<Scalars['ID']['output']>;
  invoiceNumber: Maybe<Scalars['Int']['output']>;
  outstandingBalance: Maybe<Scalars['Float']['output']>;
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  amount: Maybe<Scalars['Float']['output']>;
  customerId: Maybe<Scalars['ID']['output']>;
  effectiveTimestamp: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  invoiceAllocations: Maybe<Array<Maybe<InvoiceAllocation>>>;
  paymentActivityId: Maybe<Scalars['ID']['output']>;
};

export type Login = {
  __typename?: 'Login';
  session: Maybe<Session>;
};

export type MatterServiceAddDocsAndFormsToFolder_Args = {
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  items: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceAddDocsAndFormsToMatter_Args = {
  items: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceAddItemsToFolder_Args = {
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneys = {
  __typename?: 'MatterServiceAttorneys';
  accountCreatorId: Scalars['String']['output'];
  accountId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  /** An array relationship */
  addresses: Array<MatterServiceEntityAddresses>;
  /** An aggregate relationship */
  addresses_aggregate: MatterServiceEntityAddresses_Aggregate;
  barNumber: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  /** An array relationship */
  emails: Array<MatterServiceEntityEmails>;
  /** An aggregate relationship */
  emails_aggregate: MatterServiceEntityEmails_Aggregate;
  firmName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_firm_name_capitalized" */
  firmNameCapitalized: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  /** A computed field, executes function "attorney_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_full_name_plus_sbn" */
  fullNamePlusSBN: Maybe<Scalars['String']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  lastName: Scalars['String']['output'];
  /** An array relationship */
  matterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  middleName: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  phoneNumbers: Array<MatterServiceEntityPhoneNumbers>;
  /** An aggregate relationship */
  phoneNumbers_aggregate: MatterServiceEntityPhoneNumbers_Aggregate;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysAddressesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysAddresses_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysEmailsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysEmails_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysPhoneNumbersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

/** columns and relationships of "attorneys" */
export type MatterServiceAttorneysPhoneNumbers_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

/** aggregated selection of "attorneys" */
export type MatterServiceAttorneys_Aggregate = {
  __typename?: 'MatterServiceAttorneys_aggregate';
  aggregate: Maybe<MatterServiceAttorneys_Aggregate_Fields>;
  nodes: Array<MatterServiceAttorneys>;
};

/** aggregate fields of "attorneys" */
export type MatterServiceAttorneys_Aggregate_Fields = {
  __typename?: 'MatterServiceAttorneys_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceAttorneys_Max_Fields>;
  min: Maybe<MatterServiceAttorneys_Min_Fields>;
};

/** aggregate fields of "attorneys" */
export type MatterServiceAttorneys_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "attorneys" */
export type MatterServiceAttorneys_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceAttorneys_Max_Order_By>;
  min: InputMaybe<MatterServiceAttorneys_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "attorneys". All fields are combined with a logical 'AND'. */
export type MatterServiceAttorneys_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceAttorneys_Bool_Exp>>;
  _not: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceAttorneys_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  addresses: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
  addresses_aggregate: InputMaybe<MatterServiceEntityAddresses_Aggregate_Bool_Exp>;
  barNumber: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  emails: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
  emails_aggregate: InputMaybe<MatterServiceEntityEmails_Aggregate_Bool_Exp>;
  firmName: InputMaybe<MatterServiceString_Comparison_Exp>;
  firmNameCapitalized: InputMaybe<MatterServiceString_Comparison_Exp>;
  firstName: InputMaybe<MatterServiceString_Comparison_Exp>;
  fullName: InputMaybe<MatterServiceString_Comparison_Exp>;
  fullNamePlusSBN: InputMaybe<MatterServiceString_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  lastName: InputMaybe<MatterServiceString_Comparison_Exp>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp>;
  middleName: InputMaybe<MatterServiceString_Comparison_Exp>;
  phoneNumbers: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
  phoneNumbers_aggregate: InputMaybe<MatterServiceEntityPhoneNumbers_Aggregate_Bool_Exp>;
  suffix: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "attorneys" */
export enum MatterServiceAttorneys_Constraint {
  /** unique or primary key constraint on columns "id" */
  AttorneysPkey = 'attorneys_pkey',
}

/** input type for inserting data into table "attorneys" */
export type MatterServiceAttorneys_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  addresses: InputMaybe<MatterServiceEntityAddresses_Arr_Rel_Insert_Input>;
  barNumber: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  emails: InputMaybe<MatterServiceEntityEmails_Arr_Rel_Insert_Input>;
  firmName: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Arr_Rel_Insert_Input>;
  middleName: InputMaybe<Scalars['String']['input']>;
  phoneNumbers: InputMaybe<MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceAttorneys_Max_Fields = {
  __typename?: 'MatterServiceAttorneys_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  barNumber: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  firmName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_firm_name_capitalized" */
  firmNameCapitalized: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_full_name_plus_sbn" */
  fullNamePlusSBN: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "attorneys" */
export type MatterServiceAttorneys_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  barNumber: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  firmName: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceAttorneys_Min_Fields = {
  __typename?: 'MatterServiceAttorneys_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  barNumber: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  firmName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_firm_name_capitalized" */
  firmNameCapitalized: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "attorney_full_name_plus_sbn" */
  fullNamePlusSBN: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "attorneys" */
export type MatterServiceAttorneys_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  barNumber: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  firmName: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "attorneys" */
export type MatterServiceAttorneys_Mutation_Response = {
  __typename?: 'MatterServiceAttorneys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceAttorneys>;
};

/** input type for inserting object relation for remote table "attorneys" */
export type MatterServiceAttorneys_Obj_Rel_Insert_Input = {
  data: MatterServiceAttorneys_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceAttorneys_On_Conflict>;
};

/** on_conflict condition type for table "attorneys" */
export type MatterServiceAttorneys_On_Conflict = {
  constraint: MatterServiceAttorneys_Constraint;
  update_columns: Array<MatterServiceAttorneys_Update_Column>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

/** Ordering options when selecting data from "attorneys". */
export type MatterServiceAttorneys_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  addresses_aggregate: InputMaybe<MatterServiceEntityAddresses_Aggregate_Order_By>;
  barNumber: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  emails_aggregate: InputMaybe<MatterServiceEntityEmails_Aggregate_Order_By>;
  firmName: InputMaybe<MatterServiceOrder_By>;
  firmNameCapitalized: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  fullName: InputMaybe<MatterServiceOrder_By>;
  fullNamePlusSBN: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Order_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  phoneNumbers_aggregate: InputMaybe<MatterServiceEntityPhoneNumbers_Aggregate_Order_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: attorneys */
export type MatterServiceAttorneys_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "attorneys" */
export enum MatterServiceAttorneys_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  BarNumber = 'barNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirmName = 'firmName',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "attorneys" */
export type MatterServiceAttorneys_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  barNumber: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  firmName: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "attorneys" */
export type MatterServiceAttorneys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceAttorneys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceAttorneys_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  barNumber: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  firmName: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "attorneys" */
export enum MatterServiceAttorneys_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  BarNumber = 'barNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirmName = 'firmName',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceAttorneys_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceAttorneys_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceAttorneys_Bool_Exp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type MatterServiceBoolean_Comparison_Exp = {
  _eq: InputMaybe<Scalars['Boolean']['input']>;
  _gt: InputMaybe<Scalars['Boolean']['input']>;
  _gte: InputMaybe<Scalars['Boolean']['input']>;
  _in: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Boolean']['input']>;
  _lte: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<Scalars['Boolean']['input']>;
  _nin: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "clients" */
export type MatterServiceClients = {
  __typename?: 'MatterServiceClients';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  id: Scalars['MatterServiceUuid']['output'];
  /** An array relationship */
  matters: Array<MatterServiceMatters>;
  /** An aggregate relationship */
  matters_aggregate: MatterServiceMatters_Aggregate;
  name: Scalars['String']['output'];
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** columns and relationships of "clients" */
export type MatterServiceClientsMattersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

/** columns and relationships of "clients" */
export type MatterServiceClientsMatters_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

/** aggregated selection of "clients" */
export type MatterServiceClients_Aggregate = {
  __typename?: 'MatterServiceClients_aggregate';
  aggregate: Maybe<MatterServiceClients_Aggregate_Fields>;
  nodes: Array<MatterServiceClients>;
};

/** aggregate fields of "clients" */
export type MatterServiceClients_Aggregate_Fields = {
  __typename?: 'MatterServiceClients_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceClients_Max_Fields>;
  min: Maybe<MatterServiceClients_Min_Fields>;
};

/** aggregate fields of "clients" */
export type MatterServiceClients_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceClients_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type MatterServiceClients_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceClients_Bool_Exp>>;
  _not: InputMaybe<MatterServiceClients_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceClients_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matters: InputMaybe<MatterServiceMatters_Bool_Exp>;
  matters_aggregate: InputMaybe<MatterServiceMatters_Aggregate_Bool_Exp>;
  name: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum MatterServiceClients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey',
}

/** input type for inserting data into table "clients" */
export type MatterServiceClients_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matters: InputMaybe<MatterServiceMatters_Arr_Rel_Insert_Input>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceClients_Max_Fields = {
  __typename?: 'MatterServiceClients_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** aggregate min on columns */
export type MatterServiceClients_Min_Fields = {
  __typename?: 'MatterServiceClients_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** response of any mutation on the table "clients" */
export type MatterServiceClients_Mutation_Response = {
  __typename?: 'MatterServiceClients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceClients>;
};

/** input type for inserting object relation for remote table "clients" */
export type MatterServiceClients_Obj_Rel_Insert_Input = {
  data: MatterServiceClients_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceClients_On_Conflict>;
};

/** on_conflict condition type for table "clients" */
export type MatterServiceClients_On_Conflict = {
  constraint: MatterServiceClients_Constraint;
  update_columns: Array<MatterServiceClients_Update_Column>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type MatterServiceClients_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matters_aggregate: InputMaybe<MatterServiceMatters_Aggregate_Order_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: clients */
export type MatterServiceClients_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "clients" */
export enum MatterServiceClients_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "clients" */
export type MatterServiceClients_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "clients" */
export type MatterServiceClients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceClients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceClients_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "clients" */
export enum MatterServiceClients_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceClients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceClients_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceClients_Bool_Exp;
};

/** columns and relationships of "courts" */
export type MatterServiceCourts = {
  __typename?: 'MatterServiceCourts';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  /** An object relationship */
  address: MatterServiceEntityAddresses;
  /** A computed field, executes function "court_address_block" */
  addressBlock: Maybe<Scalars['String']['output']>;
  addressId: Scalars['MatterServiceUuid']['output'];
  branchName: Maybe<Scalars['String']['output']>;
  county: Scalars['String']['output'];
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  /** An array relationship */
  matterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  name: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** columns and relationships of "courts" */
export type MatterServiceCourtsMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "courts" */
export type MatterServiceCourtsMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** aggregated selection of "courts" */
export type MatterServiceCourts_Aggregate = {
  __typename?: 'MatterServiceCourts_aggregate';
  aggregate: Maybe<MatterServiceCourts_Aggregate_Fields>;
  nodes: Array<MatterServiceCourts>;
};

/** aggregate fields of "courts" */
export type MatterServiceCourts_Aggregate_Fields = {
  __typename?: 'MatterServiceCourts_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceCourts_Max_Fields>;
  min: Maybe<MatterServiceCourts_Min_Fields>;
};

/** aggregate fields of "courts" */
export type MatterServiceCourts_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "courts" */
export type MatterServiceCourts_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceCourts_Max_Order_By>;
  min: InputMaybe<MatterServiceCourts_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "courts". All fields are combined with a logical 'AND'. */
export type MatterServiceCourts_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceCourts_Bool_Exp>>;
  _not: InputMaybe<MatterServiceCourts_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceCourts_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  address: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
  addressBlock: InputMaybe<MatterServiceString_Comparison_Exp>;
  addressId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  branchName: InputMaybe<MatterServiceString_Comparison_Exp>;
  county: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp>;
  name: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courts" */
export enum MatterServiceCourts_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourtsPkey = 'courts_pkey',
}

/** input type for inserting data into table "courts" */
export type MatterServiceCourts_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  address: InputMaybe<MatterServiceEntityAddresses_Obj_Rel_Insert_Input>;
  addressId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  branchName: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Arr_Rel_Insert_Input>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceCourts_Max_Fields = {
  __typename?: 'MatterServiceCourts_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "court_address_block" */
  addressBlock: Maybe<Scalars['String']['output']>;
  addressId: Maybe<Scalars['MatterServiceUuid']['output']>;
  branchName: Maybe<Scalars['String']['output']>;
  county: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "courts" */
export type MatterServiceCourts_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  addressId: InputMaybe<MatterServiceOrder_By>;
  branchName: InputMaybe<MatterServiceOrder_By>;
  county: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceCourts_Min_Fields = {
  __typename?: 'MatterServiceCourts_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "court_address_block" */
  addressBlock: Maybe<Scalars['String']['output']>;
  addressId: Maybe<Scalars['MatterServiceUuid']['output']>;
  branchName: Maybe<Scalars['String']['output']>;
  county: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "courts" */
export type MatterServiceCourts_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  addressId: InputMaybe<MatterServiceOrder_By>;
  branchName: InputMaybe<MatterServiceOrder_By>;
  county: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "courts" */
export type MatterServiceCourts_Mutation_Response = {
  __typename?: 'MatterServiceCourts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceCourts>;
};

/** input type for inserting object relation for remote table "courts" */
export type MatterServiceCourts_Obj_Rel_Insert_Input = {
  data: MatterServiceCourts_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceCourts_On_Conflict>;
};

/** on_conflict condition type for table "courts" */
export type MatterServiceCourts_On_Conflict = {
  constraint: MatterServiceCourts_Constraint;
  update_columns: Array<MatterServiceCourts_Update_Column>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

/** Ordering options when selecting data from "courts". */
export type MatterServiceCourts_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  address: InputMaybe<MatterServiceEntityAddresses_Order_By>;
  addressBlock: InputMaybe<MatterServiceOrder_By>;
  addressId: InputMaybe<MatterServiceOrder_By>;
  branchName: InputMaybe<MatterServiceOrder_By>;
  county: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Order_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: courts */
export type MatterServiceCourts_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "courts" */
export enum MatterServiceCourts_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  BranchName = 'branchName',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "courts" */
export type MatterServiceCourts_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  addressId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  branchName: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "courts" */
export type MatterServiceCourts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceCourts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceCourts_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  addressId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  branchName: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "courts" */
export enum MatterServiceCourts_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  BranchName = 'branchName',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceCourts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceCourts_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceCourts_Bool_Exp;
};

export type MatterServiceCreateFolderAddDocsAndForms_Args = {
  folderName: InputMaybe<Scalars['String']['input']>;
  items: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceCreateFolderAddItems_Args = {
  folderName: InputMaybe<Scalars['String']['input']>;
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceCreateFolderDuplicateItems_Args = {
  folderName: InputMaybe<Scalars['String']['input']>;
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

/** ordering argument of a cursor */
export enum MatterServiceCursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type MatterServiceDate_Comparison_Exp = {
  _eq: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _gt: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _gte: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _in: InputMaybe<Array<Scalars['MatterServiceDate']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _lte: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _neq: InputMaybe<Scalars['MatterServiceDate']['input']>;
  _nin: InputMaybe<Array<Scalars['MatterServiceDate']['input']>>;
};

/** columns and relationships of "domesticPartnerships" */
export type MatterServiceDomesticPartnerships = {
  __typename?: 'MatterServiceDomesticPartnerships';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  dateOfRegistration: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_registration" */
  formattedDateOfRegistration: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation_dp" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  matterId: Scalars['MatterServiceUuid']['output'];
  /** A computed field, executes function "length_of_dp_months" */
  monthsOfPartnership: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
  /** A computed field, executes function "length_of_dp_years" */
  yearsOfPartnership: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Aggregate = {
  __typename?: 'MatterServiceDomesticPartnerships_aggregate';
  aggregate: Maybe<MatterServiceDomesticPartnerships_Aggregate_Fields>;
  nodes: Array<MatterServiceDomesticPartnerships>;
};

/** aggregate fields of "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Aggregate_Fields = {
  __typename?: 'MatterServiceDomesticPartnerships_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceDomesticPartnerships_Max_Fields>;
  min: Maybe<MatterServiceDomesticPartnerships_Min_Fields>;
};

/** aggregate fields of "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceDomesticPartnerships_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "domesticPartnerships". All fields are combined with a logical 'AND'. */
export type MatterServiceDomesticPartnerships_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceDomesticPartnerships_Bool_Exp>>;
  _not: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceDomesticPartnerships_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  dateOfRegistration: InputMaybe<MatterServiceDate_Comparison_Exp>;
  dateOfSeparation: InputMaybe<MatterServiceDate_Comparison_Exp>;
  formattedDateOfRegistration: InputMaybe<MatterServiceString_Comparison_Exp>;
  formattedDateOfSeparation: InputMaybe<MatterServiceString_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  monthsOfPartnership: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  yearsOfPartnership: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "domesticPartnerships" */
export enum MatterServiceDomesticPartnerships_Constraint {
  /** unique or primary key constraint on columns "matterId" */
  DomesticPartnershipsMatterIdKey = 'domesticPartnerships_matterId_key',
  /** unique or primary key constraint on columns "id" */
  DomesticPartnershipsPkey = 'domesticPartnerships_pkey',
}

/** input type for inserting data into table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfRegistration: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceDomesticPartnerships_Max_Fields = {
  __typename?: 'MatterServiceDomesticPartnerships_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfRegistration: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_registration" */
  formattedDateOfRegistration: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation_dp" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  /** A computed field, executes function "length_of_dp_months" */
  monthsOfPartnership: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "length_of_dp_years" */
  yearsOfPartnership: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceDomesticPartnerships_Min_Fields = {
  __typename?: 'MatterServiceDomesticPartnerships_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfRegistration: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_registration" */
  formattedDateOfRegistration: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation_dp" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  /** A computed field, executes function "length_of_dp_months" */
  monthsOfPartnership: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "length_of_dp_years" */
  yearsOfPartnership: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Mutation_Response = {
  __typename?: 'MatterServiceDomesticPartnerships_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceDomesticPartnerships>;
};

/** input type for inserting object relation for remote table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Obj_Rel_Insert_Input = {
  data: MatterServiceDomesticPartnerships_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceDomesticPartnerships_On_Conflict>;
};

/** on_conflict condition type for table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_On_Conflict = {
  constraint: MatterServiceDomesticPartnerships_Constraint;
  update_columns: Array<MatterServiceDomesticPartnerships_Update_Column>;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

/** Ordering options when selecting data from "domesticPartnerships". */
export type MatterServiceDomesticPartnerships_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  dateOfRegistration: InputMaybe<MatterServiceOrder_By>;
  dateOfSeparation: InputMaybe<MatterServiceOrder_By>;
  formattedDateOfRegistration: InputMaybe<MatterServiceOrder_By>;
  formattedDateOfSeparation: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  monthsOfPartnership: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  yearsOfPartnership: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: domesticPartnerships */
export type MatterServiceDomesticPartnerships_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "domesticPartnerships" */
export enum MatterServiceDomesticPartnerships_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfRegistration = 'dateOfRegistration',
  /** column name */
  DateOfSeparation = 'dateOfSeparation',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfRegistration: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "domesticPartnerships" */
export type MatterServiceDomesticPartnerships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceDomesticPartnerships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceDomesticPartnerships_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfRegistration: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "domesticPartnerships" */
export enum MatterServiceDomesticPartnerships_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfRegistration = 'dateOfRegistration',
  /** column name */
  DateOfSeparation = 'dateOfSeparation',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceDomesticPartnerships_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceDomesticPartnerships_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceDomesticPartnerships_Bool_Exp;
};

export type MatterServiceDuplicateItemsInMatter_Args = {
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceDuplicateItemsToFolder_Args = {
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

/** columns and relationships of "entityAddressTypes" */
export type MatterServiceEntityAddressTypes = {
  __typename?: 'MatterServiceEntityAddressTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Aggregate = {
  __typename?: 'MatterServiceEntityAddressTypes_aggregate';
  aggregate: Maybe<MatterServiceEntityAddressTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityAddressTypes>;
};

/** aggregate fields of "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityAddressTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityAddressTypes_Max_Fields>;
  min: Maybe<MatterServiceEntityAddressTypes_Min_Fields>;
};

/** aggregate fields of "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityAddressTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "entityAddressTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityAddressTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityAddressTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityAddressTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityAddressTypes" */
export enum MatterServiceEntityAddressTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  EntityAddressTypesPkey = 'entityAddressTypes_pkey',
}

export enum MatterServiceEntityAddressTypes_Enum {
  Home = 'HOME',
  Work = 'WORK',
}

/** Boolean expression to compare columns of type "entityAddressTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceEntityAddressTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceEntityAddressTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceEntityAddressTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceEntityAddressTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceEntityAddressTypes_Enum>>;
};

/** input type for inserting data into table "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityAddressTypes_Max_Fields = {
  __typename?: 'MatterServiceEntityAddressTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceEntityAddressTypes_Min_Fields = {
  __typename?: 'MatterServiceEntityAddressTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Mutation_Response = {
  __typename?: 'MatterServiceEntityAddressTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityAddressTypes>;
};

/** on_conflict condition type for table "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_On_Conflict = {
  constraint: MatterServiceEntityAddressTypes_Constraint;
  update_columns: Array<MatterServiceEntityAddressTypes_Update_Column>;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "entityAddressTypes". */
export type MatterServiceEntityAddressTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityAddressTypes */
export type MatterServiceEntityAddressTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "entityAddressTypes" */
export enum MatterServiceEntityAddressTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entityAddressTypes" */
export type MatterServiceEntityAddressTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityAddressTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityAddressTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entityAddressTypes" */
export enum MatterServiceEntityAddressTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceEntityAddressTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityAddressTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityAddressTypes_Bool_Exp;
};

/** columns and relationships of "entityAddresses" */
export type MatterServiceEntityAddresses = {
  __typename?: 'MatterServiceEntityAddresses';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  city: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "address_city_and_zip_code" */
  cityAndZipCode: Maybe<Scalars['String']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  entityType: MatterServiceMatterEntityTypes_Enum;
  /** A computed field, executes function "full_address_single_line" */
  fullAddressSingleLine: Maybe<Scalars['String']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  mailingAddress: Maybe<Scalars['String']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "street_address_both_lines" */
  streetAddressBothLines: Maybe<Scalars['String']['output']>;
  streetAddressLineTwo: Maybe<Scalars['String']['output']>;
  type: MatterServiceEntityAddressTypes_Enum;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
  zipCode: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "entityAddresses" */
export type MatterServiceEntityAddresses_Aggregate = {
  __typename?: 'MatterServiceEntityAddresses_aggregate';
  aggregate: Maybe<MatterServiceEntityAddresses_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityAddresses>;
};

export type MatterServiceEntityAddresses_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceEntityAddresses_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceEntityAddresses_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "entityAddresses" */
export type MatterServiceEntityAddresses_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityAddresses_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityAddresses_Max_Fields>;
  min: Maybe<MatterServiceEntityAddresses_Min_Fields>;
};

/** aggregate fields of "entityAddresses" */
export type MatterServiceEntityAddresses_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entityAddresses" */
export type MatterServiceEntityAddresses_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceEntityAddresses_Max_Order_By>;
  min: InputMaybe<MatterServiceEntityAddresses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "entityAddresses" */
export type MatterServiceEntityAddresses_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceEntityAddresses_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceEntityAddresses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "entityAddresses". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityAddresses_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityAddresses_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityAddresses_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  attorneyId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  city: InputMaybe<MatterServiceString_Comparison_Exp>;
  cityAndZipCode: InputMaybe<MatterServiceString_Comparison_Exp>;
  courtId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum_Comparison_Exp>;
  fullAddressSingleLine: InputMaybe<MatterServiceString_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  mailingAddress: InputMaybe<MatterServiceString_Comparison_Exp>;
  organizationId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  personId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  state: InputMaybe<MatterServiceString_Comparison_Exp>;
  streetAddress: InputMaybe<MatterServiceString_Comparison_Exp>;
  streetAddressBothLines: InputMaybe<MatterServiceString_Comparison_Exp>;
  streetAddressLineTwo: InputMaybe<MatterServiceString_Comparison_Exp>;
  type: InputMaybe<MatterServiceEntityAddressTypes_Enum_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  zipCode: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityAddresses" */
export enum MatterServiceEntityAddresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityAddressesPkey = 'entityAddresses_pkey',
}

/** input type for inserting data into table "entityAddresses" */
export type MatterServiceEntityAddresses_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  mailingAddress: InputMaybe<Scalars['String']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityAddressTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityAddresses_Max_Fields = {
  __typename?: 'MatterServiceEntityAddresses_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  city: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "address_city_and_zip_code" */
  cityAndZipCode: Maybe<Scalars['String']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "full_address_single_line" */
  fullAddressSingleLine: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  mailingAddress: Maybe<Scalars['String']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "street_address_both_lines" */
  streetAddressBothLines: Maybe<Scalars['String']['output']>;
  streetAddressLineTwo: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entityAddresses" */
export type MatterServiceEntityAddresses_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  city: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  mailingAddress: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  state: InputMaybe<MatterServiceOrder_By>;
  streetAddress: InputMaybe<MatterServiceOrder_By>;
  streetAddressLineTwo: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  zipCode: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceEntityAddresses_Min_Fields = {
  __typename?: 'MatterServiceEntityAddresses_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  city: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "address_city_and_zip_code" */
  cityAndZipCode: Maybe<Scalars['String']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "full_address_single_line" */
  fullAddressSingleLine: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  mailingAddress: Maybe<Scalars['String']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "street_address_both_lines" */
  streetAddressBothLines: Maybe<Scalars['String']['output']>;
  streetAddressLineTwo: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entityAddresses" */
export type MatterServiceEntityAddresses_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  city: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  mailingAddress: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  state: InputMaybe<MatterServiceOrder_By>;
  streetAddress: InputMaybe<MatterServiceOrder_By>;
  streetAddressLineTwo: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  zipCode: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "entityAddresses" */
export type MatterServiceEntityAddresses_Mutation_Response = {
  __typename?: 'MatterServiceEntityAddresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityAddresses>;
};

/** input type for inserting object relation for remote table "entityAddresses" */
export type MatterServiceEntityAddresses_Obj_Rel_Insert_Input = {
  data: MatterServiceEntityAddresses_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceEntityAddresses_On_Conflict>;
};

/** on_conflict condition type for table "entityAddresses" */
export type MatterServiceEntityAddresses_On_Conflict = {
  constraint: MatterServiceEntityAddresses_Constraint;
  update_columns: Array<MatterServiceEntityAddresses_Update_Column>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

/** Ordering options when selecting data from "entityAddresses". */
export type MatterServiceEntityAddresses_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  city: InputMaybe<MatterServiceOrder_By>;
  cityAndZipCode: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  entityType: InputMaybe<MatterServiceOrder_By>;
  fullAddressSingleLine: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  mailingAddress: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  state: InputMaybe<MatterServiceOrder_By>;
  streetAddress: InputMaybe<MatterServiceOrder_By>;
  streetAddressBothLines: InputMaybe<MatterServiceOrder_By>;
  streetAddressLineTwo: InputMaybe<MatterServiceOrder_By>;
  type: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  zipCode: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityAddresses */
export type MatterServiceEntityAddresses_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "entityAddresses" */
export enum MatterServiceEntityAddresses_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  City = 'city',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  MailingAddress = 'mailingAddress',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  StreetAddressLineTwo = 'streetAddressLineTwo',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZipCode = 'zipCode',
}

/** input type for updating data in table "entityAddresses" */
export type MatterServiceEntityAddresses_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  mailingAddress: InputMaybe<Scalars['String']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityAddressTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entityAddresses" */
export type MatterServiceEntityAddresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityAddresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityAddresses_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  mailingAddress: InputMaybe<Scalars['String']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityAddressTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entityAddresses" */
export enum MatterServiceEntityAddresses_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  City = 'city',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  MailingAddress = 'mailingAddress',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  StreetAddressLineTwo = 'streetAddressLineTwo',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZipCode = 'zipCode',
}

export type MatterServiceEntityAddresses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityAddresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityAddresses_Bool_Exp;
};

/** columns and relationships of "entityEmailTypes" */
export type MatterServiceEntityEmailTypes = {
  __typename?: 'MatterServiceEntityEmailTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Aggregate = {
  __typename?: 'MatterServiceEntityEmailTypes_aggregate';
  aggregate: Maybe<MatterServiceEntityEmailTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityEmailTypes>;
};

/** aggregate fields of "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityEmailTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityEmailTypes_Max_Fields>;
  min: Maybe<MatterServiceEntityEmailTypes_Min_Fields>;
};

/** aggregate fields of "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityEmailTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "entityEmailTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityEmailTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityEmailTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityEmailTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityEmailTypes" */
export enum MatterServiceEntityEmailTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  EntityEmailTypesPkey = 'entityEmailTypes_pkey',
}

export enum MatterServiceEntityEmailTypes_Enum {
  Personal = 'PERSONAL',
  Work = 'WORK',
}

/** Boolean expression to compare columns of type "entityEmailTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceEntityEmailTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceEntityEmailTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceEntityEmailTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceEntityEmailTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceEntityEmailTypes_Enum>>;
};

/** input type for inserting data into table "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityEmailTypes_Max_Fields = {
  __typename?: 'MatterServiceEntityEmailTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceEntityEmailTypes_Min_Fields = {
  __typename?: 'MatterServiceEntityEmailTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Mutation_Response = {
  __typename?: 'MatterServiceEntityEmailTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityEmailTypes>;
};

/** on_conflict condition type for table "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_On_Conflict = {
  constraint: MatterServiceEntityEmailTypes_Constraint;
  update_columns: Array<MatterServiceEntityEmailTypes_Update_Column>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "entityEmailTypes". */
export type MatterServiceEntityEmailTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityEmailTypes */
export type MatterServiceEntityEmailTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "entityEmailTypes" */
export enum MatterServiceEntityEmailTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entityEmailTypes" */
export type MatterServiceEntityEmailTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityEmailTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityEmailTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entityEmailTypes" */
export enum MatterServiceEntityEmailTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceEntityEmailTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityEmailTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityEmailTypes_Bool_Exp;
};

/** columns and relationships of "entityEmails" */
export type MatterServiceEntityEmails = {
  __typename?: 'MatterServiceEntityEmails';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  email: Scalars['String']['output'];
  entityType: MatterServiceMatterEntityTypes_Enum;
  id: Scalars['MatterServiceUuid']['output'];
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  type: MatterServiceEntityEmailTypes_Enum;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** aggregated selection of "entityEmails" */
export type MatterServiceEntityEmails_Aggregate = {
  __typename?: 'MatterServiceEntityEmails_aggregate';
  aggregate: Maybe<MatterServiceEntityEmails_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityEmails>;
};

export type MatterServiceEntityEmails_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceEntityEmails_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceEntityEmails_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "entityEmails" */
export type MatterServiceEntityEmails_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityEmails_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityEmails_Max_Fields>;
  min: Maybe<MatterServiceEntityEmails_Min_Fields>;
};

/** aggregate fields of "entityEmails" */
export type MatterServiceEntityEmails_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entityEmails" */
export type MatterServiceEntityEmails_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceEntityEmails_Max_Order_By>;
  min: InputMaybe<MatterServiceEntityEmails_Min_Order_By>;
};

/** input type for inserting array relation for remote table "entityEmails" */
export type MatterServiceEntityEmails_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceEntityEmails_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceEntityEmails_On_Conflict>;
};

/** Boolean expression to filter rows from the table "entityEmails". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityEmails_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityEmails_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityEmails_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  attorneyId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  courtId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  email: InputMaybe<MatterServiceString_Comparison_Exp>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  organizationId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  personId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  type: InputMaybe<MatterServiceEntityEmailTypes_Enum_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityEmails" */
export enum MatterServiceEntityEmails_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityEmailsPkey = 'entityEmails_pkey',
}

/** input type for inserting data into table "entityEmails" */
export type MatterServiceEntityEmails_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  type: InputMaybe<MatterServiceEntityEmailTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityEmails_Max_Fields = {
  __typename?: 'MatterServiceEntityEmails_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "entityEmails" */
export type MatterServiceEntityEmails_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  email: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceEntityEmails_Min_Fields = {
  __typename?: 'MatterServiceEntityEmails_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "entityEmails" */
export type MatterServiceEntityEmails_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  email: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "entityEmails" */
export type MatterServiceEntityEmails_Mutation_Response = {
  __typename?: 'MatterServiceEntityEmails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityEmails>;
};

/** on_conflict condition type for table "entityEmails" */
export type MatterServiceEntityEmails_On_Conflict = {
  constraint: MatterServiceEntityEmails_Constraint;
  update_columns: Array<MatterServiceEntityEmails_Update_Column>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

/** Ordering options when selecting data from "entityEmails". */
export type MatterServiceEntityEmails_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  email: InputMaybe<MatterServiceOrder_By>;
  entityType: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  type: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityEmails */
export type MatterServiceEntityEmails_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "entityEmails" */
export enum MatterServiceEntityEmails_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "entityEmails" */
export type MatterServiceEntityEmails_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  type: InputMaybe<MatterServiceEntityEmailTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "entityEmails" */
export type MatterServiceEntityEmails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityEmails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityEmails_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  type: InputMaybe<MatterServiceEntityEmailTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "entityEmails" */
export enum MatterServiceEntityEmails_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceEntityEmails_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityEmails_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityEmails_Bool_Exp;
};

/** columns and relationships of "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Aggregate = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes_aggregate';
  aggregate: Maybe<MatterServiceEntityPhoneNumberTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityPhoneNumberTypes>;
};

/** aggregate fields of "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityPhoneNumberTypes_Max_Fields>;
  min: Maybe<MatterServiceEntityPhoneNumberTypes_Min_Fields>;
};

/** aggregate fields of "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "entityPhoneNumberTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityPhoneNumberTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityPhoneNumberTypes" */
export enum MatterServiceEntityPhoneNumberTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  EntityPhoneNumberTypesPkey = 'entityPhoneNumberTypes_pkey',
}

export enum MatterServiceEntityPhoneNumberTypes_Enum {
  Cell = 'CELL',
  Fax = 'FAX',
  Home = 'HOME',
  Work = 'WORK',
}

/** Boolean expression to compare columns of type "entityPhoneNumberTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceEntityPhoneNumberTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Enum>>;
};

/** input type for inserting data into table "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityPhoneNumberTypes_Max_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceEntityPhoneNumberTypes_Min_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Mutation_Response = {
  __typename?: 'MatterServiceEntityPhoneNumberTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityPhoneNumberTypes>;
};

/** on_conflict condition type for table "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_On_Conflict = {
  constraint: MatterServiceEntityPhoneNumberTypes_Constraint;
  update_columns: Array<MatterServiceEntityPhoneNumberTypes_Update_Column>;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "entityPhoneNumberTypes". */
export type MatterServiceEntityPhoneNumberTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityPhoneNumberTypes */
export type MatterServiceEntityPhoneNumberTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "entityPhoneNumberTypes" */
export enum MatterServiceEntityPhoneNumberTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entityPhoneNumberTypes" */
export type MatterServiceEntityPhoneNumberTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityPhoneNumberTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityPhoneNumberTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entityPhoneNumberTypes" */
export enum MatterServiceEntityPhoneNumberTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceEntityPhoneNumberTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityPhoneNumberTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityPhoneNumberTypes_Bool_Exp;
};

/** columns and relationships of "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers = {
  __typename?: 'MatterServiceEntityPhoneNumbers';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  entityType: MatterServiceMatterEntityTypes_Enum;
  id: Scalars['MatterServiceUuid']['output'];
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  type: MatterServiceEntityPhoneNumberTypes_Enum;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** aggregated selection of "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Aggregate = {
  __typename?: 'MatterServiceEntityPhoneNumbers_aggregate';
  aggregate: Maybe<MatterServiceEntityPhoneNumbers_Aggregate_Fields>;
  nodes: Array<MatterServiceEntityPhoneNumbers>;
};

export type MatterServiceEntityPhoneNumbers_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceEntityPhoneNumbers_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceEntityPhoneNumbers_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Aggregate_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumbers_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceEntityPhoneNumbers_Max_Fields>;
  min: Maybe<MatterServiceEntityPhoneNumbers_Min_Fields>;
};

/** aggregate fields of "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceEntityPhoneNumbers_Max_Order_By>;
  min: InputMaybe<MatterServiceEntityPhoneNumbers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceEntityPhoneNumbers_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceEntityPhoneNumbers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "entityPhoneNumbers". All fields are combined with a logical 'AND'. */
export type MatterServiceEntityPhoneNumbers_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Bool_Exp>>;
  _not: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  attorneyId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  courtId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  organizationId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  personId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  phoneNumber: InputMaybe<MatterServiceString_Comparison_Exp>;
  type: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "entityPhoneNumbers" */
export enum MatterServiceEntityPhoneNumbers_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityPhoneNumbersPkey = 'entityPhoneNumbers_pkey',
}

/** input type for inserting data into table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceEntityPhoneNumbers_Max_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumbers_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  phoneNumber: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceEntityPhoneNumbers_Min_Fields = {
  __typename?: 'MatterServiceEntityPhoneNumbers_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  phoneNumber: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Mutation_Response = {
  __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceEntityPhoneNumbers>;
};

/** on_conflict condition type for table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_On_Conflict = {
  constraint: MatterServiceEntityPhoneNumbers_Constraint;
  update_columns: Array<MatterServiceEntityPhoneNumbers_Update_Column>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

/** Ordering options when selecting data from "entityPhoneNumbers". */
export type MatterServiceEntityPhoneNumbers_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  entityType: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  phoneNumber: InputMaybe<MatterServiceOrder_By>;
  type: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: entityPhoneNumbers */
export type MatterServiceEntityPhoneNumbers_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "entityPhoneNumbers" */
export enum MatterServiceEntityPhoneNumbers_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "entityPhoneNumbers" */
export type MatterServiceEntityPhoneNumbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceEntityPhoneNumbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceEntityPhoneNumbers_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  entityType: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<MatterServiceEntityPhoneNumberTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "entityPhoneNumbers" */
export enum MatterServiceEntityPhoneNumbers_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityType = 'entityType',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PersonId = 'personId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceEntityPhoneNumbers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceEntityPhoneNumbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceEntityPhoneNumbers_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type MatterServiceInt_Comparison_Exp = {
  _eq: InputMaybe<Scalars['Int']['input']>;
  _gt: InputMaybe<Scalars['Int']['input']>;
  _gte: InputMaybe<Scalars['Int']['input']>;
  _in: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Int']['input']>;
  _lte: InputMaybe<Scalars['Int']['input']>;
  _neq: InputMaybe<Scalars['Int']['input']>;
  _nin: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MatterServiceJsonb_Cast_Exp = {
  String: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type MatterServiceJsonb_Comparison_Exp = {
  _cast: InputMaybe<MatterServiceJsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _eq: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _gt: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _gte: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any: InputMaybe<Array<Scalars['String']['input']>>;
  _in: InputMaybe<Array<Scalars['MatterServiceJsonb']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _lte: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _neq: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  _nin: InputMaybe<Array<Scalars['MatterServiceJsonb']['input']>>;
};

/** columns and relationships of "marriages" */
export type MatterServiceMarriages = {
  __typename?: 'MatterServiceMarriages';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  dateOfMarriage: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_marriage" */
  formattedDateOfMarriage: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  matterId: Scalars['MatterServiceUuid']['output'];
  /** A computed field, executes function "length_of_marriage_months" */
  monthsOfMarriage: Maybe<Scalars['String']['output']>;
  restorationOfPreviousName: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
  /** A computed field, executes function "length_of_marriage_years" */
  yearsOfMarriage: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "marriages" */
export type MatterServiceMarriages_Aggregate = {
  __typename?: 'MatterServiceMarriages_aggregate';
  aggregate: Maybe<MatterServiceMarriages_Aggregate_Fields>;
  nodes: Array<MatterServiceMarriages>;
};

/** aggregate fields of "marriages" */
export type MatterServiceMarriages_Aggregate_Fields = {
  __typename?: 'MatterServiceMarriages_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMarriages_Max_Fields>;
  min: Maybe<MatterServiceMarriages_Min_Fields>;
};

/** aggregate fields of "marriages" */
export type MatterServiceMarriages_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMarriages_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "marriages". All fields are combined with a logical 'AND'. */
export type MatterServiceMarriages_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMarriages_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMarriages_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMarriages_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  dateOfMarriage: InputMaybe<MatterServiceDate_Comparison_Exp>;
  dateOfSeparation: InputMaybe<MatterServiceDate_Comparison_Exp>;
  formattedDateOfMarriage: InputMaybe<MatterServiceString_Comparison_Exp>;
  formattedDateOfSeparation: InputMaybe<MatterServiceString_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  monthsOfMarriage: InputMaybe<MatterServiceString_Comparison_Exp>;
  restorationOfPreviousName: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  yearsOfMarriage: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "marriages" */
export enum MatterServiceMarriages_Constraint {
  /** unique or primary key constraint on columns "matterId" */
  MarriagesMatterIdKey = 'marriages_matterId_key',
  /** unique or primary key constraint on columns "id" */
  MarriagesPkey = 'marriages_pkey',
}

/** input type for inserting data into table "marriages" */
export type MatterServiceMarriages_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfMarriage: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  restorationOfPreviousName: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMarriages_Max_Fields = {
  __typename?: 'MatterServiceMarriages_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfMarriage: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_marriage" */
  formattedDateOfMarriage: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  /** A computed field, executes function "length_of_marriage_months" */
  monthsOfMarriage: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "length_of_marriage_years" */
  yearsOfMarriage: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMarriages_Min_Fields = {
  __typename?: 'MatterServiceMarriages_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfMarriage: Maybe<Scalars['MatterServiceDate']['output']>;
  dateOfSeparation: Maybe<Scalars['MatterServiceDate']['output']>;
  /** A computed field, executes function "formatted_date_of_marriage" */
  formattedDateOfMarriage: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_separation" */
  formattedDateOfSeparation: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  /** A computed field, executes function "length_of_marriage_months" */
  monthsOfMarriage: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  /** A computed field, executes function "length_of_marriage_years" */
  yearsOfMarriage: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "marriages" */
export type MatterServiceMarriages_Mutation_Response = {
  __typename?: 'MatterServiceMarriages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMarriages>;
};

/** input type for inserting object relation for remote table "marriages" */
export type MatterServiceMarriages_Obj_Rel_Insert_Input = {
  data: MatterServiceMarriages_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMarriages_On_Conflict>;
};

/** on_conflict condition type for table "marriages" */
export type MatterServiceMarriages_On_Conflict = {
  constraint: MatterServiceMarriages_Constraint;
  update_columns: Array<MatterServiceMarriages_Update_Column>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

/** Ordering options when selecting data from "marriages". */
export type MatterServiceMarriages_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  dateOfMarriage: InputMaybe<MatterServiceOrder_By>;
  dateOfSeparation: InputMaybe<MatterServiceOrder_By>;
  formattedDateOfMarriage: InputMaybe<MatterServiceOrder_By>;
  formattedDateOfSeparation: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  monthsOfMarriage: InputMaybe<MatterServiceOrder_By>;
  restorationOfPreviousName: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  yearsOfMarriage: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: marriages */
export type MatterServiceMarriages_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "marriages" */
export enum MatterServiceMarriages_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfMarriage = 'dateOfMarriage',
  /** column name */
  DateOfSeparation = 'dateOfSeparation',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  RestorationOfPreviousName = 'restorationOfPreviousName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "marriages" */
export type MatterServiceMarriages_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfMarriage: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  restorationOfPreviousName: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "marriages" */
export type MatterServiceMarriages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMarriages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMarriages_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfMarriage: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  restorationOfPreviousName: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "marriages" */
export enum MatterServiceMarriages_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfMarriage = 'dateOfMarriage',
  /** column name */
  DateOfSeparation = 'dateOfSeparation',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  RestorationOfPreviousName = 'restorationOfPreviousName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceMarriages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMarriages_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMarriages_Bool_Exp;
};

/** columns and relationships of "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Aggregate = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterAttorneyRoleTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterAttorneyRoleTypes>;
};

/** aggregate fields of "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterAttorneyRoleTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterAttorneyRoleTypes_Min_Fields>;
};

/** aggregate fields of "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<
    Array<MatterServiceMatterAttorneyRoleTypes_Select_Column>
  >;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterAttorneyRoleTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterAttorneyRoleTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterAttorneyRoleTypes" */
export enum MatterServiceMatterAttorneyRoleTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterAttorneyRoleTypesPkey = 'matterAttorneyRoleTypes_pkey',
}

export enum MatterServiceMatterAttorneyRoleTypes_Enum {
  FilingAttorney = 'FILING_ATTORNEY',
  OpposingCounsel = 'OPPOSING_COUNSEL',
}

/** Boolean expression to compare columns of type "matterAttorneyRoleTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterAttorneyRoleTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Enum>>;
};

/** input type for inserting data into table "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterAttorneyRoleTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterAttorneyRoleTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterAttorneyRoleTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterAttorneyRoleTypes>;
};

/** on_conflict condition type for table "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_On_Conflict = {
  constraint: MatterServiceMatterAttorneyRoleTypes_Constraint;
  update_columns: Array<MatterServiceMatterAttorneyRoleTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterAttorneyRoleTypes". */
export type MatterServiceMatterAttorneyRoleTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterAttorneyRoleTypes */
export type MatterServiceMatterAttorneyRoleTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterAttorneyRoleTypes" */
export enum MatterServiceMatterAttorneyRoleTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterAttorneyRoleTypes" */
export type MatterServiceMatterAttorneyRoleTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterAttorneyRoleTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterAttorneyRoleTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterAttorneyRoleTypes" */
export enum MatterServiceMatterAttorneyRoleTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterAttorneyRoleTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterAttorneyRoleTypes_Bool_Exp;
};

/** columns and relationships of "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms = {
  __typename?: 'MatterServiceMatterDocsAndForms';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  contentfulId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  data: Scalars['MatterServiceJsonb']['output'];
  docspringTemplateId: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  folder: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  folderId: Maybe<Scalars['MatterServiceUuid']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  isDuplicate: Maybe<Scalars['Boolean']['output']>;
  keepUpdatedWithMatter: Scalars['Boolean']['output'];
  /** An object relationship */
  matter: MatterServiceMatters;
  matterId: Scalars['MatterServiceUuid']['output'];
  position: Maybe<Scalars['Int']['output']>;
  status: Maybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  type: MatterServiceMatterDocsAndFormsTypes_Enum;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** columns and relationships of "matterDocsAndForms" */
export type MatterServiceMatterDocsAndFormsDataArgs = {
  path: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  id: Scalars['MatterServiceUuid']['output'];
  /** An array relationship */
  items: Array<MatterServiceMatterDocsAndForms>;
  /** An aggregate relationship */
  items_aggregate: MatterServiceMatterDocsAndForms_Aggregate;
  /** An object relationship */
  matter: MatterServiceMatters;
  matterId: Scalars['MatterServiceUuid']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** columns and relationships of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFoldersItemsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** columns and relationships of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFoldersItems_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** aggregated selection of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Aggregate = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders_aggregate';
  aggregate: Maybe<MatterServiceMatterDocsAndFormsFolders_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterDocsAndFormsFolders>;
};

export type MatterServiceMatterDocsAndFormsFolders_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceMatterDocsAndFormsFolders_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterDocsAndFormsFolders_Max_Fields>;
  min: Maybe<MatterServiceMatterDocsAndFormsFolders_Min_Fields>;
};

/** aggregate fields of "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Max_Order_By>;
  min: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceMatterDocsAndFormsFolders_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsFolders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "matterDocsAndFormsFolders". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterDocsAndFormsFolders_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  items: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  items_aggregate: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp>;
  matter: InputMaybe<MatterServiceMatters_Bool_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  name: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterDocsAndFormsFolders" */
export enum MatterServiceMatterDocsAndFormsFolders_Constraint {
  /** unique or primary key constraint on columns "id" */
  MatterDocsAndFormsFoldersPkey = 'matterDocsAndFormsFolders_pkey',
}

/** input type for inserting data into table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  items: InputMaybe<MatterServiceMatterDocsAndForms_Arr_Rel_Insert_Input>;
  matter: InputMaybe<MatterServiceMatters_Obj_Rel_Insert_Input>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterDocsAndFormsFolders_Max_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceMatterDocsAndFormsFolders_Min_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Mutation_Response = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterDocsAndFormsFolders>;
};

/** input type for inserting object relation for remote table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Obj_Rel_Insert_Input = {
  data: MatterServiceMatterDocsAndFormsFolders_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsFolders_On_Conflict>;
};

/** on_conflict condition type for table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_On_Conflict = {
  constraint: MatterServiceMatterDocsAndFormsFolders_Constraint;
  update_columns: Array<MatterServiceMatterDocsAndFormsFolders_Update_Column>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

/** Ordering options when selecting data from "matterDocsAndFormsFolders". */
export type MatterServiceMatterDocsAndFormsFolders_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  items_aggregate: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Order_By>;
  matter: InputMaybe<MatterServiceMatters_Order_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterDocsAndFormsFolders */
export type MatterServiceMatterDocsAndFormsFolders_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "matterDocsAndFormsFolders" */
export enum MatterServiceMatterDocsAndFormsFolders_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "matterDocsAndFormsFolders" */
export type MatterServiceMatterDocsAndFormsFolders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterDocsAndFormsFolders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterDocsAndFormsFolders_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "matterDocsAndFormsFolders" */
export enum MatterServiceMatterDocsAndFormsFolders_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceMatterDocsAndFormsFolders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterDocsAndFormsFolders_Bool_Exp;
};

/** columns and relationships of "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Aggregate = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses_aggregate';
  aggregate: Maybe<MatterServiceMatterDocsAndFormsStatuses_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterDocsAndFormsStatuses>;
};

/** aggregate fields of "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterDocsAndFormsStatuses_Max_Fields>;
  min: Maybe<MatterServiceMatterDocsAndFormsStatuses_Min_Fields>;
};

/** aggregate fields of "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Aggregate_FieldsCountArgs =
  {
    columns: InputMaybe<
      Array<MatterServiceMatterDocsAndFormsStatuses_Select_Column>
    >;
    distinct: InputMaybe<Scalars['Boolean']['input']>;
  };

/** Boolean expression to filter rows from the table "matterDocsAndFormsStatuses". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterDocsAndFormsStatuses_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterDocsAndFormsStatuses" */
export enum MatterServiceMatterDocsAndFormsStatuses_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterDocsAndFormsStatusesPkey = 'matterDocsAndFormsStatuses_pkey',
}

export enum MatterServiceMatterDocsAndFormsStatuses_Enum {
  Done = 'DONE',
  Draft = 'DRAFT',
  New = 'NEW',
}

/** Boolean expression to compare columns of type "matterDocsAndFormsStatuses_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterDocsAndFormsStatuses_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Enum>>;
};

/** input type for inserting data into table "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterDocsAndFormsStatuses_Max_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterDocsAndFormsStatuses_Min_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Mutation_Response = {
  __typename?: 'MatterServiceMatterDocsAndFormsStatuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterDocsAndFormsStatuses>;
};

/** on_conflict condition type for table "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_On_Conflict = {
  constraint: MatterServiceMatterDocsAndFormsStatuses_Constraint;
  update_columns: Array<MatterServiceMatterDocsAndFormsStatuses_Update_Column>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
};

/** Ordering options when selecting data from "matterDocsAndFormsStatuses". */
export type MatterServiceMatterDocsAndFormsStatuses_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterDocsAndFormsStatuses */
export type MatterServiceMatterDocsAndFormsStatuses_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterDocsAndFormsStatuses" */
export enum MatterServiceMatterDocsAndFormsStatuses_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterDocsAndFormsStatuses" */
export type MatterServiceMatterDocsAndFormsStatuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterDocsAndFormsStatuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterDocsAndFormsStatuses_Stream_Cursor_Value_Input =
  {
    value: InputMaybe<Scalars['String']['input']>;
  };

/** update columns of table "matterDocsAndFormsStatuses" */
export enum MatterServiceMatterDocsAndFormsStatuses_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterDocsAndFormsStatuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterDocsAndFormsStatuses_Bool_Exp;
};

/** columns and relationships of "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Aggregate = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterDocsAndFormsTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterDocsAndFormsTypes>;
};

/** aggregate fields of "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterDocsAndFormsTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterDocsAndFormsTypes_Min_Fields>;
};

/** aggregate fields of "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsTypes_Select_Column>
  >;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterDocsAndFormsTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterDocsAndFormsTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterDocsAndFormsTypes" */
export enum MatterServiceMatterDocsAndFormsTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterDocsAndFormsTypesPkey = 'matterDocsAndFormsTypes_pkey',
}

export enum MatterServiceMatterDocsAndFormsTypes_Enum {
  TrueDoc = 'TrueDoc',
  TrueForm = 'TrueForm',
}

/** Boolean expression to compare columns of type "matterDocsAndFormsTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterDocsAndFormsTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Enum>>;
};

/** input type for inserting data into table "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterDocsAndFormsTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterDocsAndFormsTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterDocsAndFormsTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterDocsAndFormsTypes>;
};

/** on_conflict condition type for table "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_On_Conflict = {
  constraint: MatterServiceMatterDocsAndFormsTypes_Constraint;
  update_columns: Array<MatterServiceMatterDocsAndFormsTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterDocsAndFormsTypes". */
export type MatterServiceMatterDocsAndFormsTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterDocsAndFormsTypes */
export type MatterServiceMatterDocsAndFormsTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterDocsAndFormsTypes" */
export enum MatterServiceMatterDocsAndFormsTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterDocsAndFormsTypes" */
export type MatterServiceMatterDocsAndFormsTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterDocsAndFormsTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterDocsAndFormsTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterDocsAndFormsTypes" */
export enum MatterServiceMatterDocsAndFormsTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterDocsAndFormsTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterDocsAndFormsTypes_Bool_Exp;
};

/** aggregated selection of "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Aggregate = {
  __typename?: 'MatterServiceMatterDocsAndForms_aggregate';
  aggregate: Maybe<MatterServiceMatterDocsAndForms_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterDocsAndForms>;
};

export type MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Bool_And = {
  arguments: MatterServiceMatterDocsAndForms_Select_Column_MatterDocsAndForms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  predicate: MatterServiceBoolean_Comparison_Exp;
};

export type MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: MatterServiceMatterDocsAndForms_Select_Column_MatterDocsAndForms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  predicate: MatterServiceBoolean_Comparison_Exp;
};

export type MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_aggregate_fields';
  avg: Maybe<MatterServiceMatterDocsAndForms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterDocsAndForms_Max_Fields>;
  min: Maybe<MatterServiceMatterDocsAndForms_Min_Fields>;
  stddev: Maybe<MatterServiceMatterDocsAndForms_Stddev_Fields>;
  stddev_pop: Maybe<MatterServiceMatterDocsAndForms_Stddev_Pop_Fields>;
  stddev_samp: Maybe<MatterServiceMatterDocsAndForms_Stddev_Samp_Fields>;
  sum: Maybe<MatterServiceMatterDocsAndForms_Sum_Fields>;
  var_pop: Maybe<MatterServiceMatterDocsAndForms_Var_Pop_Fields>;
  var_samp: Maybe<MatterServiceMatterDocsAndForms_Var_Samp_Fields>;
  variance: Maybe<MatterServiceMatterDocsAndForms_Variance_Fields>;
};

/** aggregate fields of "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Aggregate_Order_By = {
  avg: InputMaybe<MatterServiceMatterDocsAndForms_Avg_Order_By>;
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceMatterDocsAndForms_Max_Order_By>;
  min: InputMaybe<MatterServiceMatterDocsAndForms_Min_Order_By>;
  stddev: InputMaybe<MatterServiceMatterDocsAndForms_Stddev_Order_By>;
  stddev_pop: InputMaybe<MatterServiceMatterDocsAndForms_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<MatterServiceMatterDocsAndForms_Stddev_Samp_Order_By>;
  sum: InputMaybe<MatterServiceMatterDocsAndForms_Sum_Order_By>;
  var_pop: InputMaybe<MatterServiceMatterDocsAndForms_Var_Pop_Order_By>;
  var_samp: InputMaybe<MatterServiceMatterDocsAndForms_Var_Samp_Order_By>;
  variance: InputMaybe<MatterServiceMatterDocsAndForms_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MatterServiceMatterDocsAndForms_Append_Input = {
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
};

/** input type for inserting array relation for remote table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceMatterDocsAndForms_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatterDocsAndForms_On_Conflict>;
};

/** aggregate avg on columns */
export type MatterServiceMatterDocsAndForms_Avg_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_avg_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Avg_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** Boolean expression to filter rows from the table "matterDocsAndForms". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterDocsAndForms_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterDocsAndForms_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterDocsAndForms_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  contentfulId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  data: InputMaybe<MatterServiceJsonb_Comparison_Exp>;
  docspringTemplateId: InputMaybe<MatterServiceString_Comparison_Exp>;
  folder: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
  folderId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  isDuplicate: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  keepUpdatedWithMatter: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  matter: InputMaybe<MatterServiceMatters_Bool_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  position: InputMaybe<MatterServiceInt_Comparison_Exp>;
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum_Comparison_Exp>;
  type: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterDocsAndForms" */
export enum MatterServiceMatterDocsAndForms_Constraint {
  /** unique or primary key constraint on columns "id" */
  MatterDocsAndFormsPkey = 'matterDocsAndForms_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MatterServiceMatterDocsAndForms_Delete_At_Path_Input = {
  data: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MatterServiceMatterDocsAndForms_Delete_Elem_Input = {
  data: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MatterServiceMatterDocsAndForms_Delete_Key_Input = {
  data: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Inc_Input = {
  position: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  contentfulId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  docspringTemplateId: InputMaybe<Scalars['String']['input']>;
  folder: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Obj_Rel_Insert_Input>;
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  isDuplicate: InputMaybe<Scalars['Boolean']['input']>;
  keepUpdatedWithMatter: InputMaybe<Scalars['Boolean']['input']>;
  matter: InputMaybe<MatterServiceMatters_Obj_Rel_Insert_Input>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  type: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterDocsAndForms_Max_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  contentfulId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  docspringTemplateId: Maybe<Scalars['String']['output']>;
  folderId: Maybe<Scalars['MatterServiceUuid']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  contentfulId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  docspringTemplateId: InputMaybe<MatterServiceOrder_By>;
  folderId: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  position: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceMatterDocsAndForms_Min_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  contentfulId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  docspringTemplateId: Maybe<Scalars['String']['output']>;
  folderId: Maybe<Scalars['MatterServiceUuid']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  contentfulId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  docspringTemplateId: InputMaybe<MatterServiceOrder_By>;
  folderId: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  position: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Mutation_Response = {
  __typename?: 'MatterServiceMatterDocsAndForms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterDocsAndForms>;
};

/** on_conflict condition type for table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_On_Conflict = {
  constraint: MatterServiceMatterDocsAndForms_Constraint;
  update_columns: Array<MatterServiceMatterDocsAndForms_Update_Column>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** Ordering options when selecting data from "matterDocsAndForms". */
export type MatterServiceMatterDocsAndForms_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  contentfulId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  data: InputMaybe<MatterServiceOrder_By>;
  docspringTemplateId: InputMaybe<MatterServiceOrder_By>;
  folder: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Order_By>;
  folderId: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  isDuplicate: InputMaybe<MatterServiceOrder_By>;
  keepUpdatedWithMatter: InputMaybe<MatterServiceOrder_By>;
  matter: InputMaybe<MatterServiceMatters_Order_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  position: InputMaybe<MatterServiceOrder_By>;
  status: InputMaybe<MatterServiceOrder_By>;
  type: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterDocsAndForms */
export type MatterServiceMatterDocsAndForms_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MatterServiceMatterDocsAndForms_Prepend_Input = {
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
};

/** select columns of table "matterDocsAndForms" */
export enum MatterServiceMatterDocsAndForms_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ContentfulId = 'contentfulId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  DocspringTemplateId = 'docspringTemplateId',
  /** column name */
  FolderId = 'folderId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDuplicate = 'isDuplicate',
  /** column name */
  KeepUpdatedWithMatter = 'keepUpdatedWithMatter',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  Position = 'position',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "matterDocsAndForms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "matterDocsAndForms" */
export enum MatterServiceMatterDocsAndForms_Select_Column_MatterDocsAndForms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDuplicate = 'isDuplicate',
  /** column name */
  KeepUpdatedWithMatter = 'keepUpdatedWithMatter',
}

/** select "matterDocsAndForms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "matterDocsAndForms" */
export enum MatterServiceMatterDocsAndForms_Select_Column_MatterDocsAndForms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDuplicate = 'isDuplicate',
  /** column name */
  KeepUpdatedWithMatter = 'keepUpdatedWithMatter',
}

/** input type for updating data in table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  contentfulId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  docspringTemplateId: InputMaybe<Scalars['String']['input']>;
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  isDuplicate: InputMaybe<Scalars['Boolean']['input']>;
  keepUpdatedWithMatter: InputMaybe<Scalars['Boolean']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  type: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate stddev on columns */
export type MatterServiceMatterDocsAndForms_Stddev_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_stddev_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Stddev_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate stddev_pop on columns */
export type MatterServiceMatterDocsAndForms_Stddev_Pop_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_stddev_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Stddev_Pop_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate stddev_samp on columns */
export type MatterServiceMatterDocsAndForms_Stddev_Samp_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_stddev_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Stddev_Samp_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** Streaming cursor of the table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterDocsAndForms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterDocsAndForms_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  contentfulId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  docspringTemplateId: InputMaybe<Scalars['String']['input']>;
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  isDuplicate: InputMaybe<Scalars['Boolean']['input']>;
  keepUpdatedWithMatter: InputMaybe<Scalars['Boolean']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  type: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate sum on columns */
export type MatterServiceMatterDocsAndForms_Sum_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_sum_fields';
  position: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Sum_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** update columns of table "matterDocsAndForms" */
export enum MatterServiceMatterDocsAndForms_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ContentfulId = 'contentfulId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  DocspringTemplateId = 'docspringTemplateId',
  /** column name */
  FolderId = 'folderId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDuplicate = 'isDuplicate',
  /** column name */
  KeepUpdatedWithMatter = 'keepUpdatedWithMatter',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  Position = 'position',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceMatterDocsAndForms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append: InputMaybe<MatterServiceMatterDocsAndForms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path: InputMaybe<MatterServiceMatterDocsAndForms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<MatterServiceMatterDocsAndForms_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend: InputMaybe<MatterServiceMatterDocsAndForms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterDocsAndForms_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterDocsAndForms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MatterServiceMatterDocsAndForms_Var_Pop_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_var_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Var_Pop_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate var_samp on columns */
export type MatterServiceMatterDocsAndForms_Var_Samp_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_var_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Var_Samp_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate variance on columns */
export type MatterServiceMatterDocsAndForms_Variance_Fields = {
  __typename?: 'MatterServiceMatterDocsAndForms_variance_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "matterDocsAndForms" */
export type MatterServiceMatterDocsAndForms_Variance_Order_By = {
  position: InputMaybe<MatterServiceOrder_By>;
};

/** columns and relationships of "matterEntities" */
export type MatterServiceMatterEntities = {
  __typename?: 'MatterServiceMatterEntities';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  /** An object relationship */
  attorney: Maybe<MatterServiceAttorneys>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  attorneyRole: Maybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  /** An object relationship */
  court: Maybe<MatterServiceCourts>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  /** An object relationship */
  matter: MatterServiceMatters;
  matterId: Scalars['MatterServiceUuid']['output'];
  /** An object relationship */
  organization: Maybe<MatterServiceOrganizations>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationRole: Maybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  /** An object relationship */
  person: Maybe<MatterServicePeople>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personRole: Maybe<MatterServiceMatterPersonRoleTypes_Enum>;
  type: MatterServiceMatterEntityTypes_Enum;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** aggregated selection of "matterEntities" */
export type MatterServiceMatterEntities_Aggregate = {
  __typename?: 'MatterServiceMatterEntities_aggregate';
  aggregate: Maybe<MatterServiceMatterEntities_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterEntities>;
};

export type MatterServiceMatterEntities_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceMatterEntities_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "matterEntities" */
export type MatterServiceMatterEntities_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterEntities_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterEntities_Max_Fields>;
  min: Maybe<MatterServiceMatterEntities_Min_Fields>;
};

/** aggregate fields of "matterEntities" */
export type MatterServiceMatterEntities_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matterEntities" */
export type MatterServiceMatterEntities_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceMatterEntities_Max_Order_By>;
  min: InputMaybe<MatterServiceMatterEntities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "matterEntities" */
export type MatterServiceMatterEntities_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceMatterEntities_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatterEntities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "matterEntities". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterEntities_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterEntities_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterEntities_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  attorney: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  attorneyId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  attorneyRole: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum_Comparison_Exp>;
  court: InputMaybe<MatterServiceCourts_Bool_Exp>;
  courtId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matter: InputMaybe<MatterServiceMatters_Bool_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  organization: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
  organizationId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  organizationRole: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum_Comparison_Exp>;
  person: InputMaybe<MatterServicePeople_Bool_Exp>;
  personId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  personRole: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum_Comparison_Exp>;
  type: InputMaybe<MatterServiceMatterEntityTypes_Enum_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterEntities" */
export enum MatterServiceMatterEntities_Constraint {
  /** unique or primary key constraint on columns "id" */
  MatterEntitiesPkey = 'matterEntities_pkey',
}

/** input type for inserting data into table "matterEntities" */
export type MatterServiceMatterEntities_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorney: InputMaybe<MatterServiceAttorneys_Obj_Rel_Insert_Input>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  attorneyRole: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  court: InputMaybe<MatterServiceCourts_Obj_Rel_Insert_Input>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matter: InputMaybe<MatterServiceMatters_Obj_Rel_Insert_Input>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organization: InputMaybe<MatterServiceOrganizations_Obj_Rel_Insert_Input>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationRole: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  person: InputMaybe<MatterServicePeople_Obj_Rel_Insert_Input>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personRole: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  type: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterEntities_Max_Fields = {
  __typename?: 'MatterServiceMatterEntities_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "matterEntities" */
export type MatterServiceMatterEntities_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceMatterEntities_Min_Fields = {
  __typename?: 'MatterServiceMatterEntities_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  attorneyId: Maybe<Scalars['MatterServiceUuid']['output']>;
  courtId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  organizationId: Maybe<Scalars['MatterServiceUuid']['output']>;
  personId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "matterEntities" */
export type MatterServiceMatterEntities_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "matterEntities" */
export type MatterServiceMatterEntities_Mutation_Response = {
  __typename?: 'MatterServiceMatterEntities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterEntities>;
};

/** on_conflict condition type for table "matterEntities" */
export type MatterServiceMatterEntities_On_Conflict = {
  constraint: MatterServiceMatterEntities_Constraint;
  update_columns: Array<MatterServiceMatterEntities_Update_Column>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** Ordering options when selecting data from "matterEntities". */
export type MatterServiceMatterEntities_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorney: InputMaybe<MatterServiceAttorneys_Order_By>;
  attorneyId: InputMaybe<MatterServiceOrder_By>;
  attorneyRole: InputMaybe<MatterServiceOrder_By>;
  court: InputMaybe<MatterServiceCourts_Order_By>;
  courtId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matter: InputMaybe<MatterServiceMatters_Order_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  organization: InputMaybe<MatterServiceOrganizations_Order_By>;
  organizationId: InputMaybe<MatterServiceOrder_By>;
  organizationRole: InputMaybe<MatterServiceOrder_By>;
  person: InputMaybe<MatterServicePeople_Order_By>;
  personId: InputMaybe<MatterServiceOrder_By>;
  personRole: InputMaybe<MatterServiceOrder_By>;
  type: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterEntities */
export type MatterServiceMatterEntities_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "matterEntities" */
export enum MatterServiceMatterEntities_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  AttorneyRole = 'attorneyRole',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OrganizationRole = 'organizationRole',
  /** column name */
  PersonId = 'personId',
  /** column name */
  PersonRole = 'personRole',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "matterEntities" */
export type MatterServiceMatterEntities_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  attorneyRole: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationRole: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personRole: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  type: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "matterEntities" */
export type MatterServiceMatterEntities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterEntities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterEntities_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  attorneyId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  attorneyRole: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Enum>;
  courtId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  organizationRole: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  personRole: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  type: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "matterEntities" */
export enum MatterServiceMatterEntities_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  AttorneyId = 'attorneyId',
  /** column name */
  AttorneyRole = 'attorneyRole',
  /** column name */
  CourtId = 'courtId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OrganizationRole = 'organizationRole',
  /** column name */
  PersonId = 'personId',
  /** column name */
  PersonRole = 'personRole',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceMatterEntities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterEntities_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterEntities_Bool_Exp;
};

/** columns and relationships of "matterEntityTypes" */
export type MatterServiceMatterEntityTypes = {
  __typename?: 'MatterServiceMatterEntityTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Aggregate = {
  __typename?: 'MatterServiceMatterEntityTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterEntityTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterEntityTypes>;
};

/** aggregate fields of "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterEntityTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterEntityTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterEntityTypes_Min_Fields>;
};

/** aggregate fields of "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterEntityTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterEntityTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterEntityTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterEntityTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterEntityTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterEntityTypes" */
export enum MatterServiceMatterEntityTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterEntityTypesPkey = 'matterEntityTypes_pkey',
}

export enum MatterServiceMatterEntityTypes_Enum {
  Attorney = 'ATTORNEY',
  Court = 'COURT',
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

/** Boolean expression to compare columns of type "matterEntityTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterEntityTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterEntityTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterEntityTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterEntityTypes_Enum>>;
};

/** input type for inserting data into table "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterEntityTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterEntityTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterEntityTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterEntityTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterEntityTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterEntityTypes>;
};

/** on_conflict condition type for table "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_On_Conflict = {
  constraint: MatterServiceMatterEntityTypes_Constraint;
  update_columns: Array<MatterServiceMatterEntityTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterEntityTypes". */
export type MatterServiceMatterEntityTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterEntityTypes */
export type MatterServiceMatterEntityTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterEntityTypes" */
export enum MatterServiceMatterEntityTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterEntityTypes" */
export type MatterServiceMatterEntityTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterEntityTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterEntityTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterEntityTypes" */
export enum MatterServiceMatterEntityTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterEntityTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterEntityTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterEntityTypes_Bool_Exp;
};

/** columns and relationships of "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes = {
  __typename?: 'MatterServiceMatterFilingForTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Aggregate = {
  __typename?: 'MatterServiceMatterFilingForTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterFilingForTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterFilingForTypes>;
};

/** aggregate fields of "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterFilingForTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterFilingForTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterFilingForTypes_Min_Fields>;
};

/** aggregate fields of "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterFilingForTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterFilingForTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterFilingForTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterFilingForTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterFilingForTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterFilingForTypes" */
export enum MatterServiceMatterFilingForTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterFilingForTypesPkey = 'matterFilingForTypes_pkey',
}

export enum MatterServiceMatterFilingForTypes_Enum {
  DefendantOrRespondent = 'DEFENDANT_OR_RESPONDENT',
  PlaintiffOrPetitioner = 'PLAINTIFF_OR_PETITIONER',
}

/** Boolean expression to compare columns of type "matterFilingForTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterFilingForTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterFilingForTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterFilingForTypes_Enum>>;
};

/** input type for inserting data into table "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterFilingForTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterFilingForTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterFilingForTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterFilingForTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterFilingForTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterFilingForTypes>;
};

/** on_conflict condition type for table "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_On_Conflict = {
  constraint: MatterServiceMatterFilingForTypes_Constraint;
  update_columns: Array<MatterServiceMatterFilingForTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterFilingForTypes". */
export type MatterServiceMatterFilingForTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterFilingForTypes */
export type MatterServiceMatterFilingForTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterFilingForTypes" */
export enum MatterServiceMatterFilingForTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterFilingForTypes" */
export type MatterServiceMatterFilingForTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterFilingForTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterFilingForTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterFilingForTypes" */
export enum MatterServiceMatterFilingForTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterFilingForTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterFilingForTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterFilingForTypes_Bool_Exp;
};

/** columns and relationships of "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Aggregate = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterOrganizationRoleTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterOrganizationRoleTypes>;
};

/** aggregate fields of "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterOrganizationRoleTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterOrganizationRoleTypes_Min_Fields>;
};

/** aggregate fields of "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Aggregate_FieldsCountArgs =
  {
    columns: InputMaybe<
      Array<MatterServiceMatterOrganizationRoleTypes_Select_Column>
    >;
    distinct: InputMaybe<Scalars['Boolean']['input']>;
  };

/** Boolean expression to filter rows from the table "matterOrganizationRoleTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterOrganizationRoleTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterOrganizationRoleTypes" */
export enum MatterServiceMatterOrganizationRoleTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterOrganizationRoleTypesPkey = 'matterOrganizationRoleTypes_pkey',
}

export enum MatterServiceMatterOrganizationRoleTypes_Enum {
  CodefendentOrCorespondent = 'CODEFENDENT_OR_CORESPONDENT',
  CoplaintiffOrCopetitioner = 'COPLAINTIFF_OR_COPETITIONER',
  DefendentOrRespondent = 'DEFENDENT_OR_RESPONDENT',
  PlaintiffOrPetitioner = 'PLAINTIFF_OR_PETITIONER',
}

/** Boolean expression to compare columns of type "matterOrganizationRoleTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterOrganizationRoleTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterOrganizationRoleTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterOrganizationRoleTypes_Enum>>;
};

/** input type for inserting data into table "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterOrganizationRoleTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterOrganizationRoleTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterOrganizationRoleTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterOrganizationRoleTypes>;
};

/** on_conflict condition type for table "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_On_Conflict = {
  constraint: MatterServiceMatterOrganizationRoleTypes_Constraint;
  update_columns: Array<MatterServiceMatterOrganizationRoleTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterOrganizationRoleTypes". */
export type MatterServiceMatterOrganizationRoleTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterOrganizationRoleTypes */
export type MatterServiceMatterOrganizationRoleTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterOrganizationRoleTypes" */
export enum MatterServiceMatterOrganizationRoleTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterOrganizationRoleTypes" */
export type MatterServiceMatterOrganizationRoleTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterOrganizationRoleTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterOrganizationRoleTypes_Stream_Cursor_Value_Input =
  {
    value: InputMaybe<Scalars['String']['input']>;
  };

/** update columns of table "matterOrganizationRoleTypes" */
export enum MatterServiceMatterOrganizationRoleTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterOrganizationRoleTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterOrganizationRoleTypes_Bool_Exp;
};

/** columns and relationships of "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes = {
  __typename?: 'MatterServiceMatterPersonRoleTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Aggregate = {
  __typename?: 'MatterServiceMatterPersonRoleTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterPersonRoleTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterPersonRoleTypes>;
};

/** aggregate fields of "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterPersonRoleTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterPersonRoleTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterPersonRoleTypes_Min_Fields>;
};

/** aggregate fields of "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterPersonRoleTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterPersonRoleTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterPersonRoleTypes" */
export enum MatterServiceMatterPersonRoleTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterPersonRoleTypesPkey = 'matterPersonRoleTypes_pkey',
}

export enum MatterServiceMatterPersonRoleTypes_Enum {
  CodefendantOrCorespondent = 'CODEFENDANT_OR_CORESPONDENT',
  CoplaintiffOrCopetitioner = 'COPLAINTIFF_OR_COPETITIONER',
  DefendantOrRespondent = 'DEFENDANT_OR_RESPONDENT',
  MinorChild = 'MINOR_CHILD',
  PlaintiffOrPetitioner = 'PLAINTIFF_OR_PETITIONER',
  SettlorOne = 'SETTLOR_ONE',
  SettlorTwo = 'SETTLOR_TWO',
}

/** Boolean expression to compare columns of type "matterPersonRoleTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterPersonRoleTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Enum>>;
};

/** input type for inserting data into table "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterPersonRoleTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterPersonRoleTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterPersonRoleTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterPersonRoleTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterPersonRoleTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterPersonRoleTypes>;
};

/** on_conflict condition type for table "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_On_Conflict = {
  constraint: MatterServiceMatterPersonRoleTypes_Constraint;
  update_columns: Array<MatterServiceMatterPersonRoleTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterPersonRoleTypes". */
export type MatterServiceMatterPersonRoleTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterPersonRoleTypes */
export type MatterServiceMatterPersonRoleTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterPersonRoleTypes" */
export enum MatterServiceMatterPersonRoleTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterPersonRoleTypes" */
export type MatterServiceMatterPersonRoleTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterPersonRoleTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterPersonRoleTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterPersonRoleTypes" */
export enum MatterServiceMatterPersonRoleTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterPersonRoleTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterPersonRoleTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterPersonRoleTypes_Bool_Exp;
};

/** columns and relationships of "matterTypes" */
export type MatterServiceMatterTypes = {
  __typename?: 'MatterServiceMatterTypes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "matterTypes" */
export type MatterServiceMatterTypes_Aggregate = {
  __typename?: 'MatterServiceMatterTypes_aggregate';
  aggregate: Maybe<MatterServiceMatterTypes_Aggregate_Fields>;
  nodes: Array<MatterServiceMatterTypes>;
};

/** aggregate fields of "matterTypes" */
export type MatterServiceMatterTypes_Aggregate_Fields = {
  __typename?: 'MatterServiceMatterTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatterTypes_Max_Fields>;
  min: Maybe<MatterServiceMatterTypes_Min_Fields>;
};

/** aggregate fields of "matterTypes" */
export type MatterServiceMatterTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatterTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "matterTypes". All fields are combined with a logical 'AND'. */
export type MatterServiceMatterTypes_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatterTypes_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatterTypes_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "matterTypes" */
export enum MatterServiceMatterTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  MatterTypesPkey = 'matterTypes_pkey',
}

export enum MatterServiceMatterTypes_Enum {
  FamilyLaw = 'FAMILY_LAW',
  TrustAndEstates = 'TRUST_AND_ESTATES',
}

/** Boolean expression to compare columns of type "matterTypes_enum". All fields are combined with logical 'AND'. */
export type MatterServiceMatterTypes_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServiceMatterTypes_Enum>;
  _in: InputMaybe<Array<MatterServiceMatterTypes_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServiceMatterTypes_Enum>;
  _nin: InputMaybe<Array<MatterServiceMatterTypes_Enum>>;
};

/** input type for inserting data into table "matterTypes" */
export type MatterServiceMatterTypes_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatterTypes_Max_Fields = {
  __typename?: 'MatterServiceMatterTypes_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServiceMatterTypes_Min_Fields = {
  __typename?: 'MatterServiceMatterTypes_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "matterTypes" */
export type MatterServiceMatterTypes_Mutation_Response = {
  __typename?: 'MatterServiceMatterTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatterTypes>;
};

/** on_conflict condition type for table "matterTypes" */
export type MatterServiceMatterTypes_On_Conflict = {
  constraint: MatterServiceMatterTypes_Constraint;
  update_columns: Array<MatterServiceMatterTypes_Update_Column>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "matterTypes". */
export type MatterServiceMatterTypes_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matterTypes */
export type MatterServiceMatterTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "matterTypes" */
export enum MatterServiceMatterTypes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "matterTypes" */
export type MatterServiceMatterTypes_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "matterTypes" */
export type MatterServiceMatterTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatterTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatterTypes_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "matterTypes" */
export enum MatterServiceMatterTypes_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServiceMatterTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatterTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatterTypes_Bool_Exp;
};

/** columns and relationships of "matters" */
export type MatterServiceMatters = {
  __typename?: 'MatterServiceMatters';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  /** A computed field, executes function "attorney_for_defendant_or_respondent" */
  attorneyForForDefendantOrRespondent: Maybe<Array<MatterServiceAttorneys>>;
  /** A computed field, executes function "attorney_for_plaintiff_or_petitioner" */
  attorneyForPlaintiffOrPetitioner: Maybe<Array<MatterServiceAttorneys>>;
  caseNumber: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  client: Maybe<MatterServiceClients>;
  clientId: Scalars['MatterServiceUuid']['output'];
  /** A computed field, executes function "court" */
  court: Maybe<Array<MatterServiceCourts>>;
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  /** A computed field, executes function "defendant_or_respondent" */
  defendantOrRespondent: Maybe<Array<MatterServicePeople>>;
  description: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  docsAndForms: Array<MatterServiceMatterDocsAndForms>;
  /** An aggregate relationship */
  docsAndForms_aggregate: MatterServiceMatterDocsAndForms_Aggregate;
  /** A computed field, executes function "matter_has_no_minor_children" */
  doesNotIncludeMinorChildren: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  domesticPartnership: Maybe<MatterServiceDomesticPartnerships>;
  /** An array relationship */
  entities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  entities_aggregate: MatterServiceMatterEntities_Aggregate;
  /** A computed field, executes function "filing_attorney" */
  filingAttorney: Maybe<Array<MatterServiceAttorneys>>;
  filingFor: Maybe<MatterServiceMatterFilingForTypes_Enum>;
  /** A computed field, executes function "matter_filing_for_name" */
  filingForName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "matter_filing_for" */
  filingForPerson: Maybe<Array<MatterServicePeople>>;
  /** An array relationship */
  folders: Array<MatterServiceMatterDocsAndFormsFolders>;
  /** An aggregate relationship */
  folders_aggregate: MatterServiceMatterDocsAndFormsFolders_Aggregate;
  id: Scalars['MatterServiceUuid']['output'];
  /** A computed field, executes function "matter_has_minor_children" */
  includesMinorChildren: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  marriage: Maybe<MatterServiceMarriages>;
  matterId: Maybe<Scalars['String']['output']>;
  matterType: MatterServiceMatterTypes_Enum;
  /** A computed field, executes function "minor_children" */
  minorChildren: Maybe<Array<MatterServicePeople>>;
  name: Scalars['String']['output'];
  /** A computed field, executes function "opposing_counsel" */
  opposingCounsel: Maybe<Array<MatterServiceAttorneys>>;
  otherParentClaimant: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "plaintiff_or_petitioner" */
  plaintiffOrPetitioner: Maybe<Array<MatterServicePeople>>;
  /** A computed field, executes function "matter_short_name" */
  shortName: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  temporaryRestrainingOrder: Maybe<MatterServiceTemporaryRestrainingOrders>;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** columns and relationships of "matters" */
export type MatterServiceMattersAttorneyForForDefendantOrRespondentArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersAttorneyForPlaintiffOrPetitionerArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersCourtArgs = {
  distinct_on: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceCourts_Order_By>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersDefendantOrRespondentArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersDocsAndFormsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersDocsAndForms_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersFilingAttorneyArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersFilingForPersonArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersFoldersArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersFolders_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersMinorChildrenArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersOpposingCounselArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

/** columns and relationships of "matters" */
export type MatterServiceMattersPlaintiffOrPetitionerArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

/** aggregated selection of "matters" */
export type MatterServiceMatters_Aggregate = {
  __typename?: 'MatterServiceMatters_aggregate';
  aggregate: Maybe<MatterServiceMatters_Aggregate_Fields>;
  nodes: Array<MatterServiceMatters>;
};

export type MatterServiceMatters_Aggregate_Bool_Exp = {
  count: InputMaybe<MatterServiceMatters_Aggregate_Bool_Exp_Count>;
};

export type MatterServiceMatters_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MatterServiceMatters_Bool_Exp>;
  predicate: MatterServiceInt_Comparison_Exp;
};

/** aggregate fields of "matters" */
export type MatterServiceMatters_Aggregate_Fields = {
  __typename?: 'MatterServiceMatters_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceMatters_Max_Fields>;
  min: Maybe<MatterServiceMatters_Min_Fields>;
};

/** aggregate fields of "matters" */
export type MatterServiceMatters_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matters" */
export type MatterServiceMatters_Aggregate_Order_By = {
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServiceMatters_Max_Order_By>;
  min: InputMaybe<MatterServiceMatters_Min_Order_By>;
};

/** input type for inserting array relation for remote table "matters" */
export type MatterServiceMatters_Arr_Rel_Insert_Input = {
  data: Array<MatterServiceMatters_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatters_On_Conflict>;
};

/** Boolean expression to filter rows from the table "matters". All fields are combined with a logical 'AND'. */
export type MatterServiceMatters_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceMatters_Bool_Exp>>;
  _not: InputMaybe<MatterServiceMatters_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceMatters_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  attorneyForForDefendantOrRespondent: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  attorneyForPlaintiffOrPetitioner: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  caseNumber: InputMaybe<MatterServiceString_Comparison_Exp>;
  client: InputMaybe<MatterServiceClients_Bool_Exp>;
  clientId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  court: InputMaybe<MatterServiceCourts_Bool_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  defendantOrRespondent: InputMaybe<MatterServicePeople_Bool_Exp>;
  description: InputMaybe<MatterServiceString_Comparison_Exp>;
  docsAndForms: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
  docsAndForms_aggregate: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Bool_Exp>;
  doesNotIncludeMinorChildren: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  domesticPartnership: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
  entities: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  entities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp>;
  filingAttorney: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  filingFor: InputMaybe<MatterServiceMatterFilingForTypes_Enum_Comparison_Exp>;
  filingForName: InputMaybe<MatterServiceString_Comparison_Exp>;
  filingForPerson: InputMaybe<MatterServicePeople_Bool_Exp>;
  folders: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
  folders_aggregate: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Aggregate_Bool_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  includesMinorChildren: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  marriage: InputMaybe<MatterServiceMarriages_Bool_Exp>;
  matterId: InputMaybe<MatterServiceString_Comparison_Exp>;
  matterType: InputMaybe<MatterServiceMatterTypes_Enum_Comparison_Exp>;
  minorChildren: InputMaybe<MatterServicePeople_Bool_Exp>;
  name: InputMaybe<MatterServiceString_Comparison_Exp>;
  opposingCounsel: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
  otherParentClaimant: InputMaybe<MatterServiceString_Comparison_Exp>;
  plaintiffOrPetitioner: InputMaybe<MatterServicePeople_Bool_Exp>;
  shortName: InputMaybe<MatterServiceString_Comparison_Exp>;
  temporaryRestrainingOrder: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matters" */
export enum MatterServiceMatters_Constraint {
  /** unique or primary key constraint on columns "id" */
  MattersPkey = 'matters_pkey',
}

/** input type for inserting data into table "matters" */
export type MatterServiceMatters_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  caseNumber: InputMaybe<Scalars['String']['input']>;
  client: InputMaybe<MatterServiceClients_Obj_Rel_Insert_Input>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  docsAndForms: InputMaybe<MatterServiceMatterDocsAndForms_Arr_Rel_Insert_Input>;
  domesticPartnership: InputMaybe<MatterServiceDomesticPartnerships_Obj_Rel_Insert_Input>;
  entities: InputMaybe<MatterServiceMatterEntities_Arr_Rel_Insert_Input>;
  filingFor: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
  folders: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Arr_Rel_Insert_Input>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  marriage: InputMaybe<MatterServiceMarriages_Obj_Rel_Insert_Input>;
  matterId: InputMaybe<Scalars['String']['input']>;
  matterType: InputMaybe<MatterServiceMatterTypes_Enum>;
  name: InputMaybe<Scalars['String']['input']>;
  otherParentClaimant: InputMaybe<Scalars['String']['input']>;
  temporaryRestrainingOrder: InputMaybe<MatterServiceTemporaryRestrainingOrders_Obj_Rel_Insert_Input>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceMatters_Max_Fields = {
  __typename?: 'MatterServiceMatters_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  caseNumber: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "matter_filing_for_name" */
  filingForName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  otherParentClaimant: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "matter_short_name" */
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by max() on columns of table "matters" */
export type MatterServiceMatters_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  caseNumber: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  description: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  otherParentClaimant: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServiceMatters_Min_Fields = {
  __typename?: 'MatterServiceMatters_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  caseNumber: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "matter_filing_for_name" */
  filingForName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  otherParentClaimant: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "matter_short_name" */
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** order by min() on columns of table "matters" */
export type MatterServiceMatters_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  caseNumber: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  description: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  otherParentClaimant: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "matters" */
export type MatterServiceMatters_Mutation_Response = {
  __typename?: 'MatterServiceMatters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceMatters>;
};

/** input type for inserting object relation for remote table "matters" */
export type MatterServiceMatters_Obj_Rel_Insert_Input = {
  data: MatterServiceMatters_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceMatters_On_Conflict>;
};

/** on_conflict condition type for table "matters" */
export type MatterServiceMatters_On_Conflict = {
  constraint: MatterServiceMatters_Constraint;
  update_columns: Array<MatterServiceMatters_Update_Column>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

/** Ordering options when selecting data from "matters". */
export type MatterServiceMatters_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  attorneyForForDefendantOrRespondent_aggregate: InputMaybe<MatterServiceAttorneys_Aggregate_Order_By>;
  attorneyForPlaintiffOrPetitioner_aggregate: InputMaybe<MatterServiceAttorneys_Aggregate_Order_By>;
  caseNumber: InputMaybe<MatterServiceOrder_By>;
  client: InputMaybe<MatterServiceClients_Order_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  court_aggregate: InputMaybe<MatterServiceCourts_Aggregate_Order_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  defendantOrRespondent_aggregate: InputMaybe<MatterServicePeople_Aggregate_Order_By>;
  description: InputMaybe<MatterServiceOrder_By>;
  docsAndForms_aggregate: InputMaybe<MatterServiceMatterDocsAndForms_Aggregate_Order_By>;
  doesNotIncludeMinorChildren: InputMaybe<MatterServiceOrder_By>;
  domesticPartnership: InputMaybe<MatterServiceDomesticPartnerships_Order_By>;
  entities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Order_By>;
  filingAttorney_aggregate: InputMaybe<MatterServiceAttorneys_Aggregate_Order_By>;
  filingFor: InputMaybe<MatterServiceOrder_By>;
  filingForName: InputMaybe<MatterServiceOrder_By>;
  filingForPerson_aggregate: InputMaybe<MatterServicePeople_Aggregate_Order_By>;
  folders_aggregate: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Aggregate_Order_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  includesMinorChildren: InputMaybe<MatterServiceOrder_By>;
  marriage: InputMaybe<MatterServiceMarriages_Order_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  matterType: InputMaybe<MatterServiceOrder_By>;
  minorChildren_aggregate: InputMaybe<MatterServicePeople_Aggregate_Order_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  opposingCounsel_aggregate: InputMaybe<MatterServiceAttorneys_Aggregate_Order_By>;
  otherParentClaimant: InputMaybe<MatterServiceOrder_By>;
  plaintiffOrPetitioner_aggregate: InputMaybe<MatterServicePeople_Aggregate_Order_By>;
  shortName: InputMaybe<MatterServiceOrder_By>;
  temporaryRestrainingOrder: InputMaybe<MatterServiceTemporaryRestrainingOrders_Order_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: matters */
export type MatterServiceMatters_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "matters" */
export enum MatterServiceMatters_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CaseNumber = 'caseNumber',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FilingFor = 'filingFor',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  MatterType = 'matterType',
  /** column name */
  Name = 'name',
  /** column name */
  OtherParentClaimant = 'otherParentClaimant',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "matters" */
export type MatterServiceMatters_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  caseNumber: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  filingFor: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['String']['input']>;
  matterType: InputMaybe<MatterServiceMatterTypes_Enum>;
  name: InputMaybe<Scalars['String']['input']>;
  otherParentClaimant: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "matters" */
export type MatterServiceMatters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceMatters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceMatters_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  caseNumber: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  filingFor: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['String']['input']>;
  matterType: InputMaybe<MatterServiceMatterTypes_Enum>;
  name: InputMaybe<Scalars['String']['input']>;
  otherParentClaimant: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "matters" */
export enum MatterServiceMatters_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CaseNumber = 'caseNumber',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FilingFor = 'filingFor',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  MatterType = 'matterType',
  /** column name */
  Name = 'name',
  /** column name */
  OtherParentClaimant = 'otherParentClaimant',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceMatters_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceMatters_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceMatters_Bool_Exp;
};

/** column ordering options */
export enum MatterServiceOrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "organizations" */
export type MatterServiceOrganizations = {
  __typename?: 'MatterServiceOrganizations';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  clientId: Scalars['MatterServiceUuid']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  id: Scalars['MatterServiceUuid']['output'];
  /** An array relationship */
  matterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  name: Scalars['String']['output'];
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** columns and relationships of "organizations" */
export type MatterServiceOrganizationsMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "organizations" */
export type MatterServiceOrganizationsMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type MatterServiceOrganizations_Aggregate = {
  __typename?: 'MatterServiceOrganizations_aggregate';
  aggregate: Maybe<MatterServiceOrganizations_Aggregate_Fields>;
  nodes: Array<MatterServiceOrganizations>;
};

/** aggregate fields of "organizations" */
export type MatterServiceOrganizations_Aggregate_Fields = {
  __typename?: 'MatterServiceOrganizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceOrganizations_Max_Fields>;
  min: Maybe<MatterServiceOrganizations_Min_Fields>;
};

/** aggregate fields of "organizations" */
export type MatterServiceOrganizations_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServiceOrganizations_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type MatterServiceOrganizations_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceOrganizations_Bool_Exp>>;
  _not: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceOrganizations_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  clientId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp>;
  name: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum MatterServiceOrganizations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey',
}

/** input type for inserting data into table "organizations" */
export type MatterServiceOrganizations_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Arr_Rel_Insert_Input>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceOrganizations_Max_Fields = {
  __typename?: 'MatterServiceOrganizations_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** aggregate min on columns */
export type MatterServiceOrganizations_Min_Fields = {
  __typename?: 'MatterServiceOrganizations_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** response of any mutation on the table "organizations" */
export type MatterServiceOrganizations_Mutation_Response = {
  __typename?: 'MatterServiceOrganizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceOrganizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type MatterServiceOrganizations_Obj_Rel_Insert_Input = {
  data: MatterServiceOrganizations_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceOrganizations_On_Conflict>;
};

/** on_conflict condition type for table "organizations" */
export type MatterServiceOrganizations_On_Conflict = {
  constraint: MatterServiceOrganizations_Constraint;
  update_columns: Array<MatterServiceOrganizations_Update_Column>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type MatterServiceOrganizations_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Order_By>;
  name: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: organizations */
export type MatterServiceOrganizations_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "organizations" */
export enum MatterServiceOrganizations_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "organizations" */
export type MatterServiceOrganizations_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "organizations" */
export type MatterServiceOrganizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceOrganizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceOrganizations_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** update columns of table "organizations" */
export enum MatterServiceOrganizations_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceOrganizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceOrganizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceOrganizations_Bool_Exp;
};

/** columns and relationships of "people" */
export type MatterServicePeople = {
  __typename?: 'MatterServicePeople';
  accountCreatorId: Scalars['String']['output'];
  accountOwnerId: Scalars['String']['output'];
  /** An array relationship */
  addresses: Array<MatterServiceEntityAddresses>;
  /** An aggregate relationship */
  addresses_aggregate: MatterServiceEntityAddresses_Aggregate;
  /** A computed field, executes function "person_age" */
  age: Maybe<Scalars['String']['output']>;
  clientId: Scalars['MatterServiceUuid']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  dateOfBirth: Maybe<Scalars['MatterServiceDate']['output']>;
  driversLicense: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  emails: Array<MatterServiceEntityEmails>;
  /** An aggregate relationship */
  emails_aggregate: MatterServiceEntityEmails_Aggregate;
  employerName: Maybe<Scalars['String']['output']>;
  estimatedAge: Maybe<Scalars['Int']['output']>;
  eyeColor: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  /** A computed field, executes function "formatted_date_of_birth" */
  formattedDateOfBirth: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_formatted_gender" */
  formattedGender: Maybe<Scalars['String']['output']>;
  formerName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  gender: Maybe<MatterServicePeopleGenders_Enum>;
  hairColor: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['String']['output']>;
  id: Scalars['MatterServiceUuid']['output'];
  /** A computed field, executes function "person_is_female" */
  isFemale: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "person_is_male" */
  isMale: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "person_is_nonbinary" */
  isNonBinary: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  /** An array relationship */
  matterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  middleName: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  occupation: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  phoneNumbers: Array<MatterServiceEntityPhoneNumbers>;
  /** An aggregate relationship */
  phoneNumbers_aggregate: MatterServiceEntityPhoneNumbers_Aggregate;
  preferredProunouns: Maybe<Scalars['String']['output']>;
  race: Maybe<Scalars['String']['output']>;
  socialSecurityNumber: Maybe<Scalars['String']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
  weight: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleAddressesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleAddresses_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleEmailsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleEmails_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeopleMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeoplePhoneNumbersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

/** columns and relationships of "people" */
export type MatterServicePeoplePhoneNumbers_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

/** columns and relationships of "peopleGenders" */
export type MatterServicePeopleGenders = {
  __typename?: 'MatterServicePeopleGenders';
  value: Scalars['String']['output'];
};

/** aggregated selection of "peopleGenders" */
export type MatterServicePeopleGenders_Aggregate = {
  __typename?: 'MatterServicePeopleGenders_aggregate';
  aggregate: Maybe<MatterServicePeopleGenders_Aggregate_Fields>;
  nodes: Array<MatterServicePeopleGenders>;
};

/** aggregate fields of "peopleGenders" */
export type MatterServicePeopleGenders_Aggregate_Fields = {
  __typename?: 'MatterServicePeopleGenders_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServicePeopleGenders_Max_Fields>;
  min: Maybe<MatterServicePeopleGenders_Min_Fields>;
};

/** aggregate fields of "peopleGenders" */
export type MatterServicePeopleGenders_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServicePeopleGenders_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "peopleGenders". All fields are combined with a logical 'AND'. */
export type MatterServicePeopleGenders_Bool_Exp = {
  _and: InputMaybe<Array<MatterServicePeopleGenders_Bool_Exp>>;
  _not: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
  _or: InputMaybe<Array<MatterServicePeopleGenders_Bool_Exp>>;
  value: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "peopleGenders" */
export enum MatterServicePeopleGenders_Constraint {
  /** unique or primary key constraint on columns "value" */
  PeopleGendersPkey = 'peopleGenders_pkey',
}

export enum MatterServicePeopleGenders_Enum {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY',
}

/** Boolean expression to compare columns of type "peopleGenders_enum". All fields are combined with logical 'AND'. */
export type MatterServicePeopleGenders_Enum_Comparison_Exp = {
  _eq: InputMaybe<MatterServicePeopleGenders_Enum>;
  _in: InputMaybe<Array<MatterServicePeopleGenders_Enum>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<MatterServicePeopleGenders_Enum>;
  _nin: InputMaybe<Array<MatterServicePeopleGenders_Enum>>;
};

/** input type for inserting data into table "peopleGenders" */
export type MatterServicePeopleGenders_Insert_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServicePeopleGenders_Max_Fields = {
  __typename?: 'MatterServicePeopleGenders_max_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MatterServicePeopleGenders_Min_Fields = {
  __typename?: 'MatterServicePeopleGenders_min_fields';
  value: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "peopleGenders" */
export type MatterServicePeopleGenders_Mutation_Response = {
  __typename?: 'MatterServicePeopleGenders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServicePeopleGenders>;
};

/** on_conflict condition type for table "peopleGenders" */
export type MatterServicePeopleGenders_On_Conflict = {
  constraint: MatterServicePeopleGenders_Constraint;
  update_columns: Array<MatterServicePeopleGenders_Update_Column>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

/** Ordering options when selecting data from "peopleGenders". */
export type MatterServicePeopleGenders_Order_By = {
  value: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: peopleGenders */
export type MatterServicePeopleGenders_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "peopleGenders" */
export enum MatterServicePeopleGenders_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "peopleGenders" */
export type MatterServicePeopleGenders_Set_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "peopleGenders" */
export type MatterServicePeopleGenders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServicePeopleGenders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServicePeopleGenders_Stream_Cursor_Value_Input = {
  value: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "peopleGenders" */
export enum MatterServicePeopleGenders_Update_Column {
  /** column name */
  Value = 'value',
}

export type MatterServicePeopleGenders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServicePeopleGenders_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServicePeopleGenders_Bool_Exp;
};

/** aggregated selection of "people" */
export type MatterServicePeople_Aggregate = {
  __typename?: 'MatterServicePeople_aggregate';
  aggregate: Maybe<MatterServicePeople_Aggregate_Fields>;
  nodes: Array<MatterServicePeople>;
};

/** aggregate fields of "people" */
export type MatterServicePeople_Aggregate_Fields = {
  __typename?: 'MatterServicePeople_aggregate_fields';
  avg: Maybe<MatterServicePeople_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<MatterServicePeople_Max_Fields>;
  min: Maybe<MatterServicePeople_Min_Fields>;
  stddev: Maybe<MatterServicePeople_Stddev_Fields>;
  stddev_pop: Maybe<MatterServicePeople_Stddev_Pop_Fields>;
  stddev_samp: Maybe<MatterServicePeople_Stddev_Samp_Fields>;
  sum: Maybe<MatterServicePeople_Sum_Fields>;
  var_pop: Maybe<MatterServicePeople_Var_Pop_Fields>;
  var_samp: Maybe<MatterServicePeople_Var_Samp_Fields>;
  variance: Maybe<MatterServicePeople_Variance_Fields>;
};

/** aggregate fields of "people" */
export type MatterServicePeople_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "people" */
export type MatterServicePeople_Aggregate_Order_By = {
  avg: InputMaybe<MatterServicePeople_Avg_Order_By>;
  count: InputMaybe<MatterServiceOrder_By>;
  max: InputMaybe<MatterServicePeople_Max_Order_By>;
  min: InputMaybe<MatterServicePeople_Min_Order_By>;
  stddev: InputMaybe<MatterServicePeople_Stddev_Order_By>;
  stddev_pop: InputMaybe<MatterServicePeople_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<MatterServicePeople_Stddev_Samp_Order_By>;
  sum: InputMaybe<MatterServicePeople_Sum_Order_By>;
  var_pop: InputMaybe<MatterServicePeople_Var_Pop_Order_By>;
  var_samp: InputMaybe<MatterServicePeople_Var_Samp_Order_By>;
  variance: InputMaybe<MatterServicePeople_Variance_Order_By>;
};

/** aggregate avg on columns */
export type MatterServicePeople_Avg_Fields = {
  __typename?: 'MatterServicePeople_avg_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "people" */
export type MatterServicePeople_Avg_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** Boolean expression to filter rows from the table "people". All fields are combined with a logical 'AND'. */
export type MatterServicePeople_Bool_Exp = {
  _and: InputMaybe<Array<MatterServicePeople_Bool_Exp>>;
  _not: InputMaybe<MatterServicePeople_Bool_Exp>;
  _or: InputMaybe<Array<MatterServicePeople_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  addresses: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
  addresses_aggregate: InputMaybe<MatterServiceEntityAddresses_Aggregate_Bool_Exp>;
  age: InputMaybe<MatterServiceString_Comparison_Exp>;
  clientId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  dateOfBirth: InputMaybe<MatterServiceDate_Comparison_Exp>;
  driversLicense: InputMaybe<MatterServiceString_Comparison_Exp>;
  emails: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
  emails_aggregate: InputMaybe<MatterServiceEntityEmails_Aggregate_Bool_Exp>;
  employerName: InputMaybe<MatterServiceString_Comparison_Exp>;
  estimatedAge: InputMaybe<MatterServiceInt_Comparison_Exp>;
  eyeColor: InputMaybe<MatterServiceString_Comparison_Exp>;
  firstName: InputMaybe<MatterServiceString_Comparison_Exp>;
  formattedDateOfBirth: InputMaybe<MatterServiceString_Comparison_Exp>;
  formattedGender: InputMaybe<MatterServiceString_Comparison_Exp>;
  formerName: InputMaybe<MatterServiceString_Comparison_Exp>;
  fullName: InputMaybe<MatterServiceString_Comparison_Exp>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum_Comparison_Exp>;
  hairColor: InputMaybe<MatterServiceString_Comparison_Exp>;
  height: InputMaybe<MatterServiceString_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  isFemale: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  isMale: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  isNonBinary: InputMaybe<MatterServiceBoolean_Comparison_Exp>;
  lastName: InputMaybe<MatterServiceString_Comparison_Exp>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Bool_Exp>;
  middleName: InputMaybe<MatterServiceString_Comparison_Exp>;
  nickname: InputMaybe<MatterServiceString_Comparison_Exp>;
  occupation: InputMaybe<MatterServiceString_Comparison_Exp>;
  phoneNumbers: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
  phoneNumbers_aggregate: InputMaybe<MatterServiceEntityPhoneNumbers_Aggregate_Bool_Exp>;
  preferredProunouns: InputMaybe<MatterServiceString_Comparison_Exp>;
  race: InputMaybe<MatterServiceString_Comparison_Exp>;
  socialSecurityNumber: InputMaybe<MatterServiceString_Comparison_Exp>;
  suffix: InputMaybe<MatterServiceString_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  weight: InputMaybe<MatterServiceString_Comparison_Exp>;
};

/** unique or primary key constraints on table "people" */
export enum MatterServicePeople_Constraint {
  /** unique or primary key constraint on columns "id" */
  PeoplePkey = 'people_pkey',
}

/** input type for incrementing numeric columns in table "people" */
export type MatterServicePeople_Inc_Input = {
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "people" */
export type MatterServicePeople_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  addresses: InputMaybe<MatterServiceEntityAddresses_Arr_Rel_Insert_Input>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfBirth: InputMaybe<Scalars['MatterServiceDate']['input']>;
  driversLicense: InputMaybe<Scalars['String']['input']>;
  emails: InputMaybe<MatterServiceEntityEmails_Arr_Rel_Insert_Input>;
  employerName: InputMaybe<Scalars['String']['input']>;
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
  eyeColor: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  formerName: InputMaybe<Scalars['String']['input']>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum>;
  hairColor: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  matterEntities: InputMaybe<MatterServiceMatterEntities_Arr_Rel_Insert_Input>;
  middleName: InputMaybe<Scalars['String']['input']>;
  nickname: InputMaybe<Scalars['String']['input']>;
  occupation: InputMaybe<Scalars['String']['input']>;
  phoneNumbers: InputMaybe<MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input>;
  preferredProunouns: InputMaybe<Scalars['String']['input']>;
  race: InputMaybe<Scalars['String']['input']>;
  socialSecurityNumber: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  weight: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type MatterServicePeople_Max_Fields = {
  __typename?: 'MatterServicePeople_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_age" */
  age: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfBirth: Maybe<Scalars['MatterServiceDate']['output']>;
  driversLicense: Maybe<Scalars['String']['output']>;
  employerName: Maybe<Scalars['String']['output']>;
  estimatedAge: Maybe<Scalars['Int']['output']>;
  eyeColor: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_birth" */
  formattedDateOfBirth: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_formatted_gender" */
  formattedGender: Maybe<Scalars['String']['output']>;
  formerName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  hairColor: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  occupation: Maybe<Scalars['String']['output']>;
  preferredProunouns: Maybe<Scalars['String']['output']>;
  race: Maybe<Scalars['String']['output']>;
  socialSecurityNumber: Maybe<Scalars['String']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  weight: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "people" */
export type MatterServicePeople_Max_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  dateOfBirth: InputMaybe<MatterServiceOrder_By>;
  driversLicense: InputMaybe<MatterServiceOrder_By>;
  employerName: InputMaybe<MatterServiceOrder_By>;
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
  eyeColor: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  formerName: InputMaybe<MatterServiceOrder_By>;
  hairColor: InputMaybe<MatterServiceOrder_By>;
  height: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  nickname: InputMaybe<MatterServiceOrder_By>;
  occupation: InputMaybe<MatterServiceOrder_By>;
  preferredProunouns: InputMaybe<MatterServiceOrder_By>;
  race: InputMaybe<MatterServiceOrder_By>;
  socialSecurityNumber: InputMaybe<MatterServiceOrder_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  weight: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate min on columns */
export type MatterServicePeople_Min_Fields = {
  __typename?: 'MatterServicePeople_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_age" */
  age: Maybe<Scalars['String']['output']>;
  clientId: Maybe<Scalars['MatterServiceUuid']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  dateOfBirth: Maybe<Scalars['MatterServiceDate']['output']>;
  driversLicense: Maybe<Scalars['String']['output']>;
  employerName: Maybe<Scalars['String']['output']>;
  estimatedAge: Maybe<Scalars['Int']['output']>;
  eyeColor: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "formatted_date_of_birth" */
  formattedDateOfBirth: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_formatted_gender" */
  formattedGender: Maybe<Scalars['String']['output']>;
  formerName: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "person_full_name" */
  fullName: Maybe<Scalars['String']['output']>;
  hairColor: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  occupation: Maybe<Scalars['String']['output']>;
  preferredProunouns: Maybe<Scalars['String']['output']>;
  race: Maybe<Scalars['String']['output']>;
  socialSecurityNumber: Maybe<Scalars['String']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  weight: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "people" */
export type MatterServicePeople_Min_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  dateOfBirth: InputMaybe<MatterServiceOrder_By>;
  driversLicense: InputMaybe<MatterServiceOrder_By>;
  employerName: InputMaybe<MatterServiceOrder_By>;
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
  eyeColor: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  formerName: InputMaybe<MatterServiceOrder_By>;
  hairColor: InputMaybe<MatterServiceOrder_By>;
  height: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  nickname: InputMaybe<MatterServiceOrder_By>;
  occupation: InputMaybe<MatterServiceOrder_By>;
  preferredProunouns: InputMaybe<MatterServiceOrder_By>;
  race: InputMaybe<MatterServiceOrder_By>;
  socialSecurityNumber: InputMaybe<MatterServiceOrder_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  weight: InputMaybe<MatterServiceOrder_By>;
};

/** response of any mutation on the table "people" */
export type MatterServicePeople_Mutation_Response = {
  __typename?: 'MatterServicePeople_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServicePeople>;
};

/** input type for inserting object relation for remote table "people" */
export type MatterServicePeople_Obj_Rel_Insert_Input = {
  data: MatterServicePeople_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServicePeople_On_Conflict>;
};

/** on_conflict condition type for table "people" */
export type MatterServicePeople_On_Conflict = {
  constraint: MatterServicePeople_Constraint;
  update_columns: Array<MatterServicePeople_Update_Column>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

/** Ordering options when selecting data from "people". */
export type MatterServicePeople_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  addresses_aggregate: InputMaybe<MatterServiceEntityAddresses_Aggregate_Order_By>;
  age: InputMaybe<MatterServiceOrder_By>;
  clientId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  dateOfBirth: InputMaybe<MatterServiceOrder_By>;
  driversLicense: InputMaybe<MatterServiceOrder_By>;
  emails_aggregate: InputMaybe<MatterServiceEntityEmails_Aggregate_Order_By>;
  employerName: InputMaybe<MatterServiceOrder_By>;
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
  eyeColor: InputMaybe<MatterServiceOrder_By>;
  firstName: InputMaybe<MatterServiceOrder_By>;
  formattedDateOfBirth: InputMaybe<MatterServiceOrder_By>;
  formattedGender: InputMaybe<MatterServiceOrder_By>;
  formerName: InputMaybe<MatterServiceOrder_By>;
  fullName: InputMaybe<MatterServiceOrder_By>;
  gender: InputMaybe<MatterServiceOrder_By>;
  hairColor: InputMaybe<MatterServiceOrder_By>;
  height: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  isFemale: InputMaybe<MatterServiceOrder_By>;
  isMale: InputMaybe<MatterServiceOrder_By>;
  isNonBinary: InputMaybe<MatterServiceOrder_By>;
  lastName: InputMaybe<MatterServiceOrder_By>;
  matterEntities_aggregate: InputMaybe<MatterServiceMatterEntities_Aggregate_Order_By>;
  middleName: InputMaybe<MatterServiceOrder_By>;
  nickname: InputMaybe<MatterServiceOrder_By>;
  occupation: InputMaybe<MatterServiceOrder_By>;
  phoneNumbers_aggregate: InputMaybe<MatterServiceEntityPhoneNumbers_Aggregate_Order_By>;
  preferredProunouns: InputMaybe<MatterServiceOrder_By>;
  race: InputMaybe<MatterServiceOrder_By>;
  socialSecurityNumber: InputMaybe<MatterServiceOrder_By>;
  suffix: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
  weight: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: people */
export type MatterServicePeople_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "people" */
export enum MatterServicePeople_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DriversLicense = 'driversLicense',
  /** column name */
  EmployerName = 'employerName',
  /** column name */
  EstimatedAge = 'estimatedAge',
  /** column name */
  EyeColor = 'eyeColor',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FormerName = 'formerName',
  /** column name */
  Gender = 'gender',
  /** column name */
  HairColor = 'hairColor',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  PreferredProunouns = 'preferredProunouns',
  /** column name */
  Race = 'race',
  /** column name */
  SocialSecurityNumber = 'socialSecurityNumber',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "people" */
export type MatterServicePeople_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfBirth: InputMaybe<Scalars['MatterServiceDate']['input']>;
  driversLicense: InputMaybe<Scalars['String']['input']>;
  employerName: InputMaybe<Scalars['String']['input']>;
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
  eyeColor: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  formerName: InputMaybe<Scalars['String']['input']>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum>;
  hairColor: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  nickname: InputMaybe<Scalars['String']['input']>;
  occupation: InputMaybe<Scalars['String']['input']>;
  preferredProunouns: InputMaybe<Scalars['String']['input']>;
  race: InputMaybe<Scalars['String']['input']>;
  socialSecurityNumber: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  weight: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type MatterServicePeople_Stddev_Fields = {
  __typename?: 'MatterServicePeople_stddev_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "people" */
export type MatterServicePeople_Stddev_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate stddev_pop on columns */
export type MatterServicePeople_Stddev_Pop_Fields = {
  __typename?: 'MatterServicePeople_stddev_pop_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "people" */
export type MatterServicePeople_Stddev_Pop_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate stddev_samp on columns */
export type MatterServicePeople_Stddev_Samp_Fields = {
  __typename?: 'MatterServicePeople_stddev_samp_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "people" */
export type MatterServicePeople_Stddev_Samp_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** Streaming cursor of the table "people" */
export type MatterServicePeople_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServicePeople_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServicePeople_Stream_Cursor_Value_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  clientId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  dateOfBirth: InputMaybe<Scalars['MatterServiceDate']['input']>;
  driversLicense: InputMaybe<Scalars['String']['input']>;
  employerName: InputMaybe<Scalars['String']['input']>;
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
  eyeColor: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  formerName: InputMaybe<Scalars['String']['input']>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum>;
  hairColor: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  nickname: InputMaybe<Scalars['String']['input']>;
  occupation: InputMaybe<Scalars['String']['input']>;
  preferredProunouns: InputMaybe<Scalars['String']['input']>;
  race: InputMaybe<Scalars['String']['input']>;
  socialSecurityNumber: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  weight: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type MatterServicePeople_Sum_Fields = {
  __typename?: 'MatterServicePeople_sum_fields';
  estimatedAge: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "people" */
export type MatterServicePeople_Sum_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** update columns of table "people" */
export enum MatterServicePeople_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DriversLicense = 'driversLicense',
  /** column name */
  EmployerName = 'employerName',
  /** column name */
  EstimatedAge = 'estimatedAge',
  /** column name */
  EyeColor = 'eyeColor',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FormerName = 'formerName',
  /** column name */
  Gender = 'gender',
  /** column name */
  HairColor = 'hairColor',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  PreferredProunouns = 'preferredProunouns',
  /** column name */
  Race = 'race',
  /** column name */
  SocialSecurityNumber = 'socialSecurityNumber',
  /** column name */
  Suffix = 'suffix',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weight = 'weight',
}

export type MatterServicePeople_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<MatterServicePeople_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServicePeople_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServicePeople_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MatterServicePeople_Var_Pop_Fields = {
  __typename?: 'MatterServicePeople_var_pop_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "people" */
export type MatterServicePeople_Var_Pop_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate var_samp on columns */
export type MatterServicePeople_Var_Samp_Fields = {
  __typename?: 'MatterServicePeople_var_samp_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "people" */
export type MatterServicePeople_Var_Samp_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

/** aggregate variance on columns */
export type MatterServicePeople_Variance_Fields = {
  __typename?: 'MatterServicePeople_variance_fields';
  estimatedAge: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "people" */
export type MatterServicePeople_Variance_Order_By = {
  estimatedAge: InputMaybe<MatterServiceOrder_By>;
};

export type MatterServicePositionItemInFolder_Args = {
  folderId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  itemId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  newPosition: InputMaybe<Scalars['Int']['input']>;
};

export type MatterServiceRemoveItemsFromFolder_Args = {
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

export type MatterServiceRemoveItemsFromMatter_Args = {
  itemIds: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type MatterServiceString_Comparison_Exp = {
  _eq: InputMaybe<Scalars['String']['input']>;
  _gt: InputMaybe<Scalars['String']['input']>;
  _gte: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: InputMaybe<Scalars['String']['input']>;
  _in: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: InputMaybe<Scalars['String']['input']>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like: InputMaybe<Scalars['String']['input']>;
  _lt: InputMaybe<Scalars['String']['input']>;
  _lte: InputMaybe<Scalars['String']['input']>;
  _neq: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: InputMaybe<Scalars['String']['input']>;
  _nin: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders';
  accountCreatorId: Scalars['String']['output'];
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Scalars['String']['output'];
  createdAt: Scalars['MatterServiceTimestamptz']['output'];
  id: Scalars['MatterServiceUuid']['output'];
  matterId: Scalars['MatterServiceUuid']['output'];
  /** An object relationship */
  protectedPerson: Maybe<MatterServicePeople>;
  protectedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  relationshipToProtectedPerson: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  restrainedPerson: Maybe<MatterServicePeople>;
  restrainedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Scalars['MatterServiceTimestamptz']['output'];
};

/** aggregated selection of "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Aggregate = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders_aggregate';
  aggregate: Maybe<MatterServiceTemporaryRestrainingOrders_Aggregate_Fields>;
  nodes: Array<MatterServiceTemporaryRestrainingOrders>;
};

/** aggregate fields of "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Aggregate_Fields = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<MatterServiceTemporaryRestrainingOrders_Max_Fields>;
  min: Maybe<MatterServiceTemporaryRestrainingOrders_Min_Fields>;
};

/** aggregate fields of "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Aggregate_FieldsCountArgs =
  {
    columns: InputMaybe<
      Array<MatterServiceTemporaryRestrainingOrders_Select_Column>
    >;
    distinct: InputMaybe<Scalars['Boolean']['input']>;
  };

/** Boolean expression to filter rows from the table "temporaryRestrainingOrders". All fields are combined with a logical 'AND'. */
export type MatterServiceTemporaryRestrainingOrders_Bool_Exp = {
  _and: InputMaybe<Array<MatterServiceTemporaryRestrainingOrders_Bool_Exp>>;
  _not: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
  _or: InputMaybe<Array<MatterServiceTemporaryRestrainingOrders_Bool_Exp>>;
  accountCreatorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountLastEditorId: InputMaybe<MatterServiceString_Comparison_Exp>;
  accountOwnerId: InputMaybe<MatterServiceString_Comparison_Exp>;
  createdAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
  id: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  matterId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  protectedPerson: InputMaybe<MatterServicePeople_Bool_Exp>;
  protectedPersonId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  relationshipToProtectedPerson: InputMaybe<MatterServiceString_Comparison_Exp>;
  restrainedPerson: InputMaybe<MatterServicePeople_Bool_Exp>;
  restrainedPersonId: InputMaybe<MatterServiceUuid_Comparison_Exp>;
  updatedAt: InputMaybe<MatterServiceTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "temporaryRestrainingOrders" */
export enum MatterServiceTemporaryRestrainingOrders_Constraint {
  /** unique or primary key constraint on columns "matterId" */
  TemporaryRestrainingOrdersMatterIdKey = 'temporaryRestrainingOrders_matterId_key',
  /** unique or primary key constraint on columns "id" */
  TemporaryRestrainingOrdersPkey = 'temporaryRestrainingOrders_pkey',
}

/** input type for inserting data into table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Insert_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  protectedPerson: InputMaybe<MatterServicePeople_Obj_Rel_Insert_Input>;
  protectedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  relationshipToProtectedPerson: InputMaybe<Scalars['String']['input']>;
  restrainedPerson: InputMaybe<MatterServicePeople_Obj_Rel_Insert_Input>;
  restrainedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** aggregate max on columns */
export type MatterServiceTemporaryRestrainingOrders_Max_Fields = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders_max_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  protectedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  relationshipToProtectedPerson: Maybe<Scalars['String']['output']>;
  restrainedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** aggregate min on columns */
export type MatterServiceTemporaryRestrainingOrders_Min_Fields = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders_min_fields';
  accountCreatorId: Maybe<Scalars['String']['output']>;
  accountLastEditorId: Maybe<Scalars['String']['output']>;
  accountOwnerId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
  id: Maybe<Scalars['MatterServiceUuid']['output']>;
  matterId: Maybe<Scalars['MatterServiceUuid']['output']>;
  protectedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  relationshipToProtectedPerson: Maybe<Scalars['String']['output']>;
  restrainedPersonId: Maybe<Scalars['MatterServiceUuid']['output']>;
  updatedAt: Maybe<Scalars['MatterServiceTimestamptz']['output']>;
};

/** response of any mutation on the table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Mutation_Response = {
  __typename?: 'MatterServiceTemporaryRestrainingOrders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MatterServiceTemporaryRestrainingOrders>;
};

/** input type for inserting object relation for remote table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Obj_Rel_Insert_Input = {
  data: MatterServiceTemporaryRestrainingOrders_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<MatterServiceTemporaryRestrainingOrders_On_Conflict>;
};

/** on_conflict condition type for table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_On_Conflict = {
  constraint: MatterServiceTemporaryRestrainingOrders_Constraint;
  update_columns: Array<MatterServiceTemporaryRestrainingOrders_Update_Column>;
  where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
};

/** Ordering options when selecting data from "temporaryRestrainingOrders". */
export type MatterServiceTemporaryRestrainingOrders_Order_By = {
  accountCreatorId: InputMaybe<MatterServiceOrder_By>;
  accountLastEditorId: InputMaybe<MatterServiceOrder_By>;
  accountOwnerId: InputMaybe<MatterServiceOrder_By>;
  createdAt: InputMaybe<MatterServiceOrder_By>;
  id: InputMaybe<MatterServiceOrder_By>;
  matterId: InputMaybe<MatterServiceOrder_By>;
  protectedPerson: InputMaybe<MatterServicePeople_Order_By>;
  protectedPersonId: InputMaybe<MatterServiceOrder_By>;
  relationshipToProtectedPerson: InputMaybe<MatterServiceOrder_By>;
  restrainedPerson: InputMaybe<MatterServicePeople_Order_By>;
  restrainedPersonId: InputMaybe<MatterServiceOrder_By>;
  updatedAt: InputMaybe<MatterServiceOrder_By>;
};

/** primary key columns input for table: temporaryRestrainingOrders */
export type MatterServiceTemporaryRestrainingOrders_Pk_Columns_Input = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** select columns of table "temporaryRestrainingOrders" */
export enum MatterServiceTemporaryRestrainingOrders_Select_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  ProtectedPersonId = 'protectedPersonId',
  /** column name */
  RelationshipToProtectedPerson = 'relationshipToProtectedPerson',
  /** column name */
  RestrainedPersonId = 'restrainedPersonId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Set_Input = {
  accountCreatorId: InputMaybe<Scalars['String']['input']>;
  accountLastEditorId: InputMaybe<Scalars['String']['input']>;
  accountOwnerId: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  protectedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  relationshipToProtectedPerson: InputMaybe<Scalars['String']['input']>;
  restrainedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
};

/** Streaming cursor of the table "temporaryRestrainingOrders" */
export type MatterServiceTemporaryRestrainingOrders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MatterServiceTemporaryRestrainingOrders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<MatterServiceCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MatterServiceTemporaryRestrainingOrders_Stream_Cursor_Value_Input =
  {
    accountCreatorId: InputMaybe<Scalars['String']['input']>;
    accountLastEditorId: InputMaybe<Scalars['String']['input']>;
    accountOwnerId: InputMaybe<Scalars['String']['input']>;
    createdAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
    id: InputMaybe<Scalars['MatterServiceUuid']['input']>;
    matterId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
    protectedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
    relationshipToProtectedPerson: InputMaybe<Scalars['String']['input']>;
    restrainedPersonId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
    updatedAt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  };

/** update columns of table "temporaryRestrainingOrders" */
export enum MatterServiceTemporaryRestrainingOrders_Update_Column {
  /** column name */
  AccountCreatorId = 'accountCreatorId',
  /** column name */
  AccountLastEditorId = 'accountLastEditorId',
  /** column name */
  AccountOwnerId = 'accountOwnerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MatterId = 'matterId',
  /** column name */
  ProtectedPersonId = 'protectedPersonId',
  /** column name */
  RelationshipToProtectedPerson = 'relationshipToProtectedPerson',
  /** column name */
  RestrainedPersonId = 'restrainedPersonId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MatterServiceTemporaryRestrainingOrders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<MatterServiceTemporaryRestrainingOrders_Set_Input>;
  /** filter the rows which have to be updated */
  where: MatterServiceTemporaryRestrainingOrders_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type MatterServiceTimestamptz_Comparison_Exp = {
  _eq: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _gt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _gte: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _in: InputMaybe<Array<Scalars['MatterServiceTimestamptz']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _lte: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _neq: InputMaybe<Scalars['MatterServiceTimestamptz']['input']>;
  _nin: InputMaybe<Array<Scalars['MatterServiceTimestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type MatterServiceUuid_Comparison_Exp = {
  _eq: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _gt: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _gte: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _in: InputMaybe<Array<Scalars['MatterServiceUuid']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _lte: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _neq: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  _nin: InputMaybe<Array<Scalars['MatterServiceUuid']['input']>>;
};

/** mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  acceptFirmInvite: Maybe<Scalars['Boolean']['output']>;
  addContentFavorite: Maybe<ContentFavorite>;
  addCourseToWatchlist: Maybe<WatchlistCourse>;
  addFirmAdministrator: Maybe<Scalars['Boolean']['output']>;
  completePasswordReset: Maybe<Scalars['Boolean']['output']>;
  createAcademicSubscription: Maybe<Subscription>;
  createBookmark: Maybe<Bookmark>;
  createBookmarkFolder: Maybe<BookmarkFolder>;
  createFirmSubscription: Maybe<Subscription>;
  createHistoryItem: Maybe<HistoryItem>;
  createUserSubscription: Maybe<Subscription>;
  deleteBookmark: Maybe<Scalars['Boolean']['output']>;
  deleteBookmarkFolder: Maybe<Scalars['Boolean']['output']>;
  deleteBookmarks: Maybe<Scalars['Boolean']['output']>;
  deleteContentFavorite: Maybe<Scalars['Boolean']['output']>;
  deleteCourseFromWatchlist: Maybe<Scalars['Boolean']['output']>;
  deleteFirmInvite: Maybe<Scalars['Boolean']['output']>;
  initiatePasswordReset: Maybe<Scalars['Boolean']['output']>;
  ipAccess: Maybe<Login>;
  login: Maybe<Login>;
  logout: Maybe<Scalars['Boolean']['output']>;
  /** execute VOLATILE function "addDocsAndFormsToFolder" which returns "matterDocsAndForms" */
  matterServiceAddDocsAndFormsToFolder: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "addDocsAndFormsToMatter" which returns "matterDocsAndForms" */
  matterServiceAddDocsAndFormsToMatter: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "addItemsToFolder" which returns "matterDocsAndForms" */
  matterServiceAddItemsToFolder: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "createFolderAddDocsAndForms" which returns "matterDocsAndForms" */
  matterServiceCreateFolderAddDocsAndForms: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "createFolderAddItems" which returns "matterDocsAndForms" */
  matterServiceCreateFolderAddItems: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "createFolderDuplicateItems" which returns "matterDocsAndForms" */
  matterServiceCreateFolderDuplicateItems: Array<MatterServiceMatterDocsAndForms>;
  /** delete data from the table: "attorneys" */
  matterServiceDelete_attorneys: Maybe<MatterServiceAttorneys_Mutation_Response>;
  /** delete single row from the table: "attorneys" */
  matterServiceDelete_attorneys_by_pk: Maybe<MatterServiceAttorneys>;
  /** delete data from the table: "clients" */
  matterServiceDelete_clients: Maybe<MatterServiceClients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  matterServiceDelete_clients_by_pk: Maybe<MatterServiceClients>;
  /** delete data from the table: "courts" */
  matterServiceDelete_courts: Maybe<MatterServiceCourts_Mutation_Response>;
  /** delete single row from the table: "courts" */
  matterServiceDelete_courts_by_pk: Maybe<MatterServiceCourts>;
  /** delete data from the table: "domesticPartnerships" */
  matterServiceDelete_domesticPartnerships: Maybe<MatterServiceDomesticPartnerships_Mutation_Response>;
  /** delete single row from the table: "domesticPartnerships" */
  matterServiceDelete_domesticPartnerships_by_pk: Maybe<MatterServiceDomesticPartnerships>;
  /** delete data from the table: "entityAddressTypes" */
  matterServiceDelete_entityAddressTypes: Maybe<MatterServiceEntityAddressTypes_Mutation_Response>;
  /** delete single row from the table: "entityAddressTypes" */
  matterServiceDelete_entityAddressTypes_by_pk: Maybe<MatterServiceEntityAddressTypes>;
  /** delete data from the table: "entityAddresses" */
  matterServiceDelete_entityAddresses: Maybe<MatterServiceEntityAddresses_Mutation_Response>;
  /** delete single row from the table: "entityAddresses" */
  matterServiceDelete_entityAddresses_by_pk: Maybe<MatterServiceEntityAddresses>;
  /** delete data from the table: "entityEmailTypes" */
  matterServiceDelete_entityEmailTypes: Maybe<MatterServiceEntityEmailTypes_Mutation_Response>;
  /** delete single row from the table: "entityEmailTypes" */
  matterServiceDelete_entityEmailTypes_by_pk: Maybe<MatterServiceEntityEmailTypes>;
  /** delete data from the table: "entityEmails" */
  matterServiceDelete_entityEmails: Maybe<MatterServiceEntityEmails_Mutation_Response>;
  /** delete single row from the table: "entityEmails" */
  matterServiceDelete_entityEmails_by_pk: Maybe<MatterServiceEntityEmails>;
  /** delete data from the table: "entityPhoneNumberTypes" */
  matterServiceDelete_entityPhoneNumberTypes: Maybe<MatterServiceEntityPhoneNumberTypes_Mutation_Response>;
  /** delete single row from the table: "entityPhoneNumberTypes" */
  matterServiceDelete_entityPhoneNumberTypes_by_pk: Maybe<MatterServiceEntityPhoneNumberTypes>;
  /** delete data from the table: "entityPhoneNumbers" */
  matterServiceDelete_entityPhoneNumbers: Maybe<MatterServiceEntityPhoneNumbers_Mutation_Response>;
  /** delete single row from the table: "entityPhoneNumbers" */
  matterServiceDelete_entityPhoneNumbers_by_pk: Maybe<MatterServiceEntityPhoneNumbers>;
  /** delete data from the table: "marriages" */
  matterServiceDelete_marriages: Maybe<MatterServiceMarriages_Mutation_Response>;
  /** delete single row from the table: "marriages" */
  matterServiceDelete_marriages_by_pk: Maybe<MatterServiceMarriages>;
  /** delete data from the table: "matterAttorneyRoleTypes" */
  matterServiceDelete_matterAttorneyRoleTypes: Maybe<MatterServiceMatterAttorneyRoleTypes_Mutation_Response>;
  /** delete single row from the table: "matterAttorneyRoleTypes" */
  matterServiceDelete_matterAttorneyRoleTypes_by_pk: Maybe<MatterServiceMatterAttorneyRoleTypes>;
  /** delete data from the table: "matterDocsAndForms" */
  matterServiceDelete_matterDocsAndForms: Maybe<MatterServiceMatterDocsAndForms_Mutation_Response>;
  /** delete data from the table: "matterDocsAndFormsFolders" */
  matterServiceDelete_matterDocsAndFormsFolders: Maybe<MatterServiceMatterDocsAndFormsFolders_Mutation_Response>;
  /** delete single row from the table: "matterDocsAndFormsFolders" */
  matterServiceDelete_matterDocsAndFormsFolders_by_pk: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  /** delete data from the table: "matterDocsAndFormsStatuses" */
  matterServiceDelete_matterDocsAndFormsStatuses: Maybe<MatterServiceMatterDocsAndFormsStatuses_Mutation_Response>;
  /** delete single row from the table: "matterDocsAndFormsStatuses" */
  matterServiceDelete_matterDocsAndFormsStatuses_by_pk: Maybe<MatterServiceMatterDocsAndFormsStatuses>;
  /** delete data from the table: "matterDocsAndFormsTypes" */
  matterServiceDelete_matterDocsAndFormsTypes: Maybe<MatterServiceMatterDocsAndFormsTypes_Mutation_Response>;
  /** delete single row from the table: "matterDocsAndFormsTypes" */
  matterServiceDelete_matterDocsAndFormsTypes_by_pk: Maybe<MatterServiceMatterDocsAndFormsTypes>;
  /** delete single row from the table: "matterDocsAndForms" */
  matterServiceDelete_matterDocsAndForms_by_pk: Maybe<MatterServiceMatterDocsAndForms>;
  /** delete data from the table: "matterEntities" */
  matterServiceDelete_matterEntities: Maybe<MatterServiceMatterEntities_Mutation_Response>;
  /** delete single row from the table: "matterEntities" */
  matterServiceDelete_matterEntities_by_pk: Maybe<MatterServiceMatterEntities>;
  /** delete data from the table: "matterEntityTypes" */
  matterServiceDelete_matterEntityTypes: Maybe<MatterServiceMatterEntityTypes_Mutation_Response>;
  /** delete single row from the table: "matterEntityTypes" */
  matterServiceDelete_matterEntityTypes_by_pk: Maybe<MatterServiceMatterEntityTypes>;
  /** delete data from the table: "matterFilingForTypes" */
  matterServiceDelete_matterFilingForTypes: Maybe<MatterServiceMatterFilingForTypes_Mutation_Response>;
  /** delete single row from the table: "matterFilingForTypes" */
  matterServiceDelete_matterFilingForTypes_by_pk: Maybe<MatterServiceMatterFilingForTypes>;
  /** delete data from the table: "matterOrganizationRoleTypes" */
  matterServiceDelete_matterOrganizationRoleTypes: Maybe<MatterServiceMatterOrganizationRoleTypes_Mutation_Response>;
  /** delete single row from the table: "matterOrganizationRoleTypes" */
  matterServiceDelete_matterOrganizationRoleTypes_by_pk: Maybe<MatterServiceMatterOrganizationRoleTypes>;
  /** delete data from the table: "matterPersonRoleTypes" */
  matterServiceDelete_matterPersonRoleTypes: Maybe<MatterServiceMatterPersonRoleTypes_Mutation_Response>;
  /** delete single row from the table: "matterPersonRoleTypes" */
  matterServiceDelete_matterPersonRoleTypes_by_pk: Maybe<MatterServiceMatterPersonRoleTypes>;
  /** delete data from the table: "matterTypes" */
  matterServiceDelete_matterTypes: Maybe<MatterServiceMatterTypes_Mutation_Response>;
  /** delete single row from the table: "matterTypes" */
  matterServiceDelete_matterTypes_by_pk: Maybe<MatterServiceMatterTypes>;
  /** delete data from the table: "matters" */
  matterServiceDelete_matters: Maybe<MatterServiceMatters_Mutation_Response>;
  /** delete single row from the table: "matters" */
  matterServiceDelete_matters_by_pk: Maybe<MatterServiceMatters>;
  /** delete data from the table: "organizations" */
  matterServiceDelete_organizations: Maybe<MatterServiceOrganizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  matterServiceDelete_organizations_by_pk: Maybe<MatterServiceOrganizations>;
  /** delete data from the table: "people" */
  matterServiceDelete_people: Maybe<MatterServicePeople_Mutation_Response>;
  /** delete data from the table: "peopleGenders" */
  matterServiceDelete_peopleGenders: Maybe<MatterServicePeopleGenders_Mutation_Response>;
  /** delete single row from the table: "peopleGenders" */
  matterServiceDelete_peopleGenders_by_pk: Maybe<MatterServicePeopleGenders>;
  /** delete single row from the table: "people" */
  matterServiceDelete_people_by_pk: Maybe<MatterServicePeople>;
  /** delete data from the table: "temporaryRestrainingOrders" */
  matterServiceDelete_temporaryRestrainingOrders: Maybe<MatterServiceTemporaryRestrainingOrders_Mutation_Response>;
  /** delete single row from the table: "temporaryRestrainingOrders" */
  matterServiceDelete_temporaryRestrainingOrders_by_pk: Maybe<MatterServiceTemporaryRestrainingOrders>;
  /** execute VOLATILE function "duplicateItemsInMatter" which returns "matterDocsAndForms" */
  matterServiceDuplicateItemsInMatter: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "duplicateItemsToFolder" which returns "matterDocsAndForms" */
  matterServiceDuplicateItemsToFolder: Array<MatterServiceMatterDocsAndForms>;
  /** insert data into the table: "attorneys" */
  matterServiceInsert_attorneys: Maybe<MatterServiceAttorneys_Mutation_Response>;
  /** insert a single row into the table: "attorneys" */
  matterServiceInsert_attorneys_one: Maybe<MatterServiceAttorneys>;
  /** insert data into the table: "clients" */
  matterServiceInsert_clients: Maybe<MatterServiceClients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  matterServiceInsert_clients_one: Maybe<MatterServiceClients>;
  /** insert data into the table: "courts" */
  matterServiceInsert_courts: Maybe<MatterServiceCourts_Mutation_Response>;
  /** insert a single row into the table: "courts" */
  matterServiceInsert_courts_one: Maybe<MatterServiceCourts>;
  /** insert data into the table: "domesticPartnerships" */
  matterServiceInsert_domesticPartnerships: Maybe<MatterServiceDomesticPartnerships_Mutation_Response>;
  /** insert a single row into the table: "domesticPartnerships" */
  matterServiceInsert_domesticPartnerships_one: Maybe<MatterServiceDomesticPartnerships>;
  /** insert data into the table: "entityAddressTypes" */
  matterServiceInsert_entityAddressTypes: Maybe<MatterServiceEntityAddressTypes_Mutation_Response>;
  /** insert a single row into the table: "entityAddressTypes" */
  matterServiceInsert_entityAddressTypes_one: Maybe<MatterServiceEntityAddressTypes>;
  /** insert data into the table: "entityAddresses" */
  matterServiceInsert_entityAddresses: Maybe<MatterServiceEntityAddresses_Mutation_Response>;
  /** insert a single row into the table: "entityAddresses" */
  matterServiceInsert_entityAddresses_one: Maybe<MatterServiceEntityAddresses>;
  /** insert data into the table: "entityEmailTypes" */
  matterServiceInsert_entityEmailTypes: Maybe<MatterServiceEntityEmailTypes_Mutation_Response>;
  /** insert a single row into the table: "entityEmailTypes" */
  matterServiceInsert_entityEmailTypes_one: Maybe<MatterServiceEntityEmailTypes>;
  /** insert data into the table: "entityEmails" */
  matterServiceInsert_entityEmails: Maybe<MatterServiceEntityEmails_Mutation_Response>;
  /** insert a single row into the table: "entityEmails" */
  matterServiceInsert_entityEmails_one: Maybe<MatterServiceEntityEmails>;
  /** insert data into the table: "entityPhoneNumberTypes" */
  matterServiceInsert_entityPhoneNumberTypes: Maybe<MatterServiceEntityPhoneNumberTypes_Mutation_Response>;
  /** insert a single row into the table: "entityPhoneNumberTypes" */
  matterServiceInsert_entityPhoneNumberTypes_one: Maybe<MatterServiceEntityPhoneNumberTypes>;
  /** insert data into the table: "entityPhoneNumbers" */
  matterServiceInsert_entityPhoneNumbers: Maybe<MatterServiceEntityPhoneNumbers_Mutation_Response>;
  /** insert a single row into the table: "entityPhoneNumbers" */
  matterServiceInsert_entityPhoneNumbers_one: Maybe<MatterServiceEntityPhoneNumbers>;
  /** insert data into the table: "marriages" */
  matterServiceInsert_marriages: Maybe<MatterServiceMarriages_Mutation_Response>;
  /** insert a single row into the table: "marriages" */
  matterServiceInsert_marriages_one: Maybe<MatterServiceMarriages>;
  /** insert data into the table: "matterAttorneyRoleTypes" */
  matterServiceInsert_matterAttorneyRoleTypes: Maybe<MatterServiceMatterAttorneyRoleTypes_Mutation_Response>;
  /** insert a single row into the table: "matterAttorneyRoleTypes" */
  matterServiceInsert_matterAttorneyRoleTypes_one: Maybe<MatterServiceMatterAttorneyRoleTypes>;
  /** insert data into the table: "matterDocsAndForms" */
  matterServiceInsert_matterDocsAndForms: Maybe<MatterServiceMatterDocsAndForms_Mutation_Response>;
  /** insert data into the table: "matterDocsAndFormsFolders" */
  matterServiceInsert_matterDocsAndFormsFolders: Maybe<MatterServiceMatterDocsAndFormsFolders_Mutation_Response>;
  /** insert a single row into the table: "matterDocsAndFormsFolders" */
  matterServiceInsert_matterDocsAndFormsFolders_one: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  /** insert data into the table: "matterDocsAndFormsStatuses" */
  matterServiceInsert_matterDocsAndFormsStatuses: Maybe<MatterServiceMatterDocsAndFormsStatuses_Mutation_Response>;
  /** insert a single row into the table: "matterDocsAndFormsStatuses" */
  matterServiceInsert_matterDocsAndFormsStatuses_one: Maybe<MatterServiceMatterDocsAndFormsStatuses>;
  /** insert data into the table: "matterDocsAndFormsTypes" */
  matterServiceInsert_matterDocsAndFormsTypes: Maybe<MatterServiceMatterDocsAndFormsTypes_Mutation_Response>;
  /** insert a single row into the table: "matterDocsAndFormsTypes" */
  matterServiceInsert_matterDocsAndFormsTypes_one: Maybe<MatterServiceMatterDocsAndFormsTypes>;
  /** insert a single row into the table: "matterDocsAndForms" */
  matterServiceInsert_matterDocsAndForms_one: Maybe<MatterServiceMatterDocsAndForms>;
  /** insert data into the table: "matterEntities" */
  matterServiceInsert_matterEntities: Maybe<MatterServiceMatterEntities_Mutation_Response>;
  /** insert a single row into the table: "matterEntities" */
  matterServiceInsert_matterEntities_one: Maybe<MatterServiceMatterEntities>;
  /** insert data into the table: "matterEntityTypes" */
  matterServiceInsert_matterEntityTypes: Maybe<MatterServiceMatterEntityTypes_Mutation_Response>;
  /** insert a single row into the table: "matterEntityTypes" */
  matterServiceInsert_matterEntityTypes_one: Maybe<MatterServiceMatterEntityTypes>;
  /** insert data into the table: "matterFilingForTypes" */
  matterServiceInsert_matterFilingForTypes: Maybe<MatterServiceMatterFilingForTypes_Mutation_Response>;
  /** insert a single row into the table: "matterFilingForTypes" */
  matterServiceInsert_matterFilingForTypes_one: Maybe<MatterServiceMatterFilingForTypes>;
  /** insert data into the table: "matterOrganizationRoleTypes" */
  matterServiceInsert_matterOrganizationRoleTypes: Maybe<MatterServiceMatterOrganizationRoleTypes_Mutation_Response>;
  /** insert a single row into the table: "matterOrganizationRoleTypes" */
  matterServiceInsert_matterOrganizationRoleTypes_one: Maybe<MatterServiceMatterOrganizationRoleTypes>;
  /** insert data into the table: "matterPersonRoleTypes" */
  matterServiceInsert_matterPersonRoleTypes: Maybe<MatterServiceMatterPersonRoleTypes_Mutation_Response>;
  /** insert a single row into the table: "matterPersonRoleTypes" */
  matterServiceInsert_matterPersonRoleTypes_one: Maybe<MatterServiceMatterPersonRoleTypes>;
  /** insert data into the table: "matterTypes" */
  matterServiceInsert_matterTypes: Maybe<MatterServiceMatterTypes_Mutation_Response>;
  /** insert a single row into the table: "matterTypes" */
  matterServiceInsert_matterTypes_one: Maybe<MatterServiceMatterTypes>;
  /** insert data into the table: "matters" */
  matterServiceInsert_matters: Maybe<MatterServiceMatters_Mutation_Response>;
  /** insert a single row into the table: "matters" */
  matterServiceInsert_matters_one: Maybe<MatterServiceMatters>;
  /** insert data into the table: "organizations" */
  matterServiceInsert_organizations: Maybe<MatterServiceOrganizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  matterServiceInsert_organizations_one: Maybe<MatterServiceOrganizations>;
  /** insert data into the table: "people" */
  matterServiceInsert_people: Maybe<MatterServicePeople_Mutation_Response>;
  /** insert data into the table: "peopleGenders" */
  matterServiceInsert_peopleGenders: Maybe<MatterServicePeopleGenders_Mutation_Response>;
  /** insert a single row into the table: "peopleGenders" */
  matterServiceInsert_peopleGenders_one: Maybe<MatterServicePeopleGenders>;
  /** insert a single row into the table: "people" */
  matterServiceInsert_people_one: Maybe<MatterServicePeople>;
  /** insert data into the table: "temporaryRestrainingOrders" */
  matterServiceInsert_temporaryRestrainingOrders: Maybe<MatterServiceTemporaryRestrainingOrders_Mutation_Response>;
  /** insert a single row into the table: "temporaryRestrainingOrders" */
  matterServiceInsert_temporaryRestrainingOrders_one: Maybe<MatterServiceTemporaryRestrainingOrders>;
  /** execute VOLATILE function "positionItemInFolder" which returns "matterDocsAndForms" */
  matterServicePositionItemInFolder: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "removeItemsFromFolder" which returns "matterDocsAndForms" */
  matterServiceRemoveItemsFromFolder: Array<MatterServiceMatterDocsAndForms>;
  /** execute VOLATILE function "removeItemsFromMatter" which returns "matterDocsAndForms" */
  matterServiceRemoveItemsFromMatter: Array<MatterServiceMatterDocsAndForms>;
  /** update data of the table: "attorneys" */
  matterServiceUpdate_attorneys: Maybe<MatterServiceAttorneys_Mutation_Response>;
  /** update single row of the table: "attorneys" */
  matterServiceUpdate_attorneys_by_pk: Maybe<MatterServiceAttorneys>;
  /** update multiples rows of table: "attorneys" */
  matterServiceUpdate_attorneys_many: Maybe<
    Array<Maybe<MatterServiceAttorneys_Mutation_Response>>
  >;
  /** update data of the table: "clients" */
  matterServiceUpdate_clients: Maybe<MatterServiceClients_Mutation_Response>;
  /** update single row of the table: "clients" */
  matterServiceUpdate_clients_by_pk: Maybe<MatterServiceClients>;
  /** update multiples rows of table: "clients" */
  matterServiceUpdate_clients_many: Maybe<
    Array<Maybe<MatterServiceClients_Mutation_Response>>
  >;
  /** update data of the table: "courts" */
  matterServiceUpdate_courts: Maybe<MatterServiceCourts_Mutation_Response>;
  /** update single row of the table: "courts" */
  matterServiceUpdate_courts_by_pk: Maybe<MatterServiceCourts>;
  /** update multiples rows of table: "courts" */
  matterServiceUpdate_courts_many: Maybe<
    Array<Maybe<MatterServiceCourts_Mutation_Response>>
  >;
  /** update data of the table: "domesticPartnerships" */
  matterServiceUpdate_domesticPartnerships: Maybe<MatterServiceDomesticPartnerships_Mutation_Response>;
  /** update single row of the table: "domesticPartnerships" */
  matterServiceUpdate_domesticPartnerships_by_pk: Maybe<MatterServiceDomesticPartnerships>;
  /** update multiples rows of table: "domesticPartnerships" */
  matterServiceUpdate_domesticPartnerships_many: Maybe<
    Array<Maybe<MatterServiceDomesticPartnerships_Mutation_Response>>
  >;
  /** update data of the table: "entityAddressTypes" */
  matterServiceUpdate_entityAddressTypes: Maybe<MatterServiceEntityAddressTypes_Mutation_Response>;
  /** update single row of the table: "entityAddressTypes" */
  matterServiceUpdate_entityAddressTypes_by_pk: Maybe<MatterServiceEntityAddressTypes>;
  /** update multiples rows of table: "entityAddressTypes" */
  matterServiceUpdate_entityAddressTypes_many: Maybe<
    Array<Maybe<MatterServiceEntityAddressTypes_Mutation_Response>>
  >;
  /** update data of the table: "entityAddresses" */
  matterServiceUpdate_entityAddresses: Maybe<MatterServiceEntityAddresses_Mutation_Response>;
  /** update single row of the table: "entityAddresses" */
  matterServiceUpdate_entityAddresses_by_pk: Maybe<MatterServiceEntityAddresses>;
  /** update multiples rows of table: "entityAddresses" */
  matterServiceUpdate_entityAddresses_many: Maybe<
    Array<Maybe<MatterServiceEntityAddresses_Mutation_Response>>
  >;
  /** update data of the table: "entityEmailTypes" */
  matterServiceUpdate_entityEmailTypes: Maybe<MatterServiceEntityEmailTypes_Mutation_Response>;
  /** update single row of the table: "entityEmailTypes" */
  matterServiceUpdate_entityEmailTypes_by_pk: Maybe<MatterServiceEntityEmailTypes>;
  /** update multiples rows of table: "entityEmailTypes" */
  matterServiceUpdate_entityEmailTypes_many: Maybe<
    Array<Maybe<MatterServiceEntityEmailTypes_Mutation_Response>>
  >;
  /** update data of the table: "entityEmails" */
  matterServiceUpdate_entityEmails: Maybe<MatterServiceEntityEmails_Mutation_Response>;
  /** update single row of the table: "entityEmails" */
  matterServiceUpdate_entityEmails_by_pk: Maybe<MatterServiceEntityEmails>;
  /** update multiples rows of table: "entityEmails" */
  matterServiceUpdate_entityEmails_many: Maybe<
    Array<Maybe<MatterServiceEntityEmails_Mutation_Response>>
  >;
  /** update data of the table: "entityPhoneNumberTypes" */
  matterServiceUpdate_entityPhoneNumberTypes: Maybe<MatterServiceEntityPhoneNumberTypes_Mutation_Response>;
  /** update single row of the table: "entityPhoneNumberTypes" */
  matterServiceUpdate_entityPhoneNumberTypes_by_pk: Maybe<MatterServiceEntityPhoneNumberTypes>;
  /** update multiples rows of table: "entityPhoneNumberTypes" */
  matterServiceUpdate_entityPhoneNumberTypes_many: Maybe<
    Array<Maybe<MatterServiceEntityPhoneNumberTypes_Mutation_Response>>
  >;
  /** update data of the table: "entityPhoneNumbers" */
  matterServiceUpdate_entityPhoneNumbers: Maybe<MatterServiceEntityPhoneNumbers_Mutation_Response>;
  /** update single row of the table: "entityPhoneNumbers" */
  matterServiceUpdate_entityPhoneNumbers_by_pk: Maybe<MatterServiceEntityPhoneNumbers>;
  /** update multiples rows of table: "entityPhoneNumbers" */
  matterServiceUpdate_entityPhoneNumbers_many: Maybe<
    Array<Maybe<MatterServiceEntityPhoneNumbers_Mutation_Response>>
  >;
  /** update data of the table: "marriages" */
  matterServiceUpdate_marriages: Maybe<MatterServiceMarriages_Mutation_Response>;
  /** update single row of the table: "marriages" */
  matterServiceUpdate_marriages_by_pk: Maybe<MatterServiceMarriages>;
  /** update multiples rows of table: "marriages" */
  matterServiceUpdate_marriages_many: Maybe<
    Array<Maybe<MatterServiceMarriages_Mutation_Response>>
  >;
  /** update data of the table: "matterAttorneyRoleTypes" */
  matterServiceUpdate_matterAttorneyRoleTypes: Maybe<MatterServiceMatterAttorneyRoleTypes_Mutation_Response>;
  /** update single row of the table: "matterAttorneyRoleTypes" */
  matterServiceUpdate_matterAttorneyRoleTypes_by_pk: Maybe<MatterServiceMatterAttorneyRoleTypes>;
  /** update multiples rows of table: "matterAttorneyRoleTypes" */
  matterServiceUpdate_matterAttorneyRoleTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterAttorneyRoleTypes_Mutation_Response>>
  >;
  /** update data of the table: "matterDocsAndForms" */
  matterServiceUpdate_matterDocsAndForms: Maybe<MatterServiceMatterDocsAndForms_Mutation_Response>;
  /** update data of the table: "matterDocsAndFormsFolders" */
  matterServiceUpdate_matterDocsAndFormsFolders: Maybe<MatterServiceMatterDocsAndFormsFolders_Mutation_Response>;
  /** update single row of the table: "matterDocsAndFormsFolders" */
  matterServiceUpdate_matterDocsAndFormsFolders_by_pk: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  /** update multiples rows of table: "matterDocsAndFormsFolders" */
  matterServiceUpdate_matterDocsAndFormsFolders_many: Maybe<
    Array<Maybe<MatterServiceMatterDocsAndFormsFolders_Mutation_Response>>
  >;
  /** update data of the table: "matterDocsAndFormsStatuses" */
  matterServiceUpdate_matterDocsAndFormsStatuses: Maybe<MatterServiceMatterDocsAndFormsStatuses_Mutation_Response>;
  /** update single row of the table: "matterDocsAndFormsStatuses" */
  matterServiceUpdate_matterDocsAndFormsStatuses_by_pk: Maybe<MatterServiceMatterDocsAndFormsStatuses>;
  /** update multiples rows of table: "matterDocsAndFormsStatuses" */
  matterServiceUpdate_matterDocsAndFormsStatuses_many: Maybe<
    Array<Maybe<MatterServiceMatterDocsAndFormsStatuses_Mutation_Response>>
  >;
  /** update data of the table: "matterDocsAndFormsTypes" */
  matterServiceUpdate_matterDocsAndFormsTypes: Maybe<MatterServiceMatterDocsAndFormsTypes_Mutation_Response>;
  /** update single row of the table: "matterDocsAndFormsTypes" */
  matterServiceUpdate_matterDocsAndFormsTypes_by_pk: Maybe<MatterServiceMatterDocsAndFormsTypes>;
  /** update multiples rows of table: "matterDocsAndFormsTypes" */
  matterServiceUpdate_matterDocsAndFormsTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterDocsAndFormsTypes_Mutation_Response>>
  >;
  /** update single row of the table: "matterDocsAndForms" */
  matterServiceUpdate_matterDocsAndForms_by_pk: Maybe<MatterServiceMatterDocsAndForms>;
  /** update multiples rows of table: "matterDocsAndForms" */
  matterServiceUpdate_matterDocsAndForms_many: Maybe<
    Array<Maybe<MatterServiceMatterDocsAndForms_Mutation_Response>>
  >;
  /** update data of the table: "matterEntities" */
  matterServiceUpdate_matterEntities: Maybe<MatterServiceMatterEntities_Mutation_Response>;
  /** update single row of the table: "matterEntities" */
  matterServiceUpdate_matterEntities_by_pk: Maybe<MatterServiceMatterEntities>;
  /** update multiples rows of table: "matterEntities" */
  matterServiceUpdate_matterEntities_many: Maybe<
    Array<Maybe<MatterServiceMatterEntities_Mutation_Response>>
  >;
  /** update data of the table: "matterEntityTypes" */
  matterServiceUpdate_matterEntityTypes: Maybe<MatterServiceMatterEntityTypes_Mutation_Response>;
  /** update single row of the table: "matterEntityTypes" */
  matterServiceUpdate_matterEntityTypes_by_pk: Maybe<MatterServiceMatterEntityTypes>;
  /** update multiples rows of table: "matterEntityTypes" */
  matterServiceUpdate_matterEntityTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterEntityTypes_Mutation_Response>>
  >;
  /** update data of the table: "matterFilingForTypes" */
  matterServiceUpdate_matterFilingForTypes: Maybe<MatterServiceMatterFilingForTypes_Mutation_Response>;
  /** update single row of the table: "matterFilingForTypes" */
  matterServiceUpdate_matterFilingForTypes_by_pk: Maybe<MatterServiceMatterFilingForTypes>;
  /** update multiples rows of table: "matterFilingForTypes" */
  matterServiceUpdate_matterFilingForTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterFilingForTypes_Mutation_Response>>
  >;
  /** update data of the table: "matterOrganizationRoleTypes" */
  matterServiceUpdate_matterOrganizationRoleTypes: Maybe<MatterServiceMatterOrganizationRoleTypes_Mutation_Response>;
  /** update single row of the table: "matterOrganizationRoleTypes" */
  matterServiceUpdate_matterOrganizationRoleTypes_by_pk: Maybe<MatterServiceMatterOrganizationRoleTypes>;
  /** update multiples rows of table: "matterOrganizationRoleTypes" */
  matterServiceUpdate_matterOrganizationRoleTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterOrganizationRoleTypes_Mutation_Response>>
  >;
  /** update data of the table: "matterPersonRoleTypes" */
  matterServiceUpdate_matterPersonRoleTypes: Maybe<MatterServiceMatterPersonRoleTypes_Mutation_Response>;
  /** update single row of the table: "matterPersonRoleTypes" */
  matterServiceUpdate_matterPersonRoleTypes_by_pk: Maybe<MatterServiceMatterPersonRoleTypes>;
  /** update multiples rows of table: "matterPersonRoleTypes" */
  matterServiceUpdate_matterPersonRoleTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterPersonRoleTypes_Mutation_Response>>
  >;
  /** update data of the table: "matterTypes" */
  matterServiceUpdate_matterTypes: Maybe<MatterServiceMatterTypes_Mutation_Response>;
  /** update single row of the table: "matterTypes" */
  matterServiceUpdate_matterTypes_by_pk: Maybe<MatterServiceMatterTypes>;
  /** update multiples rows of table: "matterTypes" */
  matterServiceUpdate_matterTypes_many: Maybe<
    Array<Maybe<MatterServiceMatterTypes_Mutation_Response>>
  >;
  /** update data of the table: "matters" */
  matterServiceUpdate_matters: Maybe<MatterServiceMatters_Mutation_Response>;
  /** update single row of the table: "matters" */
  matterServiceUpdate_matters_by_pk: Maybe<MatterServiceMatters>;
  /** update multiples rows of table: "matters" */
  matterServiceUpdate_matters_many: Maybe<
    Array<Maybe<MatterServiceMatters_Mutation_Response>>
  >;
  /** update data of the table: "organizations" */
  matterServiceUpdate_organizations: Maybe<MatterServiceOrganizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  matterServiceUpdate_organizations_by_pk: Maybe<MatterServiceOrganizations>;
  /** update multiples rows of table: "organizations" */
  matterServiceUpdate_organizations_many: Maybe<
    Array<Maybe<MatterServiceOrganizations_Mutation_Response>>
  >;
  /** update data of the table: "people" */
  matterServiceUpdate_people: Maybe<MatterServicePeople_Mutation_Response>;
  /** update data of the table: "peopleGenders" */
  matterServiceUpdate_peopleGenders: Maybe<MatterServicePeopleGenders_Mutation_Response>;
  /** update single row of the table: "peopleGenders" */
  matterServiceUpdate_peopleGenders_by_pk: Maybe<MatterServicePeopleGenders>;
  /** update multiples rows of table: "peopleGenders" */
  matterServiceUpdate_peopleGenders_many: Maybe<
    Array<Maybe<MatterServicePeopleGenders_Mutation_Response>>
  >;
  /** update single row of the table: "people" */
  matterServiceUpdate_people_by_pk: Maybe<MatterServicePeople>;
  /** update multiples rows of table: "people" */
  matterServiceUpdate_people_many: Maybe<
    Array<Maybe<MatterServicePeople_Mutation_Response>>
  >;
  /** update data of the table: "temporaryRestrainingOrders" */
  matterServiceUpdate_temporaryRestrainingOrders: Maybe<MatterServiceTemporaryRestrainingOrders_Mutation_Response>;
  /** update single row of the table: "temporaryRestrainingOrders" */
  matterServiceUpdate_temporaryRestrainingOrders_by_pk: Maybe<MatterServiceTemporaryRestrainingOrders>;
  /** update multiples rows of table: "temporaryRestrainingOrders" */
  matterServiceUpdate_temporaryRestrainingOrders_many: Maybe<
    Array<Maybe<MatterServiceTemporaryRestrainingOrders_Mutation_Response>>
  >;
  payFirmInvoice: Maybe<InvoicePayment>;
  payUserInvoice: Maybe<InvoicePayment>;
  refreshSession: Maybe<Session>;
  register: Maybe<Session>;
  removeFirmAdministrator: Maybe<Scalars['Boolean']['output']>;
  removeUserFromFirm: Maybe<Scalars['Boolean']['output']>;
  replaceFirmSubscription: Maybe<Subscription>;
  replaceUserSubscription: Maybe<Subscription>;
  resendFirmInvitation: Maybe<Scalars['Boolean']['output']>;
  sendFirmInvitation: Maybe<Scalars['Boolean']['output']>;
  updateBookmark: Maybe<Bookmark>;
  updateBookmarkFolder: Maybe<BookmarkFolder>;
  updateFirmAddressInfo: Maybe<AddressInfo>;
  updateFirmSubscription: Maybe<Subscription>;
  updateHistoryItem: Maybe<HistoryItem>;
  updateSecondaryPracticeAreas: Maybe<Scalars['Boolean']['output']>;
  updateUser: Maybe<User>;
  updateUserAddressInfo: Maybe<AddressInfo>;
  updateUserSubscription: Maybe<Subscription>;
  upsertCourseProgress: Maybe<CourseProgress>;
};

/** mutation root */
export type MutationAcceptFirmInviteArgs = {
  inviteId: Scalars['ID']['input'];
};

/** mutation root */
export type MutationAddContentFavoriteArgs = {
  contentItemId: Scalars['ID']['input'];
  type: ContentFavoriteType;
};

/** mutation root */
export type MutationAddCourseToWatchlistArgs = {
  contentfulCourseId: Scalars['String']['input'];
};

/** mutation root */
export type MutationAddFirmAdministratorArgs = {
  accountId: Scalars['ID']['input'];
};

/** mutation root */
export type MutationCompletePasswordResetArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
};

/** mutation root */
export type MutationCreateAcademicSubscriptionArgs = {
  graduationDate: InputMaybe<Scalars['IsoDate']['input']>;
  schoolCode: Scalars['NonEmptyString']['input'];
};

/** mutation root */
export type MutationCreateBookmarkArgs = {
  folderId: Scalars['ID']['input'];
  path: Scalars['String']['input'];
  subtype: InputMaybe<Scalars['String']['input']>;
  text: Scalars['JSONObject']['input'];
  title: Scalars['String']['input'];
  type: HistoryType;
};

/** mutation root */
export type MutationCreateBookmarkFolderArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type MutationCreateFirmSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

/** mutation root */
export type MutationCreateHistoryItemArgs = {
  action: HistoryAction;
  parentId: InputMaybe<Scalars['ID']['input']>;
  path: Scalars['String']['input'];
  subtype: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: HistoryType;
};

/** mutation root */
export type MutationCreateUserSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

/** mutation root */
export type MutationDeleteBookmarkArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type MutationDeleteBookmarkFolderArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type MutationDeleteBookmarksArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** mutation root */
export type MutationDeleteContentFavoriteArgs = {
  contentItemId: Scalars['ID']['input'];
};

/** mutation root */
export type MutationDeleteCourseFromWatchlistArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type MutationDeleteFirmInviteArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type MutationInitiatePasswordResetArgs = {
  email: Scalars['String']['input'];
};

/** mutation root */
export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceAddDocsAndFormsToFolderArgs = {
  args: MatterServiceAddDocsAndFormsToFolder_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceAddDocsAndFormsToMatterArgs = {
  args: MatterServiceAddDocsAndFormsToMatter_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceAddItemsToFolderArgs = {
  args: MatterServiceAddItemsToFolder_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceCreateFolderAddDocsAndFormsArgs = {
  args: MatterServiceCreateFolderAddDocsAndForms_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceCreateFolderAddItemsArgs = {
  args: MatterServiceCreateFolderAddItems_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceCreateFolderDuplicateItemsArgs = {
  args: MatterServiceCreateFolderDuplicateItems_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceDelete_AttorneysArgs = {
  where: MatterServiceAttorneys_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Attorneys_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_ClientsArgs = {
  where: MatterServiceClients_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Clients_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_CourtsArgs = {
  where: MatterServiceCourts_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Courts_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_DomesticPartnershipsArgs = {
  where: MatterServiceDomesticPartnerships_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_DomesticPartnerships_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityAddressTypesArgs = {
  where: MatterServiceEntityAddressTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityAddressTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityAddressesArgs = {
  where: MatterServiceEntityAddresses_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityAddresses_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityEmailTypesArgs = {
  where: MatterServiceEntityEmailTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityEmailTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityEmailsArgs = {
  where: MatterServiceEntityEmails_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityEmails_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityPhoneNumberTypesArgs = {
  where: MatterServiceEntityPhoneNumberTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityPhoneNumberTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_EntityPhoneNumbersArgs = {
  where: MatterServiceEntityPhoneNumbers_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_EntityPhoneNumbers_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MarriagesArgs = {
  where: MatterServiceMarriages_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Marriages_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterAttorneyRoleTypesArgs = {
  where: MatterServiceMatterAttorneyRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterAttorneyRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsArgs = {
  where: MatterServiceMatterDocsAndForms_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsFoldersArgs = {
  where: MatterServiceMatterDocsAndFormsFolders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsFolders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsStatusesArgs = {
  where: MatterServiceMatterDocsAndFormsStatuses_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsStatuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsTypesArgs = {
  where: MatterServiceMatterDocsAndFormsTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndFormsTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterDocsAndForms_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterEntitiesArgs = {
  where: MatterServiceMatterEntities_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterEntities_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterEntityTypesArgs = {
  where: MatterServiceMatterEntityTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterEntityTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterFilingForTypesArgs = {
  where: MatterServiceMatterFilingForTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterFilingForTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterOrganizationRoleTypesArgs = {
  where: MatterServiceMatterOrganizationRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterOrganizationRoleTypes_By_PkArgs =
  {
    value: Scalars['String']['input'];
  };

/** mutation root */
export type MutationMatterServiceDelete_MatterPersonRoleTypesArgs = {
  where: MatterServiceMatterPersonRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterPersonRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MatterTypesArgs = {
  where: MatterServiceMatterTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_MatterTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_MattersArgs = {
  where: MatterServiceMatters_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Matters_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_OrganizationsArgs = {
  where: MatterServiceOrganizations_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_Organizations_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_PeopleArgs = {
  where: MatterServicePeople_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_PeopleGendersArgs = {
  where: MatterServicePeopleGenders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_PeopleGenders_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_People_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDelete_TemporaryRestrainingOrdersArgs = {
  where: MatterServiceTemporaryRestrainingOrders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceDelete_TemporaryRestrainingOrders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

/** mutation root */
export type MutationMatterServiceDuplicateItemsInMatterArgs = {
  args: MatterServiceDuplicateItemsInMatter_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceDuplicateItemsToFolderArgs = {
  args: MatterServiceDuplicateItemsToFolder_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceInsert_AttorneysArgs = {
  objects: Array<MatterServiceAttorneys_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceAttorneys_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Attorneys_OneArgs = {
  object: MatterServiceAttorneys_Insert_Input;
  on_conflict: InputMaybe<MatterServiceAttorneys_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_ClientsArgs = {
  objects: Array<MatterServiceClients_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceClients_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Clients_OneArgs = {
  object: MatterServiceClients_Insert_Input;
  on_conflict: InputMaybe<MatterServiceClients_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_CourtsArgs = {
  objects: Array<MatterServiceCourts_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceCourts_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Courts_OneArgs = {
  object: MatterServiceCourts_Insert_Input;
  on_conflict: InputMaybe<MatterServiceCourts_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_DomesticPartnershipsArgs = {
  objects: Array<MatterServiceDomesticPartnerships_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceDomesticPartnerships_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_DomesticPartnerships_OneArgs = {
  object: MatterServiceDomesticPartnerships_Insert_Input;
  on_conflict: InputMaybe<MatterServiceDomesticPartnerships_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityAddressTypesArgs = {
  objects: Array<MatterServiceEntityAddressTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityAddressTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityAddressTypes_OneArgs = {
  object: MatterServiceEntityAddressTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityAddressTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityAddressesArgs = {
  objects: Array<MatterServiceEntityAddresses_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityAddresses_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityAddresses_OneArgs = {
  object: MatterServiceEntityAddresses_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityAddresses_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityEmailTypesArgs = {
  objects: Array<MatterServiceEntityEmailTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityEmailTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityEmailTypes_OneArgs = {
  object: MatterServiceEntityEmailTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityEmailTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityEmailsArgs = {
  objects: Array<MatterServiceEntityEmails_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityEmails_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityEmails_OneArgs = {
  object: MatterServiceEntityEmails_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityEmails_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityPhoneNumberTypesArgs = {
  objects: Array<MatterServiceEntityPhoneNumberTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityPhoneNumberTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityPhoneNumberTypes_OneArgs = {
  object: MatterServiceEntityPhoneNumberTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityPhoneNumberTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityPhoneNumbersArgs = {
  objects: Array<MatterServiceEntityPhoneNumbers_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceEntityPhoneNumbers_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_EntityPhoneNumbers_OneArgs = {
  object: MatterServiceEntityPhoneNumbers_Insert_Input;
  on_conflict: InputMaybe<MatterServiceEntityPhoneNumbers_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MarriagesArgs = {
  objects: Array<MatterServiceMarriages_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMarriages_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Marriages_OneArgs = {
  object: MatterServiceMarriages_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMarriages_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterAttorneyRoleTypesArgs = {
  objects: Array<MatterServiceMatterAttorneyRoleTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterAttorneyRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterAttorneyRoleTypes_OneArgs = {
  object: MatterServiceMatterAttorneyRoleTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterAttorneyRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsArgs = {
  objects: Array<MatterServiceMatterDocsAndForms_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndForms_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsFoldersArgs = {
  objects: Array<MatterServiceMatterDocsAndFormsFolders_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsFolders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsFolders_OneArgs = {
  object: MatterServiceMatterDocsAndFormsFolders_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsFolders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsStatusesArgs = {
  objects: Array<MatterServiceMatterDocsAndFormsStatuses_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsStatuses_OneArgs = {
  object: MatterServiceMatterDocsAndFormsStatuses_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsTypesArgs = {
  objects: Array<MatterServiceMatterDocsAndFormsTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndFormsTypes_OneArgs = {
  object: MatterServiceMatterDocsAndFormsTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndFormsTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterDocsAndForms_OneArgs = {
  object: MatterServiceMatterDocsAndForms_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterDocsAndForms_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterEntitiesArgs = {
  objects: Array<MatterServiceMatterEntities_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterEntities_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterEntities_OneArgs = {
  object: MatterServiceMatterEntities_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterEntities_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterEntityTypesArgs = {
  objects: Array<MatterServiceMatterEntityTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterEntityTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterEntityTypes_OneArgs = {
  object: MatterServiceMatterEntityTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterEntityTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterFilingForTypesArgs = {
  objects: Array<MatterServiceMatterFilingForTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterFilingForTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterFilingForTypes_OneArgs = {
  object: MatterServiceMatterFilingForTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterFilingForTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterOrganizationRoleTypesArgs = {
  objects: Array<MatterServiceMatterOrganizationRoleTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterOrganizationRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterOrganizationRoleTypes_OneArgs = {
  object: MatterServiceMatterOrganizationRoleTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterOrganizationRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterPersonRoleTypesArgs = {
  objects: Array<MatterServiceMatterPersonRoleTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterPersonRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterPersonRoleTypes_OneArgs = {
  object: MatterServiceMatterPersonRoleTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterPersonRoleTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterTypesArgs = {
  objects: Array<MatterServiceMatterTypes_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatterTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MatterTypes_OneArgs = {
  object: MatterServiceMatterTypes_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatterTypes_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_MattersArgs = {
  objects: Array<MatterServiceMatters_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceMatters_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Matters_OneArgs = {
  object: MatterServiceMatters_Insert_Input;
  on_conflict: InputMaybe<MatterServiceMatters_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_OrganizationsArgs = {
  objects: Array<MatterServiceOrganizations_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceOrganizations_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_Organizations_OneArgs = {
  object: MatterServiceOrganizations_Insert_Input;
  on_conflict: InputMaybe<MatterServiceOrganizations_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_PeopleArgs = {
  objects: Array<MatterServicePeople_Insert_Input>;
  on_conflict: InputMaybe<MatterServicePeople_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_PeopleGendersArgs = {
  objects: Array<MatterServicePeopleGenders_Insert_Input>;
  on_conflict: InputMaybe<MatterServicePeopleGenders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_PeopleGenders_OneArgs = {
  object: MatterServicePeopleGenders_Insert_Input;
  on_conflict: InputMaybe<MatterServicePeopleGenders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_People_OneArgs = {
  object: MatterServicePeople_Insert_Input;
  on_conflict: InputMaybe<MatterServicePeople_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_TemporaryRestrainingOrdersArgs = {
  objects: Array<MatterServiceTemporaryRestrainingOrders_Insert_Input>;
  on_conflict: InputMaybe<MatterServiceTemporaryRestrainingOrders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServiceInsert_TemporaryRestrainingOrders_OneArgs = {
  object: MatterServiceTemporaryRestrainingOrders_Insert_Input;
  on_conflict: InputMaybe<MatterServiceTemporaryRestrainingOrders_On_Conflict>;
};

/** mutation root */
export type MutationMatterServicePositionItemInFolderArgs = {
  args: MatterServicePositionItemInFolder_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceRemoveItemsFromFolderArgs = {
  args: MatterServiceRemoveItemsFromFolder_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceRemoveItemsFromMatterArgs = {
  args: MatterServiceRemoveItemsFromMatter_Args;
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

/** mutation root */
export type MutationMatterServiceUpdate_AttorneysArgs = {
  _set: InputMaybe<MatterServiceAttorneys_Set_Input>;
  where: MatterServiceAttorneys_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Attorneys_By_PkArgs = {
  _set: InputMaybe<MatterServiceAttorneys_Set_Input>;
  pk_columns: MatterServiceAttorneys_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Attorneys_ManyArgs = {
  updates: Array<MatterServiceAttorneys_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_ClientsArgs = {
  _set: InputMaybe<MatterServiceClients_Set_Input>;
  where: MatterServiceClients_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Clients_By_PkArgs = {
  _set: InputMaybe<MatterServiceClients_Set_Input>;
  pk_columns: MatterServiceClients_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Clients_ManyArgs = {
  updates: Array<MatterServiceClients_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_CourtsArgs = {
  _set: InputMaybe<MatterServiceCourts_Set_Input>;
  where: MatterServiceCourts_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Courts_By_PkArgs = {
  _set: InputMaybe<MatterServiceCourts_Set_Input>;
  pk_columns: MatterServiceCourts_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Courts_ManyArgs = {
  updates: Array<MatterServiceCourts_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_DomesticPartnershipsArgs = {
  _set: InputMaybe<MatterServiceDomesticPartnerships_Set_Input>;
  where: MatterServiceDomesticPartnerships_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_DomesticPartnerships_By_PkArgs = {
  _set: InputMaybe<MatterServiceDomesticPartnerships_Set_Input>;
  pk_columns: MatterServiceDomesticPartnerships_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_DomesticPartnerships_ManyArgs = {
  updates: Array<MatterServiceDomesticPartnerships_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddressTypesArgs = {
  _set: InputMaybe<MatterServiceEntityAddressTypes_Set_Input>;
  where: MatterServiceEntityAddressTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddressTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityAddressTypes_Set_Input>;
  pk_columns: MatterServiceEntityAddressTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddressTypes_ManyArgs = {
  updates: Array<MatterServiceEntityAddressTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddressesArgs = {
  _set: InputMaybe<MatterServiceEntityAddresses_Set_Input>;
  where: MatterServiceEntityAddresses_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddresses_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityAddresses_Set_Input>;
  pk_columns: MatterServiceEntityAddresses_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityAddresses_ManyArgs = {
  updates: Array<MatterServiceEntityAddresses_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmailTypesArgs = {
  _set: InputMaybe<MatterServiceEntityEmailTypes_Set_Input>;
  where: MatterServiceEntityEmailTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmailTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityEmailTypes_Set_Input>;
  pk_columns: MatterServiceEntityEmailTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmailTypes_ManyArgs = {
  updates: Array<MatterServiceEntityEmailTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmailsArgs = {
  _set: InputMaybe<MatterServiceEntityEmails_Set_Input>;
  where: MatterServiceEntityEmails_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmails_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityEmails_Set_Input>;
  pk_columns: MatterServiceEntityEmails_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityEmails_ManyArgs = {
  updates: Array<MatterServiceEntityEmails_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumberTypesArgs = {
  _set: InputMaybe<MatterServiceEntityPhoneNumberTypes_Set_Input>;
  where: MatterServiceEntityPhoneNumberTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumberTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityPhoneNumberTypes_Set_Input>;
  pk_columns: MatterServiceEntityPhoneNumberTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumberTypes_ManyArgs = {
  updates: Array<MatterServiceEntityPhoneNumberTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumbersArgs = {
  _set: InputMaybe<MatterServiceEntityPhoneNumbers_Set_Input>;
  where: MatterServiceEntityPhoneNumbers_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumbers_By_PkArgs = {
  _set: InputMaybe<MatterServiceEntityPhoneNumbers_Set_Input>;
  pk_columns: MatterServiceEntityPhoneNumbers_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_EntityPhoneNumbers_ManyArgs = {
  updates: Array<MatterServiceEntityPhoneNumbers_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MarriagesArgs = {
  _set: InputMaybe<MatterServiceMarriages_Set_Input>;
  where: MatterServiceMarriages_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Marriages_By_PkArgs = {
  _set: InputMaybe<MatterServiceMarriages_Set_Input>;
  pk_columns: MatterServiceMarriages_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Marriages_ManyArgs = {
  updates: Array<MatterServiceMarriages_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterAttorneyRoleTypesArgs = {
  _set: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Set_Input>;
  where: MatterServiceMatterAttorneyRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterAttorneyRoleTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Set_Input>;
  pk_columns: MatterServiceMatterAttorneyRoleTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterAttorneyRoleTypes_ManyArgs = {
  updates: Array<MatterServiceMatterAttorneyRoleTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsArgs = {
  _append: InputMaybe<MatterServiceMatterDocsAndForms_Append_Input>;
  _delete_at_path: InputMaybe<MatterServiceMatterDocsAndForms_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Elem_Input>;
  _delete_key: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Key_Input>;
  _inc: InputMaybe<MatterServiceMatterDocsAndForms_Inc_Input>;
  _prepend: InputMaybe<MatterServiceMatterDocsAndForms_Prepend_Input>;
  _set: InputMaybe<MatterServiceMatterDocsAndForms_Set_Input>;
  where: MatterServiceMatterDocsAndForms_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsFoldersArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Set_Input>;
  where: MatterServiceMatterDocsAndFormsFolders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsFolders_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Set_Input>;
  pk_columns: MatterServiceMatterDocsAndFormsFolders_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsFolders_ManyArgs = {
  updates: Array<MatterServiceMatterDocsAndFormsFolders_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsStatusesArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Set_Input>;
  where: MatterServiceMatterDocsAndFormsStatuses_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsStatuses_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Set_Input>;
  pk_columns: MatterServiceMatterDocsAndFormsStatuses_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsStatuses_ManyArgs = {
  updates: Array<MatterServiceMatterDocsAndFormsStatuses_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsTypesArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Set_Input>;
  where: MatterServiceMatterDocsAndFormsTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Set_Input>;
  pk_columns: MatterServiceMatterDocsAndFormsTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndFormsTypes_ManyArgs = {
  updates: Array<MatterServiceMatterDocsAndFormsTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndForms_By_PkArgs = {
  _append: InputMaybe<MatterServiceMatterDocsAndForms_Append_Input>;
  _delete_at_path: InputMaybe<MatterServiceMatterDocsAndForms_Delete_At_Path_Input>;
  _delete_elem: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Elem_Input>;
  _delete_key: InputMaybe<MatterServiceMatterDocsAndForms_Delete_Key_Input>;
  _inc: InputMaybe<MatterServiceMatterDocsAndForms_Inc_Input>;
  _prepend: InputMaybe<MatterServiceMatterDocsAndForms_Prepend_Input>;
  _set: InputMaybe<MatterServiceMatterDocsAndForms_Set_Input>;
  pk_columns: MatterServiceMatterDocsAndForms_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterDocsAndForms_ManyArgs = {
  updates: Array<MatterServiceMatterDocsAndForms_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntitiesArgs = {
  _set: InputMaybe<MatterServiceMatterEntities_Set_Input>;
  where: MatterServiceMatterEntities_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntities_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterEntities_Set_Input>;
  pk_columns: MatterServiceMatterEntities_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntities_ManyArgs = {
  updates: Array<MatterServiceMatterEntities_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntityTypesArgs = {
  _set: InputMaybe<MatterServiceMatterEntityTypes_Set_Input>;
  where: MatterServiceMatterEntityTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntityTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterEntityTypes_Set_Input>;
  pk_columns: MatterServiceMatterEntityTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterEntityTypes_ManyArgs = {
  updates: Array<MatterServiceMatterEntityTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterFilingForTypesArgs = {
  _set: InputMaybe<MatterServiceMatterFilingForTypes_Set_Input>;
  where: MatterServiceMatterFilingForTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterFilingForTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterFilingForTypes_Set_Input>;
  pk_columns: MatterServiceMatterFilingForTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterFilingForTypes_ManyArgs = {
  updates: Array<MatterServiceMatterFilingForTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterOrganizationRoleTypesArgs = {
  _set: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Set_Input>;
  where: MatterServiceMatterOrganizationRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterOrganizationRoleTypes_By_PkArgs =
  {
    _set: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Set_Input>;
    pk_columns: MatterServiceMatterOrganizationRoleTypes_Pk_Columns_Input;
  };

/** mutation root */
export type MutationMatterServiceUpdate_MatterOrganizationRoleTypes_ManyArgs = {
  updates: Array<MatterServiceMatterOrganizationRoleTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterPersonRoleTypesArgs = {
  _set: InputMaybe<MatterServiceMatterPersonRoleTypes_Set_Input>;
  where: MatterServiceMatterPersonRoleTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterPersonRoleTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterPersonRoleTypes_Set_Input>;
  pk_columns: MatterServiceMatterPersonRoleTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterPersonRoleTypes_ManyArgs = {
  updates: Array<MatterServiceMatterPersonRoleTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterTypesArgs = {
  _set: InputMaybe<MatterServiceMatterTypes_Set_Input>;
  where: MatterServiceMatterTypes_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterTypes_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatterTypes_Set_Input>;
  pk_columns: MatterServiceMatterTypes_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_MatterTypes_ManyArgs = {
  updates: Array<MatterServiceMatterTypes_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_MattersArgs = {
  _set: InputMaybe<MatterServiceMatters_Set_Input>;
  where: MatterServiceMatters_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Matters_By_PkArgs = {
  _set: InputMaybe<MatterServiceMatters_Set_Input>;
  pk_columns: MatterServiceMatters_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Matters_ManyArgs = {
  updates: Array<MatterServiceMatters_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_OrganizationsArgs = {
  _set: InputMaybe<MatterServiceOrganizations_Set_Input>;
  where: MatterServiceOrganizations_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_Organizations_By_PkArgs = {
  _set: InputMaybe<MatterServiceOrganizations_Set_Input>;
  pk_columns: MatterServiceOrganizations_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_Organizations_ManyArgs = {
  updates: Array<MatterServiceOrganizations_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_PeopleArgs = {
  _inc: InputMaybe<MatterServicePeople_Inc_Input>;
  _set: InputMaybe<MatterServicePeople_Set_Input>;
  where: MatterServicePeople_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_PeopleGendersArgs = {
  _set: InputMaybe<MatterServicePeopleGenders_Set_Input>;
  where: MatterServicePeopleGenders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_PeopleGenders_By_PkArgs = {
  _set: InputMaybe<MatterServicePeopleGenders_Set_Input>;
  pk_columns: MatterServicePeopleGenders_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_PeopleGenders_ManyArgs = {
  updates: Array<MatterServicePeopleGenders_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_People_By_PkArgs = {
  _inc: InputMaybe<MatterServicePeople_Inc_Input>;
  _set: InputMaybe<MatterServicePeople_Set_Input>;
  pk_columns: MatterServicePeople_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_People_ManyArgs = {
  updates: Array<MatterServicePeople_Updates>;
};

/** mutation root */
export type MutationMatterServiceUpdate_TemporaryRestrainingOrdersArgs = {
  _set: InputMaybe<MatterServiceTemporaryRestrainingOrders_Set_Input>;
  where: MatterServiceTemporaryRestrainingOrders_Bool_Exp;
};

/** mutation root */
export type MutationMatterServiceUpdate_TemporaryRestrainingOrders_By_PkArgs = {
  _set: InputMaybe<MatterServiceTemporaryRestrainingOrders_Set_Input>;
  pk_columns: MatterServiceTemporaryRestrainingOrders_Pk_Columns_Input;
};

/** mutation root */
export type MutationMatterServiceUpdate_TemporaryRestrainingOrders_ManyArgs = {
  updates: Array<MatterServiceTemporaryRestrainingOrders_Updates>;
};

/** mutation root */
export type MutationPayFirmInvoiceArgs = {
  input: PayInvoiceInput;
};

/** mutation root */
export type MutationPayUserInvoiceArgs = {
  input: PayInvoiceInput;
};

/** mutation root */
export type MutationRegisterArgs = {
  barNumber: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationName: InputMaybe<Scalars['String']['input']>;
  organizationType: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  secondaryOrganizationType: InputMaybe<Scalars['String']['input']>;
  userRole: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type MutationRemoveFirmAdministratorArgs = {
  accountId: Scalars['ID']['input'];
};

/** mutation root */
export type MutationRemoveUserFromFirmArgs = {
  accountId: Scalars['ID']['input'];
};

/** mutation root */
export type MutationReplaceFirmSubscriptionArgs = {
  input: ReplaceSubscriptionInput;
};

/** mutation root */
export type MutationReplaceUserSubscriptionArgs = {
  input: ReplaceSubscriptionInput;
};

/** mutation root */
export type MutationResendFirmInvitationArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type MutationSendFirmInvitationArgs = {
  admin: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  inviteeBarNumber: InputMaybe<Scalars['String']['input']>;
  inviteeFirstName: InputMaybe<Scalars['String']['input']>;
  inviteeLastName: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type MutationUpdateBookmarkArgs = {
  folderId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  path: InputMaybe<Scalars['String']['input']>;
  subtype: InputMaybe<Scalars['String']['input']>;
  text: InputMaybe<Scalars['JSONObject']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<HistoryType>;
};

/** mutation root */
export type MutationUpdateBookmarkFolderArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** mutation root */
export type MutationUpdateFirmAddressInfoArgs = {
  input: AddressInfoInput;
};

/** mutation root */
export type MutationUpdateFirmSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

/** mutation root */
export type MutationUpdateHistoryItemArgs = {
  action: InputMaybe<HistoryAction>;
  id: Scalars['ID']['input'];
  parentId: InputMaybe<Scalars['ID']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  subtype: InputMaybe<Scalars['String']['input']>;
  text: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<HistoryType>;
};

/** mutation root */
export type MutationUpdateSecondaryPracticeAreasArgs = {
  selected: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type MutationUpdateUserArgs = {
  barNumber: InputMaybe<Scalars['String']['input']>;
  complianceGroupOverrideId: InputMaybe<
    Scalars['ComplianceGroupOverrideId']['input']
  >;
  email: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  organizationName: InputMaybe<Scalars['String']['input']>;
  organizationType: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  primarySpecialty: InputMaybe<Scalars['UserSpecialty']['input']>;
  recoveryEmail: InputMaybe<Scalars['String']['input']>;
  secondaryOrganizationType: InputMaybe<Scalars['String']['input']>;
  secondarySpecialty: InputMaybe<Scalars['UserSpecialty']['input']>;
  termsConditionsLastAcceptance: InputMaybe<Scalars['IsoDate']['input']>;
  userRole: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type MutationUpdateUserAddressInfoArgs = {
  input: AddressInfoInput;
};

/** mutation root */
export type MutationUpdateUserSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

/** mutation root */
export type MutationUpsertCourseProgressArgs = {
  contentfulCourseId: Scalars['String']['input'];
  currentPlayheadInMs: Scalars['Int']['input'];
  videoDurationInMs: Scalars['Int']['input'];
};

export type NgramTypeaheadResult = {
  __typename?: 'NgramTypeaheadResult';
  value: Scalars['String']['output'];
};

export type OnlawOutlineTypeaheadResult = {
  __typename?: 'OnlawOutlineTypeaheadResult';
  onlawId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OnlawSearchCaseTypeaheadResult = {
  __typename?: 'OnlawSearchCaseTypeaheadResult';
  cite: Scalars['String']['output'];
  date: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OnlawSearchCodeTypeaheadResult = {
  __typename?: 'OnlawSearchCodeTypeaheadResult';
  value: Scalars['String']['output'];
};

export type OrgType = {
  __typename?: 'OrgType';
  label: Maybe<Scalars['String']['output']>;
  secondaryOrganizationTypes: Maybe<Array<Maybe<SelectOption>>>;
  userRoles: Maybe<Array<Maybe<SelectOption>>>;
  value: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PagedContributorSearchConnection = PagedResultConnection & {
  __typename?: 'PagedContributorSearchConnection';
  edges: Maybe<Array<Maybe<CleContributor>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedCourseSearchConnection = PagedResultConnection & {
  __typename?: 'PagedCourseSearchConnection';
  edges: Maybe<Array<Maybe<CleCourse>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedCoursesCollectionSearchConnection = PagedResultConnection & {
  __typename?: 'PagedCoursesCollectionSearchConnection';
  edges: Maybe<Array<Maybe<CoursesCollection>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedCurrentAwarenessConnection = PagedResultConnection & {
  __typename?: 'PagedCurrentAwarenessConnection';
  edges: Maybe<Array<Maybe<CurrentAwareness>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedInvoiceConnection = PagedResultConnection & {
  __typename?: 'PagedInvoiceConnection';
  edges: Maybe<Array<Maybe<Invoice>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedResultConnection = {
  /** information about the current page of results */
  pageInfo: PageInfo;
  /** total number of results */
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PagedTrueDocsAndFormsCollectionsSearchConnection =
  PagedResultConnection & {
    __typename?: 'PagedTrueDocsAndFormsCollectionsSearchConnection';
    edges: Maybe<Array<Maybe<CleTrueDocsAndFormsCollections>>>;
    pageInfo: PageInfo;
    totalCount: Maybe<Scalars['Int']['output']>;
  };

export type PagedTrueDocsAndFormsSearchConnection = PagedResultConnection & {
  __typename?: 'PagedTrueDocsAndFormsSearchConnection';
  edges: Maybe<Array<Maybe<CleTrueDocsAndForms>>>;
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type PayInvoiceInput = {
  /** The id of the invoice to pay */
  invoiceId: Scalars['ID']['input'];
  /** The id of the payment method to use to pay the invoice. If none is specified, the user's default payment method is used.     */
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  cardType: Maybe<Scalars['String']['output']>;
  expirationMonth: Maybe<Scalars['Int']['output']>;
  expirationYear: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  maskedCardNumber: Maybe<Scalars['String']['output']>;
};

export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  amount: Maybe<Scalars['Float']['output']>;
  daysDueAfterTerm: Maybe<Scalars['Int']['output']>;
  dueDateTimestamp: Maybe<Scalars['String']['output']>;
  outstandingBalance: Maybe<Scalars['Float']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type PracticeArea = {
  __typename?: 'PracticeArea';
  contentfulId: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

export type PracticeAreaLibrary = {
  __typename?: 'PracticeAreaLibrary';
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

export type PractitionerDocumentTypeaheadResult = {
  __typename?: 'PractitionerDocumentTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PractitionerSearchCaseTypeaheadResult = {
  __typename?: 'PractitionerSearchCaseTypeaheadResult';
  cite: Scalars['String']['output'];
  date: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PractitionerSearchEdge = {
  __typename?: 'PractitionerSearchEdge';
  node: PractitionerSearchNode;
  snippets: Maybe<Array<Maybe<Snippet>>>;
};

export type PractitionerSearchNode = {
  __typename?: 'PractitionerSearchNode';
  contentfulId: Maybe<Scalars['ID']['output']>;
  contentfulKnowHowDocument: Maybe<ContentfulKnowHowDocument>;
  doctype: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
};

export type ProductCodeWithQuantityInput = {
  productCode: Scalars['String']['input'];
  quantity: InputMaybe<Scalars['Float']['input']>;
};

export type PublicationContributionBook = {
  __typename?: 'PublicationContributionBook';
  name: Maybe<Scalars['String']['output']>;
  onlawProProductCode: Maybe<Scalars['String']['output']>;
  originalAuthor: Maybe<Scalars['Boolean']['output']>;
  primaryPracticeAreaLibrary: Maybe<PracticeAreaLibrary>;
  publicationContributionChapters: Maybe<
    Array<Maybe<PublicationContributionChapter>>
  >;
  publicationDate: Maybe<Scalars['DateTime']['output']>;
  updateAuthor: Maybe<Scalars['Boolean']['output']>;
};

export type PublicationContributionChapter = {
  __typename?: 'PublicationContributionChapter';
  name: Maybe<Scalars['String']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  originalAuthor: Maybe<Scalars['Boolean']['output']>;
  updateAuthor: Maybe<Scalars['Boolean']['output']>;
};

export type PublicationSearchNode = {
  __typename?: 'PublicationSearchNode';
  id: Maybe<Scalars['Int']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  secondarySourcesTitleParts: Maybe<Array<Maybe<SecondarySourcesTitleParts>>>;
};

export type PublicationSectionSearchEdge = {
  __typename?: 'PublicationSectionSearchEdge';
  breadcrumbs: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  missingTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  node: PublicationSearchNode;
  ordering: Maybe<Scalars['Int']['output']>;
  snippets: Maybe<Array<Maybe<Snippet>>>;
};

export type Query = {
  __typename?: 'Query';
  assets: Maybe<Array<Maybe<Asset>>>;
  bookmarkFolders: Maybe<Array<Maybe<BookmarkFolder>>>;
  bookmarks: Maybe<Array<Maybe<Bookmark>>>;
  caConstitutionHeading: Maybe<CaConstitutionHeading>;
  caConstitutionSection: Maybe<CaConstitutionSection>;
  californiaConstitution: Maybe<CaConstitutionCode>;
  /** Look up a case by Judicata ID */
  case: Maybe<Case>;
  casesById: Maybe<Array<Maybe<Case>>>;
  checkUserAvailability: Maybe<UserAvailabilityReport>;
  code: Maybe<Code>;
  codeHeading: Maybe<CodeHeading>;
  codeSection: Maybe<CodeSection>;
  confirmBarNumberMatch: Maybe<BarNumberCheckResult>;
  contentFavorites: Maybe<Array<Maybe<ContentFavorite>>>;
  contentfulAgency: Maybe<ContentfulAgency>;
  contentfulAgencyCollection: Maybe<ContentfulAgencyCollection>;
  contentfulAgreementsAndLicenses: Maybe<ContentfulAgreementsAndLicenses>;
  contentfulAgreementsAndLicensesCollection: Maybe<ContentfulAgreementsAndLicensesCollection>;
  contentfulAsset: Maybe<ContentfulAsset>;
  contentfulAssetCollection: Maybe<ContentfulAssetCollection>;
  contentfulBook: Maybe<ContentfulBook>;
  contentfulBookCollection: Maybe<ContentfulBookCollection>;
  contentfulChapter: Maybe<ContentfulChapter>;
  contentfulChapterCollection: Maybe<ContentfulChapterCollection>;
  contentfulContentPublisher: Maybe<ContentfulContentPublisher>;
  contentfulContentPublisherCollection: Maybe<ContentfulContentPublisherCollection>;
  contentfulContributor: Maybe<ContentfulContributor>;
  contentfulContributorCollection: Maybe<ContentfulContributorCollection>;
  contentfulContributorDirectory: Maybe<ContentfulContributorDirectory>;
  contentfulContributorDirectoryCollection: Maybe<ContentfulContributorDirectoryCollection>;
  contentfulContributorOrganization: Maybe<ContentfulContributorOrganization>;
  contentfulContributorOrganizationCollection: Maybe<ContentfulContributorOrganizationCollection>;
  contentfulCourse: Maybe<ContentfulCourse>;
  contentfulCourseCollection: Maybe<ContentfulCourseCollection>;
  contentfulCourseCreditCategory: Maybe<ContentfulCourseCreditCategory>;
  contentfulCourseCreditCategoryCollection: Maybe<ContentfulCourseCreditCategoryCollection>;
  contentfulCoursesCollection: Maybe<ContentfulCoursesCollection>;
  contentfulCoursesCollectionCollection: Maybe<ContentfulCoursesCollectionCollection>;
  contentfulCourt: Maybe<ContentfulCourt>;
  contentfulCourtCollection: Maybe<ContentfulCourtCollection>;
  contentfulCurrentAwareness: Maybe<ContentfulCurrentAwareness>;
  contentfulCurrentAwarenessCollection: Maybe<ContentfulCurrentAwarenessCollection>;
  contentfulDocumentType: Maybe<ContentfulDocumentType>;
  contentfulDocumentTypeCollection: Maybe<ContentfulDocumentTypeCollection>;
  contentfulEndMatter: Maybe<ContentfulEndMatter>;
  contentfulEndMatterCollection: Maybe<ContentfulEndMatterCollection>;
  contentfulEntryCollection: Maybe<ContentfulEntryCollection>;
  contentfulForm: Maybe<ContentfulForm>;
  contentfulFormCollection: Maybe<ContentfulFormCollection>;
  contentfulFormContainer: Maybe<ContentfulFormContainer>;
  contentfulFormContainerCollection: Maybe<ContentfulFormContainerCollection>;
  contentfulFormSource: Maybe<ContentfulFormSource>;
  contentfulFormSourceCollection: Maybe<ContentfulFormSourceCollection>;
  contentfulFormsProvider: Maybe<ContentfulFormsProvider>;
  contentfulFormsProviderCollection: Maybe<ContentfulFormsProviderCollection>;
  contentfulFrontMatter: Maybe<ContentfulFrontMatter>;
  contentfulFrontMatterCollection: Maybe<ContentfulFrontMatterCollection>;
  contentfulGraphAuthentication: Maybe<ContentfulGraphAuthentication>;
  contentfulGraphAuthenticationCollection: Maybe<ContentfulGraphAuthenticationCollection>;
  contentfulKnowHowDocument: Maybe<ContentfulKnowHowDocument>;
  contentfulKnowHowDocumentCollection: Maybe<ContentfulKnowHowDocumentCollection>;
  contentfulKnowHowProduct: Maybe<ContentfulKnowHowProduct>;
  contentfulKnowHowProductCollection: Maybe<ContentfulKnowHowProductCollection>;
  contentfulLegalTask: Maybe<ContentfulLegalTask>;
  contentfulLegalTaskCollection: Maybe<ContentfulLegalTaskCollection>;
  contentfulLooseleafBook: Maybe<ContentfulLooseleafBook>;
  contentfulLooseleafBookCollection: Maybe<ContentfulLooseleafBookCollection>;
  contentfulLooseleafChapter: Maybe<ContentfulLooseleafChapter>;
  contentfulLooseleafChapterCollection: Maybe<ContentfulLooseleafChapterCollection>;
  contentfulLooseleafEndMatter: Maybe<ContentfulLooseleafEndMatter>;
  contentfulLooseleafEndMatterCollection: Maybe<ContentfulLooseleafEndMatterCollection>;
  contentfulLooseleafFrontMatter: Maybe<ContentfulLooseleafFrontMatter>;
  contentfulLooseleafFrontMatterCollection: Maybe<ContentfulLooseleafFrontMatterCollection>;
  contentfulPlanOffering: Maybe<ContentfulPlanOffering>;
  contentfulPlanOfferingCollection: Maybe<ContentfulPlanOfferingCollection>;
  contentfulPlanOfferingOption: Maybe<ContentfulPlanOfferingOption>;
  contentfulPlanOfferingOptionCollection: Maybe<ContentfulPlanOfferingOptionCollection>;
  contentfulPracticeArea: Maybe<ContentfulPracticeArea>;
  contentfulPracticeAreaCollection: Maybe<ContentfulPracticeAreaCollection>;
  contentfulPractitionerWorkflow: Maybe<ContentfulPractitionerWorkflow>;
  contentfulPractitionerWorkflowCollection: Maybe<ContentfulPractitionerWorkflowCollection>;
  contentfulPractitionerWorkflowSectionGroup: Maybe<ContentfulPractitionerWorkflowSectionGroup>;
  contentfulPractitionerWorkflowSectionGroupCollection: Maybe<ContentfulPractitionerWorkflowSectionGroupCollection>;
  contentfulPractitionerWorkflowSectionItems: Maybe<ContentfulPractitionerWorkflowSectionItems>;
  contentfulPractitionerWorkflowSectionItemsCollection: Maybe<ContentfulPractitionerWorkflowSectionItemsCollection>;
  contentfulProgram: Maybe<ContentfulProgram>;
  contentfulProgramCollection: Maybe<ContentfulProgramCollection>;
  contentfulPublicationBook: Maybe<ContentfulPublicationBook>;
  contentfulPublicationBookCollection: Maybe<ContentfulPublicationBookCollection>;
  contentfulPublicationBookType: Maybe<ContentfulPublicationBookType>;
  contentfulPublicationBookTypeCollection: Maybe<ContentfulPublicationBookTypeCollection>;
  contentfulPublicationEdition: Maybe<ContentfulPublicationEdition>;
  contentfulPublicationEditionChapter: Maybe<ContentfulPublicationEditionChapter>;
  contentfulPublicationEditionChapterCollection: Maybe<ContentfulPublicationEditionChapterCollection>;
  contentfulPublicationEditionCollection: Maybe<ContentfulPublicationEditionCollection>;
  contentfulSimplePage: Maybe<ContentfulSimplePage>;
  contentfulSimplePageCollection: Maybe<ContentfulSimplePageCollection>;
  contentfulTopic: Maybe<ContentfulTopic>;
  contentfulTopicCollection: Maybe<ContentfulTopicCollection>;
  contentfulTrueDocsAndForms: Maybe<ContentfulTrueDocsAndForms>;
  contentfulTrueDocsAndFormsCollection: Maybe<ContentfulTrueDocsAndFormsCollection>;
  contentfulTrueDocsFormsCollections: Maybe<ContentfulTrueDocsFormsCollections>;
  contentfulTrueDocsFormsCollectionsCollection: Maybe<ContentfulTrueDocsFormsCollectionsCollection>;
  contentful_node: Maybe<Contentful_Node>;
  contributor: Maybe<CleContributor>;
  contributorOrganization: Maybe<CleContributorOrganization>;
  contributorSearch: Maybe<PagedContributorSearchConnection>;
  course: Maybe<CleCourse>;
  courseProgress: Maybe<Array<Maybe<CourseProgress>>>;
  courseSearch: Maybe<PagedCourseSearchConnection>;
  courseWatchlist: Maybe<Array<Maybe<WatchlistCourse>>>;
  coursesCollection: Maybe<CoursesCollection>;
  coursesCollectionSearch: Maybe<PagedCoursesCollectionSearchConnection>;
  currentAwarenessSearch: Maybe<PagedCurrentAwarenessConnection>;
  firm: Maybe<Firm>;
  firmInvitation: Maybe<FirmInvitation>;
  historyDates: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  historyItemsByDate: Maybe<Array<Maybe<HistoryItem>>>;
  historyItemsByType: Maybe<Array<Maybe<HistoryItem>>>;
  /** fetch data from the table: "attorneys" */
  matterServiceAttorneys: Array<MatterServiceAttorneys>;
  /** fetch aggregated fields from the table: "attorneys" */
  matterServiceAttorneys_aggregate: MatterServiceAttorneys_Aggregate;
  /** fetch data from the table: "attorneys" using primary key columns */
  matterServiceAttorneys_by_pk: Maybe<MatterServiceAttorneys>;
  /** fetch data from the table: "clients" */
  matterServiceClients: Array<MatterServiceClients>;
  /** fetch aggregated fields from the table: "clients" */
  matterServiceClients_aggregate: MatterServiceClients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  matterServiceClients_by_pk: Maybe<MatterServiceClients>;
  /** fetch data from the table: "courts" */
  matterServiceCourts: Array<MatterServiceCourts>;
  /** fetch aggregated fields from the table: "courts" */
  matterServiceCourts_aggregate: MatterServiceCourts_Aggregate;
  /** fetch data from the table: "courts" using primary key columns */
  matterServiceCourts_by_pk: Maybe<MatterServiceCourts>;
  /** fetch data from the table: "domesticPartnerships" */
  matterServiceDomesticPartnerships: Array<MatterServiceDomesticPartnerships>;
  /** fetch aggregated fields from the table: "domesticPartnerships" */
  matterServiceDomesticPartnerships_aggregate: MatterServiceDomesticPartnerships_Aggregate;
  /** fetch data from the table: "domesticPartnerships" using primary key columns */
  matterServiceDomesticPartnerships_by_pk: Maybe<MatterServiceDomesticPartnerships>;
  /** fetch data from the table: "entityAddressTypes" */
  matterServiceEntityAddressTypes: Array<MatterServiceEntityAddressTypes>;
  /** fetch aggregated fields from the table: "entityAddressTypes" */
  matterServiceEntityAddressTypes_aggregate: MatterServiceEntityAddressTypes_Aggregate;
  /** fetch data from the table: "entityAddressTypes" using primary key columns */
  matterServiceEntityAddressTypes_by_pk: Maybe<MatterServiceEntityAddressTypes>;
  /** fetch data from the table: "entityAddresses" */
  matterServiceEntityAddresses: Array<MatterServiceEntityAddresses>;
  /** fetch aggregated fields from the table: "entityAddresses" */
  matterServiceEntityAddresses_aggregate: MatterServiceEntityAddresses_Aggregate;
  /** fetch data from the table: "entityAddresses" using primary key columns */
  matterServiceEntityAddresses_by_pk: Maybe<MatterServiceEntityAddresses>;
  /** fetch data from the table: "entityEmailTypes" */
  matterServiceEntityEmailTypes: Array<MatterServiceEntityEmailTypes>;
  /** fetch aggregated fields from the table: "entityEmailTypes" */
  matterServiceEntityEmailTypes_aggregate: MatterServiceEntityEmailTypes_Aggregate;
  /** fetch data from the table: "entityEmailTypes" using primary key columns */
  matterServiceEntityEmailTypes_by_pk: Maybe<MatterServiceEntityEmailTypes>;
  /** fetch data from the table: "entityEmails" */
  matterServiceEntityEmails: Array<MatterServiceEntityEmails>;
  /** fetch aggregated fields from the table: "entityEmails" */
  matterServiceEntityEmails_aggregate: MatterServiceEntityEmails_Aggregate;
  /** fetch data from the table: "entityEmails" using primary key columns */
  matterServiceEntityEmails_by_pk: Maybe<MatterServiceEntityEmails>;
  /** fetch data from the table: "entityPhoneNumberTypes" */
  matterServiceEntityPhoneNumberTypes: Array<MatterServiceEntityPhoneNumberTypes>;
  /** fetch aggregated fields from the table: "entityPhoneNumberTypes" */
  matterServiceEntityPhoneNumberTypes_aggregate: MatterServiceEntityPhoneNumberTypes_Aggregate;
  /** fetch data from the table: "entityPhoneNumberTypes" using primary key columns */
  matterServiceEntityPhoneNumberTypes_by_pk: Maybe<MatterServiceEntityPhoneNumberTypes>;
  /** fetch data from the table: "entityPhoneNumbers" */
  matterServiceEntityPhoneNumbers: Array<MatterServiceEntityPhoneNumbers>;
  /** fetch aggregated fields from the table: "entityPhoneNumbers" */
  matterServiceEntityPhoneNumbers_aggregate: MatterServiceEntityPhoneNumbers_Aggregate;
  /** fetch data from the table: "entityPhoneNumbers" using primary key columns */
  matterServiceEntityPhoneNumbers_by_pk: Maybe<MatterServiceEntityPhoneNumbers>;
  /** fetch data from the table: "marriages" */
  matterServiceMarriages: Array<MatterServiceMarriages>;
  /** fetch aggregated fields from the table: "marriages" */
  matterServiceMarriages_aggregate: MatterServiceMarriages_Aggregate;
  /** fetch data from the table: "marriages" using primary key columns */
  matterServiceMarriages_by_pk: Maybe<MatterServiceMarriages>;
  /** fetch data from the table: "matterAttorneyRoleTypes" */
  matterServiceMatterAttorneyRoleTypes: Array<MatterServiceMatterAttorneyRoleTypes>;
  /** fetch aggregated fields from the table: "matterAttorneyRoleTypes" */
  matterServiceMatterAttorneyRoleTypes_aggregate: MatterServiceMatterAttorneyRoleTypes_Aggregate;
  /** fetch data from the table: "matterAttorneyRoleTypes" using primary key columns */
  matterServiceMatterAttorneyRoleTypes_by_pk: Maybe<MatterServiceMatterAttorneyRoleTypes>;
  /** fetch data from the table: "matterDocsAndForms" */
  matterServiceMatterDocsAndForms: Array<MatterServiceMatterDocsAndForms>;
  /** fetch data from the table: "matterDocsAndFormsFolders" */
  matterServiceMatterDocsAndFormsFolders: Array<MatterServiceMatterDocsAndFormsFolders>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsFolders" */
  matterServiceMatterDocsAndFormsFolders_aggregate: MatterServiceMatterDocsAndFormsFolders_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsFolders" using primary key columns */
  matterServiceMatterDocsAndFormsFolders_by_pk: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  /** fetch data from the table: "matterDocsAndFormsStatuses" */
  matterServiceMatterDocsAndFormsStatuses: Array<MatterServiceMatterDocsAndFormsStatuses>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsStatuses" */
  matterServiceMatterDocsAndFormsStatuses_aggregate: MatterServiceMatterDocsAndFormsStatuses_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsStatuses" using primary key columns */
  matterServiceMatterDocsAndFormsStatuses_by_pk: Maybe<MatterServiceMatterDocsAndFormsStatuses>;
  /** fetch data from the table: "matterDocsAndFormsTypes" */
  matterServiceMatterDocsAndFormsTypes: Array<MatterServiceMatterDocsAndFormsTypes>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsTypes" */
  matterServiceMatterDocsAndFormsTypes_aggregate: MatterServiceMatterDocsAndFormsTypes_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsTypes" using primary key columns */
  matterServiceMatterDocsAndFormsTypes_by_pk: Maybe<MatterServiceMatterDocsAndFormsTypes>;
  /** fetch aggregated fields from the table: "matterDocsAndForms" */
  matterServiceMatterDocsAndForms_aggregate: MatterServiceMatterDocsAndForms_Aggregate;
  /** fetch data from the table: "matterDocsAndForms" using primary key columns */
  matterServiceMatterDocsAndForms_by_pk: Maybe<MatterServiceMatterDocsAndForms>;
  /** An array relationship */
  matterServiceMatterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterServiceMatterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  /** fetch data from the table: "matterEntities" using primary key columns */
  matterServiceMatterEntities_by_pk: Maybe<MatterServiceMatterEntities>;
  /** fetch data from the table: "matterEntityTypes" */
  matterServiceMatterEntityTypes: Array<MatterServiceMatterEntityTypes>;
  /** fetch aggregated fields from the table: "matterEntityTypes" */
  matterServiceMatterEntityTypes_aggregate: MatterServiceMatterEntityTypes_Aggregate;
  /** fetch data from the table: "matterEntityTypes" using primary key columns */
  matterServiceMatterEntityTypes_by_pk: Maybe<MatterServiceMatterEntityTypes>;
  /** fetch data from the table: "matterFilingForTypes" */
  matterServiceMatterFilingForTypes: Array<MatterServiceMatterFilingForTypes>;
  /** fetch aggregated fields from the table: "matterFilingForTypes" */
  matterServiceMatterFilingForTypes_aggregate: MatterServiceMatterFilingForTypes_Aggregate;
  /** fetch data from the table: "matterFilingForTypes" using primary key columns */
  matterServiceMatterFilingForTypes_by_pk: Maybe<MatterServiceMatterFilingForTypes>;
  /** fetch data from the table: "matterOrganizationRoleTypes" */
  matterServiceMatterOrganizationRoleTypes: Array<MatterServiceMatterOrganizationRoleTypes>;
  /** fetch aggregated fields from the table: "matterOrganizationRoleTypes" */
  matterServiceMatterOrganizationRoleTypes_aggregate: MatterServiceMatterOrganizationRoleTypes_Aggregate;
  /** fetch data from the table: "matterOrganizationRoleTypes" using primary key columns */
  matterServiceMatterOrganizationRoleTypes_by_pk: Maybe<MatterServiceMatterOrganizationRoleTypes>;
  /** fetch data from the table: "matterPersonRoleTypes" */
  matterServiceMatterPersonRoleTypes: Array<MatterServiceMatterPersonRoleTypes>;
  /** fetch aggregated fields from the table: "matterPersonRoleTypes" */
  matterServiceMatterPersonRoleTypes_aggregate: MatterServiceMatterPersonRoleTypes_Aggregate;
  /** fetch data from the table: "matterPersonRoleTypes" using primary key columns */
  matterServiceMatterPersonRoleTypes_by_pk: Maybe<MatterServiceMatterPersonRoleTypes>;
  /** fetch data from the table: "matterTypes" */
  matterServiceMatterTypes: Array<MatterServiceMatterTypes>;
  /** fetch aggregated fields from the table: "matterTypes" */
  matterServiceMatterTypes_aggregate: MatterServiceMatterTypes_Aggregate;
  /** fetch data from the table: "matterTypes" using primary key columns */
  matterServiceMatterTypes_by_pk: Maybe<MatterServiceMatterTypes>;
  /** An array relationship */
  matterServiceMatters: Array<MatterServiceMatters>;
  /** An aggregate relationship */
  matterServiceMatters_aggregate: MatterServiceMatters_Aggregate;
  /** fetch data from the table: "matters" using primary key columns */
  matterServiceMatters_by_pk: Maybe<MatterServiceMatters>;
  /** fetch data from the table: "organizations" */
  matterServiceOrganizations: Array<MatterServiceOrganizations>;
  /** fetch aggregated fields from the table: "organizations" */
  matterServiceOrganizations_aggregate: MatterServiceOrganizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  matterServiceOrganizations_by_pk: Maybe<MatterServiceOrganizations>;
  /** fetch data from the table: "people" */
  matterServicePeople: Array<MatterServicePeople>;
  /** fetch data from the table: "peopleGenders" */
  matterServicePeopleGenders: Array<MatterServicePeopleGenders>;
  /** fetch aggregated fields from the table: "peopleGenders" */
  matterServicePeopleGenders_aggregate: MatterServicePeopleGenders_Aggregate;
  /** fetch data from the table: "peopleGenders" using primary key columns */
  matterServicePeopleGenders_by_pk: Maybe<MatterServicePeopleGenders>;
  /** fetch aggregated fields from the table: "people" */
  matterServicePeople_aggregate: MatterServicePeople_Aggregate;
  /** fetch data from the table: "people" using primary key columns */
  matterServicePeople_by_pk: Maybe<MatterServicePeople>;
  /** fetch data from the table: "temporaryRestrainingOrders" */
  matterServiceTemporaryRestrainingOrders: Array<MatterServiceTemporaryRestrainingOrders>;
  /** fetch aggregated fields from the table: "temporaryRestrainingOrders" */
  matterServiceTemporaryRestrainingOrders_aggregate: MatterServiceTemporaryRestrainingOrders_Aggregate;
  /** fetch data from the table: "temporaryRestrainingOrders" using primary key columns */
  matterServiceTemporaryRestrainingOrders_by_pk: Maybe<MatterServiceTemporaryRestrainingOrders>;
  organizationTypes: Maybe<Array<Maybe<OrgType>>>;
  /** Run a search against Judicata content */
  search: Maybe<SearchResultConnection>;
  /** Retrieve the filter values for a particular search filter from Judicata */
  searchFilter: Maybe<SearchFilter>;
  secondaryPracticeAreaOptions: Maybe<Array<Maybe<SelectOption>>>;
  /** fetch data from the table: "collections" */
  secondarySourcesCollections: Array<SecondarySourcesCollections>;
  /** fetch aggregated fields from the table: "collections" */
  secondarySourcesCollections_aggregate: SecondarySourcesCollections_Aggregate;
  /** fetch data from the table: "collections" using primary key columns */
  secondarySourcesCollections_by_pk: Maybe<SecondarySourcesCollections>;
  /** fetch data from the table: "documentParts" */
  secondarySourcesDocumentParts: Array<SecondarySourcesDocumentParts>;
  /** fetch aggregated fields from the table: "documentParts" */
  secondarySourcesDocumentParts_aggregate: SecondarySourcesDocumentParts_Aggregate;
  /** fetch data from the table: "documentParts" using primary key columns */
  secondarySourcesDocumentParts_by_pk: Maybe<SecondarySourcesDocumentParts>;
  /** fetch data from the table: "documents" */
  secondarySourcesDocuments: Array<SecondarySourcesDocuments>;
  /** fetch aggregated fields from the table: "documents" */
  secondarySourcesDocuments_aggregate: SecondarySourcesDocuments_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  secondarySourcesDocuments_by_pk: Maybe<SecondarySourcesDocuments>;
  /** fetch data from the table: "imports" */
  secondarySourcesImports: Array<SecondarySourcesImports>;
  /** fetch aggregated fields from the table: "imports" */
  secondarySourcesImports_aggregate: SecondarySourcesImports_Aggregate;
  /** fetch data from the table: "imports" using primary key columns */
  secondarySourcesImports_by_pk: Maybe<SecondarySourcesImports>;
  /** An array relationship */
  secondarySourcesPracticeAreaTitles: Array<SecondarySourcesPracticeAreaTitles>;
  /** An aggregate relationship */
  secondarySourcesPracticeAreaTitles_aggregate: SecondarySourcesPracticeAreaTitles_Aggregate;
  /** fetch data from the table: "practiceAreaTitles" using primary key columns */
  secondarySourcesPracticeAreaTitles_by_pk: Maybe<SecondarySourcesPracticeAreaTitles>;
  /** An array relationship */
  secondarySourcesPracticeAreas: Array<SecondarySourcesPracticeAreas>;
  /** An aggregate relationship */
  secondarySourcesPracticeAreas_aggregate: SecondarySourcesPracticeAreas_Aggregate;
  /** fetch data from the table: "practiceAreas" using primary key columns */
  secondarySourcesPracticeAreas_by_pk: Maybe<SecondarySourcesPracticeAreas>;
  /** An array relationship */
  secondarySourcesResourceTypes: Array<SecondarySourcesResourceTypes>;
  /** An aggregate relationship */
  secondarySourcesResourceTypes_aggregate: SecondarySourcesResourceTypes_Aggregate;
  /** fetch data from the table: "resourceTypes" using primary key columns */
  secondarySourcesResourceTypes_by_pk: Maybe<SecondarySourcesResourceTypes>;
  /** An array relationship */
  secondarySourcesTitleParts: Array<SecondarySourcesTitleParts>;
  /** An aggregate relationship */
  secondarySourcesTitleParts_aggregate: SecondarySourcesTitleParts_Aggregate;
  /** fetch data from the table: "titleParts" using primary key columns */
  secondarySourcesTitleParts_by_pk: Maybe<SecondarySourcesTitleParts>;
  /** An array relationship */
  secondarySourcesTitles: Array<SecondarySourcesTitles>;
  /** An aggregate relationship */
  secondarySourcesTitles_aggregate: SecondarySourcesTitles_Aggregate;
  /** fetch data from the table: "titles" using primary key columns */
  secondarySourcesTitles_by_pk: Maybe<SecondarySourcesTitles>;
  /** Retrieve the latest version of the current user's session token */
  session: Maybe<Session>;
  /** Look up a statute by jurisdiction */
  statute: Maybe<Statute>;
  /** List all statutes */
  statutes: Maybe<Array<Maybe<Statute>>>;
  subscriptionPreview: Maybe<Subscription>;
  trueDocsAndFormsCollectionsSearch: Maybe<PagedTrueDocsAndFormsCollectionsSearchConnection>;
  trueDocsAndFormsSearch: Maybe<PagedTrueDocsAndFormsSearchConnection>;
  typeaheadCache: Maybe<Array<Maybe<TypeaheadCacheResult>>>;
  typeaheadSearch: Maybe<Array<Maybe<TypeaheadSearchResult>>>;
  user: Maybe<User>;
  validateAcademicSchoolCode: Maybe<SchoolCodeValidation>;
  validateCoupon: Maybe<CouponValidation>;
};

export type QueryBookmarksArgs = {
  bookmarkFolderId: InputMaybe<Scalars['ID']['input']>;
  paths: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCaConstitutionHeadingArgs = {
  article: Scalars['String']['input'];
};

export type QueryCaConstitutionSectionArgs = {
  article: Scalars['String']['input'];
  fromQuery: InputMaybe<Scalars['String']['input']>;
  judicataId: Scalars['String']['input'];
};

export type QueryCaseArgs = {
  fromQuery: InputMaybe<Scalars['String']['input']>;
  judicataId: Scalars['String']['input'];
};

export type QueryCasesByIdArgs = {
  fromQuery: InputMaybe<Scalars['String']['input']>;
  judicataIds: Array<Scalars['String']['input']>;
};

export type QueryCheckUserAvailabilityArgs = {
  barNumber: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type QueryCodeArgs = {
  judicataId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type QueryCodeHeadingArgs = {
  codeJudicataId: Scalars['String']['input'];
  judicataIdBegin: Scalars['String']['input'];
  judicataIdEnd: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type QueryCodeSectionArgs = {
  codeJudicataId: Scalars['String']['input'];
  fromQuery: InputMaybe<Scalars['String']['input']>;
  judicataId: Scalars['String']['input'];
  jurisdictionId: Scalars['String']['input'];
};

export type QueryConfirmBarNumberMatchArgs = {
  barNumber: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type QueryContentFavoritesArgs = {
  type: InputMaybe<ContentFavoriteType>;
};

export type QueryContentfulAgencyArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulAgencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulAgencyOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulAgencyFilter>;
};

export type QueryContentfulAgreementsAndLicensesArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulAgreementsAndLicensesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulAgreementsAndLicensesOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulAgreementsAndLicensesFilter>;
};

export type QueryContentfulAssetArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulAssetOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulAssetFilter>;
};

export type QueryContentfulBookArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulBookOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulBookFilter>;
};

export type QueryContentfulChapterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulChapterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulChapterFilter>;
};

export type QueryContentfulContentPublisherArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulContentPublisherCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulContentPublisherOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContentPublisherFilter>;
};

export type QueryContentfulContributorArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulContributorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulContributorOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorFilter>;
};

export type QueryContentfulContributorDirectoryArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulContributorDirectoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulContributorDirectoryOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorDirectoryFilter>;
};

export type QueryContentfulContributorOrganizationArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulContributorOrganizationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulContributorOrganizationOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulContributorOrganizationFilter>;
};

export type QueryContentfulCourseArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulCourseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCourseOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseFilter>;
};

export type QueryContentfulCourseCreditCategoryArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulCourseCreditCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCourseCreditCategoryOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourseCreditCategoryFilter>;
};

export type QueryContentfulCoursesCollectionArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulCoursesCollectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCoursesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCoursesCollectionFilter>;
};

export type QueryContentfulCourtArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulCourtCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCourtOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCourtFilter>;
};

export type QueryContentfulCurrentAwarenessArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulCurrentAwarenessCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulCurrentAwarenessOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulCurrentAwarenessFilter>;
};

export type QueryContentfulDocumentTypeArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulDocumentTypeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulDocumentTypeOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulDocumentTypeFilter>;
};

export type QueryContentfulEndMatterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulEndMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulEndMatterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulEndMatterFilter>;
};

export type QueryContentfulEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulEntryOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulEntryFilter>;
};

export type QueryContentfulFormArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFormOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFormFilter>;
};

export type QueryContentfulFormContainerArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulFormContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFormContainerOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFormContainerFilter>;
};

export type QueryContentfulFormSourceArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulFormSourceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFormSourceOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFormSourceFilter>;
};

export type QueryContentfulFormsProviderArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulFormsProviderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFormsProviderOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFormsProviderFilter>;
};

export type QueryContentfulFrontMatterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulFrontMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulFrontMatterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulFrontMatterFilter>;
};

export type QueryContentfulGraphAuthenticationArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulGraphAuthenticationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulGraphAuthenticationOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulGraphAuthenticationFilter>;
};

export type QueryContentfulKnowHowDocumentArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulKnowHowDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulKnowHowDocumentOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulKnowHowDocumentFilter>;
};

export type QueryContentfulKnowHowProductArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulKnowHowProductCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulKnowHowProductOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulKnowHowProductFilter>;
};

export type QueryContentfulLegalTaskArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulLegalTaskCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulLegalTaskOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLegalTaskFilter>;
};

export type QueryContentfulLooseleafBookArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulLooseleafBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulLooseleafBookOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafBookFilter>;
};

export type QueryContentfulLooseleafChapterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulLooseleafChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulLooseleafChapterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafChapterFilter>;
};

export type QueryContentfulLooseleafEndMatterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulLooseleafEndMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulLooseleafEndMatterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafEndMatterFilter>;
};

export type QueryContentfulLooseleafFrontMatterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulLooseleafFrontMatterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulLooseleafFrontMatterOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulLooseleafFrontMatterFilter>;
};

export type QueryContentfulPlanOfferingArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPlanOfferingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPlanOfferingFilter>;
};

export type QueryContentfulPlanOfferingOptionArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPlanOfferingOptionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPlanOfferingOptionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPlanOfferingOptionFilter>;
};

export type QueryContentfulPracticeAreaArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPracticeAreaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPracticeAreaOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPracticeAreaFilter>;
};

export type QueryContentfulPractitionerWorkflowArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPractitionerWorkflowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPractitionerWorkflowOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPractitionerWorkflowFilter>;
};

export type QueryContentfulPractitionerWorkflowSectionGroupArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPractitionerWorkflowSectionGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionGroupOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPractitionerWorkflowSectionGroupFilter>;
};

export type QueryContentfulPractitionerWorkflowSectionItemsArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPractitionerWorkflowSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPractitionerWorkflowSectionItemsOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPractitionerWorkflowSectionItemsFilter>;
};

export type QueryContentfulProgramArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulProgramCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulProgramOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulProgramFilter>;
};

export type QueryContentfulPublicationBookArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPublicationBookCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPublicationBookOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationBookFilter>;
};

export type QueryContentfulPublicationBookTypeArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPublicationBookTypeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPublicationBookTypeOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationBookTypeFilter>;
};

export type QueryContentfulPublicationEditionArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPublicationEditionChapterArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulPublicationEditionChapterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<
    Array<InputMaybe<ContentfulPublicationEditionChapterOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationEditionChapterFilter>;
};

export type QueryContentfulPublicationEditionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulPublicationEditionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulPublicationEditionFilter>;
};

export type QueryContentfulSimplePageArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulSimplePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulSimplePageOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulSimplePageFilter>;
};

export type QueryContentfulTopicArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulTopicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulTopicOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulTopicFilter>;
};

export type QueryContentfulTrueDocsAndFormsArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulTrueDocsAndFormsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulTrueDocsAndFormsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulTrueDocsAndFormsFilter>;
};

export type QueryContentfulTrueDocsFormsCollectionsArgs = {
  id: Scalars['String']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContentfulTrueDocsFormsCollectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Array<InputMaybe<ContentfulTrueDocsFormsCollectionsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentfulTrueDocsFormsCollectionsFilter>;
};

export type QueryContentful_NodeArgs = {
  id: Scalars['ID']['input'];
  locale: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContributorArgs = {
  slug: Scalars['String']['input'];
};

export type QueryContributorOrganizationArgs = {
  slug: Scalars['String']['input'];
};

export type QueryContributorSearchArgs = {
  filters: InputMaybe<ContributorSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryCourseArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCourseProgressArgs = {
  contentfulCourseIds: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryCourseSearchArgs = {
  filters: InputMaybe<CourseSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryCoursesCollectionArgs = {
  compliancePackageProductCode: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

export type QueryCoursesCollectionSearchArgs = {
  filters: InputMaybe<CoursesCollectionSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryCurrentAwarenessSearchArgs = {
  filters: InputMaybe<CurrentAwarenessSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryFirmInvitationArgs = {
  code: Scalars['String']['input'];
};

export type QueryHistoryDatesArgs = {
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type QueryHistoryItemsByDateArgs = {
  endDate: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type QueryHistoryItemsByTypeArgs = {
  action: HistoryAction;
  limit: Scalars['Int']['input'];
  type: HistoryType;
};

export type QueryMatterServiceAttorneysArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

export type QueryMatterServiceAttorneys_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

export type QueryMatterServiceAttorneys_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceClientsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceClients_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceClients_Order_By>>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

export type QueryMatterServiceClients_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceClients_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceClients_Order_By>>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

export type QueryMatterServiceClients_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceCourtsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceCourts_Order_By>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

export type QueryMatterServiceCourts_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceCourts_Order_By>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

export type QueryMatterServiceCourts_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceDomesticPartnershipsArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceDomesticPartnerships_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceDomesticPartnerships_Order_By>>;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

export type QueryMatterServiceDomesticPartnerships_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceDomesticPartnerships_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceDomesticPartnerships_Order_By>>;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

export type QueryMatterServiceDomesticPartnerships_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceEntityAddressTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddressTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddressTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityAddressTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddressTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddressTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityAddressTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceEntityAddressesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

export type QueryMatterServiceEntityAddresses_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

export type QueryMatterServiceEntityAddresses_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceEntityEmailTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmailTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmailTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityEmailTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmailTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmailTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityEmailTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceEntityEmailsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

export type QueryMatterServiceEntityEmails_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

export type QueryMatterServiceEntityEmails_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceEntityPhoneNumberTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceEntityPhoneNumberTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityPhoneNumberTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceEntityPhoneNumberTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

export type QueryMatterServiceEntityPhoneNumberTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceEntityPhoneNumbersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

export type QueryMatterServiceEntityPhoneNumbers_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

export type QueryMatterServiceEntityPhoneNumbers_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceMarriagesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMarriages_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMarriages_Order_By>>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

export type QueryMatterServiceMarriages_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMarriages_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMarriages_Order_By>>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

export type QueryMatterServiceMarriages_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceMatterAttorneyRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterAttorneyRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterAttorneyRoleTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterAttorneyRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterAttorneyRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterDocsAndFormsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsFoldersArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsFolders_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsFolders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceMatterDocsAndFormsStatusesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsStatuses_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsStatuses_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsStatuses_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsStatuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterDocsAndFormsTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndFormsTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterDocsAndForms_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

export type QueryMatterServiceMatterDocsAndForms_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

export type QueryMatterServiceMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

export type QueryMatterServiceMatterEntities_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceMatterEntityTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntityTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntityTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterEntityTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntityTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntityTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterEntityTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterFilingForTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterFilingForTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterFilingForTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterFilingForTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterFilingForTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterFilingForTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterFilingForTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterOrganizationRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Order_By>
  >;
  where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterOrganizationRoleTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Order_By>
  >;
  where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterOrganizationRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterPersonRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterPersonRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterPersonRoleTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterPersonRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterPersonRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMatterTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

export type QueryMatterServiceMatterTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServiceMattersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

export type QueryMatterServiceMatters_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

export type QueryMatterServiceMatters_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceOrganizationsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceOrganizations_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceOrganizations_Order_By>>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

export type QueryMatterServiceOrganizations_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceOrganizations_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceOrganizations_Order_By>>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

export type QueryMatterServiceOrganizations_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServicePeopleArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

export type QueryMatterServicePeopleGendersArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeopleGenders_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeopleGenders_Order_By>>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

export type QueryMatterServicePeopleGenders_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeopleGenders_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeopleGenders_Order_By>>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

export type QueryMatterServicePeopleGenders_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type QueryMatterServicePeople_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

export type QueryMatterServicePeople_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QueryMatterServiceTemporaryRestrainingOrdersArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceTemporaryRestrainingOrders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceTemporaryRestrainingOrders_Order_By>>;
  where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
};

export type QueryMatterServiceTemporaryRestrainingOrders_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceTemporaryRestrainingOrders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceTemporaryRestrainingOrders_Order_By>>;
  where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
};

export type QueryMatterServiceTemporaryRestrainingOrders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type QuerySearchArgs = {
  filters: InputMaybe<SearchFilterInput>;
  page: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<SearchSortType>;
  type: SearchType;
};

export type QuerySearchFilterArgs = {
  category: SearchFilterCategory;
};

export type QuerySecondarySourcesCollectionsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesCollections_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesCollections_Order_By>>;
  where: InputMaybe<SecondarySourcesCollections_Bool_Exp>;
};

export type QuerySecondarySourcesCollections_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesCollections_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesCollections_Order_By>>;
  where: InputMaybe<SecondarySourcesCollections_Bool_Exp>;
};

export type QuerySecondarySourcesCollections_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesDocumentPartsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

export type QuerySecondarySourcesDocumentParts_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

export type QuerySecondarySourcesDocumentParts_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesDocumentsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocuments_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocuments_Order_By>>;
  where: InputMaybe<SecondarySourcesDocuments_Bool_Exp>;
};

export type QuerySecondarySourcesDocuments_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocuments_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocuments_Order_By>>;
  where: InputMaybe<SecondarySourcesDocuments_Bool_Exp>;
};

export type QuerySecondarySourcesDocuments_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesImportsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesImports_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesImports_Order_By>>;
  where: InputMaybe<SecondarySourcesImports_Bool_Exp>;
};

export type QuerySecondarySourcesImports_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesImports_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesImports_Order_By>>;
  where: InputMaybe<SecondarySourcesImports_Bool_Exp>;
};

export type QuerySecondarySourcesImports_By_PkArgs = {
  id: Scalars['SecondarySourcesBigint']['input'];
};

export type QuerySecondarySourcesPracticeAreaTitlesArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

export type QuerySecondarySourcesPracticeAreaTitles_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

export type QuerySecondarySourcesPracticeAreaTitles_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesPracticeAreasArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreas_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
};

export type QuerySecondarySourcesPracticeAreas_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreas_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
};

export type QuerySecondarySourcesPracticeAreas_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesResourceTypesArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesResourceTypes_Order_By>>;
  where: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
};

export type QuerySecondarySourcesResourceTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesResourceTypes_Order_By>>;
  where: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
};

export type QuerySecondarySourcesResourceTypes_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySecondarySourcesTitlePartsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

export type QuerySecondarySourcesTitleParts_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

export type QuerySecondarySourcesTitleParts_By_PkArgs = {
  contentId: Scalars['String']['input'];
};

export type QuerySecondarySourcesTitlesArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

export type QuerySecondarySourcesTitles_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

export type QuerySecondarySourcesTitles_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type QueryStatuteArgs = {
  jurisdictionId: Scalars['String']['input'];
};

export type QuerySubscriptionPreviewArgs = {
  input: SubscriptionPreviewInput;
};

export type QueryTrueDocsAndFormsCollectionsSearchArgs = {
  filters: InputMaybe<TrueDocsAndFormsSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryTrueDocsAndFormsSearchArgs = {
  filters: InputMaybe<TrueDocsAndFormsSearchFilterInput>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<Scalars['String']['input']>;
};

export type QueryTypeaheadSearchArgs = {
  query: Scalars['String']['input'];
  types: Array<TypeaheadSearchType>;
};

export type QueryValidateAcademicSchoolCodeArgs = {
  code: Scalars['NonEmptyString']['input'];
};

export type QueryValidateCouponArgs = {
  couponCode: Scalars['String']['input'];
  planCode: Scalars['String']['input'];
};

export type ReplaceSubscriptionInput = {
  couponCode: InputMaybe<Scalars['String']['input']>;
  interval: SubscriptionInterval;
  planCode: Scalars['String']['input'];
  productCodes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCodesWithQuantities: InputMaybe<
    Array<InputMaybe<ProductCodeWithQuantityInput>>
  >;
  subscriptionId: Scalars['ID']['input'];
};

export type SchoolCodeValidation = {
  __typename?: 'SchoolCodeValidation';
  schoolName: Maybe<Scalars['String']['output']>;
};

export type SearchFeedback = {
  __typename?: 'SearchFeedback';
  correctedQuery: Maybe<Scalars['String']['output']>;
  prompt: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type SearchFilter = {
  __typename?: 'SearchFilter';
  categoryId: Maybe<Scalars['String']['output']>;
  children: Maybe<Array<Maybe<FilterOption>>>;
  name: Maybe<Scalars['String']['output']>;
  needsMore: Maybe<Scalars['Boolean']['output']>;
};

export enum SearchFilterCategory {
  /** Case: Appellant's Trial Court Role */
  CaseAppellantTrialCourtRole = 'CASE_APPELLANT_TRIAL_COURT_ROLE',
  /** Case: Cause of Action */
  CaseCauseOfAction = 'CASE_CAUSE_OF_ACTION',
  /** Case: Court (Including District and Division) */
  CaseCourt = 'CASE_COURT',
  /** Case: Disposition */
  CaseDisposition = 'CASE_DISPOSITION',
  /** Case: Level of Analysis (for Citation Search) */
  CaseLevelOfAnalysis = 'CASE_LEVEL_OF_ANALYSIS',
  /** Case: Procedural Posture */
  CaseProceduralPosture = 'CASE_PROCEDURAL_POSTURE',
  /** Case: Treatment */
  CaseTreatment = 'CASE_TREATMENT',
  CaseYear = 'CASE_YEAR',
  PractitionerCourt = 'PRACTITIONER_COURT',
  PractitionerDocumentType = 'PRACTITIONER_DOCUMENT_TYPE',
  PractitionerLegalTask = 'PRACTITIONER_LEGAL_TASK',
  PractitionerPracticeArea = 'PRACTITIONER_PRACTICE_AREA',
  /** Secondary Sources: Practice Area for Publication */
  PublicationPracticeArea = 'PUBLICATION_PRACTICE_AREA',
  /** Secondary Sources: Resource Type for Publication */
  PublicationResourceType = 'PUBLICATION_RESOURCE_TYPE',
  /** Secondary Sources: Title for Publication */
  PublicationTitle = 'PUBLICATION_TITLE',
  /** Statute: California Code List */
  StatuteCaliforniaCode = 'STATUTE_CALIFORNIA_CODE',
}

/** Filter options for a Judicata search */
export type SearchFilterInput = {
  /** Filters Case results by years greater than the supplied value */
  caseDate_gte: InputMaybe<Scalars['Year']['input']>;
  /** Filters Case results by years less than the supplied value */
  caseDate_lte: InputMaybe<Scalars['Year']['input']>;
  /** Filter Case results by their publication status */
  casePublicationStatus: InputMaybe<CasePublicationStatus>;
  /** Filter Case results by ID */
  citedCaseJudicataId: InputMaybe<Scalars['String']['input']>;
  citedCodeSectionJudicataId: InputMaybe<Scalars['Int']['input']>;
  optionFilters: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  statuteReference: InputMaybe<StatuteReferenceInput>;
  textFilters: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SearchResultConnection = {
  __typename?: 'SearchResultConnection';
  edges: Maybe<Array<Maybe<SearchResultEdge>>>;
  feedback: Maybe<SearchFeedback>;
  pageInfo: PageInfo;
  searchFilter: Maybe<SearchFilter>;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type SearchResultConnectionSearchFilterArgs = {
  category: SearchFilterCategory;
};

export type SearchResultEdge =
  | CaseSearchEdge
  | GenericSearchEdge
  | PractitionerSearchEdge
  | PublicationSectionSearchEdge
  | StatuteSearchEdge;

/** The field to sort on in Judicata search results. */
export enum SearchSortType {
  Date = 'DATE',
  Relevance = 'RELEVANCE',
  UpdatedAt = 'UPDATED_AT',
}

/** Judicata search type */
export enum SearchType {
  Case = 'CASE',
  Generic = 'GENERIC',
  Practitioner = 'PRACTITIONER',
  Publication = 'PUBLICATION',
  Statute = 'STATUTE',
}

export type SecondaryPracticeAreas = {
  __typename?: 'SecondaryPracticeAreas';
  options: Maybe<Array<Maybe<SelectOption>>>;
  selected: Maybe<Scalars['String']['output']>;
  selectedContentfulIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type SecondarySourcesBigint_Comparison_Exp = {
  _eq: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _gt: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _gte: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _in: InputMaybe<Array<Scalars['SecondarySourcesBigint']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _lte: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _neq: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  _nin: InputMaybe<Array<Scalars['SecondarySourcesBigint']['input']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type SecondarySourcesBoolean_Comparison_Exp = {
  _eq: InputMaybe<Scalars['Boolean']['input']>;
  _gt: InputMaybe<Scalars['Boolean']['input']>;
  _gte: InputMaybe<Scalars['Boolean']['input']>;
  _in: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Boolean']['input']>;
  _lte: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<Scalars['Boolean']['input']>;
  _nin: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "collections" */
export type SecondarySourcesCollections = {
  __typename?: 'SecondarySourcesCollections';
  collectionId: Scalars['String']['output'];
  collectionTitle: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

/** aggregated selection of "collections" */
export type SecondarySourcesCollections_Aggregate = {
  __typename?: 'SecondarySourcesCollections_aggregate';
  aggregate: Maybe<SecondarySourcesCollections_Aggregate_Fields>;
  nodes: Array<SecondarySourcesCollections>;
};

/** aggregate fields of "collections" */
export type SecondarySourcesCollections_Aggregate_Fields = {
  __typename?: 'SecondarySourcesCollections_aggregate_fields';
  avg: Maybe<SecondarySourcesCollections_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesCollections_Max_Fields>;
  min: Maybe<SecondarySourcesCollections_Min_Fields>;
  stddev: Maybe<SecondarySourcesCollections_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesCollections_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesCollections_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesCollections_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesCollections_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesCollections_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesCollections_Variance_Fields>;
};

/** aggregate fields of "collections" */
export type SecondarySourcesCollections_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesCollections_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type SecondarySourcesCollections_Avg_Fields = {
  __typename?: 'SecondarySourcesCollections_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "collections". All fields are combined with a logical 'AND'. */
export type SecondarySourcesCollections_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesCollections_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesCollections_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesCollections_Bool_Exp>>;
  collectionId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  collectionTitle: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
};

/** unique or primary key constraints on table "collections" */
export enum SecondarySourcesCollections_Constraint {
  /** unique or primary key constraint on columns "collectionId" */
  CollectionsCollectionIdKey = 'collections_collectionId_key',
  /** unique or primary key constraint on columns "id" */
  CollectionsPkey = 'collections_pkey',
}

/** input type for incrementing numeric columns in table "collections" */
export type SecondarySourcesCollections_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "collections" */
export type SecondarySourcesCollections_Insert_Input = {
  collectionId: InputMaybe<Scalars['String']['input']>;
  collectionTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type SecondarySourcesCollections_Max_Fields = {
  __typename?: 'SecondarySourcesCollections_max_fields';
  collectionId: Maybe<Scalars['String']['output']>;
  collectionTitle: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type SecondarySourcesCollections_Min_Fields = {
  __typename?: 'SecondarySourcesCollections_min_fields';
  collectionId: Maybe<Scalars['String']['output']>;
  collectionTitle: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "collections" */
export type SecondarySourcesCollections_Mutation_Response = {
  __typename?: 'SecondarySourcesCollections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesCollections>;
};

/** on_conflict condition type for table "collections" */
export type SecondarySourcesCollections_On_Conflict = {
  constraint: SecondarySourcesCollections_Constraint;
  update_columns: Array<SecondarySourcesCollections_Update_Column>;
  where: InputMaybe<SecondarySourcesCollections_Bool_Exp>;
};

/** Ordering options when selecting data from "collections". */
export type SecondarySourcesCollections_Order_By = {
  collectionId: InputMaybe<SecondarySourcesOrder_By>;
  collectionTitle: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
};

/** primary key columns input for table: collections */
export type SecondarySourcesCollections_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "collections" */
export enum SecondarySourcesCollections_Select_Column {
  /** column name */
  CollectionId = 'collectionId',
  /** column name */
  CollectionTitle = 'collectionTitle',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "collections" */
export type SecondarySourcesCollections_Set_Input = {
  collectionId: InputMaybe<Scalars['String']['input']>;
  collectionTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesCollections_Stddev_Fields = {
  __typename?: 'SecondarySourcesCollections_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesCollections_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesCollections_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesCollections_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesCollections_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "collections" */
export type SecondarySourcesCollections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesCollections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesCollections_Stream_Cursor_Value_Input = {
  collectionId: InputMaybe<Scalars['String']['input']>;
  collectionTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesCollections_Sum_Fields = {
  __typename?: 'SecondarySourcesCollections_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "collections" */
export enum SecondarySourcesCollections_Update_Column {
  /** column name */
  CollectionId = 'collectionId',
  /** column name */
  CollectionTitle = 'collectionTitle',
  /** column name */
  Id = 'id',
}

export type SecondarySourcesCollections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesCollections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesCollections_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesCollections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesCollections_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesCollections_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesCollections_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesCollections_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SecondarySourcesCollections_Variance_Fields = {
  __typename?: 'SecondarySourcesCollections_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum SecondarySourcesCursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "documentParts" */
export type SecondarySourcesDocumentParts = {
  __typename?: 'SecondarySourcesDocumentParts';
  active: Scalars['Boolean']['output'];
  content: Maybe<Scalars['String']['output']>;
  depth: Scalars['Int']['output'];
  /** An object relationship */
  document: SecondarySourcesDocuments;
  documentId: Scalars['String']['output'];
  documentPartId: Scalars['String']['output'];
  documentRowId: Scalars['Int']['output'];
  hasChildren: Maybe<Scalars['Boolean']['output']>;
  hasContent: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  immediateChildren: Array<SecondarySourcesDocumentParts>;
  /** An aggregate relationship */
  immediateChildren_aggregate: SecondarySourcesDocumentParts_Aggregate;
  /** An object relationship */
  import: SecondarySourcesImports;
  importId: Scalars['SecondarySourcesBigint']['output'];
  /** An object relationship */
  leftSibling: Maybe<SecondarySourcesDocumentParts>;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  /** An object relationship */
  parent: Maybe<SecondarySourcesDocumentParts>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  refglobal: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  rightSibling: Maybe<SecondarySourcesDocumentParts>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  rootParent: Maybe<SecondarySourcesDocumentParts>;
  /** An array relationship */
  rootParentChildren: Array<SecondarySourcesDocumentParts>;
  /** An aggregate relationship */
  rootParentChildren_aggregate: SecondarySourcesDocumentParts_Aggregate;
  rootParentId: Maybe<Scalars['String']['output']>;
  selfId: Scalars['String']['output'];
};

/** columns and relationships of "documentParts" */
export type SecondarySourcesDocumentPartsImmediateChildrenArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** columns and relationships of "documentParts" */
export type SecondarySourcesDocumentPartsImmediateChildren_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** columns and relationships of "documentParts" */
export type SecondarySourcesDocumentPartsRootParentChildrenArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** columns and relationships of "documentParts" */
export type SecondarySourcesDocumentPartsRootParentChildren_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** aggregated selection of "documentParts" */
export type SecondarySourcesDocumentParts_Aggregate = {
  __typename?: 'SecondarySourcesDocumentParts_aggregate';
  aggregate: Maybe<SecondarySourcesDocumentParts_Aggregate_Fields>;
  nodes: Array<SecondarySourcesDocumentParts>;
};

export type SecondarySourcesDocumentParts_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesDocumentParts_Select_Column_DocumentParts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesDocumentParts_Select_Column_DocumentParts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesDocumentParts_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "documentParts" */
export type SecondarySourcesDocumentParts_Aggregate_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_aggregate_fields';
  avg: Maybe<SecondarySourcesDocumentParts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesDocumentParts_Max_Fields>;
  min: Maybe<SecondarySourcesDocumentParts_Min_Fields>;
  stddev: Maybe<SecondarySourcesDocumentParts_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesDocumentParts_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesDocumentParts_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesDocumentParts_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesDocumentParts_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesDocumentParts_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesDocumentParts_Variance_Fields>;
};

/** aggregate fields of "documentParts" */
export type SecondarySourcesDocumentParts_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "documentParts" */
export type SecondarySourcesDocumentParts_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesDocumentParts_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesDocumentParts_Max_Order_By>;
  min: InputMaybe<SecondarySourcesDocumentParts_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesDocumentParts_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesDocumentParts_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesDocumentParts_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesDocumentParts_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesDocumentParts_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesDocumentParts_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesDocumentParts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "documentParts" */
export type SecondarySourcesDocumentParts_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesDocumentParts_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesDocumentParts_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesDocumentParts_Avg_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_avg_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Avg_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "documentParts". All fields are combined with a logical 'AND'. */
export type SecondarySourcesDocumentParts_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesDocumentParts_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesDocumentParts_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  content: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  depth: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  document: InputMaybe<SecondarySourcesDocuments_Bool_Exp>;
  documentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  documentPartId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  documentRowId: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  hasChildren: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  hasContent: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  immediateChildren: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  immediateChildren_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  leftSibling: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  name: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  order: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  parent: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  parentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  path: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  refglobal: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  rightSibling: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  rightSiblingId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  rootParent: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  rootParentChildren: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  rootParentChildren_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp>;
  rootParentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  selfId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
};

/** unique or primary key constraints on table "documentParts" */
export enum SecondarySourcesDocumentParts_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentPartsIdKey = 'documentParts_id_key',
  /** unique or primary key constraint on columns "id" */
  DocumentPartsPkey = 'documentParts_pkey',
  /** unique or primary key constraint on columns "selfId" */
  DocumentPartsSelfIdKey = 'documentParts_selfId_key',
}

/** input type for incrementing numeric columns in table "documentParts" */
export type SecondarySourcesDocumentParts_Inc_Input = {
  depth: InputMaybe<Scalars['Int']['input']>;
  documentRowId: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "documentParts" */
export type SecondarySourcesDocumentParts_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  content: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  document: InputMaybe<SecondarySourcesDocuments_Obj_Rel_Insert_Input>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentPartId: InputMaybe<Scalars['String']['input']>;
  documentRowId: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  immediateChildren: InputMaybe<SecondarySourcesDocumentParts_Arr_Rel_Insert_Input>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  leftSibling: InputMaybe<SecondarySourcesDocumentParts_Obj_Rel_Insert_Input>;
  name: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parent: InputMaybe<SecondarySourcesDocumentParts_Obj_Rel_Insert_Input>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  refglobal: InputMaybe<Scalars['String']['input']>;
  rightSibling: InputMaybe<SecondarySourcesDocumentParts_Obj_Rel_Insert_Input>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParent: InputMaybe<SecondarySourcesDocumentParts_Obj_Rel_Insert_Input>;
  rootParentChildren: InputMaybe<SecondarySourcesDocumentParts_Arr_Rel_Insert_Input>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  selfId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type SecondarySourcesDocumentParts_Max_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_max_fields';
  content: Maybe<Scalars['String']['output']>;
  depth: Maybe<Scalars['Int']['output']>;
  documentId: Maybe<Scalars['String']['output']>;
  documentPartId: Maybe<Scalars['String']['output']>;
  documentRowId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  refglobal: Maybe<Scalars['String']['output']>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  rootParentId: Maybe<Scalars['String']['output']>;
  selfId: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Max_Order_By = {
  content: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentId: InputMaybe<SecondarySourcesOrder_By>;
  documentPartId: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  refglobal: InputMaybe<SecondarySourcesOrder_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  selfId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesDocumentParts_Min_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_min_fields';
  content: Maybe<Scalars['String']['output']>;
  depth: Maybe<Scalars['Int']['output']>;
  documentId: Maybe<Scalars['String']['output']>;
  documentPartId: Maybe<Scalars['String']['output']>;
  documentRowId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  refglobal: Maybe<Scalars['String']['output']>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  rootParentId: Maybe<Scalars['String']['output']>;
  selfId: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Min_Order_By = {
  content: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentId: InputMaybe<SecondarySourcesOrder_By>;
  documentPartId: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  refglobal: InputMaybe<SecondarySourcesOrder_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  selfId: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "documentParts" */
export type SecondarySourcesDocumentParts_Mutation_Response = {
  __typename?: 'SecondarySourcesDocumentParts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesDocumentParts>;
};

/** input type for inserting object relation for remote table "documentParts" */
export type SecondarySourcesDocumentParts_Obj_Rel_Insert_Input = {
  data: SecondarySourcesDocumentParts_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesDocumentParts_On_Conflict>;
};

/** on_conflict condition type for table "documentParts" */
export type SecondarySourcesDocumentParts_On_Conflict = {
  constraint: SecondarySourcesDocumentParts_Constraint;
  update_columns: Array<SecondarySourcesDocumentParts_Update_Column>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** Ordering options when selecting data from "documentParts". */
export type SecondarySourcesDocumentParts_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  content: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  document: InputMaybe<SecondarySourcesDocuments_Order_By>;
  documentId: InputMaybe<SecondarySourcesOrder_By>;
  documentPartId: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  hasChildren: InputMaybe<SecondarySourcesOrder_By>;
  hasContent: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  immediateChildren_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Order_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  leftSibling: InputMaybe<SecondarySourcesDocumentParts_Order_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parent: InputMaybe<SecondarySourcesDocumentParts_Order_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  refglobal: InputMaybe<SecondarySourcesOrder_By>;
  rightSibling: InputMaybe<SecondarySourcesDocumentParts_Order_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParent: InputMaybe<SecondarySourcesDocumentParts_Order_By>;
  rootParentChildren_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Order_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  selfId: InputMaybe<SecondarySourcesOrder_By>;
};

/** primary key columns input for table: documentParts */
export type SecondarySourcesDocumentParts_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "documentParts" */
export enum SecondarySourcesDocumentParts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Content = 'content',
  /** column name */
  Depth = 'depth',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentPartId = 'documentPartId',
  /** column name */
  DocumentRowId = 'documentRowId',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Path = 'path',
  /** column name */
  Refglobal = 'refglobal',
  /** column name */
  RightSiblingId = 'rightSiblingId',
  /** column name */
  RootParentId = 'rootParentId',
  /** column name */
  SelfId = 'selfId',
}

/** select "documentParts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "documentParts" */
export enum SecondarySourcesDocumentParts_Select_Column_DocumentParts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
}

/** select "documentParts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "documentParts" */
export enum SecondarySourcesDocumentParts_Select_Column_DocumentParts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
}

/** input type for updating data in table "documentParts" */
export type SecondarySourcesDocumentParts_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  content: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentPartId: InputMaybe<Scalars['String']['input']>;
  documentRowId: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  refglobal: InputMaybe<Scalars['String']['input']>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  selfId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesDocumentParts_Stddev_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_stddev_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Stddev_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesDocumentParts_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_stddev_pop_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Stddev_Pop_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesDocumentParts_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_stddev_samp_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Stddev_Samp_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "documentParts" */
export type SecondarySourcesDocumentParts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesDocumentParts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesDocumentParts_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  content: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentPartId: InputMaybe<Scalars['String']['input']>;
  documentRowId: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  refglobal: InputMaybe<Scalars['String']['input']>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  selfId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesDocumentParts_Sum_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_sum_fields';
  depth: Maybe<Scalars['Int']['output']>;
  documentRowId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  order: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Sum_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "documentParts" */
export enum SecondarySourcesDocumentParts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Content = 'content',
  /** column name */
  Depth = 'depth',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentPartId = 'documentPartId',
  /** column name */
  DocumentRowId = 'documentRowId',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Path = 'path',
  /** column name */
  Refglobal = 'refglobal',
  /** column name */
  RightSiblingId = 'rightSiblingId',
  /** column name */
  RootParentId = 'rootParentId',
  /** column name */
  SelfId = 'selfId',
}

export type SecondarySourcesDocumentParts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesDocumentParts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesDocumentParts_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesDocumentParts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesDocumentParts_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_var_pop_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Var_Pop_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesDocumentParts_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_var_samp_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Var_Samp_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesDocumentParts_Variance_Fields = {
  __typename?: 'SecondarySourcesDocumentParts_variance_fields';
  depth: Maybe<Scalars['Float']['output']>;
  documentRowId: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "documentParts" */
export type SecondarySourcesDocumentParts_Variance_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  documentRowId: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
};

/** columns and relationships of "documents" */
export type SecondarySourcesDocuments = {
  __typename?: 'SecondarySourcesDocuments';
  active: Scalars['Boolean']['output'];
  documentId: Scalars['String']['output'];
  documentTitle: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  /** An array relationship */
  tableOfContents: Array<SecondarySourcesDocumentParts>;
  /** An aggregate relationship */
  tableOfContents_aggregate: SecondarySourcesDocumentParts_Aggregate;
};

/** columns and relationships of "documents" */
export type SecondarySourcesDocumentsTableOfContentsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type SecondarySourcesDocumentsTableOfContents_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesDocumentParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesDocumentParts_Order_By>>;
  where: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
};

/** aggregated selection of "documents" */
export type SecondarySourcesDocuments_Aggregate = {
  __typename?: 'SecondarySourcesDocuments_aggregate';
  aggregate: Maybe<SecondarySourcesDocuments_Aggregate_Fields>;
  nodes: Array<SecondarySourcesDocuments>;
};

/** aggregate fields of "documents" */
export type SecondarySourcesDocuments_Aggregate_Fields = {
  __typename?: 'SecondarySourcesDocuments_aggregate_fields';
  avg: Maybe<SecondarySourcesDocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesDocuments_Max_Fields>;
  min: Maybe<SecondarySourcesDocuments_Min_Fields>;
  stddev: Maybe<SecondarySourcesDocuments_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesDocuments_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesDocuments_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesDocuments_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesDocuments_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesDocuments_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesDocuments_Variance_Fields>;
};

/** aggregate fields of "documents" */
export type SecondarySourcesDocuments_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesDocuments_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type SecondarySourcesDocuments_Avg_Fields = {
  __typename?: 'SecondarySourcesDocuments_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type SecondarySourcesDocuments_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesDocuments_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesDocuments_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesDocuments_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  documentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  documentTitle: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  tableOfContents: InputMaybe<SecondarySourcesDocumentParts_Bool_Exp>;
  tableOfContents_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "documents" */
export enum SecondarySourcesDocuments_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey',
}

/** input type for incrementing numeric columns in table "documents" */
export type SecondarySourcesDocuments_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
};

/** input type for inserting data into table "documents" */
export type SecondarySourcesDocuments_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  tableOfContents: InputMaybe<SecondarySourcesDocumentParts_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type SecondarySourcesDocuments_Max_Fields = {
  __typename?: 'SecondarySourcesDocuments_max_fields';
  documentId: Maybe<Scalars['String']['output']>;
  documentTitle: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
};

/** aggregate min on columns */
export type SecondarySourcesDocuments_Min_Fields = {
  __typename?: 'SecondarySourcesDocuments_min_fields';
  documentId: Maybe<Scalars['String']['output']>;
  documentTitle: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
};

/** response of any mutation on the table "documents" */
export type SecondarySourcesDocuments_Mutation_Response = {
  __typename?: 'SecondarySourcesDocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesDocuments>;
};

/** input type for inserting object relation for remote table "documents" */
export type SecondarySourcesDocuments_Obj_Rel_Insert_Input = {
  data: SecondarySourcesDocuments_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesDocuments_On_Conflict>;
};

/** on_conflict condition type for table "documents" */
export type SecondarySourcesDocuments_On_Conflict = {
  constraint: SecondarySourcesDocuments_Constraint;
  update_columns: Array<SecondarySourcesDocuments_Update_Column>;
  where: InputMaybe<SecondarySourcesDocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "documents". */
export type SecondarySourcesDocuments_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  documentId: InputMaybe<SecondarySourcesOrder_By>;
  documentTitle: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  tableOfContents_aggregate: InputMaybe<SecondarySourcesDocumentParts_Aggregate_Order_By>;
};

/** primary key columns input for table: documents */
export type SecondarySourcesDocuments_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "documents" */
export enum SecondarySourcesDocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentTitle = 'documentTitle',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
}

/** input type for updating data in table "documents" */
export type SecondarySourcesDocuments_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesDocuments_Stddev_Fields = {
  __typename?: 'SecondarySourcesDocuments_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesDocuments_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesDocuments_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesDocuments_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesDocuments_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "documents" */
export type SecondarySourcesDocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesDocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesDocuments_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  documentId: InputMaybe<Scalars['String']['input']>;
  documentTitle: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesDocuments_Sum_Fields = {
  __typename?: 'SecondarySourcesDocuments_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
};

/** update columns of table "documents" */
export enum SecondarySourcesDocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentTitle = 'documentTitle',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
}

export type SecondarySourcesDocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesDocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesDocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesDocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesDocuments_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesDocuments_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesDocuments_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesDocuments_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SecondarySourcesDocuments_Variance_Fields = {
  __typename?: 'SecondarySourcesDocuments_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImports = {
  __typename?: 'SecondarySourcesImports';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['SecondarySourcesTimestamptz']['output'];
  documentpartsCount: Maybe<Scalars['Int']['output']>;
  id: Scalars['SecondarySourcesBigint']['output'];
  /** An array relationship */
  practiceAreaTitles: Array<SecondarySourcesPracticeAreaTitles>;
  /** An aggregate relationship */
  practiceAreaTitles_aggregate: SecondarySourcesPracticeAreaTitles_Aggregate;
  /** An array relationship */
  practiceAreas: Array<SecondarySourcesPracticeAreas>;
  /** An aggregate relationship */
  practiceAreas_aggregate: SecondarySourcesPracticeAreas_Aggregate;
  publishedAt: Maybe<Scalars['SecondarySourcesTimestamptz']['output']>;
  /** An array relationship */
  resourceTypes: Array<SecondarySourcesResourceTypes>;
  /** An aggregate relationship */
  resourceTypes_aggregate: SecondarySourcesResourceTypes_Aggregate;
  /** An array relationship */
  titleParts: Array<SecondarySourcesTitleParts>;
  /** An aggregate relationship */
  titleParts_aggregate: SecondarySourcesTitleParts_Aggregate;
  titlepartsCount: Scalars['Int']['output'];
  /** An array relationship */
  titles: Array<SecondarySourcesTitles>;
  /** An aggregate relationship */
  titles_aggregate: SecondarySourcesTitles_Aggregate;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsPracticeAreaTitlesArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsPracticeAreaTitles_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsPracticeAreasArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreas_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsPracticeAreas_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreas_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsResourceTypesArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesResourceTypes_Order_By>>;
  where: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsResourceTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesResourceTypes_Order_By>>;
  where: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsTitlePartsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsTitleParts_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsTitlesArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

/** columns and relationships of "imports" */
export type SecondarySourcesImportsTitles_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

/** aggregated selection of "imports" */
export type SecondarySourcesImports_Aggregate = {
  __typename?: 'SecondarySourcesImports_aggregate';
  aggregate: Maybe<SecondarySourcesImports_Aggregate_Fields>;
  nodes: Array<SecondarySourcesImports>;
};

/** aggregate fields of "imports" */
export type SecondarySourcesImports_Aggregate_Fields = {
  __typename?: 'SecondarySourcesImports_aggregate_fields';
  avg: Maybe<SecondarySourcesImports_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesImports_Max_Fields>;
  min: Maybe<SecondarySourcesImports_Min_Fields>;
  stddev: Maybe<SecondarySourcesImports_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesImports_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesImports_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesImports_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesImports_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesImports_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesImports_Variance_Fields>;
};

/** aggregate fields of "imports" */
export type SecondarySourcesImports_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesImports_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type SecondarySourcesImports_Avg_Fields = {
  __typename?: 'SecondarySourcesImports_avg_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "imports". All fields are combined with a logical 'AND'. */
export type SecondarySourcesImports_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesImports_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesImports_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  createdAt: InputMaybe<SecondarySourcesTimestamptz_Comparison_Exp>;
  documentpartsCount: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  practiceAreaTitles: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  practiceAreaTitles_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp>;
  practiceAreas: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  practiceAreas_aggregate: InputMaybe<SecondarySourcesPracticeAreas_Aggregate_Bool_Exp>;
  publishedAt: InputMaybe<SecondarySourcesTimestamptz_Comparison_Exp>;
  resourceTypes: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  resourceTypes_aggregate: InputMaybe<SecondarySourcesResourceTypes_Aggregate_Bool_Exp>;
  titleParts: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  titleParts_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp>;
  titlepartsCount: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  titles: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  titles_aggregate: InputMaybe<SecondarySourcesTitles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "imports" */
export enum SecondarySourcesImports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportsPkey = 'imports_pkey',
}

/** input type for incrementing numeric columns in table "imports" */
export type SecondarySourcesImports_Inc_Input = {
  documentpartsCount: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  titlepartsCount: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "imports" */
export type SecondarySourcesImports_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  documentpartsCount: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  practiceAreaTitles: InputMaybe<SecondarySourcesPracticeAreaTitles_Arr_Rel_Insert_Input>;
  practiceAreas: InputMaybe<SecondarySourcesPracticeAreas_Arr_Rel_Insert_Input>;
  publishedAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  resourceTypes: InputMaybe<SecondarySourcesResourceTypes_Arr_Rel_Insert_Input>;
  titleParts: InputMaybe<SecondarySourcesTitleParts_Arr_Rel_Insert_Input>;
  titlepartsCount: InputMaybe<Scalars['Int']['input']>;
  titles: InputMaybe<SecondarySourcesTitles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type SecondarySourcesImports_Max_Fields = {
  __typename?: 'SecondarySourcesImports_max_fields';
  createdAt: Maybe<Scalars['SecondarySourcesTimestamptz']['output']>;
  documentpartsCount: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  publishedAt: Maybe<Scalars['SecondarySourcesTimestamptz']['output']>;
  titlepartsCount: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type SecondarySourcesImports_Min_Fields = {
  __typename?: 'SecondarySourcesImports_min_fields';
  createdAt: Maybe<Scalars['SecondarySourcesTimestamptz']['output']>;
  documentpartsCount: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  publishedAt: Maybe<Scalars['SecondarySourcesTimestamptz']['output']>;
  titlepartsCount: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "imports" */
export type SecondarySourcesImports_Mutation_Response = {
  __typename?: 'SecondarySourcesImports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesImports>;
};

/** input type for inserting object relation for remote table "imports" */
export type SecondarySourcesImports_Obj_Rel_Insert_Input = {
  data: SecondarySourcesImports_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesImports_On_Conflict>;
};

/** on_conflict condition type for table "imports" */
export type SecondarySourcesImports_On_Conflict = {
  constraint: SecondarySourcesImports_Constraint;
  update_columns: Array<SecondarySourcesImports_Update_Column>;
  where: InputMaybe<SecondarySourcesImports_Bool_Exp>;
};

/** Ordering options when selecting data from "imports". */
export type SecondarySourcesImports_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  createdAt: InputMaybe<SecondarySourcesOrder_By>;
  documentpartsCount: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaTitles_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Order_By>;
  practiceAreas_aggregate: InputMaybe<SecondarySourcesPracticeAreas_Aggregate_Order_By>;
  publishedAt: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypes_aggregate: InputMaybe<SecondarySourcesResourceTypes_Aggregate_Order_By>;
  titleParts_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Order_By>;
  titlepartsCount: InputMaybe<SecondarySourcesOrder_By>;
  titles_aggregate: InputMaybe<SecondarySourcesTitles_Aggregate_Order_By>;
};

/** primary key columns input for table: imports */
export type SecondarySourcesImports_Pk_Columns_Input = {
  id: Scalars['SecondarySourcesBigint']['input'];
};

/** select columns of table "imports" */
export enum SecondarySourcesImports_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentpartsCount = 'documentpartsCount',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  TitlepartsCount = 'titlepartsCount',
}

/** input type for updating data in table "imports" */
export type SecondarySourcesImports_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  documentpartsCount: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  publishedAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  titlepartsCount: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesImports_Stddev_Fields = {
  __typename?: 'SecondarySourcesImports_stddev_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesImports_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesImports_stddev_pop_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesImports_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesImports_stddev_samp_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "imports" */
export type SecondarySourcesImports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesImports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesImports_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  documentpartsCount: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  publishedAt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  titlepartsCount: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesImports_Sum_Fields = {
  __typename?: 'SecondarySourcesImports_sum_fields';
  documentpartsCount: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  titlepartsCount: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "imports" */
export enum SecondarySourcesImports_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentpartsCount = 'documentpartsCount',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  TitlepartsCount = 'titlepartsCount',
}

export type SecondarySourcesImports_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesImports_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesImports_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesImports_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesImports_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesImports_var_pop_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesImports_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesImports_var_samp_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SecondarySourcesImports_Variance_Fields = {
  __typename?: 'SecondarySourcesImports_variance_fields';
  documentpartsCount: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  titlepartsCount: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type SecondarySourcesInt_Comparison_Exp = {
  _eq: InputMaybe<Scalars['Int']['input']>;
  _gt: InputMaybe<Scalars['Int']['input']>;
  _gte: InputMaybe<Scalars['Int']['input']>;
  _in: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Int']['input']>;
  _lte: InputMaybe<Scalars['Int']['input']>;
  _neq: InputMaybe<Scalars['Int']['input']>;
  _nin: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type SecondarySourcesJson_Comparison_Exp = {
  _eq: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _gt: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _gte: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _in: InputMaybe<Array<Scalars['SecondarySourcesJson']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _lte: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _neq: InputMaybe<Scalars['SecondarySourcesJson']['input']>;
  _nin: InputMaybe<Array<Scalars['SecondarySourcesJson']['input']>>;
};

/** column ordering options */
export enum SecondarySourcesOrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles = {
  __typename?: 'SecondarySourcesPracticeAreaTitles';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  /** An object relationship */
  practiceArea: SecondarySourcesPracticeAreas;
  practiceAreaId: Scalars['Int']['output'];
  primary: Scalars['Boolean']['output'];
  /** An object relationship */
  title: SecondarySourcesTitles;
  titleId: Scalars['Int']['output'];
};

/** aggregated selection of "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Aggregate = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_aggregate';
  aggregate: Maybe<SecondarySourcesPracticeAreaTitles_Aggregate_Fields>;
  nodes: Array<SecondarySourcesPracticeAreaTitles>;
};

export type SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesPracticeAreaTitles_Select_Column_PracticeAreaTitles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesPracticeAreaTitles_Select_Column_PracticeAreaTitles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Aggregate_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_aggregate_fields';
  avg: Maybe<SecondarySourcesPracticeAreaTitles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesPracticeAreaTitles_Max_Fields>;
  min: Maybe<SecondarySourcesPracticeAreaTitles_Min_Fields>;
  stddev: Maybe<SecondarySourcesPracticeAreaTitles_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesPracticeAreaTitles_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesPracticeAreaTitles_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesPracticeAreaTitles_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesPracticeAreaTitles_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesPracticeAreaTitles_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesPracticeAreaTitles_Variance_Fields>;
};

/** aggregate fields of "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesPracticeAreaTitles_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesPracticeAreaTitles_Max_Order_By>;
  min: InputMaybe<SecondarySourcesPracticeAreaTitles_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesPracticeAreaTitles_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesPracticeAreaTitles_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesPracticeAreaTitles_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesPracticeAreaTitles_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesPracticeAreaTitles_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesPracticeAreaTitles_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesPracticeAreaTitles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesPracticeAreaTitles_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesPracticeAreaTitles_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesPracticeAreaTitles_Avg_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Avg_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "practiceAreaTitles". All fields are combined with a logical 'AND'. */
export type SecondarySourcesPracticeAreaTitles_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  practiceArea: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  practiceAreaId: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  primary: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  title: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  titleId: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
};

/** unique or primary key constraints on table "practiceAreaTitles" */
export enum SecondarySourcesPracticeAreaTitles_Constraint {
  /** unique or primary key constraint on columns "id" */
  PracticeAreaTitlesPkey = 'practice_area_titles_pkey',
}

/** input type for incrementing numeric columns in table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  practiceAreaId: InputMaybe<Scalars['Int']['input']>;
  titleId: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  practiceArea: InputMaybe<SecondarySourcesPracticeAreas_Obj_Rel_Insert_Input>;
  practiceAreaId: InputMaybe<Scalars['Int']['input']>;
  primary: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<SecondarySourcesTitles_Obj_Rel_Insert_Input>;
  titleId: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type SecondarySourcesPracticeAreaTitles_Max_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_max_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  practiceAreaId: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Max_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesPracticeAreaTitles_Min_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_min_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  practiceAreaId: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Min_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Mutation_Response = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesPracticeAreaTitles>;
};

/** on_conflict condition type for table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_On_Conflict = {
  constraint: SecondarySourcesPracticeAreaTitles_Constraint;
  update_columns: Array<SecondarySourcesPracticeAreaTitles_Update_Column>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** Ordering options when selecting data from "practiceAreaTitles". */
export type SecondarySourcesPracticeAreaTitles_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceArea: InputMaybe<SecondarySourcesPracticeAreas_Order_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  primary: InputMaybe<SecondarySourcesOrder_By>;
  title: InputMaybe<SecondarySourcesTitles_Order_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** primary key columns input for table: practiceAreaTitles */
export type SecondarySourcesPracticeAreaTitles_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "practiceAreaTitles" */
export enum SecondarySourcesPracticeAreaTitles_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  PracticeAreaId = 'practiceAreaId',
  /** column name */
  Primary = 'primary',
  /** column name */
  TitleId = 'titleId',
}

/** select "practiceAreaTitles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "practiceAreaTitles" */
export enum SecondarySourcesPracticeAreaTitles_Select_Column_PracticeAreaTitles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Primary = 'primary',
}

/** select "practiceAreaTitles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "practiceAreaTitles" */
export enum SecondarySourcesPracticeAreaTitles_Select_Column_PracticeAreaTitles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Primary = 'primary',
}

/** input type for updating data in table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  practiceAreaId: InputMaybe<Scalars['Int']['input']>;
  primary: InputMaybe<Scalars['Boolean']['input']>;
  titleId: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesPracticeAreaTitles_Stddev_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Stddev_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesPracticeAreaTitles_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Stddev_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesPracticeAreaTitles_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Stddev_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesPracticeAreaTitles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesPracticeAreaTitles_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  practiceAreaId: InputMaybe<Scalars['Int']['input']>;
  primary: InputMaybe<Scalars['Boolean']['input']>;
  titleId: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesPracticeAreaTitles_Sum_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  practiceAreaId: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Sum_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "practiceAreaTitles" */
export enum SecondarySourcesPracticeAreaTitles_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  PracticeAreaId = 'practiceAreaId',
  /** column name */
  Primary = 'primary',
  /** column name */
  TitleId = 'titleId',
}

export type SecondarySourcesPracticeAreaTitles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesPracticeAreaTitles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesPracticeAreaTitles_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesPracticeAreaTitles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesPracticeAreaTitles_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Var_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesPracticeAreaTitles_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Var_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesPracticeAreaTitles_Variance_Fields = {
  __typename?: 'SecondarySourcesPracticeAreaTitles_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  practiceAreaId: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "practiceAreaTitles" */
export type SecondarySourcesPracticeAreaTitles_Variance_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreaId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** columns and relationships of "practiceAreas" */
export type SecondarySourcesPracticeAreas = {
  __typename?: 'SecondarySourcesPracticeAreas';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  titles: Array<SecondarySourcesPracticeAreaTitles>;
  /** An aggregate relationship */
  titles_aggregate: SecondarySourcesPracticeAreaTitles_Aggregate;
};

/** columns and relationships of "practiceAreas" */
export type SecondarySourcesPracticeAreasTitlesArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** columns and relationships of "practiceAreas" */
export type SecondarySourcesPracticeAreasTitles_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** aggregated selection of "practiceAreas" */
export type SecondarySourcesPracticeAreas_Aggregate = {
  __typename?: 'SecondarySourcesPracticeAreas_aggregate';
  aggregate: Maybe<SecondarySourcesPracticeAreas_Aggregate_Fields>;
  nodes: Array<SecondarySourcesPracticeAreas>;
};

export type SecondarySourcesPracticeAreas_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesPracticeAreas_Select_Column_PracticeAreas_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesPracticeAreas_Select_Column_PracticeAreas_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesPracticeAreas_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "practiceAreas" */
export type SecondarySourcesPracticeAreas_Aggregate_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_aggregate_fields';
  avg: Maybe<SecondarySourcesPracticeAreas_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesPracticeAreas_Max_Fields>;
  min: Maybe<SecondarySourcesPracticeAreas_Min_Fields>;
  stddev: Maybe<SecondarySourcesPracticeAreas_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesPracticeAreas_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesPracticeAreas_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesPracticeAreas_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesPracticeAreas_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesPracticeAreas_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesPracticeAreas_Variance_Fields>;
};

/** aggregate fields of "practiceAreas" */
export type SecondarySourcesPracticeAreas_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesPracticeAreas_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesPracticeAreas_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesPracticeAreas_Max_Order_By>;
  min: InputMaybe<SecondarySourcesPracticeAreas_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesPracticeAreas_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesPracticeAreas_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesPracticeAreas_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesPracticeAreas_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesPracticeAreas_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesPracticeAreas_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesPracticeAreas_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesPracticeAreas_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesPracticeAreas_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesPracticeAreas_Avg_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Avg_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "practiceAreas". All fields are combined with a logical 'AND'. */
export type SecondarySourcesPracticeAreas_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesPracticeAreas_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesPracticeAreas_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  name: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  slug: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  titles: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  titles_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "practiceAreas" */
export enum SecondarySourcesPracticeAreas_Constraint {
  /** unique or primary key constraint on columns "id" */
  PracticeAreasPkey = 'practice_areas_pkey',
}

/** input type for incrementing numeric columns in table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
};

/** input type for inserting data into table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  titles: InputMaybe<SecondarySourcesPracticeAreaTitles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type SecondarySourcesPracticeAreas_Max_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_max_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Max_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesPracticeAreas_Min_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_min_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Min_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Mutation_Response = {
  __typename?: 'SecondarySourcesPracticeAreas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesPracticeAreas>;
};

/** input type for inserting object relation for remote table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Obj_Rel_Insert_Input = {
  data: SecondarySourcesPracticeAreas_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesPracticeAreas_On_Conflict>;
};

/** on_conflict condition type for table "practiceAreas" */
export type SecondarySourcesPracticeAreas_On_Conflict = {
  constraint: SecondarySourcesPracticeAreas_Constraint;
  update_columns: Array<SecondarySourcesPracticeAreas_Update_Column>;
  where: InputMaybe<SecondarySourcesPracticeAreas_Bool_Exp>;
};

/** Ordering options when selecting data from "practiceAreas". */
export type SecondarySourcesPracticeAreas_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
  titles_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Order_By>;
};

/** primary key columns input for table: practiceAreas */
export type SecondarySourcesPracticeAreas_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "practiceAreas" */
export enum SecondarySourcesPracticeAreas_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** select "practiceAreas_aggregate_bool_exp_bool_and_arguments_columns" columns of table "practiceAreas" */
export enum SecondarySourcesPracticeAreas_Select_Column_PracticeAreas_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "practiceAreas_aggregate_bool_exp_bool_or_arguments_columns" columns of table "practiceAreas" */
export enum SecondarySourcesPracticeAreas_Select_Column_PracticeAreas_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesPracticeAreas_Stddev_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Stddev_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesPracticeAreas_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Stddev_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesPracticeAreas_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Stddev_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesPracticeAreas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesPracticeAreas_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesPracticeAreas_Sum_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
};

/** order by sum() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Sum_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "practiceAreas" */
export enum SecondarySourcesPracticeAreas_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

export type SecondarySourcesPracticeAreas_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesPracticeAreas_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesPracticeAreas_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesPracticeAreas_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesPracticeAreas_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Var_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesPracticeAreas_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Var_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesPracticeAreas_Variance_Fields = {
  __typename?: 'SecondarySourcesPracticeAreas_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "practiceAreas" */
export type SecondarySourcesPracticeAreas_Variance_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** columns and relationships of "resourceTypes" */
export type SecondarySourcesResourceTypes = {
  __typename?: 'SecondarySourcesResourceTypes';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  /** An array relationship */
  titles: Array<SecondarySourcesTitles>;
  /** An aggregate relationship */
  titles_aggregate: SecondarySourcesTitles_Aggregate;
};

/** columns and relationships of "resourceTypes" */
export type SecondarySourcesResourceTypesTitlesArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

/** columns and relationships of "resourceTypes" */
export type SecondarySourcesResourceTypesTitles_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

/** aggregated selection of "resourceTypes" */
export type SecondarySourcesResourceTypes_Aggregate = {
  __typename?: 'SecondarySourcesResourceTypes_aggregate';
  aggregate: Maybe<SecondarySourcesResourceTypes_Aggregate_Fields>;
  nodes: Array<SecondarySourcesResourceTypes>;
};

export type SecondarySourcesResourceTypes_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesResourceTypes_Select_Column_ResourceTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesResourceTypes_Select_Column_ResourceTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesResourceTypes_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "resourceTypes" */
export type SecondarySourcesResourceTypes_Aggregate_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_aggregate_fields';
  avg: Maybe<SecondarySourcesResourceTypes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesResourceTypes_Max_Fields>;
  min: Maybe<SecondarySourcesResourceTypes_Min_Fields>;
  stddev: Maybe<SecondarySourcesResourceTypes_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesResourceTypes_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesResourceTypes_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesResourceTypes_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesResourceTypes_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesResourceTypes_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesResourceTypes_Variance_Fields>;
};

/** aggregate fields of "resourceTypes" */
export type SecondarySourcesResourceTypes_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesResourceTypes_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesResourceTypes_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesResourceTypes_Max_Order_By>;
  min: InputMaybe<SecondarySourcesResourceTypes_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesResourceTypes_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesResourceTypes_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesResourceTypes_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesResourceTypes_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesResourceTypes_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesResourceTypes_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesResourceTypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "resourceTypes" */
export type SecondarySourcesResourceTypes_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesResourceTypes_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesResourceTypes_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesResourceTypes_Avg_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Avg_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "resourceTypes". All fields are combined with a logical 'AND'. */
export type SecondarySourcesResourceTypes_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesResourceTypes_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesResourceTypes_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  name: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  slug: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  titles: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  titles_aggregate: InputMaybe<SecondarySourcesTitles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "resourceTypes" */
export enum SecondarySourcesResourceTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResourceTypesPkey = 'resourceTypes_pkey',
}

/** input type for incrementing numeric columns in table "resourceTypes" */
export type SecondarySourcesResourceTypes_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
};

/** input type for inserting data into table "resourceTypes" */
export type SecondarySourcesResourceTypes_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  titles: InputMaybe<SecondarySourcesTitles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type SecondarySourcesResourceTypes_Max_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_max_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Max_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesResourceTypes_Min_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_min_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Min_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "resourceTypes" */
export type SecondarySourcesResourceTypes_Mutation_Response = {
  __typename?: 'SecondarySourcesResourceTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesResourceTypes>;
};

/** input type for inserting object relation for remote table "resourceTypes" */
export type SecondarySourcesResourceTypes_Obj_Rel_Insert_Input = {
  data: SecondarySourcesResourceTypes_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesResourceTypes_On_Conflict>;
};

/** on_conflict condition type for table "resourceTypes" */
export type SecondarySourcesResourceTypes_On_Conflict = {
  constraint: SecondarySourcesResourceTypes_Constraint;
  update_columns: Array<SecondarySourcesResourceTypes_Update_Column>;
  where: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "resourceTypes". */
export type SecondarySourcesResourceTypes_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  slug: InputMaybe<SecondarySourcesOrder_By>;
  titles_aggregate: InputMaybe<SecondarySourcesTitles_Aggregate_Order_By>;
};

/** primary key columns input for table: resourceTypes */
export type SecondarySourcesResourceTypes_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "resourceTypes" */
export enum SecondarySourcesResourceTypes_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** select "resourceTypes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "resourceTypes" */
export enum SecondarySourcesResourceTypes_Select_Column_ResourceTypes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "resourceTypes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "resourceTypes" */
export enum SecondarySourcesResourceTypes_Select_Column_ResourceTypes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "resourceTypes" */
export type SecondarySourcesResourceTypes_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesResourceTypes_Stddev_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Stddev_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesResourceTypes_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Stddev_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesResourceTypes_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Stddev_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "resourceTypes" */
export type SecondarySourcesResourceTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesResourceTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesResourceTypes_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesResourceTypes_Sum_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
};

/** order by sum() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Sum_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "resourceTypes" */
export enum SecondarySourcesResourceTypes_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

export type SecondarySourcesResourceTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesResourceTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesResourceTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesResourceTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesResourceTypes_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Var_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesResourceTypes_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Var_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesResourceTypes_Variance_Fields = {
  __typename?: 'SecondarySourcesResourceTypes_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "resourceTypes" */
export type SecondarySourcesResourceTypes_Variance_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type SecondarySourcesString_Comparison_Exp = {
  _eq: InputMaybe<Scalars['String']['input']>;
  _gt: InputMaybe<Scalars['String']['input']>;
  _gte: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: InputMaybe<Scalars['String']['input']>;
  _in: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: InputMaybe<Scalars['String']['input']>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like: InputMaybe<Scalars['String']['input']>;
  _lt: InputMaybe<Scalars['String']['input']>;
  _lte: InputMaybe<Scalars['String']['input']>;
  _neq: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: InputMaybe<Scalars['String']['input']>;
  _nin: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type SecondarySourcesTimestamptz_Comparison_Exp = {
  _eq: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _gt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _gte: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _in: InputMaybe<Array<Scalars['SecondarySourcesTimestamptz']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _lte: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _neq: InputMaybe<Scalars['SecondarySourcesTimestamptz']['input']>;
  _nin: InputMaybe<Array<Scalars['SecondarySourcesTimestamptz']['input']>>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitleParts = {
  __typename?: 'SecondarySourcesTitleParts';
  active: Scalars['Boolean']['output'];
  baseId: Maybe<Scalars['String']['output']>;
  californiaCitationTemplate: Maybe<Scalars['SecondarySourcesJson']['output']>;
  content: Maybe<Scalars['String']['output']>;
  contentId: Scalars['String']['output'];
  depth: Maybe<Scalars['Int']['output']>;
  hasChildren: Maybe<Scalars['Boolean']['output']>;
  hasContent: Maybe<Scalars['Boolean']['output']>;
  htmlFile: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  immediateChildren: Array<SecondarySourcesTitleParts>;
  /** An aggregate relationship */
  immediateChildren_aggregate: SecondarySourcesTitleParts_Aggregate;
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  isForm: Scalars['Boolean']['output'];
  /** An object relationship */
  leftSibling: Maybe<SecondarySourcesTitleParts>;
  name: Scalars['String']['output'];
  onlawId: Scalars['String']['output'];
  order: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  parent: Maybe<SecondarySourcesTitleParts>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  rightSibling: Maybe<SecondarySourcesTitleParts>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  rootParent: Maybe<SecondarySourcesTitleParts>;
  /** An array relationship */
  rootParentChildren: Array<SecondarySourcesTitleParts>;
  /** An aggregate relationship */
  rootParentChildren_aggregate: SecondarySourcesTitleParts_Aggregate;
  rootParentId: Maybe<Scalars['String']['output']>;
  standardCitationTemplate: Maybe<Scalars['SecondarySourcesJson']['output']>;
  /** An object relationship */
  title: Maybe<SecondarySourcesTitles>;
  titleId: Maybe<Scalars['Int']['output']>;
  titleOnlawId: Scalars['String']['output'];
  titleProductCode: Scalars['String']['output'];
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsCaliforniaCitationTemplateArgs = {
  path: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsImmediateChildrenArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsImmediateChildren_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsRootParentChildrenArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsRootParentChildren_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "titleParts" */
export type SecondarySourcesTitlePartsStandardCitationTemplateArgs = {
  path: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "titleParts" */
export type SecondarySourcesTitleParts_Aggregate = {
  __typename?: 'SecondarySourcesTitleParts_aggregate';
  aggregate: Maybe<SecondarySourcesTitleParts_Aggregate_Fields>;
  nodes: Array<SecondarySourcesTitleParts>;
};

export type SecondarySourcesTitleParts_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesTitleParts_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesTitleParts_Select_Column_TitleParts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesTitleParts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesTitleParts_Select_Column_TitleParts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesTitleParts_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "titleParts" */
export type SecondarySourcesTitleParts_Aggregate_Fields = {
  __typename?: 'SecondarySourcesTitleParts_aggregate_fields';
  avg: Maybe<SecondarySourcesTitleParts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesTitleParts_Max_Fields>;
  min: Maybe<SecondarySourcesTitleParts_Min_Fields>;
  stddev: Maybe<SecondarySourcesTitleParts_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesTitleParts_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesTitleParts_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesTitleParts_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesTitleParts_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesTitleParts_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesTitleParts_Variance_Fields>;
};

/** aggregate fields of "titleParts" */
export type SecondarySourcesTitleParts_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "titleParts" */
export type SecondarySourcesTitleParts_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesTitleParts_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesTitleParts_Max_Order_By>;
  min: InputMaybe<SecondarySourcesTitleParts_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesTitleParts_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesTitleParts_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesTitleParts_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesTitleParts_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesTitleParts_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesTitleParts_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesTitleParts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "titleParts" */
export type SecondarySourcesTitleParts_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesTitleParts_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesTitleParts_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesTitleParts_Avg_Fields = {
  __typename?: 'SecondarySourcesTitleParts_avg_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Avg_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "titleParts". All fields are combined with a logical 'AND'. */
export type SecondarySourcesTitleParts_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesTitleParts_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesTitleParts_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  baseId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  californiaCitationTemplate: InputMaybe<SecondarySourcesJson_Comparison_Exp>;
  content: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  contentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  depth: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  hasChildren: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  hasContent: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  htmlFile: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  immediateChildren: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  immediateChildren_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  isForm: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  leftSibling: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  name: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  onlawId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  order: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  parent: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  parentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  path: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  rightSibling: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  rightSiblingId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  rootParent: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  rootParentChildren: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  rootParentChildren_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp>;
  rootParentId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  standardCitationTemplate: InputMaybe<SecondarySourcesJson_Comparison_Exp>;
  title: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  titleId: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  titleOnlawId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  titleProductCode: InputMaybe<SecondarySourcesString_Comparison_Exp>;
};

/** unique or primary key constraints on table "titleParts" */
export enum SecondarySourcesTitleParts_Constraint {
  /** unique or primary key constraint on columns "id" */
  TitlePartsIdKey = 'titleParts_id_key',
  /** unique or primary key constraint on columns "contentId" */
  TitlePartsPkey = 'titleParts_pkey',
}

/** input type for incrementing numeric columns in table "titleParts" */
export type SecondarySourcesTitleParts_Inc_Input = {
  depth: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  titleId: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "titleParts" */
export type SecondarySourcesTitleParts_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  baseId: InputMaybe<Scalars['String']['input']>;
  californiaCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  content: InputMaybe<Scalars['String']['input']>;
  contentId: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  htmlFile: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  immediateChildren: InputMaybe<SecondarySourcesTitleParts_Arr_Rel_Insert_Input>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  isForm: InputMaybe<Scalars['Boolean']['input']>;
  leftSibling: InputMaybe<SecondarySourcesTitleParts_Obj_Rel_Insert_Input>;
  name: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parent: InputMaybe<SecondarySourcesTitleParts_Obj_Rel_Insert_Input>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  rightSibling: InputMaybe<SecondarySourcesTitleParts_Obj_Rel_Insert_Input>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParent: InputMaybe<SecondarySourcesTitleParts_Obj_Rel_Insert_Input>;
  rootParentChildren: InputMaybe<SecondarySourcesTitleParts_Arr_Rel_Insert_Input>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  standardCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  title: InputMaybe<SecondarySourcesTitles_Obj_Rel_Insert_Input>;
  titleId: InputMaybe<Scalars['Int']['input']>;
  titleOnlawId: InputMaybe<Scalars['String']['input']>;
  titleProductCode: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type SecondarySourcesTitleParts_Max_Fields = {
  __typename?: 'SecondarySourcesTitleParts_max_fields';
  baseId: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  contentId: Maybe<Scalars['String']['output']>;
  depth: Maybe<Scalars['Int']['output']>;
  htmlFile: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  rootParentId: Maybe<Scalars['String']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
  titleOnlawId: Maybe<Scalars['String']['output']>;
  titleProductCode: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Max_Order_By = {
  baseId: InputMaybe<SecondarySourcesOrder_By>;
  content: InputMaybe<SecondarySourcesOrder_By>;
  contentId: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  htmlFile: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
  titleOnlawId: InputMaybe<SecondarySourcesOrder_By>;
  titleProductCode: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesTitleParts_Min_Fields = {
  __typename?: 'SecondarySourcesTitleParts_min_fields';
  baseId: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  contentId: Maybe<Scalars['String']['output']>;
  depth: Maybe<Scalars['Int']['output']>;
  htmlFile: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  name: Maybe<Scalars['String']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  parentId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  rightSiblingId: Maybe<Scalars['String']['output']>;
  rootParentId: Maybe<Scalars['String']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
  titleOnlawId: Maybe<Scalars['String']['output']>;
  titleProductCode: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Min_Order_By = {
  baseId: InputMaybe<SecondarySourcesOrder_By>;
  content: InputMaybe<SecondarySourcesOrder_By>;
  contentId: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  htmlFile: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
  titleOnlawId: InputMaybe<SecondarySourcesOrder_By>;
  titleProductCode: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "titleParts" */
export type SecondarySourcesTitleParts_Mutation_Response = {
  __typename?: 'SecondarySourcesTitleParts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesTitleParts>;
};

/** input type for inserting object relation for remote table "titleParts" */
export type SecondarySourcesTitleParts_Obj_Rel_Insert_Input = {
  data: SecondarySourcesTitleParts_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesTitleParts_On_Conflict>;
};

/** on_conflict condition type for table "titleParts" */
export type SecondarySourcesTitleParts_On_Conflict = {
  constraint: SecondarySourcesTitleParts_Constraint;
  update_columns: Array<SecondarySourcesTitleParts_Update_Column>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** Ordering options when selecting data from "titleParts". */
export type SecondarySourcesTitleParts_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  baseId: InputMaybe<SecondarySourcesOrder_By>;
  californiaCitationTemplate: InputMaybe<SecondarySourcesOrder_By>;
  content: InputMaybe<SecondarySourcesOrder_By>;
  contentId: InputMaybe<SecondarySourcesOrder_By>;
  depth: InputMaybe<SecondarySourcesOrder_By>;
  hasChildren: InputMaybe<SecondarySourcesOrder_By>;
  hasContent: InputMaybe<SecondarySourcesOrder_By>;
  htmlFile: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  immediateChildren_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Order_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  isForm: InputMaybe<SecondarySourcesOrder_By>;
  leftSibling: InputMaybe<SecondarySourcesTitleParts_Order_By>;
  name: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  parent: InputMaybe<SecondarySourcesTitleParts_Order_By>;
  parentId: InputMaybe<SecondarySourcesOrder_By>;
  path: InputMaybe<SecondarySourcesOrder_By>;
  rightSibling: InputMaybe<SecondarySourcesTitleParts_Order_By>;
  rightSiblingId: InputMaybe<SecondarySourcesOrder_By>;
  rootParent: InputMaybe<SecondarySourcesTitleParts_Order_By>;
  rootParentChildren_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Order_By>;
  rootParentId: InputMaybe<SecondarySourcesOrder_By>;
  standardCitationTemplate: InputMaybe<SecondarySourcesOrder_By>;
  title: InputMaybe<SecondarySourcesTitles_Order_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
  titleOnlawId: InputMaybe<SecondarySourcesOrder_By>;
  titleProductCode: InputMaybe<SecondarySourcesOrder_By>;
};

/** primary key columns input for table: titleParts */
export type SecondarySourcesTitleParts_Pk_Columns_Input = {
  contentId: Scalars['String']['input'];
};

/** select columns of table "titleParts" */
export enum SecondarySourcesTitleParts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BaseId = 'baseId',
  /** column name */
  CaliforniaCitationTemplate = 'californiaCitationTemplate',
  /** column name */
  Content = 'content',
  /** column name */
  ContentId = 'contentId',
  /** column name */
  Depth = 'depth',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  HtmlFile = 'htmlFile',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  IsForm = 'isForm',
  /** column name */
  Name = 'name',
  /** column name */
  OnlawId = 'onlawId',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Path = 'path',
  /** column name */
  RightSiblingId = 'rightSiblingId',
  /** column name */
  RootParentId = 'rootParentId',
  /** column name */
  StandardCitationTemplate = 'standardCitationTemplate',
  /** column name */
  TitleId = 'titleId',
  /** column name */
  TitleOnlawId = 'titleOnlawId',
  /** column name */
  TitleProductCode = 'titleProductCode',
}

/** select "titleParts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "titleParts" */
export enum SecondarySourcesTitleParts_Select_Column_TitleParts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  IsForm = 'isForm',
}

/** select "titleParts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "titleParts" */
export enum SecondarySourcesTitleParts_Select_Column_TitleParts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  IsForm = 'isForm',
}

/** input type for updating data in table "titleParts" */
export type SecondarySourcesTitleParts_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  baseId: InputMaybe<Scalars['String']['input']>;
  californiaCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  content: InputMaybe<Scalars['String']['input']>;
  contentId: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  htmlFile: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  isForm: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  standardCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  titleId: InputMaybe<Scalars['Int']['input']>;
  titleOnlawId: InputMaybe<Scalars['String']['input']>;
  titleProductCode: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesTitleParts_Stddev_Fields = {
  __typename?: 'SecondarySourcesTitleParts_stddev_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Stddev_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesTitleParts_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesTitleParts_stddev_pop_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Stddev_Pop_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesTitleParts_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesTitleParts_stddev_samp_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Stddev_Samp_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "titleParts" */
export type SecondarySourcesTitleParts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesTitleParts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesTitleParts_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  baseId: InputMaybe<Scalars['String']['input']>;
  californiaCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  content: InputMaybe<Scalars['String']['input']>;
  contentId: InputMaybe<Scalars['String']['input']>;
  depth: InputMaybe<Scalars['Int']['input']>;
  hasChildren: InputMaybe<Scalars['Boolean']['input']>;
  hasContent: InputMaybe<Scalars['Boolean']['input']>;
  htmlFile: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  isForm: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<Scalars['Int']['input']>;
  parentId: InputMaybe<Scalars['String']['input']>;
  path: InputMaybe<Scalars['String']['input']>;
  rightSiblingId: InputMaybe<Scalars['String']['input']>;
  rootParentId: InputMaybe<Scalars['String']['input']>;
  standardCitationTemplate: InputMaybe<
    Scalars['SecondarySourcesJson']['input']
  >;
  titleId: InputMaybe<Scalars['Int']['input']>;
  titleOnlawId: InputMaybe<Scalars['String']['input']>;
  titleProductCode: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesTitleParts_Sum_Fields = {
  __typename?: 'SecondarySourcesTitleParts_sum_fields';
  depth: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  order: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Sum_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "titleParts" */
export enum SecondarySourcesTitleParts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BaseId = 'baseId',
  /** column name */
  CaliforniaCitationTemplate = 'californiaCitationTemplate',
  /** column name */
  Content = 'content',
  /** column name */
  ContentId = 'contentId',
  /** column name */
  Depth = 'depth',
  /** column name */
  HasChildren = 'hasChildren',
  /** column name */
  HasContent = 'hasContent',
  /** column name */
  HtmlFile = 'htmlFile',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  IsForm = 'isForm',
  /** column name */
  Name = 'name',
  /** column name */
  OnlawId = 'onlawId',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Path = 'path',
  /** column name */
  RightSiblingId = 'rightSiblingId',
  /** column name */
  RootParentId = 'rootParentId',
  /** column name */
  StandardCitationTemplate = 'standardCitationTemplate',
  /** column name */
  TitleId = 'titleId',
  /** column name */
  TitleOnlawId = 'titleOnlawId',
  /** column name */
  TitleProductCode = 'titleProductCode',
}

export type SecondarySourcesTitleParts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesTitleParts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesTitleParts_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesTitleParts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesTitleParts_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesTitleParts_var_pop_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Var_Pop_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesTitleParts_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesTitleParts_var_samp_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Var_Samp_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesTitleParts_Variance_Fields = {
  __typename?: 'SecondarySourcesTitleParts_variance_fields';
  depth: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  order: Maybe<Scalars['Float']['output']>;
  titleId: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "titleParts" */
export type SecondarySourcesTitleParts_Variance_Order_By = {
  depth: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  order: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** columns and relationships of "titles" */
export type SecondarySourcesTitles = {
  __typename?: 'SecondarySourcesTitles';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  import: Maybe<SecondarySourcesImports>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  importSource: Maybe<Scalars['String']['output']>;
  onlawId: Scalars['String']['output'];
  /** An array relationship */
  practiceAreas: Array<SecondarySourcesPracticeAreaTitles>;
  /** An aggregate relationship */
  practiceAreas_aggregate: SecondarySourcesPracticeAreaTitles_Aggregate;
  productCode: Maybe<Scalars['String']['output']>;
  productTitle: Scalars['String']['output'];
  /** An object relationship */
  resourceType: Maybe<SecondarySourcesResourceTypes>;
  resourceTypeId: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  tableOfContents: Array<SecondarySourcesTitleParts>;
  /** An aggregate relationship */
  tableOfContents_aggregate: SecondarySourcesTitleParts_Aggregate;
  titleId: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "titles" */
export type SecondarySourcesTitlesPracticeAreasArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** columns and relationships of "titles" */
export type SecondarySourcesTitlesPracticeAreas_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<SecondarySourcesPracticeAreaTitles_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesPracticeAreaTitles_Order_By>>;
  where: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
};

/** columns and relationships of "titles" */
export type SecondarySourcesTitlesTableOfContentsArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** columns and relationships of "titles" */
export type SecondarySourcesTitlesTableOfContents_AggregateArgs = {
  distinct_on: InputMaybe<Array<SecondarySourcesTitleParts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<SecondarySourcesTitleParts_Order_By>>;
  where: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
};

/** aggregated selection of "titles" */
export type SecondarySourcesTitles_Aggregate = {
  __typename?: 'SecondarySourcesTitles_aggregate';
  aggregate: Maybe<SecondarySourcesTitles_Aggregate_Fields>;
  nodes: Array<SecondarySourcesTitles>;
};

export type SecondarySourcesTitles_Aggregate_Bool_Exp = {
  bool_and: InputMaybe<SecondarySourcesTitles_Aggregate_Bool_Exp_Bool_And>;
  bool_or: InputMaybe<SecondarySourcesTitles_Aggregate_Bool_Exp_Bool_Or>;
  count: InputMaybe<SecondarySourcesTitles_Aggregate_Bool_Exp_Count>;
};

export type SecondarySourcesTitles_Aggregate_Bool_Exp_Bool_And = {
  arguments: SecondarySourcesTitles_Select_Column_Titles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesTitles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SecondarySourcesTitles_Select_Column_Titles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  predicate: SecondarySourcesBoolean_Comparison_Exp;
};

export type SecondarySourcesTitles_Aggregate_Bool_Exp_Count = {
  arguments: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  predicate: SecondarySourcesInt_Comparison_Exp;
};

/** aggregate fields of "titles" */
export type SecondarySourcesTitles_Aggregate_Fields = {
  __typename?: 'SecondarySourcesTitles_aggregate_fields';
  avg: Maybe<SecondarySourcesTitles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max: Maybe<SecondarySourcesTitles_Max_Fields>;
  min: Maybe<SecondarySourcesTitles_Min_Fields>;
  stddev: Maybe<SecondarySourcesTitles_Stddev_Fields>;
  stddev_pop: Maybe<SecondarySourcesTitles_Stddev_Pop_Fields>;
  stddev_samp: Maybe<SecondarySourcesTitles_Stddev_Samp_Fields>;
  sum: Maybe<SecondarySourcesTitles_Sum_Fields>;
  var_pop: Maybe<SecondarySourcesTitles_Var_Pop_Fields>;
  var_samp: Maybe<SecondarySourcesTitles_Var_Samp_Fields>;
  variance: Maybe<SecondarySourcesTitles_Variance_Fields>;
};

/** aggregate fields of "titles" */
export type SecondarySourcesTitles_Aggregate_FieldsCountArgs = {
  columns: InputMaybe<Array<SecondarySourcesTitles_Select_Column>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "titles" */
export type SecondarySourcesTitles_Aggregate_Order_By = {
  avg: InputMaybe<SecondarySourcesTitles_Avg_Order_By>;
  count: InputMaybe<SecondarySourcesOrder_By>;
  max: InputMaybe<SecondarySourcesTitles_Max_Order_By>;
  min: InputMaybe<SecondarySourcesTitles_Min_Order_By>;
  stddev: InputMaybe<SecondarySourcesTitles_Stddev_Order_By>;
  stddev_pop: InputMaybe<SecondarySourcesTitles_Stddev_Pop_Order_By>;
  stddev_samp: InputMaybe<SecondarySourcesTitles_Stddev_Samp_Order_By>;
  sum: InputMaybe<SecondarySourcesTitles_Sum_Order_By>;
  var_pop: InputMaybe<SecondarySourcesTitles_Var_Pop_Order_By>;
  var_samp: InputMaybe<SecondarySourcesTitles_Var_Samp_Order_By>;
  variance: InputMaybe<SecondarySourcesTitles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "titles" */
export type SecondarySourcesTitles_Arr_Rel_Insert_Input = {
  data: Array<SecondarySourcesTitles_Insert_Input>;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesTitles_On_Conflict>;
};

/** aggregate avg on columns */
export type SecondarySourcesTitles_Avg_Fields = {
  __typename?: 'SecondarySourcesTitles_avg_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "titles" */
export type SecondarySourcesTitles_Avg_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Boolean expression to filter rows from the table "titles". All fields are combined with a logical 'AND'. */
export type SecondarySourcesTitles_Bool_Exp = {
  _and: InputMaybe<Array<SecondarySourcesTitles_Bool_Exp>>;
  _not: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
  _or: InputMaybe<Array<SecondarySourcesTitles_Bool_Exp>>;
  active: InputMaybe<SecondarySourcesBoolean_Comparison_Exp>;
  id: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  import: InputMaybe<SecondarySourcesImports_Bool_Exp>;
  importId: InputMaybe<SecondarySourcesBigint_Comparison_Exp>;
  importSource: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  onlawId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  practiceAreas: InputMaybe<SecondarySourcesPracticeAreaTitles_Bool_Exp>;
  practiceAreas_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Bool_Exp>;
  productCode: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  productTitle: InputMaybe<SecondarySourcesString_Comparison_Exp>;
  resourceType: InputMaybe<SecondarySourcesResourceTypes_Bool_Exp>;
  resourceTypeId: InputMaybe<SecondarySourcesInt_Comparison_Exp>;
  tableOfContents: InputMaybe<SecondarySourcesTitleParts_Bool_Exp>;
  tableOfContents_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Bool_Exp>;
  titleId: InputMaybe<SecondarySourcesString_Comparison_Exp>;
};

/** unique or primary key constraints on table "titles" */
export enum SecondarySourcesTitles_Constraint {
  /** unique or primary key constraint on columns "id" */
  TitlesIdKey = 'titles_id_key',
  /** unique or primary key constraint on columns "id" */
  TitlesPkey = 'titles_pkey',
}

/** input type for incrementing numeric columns in table "titles" */
export type SecondarySourcesTitles_Inc_Input = {
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  resourceTypeId: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "titles" */
export type SecondarySourcesTitles_Insert_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  import: InputMaybe<SecondarySourcesImports_Obj_Rel_Insert_Input>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  importSource: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  practiceAreas: InputMaybe<SecondarySourcesPracticeAreaTitles_Arr_Rel_Insert_Input>;
  productCode: InputMaybe<Scalars['String']['input']>;
  productTitle: InputMaybe<Scalars['String']['input']>;
  resourceType: InputMaybe<SecondarySourcesResourceTypes_Obj_Rel_Insert_Input>;
  resourceTypeId: InputMaybe<Scalars['Int']['input']>;
  tableOfContents: InputMaybe<SecondarySourcesTitleParts_Arr_Rel_Insert_Input>;
  titleId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type SecondarySourcesTitles_Max_Fields = {
  __typename?: 'SecondarySourcesTitles_max_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  importSource: Maybe<Scalars['String']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  productTitle: Maybe<Scalars['String']['output']>;
  resourceTypeId: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "titles" */
export type SecondarySourcesTitles_Max_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  importSource: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  productCode: InputMaybe<SecondarySourcesOrder_By>;
  productTitle: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate min on columns */
export type SecondarySourcesTitles_Min_Fields = {
  __typename?: 'SecondarySourcesTitles_min_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  importSource: Maybe<Scalars['String']['output']>;
  onlawId: Maybe<Scalars['String']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  productTitle: Maybe<Scalars['String']['output']>;
  resourceTypeId: Maybe<Scalars['Int']['output']>;
  titleId: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "titles" */
export type SecondarySourcesTitles_Min_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  importSource: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  productCode: InputMaybe<SecondarySourcesOrder_By>;
  productTitle: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** response of any mutation on the table "titles" */
export type SecondarySourcesTitles_Mutation_Response = {
  __typename?: 'SecondarySourcesTitles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondarySourcesTitles>;
};

/** input type for inserting object relation for remote table "titles" */
export type SecondarySourcesTitles_Obj_Rel_Insert_Input = {
  data: SecondarySourcesTitles_Insert_Input;
  /** upsert condition */
  on_conflict: InputMaybe<SecondarySourcesTitles_On_Conflict>;
};

/** on_conflict condition type for table "titles" */
export type SecondarySourcesTitles_On_Conflict = {
  constraint: SecondarySourcesTitles_Constraint;
  update_columns: Array<SecondarySourcesTitles_Update_Column>;
  where: InputMaybe<SecondarySourcesTitles_Bool_Exp>;
};

/** Ordering options when selecting data from "titles". */
export type SecondarySourcesTitles_Order_By = {
  active: InputMaybe<SecondarySourcesOrder_By>;
  id: InputMaybe<SecondarySourcesOrder_By>;
  import: InputMaybe<SecondarySourcesImports_Order_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  importSource: InputMaybe<SecondarySourcesOrder_By>;
  onlawId: InputMaybe<SecondarySourcesOrder_By>;
  practiceAreas_aggregate: InputMaybe<SecondarySourcesPracticeAreaTitles_Aggregate_Order_By>;
  productCode: InputMaybe<SecondarySourcesOrder_By>;
  productTitle: InputMaybe<SecondarySourcesOrder_By>;
  resourceType: InputMaybe<SecondarySourcesResourceTypes_Order_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
  tableOfContents_aggregate: InputMaybe<SecondarySourcesTitleParts_Aggregate_Order_By>;
  titleId: InputMaybe<SecondarySourcesOrder_By>;
};

/** primary key columns input for table: titles */
export type SecondarySourcesTitles_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "titles" */
export enum SecondarySourcesTitles_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  ImportSource = 'importSource',
  /** column name */
  OnlawId = 'onlawId',
  /** column name */
  ProductCode = 'productCode',
  /** column name */
  ProductTitle = 'productTitle',
  /** column name */
  ResourceTypeId = 'resourceTypeId',
  /** column name */
  TitleId = 'titleId',
}

/** select "titles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "titles" */
export enum SecondarySourcesTitles_Select_Column_Titles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "titles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "titles" */
export enum SecondarySourcesTitles_Select_Column_Titles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "titles" */
export type SecondarySourcesTitles_Set_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  importSource: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  productCode: InputMaybe<Scalars['String']['input']>;
  productTitle: InputMaybe<Scalars['String']['input']>;
  resourceTypeId: InputMaybe<Scalars['Int']['input']>;
  titleId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SecondarySourcesTitles_Stddev_Fields = {
  __typename?: 'SecondarySourcesTitles_stddev_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "titles" */
export type SecondarySourcesTitles_Stddev_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_pop on columns */
export type SecondarySourcesTitles_Stddev_Pop_Fields = {
  __typename?: 'SecondarySourcesTitles_stddev_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "titles" */
export type SecondarySourcesTitles_Stddev_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate stddev_samp on columns */
export type SecondarySourcesTitles_Stddev_Samp_Fields = {
  __typename?: 'SecondarySourcesTitles_stddev_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "titles" */
export type SecondarySourcesTitles_Stddev_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** Streaming cursor of the table "titles" */
export type SecondarySourcesTitles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecondarySourcesTitles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: InputMaybe<SecondarySourcesCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondarySourcesTitles_Stream_Cursor_Value_Input = {
  active: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['Int']['input']>;
  importId: InputMaybe<Scalars['SecondarySourcesBigint']['input']>;
  importSource: InputMaybe<Scalars['String']['input']>;
  onlawId: InputMaybe<Scalars['String']['input']>;
  productCode: InputMaybe<Scalars['String']['input']>;
  productTitle: InputMaybe<Scalars['String']['input']>;
  resourceTypeId: InputMaybe<Scalars['Int']['input']>;
  titleId: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SecondarySourcesTitles_Sum_Fields = {
  __typename?: 'SecondarySourcesTitles_sum_fields';
  id: Maybe<Scalars['Int']['output']>;
  importId: Maybe<Scalars['SecondarySourcesBigint']['output']>;
  resourceTypeId: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "titles" */
export type SecondarySourcesTitles_Sum_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** update columns of table "titles" */
export enum SecondarySourcesTitles_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImportId = 'importId',
  /** column name */
  ImportSource = 'importSource',
  /** column name */
  OnlawId = 'onlawId',
  /** column name */
  ProductCode = 'productCode',
  /** column name */
  ProductTitle = 'productTitle',
  /** column name */
  ResourceTypeId = 'resourceTypeId',
  /** column name */
  TitleId = 'titleId',
}

export type SecondarySourcesTitles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: InputMaybe<SecondarySourcesTitles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: InputMaybe<SecondarySourcesTitles_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecondarySourcesTitles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecondarySourcesTitles_Var_Pop_Fields = {
  __typename?: 'SecondarySourcesTitles_var_pop_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "titles" */
export type SecondarySourcesTitles_Var_Pop_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate var_samp on columns */
export type SecondarySourcesTitles_Var_Samp_Fields = {
  __typename?: 'SecondarySourcesTitles_var_samp_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "titles" */
export type SecondarySourcesTitles_Var_Samp_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

/** aggregate variance on columns */
export type SecondarySourcesTitles_Variance_Fields = {
  __typename?: 'SecondarySourcesTitles_variance_fields';
  id: Maybe<Scalars['Float']['output']>;
  importId: Maybe<Scalars['Float']['output']>;
  resourceTypeId: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "titles" */
export type SecondarySourcesTitles_Variance_Order_By = {
  id: InputMaybe<SecondarySourcesOrder_By>;
  importId: InputMaybe<SecondarySourcesOrder_By>;
  resourceTypeId: InputMaybe<SecondarySourcesOrder_By>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

/** The Session type contains authentication information */
export type Session = {
  __typename?: 'Session';
  token: Maybe<Scalars['String']['output']>;
};

export type Snippet = {
  __typename?: 'Snippet';
  id: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

export type SpellingTypeaheadResult = {
  __typename?: 'SpellingTypeaheadResult';
  actionText: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Statute = {
  __typename?: 'Statute';
  /** Associated Codes */
  codes: Maybe<Array<Maybe<Code>>>;
  /** The id of the jurisdiction in Judicata */
  jurisdictionId: Maybe<Scalars['String']['output']>;
  /** The name of the statute */
  name: Maybe<Scalars['String']['output']>;
};

/** Filter options for statute refs */
export type StatuteReferenceInput = {
  citedStatuteRegArticleId: InputMaybe<Scalars['String']['input']>;
  citedStatuteRegJudicataId: Scalars['String']['input'];
  citedStatuteRegJurisdictionId: Scalars['String']['input'];
  citedStatuteRegSectionId: Scalars['String']['input'];
};

export type StatuteSearchEdge = {
  __typename?: 'StatuteSearchEdge';
  missingTerms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  node: StatuteSearchNode;
  snippets: Maybe<Array<Maybe<Snippet>>>;
};

export type StatuteSearchNode = CaConstitutionSection | CodeSection;

export type Subscription = {
  __typename?: 'Subscription';
  activatedTimestamp: Maybe<Scalars['String']['output']>;
  amount: Maybe<Scalars['Float']['output']>;
  canMigrate: Maybe<Scalars['Boolean']['output']>;
  cancelledByCustomer: Maybe<Scalars['Boolean']['output']>;
  contractEndTimestamp: Maybe<Scalars['String']['output']>;
  contractRemainingIntervals: Maybe<Scalars['Int']['output']>;
  contractStartTimestamp: Maybe<Scalars['String']['output']>;
  createdTimestamp: Maybe<Scalars['String']['output']>;
  /** fetch data from the table: "attorneys" */
  matterServiceAttorneys: Array<MatterServiceAttorneys>;
  /** fetch aggregated fields from the table: "attorneys" */
  matterServiceAttorneys_aggregate: MatterServiceAttorneys_Aggregate;
  /** fetch data from the table: "attorneys" using primary key columns */
  matterServiceAttorneys_by_pk: Maybe<MatterServiceAttorneys>;
  /** fetch data from the table in a streaming manner: "attorneys" */
  matterServiceAttorneys_stream: Array<MatterServiceAttorneys>;
  /** fetch data from the table: "clients" */
  matterServiceClients: Array<MatterServiceClients>;
  /** fetch aggregated fields from the table: "clients" */
  matterServiceClients_aggregate: MatterServiceClients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  matterServiceClients_by_pk: Maybe<MatterServiceClients>;
  /** fetch data from the table in a streaming manner: "clients" */
  matterServiceClients_stream: Array<MatterServiceClients>;
  /** fetch data from the table: "courts" */
  matterServiceCourts: Array<MatterServiceCourts>;
  /** fetch aggregated fields from the table: "courts" */
  matterServiceCourts_aggregate: MatterServiceCourts_Aggregate;
  /** fetch data from the table: "courts" using primary key columns */
  matterServiceCourts_by_pk: Maybe<MatterServiceCourts>;
  /** fetch data from the table in a streaming manner: "courts" */
  matterServiceCourts_stream: Array<MatterServiceCourts>;
  /** fetch data from the table: "domesticPartnerships" */
  matterServiceDomesticPartnerships: Array<MatterServiceDomesticPartnerships>;
  /** fetch aggregated fields from the table: "domesticPartnerships" */
  matterServiceDomesticPartnerships_aggregate: MatterServiceDomesticPartnerships_Aggregate;
  /** fetch data from the table: "domesticPartnerships" using primary key columns */
  matterServiceDomesticPartnerships_by_pk: Maybe<MatterServiceDomesticPartnerships>;
  /** fetch data from the table in a streaming manner: "domesticPartnerships" */
  matterServiceDomesticPartnerships_stream: Array<MatterServiceDomesticPartnerships>;
  /** fetch data from the table: "entityAddressTypes" */
  matterServiceEntityAddressTypes: Array<MatterServiceEntityAddressTypes>;
  /** fetch aggregated fields from the table: "entityAddressTypes" */
  matterServiceEntityAddressTypes_aggregate: MatterServiceEntityAddressTypes_Aggregate;
  /** fetch data from the table: "entityAddressTypes" using primary key columns */
  matterServiceEntityAddressTypes_by_pk: Maybe<MatterServiceEntityAddressTypes>;
  /** fetch data from the table in a streaming manner: "entityAddressTypes" */
  matterServiceEntityAddressTypes_stream: Array<MatterServiceEntityAddressTypes>;
  /** fetch data from the table: "entityAddresses" */
  matterServiceEntityAddresses: Array<MatterServiceEntityAddresses>;
  /** fetch aggregated fields from the table: "entityAddresses" */
  matterServiceEntityAddresses_aggregate: MatterServiceEntityAddresses_Aggregate;
  /** fetch data from the table: "entityAddresses" using primary key columns */
  matterServiceEntityAddresses_by_pk: Maybe<MatterServiceEntityAddresses>;
  /** fetch data from the table in a streaming manner: "entityAddresses" */
  matterServiceEntityAddresses_stream: Array<MatterServiceEntityAddresses>;
  /** fetch data from the table: "entityEmailTypes" */
  matterServiceEntityEmailTypes: Array<MatterServiceEntityEmailTypes>;
  /** fetch aggregated fields from the table: "entityEmailTypes" */
  matterServiceEntityEmailTypes_aggregate: MatterServiceEntityEmailTypes_Aggregate;
  /** fetch data from the table: "entityEmailTypes" using primary key columns */
  matterServiceEntityEmailTypes_by_pk: Maybe<MatterServiceEntityEmailTypes>;
  /** fetch data from the table in a streaming manner: "entityEmailTypes" */
  matterServiceEntityEmailTypes_stream: Array<MatterServiceEntityEmailTypes>;
  /** fetch data from the table: "entityEmails" */
  matterServiceEntityEmails: Array<MatterServiceEntityEmails>;
  /** fetch aggregated fields from the table: "entityEmails" */
  matterServiceEntityEmails_aggregate: MatterServiceEntityEmails_Aggregate;
  /** fetch data from the table: "entityEmails" using primary key columns */
  matterServiceEntityEmails_by_pk: Maybe<MatterServiceEntityEmails>;
  /** fetch data from the table in a streaming manner: "entityEmails" */
  matterServiceEntityEmails_stream: Array<MatterServiceEntityEmails>;
  /** fetch data from the table: "entityPhoneNumberTypes" */
  matterServiceEntityPhoneNumberTypes: Array<MatterServiceEntityPhoneNumberTypes>;
  /** fetch aggregated fields from the table: "entityPhoneNumberTypes" */
  matterServiceEntityPhoneNumberTypes_aggregate: MatterServiceEntityPhoneNumberTypes_Aggregate;
  /** fetch data from the table: "entityPhoneNumberTypes" using primary key columns */
  matterServiceEntityPhoneNumberTypes_by_pk: Maybe<MatterServiceEntityPhoneNumberTypes>;
  /** fetch data from the table in a streaming manner: "entityPhoneNumberTypes" */
  matterServiceEntityPhoneNumberTypes_stream: Array<MatterServiceEntityPhoneNumberTypes>;
  /** fetch data from the table: "entityPhoneNumbers" */
  matterServiceEntityPhoneNumbers: Array<MatterServiceEntityPhoneNumbers>;
  /** fetch aggregated fields from the table: "entityPhoneNumbers" */
  matterServiceEntityPhoneNumbers_aggregate: MatterServiceEntityPhoneNumbers_Aggregate;
  /** fetch data from the table: "entityPhoneNumbers" using primary key columns */
  matterServiceEntityPhoneNumbers_by_pk: Maybe<MatterServiceEntityPhoneNumbers>;
  /** fetch data from the table in a streaming manner: "entityPhoneNumbers" */
  matterServiceEntityPhoneNumbers_stream: Array<MatterServiceEntityPhoneNumbers>;
  /** fetch data from the table: "marriages" */
  matterServiceMarriages: Array<MatterServiceMarriages>;
  /** fetch aggregated fields from the table: "marriages" */
  matterServiceMarriages_aggregate: MatterServiceMarriages_Aggregate;
  /** fetch data from the table: "marriages" using primary key columns */
  matterServiceMarriages_by_pk: Maybe<MatterServiceMarriages>;
  /** fetch data from the table in a streaming manner: "marriages" */
  matterServiceMarriages_stream: Array<MatterServiceMarriages>;
  /** fetch data from the table: "matterAttorneyRoleTypes" */
  matterServiceMatterAttorneyRoleTypes: Array<MatterServiceMatterAttorneyRoleTypes>;
  /** fetch aggregated fields from the table: "matterAttorneyRoleTypes" */
  matterServiceMatterAttorneyRoleTypes_aggregate: MatterServiceMatterAttorneyRoleTypes_Aggregate;
  /** fetch data from the table: "matterAttorneyRoleTypes" using primary key columns */
  matterServiceMatterAttorneyRoleTypes_by_pk: Maybe<MatterServiceMatterAttorneyRoleTypes>;
  /** fetch data from the table in a streaming manner: "matterAttorneyRoleTypes" */
  matterServiceMatterAttorneyRoleTypes_stream: Array<MatterServiceMatterAttorneyRoleTypes>;
  /** fetch data from the table: "matterDocsAndForms" */
  matterServiceMatterDocsAndForms: Array<MatterServiceMatterDocsAndForms>;
  /** fetch data from the table: "matterDocsAndFormsFolders" */
  matterServiceMatterDocsAndFormsFolders: Array<MatterServiceMatterDocsAndFormsFolders>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsFolders" */
  matterServiceMatterDocsAndFormsFolders_aggregate: MatterServiceMatterDocsAndFormsFolders_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsFolders" using primary key columns */
  matterServiceMatterDocsAndFormsFolders_by_pk: Maybe<MatterServiceMatterDocsAndFormsFolders>;
  /** fetch data from the table in a streaming manner: "matterDocsAndFormsFolders" */
  matterServiceMatterDocsAndFormsFolders_stream: Array<MatterServiceMatterDocsAndFormsFolders>;
  /** fetch data from the table: "matterDocsAndFormsStatuses" */
  matterServiceMatterDocsAndFormsStatuses: Array<MatterServiceMatterDocsAndFormsStatuses>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsStatuses" */
  matterServiceMatterDocsAndFormsStatuses_aggregate: MatterServiceMatterDocsAndFormsStatuses_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsStatuses" using primary key columns */
  matterServiceMatterDocsAndFormsStatuses_by_pk: Maybe<MatterServiceMatterDocsAndFormsStatuses>;
  /** fetch data from the table in a streaming manner: "matterDocsAndFormsStatuses" */
  matterServiceMatterDocsAndFormsStatuses_stream: Array<MatterServiceMatterDocsAndFormsStatuses>;
  /** fetch data from the table: "matterDocsAndFormsTypes" */
  matterServiceMatterDocsAndFormsTypes: Array<MatterServiceMatterDocsAndFormsTypes>;
  /** fetch aggregated fields from the table: "matterDocsAndFormsTypes" */
  matterServiceMatterDocsAndFormsTypes_aggregate: MatterServiceMatterDocsAndFormsTypes_Aggregate;
  /** fetch data from the table: "matterDocsAndFormsTypes" using primary key columns */
  matterServiceMatterDocsAndFormsTypes_by_pk: Maybe<MatterServiceMatterDocsAndFormsTypes>;
  /** fetch data from the table in a streaming manner: "matterDocsAndFormsTypes" */
  matterServiceMatterDocsAndFormsTypes_stream: Array<MatterServiceMatterDocsAndFormsTypes>;
  /** fetch aggregated fields from the table: "matterDocsAndForms" */
  matterServiceMatterDocsAndForms_aggregate: MatterServiceMatterDocsAndForms_Aggregate;
  /** fetch data from the table: "matterDocsAndForms" using primary key columns */
  matterServiceMatterDocsAndForms_by_pk: Maybe<MatterServiceMatterDocsAndForms>;
  /** fetch data from the table in a streaming manner: "matterDocsAndForms" */
  matterServiceMatterDocsAndForms_stream: Array<MatterServiceMatterDocsAndForms>;
  /** An array relationship */
  matterServiceMatterEntities: Array<MatterServiceMatterEntities>;
  /** An aggregate relationship */
  matterServiceMatterEntities_aggregate: MatterServiceMatterEntities_Aggregate;
  /** fetch data from the table: "matterEntities" using primary key columns */
  matterServiceMatterEntities_by_pk: Maybe<MatterServiceMatterEntities>;
  /** fetch data from the table in a streaming manner: "matterEntities" */
  matterServiceMatterEntities_stream: Array<MatterServiceMatterEntities>;
  /** fetch data from the table: "matterEntityTypes" */
  matterServiceMatterEntityTypes: Array<MatterServiceMatterEntityTypes>;
  /** fetch aggregated fields from the table: "matterEntityTypes" */
  matterServiceMatterEntityTypes_aggregate: MatterServiceMatterEntityTypes_Aggregate;
  /** fetch data from the table: "matterEntityTypes" using primary key columns */
  matterServiceMatterEntityTypes_by_pk: Maybe<MatterServiceMatterEntityTypes>;
  /** fetch data from the table in a streaming manner: "matterEntityTypes" */
  matterServiceMatterEntityTypes_stream: Array<MatterServiceMatterEntityTypes>;
  /** fetch data from the table: "matterFilingForTypes" */
  matterServiceMatterFilingForTypes: Array<MatterServiceMatterFilingForTypes>;
  /** fetch aggregated fields from the table: "matterFilingForTypes" */
  matterServiceMatterFilingForTypes_aggregate: MatterServiceMatterFilingForTypes_Aggregate;
  /** fetch data from the table: "matterFilingForTypes" using primary key columns */
  matterServiceMatterFilingForTypes_by_pk: Maybe<MatterServiceMatterFilingForTypes>;
  /** fetch data from the table in a streaming manner: "matterFilingForTypes" */
  matterServiceMatterFilingForTypes_stream: Array<MatterServiceMatterFilingForTypes>;
  /** fetch data from the table: "matterOrganizationRoleTypes" */
  matterServiceMatterOrganizationRoleTypes: Array<MatterServiceMatterOrganizationRoleTypes>;
  /** fetch aggregated fields from the table: "matterOrganizationRoleTypes" */
  matterServiceMatterOrganizationRoleTypes_aggregate: MatterServiceMatterOrganizationRoleTypes_Aggregate;
  /** fetch data from the table: "matterOrganizationRoleTypes" using primary key columns */
  matterServiceMatterOrganizationRoleTypes_by_pk: Maybe<MatterServiceMatterOrganizationRoleTypes>;
  /** fetch data from the table in a streaming manner: "matterOrganizationRoleTypes" */
  matterServiceMatterOrganizationRoleTypes_stream: Array<MatterServiceMatterOrganizationRoleTypes>;
  /** fetch data from the table: "matterPersonRoleTypes" */
  matterServiceMatterPersonRoleTypes: Array<MatterServiceMatterPersonRoleTypes>;
  /** fetch aggregated fields from the table: "matterPersonRoleTypes" */
  matterServiceMatterPersonRoleTypes_aggregate: MatterServiceMatterPersonRoleTypes_Aggregate;
  /** fetch data from the table: "matterPersonRoleTypes" using primary key columns */
  matterServiceMatterPersonRoleTypes_by_pk: Maybe<MatterServiceMatterPersonRoleTypes>;
  /** fetch data from the table in a streaming manner: "matterPersonRoleTypes" */
  matterServiceMatterPersonRoleTypes_stream: Array<MatterServiceMatterPersonRoleTypes>;
  /** fetch data from the table: "matterTypes" */
  matterServiceMatterTypes: Array<MatterServiceMatterTypes>;
  /** fetch aggregated fields from the table: "matterTypes" */
  matterServiceMatterTypes_aggregate: MatterServiceMatterTypes_Aggregate;
  /** fetch data from the table: "matterTypes" using primary key columns */
  matterServiceMatterTypes_by_pk: Maybe<MatterServiceMatterTypes>;
  /** fetch data from the table in a streaming manner: "matterTypes" */
  matterServiceMatterTypes_stream: Array<MatterServiceMatterTypes>;
  /** An array relationship */
  matterServiceMatters: Array<MatterServiceMatters>;
  /** An aggregate relationship */
  matterServiceMatters_aggregate: MatterServiceMatters_Aggregate;
  /** fetch data from the table: "matters" using primary key columns */
  matterServiceMatters_by_pk: Maybe<MatterServiceMatters>;
  /** fetch data from the table in a streaming manner: "matters" */
  matterServiceMatters_stream: Array<MatterServiceMatters>;
  /** fetch data from the table: "organizations" */
  matterServiceOrganizations: Array<MatterServiceOrganizations>;
  /** fetch aggregated fields from the table: "organizations" */
  matterServiceOrganizations_aggregate: MatterServiceOrganizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  matterServiceOrganizations_by_pk: Maybe<MatterServiceOrganizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  matterServiceOrganizations_stream: Array<MatterServiceOrganizations>;
  /** fetch data from the table: "people" */
  matterServicePeople: Array<MatterServicePeople>;
  /** fetch data from the table: "peopleGenders" */
  matterServicePeopleGenders: Array<MatterServicePeopleGenders>;
  /** fetch aggregated fields from the table: "peopleGenders" */
  matterServicePeopleGenders_aggregate: MatterServicePeopleGenders_Aggregate;
  /** fetch data from the table: "peopleGenders" using primary key columns */
  matterServicePeopleGenders_by_pk: Maybe<MatterServicePeopleGenders>;
  /** fetch data from the table in a streaming manner: "peopleGenders" */
  matterServicePeopleGenders_stream: Array<MatterServicePeopleGenders>;
  /** fetch aggregated fields from the table: "people" */
  matterServicePeople_aggregate: MatterServicePeople_Aggregate;
  /** fetch data from the table: "people" using primary key columns */
  matterServicePeople_by_pk: Maybe<MatterServicePeople>;
  /** fetch data from the table in a streaming manner: "people" */
  matterServicePeople_stream: Array<MatterServicePeople>;
  /** fetch data from the table: "temporaryRestrainingOrders" */
  matterServiceTemporaryRestrainingOrders: Array<MatterServiceTemporaryRestrainingOrders>;
  /** fetch aggregated fields from the table: "temporaryRestrainingOrders" */
  matterServiceTemporaryRestrainingOrders_aggregate: MatterServiceTemporaryRestrainingOrders_Aggregate;
  /** fetch data from the table: "temporaryRestrainingOrders" using primary key columns */
  matterServiceTemporaryRestrainingOrders_by_pk: Maybe<MatterServiceTemporaryRestrainingOrders>;
  /** fetch data from the table in a streaming manner: "temporaryRestrainingOrders" */
  matterServiceTemporaryRestrainingOrders_stream: Array<MatterServiceTemporaryRestrainingOrders>;
  nextPeriodStartDate: Maybe<Scalars['String']['output']>;
  planCode: Maybe<Scalars['String']['output']>;
  planName: Maybe<Scalars['String']['output']>;
  price: Maybe<SubscriptionPrice>;
  products: Maybe<Array<Maybe<SubscriptionProduct>>>;
  providesCLE: Maybe<Scalars['Boolean']['output']>;
  providesLegalResearch: Maybe<Scalars['Boolean']['output']>;
  remainingInterval: Maybe<Scalars['Int']['output']>;
  scheduledActivationTimestamp: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  subscriptionId: Maybe<Scalars['ID']['output']>;
  subscriptionInterval: Maybe<SubscriptionInterval>;
};

export type SubscriptionMatterServiceAttorneysArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

export type SubscriptionMatterServiceAttorneys_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceAttorneys_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceAttorneys_Order_By>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

export type SubscriptionMatterServiceAttorneys_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceAttorneys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceAttorneys_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceAttorneys_Bool_Exp>;
};

export type SubscriptionMatterServiceClientsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceClients_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceClients_Order_By>>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

export type SubscriptionMatterServiceClients_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceClients_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceClients_Order_By>>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

export type SubscriptionMatterServiceClients_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceClients_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceClients_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceClients_Bool_Exp>;
};

export type SubscriptionMatterServiceCourtsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceCourts_Order_By>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

export type SubscriptionMatterServiceCourts_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceCourts_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceCourts_Order_By>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

export type SubscriptionMatterServiceCourts_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceCourts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceCourts_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceCourts_Bool_Exp>;
};

export type SubscriptionMatterServiceDomesticPartnershipsArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceDomesticPartnerships_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceDomesticPartnerships_Order_By>>;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

export type SubscriptionMatterServiceDomesticPartnerships_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceDomesticPartnerships_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceDomesticPartnerships_Order_By>>;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

export type SubscriptionMatterServiceDomesticPartnerships_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceDomesticPartnerships_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceDomesticPartnerships_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceDomesticPartnerships_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddressTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddressTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddressTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddressTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddressTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddressTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddressTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceEntityAddressTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceEntityAddressTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceEntityAddressTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddressesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddresses_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityAddresses_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityAddresses_Order_By>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityAddresses_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceEntityAddresses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceEntityAddresses_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceEntityAddresses_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmailTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmailTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmailTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmailTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmailTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmailTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmailTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceEntityEmailTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceEntityEmailTypes_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceEntityEmailTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmailsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmails_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityEmails_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityEmails_Order_By>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityEmails_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceEntityEmails_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceEntityEmails_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceEntityEmails_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumberTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceEntityPhoneNumberTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumberTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceEntityPhoneNumberTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumberTypes_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumberTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceEntityPhoneNumberTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceEntityPhoneNumberTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceEntityPhoneNumberTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumbersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumbers_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceEntityPhoneNumbers_Order_By>>;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

export type SubscriptionMatterServiceEntityPhoneNumbers_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceEntityPhoneNumbers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceEntityPhoneNumbers_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceEntityPhoneNumbers_Bool_Exp>;
};

export type SubscriptionMatterServiceMarriagesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMarriages_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMarriages_Order_By>>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

export type SubscriptionMatterServiceMarriages_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMarriages_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMarriages_Order_By>>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

export type SubscriptionMatterServiceMarriages_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceMarriages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceMarriages_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceMarriages_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterAttorneyRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterAttorneyRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterAttorneyRoleTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterAttorneyRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterAttorneyRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterAttorneyRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterAttorneyRoleTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterAttorneyRoleTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterAttorneyRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsFoldersArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsFolders_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsFolders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsFolders_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsFolders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceMatterDocsAndFormsFolders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterDocsAndFormsFolders_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterDocsAndFormsFolders_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsStatusesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsStatuses_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsStatuses_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsStatuses_AggregateArgs =
  {
    distinct_on: InputMaybe<
      Array<MatterServiceMatterDocsAndFormsStatuses_Select_Column>
    >;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    order_by: InputMaybe<
      Array<MatterServiceMatterDocsAndFormsStatuses_Order_By>
    >;
    where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
  };

export type SubscriptionMatterServiceMatterDocsAndFormsStatuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterDocsAndFormsStatuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterDocsAndFormsTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndFormsTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndFormsTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterDocsAndFormsTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterDocsAndFormsTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterDocsAndFormsTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndForms_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterDocsAndForms_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterDocsAndForms_Order_By>>;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterDocsAndForms_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceMatterDocsAndForms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterDocsAndForms_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterDocsAndForms_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntitiesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntities_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntities_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntities_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntities_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceMatterEntities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceMatterEntities_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceMatterEntities_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntityTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntityTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntityTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntityTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterEntityTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterEntityTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterEntityTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterEntityTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceMatterEntityTypes_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceMatterEntityTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterFilingForTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterFilingForTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterFilingForTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterFilingForTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterFilingForTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterFilingForTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterFilingForTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterFilingForTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterFilingForTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterFilingForTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterOrganizationRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<
    Array<MatterServiceMatterOrganizationRoleTypes_Order_By>
  >;
  where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterOrganizationRoleTypes_AggregateArgs =
  {
    distinct_on: InputMaybe<
      Array<MatterServiceMatterOrganizationRoleTypes_Select_Column>
    >;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    order_by: InputMaybe<
      Array<MatterServiceMatterOrganizationRoleTypes_Order_By>
    >;
    where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
  };

export type SubscriptionMatterServiceMatterOrganizationRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterOrganizationRoleTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterOrganizationRoleTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterOrganizationRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterPersonRoleTypesArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterPersonRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterPersonRoleTypes_AggregateArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceMatterPersonRoleTypes_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterPersonRoleTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterPersonRoleTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterPersonRoleTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceMatterPersonRoleTypes_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceMatterPersonRoleTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterTypesArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterTypes_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatterTypes_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatterTypes_Order_By>>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMatterTypes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServiceMatterTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceMatterTypes_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceMatterTypes_Bool_Exp>;
};

export type SubscriptionMatterServiceMattersArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

export type SubscriptionMatterServiceMatters_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceMatters_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceMatters_Order_By>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

export type SubscriptionMatterServiceMatters_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceMatters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceMatters_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceMatters_Bool_Exp>;
};

export type SubscriptionMatterServiceOrganizationsArgs = {
  distinct_on: InputMaybe<Array<MatterServiceOrganizations_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceOrganizations_Order_By>>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

export type SubscriptionMatterServiceOrganizations_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServiceOrganizations_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceOrganizations_Order_By>>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

export type SubscriptionMatterServiceOrganizations_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceOrganizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServiceOrganizations_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServiceOrganizations_Bool_Exp>;
};

export type SubscriptionMatterServicePeopleArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

export type SubscriptionMatterServicePeopleGendersArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeopleGenders_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeopleGenders_Order_By>>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

export type SubscriptionMatterServicePeopleGenders_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeopleGenders_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeopleGenders_Order_By>>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

export type SubscriptionMatterServicePeopleGenders_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type SubscriptionMatterServicePeopleGenders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServicePeopleGenders_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServicePeopleGenders_Bool_Exp>;
};

export type SubscriptionMatterServicePeople_AggregateArgs = {
  distinct_on: InputMaybe<Array<MatterServicePeople_Select_Column>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServicePeople_Order_By>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

export type SubscriptionMatterServicePeople_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServicePeople_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MatterServicePeople_Stream_Cursor_Input>>;
  where: InputMaybe<MatterServicePeople_Bool_Exp>;
};

export type SubscriptionMatterServiceTemporaryRestrainingOrdersArgs = {
  distinct_on: InputMaybe<
    Array<MatterServiceTemporaryRestrainingOrders_Select_Column>
  >;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MatterServiceTemporaryRestrainingOrders_Order_By>>;
  where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
};

export type SubscriptionMatterServiceTemporaryRestrainingOrders_AggregateArgs =
  {
    distinct_on: InputMaybe<
      Array<MatterServiceTemporaryRestrainingOrders_Select_Column>
    >;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    order_by: InputMaybe<
      Array<MatterServiceTemporaryRestrainingOrders_Order_By>
    >;
    where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
  };

export type SubscriptionMatterServiceTemporaryRestrainingOrders_By_PkArgs = {
  id: Scalars['MatterServiceUuid']['input'];
};

export type SubscriptionMatterServiceTemporaryRestrainingOrders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<MatterServiceTemporaryRestrainingOrders_Stream_Cursor_Input>
  >;
  where: InputMaybe<MatterServiceTemporaryRestrainingOrders_Bool_Exp>;
};

export enum SubscriptionInterval {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type SubscriptionPreviewInput = {
  couponCode: InputMaybe<Scalars['String']['input']>;
  interval: SubscriptionInterval;
  planCode: Scalars['String']['input'];
  productCodes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productCodesWithQuantities: InputMaybe<
    Array<InputMaybe<ProductCodeWithQuantityInput>>
  >;
};

export type SubscriptionPrice = {
  __typename?: 'SubscriptionPrice';
  amount: Maybe<Scalars['Float']['output']>;
  discountAmount: Maybe<Scalars['Float']['output']>;
  discountType: Maybe<Scalars['String']['output']>;
  totalAmount: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  isRecurring: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  productType: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Float']['output']>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Migrated = 'MIGRATED',
  Provisioning = 'PROVISIONING',
  Suspended = 'SUSPENDED',
}

export type TableOfContentsEntry = {
  __typename?: 'TableOfContentsEntry';
  depth: Maybe<Scalars['String']['output']>;
  index: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  sectionBegin: Maybe<Scalars['String']['output']>;
  sectionEnd: Maybe<Scalars['String']['output']>;
  specifier: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type TrueDocsAndFormsCollectionsSearchFilterInput = {
  contentfulIds_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contentfulIds_not_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  practiceAreaIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TrueDocsAndFormsCollectionsTypeaheadResult = {
  __typename?: 'TrueDocsAndFormsCollectionsTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type TrueDocsAndFormsSearchFilterInput = {
  contentfulIds_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contentfulIds_not_in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  courtIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  practiceAreaIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  trueFormSubtypes: InputMaybe<
    Array<InputMaybe<Scalars['TrueFormSubtype']['input']>>
  >;
};

export type TrueDocsAndFormsTypeaheadResult = {
  __typename?: 'TrueDocsAndFormsTypeaheadResult';
  contentfulId: Scalars['String']['output'];
  formNumber: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type TypeaheadCacheResult = {
  __typename?: 'TypeaheadCacheResult';
  displayValue: Maybe<Scalars['String']['output']>;
  extraMatchText: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type TypeaheadSearchResult =
  | CaseCitatorTypeaheadResult
  | CaseDocumentTypeaheadResult
  | CodeOutlineTypeaheadResult
  | CodeSectionCitatorTypeaheadResult
  | CodeSectionDocumentTypeaheadResult
  | CourseTypeaheadResult
  | CoursesCollectionTypeaheadResult
  | CurrentAwarenessTypeaheadResult
  | NgramTypeaheadResult
  | OnlawOutlineTypeaheadResult
  | OnlawSearchCaseTypeaheadResult
  | OnlawSearchCodeTypeaheadResult
  | PractitionerDocumentTypeaheadResult
  | PractitionerSearchCaseTypeaheadResult
  | SpellingTypeaheadResult
  | TrueDocsAndFormsCollectionsTypeaheadResult
  | TrueDocsAndFormsTypeaheadResult;

export enum TypeaheadSearchType {
  CaseCitator = 'CASE_CITATOR',
  CaseDocument = 'CASE_DOCUMENT',
  CodeOutline = 'CODE_OUTLINE',
  CodeSectionCitator = 'CODE_SECTION_CITATOR',
  CodeSectionDocument = 'CODE_SECTION_DOCUMENT',
  Course = 'COURSE',
  CoursesCollection = 'COURSES_COLLECTION',
  CurrentAwareness = 'CURRENT_AWARENESS',
  Ngram = 'NGRAM',
  OnlawOutline = 'ONLAW_OUTLINE',
  OnlawSearchCase = 'ONLAW_SEARCH_CASE',
  OnlawSearchCodeSection = 'ONLAW_SEARCH_CODE_SECTION',
  PractitionerDocument = 'PRACTITIONER_DOCUMENT',
  PractitionerSearchCase = 'PRACTITIONER_SEARCH_CASE',
  Spelling = 'SPELLING',
  TrueDocsAndForms = 'TRUE_DOCS_AND_FORMS',
  TrueDocsAndFormsCollections = 'TRUE_DOCS_AND_FORMS_COLLECTIONS',
}

export type UpdateSubscriptionInput = {
  remainingInterval: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type User = {
  __typename?: 'User';
  accountId: Maybe<Scalars['String']['output']>;
  accountNumber: Maybe<Scalars['String']['output']>;
  addressInfo: Maybe<AddressInfo>;
  barNumber: Maybe<Scalars['String']['output']>;
  cleComplianceDeadline: Maybe<CleComplianceDeadline>;
  cleCourseCredit: Maybe<CleCourseCredit>;
  cleCourseCredits: Maybe<Array<Maybe<CleCourseCredit>>>;
  cleCreditAreaSummary: Maybe<Array<Maybe<CleCreditArea>>>;
  cleCreditRequirement: Maybe<Scalars['Int']['output']>;
  cleCreditsEarnedFromCeb: Maybe<Scalars['Float']['output']>;
  cleSpecializationSummary: Maybe<Array<Maybe<CleSpecializationSummaryArea>>>;
  complianceGroupOverrideId: Maybe<
    Scalars['ComplianceGroupOverrideId']['output']
  >;
  /** the user's fusebill customer ID */
  customerId: Maybe<Scalars['ID']['output']>;
  email: Maybe<Scalars['String']['output']>;
  engagementLevel: Maybe<Scalars['String']['output']>;
  firmPermissions: Maybe<FirmPermissions>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  /** DEPRECATED: use customerId instead */
  fusebillId: Maybe<Scalars['ID']['output']>;
  invoice: Maybe<Invoice>;
  invoices: Maybe<PagedInvoiceConnection>;
  isFirmAdmin: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  needsEmailUpdate: Maybe<Scalars['Boolean']['output']>;
  needsEmailUpdateReason: Maybe<Scalars['String']['output']>;
  organizationName: Maybe<Scalars['String']['output']>;
  organizationType: Maybe<SelectOption>;
  paymentMethod: Maybe<PaymentMethod>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  primarySpecialty: Maybe<Scalars['UserSpecialty']['output']>;
  productPracticeAreasPurchased: Maybe<Scalars['String']['output']>;
  productTypesPurchased: Maybe<Scalars['String']['output']>;
  recoveryEmail: Maybe<Scalars['String']['output']>;
  secondaryOrganizationType: Maybe<SelectOption>;
  secondaryPracticeAreas: Maybe<SecondaryPracticeAreas>;
  secondarySpecialty: Maybe<Scalars['UserSpecialty']['output']>;
  subscriptions: Maybe<Array<Maybe<Subscription>>>;
  termsConditionsLastAcceptance: Maybe<Scalars['String']['output']>;
  userRole: Maybe<SelectOption>;
};

export type UserCleCourseCreditArgs = {
  salesforceId: Scalars['ID']['input'];
};

export type UserCleCourseCreditsArgs = {
  compliancePeriod: InputMaybe<CompliancePeriod>;
};

export type UserInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type UserInvoicesArgs = {
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type UserSubscriptionsArgs = {
  statuses: InputMaybe<Array<InputMaybe<SubscriptionStatus>>>;
};

export type UserAvailabilityReport = {
  __typename?: 'UserAvailabilityReport';
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isAvailable: Scalars['Boolean']['output'];
};

export type WatchlistCourse = {
  __typename?: 'WatchlistCourse';
  contentfulCourseId: Maybe<Scalars['ID']['output']>;
  course: Maybe<CleCourse>;
  createdAt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
};

export type CaseSearchEdgeFragment = {
  __typename: 'CaseSearchEdge';
  citingTreatment: {
    __typename?: 'CitingTreatment';
    treatmentDescription: string | null;
    levelOfDiscussion: number | null;
    attitudeName: string | null;
  } | null;
  snippets: Array<{
    __typename?: 'Snippet';
    text: string | null;
    position: string | null;
  } | null> | null;
  node: {
    __typename?: 'Case';
    cite: string | null;
    courtName: string | null;
    date: string | null;
    judicataId: string | null;
    name: string | null;
    citationCount: number | null;
    lastCitedOn: string | null;
    treatmentDescription: string | null;
    treatmentAttitudeName: string | null;
    exampleTreatingCase: {
      __typename?: 'Case';
      name: string | null;
      judicataId: string | null;
      treatmentDescription: string | null;
    } | null;
  };
};

export type CaseSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  citedCaseJudicataId: InputMaybe<Scalars['String']['input']>;
  citedCodeSectionJudicataId: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
  sort?: InputMaybe<SearchSortType>;
  published: InputMaybe<CasePublicationStatus>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  caseDateGte: InputMaybe<Scalars['Year']['input']>;
  caseDateLte: InputMaybe<Scalars['Year']['input']>;
}>;

export type CaseSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<
      | {
          __typename: 'CaseSearchEdge';
          citingTreatment: {
            __typename?: 'CitingTreatment';
            treatmentDescription: string | null;
            levelOfDiscussion: number | null;
            attitudeName: string | null;
          } | null;
          snippets: Array<{
            __typename?: 'Snippet';
            text: string | null;
            position: string | null;
          } | null> | null;
          node: {
            __typename?: 'Case';
            cite: string | null;
            courtName: string | null;
            date: string | null;
            judicataId: string | null;
            name: string | null;
            citationCount: number | null;
            lastCitedOn: string | null;
            treatmentDescription: string | null;
            treatmentAttitudeName: string | null;
            exampleTreatingCase: {
              __typename?: 'Case';
              name: string | null;
              judicataId: string | null;
              treatmentDescription: string | null;
            } | null;
          };
        }
      | { __typename?: 'GenericSearchEdge' }
      | { __typename?: 'PractitionerSearchEdge' }
      | { __typename?: 'PublicationSectionSearchEdge' }
      | { __typename?: 'StatuteSearchEdge' }
      | null
    > | null;
  } | null;
};

export type ContentfulTrueDocsAndFormsFragment = {
  __typename?: 'ContentfulTrueDocsAndForms';
  type: string | null;
  docspringTemplateId: string | null;
  title: string | null;
  formNumber: string | null;
  shortDescription: string | null;
  updatedDate: any | null;
  slug: string | null;
  sys: { __typename?: 'ContentfulSys'; id: string };
  cebGuidance: {
    __typename?: 'ContentfulTrueDocsAndFormsCebGuidance';
    json: any;
  } | null;
};

export type ContentfulTrueDocsAndFormsBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ContentfulTrueDocsAndFormsBySlugQuery = {
  __typename?: 'Query';
  contentfulTrueDocsAndFormsCollection: {
    __typename?: 'ContentfulTrueDocsAndFormsCollection';
    items: Array<{
      __typename?: 'ContentfulTrueDocsAndForms';
      type: string | null;
      docspringTemplateId: string | null;
      title: string | null;
      formNumber: string | null;
      shortDescription: string | null;
      updatedDate: any | null;
      slug: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
      cebGuidance: {
        __typename?: 'ContentfulTrueDocsAndFormsCebGuidance';
        json: any;
      } | null;
    } | null>;
  } | null;
};

export type CurrentAwarenessFragment = {
  __typename?: 'CurrentAwareness';
  content: string | null;
  contentfulId: string | null;
  featureImageUrl: string | null;
  originalPublishDate: string | null;
  postResourceType: string | null;
  slug: string | null;
  subtitle: string | null;
  title: string | null;
  authors: Array<{
    __typename?: 'Author';
    webDisplayName: string | null;
  } | null> | null;
  practiceAreas: Array<{
    __typename?: 'PracticeArea';
    name: string | null;
    slug: string | null;
    contentfulId: string | null;
  } | null> | null;
};

export type AuthorFragment = {
  __typename?: 'Author';
  webDisplayName: string | null;
  shortBio: string | null;
  slug: string | null;
  headshotUrl: string | null;
};

export type GetAllPracticeAreasWithCourseQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllPracticeAreasWithCourseQuery = {
  __typename?: 'Query';
  contentfulPracticeArea: {
    __typename?: 'ContentfulPracticeArea';
    name: string | null;
    practiceAreaSubtopicsCollection: {
      __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
      items: Array<{
        __typename?: 'ContentfulPracticeArea';
        name: string | null;
        slug: string | null;
        sys: { __typename?: 'ContentfulSys'; id: string };
        linkedFrom: {
          __typename?: 'ContentfulPracticeAreaLinkingCollections';
          courseCollection: {
            __typename?: 'ContentfulCourseCollection';
            total: number;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetAllSpecializationCreditCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSpecializationCreditCategoriesQuery = {
  __typename?: 'Query';
  contentfulCourseCreditCategoryCollection: {
    __typename?: 'ContentfulCourseCreditCategoryCollection';
    items: Array<{
      __typename?: 'ContentfulCourseCreditCategory';
      name: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
      childCategoriesCollection: {
        __typename?: 'ContentfulCourseCreditCategoryChildCategoriesCollection';
        items: Array<{
          __typename?: 'ContentfulCourseCreditCategory';
          name: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type GetAllSpecializationRequirementsCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSpecializationRequirementsCategoriesQuery = {
  __typename?: 'Query';
  contentfulCourseCreditCategoryCollection: {
    __typename?: 'ContentfulCourseCreditCategoryCollection';
    items: Array<{
      __typename?: 'ContentfulCourseCreditCategory';
      name: string | null;
      childCategoriesCollection: {
        __typename?: 'ContentfulCourseCreditCategoryChildCategoriesCollection';
        items: Array<{
          __typename?: 'ContentfulCourseCreditCategory';
          name: string | null;
          sys: { __typename?: 'ContentfulSys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssetsQuery = {
  __typename?: 'Query';
  assets: Array<{
    __typename?: 'Asset';
    onLawId: string | null;
    productName: string | null;
    productCode: string | null;
    quantity: number | null;
    usageEndDate: string | null;
  } | null> | null;
};

export type GetCourseSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  contentfulIds_not_in: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  contentfulIds_in: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  practiceAreas: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  specialRequirements: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  specializationCredits: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  dateRecorded_gte: InputMaybe<Scalars['DateTime']['input']>;
  dateRecorded_lte: InputMaybe<Scalars['DateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCourseSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'PagedCourseSearchConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<{
      __typename?: 'CLECourse';
      contentfulId: string | null;
      slug: string | null;
      title: string | null;
      dateRecorded: string | null;
      featureImageUrl: string | null;
      totalCredits: number | null;
      longDescription: any | null;
      courseMaterialsUrl: string | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        contentfulId: string | null;
        name: string | null;
        slug: string | null;
      } | null> | null;
      specialRequirementsCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      specializationCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      collections: Array<{
        __typename?: 'CoursesCollection';
        title: string | null;
        slug: string | null;
        compliancePackageProductCode: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetCurrentAwarenessSearchQueryVariables = Exact<{
  practiceAreaIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
  page: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  query: InputMaybe<Scalars['String']['input']>;
  resourceTypes:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type GetCurrentAwarenessSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'PagedCurrentAwarenessConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      totalPages: number;
      currentPage: number;
    };
    edges: Array<{
      __typename?: 'CurrentAwareness';
      content: string | null;
      contentfulId: string | null;
      featureImageUrl: string | null;
      originalPublishDate: string | null;
      postResourceType: string | null;
      slug: string | null;
      subtitle: string | null;
      title: string | null;
      authors: Array<{
        __typename?: 'Author';
        webDisplayName: string | null;
      } | null> | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        name: string | null;
        slug: string | null;
        contentfulId: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetDailyNewsPracticeAreasQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDailyNewsPracticeAreasQuery = {
  __typename?: 'Query';
  contentfulPracticeArea: {
    __typename?: 'ContentfulPracticeArea';
    name: string | null;
    practiceAreaSubtopicsCollection: {
      __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
      items: Array<{
        __typename?: 'ContentfulPracticeArea';
        name: string | null;
        slug: string | null;
        sys: { __typename?: 'ContentfulSys'; id: string };
        linkedFrom: {
          __typename?: 'ContentfulPracticeAreaLinkingCollections';
          currentAwarenessCollection: {
            __typename?: 'ContentfulCurrentAwarenessCollection';
            total: number;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
  user: {
    __typename?: 'User';
    secondaryPracticeAreas: {
      __typename?: 'SecondaryPracticeAreas';
      selected: string | null;
      selectedContentfulIds: Array<string | null> | null;
    } | null;
  } | null;
};

export type GetPractitionerResourceSearchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPractitionerResourceSearchQuery = {
  __typename?: 'Query';
  searchFilter: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    needsMore: boolean | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      count: number | null;
    } | null> | null;
  } | null;
};

export type GetPractitionerSearchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPractitionerSearchQuery = {
  __typename?: 'Query';
  courts: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        categoryId: string | null;
        name: string | null;
        filterValue: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  legalAreas: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        categoryId: string | null;
        name: string | null;
        filterValue: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  practiceAreas: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        categoryId: string | null;
        name: string | null;
        filterValue: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  resourceTypes: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        categoryId: string | null;
        name: string | null;
        filterValue: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SearchResultFragment = {
  __typename?: 'SearchFilter';
  name: string | null;
  categoryId: string | null;
  children: Array<{
    __typename?: 'FilterOption';
    categoryId: string | null;
    name: string | null;
    filterValue: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetPractitionerSearchCountQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  sort?: InputMaybe<SearchSortType>;
}>;

export type GetPractitionerSearchCountQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
  } | null;
};

export type GetPractitionerSearchResultsQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  sort?: InputMaybe<SearchSortType>;
}>;

export type GetPractitionerSearchResultsQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<
      | { __typename?: 'CaseSearchEdge' }
      | { __typename?: 'GenericSearchEdge' }
      | {
          __typename?: 'PractitionerSearchEdge';
          snippets: Array<{
            __typename?: 'Snippet';
            text: string | null;
            position: string | null;
          } | null> | null;
          node: {
            __typename?: 'PractitionerSearchNode';
            id: string | null;
            contentfulId: string | null;
            doctype: string | null;
            contentfulKnowHowDocument: {
              __typename?: 'ContentfulKnowHowDocument';
              title: string | null;
              slug: string | null;
              description: string | null;
              practitionerDocumentType: {
                __typename?: 'ContentfulDocumentType';
                name: string | null;
              } | null;
              practiceAreasCollection: {
                __typename?: 'ContentfulKnowHowDocumentPracticeAreasCollection';
                items: Array<{
                  __typename?: 'ContentfulPracticeArea';
                  slug: string | null;
                } | null>;
              } | null;
            } | null;
          };
        }
      | { __typename?: 'PublicationSectionSearchEdge' }
      | { __typename?: 'StatuteSearchEdge' }
      | null
    > | null;
  } | null;
};

export type GetProductCollectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProductCollectionQuery = {
  __typename?: 'Query';
  contentfulKnowHowProductCollection: {
    __typename?: 'ContentfulKnowHowProductCollection';
    items: Array<{
      __typename?: 'ContentfulKnowHowProduct';
      name: string | null;
      productCode: string | null;
      practiceAreaCollection: {
        __typename?: 'ContentfulKnowHowProductPracticeAreaCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          slug: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type GetSecondarySourcesDocumentPartsQueryVariables = Exact<{
  refglobal: Scalars['String']['input'];
}>;

export type GetSecondarySourcesDocumentPartsQuery = {
  __typename?: 'Query';
  secondarySourcesDocumentParts: Array<{
    __typename?: 'SecondarySourcesDocumentParts';
    documentId: string;
    documentPartId: string;
  }>;
};

export type PublicationFiltersSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type PublicationFiltersSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    practiceAreas: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        filterValue: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    resourceTypes: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        filterValue: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    publicationTitles: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        filterValue: string | null;
        count: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PublicationSearchEdgeFragment = {
  __typename: 'PublicationSectionSearchEdge';
  snippets: Array<{
    __typename?: 'Snippet';
    text: string | null;
    position: string | null;
  } | null> | null;
  node: {
    __typename?: 'PublicationSearchNode';
    id: number | null;
    secondarySourcesTitleParts: Array<{
      __typename?: 'SecondarySourcesTitleParts';
      name: string;
      titleProductCode: string;
      onlawId: string;
      titleOnlawId: string;
      title: {
        __typename?: 'SecondarySourcesTitles';
        id: number;
        productTitle: string;
        resourceType: {
          __typename?: 'SecondarySourcesResourceTypes';
          name: string;
        } | null;
        practiceAreas: Array<{
          __typename?: 'SecondarySourcesPracticeAreaTitles';
          practiceArea: {
            __typename?: 'SecondarySourcesPracticeAreas';
            id: number;
            slug: string | null;
          };
        }>;
      } | null;
    } | null> | null;
  };
};

export type PublicationSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sort?: InputMaybe<SearchSortType>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type PublicationSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<
      | { __typename?: 'CaseSearchEdge' }
      | { __typename?: 'GenericSearchEdge' }
      | { __typename?: 'PractitionerSearchEdge' }
      | {
          __typename: 'PublicationSectionSearchEdge';
          snippets: Array<{
            __typename?: 'Snippet';
            text: string | null;
            position: string | null;
          } | null> | null;
          node: {
            __typename?: 'PublicationSearchNode';
            id: number | null;
            secondarySourcesTitleParts: Array<{
              __typename?: 'SecondarySourcesTitleParts';
              name: string;
              titleProductCode: string;
              onlawId: string;
              titleOnlawId: string;
              title: {
                __typename?: 'SecondarySourcesTitles';
                id: number;
                productTitle: string;
                resourceType: {
                  __typename?: 'SecondarySourcesResourceTypes';
                  name: string;
                } | null;
                practiceAreas: Array<{
                  __typename?: 'SecondarySourcesPracticeAreaTitles';
                  practiceArea: {
                    __typename?: 'SecondarySourcesPracticeAreas';
                    id: number;
                    slug: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
          };
        }
      | { __typename?: 'StatuteSearchEdge' }
      | null
    > | null;
  } | null;
};

export type GenericSearchEdgeFragment = {
  __typename: 'GenericSearchEdge';
  snippets: Array<{
    __typename?: 'Snippet';
    text: string | null;
  } | null> | null;
  node: {
    __typename: 'Generic';
    titleId: string | null;
    documentId: string | null;
    name: string | null;
    secondarySourcesDocumentParts: Array<{
      __typename?: 'SecondarySourcesDocumentParts';
      id: number;
      name: string;
      document: {
        __typename?: 'SecondarySourcesDocuments';
        id: number;
        documentTitle: string;
      };
    } | null> | null;
  };
};

export type RulesOfCourtSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sort?: InputMaybe<SearchSortType>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type RulesOfCourtSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<
      | { __typename?: 'CaseSearchEdge' }
      | {
          __typename: 'GenericSearchEdge';
          snippets: Array<{
            __typename?: 'Snippet';
            text: string | null;
          } | null> | null;
          node: {
            __typename: 'Generic';
            titleId: string | null;
            documentId: string | null;
            name: string | null;
            secondarySourcesDocumentParts: Array<{
              __typename?: 'SecondarySourcesDocumentParts';
              id: number;
              name: string;
              document: {
                __typename?: 'SecondarySourcesDocuments';
                id: number;
                documentTitle: string;
              };
            } | null> | null;
          };
        }
      | { __typename?: 'PractitionerSearchEdge' }
      | { __typename?: 'PublicationSectionSearchEdge' }
      | { __typename?: 'StatuteSearchEdge' }
      | null
    > | null;
  } | null;
};

export type SearchCaseFiltersQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  citedCaseJudicataId: InputMaybe<Scalars['String']['input']>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  published?: InputMaybe<CasePublicationStatus>;
  caseDateGte: InputMaybe<Scalars['Year']['input']>;
  caseDateLte: InputMaybe<Scalars['Year']['input']>;
}>;

export type SearchCaseFiltersQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    court: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
        children: Array<{
          __typename?: 'FilterOption';
          filterValue: string | null;
          name: string | null;
          count: number | null;
          children: Array<{
            __typename?: 'FilterOption';
            filterValue: string | null;
            name: string | null;
            count: number | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    causeOfAction: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    proceduralPosture: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    disposition: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    appellantTrialCourtRole: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type StatuteSearchEdgeFragment = {
  __typename: 'StatuteSearchEdge';
  snippets: Array<{
    __typename?: 'Snippet';
    text: string | null;
    position: string | null;
  } | null> | null;
  node:
    | {
        __typename: 'CaConstitutionSection';
        codeJudicataId: string | null;
        content: string | null;
        article: string | null;
        judicataId: string | null;
        jurisdictionId: string | null;
        title: string | null;
      }
    | {
        __typename: 'CodeSection';
        codeJudicataId: string | null;
        content: string | null;
        judicataId: string | null;
        jurisdictionId: string | null;
        title: string | null;
      };
};

export type StatuteSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sort?: InputMaybe<SearchSortType>;
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type StatuteSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<
      | { __typename?: 'CaseSearchEdge' }
      | { __typename?: 'GenericSearchEdge' }
      | { __typename?: 'PractitionerSearchEdge' }
      | { __typename?: 'PublicationSectionSearchEdge' }
      | {
          __typename: 'StatuteSearchEdge';
          snippets: Array<{
            __typename?: 'Snippet';
            text: string | null;
            position: string | null;
          } | null> | null;
          node:
            | {
                __typename: 'CaConstitutionSection';
                codeJudicataId: string | null;
                content: string | null;
                article: string | null;
                judicataId: string | null;
                jurisdictionId: string | null;
                title: string | null;
              }
            | {
                __typename: 'CodeSection';
                codeJudicataId: string | null;
                content: string | null;
                judicataId: string | null;
                jurisdictionId: string | null;
                title: string | null;
              };
        }
      | null
    > | null;
  } | null;
};

export type StatutesFiltersSearchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StatutesFiltersSearchQuery = {
  __typename?: 'Query';
  searchFilter: {
    __typename?: 'SearchFilter';
    name: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      name: string | null;
      filterValue: string | null;
      count: number | null;
    } | null> | null;
  } | null;
};

export type TrueDocsAndFormsFragment = {
  __typename?: 'CLETrueDocsAndForms';
  contentfulId: string | null;
  docspringTemplateId: string | null;
  slug: string | null;
  formNumber: string | null;
  title: string | null;
  shortDescription: string | null;
  updatedDate: string | null;
};

export type TrueDocsAndFormsSearchByIdQueryVariables = Exact<{
  contentfulIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type TrueDocsAndFormsSearchByIdQuery = {
  __typename?: 'Query';
  trueDocsAndFormsSearch: {
    __typename?: 'PagedTrueDocsAndFormsSearchConnection';
    edges: Array<{
      __typename?: 'CLETrueDocsAndForms';
      contentfulId: string | null;
      docspringTemplateId: string | null;
      slug: string | null;
      formNumber: string | null;
      title: string | null;
      shortDescription: string | null;
      updatedDate: string | null;
    } | null> | null;
  } | null;
};

export type GetOrganizationTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationTypesQuery = {
  __typename?: 'Query';
  organizationTypes: Array<{
    __typename?: 'OrgType';
    label: string | null;
    value: string | null;
    userRoles: Array<{
      __typename?: 'SelectOption';
      label: string | null;
      value: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    recoveryEmail: string | null;
    organizationName: string | null;
    barNumber: string | null;
    phoneNumber: string | null;
    needsEmailUpdate: boolean | null;
    needsEmailUpdateReason: string | null;
    primarySpecialty: any | null;
    secondarySpecialty: any | null;
    complianceGroupOverrideId: any | null;
    organizationType: {
      __typename?: 'SelectOption';
      label: string | null;
      value: string | null;
    } | null;
    userRole: {
      __typename?: 'SelectOption';
      label: string | null;
      value: string | null;
    } | null;
    secondaryPracticeAreas: {
      __typename?: 'SecondaryPracticeAreas';
      selected: string | null;
      selectedContentfulIds: Array<string | null> | null;
      options: Array<{
        __typename?: 'SelectOption';
        label: string | null;
        value: string | null;
      } | null> | null;
    } | null;
    cleComplianceDeadline: {
      __typename?: 'CLEComplianceDeadline';
      complianceGroupId: number | null;
      periodStartDate: string | null;
      periodEndDate: string | null;
      reportingDate: string | null;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    accountId: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    organizationName: string | null;
    barNumber: string | null;
    phoneNumber: string | null;
    termsConditionsLastAcceptance: string | null;
    engagementLevel: string | null;
    productTypesPurchased: string | null;
    productPracticeAreasPurchased: string | null;
    needsEmailUpdate: boolean | null;
    needsEmailUpdateReason: string | null;
    complianceGroupOverrideId: any | null;
    firmPermissions: {
      __typename?: 'FirmPermissions';
      isFirmAdmin: boolean | null;
      isBillingAccessOnlyFirmAdmin: boolean | null;
    } | null;
    secondaryPracticeAreas: {
      __typename?: 'SecondaryPracticeAreas';
      selected: string | null;
    } | null;
    organizationType: {
      __typename?: 'SelectOption';
      value: string | null;
    } | null;
    userRole: { __typename?: 'SelectOption'; value: string | null } | null;
    cleComplianceDeadline: {
      __typename?: 'CLEComplianceDeadline';
      complianceGroupId: number | null;
      periodStartDate: string | null;
      periodEndDate: string | null;
      reportingDate: string | null;
    } | null;
  } | null;
};

export type InitiatePasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type InitiatePasswordResetMutation = {
  __typename?: 'Mutation';
  initiatePasswordReset: boolean | null;
};

export type TermsAndConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type TermsAndConditionsQuery = {
  __typename?: 'Query';
  contentfulSimplePage: {
    __typename?: 'ContentfulSimplePage';
    title: string | null;
    updatedDate: any | null;
    content: { __typename?: 'ContentfulSimplePageContent'; json: any } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  recoveryEmail: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationName: InputMaybe<Scalars['String']['input']>;
  organizationType: Scalars['String']['input'];
  barNumber: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  practiceAreas: InputMaybe<Scalars['String']['input']>;
  userRole: Scalars['String']['input'];
  complianceGroupOverrideId: InputMaybe<
    Scalars['ComplianceGroupOverrideId']['input']
  >;
  primarySpecialty: InputMaybe<Scalars['UserSpecialty']['input']>;
  secondarySpecialty: InputMaybe<Scalars['UserSpecialty']['input']>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateSecondaryPracticeAreas: boolean | null;
  updateUser: {
    __typename?: 'User';
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    organizationName: string | null;
    barNumber: string | null;
    phoneNumber: string | null;
    primarySpecialty: any | null;
    secondarySpecialty: any | null;
    complianceGroupOverrideId: any | null;
    organizationType: {
      __typename?: 'SelectOption';
      label: string | null;
      value: string | null;
    } | null;
    userRole: {
      __typename?: 'SelectOption';
      label: string | null;
      value: string | null;
    } | null;
  } | null;
};

export type UserFragment = {
  __typename?: 'User';
  accountId: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  email: string | null;
  organizationName: string | null;
  barNumber: string | null;
  phoneNumber: string | null;
  termsConditionsLastAcceptance: string | null;
  engagementLevel: string | null;
  productTypesPurchased: string | null;
  productPracticeAreasPurchased: string | null;
  needsEmailUpdate: boolean | null;
  needsEmailUpdateReason: string | null;
  complianceGroupOverrideId: any | null;
  firmPermissions: {
    __typename?: 'FirmPermissions';
    isFirmAdmin: boolean | null;
    isBillingAccessOnlyFirmAdmin: boolean | null;
  } | null;
  secondaryPracticeAreas: {
    __typename?: 'SecondaryPracticeAreas';
    selected: string | null;
  } | null;
  organizationType: {
    __typename?: 'SelectOption';
    value: string | null;
  } | null;
  userRole: { __typename?: 'SelectOption'; value: string | null } | null;
  cleComplianceDeadline: {
    __typename?: 'CLEComplianceDeadline';
    complianceGroupId: number | null;
    periodStartDate: string | null;
    periodEndDate: string | null;
    reportingDate: string | null;
  } | null;
};

export type UserCleCreditAreaSummaryFragment = {
  __typename?: 'User';
  primarySpecialty: any | null;
  secondarySpecialty: any | null;
  cleCreditAreaSummary: Array<{
    __typename?: 'CLECreditArea';
    name: string | null;
    requiredCredits: number | null;
    completedCredits: number | null;
    completed: boolean | null;
    cleCreditSubArea: {
      __typename?: 'CLECreditArea';
      name: string | null;
      requiredCredits: number | null;
      completedCredits: number | null;
      completed: boolean | null;
    } | null;
  } | null> | null;
  cleSpecializationSummary: Array<{
    __typename?: 'CLESpecializationSummaryArea';
    name: string | null;
    requiredCredits: number | null;
    completedCredits: number | null;
    completed: boolean | null;
  } | null> | null;
};

export type CreditsEarnedFragment = {
  __typename?: 'User';
  barNumber: string | null;
  cleComplianceDeadline: {
    __typename?: 'CLEComplianceDeadline';
    reportingDate: string | null;
    periodStartDate: string | null;
    periodEndDate: string | null;
  } | null;
  currentPeriodCredits: Array<{
    __typename?: 'CLECourseCredit';
    salesforceId: string | null;
    courseName: string | null;
    totalCreditHours: number | null;
    completedDate: string | null;
    creditAreaHours: Array<{
      __typename?: 'CLECreditAreaHour';
      creditHours: number | null;
      creditAreaName: string | null;
    } | null> | null;
    specializationHours: Array<{
      __typename?: 'CLESpecializationHour';
      creditHours: number | null;
      category: string | null;
      subcategory: string | null;
    } | null> | null;
  } | null> | null;
  previousPeriodsCredits: Array<{
    __typename?: 'CLECourseCredit';
    salesforceId: string | null;
    courseName: string | null;
    totalCreditHours: number | null;
    completedDate: string | null;
    creditAreaHours: Array<{
      __typename?: 'CLECreditAreaHour';
      creditHours: number | null;
      creditAreaName: string | null;
    } | null> | null;
    specializationHours: Array<{
      __typename?: 'CLESpecializationHour';
      creditHours: number | null;
      category: string | null;
      subcategory: string | null;
    } | null> | null;
  } | null> | null;
};

export type LearningProgressFragment = {
  __typename?: 'User';
  cleCreditRequirement: number | null;
  cleCreditsEarnedFromCeb: number | null;
  cleComplianceDeadline: {
    __typename?: 'CLEComplianceDeadline';
    reportingDate: string | null;
    periodStartDate: string | null;
    periodEndDate: string | null;
  } | null;
};

export type CleCourseCreditFragment = {
  __typename?: 'CLECourseCredit';
  salesforceId: string | null;
  courseName: string | null;
  totalCreditHours: number | null;
  completedDate: string | null;
  creditAreaHours: Array<{
    __typename?: 'CLECreditAreaHour';
    creditHours: number | null;
    creditAreaName: string | null;
  } | null> | null;
  specializationHours: Array<{
    __typename?: 'CLESpecializationHour';
    creditHours: number | null;
    category: string | null;
    subcategory: string | null;
  } | null> | null;
};

export type UserPracticeAreasFragment = {
  __typename?: 'User';
  secondaryPracticeAreas: {
    __typename?: 'SecondaryPracticeAreas';
    selected: string | null;
    selectedContentfulIds: Array<string | null> | null;
  } | null;
};

export type ContentfulPracticeAreasFragment = {
  __typename?: 'ContentfulPracticeArea';
  name: string | null;
  practiceAreaSubtopicsCollection: {
    __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
    items: Array<{
      __typename?: 'ContentfulPracticeArea';
      name: string | null;
      slug: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
      linkedFrom: {
        __typename?: 'ContentfulPracticeAreaLinkingCollections';
        currentAwarenessCollection: {
          __typename?: 'ContentfulCurrentAwarenessCollection';
          total: number;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CancelFirmInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelFirmInviteMutation = {
  __typename?: 'Mutation';
  deleteFirmInvite: boolean | null;
};

export type FirmFragment = {
  __typename?: 'Firm';
  id: string | null;
  name: string | null;
  users: Array<{
    __typename?: 'FirmUser';
    accountId: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    barNumber: string | null;
    isFirmAdmin: boolean | null;
  } | null> | null;
  invitations: Array<{
    __typename?: 'FirmInvitation';
    id: string | null;
    inviteeFirstName: string | null;
    inviteeLastName: string | null;
    inviteeEmail: string | null;
  } | null> | null;
};

export type FirmInvitationsFragment = {
  __typename?: 'Firm';
  invitations: Array<{
    __typename?: 'FirmInvitation';
    id: string | null;
    inviteeFirstName: string | null;
    inviteeLastName: string | null;
    inviteeEmail: string | null;
  } | null> | null;
};

export type FirmInvitationFragment = {
  __typename?: 'FirmInvitation';
  id: string | null;
  inviteeFirstName: string | null;
  inviteeLastName: string | null;
  inviteeEmail: string | null;
};

export type FirmUserFragment = {
  __typename?: 'FirmUser';
  accountId: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  email: string | null;
  barNumber: string | null;
  isFirmAdmin: boolean | null;
};

export type GetFirmQueryVariables = Exact<{ [key: string]: never }>;

export type GetFirmQuery = {
  __typename?: 'Query';
  firm: {
    __typename?: 'Firm';
    id: string | null;
    name: string | null;
    users: Array<{
      __typename?: 'FirmUser';
      accountId: string | null;
      firstName: string | null;
      lastName: string | null;
      fullName: string | null;
      email: string | null;
      barNumber: string | null;
      isFirmAdmin: boolean | null;
    } | null> | null;
    invitations: Array<{
      __typename?: 'FirmInvitation';
      id: string | null;
      inviteeFirstName: string | null;
      inviteeLastName: string | null;
      inviteeEmail: string | null;
    } | null> | null;
  } | null;
};

export type GetFirmInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFirmInvoicesQuery = {
  __typename?: 'Query';
  firm: {
    __typename?: 'Firm';
    invoices: {
      __typename?: 'PagedInvoiceConnection';
      edges: Array<{
        __typename?: 'Invoice';
        date: string | null;
        amount: number | null;
        balance: number | null;
        invoiceNumber: number | null;
        terms: string | null;
        id: string | null;
        paymentSchedules: Array<{
          __typename?: 'PaymentSchedule';
          status: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetFirmSubscriptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFirmSubscriptionsQuery = {
  __typename?: 'Query';
  firm: {
    __typename?: 'Firm';
    name: string | null;
    subscriptions: Array<{
      __typename?: 'Subscription';
      subscriptionId: string | null;
      planCode: string | null;
      planName: string | null;
      subscriptionInterval: SubscriptionInterval | null;
      amount: number | null;
      createdTimestamp: string | null;
      activatedTimestamp: string | null;
      nextPeriodStartDate: string | null;
      providesCLE: boolean | null;
      providesLegalResearch: boolean | null;
      products: Array<{
        __typename?: 'SubscriptionProduct';
        isRecurring: boolean | null;
        productName: string | null;
        productCode: string | null;
        quantity: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetFirmUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetFirmUsersQuery = {
  __typename?: 'Query';
  firm: {
    __typename?: 'Firm';
    id: string | null;
    name: string | null;
    users: Array<{
      __typename?: 'FirmUser';
      accountId: string | null;
      email: string | null;
      fullName: string | null;
      lastName: string | null;
      isFirmAdmin: boolean | null;
    } | null> | null;
    invitations: Array<{
      __typename?: 'FirmInvitation';
      id: string | null;
      inviteeFirstName: string | null;
      inviteeLastName: string | null;
      inviteeEmail: string | null;
    } | null> | null;
  } | null;
};

export type ResendFirmInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResendFirmInviteMutation = {
  __typename?: 'Mutation';
  resendFirmInvitation: boolean | null;
};

export type AddFirmAdministratorMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type AddFirmAdministratorMutation = {
  __typename?: 'Mutation';
  addFirmAdministrator: boolean | null;
};

export type RemoveFirmAdministratorMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type RemoveFirmAdministratorMutation = {
  __typename?: 'Mutation';
  removeFirmAdministrator: boolean | null;
};

export type RemoveUserFromFirmMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type RemoveUserFromFirmMutation = {
  __typename?: 'Mutation';
  removeUserFromFirm: boolean | null;
};

export type GetCaseQueryVariables = Exact<{
  judicataId: Scalars['String']['input'];
  fromQuery: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCaseQuery = {
  __typename?: 'Query';
  case: {
    __typename?: 'Case';
    name: string | null;
    cite: string | null;
    date: string | null;
    courtName: string | null;
    content: string | null;
    treatmentAttitudeName: string | null;
    treatmentDescription: string | null;
    citationCount: number | null;
    lastCitedOn: string | null;
    judicataId: string | null;
    searchHighlightTerms: Array<string | null> | null;
    exampleTreatingCase: {
      __typename?: 'Case';
      name: string | null;
      judicataId: string | null;
      treatmentDescription: string | null;
    } | null;
    californiaCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
    standardCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
  } | null;
};

export type GetSearchCaseCitationFiltersQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  citedCaseJudicataId: InputMaybe<Scalars['String']['input']>;
  citedCodeSectionJudicataId: InputMaybe<Scalars['Int']['input']>;
  hideCaseAndLevel: Scalars['Boolean']['input'];
  textFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  published?: InputMaybe<CasePublicationStatus>;
  caseDateGte: InputMaybe<Scalars['Year']['input']>;
  caseDateLte: InputMaybe<Scalars['Year']['input']>;
}>;

export type GetSearchCaseCitationFiltersQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    caseTreatment?: {
      __typename?: 'SearchFilter';
      name: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        filterValue: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    levelOfAnalysis?: {
      __typename?: 'SearchFilter';
      name: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        filterValue: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    court: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
        children: Array<{
          __typename?: 'FilterOption';
          filterValue: string | null;
          name: string | null;
          count: number | null;
          children: Array<{
            __typename?: 'FilterOption';
            filterValue: string | null;
            name: string | null;
            count: number | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    causeOfAction: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    proceduralPosture: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    disposition: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    appellantTrialCourtRole: {
      __typename?: 'SearchFilter';
      name: string | null;
      needsMore: boolean | null;
      children: Array<{
        __typename?: 'FilterOption';
        filterValue: string | null;
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetClimateBriefImageAssetsQueryVariables = Exact<{
  assetIds: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetClimateBriefImageAssetsQuery = {
  __typename?: 'Query';
  contentfulAssetCollection: {
    __typename?: 'ContentfulAssetCollection';
    items: Array<{
      __typename?: 'ContentfulAsset';
      url: string | null;
      description: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
    } | null>;
  } | null;
};

export type GetClimateBriefPostQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  date: Scalars['ContentfulDateTime']['input'];
}>;

export type GetClimateBriefPostQuery = {
  __typename?: 'Query';
  post: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      featuredImageCaption: string | null;
      originalPublishDate: any | null;
      slug: string | null;
      title: string | null;
      updatedDate: any | null;
      authorsCollection: {
        __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
          shortBio: string | null;
          headshot: {
            __typename?: 'ContentfulAsset';
            url: string | null;
          } | null;
        } | null>;
      } | null;
      content: {
        __typename?: 'ContentfulCurrentAwarenessContent';
        json: any;
      } | null;
      featuredImage: {
        __typename?: 'ContentfulAsset';
        url: string | null;
      } | null;
      practiceAreasCollection: {
        __typename?: 'ContentfulCurrentAwarenessPracticeAreasCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          slug: string | null;
          name: string | null;
          sys: { __typename?: 'ContentfulSys'; id: string };
        } | null>;
      } | null;
      relatedContentCollection: {
        __typename?: 'ContentfulCurrentAwarenessRelatedContentCollection';
        items: Array<{
          __typename?: 'ContentfulCurrentAwareness';
          originalPublishDate: any | null;
          slug: string | null;
          title: string | null;
          authorsCollection: {
            __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
            items: Array<{
              __typename?: 'ContentfulContributor';
              webDisplayName: string | null;
            } | null>;
          } | null;
          content: {
            __typename?: 'ContentfulCurrentAwarenessContent';
            json: any;
          } | null;
        } | null>;
      } | null;
      topicsCollection: {
        __typename?: 'ContentfulCurrentAwarenessTopicsCollection';
        items: Array<{
          __typename?: 'ContentfulTopic';
          name: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
  recentClimateBriefPosts: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      originalPublishDate: any | null;
      slug: string | null;
      title: string | null;
      authorsCollection: {
        __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
        } | null>;
      } | null;
      content: {
        __typename?: 'ContentfulCurrentAwarenessContent';
        json: any;
      } | null;
    } | null>;
  } | null;
};

export type GetClimateBriefSearchQueryVariables = Exact<{
  topicIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  excludedPostIds: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  featuredPosts: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetClimateBriefSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'PagedCurrentAwarenessConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      totalPages: number;
      currentPage: number;
    };
    edges: Array<{
      __typename?: 'CurrentAwareness';
      contentfulId: string | null;
      slug: string | null;
      title: string | null;
      featureImageUrl: string | null;
      originalPublishDate: string | null;
      content: string | null;
      authors: Array<{
        __typename?: 'Author';
        headshotUrl: string | null;
        webDisplayName: string | null;
        slug: string | null;
      } | null> | null;
      topics: Array<{
        __typename?: 'CurrentAwarenessTopic';
        name: string | null;
        slug: string | null;
        contentfulId: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CourseDataFragment = {
  __typename?: 'CLECourse';
  contentfulId: string | null;
  slug: string | null;
  title: string | null;
  dateRecorded: string | null;
  panoptoId: string | null;
  featureImageUrl: string | null;
  totalCredits: number | null;
  courseMaterialsFileName: string | null;
  courseMaterialsUrl: string | null;
  longDescription: any | null;
  practiceAreas: Array<{
    __typename?: 'PracticeArea';
    contentfulId: string | null;
    name: string | null;
    slug: string | null;
  } | null> | null;
  specialRequirementsCredits: Array<{
    __typename?: 'CLECourseSubcredits';
    contentfulId: string | null;
    parentContentfulId: string | null;
    parentName: string | null;
    credits: number | null;
    name: string | null;
  } | null> | null;
  specializationCredits: Array<{
    __typename?: 'CLECourseSubcredits';
    contentfulId: string | null;
    parentContentfulId: string | null;
    parentName: string | null;
    credits: number | null;
    name: string | null;
  } | null> | null;
  speakers: Array<{
    __typename?: 'Author';
    contentfulId: string | null;
    slug: string | null;
    webDisplayName: string | null;
  } | null> | null;
  collections: Array<{
    __typename?: 'CoursesCollection';
    title: string | null;
    slug: string | null;
    compliancePackageProductCode: string | null;
  } | null> | null;
};

export type GetCleCourseQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetCleCourseQuery = {
  __typename?: 'Query';
  course: {
    __typename?: 'CLECourse';
    contentfulId: string | null;
    slug: string | null;
    title: string | null;
    dateRecorded: string | null;
    panoptoId: string | null;
    featureImageUrl: string | null;
    totalCredits: number | null;
    courseMaterialsFileName: string | null;
    courseMaterialsUrl: string | null;
    longDescription: any | null;
    practiceAreas: Array<{
      __typename?: 'PracticeArea';
      contentfulId: string | null;
      name: string | null;
      slug: string | null;
    } | null> | null;
    specialRequirementsCredits: Array<{
      __typename?: 'CLECourseSubcredits';
      contentfulId: string | null;
      parentContentfulId: string | null;
      parentName: string | null;
      credits: number | null;
      name: string | null;
    } | null> | null;
    specializationCredits: Array<{
      __typename?: 'CLECourseSubcredits';
      contentfulId: string | null;
      parentContentfulId: string | null;
      parentName: string | null;
      credits: number | null;
      name: string | null;
    } | null> | null;
    speakers: Array<{
      __typename?: 'Author';
      contentfulId: string | null;
      slug: string | null;
      webDisplayName: string | null;
    } | null> | null;
    collections: Array<{
      __typename?: 'CoursesCollection';
      title: string | null;
      slug: string | null;
      compliancePackageProductCode: string | null;
    } | null> | null;
  } | null;
};

export type GetCourseWatchlistQueryVariables = Exact<{ [key: string]: never }>;

export type GetCourseWatchlistQuery = {
  __typename?: 'Query';
  courseWatchlist: Array<{
    __typename?: 'WatchlistCourse';
    id: string | null;
    createdAt: string | null;
    contentfulCourseId: string | null;
    course: {
      __typename?: 'CLECourse';
      contentfulId: string | null;
      slug: string | null;
      title: string | null;
      dateRecorded: string | null;
      panoptoId: string | null;
      featureImageUrl: string | null;
      totalCredits: number | null;
      courseMaterialsFileName: string | null;
      courseMaterialsUrl: string | null;
      longDescription: any | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        contentfulId: string | null;
        name: string | null;
        slug: string | null;
      } | null> | null;
      specialRequirementsCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      specializationCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      speakers: Array<{
        __typename?: 'Author';
        contentfulId: string | null;
        slug: string | null;
        webDisplayName: string | null;
      } | null> | null;
      collections: Array<{
        __typename?: 'CoursesCollection';
        title: string | null;
        slug: string | null;
        compliancePackageProductCode: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GetLocalRulesDocumentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLocalRulesDocumentsQuery = {
  __typename?: 'Query';
  secondarySourcesDocuments: Array<{
    __typename?: 'SecondarySourcesDocuments';
    id: number;
    documentId: string;
    documentTitle: string;
  }>;
};

export type GetRulesTableOfContentsQueryVariables = Exact<{
  documentId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRulesTableOfContentsQuery = {
  __typename?: 'Query';
  secondarySourcesDocuments: Array<{
    __typename?: 'SecondarySourcesDocuments';
    documentTitle: string;
    tableOfContents: Array<{
      __typename?: 'SecondarySourcesDocumentParts';
      id: number;
      documentPartId: string;
      name: string;
      path: string;
      depth: number;
      order: number;
      hasContent: boolean;
      hasChildren: boolean | null;
      content: string | null;
    }>;
  }>;
};

export type GetRulesTitleQueryVariables = Exact<{
  documentPartId: Scalars['String']['input'];
}>;

export type GetRulesTitleQuery = {
  __typename?: 'Query';
  secondarySourcesDocumentParts: Array<{
    __typename?: 'SecondarySourcesDocumentParts';
    rootParent: {
      __typename?: 'SecondarySourcesDocumentParts';
      rootParentChildren: Array<{
        __typename?: 'SecondarySourcesDocumentParts';
        id: number;
        documentPartId: string;
        name: string;
        path: string;
        depth: number;
        order: number;
        hasContent: boolean;
        hasChildren: boolean | null;
        content: string | null;
      }>;
    } | null;
  }>;
};

export type RuleDocumentPartsFragment = {
  __typename?: 'SecondarySourcesDocumentParts';
  id: number;
  documentPartId: string;
  name: string;
  path: string;
  depth: number;
  order: number;
  hasContent: boolean;
  hasChildren: boolean | null;
  content: string | null;
};

export type GetDocsAndFormsCollectionsSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  practiceAreas: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDocsAndFormsCollectionsSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'PagedTrueDocsAndFormsCollectionsSearchConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      totalPages: number;
      currentPage: number;
    };
    edges: Array<{
      __typename?: 'CLETrueDocsAndFormsCollections';
      contentfulId: string | null;
      title: string | null;
      slug: string | null;
      shortDescription: string | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        contentfulId: string | null;
        name: string | null;
        slug: string | null;
      } | null> | null;
      trueDocsAndForms: Array<{
        __typename?: 'CLETrueDocsAndForms';
        contentfulId: string | null;
        type: string | null;
        title: string | null;
        slug: string | null;
        updatedDate: string | null;
        shortDescription: string | null;
        formNumber: string | null;
        docspringTemplateId: string | null;
        documentTemplateUrl: string | null;
        cebGuidance: any | null;
        practiceAreas: Array<{
          __typename?: 'PracticeArea';
          contentfulId: string | null;
          name: string | null;
          slug: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetDocsAndFormsCountyCourtsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDocsAndFormsCountyCourtsQuery = {
  __typename?: 'Query';
  contentfulCourtCollection: {
    __typename?: 'ContentfulCourtCollection';
    items: Array<{
      __typename?: 'ContentfulCourt';
      name: string | null;
      slug: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
      linkedFrom: {
        __typename?: 'ContentfulCourtLinkingCollections';
        trueDocsAndFormsCollection: {
          __typename?: 'ContentfulTrueDocsAndFormsCollection';
          total: number;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetDocsAndFormsPracticeAreasQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDocsAndFormsPracticeAreasQuery = {
  __typename?: 'Query';
  contentfulPracticeAreaCollection: {
    __typename?: 'ContentfulPracticeAreaCollection';
    items: Array<{
      __typename?: 'ContentfulPracticeArea';
      name: string | null;
      slug: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
      linkedFrom: {
        __typename?: 'ContentfulPracticeAreaLinkingCollections';
        trueDocsAndFormsCollection: {
          __typename?: 'ContentfulTrueDocsAndFormsCollection';
          total: number;
        } | null;
      } | null;
      practiceAreaSubtopicsCollection: {
        __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          name: string | null;
          slug: string | null;
          sys: { __typename?: 'ContentfulSys'; id: string };
          linkedFrom: {
            __typename?: 'ContentfulPracticeAreaLinkingCollections';
            trueDocsAndFormsCollection: {
              __typename?: 'ContentfulTrueDocsAndFormsCollection';
              total: number;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type GetDocsAndFormsSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  practiceAreas: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  courtIds: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  trueFormSubtypes: InputMaybe<
    | Array<InputMaybe<Scalars['TrueFormSubtype']['input']>>
    | InputMaybe<Scalars['TrueFormSubtype']['input']>
  >;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDocsAndFormsSearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'PagedTrueDocsAndFormsSearchConnection';
    totalCount: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      currentPage: number;
      totalPages: number;
    };
    edges: Array<{
      __typename?: 'CLETrueDocsAndForms';
      type: string | null;
      contentfulId: string | null;
      slug: string | null;
      formNumber: string | null;
      title: string | null;
      updatedDate: string | null;
      shortDescription: string | null;
      county: string | null;
      mandatoryJcForm: boolean | null;
      trueFormSubtype: string | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        contentfulId: string | null;
        name: string | null;
        slug: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateHistoryItemMutationVariables = Exact<{
  type: HistoryType;
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
  action: HistoryAction;
  parentId: InputMaybe<Scalars['ID']['input']>;
  subtype: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateHistoryItemMutation = {
  __typename?: 'Mutation';
  createHistoryItem: {
    __typename?: 'HistoryItem';
    id: string | null;
    parentId: string | null;
    createdAt: string | null;
    type: HistoryType | null;
    path: string | null;
    text: string | null;
    subtype: string | null;
  } | null;
};

export type GetHistoryDatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetHistoryDatesQuery = {
  __typename?: 'Query';
  historyDates: Array<string | null> | null;
};

export type GetHistoryItemsQueryVariables = Exact<{
  startDate: Scalars['String']['input'];
  endDate: InputMaybe<Scalars['String']['input']>;
}>;

export type GetHistoryItemsQuery = {
  __typename?: 'Query';
  historyItemsByDate: Array<{
    __typename?: 'HistoryItem';
    id: string | null;
    createdAt: string | null;
    type: HistoryType | null;
    path: string | null;
    text: string | null;
    action: HistoryAction | null;
    parentId: string | null;
    subtype: string | null;
    children: Array<{
      __typename?: 'HistoryItem';
      id: string | null;
      createdAt: string | null;
      type: HistoryType | null;
      path: string | null;
      text: string | null;
      parentId: string | null;
      subtype: string | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateHistoryItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  parentId: InputMaybe<Scalars['ID']['input']>;
  type: HistoryType;
  path: Scalars['String']['input'];
  text: Scalars['String']['input'];
  subtype: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateHistoryItemMutation = {
  __typename?: 'Mutation';
  updateHistoryItem: {
    __typename?: 'HistoryItem';
    id: string | null;
    parentId: string | null;
    createdAt: string | null;
    type: HistoryType | null;
    path: string | null;
    text: string | null;
    subtype: string | null;
  } | null;
};

export type GetPractitionerHistoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPractitionerHistoryQuery = {
  __typename?: 'Query';
  historyItemsByType: Array<{
    __typename?: 'HistoryItem';
    id: string | null;
    createdAt: string | null;
    text: string | null;
    path: string | null;
  } | null> | null;
};

export type CleCourseFragment = {
  __typename?: 'CLECourse';
  contentfulId: string | null;
  slug: string | null;
  title: string | null;
  dateRecorded: string | null;
  panoptoId: string | null;
  featureImageUrl: string | null;
  totalCredits: number | null;
  courseMaterialsFileName: string | null;
  courseMaterialsUrl: string | null;
  longDescription: any | null;
  practiceAreas: Array<{
    __typename?: 'PracticeArea';
    contentfulId: string | null;
    name: string | null;
    slug: string | null;
  } | null> | null;
  specialRequirementsCredits: Array<{
    __typename?: 'CLECourseSubcredits';
    contentfulId: string | null;
    parentContentfulId: string | null;
    parentName: string | null;
    credits: number | null;
    name: string | null;
  } | null> | null;
  specializationCredits: Array<{
    __typename?: 'CLECourseSubcredits';
    contentfulId: string | null;
    parentContentfulId: string | null;
    parentName: string | null;
    credits: number | null;
    name: string | null;
  } | null> | null;
  speakers: Array<{
    __typename?: 'Author';
    contentfulId: string | null;
    slug: string | null;
    webDisplayName: string | null;
  } | null> | null;
  collections: Array<{
    __typename?: 'CoursesCollection';
    title: string | null;
    slug: string | null;
    compliancePackageProductCode: string | null;
  } | null> | null;
};

export type CourseProgressFragment = {
  __typename?: 'CourseProgress';
  contentfulCourseId: string;
  currentPlayheadInMs: number | null;
  highestPlayheadInMs: number | null;
  salesforceCourseResultId: string | null;
  completedPercentage: number | null;
  updatedAt: any | null;
};

export type GetCourseProgressQueryVariables = Exact<{
  contentfulCourseIds: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetCourseProgressQuery = {
  __typename?: 'Query';
  courseProgress: Array<{
    __typename?: 'CourseProgress';
    contentfulCourseId: string;
    currentPlayheadInMs: number | null;
    highestPlayheadInMs: number | null;
    salesforceCourseResultId: string | null;
    completedPercentage: number | null;
    updatedAt: any | null;
  } | null> | null;
};

export type GetCoursesInProgressQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCoursesInProgressQuery = {
  __typename?: 'Query';
  courseProgress: Array<{
    __typename?: 'CourseProgress';
    contentfulCourseId: string;
    currentPlayheadInMs: number | null;
    highestPlayheadInMs: number | null;
    salesforceCourseResultId: string | null;
    completedPercentage: number | null;
    updatedAt: any | null;
    course: {
      __typename?: 'CLECourse';
      contentfulId: string | null;
      slug: string | null;
      title: string | null;
      dateRecorded: string | null;
      panoptoId: string | null;
      featureImageUrl: string | null;
      totalCredits: number | null;
      courseMaterialsFileName: string | null;
      courseMaterialsUrl: string | null;
      longDescription: any | null;
      practiceAreas: Array<{
        __typename?: 'PracticeArea';
        contentfulId: string | null;
        name: string | null;
        slug: string | null;
      } | null> | null;
      specialRequirementsCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      specializationCredits: Array<{
        __typename?: 'CLECourseSubcredits';
        contentfulId: string | null;
        parentContentfulId: string | null;
        parentName: string | null;
        credits: number | null;
        name: string | null;
      } | null> | null;
      speakers: Array<{
        __typename?: 'Author';
        contentfulId: string | null;
        slug: string | null;
        webDisplayName: string | null;
      } | null> | null;
      collections: Array<{
        __typename?: 'CoursesCollection';
        title: string | null;
        slug: string | null;
        compliancePackageProductCode: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GetLearningQueryVariables = Exact<{ [key: string]: never }>;

export type GetLearningQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    barNumber: string | null;
    cleCreditRequirement: number | null;
    cleCreditsEarnedFromCeb: number | null;
    cleComplianceDeadline: {
      __typename?: 'CLEComplianceDeadline';
      reportingDate: string | null;
      periodStartDate: string | null;
      periodEndDate: string | null;
    } | null;
    currentPeriodCredits: Array<{
      __typename?: 'CLECourseCredit';
      salesforceId: string | null;
      courseName: string | null;
      totalCreditHours: number | null;
      completedDate: string | null;
      creditAreaHours: Array<{
        __typename?: 'CLECreditAreaHour';
        creditHours: number | null;
        creditAreaName: string | null;
      } | null> | null;
      specializationHours: Array<{
        __typename?: 'CLESpecializationHour';
        creditHours: number | null;
        category: string | null;
        subcategory: string | null;
      } | null> | null;
    } | null> | null;
    previousPeriodsCredits: Array<{
      __typename?: 'CLECourseCredit';
      salesforceId: string | null;
      courseName: string | null;
      totalCreditHours: number | null;
      completedDate: string | null;
      creditAreaHours: Array<{
        __typename?: 'CLECreditAreaHour';
        creditHours: number | null;
        creditAreaName: string | null;
      } | null> | null;
      specializationHours: Array<{
        __typename?: 'CLESpecializationHour';
        creditHours: number | null;
        category: string | null;
        subcategory: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetUserCreditAreaSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserCreditAreaSummaryQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    primarySpecialty: any | null;
    secondarySpecialty: any | null;
    cleCreditAreaSummary: Array<{
      __typename?: 'CLECreditArea';
      name: string | null;
      requiredCredits: number | null;
      completedCredits: number | null;
      completed: boolean | null;
      cleCreditSubArea: {
        __typename?: 'CLECreditArea';
        name: string | null;
        requiredCredits: number | null;
        completedCredits: number | null;
        completed: boolean | null;
      } | null;
    } | null> | null;
    cleSpecializationSummary: Array<{
      __typename?: 'CLESpecializationSummaryArea';
      name: string | null;
      requiredCredits: number | null;
      completedCredits: number | null;
      completed: boolean | null;
    } | null> | null;
  } | null;
};

export type GetUsersCourseProgressQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsersCourseProgressQuery = {
  __typename?: 'Query';
  courseProgress: Array<{
    __typename?: 'CourseProgress';
    contentfulCourseId: string;
    currentPlayheadInMs: number | null;
    highestPlayheadInMs: number | null;
    salesforceCourseResultId: string | null;
    completedPercentage: number | null;
    updatedAt: any | null;
  } | null> | null;
};

export type AddNewMatterServiceMatterToNewClientMutationVariables = Exact<{
  clientData: InputMaybe<MatterServiceClients_Obj_Rel_Insert_Input>;
  name: Scalars['String']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  type: MatterServiceMatterTypes_Enum;
}>;

export type AddNewMatterServiceMatterToNewClientMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_matters_one: {
    __typename?: 'MatterServiceMatters';
    id: any;
  } | null;
};

export type AddNewMatterServiceMatterToClientMutationVariables = Exact<{
  clientId: Scalars['MatterServiceUuid']['input'];
  name: Scalars['String']['input'];
  description: InputMaybe<Scalars['String']['input']>;
  type: MatterServiceMatterTypes_Enum;
}>;

export type AddNewMatterServiceMatterToClientMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_matters_one: {
    __typename?: 'MatterServiceMatters';
    id: any;
    description: string | null;
    matterType: MatterServiceMatterTypes_Enum;
    matterId: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
    caseNumber: string | null;
    folders: Array<{
      __typename?: 'MatterServiceMatterDocsAndFormsFolders';
      id: any;
      name: string;
      createdAt: any;
    }>;
  } | null;
};

export type DeleteMatterServiceMatterMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceMatterMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    id: any;
    name: string;
  } | null;
};

export type GetMatterServiceMatterQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServiceMatterQuery = {
  __typename?: 'Query';
  matterServiceMatters_by_pk: {
    __typename?: 'MatterServiceMatters';
    id: any;
    description: string | null;
    matterType: MatterServiceMatterTypes_Enum;
    matterId: string | null;
    name: string;
    shortName: string | null;
    createdAt: any;
    updatedAt: any;
    caseNumber: string | null;
    filingFor: MatterServiceMatterFilingForTypes_Enum | null;
    filingForName: string | null;
    includesMinorChildren: boolean | null;
    doesNotIncludeMinorChildren: boolean | null;
    otherParentClaimant: string | null;
    accountLastEditorId: string | null;
    accountCreatorId: string;
    folders: Array<{
      __typename?: 'MatterServiceMatterDocsAndFormsFolders';
      id: any;
      name: string;
      createdAt: any;
      accountCreatorId: string;
    }>;
    attorneyForPlaintiffOrPetitioner: Array<{
      __typename?: 'MatterServiceAttorneys';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      firmName: string | null;
      firmNameCapitalized: string | null;
      barNumber: string | null;
      fullNamePlusSBN: string | null;
      accountId: string | null;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      fax: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    attorneyForForDefendantOrRespondent: Array<{
      __typename?: 'MatterServiceAttorneys';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      firmName: string | null;
      firmNameCapitalized: string | null;
      barNumber: string | null;
      fullNamePlusSBN: string | null;
      accountId: string | null;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      fax: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    filingAttorney: Array<{
      __typename?: 'MatterServiceAttorneys';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      firmName: string | null;
      firmNameCapitalized: string | null;
      barNumber: string | null;
      fullNamePlusSBN: string | null;
      accountId: string | null;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      fax: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    opposingCounsel: Array<{
      __typename?: 'MatterServiceAttorneys';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      firmName: string | null;
      firmNameCapitalized: string | null;
      barNumber: string | null;
      fullNamePlusSBN: string | null;
      accountId: string | null;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      fax: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    court: Array<{
      __typename?: 'MatterServiceCourts';
      id: any;
      name: string;
      county: string;
      branchName: string | null;
      addressBlock: string | null;
      address: {
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      };
    }> | null;
    plaintiffOrPetitioner: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    defendantOrRespondent: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    filingForPerson: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    minorChildren: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    marriage: {
      __typename?: 'MatterServiceMarriages';
      id: any;
      dateOfMarriage: any | null;
      dateOfSeparation: any | null;
      formattedDateOfMarriage: string | null;
      formattedDateOfSeparation: string | null;
      yearsOfMarriage: string | null;
      monthsOfMarriage: string | null;
    } | null;
    domesticPartnership: {
      __typename?: 'MatterServiceDomesticPartnerships';
      id: any;
      dateOfRegistration: any | null;
      dateOfSeparation: any | null;
      formattedDateOfRegistration: string | null;
      formattedDateOfSeparation: string | null;
      yearsOfPartnership: string | null;
      monthsOfPartnership: string | null;
    } | null;
    temporaryRestrainingOrder: {
      __typename?: 'MatterServiceTemporaryRestrainingOrders';
      id: any;
      relationshipToProtectedPerson: string | null;
      restrainedPerson: {
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      } | null;
      protectedPerson: {
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      } | null;
    } | null;
    client: {
      __typename?: 'MatterServiceClients';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
    } | null;
  } | null;
};

export type ListMattersByClientQueryVariables = Exact<{ [key: string]: never }>;

export type ListMattersByClientQuery = {
  __typename?: 'Query';
  matterServiceMatters: Array<{
    __typename?: 'MatterServiceMatters';
    id: any;
    description: string | null;
    matterType: MatterServiceMatterTypes_Enum;
    matterId: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
    caseNumber: string | null;
    accountLastEditorId: string | null;
    accountCreatorId: string;
    client: {
      __typename?: 'MatterServiceClients';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
    } | null;
    folders: Array<{
      __typename?: 'MatterServiceMatterDocsAndFormsFolders';
      id: any;
      name: string;
      createdAt: any;
      accountCreatorId: string;
    }>;
  }>;
};

export type ListMattersByRecentQueryVariables = Exact<{ [key: string]: never }>;

export type ListMattersByRecentQuery = {
  __typename?: 'Query';
  matterServiceMatters: Array<{
    __typename?: 'MatterServiceMatters';
    id: any;
    description: string | null;
    matterType: MatterServiceMatterTypes_Enum;
    matterId: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
    caseNumber: string | null;
    accountLastEditorId: string | null;
    accountCreatorId: string;
    client: {
      __typename?: 'MatterServiceClients';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
    } | null;
    folders: Array<{
      __typename?: 'MatterServiceMatterDocsAndFormsFolders';
      id: any;
      name: string;
      createdAt: any;
      accountCreatorId: string;
    }>;
  }>;
};

export type MatterServiceCourtFragment = {
  __typename?: 'MatterServiceCourts';
  id: any;
  name: string;
  branchName: string | null;
  county: string;
  addressBlock: string | null;
  address: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    streetAddressBothLines: string | null;
    mailingAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    cityAndZipCode: string | null;
    fullAddressSingleLine: string | null;
  };
};

export type MatterServiceDocumentFoldersQueryVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
}>;

export type MatterServiceDocumentFoldersQuery = {
  __typename?: 'Query';
  matterServiceMatterDocsAndFormsFolders: Array<{
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
    createdAt: any;
    accountCreatorId: string;
  }>;
};

export type MatterServiceDuplicateItemsInMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  itemIds: Scalars['MatterServiceJsonb']['input'];
}>;

export type MatterServiceDuplicateItemsInMatterMutation = {
  __typename?: 'Mutation';
  matterServiceDuplicateItemsInMatter: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type MatterServiceEntityAddressFragment = {
  __typename?: 'MatterServiceEntityAddresses';
  id: any;
  streetAddress: string | null;
  streetAddressLineTwo: string | null;
  streetAddressBothLines: string | null;
  mailingAddress: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  cityAndZipCode: string | null;
  fullAddressSingleLine: string | null;
};

export type MatterServiceGetDocOrFormQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type MatterServiceGetDocOrFormQuery = {
  __typename?: 'Query';
  matterServiceMatterDocsAndForms_by_pk: {
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    accountCreatorId: string;
    accountLastEditorId: string | null;
    contentfulId: string;
    createdAt: any;
    data: any;
    docspringTemplateId: string | null;
    keepUpdatedWithMatter: boolean;
    matterId: any;
    status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
    type: MatterServiceMatterDocsAndFormsTypes_Enum;
    updatedAt: any;
    isDuplicate: boolean | null;
  } | null;
};

export type MatterServiceMatterFragment = {
  __typename?: 'MatterServiceMatters';
  id: any;
  description: string | null;
  matterType: MatterServiceMatterTypes_Enum;
  matterId: string | null;
  name: string;
  shortName: string | null;
  createdAt: any;
  updatedAt: any;
  caseNumber: string | null;
  filingFor: MatterServiceMatterFilingForTypes_Enum | null;
  filingForName: string | null;
  includesMinorChildren: boolean | null;
  doesNotIncludeMinorChildren: boolean | null;
  otherParentClaimant: string | null;
  accountLastEditorId: string | null;
  accountCreatorId: string;
  folders: Array<{
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
    createdAt: any;
    accountCreatorId: string;
  }>;
  attorneyForPlaintiffOrPetitioner: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  attorneyForForDefendantOrRespondent: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  filingAttorney: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  opposingCounsel: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  court: Array<{
    __typename?: 'MatterServiceCourts';
    id: any;
    name: string;
    county: string;
    branchName: string | null;
    addressBlock: string | null;
    address: {
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    };
  }> | null;
  plaintiffOrPetitioner: Array<{
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  defendantOrRespondent: Array<{
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  filingForPerson: Array<{
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  minorChildren: Array<{
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }> | null;
  marriage: {
    __typename?: 'MatterServiceMarriages';
    id: any;
    dateOfMarriage: any | null;
    dateOfSeparation: any | null;
    formattedDateOfMarriage: string | null;
    formattedDateOfSeparation: string | null;
    yearsOfMarriage: string | null;
    monthsOfMarriage: string | null;
  } | null;
  domesticPartnership: {
    __typename?: 'MatterServiceDomesticPartnerships';
    id: any;
    dateOfRegistration: any | null;
    dateOfSeparation: any | null;
    formattedDateOfRegistration: string | null;
    formattedDateOfSeparation: string | null;
    yearsOfPartnership: string | null;
    monthsOfPartnership: string | null;
  } | null;
  temporaryRestrainingOrder: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
    relationshipToProtectedPerson: string | null;
    restrainedPerson: {
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    } | null;
    protectedPerson: {
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    } | null;
  } | null;
  client: {
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type MatterServiceMatterAndFoldersFragment = {
  __typename?: 'MatterServiceMatters';
  id: any;
  description: string | null;
  matterType: MatterServiceMatterTypes_Enum;
  matterId: string | null;
  name: string;
  createdAt: any;
  updatedAt: any;
  caseNumber: string | null;
  accountLastEditorId: string | null;
  accountCreatorId: string;
  folders: Array<{
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
    createdAt: any;
    accountCreatorId: string;
  }>;
};

export type MatterServiceMatterDocsAndFormFragment = {
  __typename?: 'MatterServiceMatterDocsAndForms';
  id: any;
  accountCreatorId: string;
  accountLastEditorId: string | null;
  contentfulId: string;
  createdAt: any;
  data: any;
  docspringTemplateId: string | null;
  keepUpdatedWithMatter: boolean;
  matterId: any;
  status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
  type: MatterServiceMatterDocsAndFormsTypes_Enum;
  updatedAt: any;
  isDuplicate: boolean | null;
};

export type MatterServiceMatterDocsAndFormsQueryVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  folderOrderBy: InputMaybe<
    | Array<MatterServiceMatterDocsAndFormsFolders_Order_By>
    | MatterServiceMatterDocsAndFormsFolders_Order_By
  >;
  itemOrderBy: InputMaybe<
    | Array<MatterServiceMatterDocsAndForms_Order_By>
    | MatterServiceMatterDocsAndForms_Order_By
  >;
}>;

export type MatterServiceMatterDocsAndFormsQuery = {
  __typename?: 'Query';
  matterServiceMatterDocsAndForms: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    accountCreatorId: string;
    accountLastEditorId: string | null;
    contentfulId: string;
    createdAt: any;
    data: any;
    docspringTemplateId: string | null;
    keepUpdatedWithMatter: boolean;
    matterId: any;
    status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
    type: MatterServiceMatterDocsAndFormsTypes_Enum;
    updatedAt: any;
    isDuplicate: boolean | null;
  }>;
  matterServiceMatterDocsAndFormsFolders: Array<{
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
    createdAt: any;
    accountCreatorId: string;
    items: Array<{
      __typename?: 'MatterServiceMatterDocsAndForms';
      id: any;
      accountCreatorId: string;
      accountLastEditorId: string | null;
      contentfulId: string;
      createdAt: any;
      data: any;
      docspringTemplateId: string | null;
      keepUpdatedWithMatter: boolean;
      matterId: any;
      status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
      type: MatterServiceMatterDocsAndFormsTypes_Enum;
      updatedAt: any;
      isDuplicate: boolean | null;
    }>;
  }>;
};

export type MatterServiceMatterDocsAndFormsFolderFragment = {
  __typename?: 'MatterServiceMatterDocsAndFormsFolders';
  id: any;
  name: string;
  createdAt: any;
  accountCreatorId: string;
};

export type MatterServiceMatterDataFragment = {
  __typename?: 'MatterServiceMatters';
  id: any;
  description: string | null;
  matterType: MatterServiceMatterTypes_Enum;
  matterId: string | null;
  name: string;
  createdAt: any;
  updatedAt: any;
  caseNumber: string | null;
  folders: Array<{
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
    createdAt: any;
  }>;
};

export type MatterServiceRemoveItemsFromMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  itemIds: Scalars['MatterServiceJsonb']['input'];
}>;

export type MatterServiceRemoveItemsFromMatterMutation = {
  __typename?: 'Mutation';
  matterServiceRemoveItemsFromMatter: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
  }>;
};

export type MatterServiceRemoveMatterFoldersMutationVariables = Exact<{
  folderIds:
    | Array<Scalars['MatterServiceUuid']['input']>
    | Scalars['MatterServiceUuid']['input'];
}>;

export type MatterServiceRemoveMatterFoldersMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterDocsAndFormsFolders: {
    __typename?: 'MatterServiceMatterDocsAndFormsFolders_mutation_response';
    returning: Array<{
      __typename?: 'MatterServiceMatterDocsAndFormsFolders';
      id: any;
    }>;
  } | null;
};

export type UpdateMatterCaseNumberMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  caseNumber: Scalars['String']['input'];
}>;

export type UpdateMatterCaseNumberMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    caseNumber: string | null;
  } | null;
};

export type UpdateMatterServiceKeepUpdatedWithMatterMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  keepUpdatedWithMatter: Scalars['Boolean']['input'];
}>;

export type UpdateMatterServiceKeepUpdatedWithMatterMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matterDocsAndForms_by_pk: {
    __typename?: 'MatterServiceMatterDocsAndForms';
    keepUpdatedWithMatter: boolean;
  } | null;
};

export type UpdateMatterServiceMatterDescriptionMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  description: Scalars['String']['input'];
}>;

export type UpdateMatterServiceMatterDescriptionMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    description: string | null;
  } | null;
};

export type UpdateMatterServiceMatterIdMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  matterId: Scalars['String']['input'];
}>;

export type UpdateMatterServiceMatterIdMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    matterId: string | null;
  } | null;
};

export type UpdateMatterServiceMatterNameMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateMatterServiceMatterNameMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    name: string;
  } | null;
};

export type UpdateMatterServiceOtherParentClaimantOnMatterMutationVariables =
  Exact<{
    id: Scalars['MatterServiceUuid']['input'];
    otherParentClaimant: InputMaybe<Scalars['String']['input']>;
  }>;

export type UpdateMatterServiceOtherParentClaimantOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    otherParentClaimant: string | null;
  } | null;
};

export type AddMatterServiceAttorneyAddressMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServiceAttorneyAddressMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityAddresses: {
    __typename?: 'MatterServiceEntityAddresses_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityAddresses_one: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
  } | null;
};

export type AddMatterServiceAttorneyEmailMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
  email: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServiceAttorneyEmailMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityEmails: {
    __typename?: 'MatterServiceEntityEmails_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityEmails_one: {
    __typename?: 'MatterServiceEntityEmails';
    id: any;
  } | null;
};

export type AddMatterServiceAttorneyFaxMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServiceAttorneyFaxMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityPhoneNumbers_one: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type AddMatterServiceAttorneyPhoneNumberMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServiceAttorneyPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityPhoneNumbers_one: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type ClearMatterServiceAttorneyRoleMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  attorneyRole: MatterServiceMatterAttorneyRoleTypes_Enum;
}>;

export type ClearMatterServiceAttorneyRoleMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateMatterServiceAttorneyMutationVariables = Exact<{
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  barNumber: InputMaybe<Scalars['String']['input']>;
  firmName: InputMaybe<Scalars['String']['input']>;
  accountId: InputMaybe<Scalars['String']['input']>;
  workPhone: InputMaybe<MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input>;
  workEmail: InputMaybe<MatterServiceEntityEmails_Arr_Rel_Insert_Input>;
  workAddress: InputMaybe<MatterServiceEntityAddresses_Arr_Rel_Insert_Input>;
}>;

export type CreateMatterServiceAttorneyMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_attorneys_one: {
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
  } | null;
};

export type CreateMatterServiceAttorneyOnMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  attorneyRole: MatterServiceMatterAttorneyRoleTypes_Enum;
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  barNumber: InputMaybe<Scalars['String']['input']>;
  firmName: InputMaybe<Scalars['String']['input']>;
  accountId: InputMaybe<Scalars['String']['input']>;
  workPhone: InputMaybe<MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input>;
  workEmail: InputMaybe<MatterServiceEntityEmails_Arr_Rel_Insert_Input>;
  workAddress: InputMaybe<MatterServiceEntityAddresses_Arr_Rel_Insert_Input>;
}>;

export type CreateMatterServiceAttorneyOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_attorneys_one: {
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
  } | null;
};

export type DeleteMatterServiceAttorneyMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceAttorneyMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_attorneys_by_pk: {
    __typename?: 'MatterServiceAttorneys';
    id: any;
  } | null;
};

export type DeleteMatterServiceAttorneyAddressMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceAttorneyAddressMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityAddresses: {
    __typename?: 'MatterServiceEntityAddresses_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMatterServiceAttorneyEmailMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceAttorneyEmailMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityEmails: {
    __typename?: 'MatterServiceEntityEmails_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMatterServiceAttorneyFaxMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceAttorneyFaxMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMatterServiceAttorneyPhoneNumberMutationVariables = Exact<{
  attorneyId: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceAttorneyPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMatterServiceAttorneyQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServiceAttorneyQuery = {
  __typename?: 'Query';
  matterServiceAttorneys_by_pk: {
    __typename?: 'MatterServiceAttorneys';
    id: any;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    barNumber: string | null;
    firmName: string | null;
    phoneNumbers: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      type: MatterServiceEntityPhoneNumberTypes_Enum;
      phoneNumber: string | null;
    }>;
    emails: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      type: MatterServiceEntityEmailTypes_Enum;
      email: string;
    }>;
    addresses: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      type: MatterServiceEntityAddressTypes_Enum;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
    }>;
  } | null;
};

export type GetMatterServiceAttorneysQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceAttorneysQuery = {
  __typename?: 'Query';
  matterServiceAttorneys: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }>;
};

export type GetMatterServiceFirmAttorneysQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceFirmAttorneysQuery = {
  __typename?: 'Query';
  matterServiceAttorneys: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }>;
};

export type GetMatterServiceOpposingAttorneysQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceOpposingAttorneysQuery = {
  __typename?: 'Query';
  matterServiceAttorneys: Array<{
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    firmName: string | null;
    firmNameCapitalized: string | null;
    barNumber: string | null;
    fullNamePlusSBN: string | null;
    accountId: string | null;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    fax: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }>;
};

export type MatterServiceAttorneyFragment = {
  __typename?: 'MatterServiceAttorneys';
  id: any;
  fullName: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  suffix: string | null;
  firmName: string | null;
  firmNameCapitalized: string | null;
  barNumber: string | null;
  fullNamePlusSBN: string | null;
  accountId: string | null;
  workPhone: Array<{
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
    phoneNumber: string | null;
  }>;
  fax: Array<{
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
    phoneNumber: string | null;
  }>;
  workAddress: Array<{
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    streetAddressBothLines: string | null;
    mailingAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    cityAndZipCode: string | null;
    fullAddressSingleLine: string | null;
  }>;
  workEmail: Array<{
    __typename?: 'MatterServiceEntityEmails';
    id: any;
    email: string;
  }>;
};

export type MatterAttorneyDataFragment = {
  __typename?: 'MatterServiceAttorneys';
  id: any;
  firstName: string;
  middleName: string | null;
  lastName: string;
  suffix: string | null;
  barNumber: string | null;
  firmName: string | null;
  phoneNumbers: Array<{
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
    type: MatterServiceEntityPhoneNumberTypes_Enum;
    phoneNumber: string | null;
  }>;
  emails: Array<{
    __typename?: 'MatterServiceEntityEmails';
    id: any;
    type: MatterServiceEntityEmailTypes_Enum;
    email: string;
  }>;
  addresses: Array<{
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    type: MatterServiceEntityAddressTypes_Enum;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
  }>;
};

export type UpdateMatterServiceAttorneyMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  firmName: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceAttorneyMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_attorneys_by_pk: {
    __typename?: 'MatterServiceAttorneys';
    id: any;
    fullName: string | null;
  } | null;
};

export type UpdateMatterServiceAttorneyAddressMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceAttorneyAddressMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityAddresses_by_pk: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
  } | null;
};

export type UpdateMatterServiceAttorneyEmailMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  email: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceAttorneyEmailMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityEmails_by_pk: {
    __typename?: 'MatterServiceEntityEmails';
    id: any;
  } | null;
};

export type UpdateMatterServiceAttorneyFaxMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceAttorneyFaxMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityPhoneNumbers_by_pk: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type UpdateMatterServiceAttorneyPhoneNumberMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceAttorneyPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityPhoneNumbers_by_pk: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type UpdateMatterServiceAttorneyToRoleMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  attorneyRole: MatterServiceMatterAttorneyRoleTypes_Enum;
  attorneyId: Scalars['MatterServiceUuid']['input'];
}>;

export type UpdateMatterServiceAttorneyToRoleMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_matterEntities_one: {
    __typename?: 'MatterServiceMatterEntities';
    id: any;
  } | null;
};

export type AddNewMatterServiceClientMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type AddNewMatterServiceClientMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_clients_one: {
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
  } | null;
};

export type DeleteMatterServiceClientMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceClientMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_clients_by_pk: {
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
  } | null;
};

export type GetMatterServiceClientQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServiceClientQuery = {
  __typename?: 'Query';
  matterServiceClients_by_pk: {
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
    createdAt: any;
    updatedAt: any;
    matters: Array<{
      __typename?: 'MatterServiceMatters';
      id: any;
      description: string | null;
      matterType: MatterServiceMatterTypes_Enum;
      matterId: string | null;
      name: string;
      shortName: string | null;
      createdAt: any;
      updatedAt: any;
      caseNumber: string | null;
      filingFor: MatterServiceMatterFilingForTypes_Enum | null;
      filingForName: string | null;
      includesMinorChildren: boolean | null;
      doesNotIncludeMinorChildren: boolean | null;
      otherParentClaimant: string | null;
      accountLastEditorId: string | null;
      accountCreatorId: string;
      folders: Array<{
        __typename?: 'MatterServiceMatterDocsAndFormsFolders';
        id: any;
        name: string;
        createdAt: any;
        accountCreatorId: string;
      }>;
      attorneyForPlaintiffOrPetitioner: Array<{
        __typename?: 'MatterServiceAttorneys';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        firmName: string | null;
        firmNameCapitalized: string | null;
        barNumber: string | null;
        fullNamePlusSBN: string | null;
        accountId: string | null;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        fax: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      attorneyForForDefendantOrRespondent: Array<{
        __typename?: 'MatterServiceAttorneys';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        firmName: string | null;
        firmNameCapitalized: string | null;
        barNumber: string | null;
        fullNamePlusSBN: string | null;
        accountId: string | null;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        fax: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      filingAttorney: Array<{
        __typename?: 'MatterServiceAttorneys';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        firmName: string | null;
        firmNameCapitalized: string | null;
        barNumber: string | null;
        fullNamePlusSBN: string | null;
        accountId: string | null;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        fax: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      opposingCounsel: Array<{
        __typename?: 'MatterServiceAttorneys';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        firmName: string | null;
        firmNameCapitalized: string | null;
        barNumber: string | null;
        fullNamePlusSBN: string | null;
        accountId: string | null;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        fax: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      court: Array<{
        __typename?: 'MatterServiceCourts';
        id: any;
        name: string;
        county: string;
        branchName: string | null;
        addressBlock: string | null;
        address: {
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        };
      }> | null;
      plaintiffOrPetitioner: Array<{
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      defendantOrRespondent: Array<{
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      filingForPerson: Array<{
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      minorChildren: Array<{
        __typename?: 'MatterServicePeople';
        id: any;
        fullName: string | null;
        firstName: string;
        middleName: string | null;
        lastName: string;
        suffix: string | null;
        formerName: string | null;
        nickname: string | null;
        race: string | null;
        gender: MatterServicePeopleGenders_Enum | null;
        formattedGender: string | null;
        isMale: boolean | null;
        isFemale: boolean | null;
        isNonBinary: boolean | null;
        dateOfBirth: any | null;
        formattedDateOfBirth: string | null;
        age: string | null;
        estimatedAge: number | null;
        socialSecurityNumber: string | null;
        driversLicense: string | null;
        height: string | null;
        weight: string | null;
        hairColor: string | null;
        eyeColor: string | null;
        occupation: string | null;
        employerName: string | null;
        personalPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        homeAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        personalEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
        workPhone: Array<{
          __typename?: 'MatterServiceEntityPhoneNumbers';
          id: any;
          phoneNumber: string | null;
        }>;
        workAddress: Array<{
          __typename?: 'MatterServiceEntityAddresses';
          id: any;
          streetAddress: string | null;
          streetAddressLineTwo: string | null;
          streetAddressBothLines: string | null;
          mailingAddress: string | null;
          city: string | null;
          state: string | null;
          zipCode: string | null;
          cityAndZipCode: string | null;
          fullAddressSingleLine: string | null;
        }>;
        workEmail: Array<{
          __typename?: 'MatterServiceEntityEmails';
          id: any;
          email: string;
        }>;
      }> | null;
      marriage: {
        __typename?: 'MatterServiceMarriages';
        id: any;
        dateOfMarriage: any | null;
        dateOfSeparation: any | null;
        formattedDateOfMarriage: string | null;
        formattedDateOfSeparation: string | null;
        yearsOfMarriage: string | null;
        monthsOfMarriage: string | null;
      } | null;
      domesticPartnership: {
        __typename?: 'MatterServiceDomesticPartnerships';
        id: any;
        dateOfRegistration: any | null;
        dateOfSeparation: any | null;
        formattedDateOfRegistration: string | null;
        formattedDateOfSeparation: string | null;
        yearsOfPartnership: string | null;
        monthsOfPartnership: string | null;
      } | null;
      temporaryRestrainingOrder: {
        __typename?: 'MatterServiceTemporaryRestrainingOrders';
        id: any;
        relationshipToProtectedPerson: string | null;
        restrainedPerson: {
          __typename?: 'MatterServicePeople';
          id: any;
          fullName: string | null;
          firstName: string;
          middleName: string | null;
          lastName: string;
          suffix: string | null;
          formerName: string | null;
          nickname: string | null;
          race: string | null;
          gender: MatterServicePeopleGenders_Enum | null;
          formattedGender: string | null;
          isMale: boolean | null;
          isFemale: boolean | null;
          isNonBinary: boolean | null;
          dateOfBirth: any | null;
          formattedDateOfBirth: string | null;
          age: string | null;
          estimatedAge: number | null;
          socialSecurityNumber: string | null;
          driversLicense: string | null;
          height: string | null;
          weight: string | null;
          hairColor: string | null;
          eyeColor: string | null;
          occupation: string | null;
          employerName: string | null;
          personalPhone: Array<{
            __typename?: 'MatterServiceEntityPhoneNumbers';
            id: any;
            phoneNumber: string | null;
          }>;
          homeAddress: Array<{
            __typename?: 'MatterServiceEntityAddresses';
            id: any;
            streetAddress: string | null;
            streetAddressLineTwo: string | null;
            streetAddressBothLines: string | null;
            mailingAddress: string | null;
            city: string | null;
            state: string | null;
            zipCode: string | null;
            cityAndZipCode: string | null;
            fullAddressSingleLine: string | null;
          }>;
          personalEmail: Array<{
            __typename?: 'MatterServiceEntityEmails';
            id: any;
            email: string;
          }>;
          workPhone: Array<{
            __typename?: 'MatterServiceEntityPhoneNumbers';
            id: any;
            phoneNumber: string | null;
          }>;
          workAddress: Array<{
            __typename?: 'MatterServiceEntityAddresses';
            id: any;
            streetAddress: string | null;
            streetAddressLineTwo: string | null;
            streetAddressBothLines: string | null;
            mailingAddress: string | null;
            city: string | null;
            state: string | null;
            zipCode: string | null;
            cityAndZipCode: string | null;
            fullAddressSingleLine: string | null;
          }>;
          workEmail: Array<{
            __typename?: 'MatterServiceEntityEmails';
            id: any;
            email: string;
          }>;
        } | null;
        protectedPerson: {
          __typename?: 'MatterServicePeople';
          id: any;
          fullName: string | null;
          firstName: string;
          middleName: string | null;
          lastName: string;
          suffix: string | null;
          formerName: string | null;
          nickname: string | null;
          race: string | null;
          gender: MatterServicePeopleGenders_Enum | null;
          formattedGender: string | null;
          isMale: boolean | null;
          isFemale: boolean | null;
          isNonBinary: boolean | null;
          dateOfBirth: any | null;
          formattedDateOfBirth: string | null;
          age: string | null;
          estimatedAge: number | null;
          socialSecurityNumber: string | null;
          driversLicense: string | null;
          height: string | null;
          weight: string | null;
          hairColor: string | null;
          eyeColor: string | null;
          occupation: string | null;
          employerName: string | null;
          personalPhone: Array<{
            __typename?: 'MatterServiceEntityPhoneNumbers';
            id: any;
            phoneNumber: string | null;
          }>;
          homeAddress: Array<{
            __typename?: 'MatterServiceEntityAddresses';
            id: any;
            streetAddress: string | null;
            streetAddressLineTwo: string | null;
            streetAddressBothLines: string | null;
            mailingAddress: string | null;
            city: string | null;
            state: string | null;
            zipCode: string | null;
            cityAndZipCode: string | null;
            fullAddressSingleLine: string | null;
          }>;
          personalEmail: Array<{
            __typename?: 'MatterServiceEntityEmails';
            id: any;
            email: string;
          }>;
          workPhone: Array<{
            __typename?: 'MatterServiceEntityPhoneNumbers';
            id: any;
            phoneNumber: string | null;
          }>;
          workAddress: Array<{
            __typename?: 'MatterServiceEntityAddresses';
            id: any;
            streetAddress: string | null;
            streetAddressLineTwo: string | null;
            streetAddressBothLines: string | null;
            mailingAddress: string | null;
            city: string | null;
            state: string | null;
            zipCode: string | null;
            cityAndZipCode: string | null;
            fullAddressSingleLine: string | null;
          }>;
          workEmail: Array<{
            __typename?: 'MatterServiceEntityEmails';
            id: any;
            email: string;
          }>;
        } | null;
      } | null;
      client: {
        __typename?: 'MatterServiceClients';
        id: any;
        name: string;
        createdAt: any;
        updatedAt: any;
      } | null;
    }>;
  } | null;
};

export type GetMatterServiceClientsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceClientsQuery = {
  __typename?: 'Query';
  matterServiceClients: Array<{
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type MatterServiceClientFragment = {
  __typename?: 'MatterServiceClients';
  id: any;
  name: string;
  createdAt: any;
  updatedAt: any;
};

export type RenameMatterServiceClientMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  name: Scalars['String']['input'];
}>;

export type RenameMatterServiceClientMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_clients_by_pk: {
    __typename?: 'MatterServiceClients';
    id: any;
    name: string;
  } | null;
};

export type AddMatterServiceCourtToMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  courtId: Scalars['MatterServiceUuid']['input'];
}>;

export type AddMatterServiceCourtToMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_matterEntities_one: {
    __typename?: 'MatterServiceMatterEntities';
    id: any;
    court: {
      __typename?: 'MatterServiceCourts';
      id: any;
      name: string;
      branchName: string | null;
    } | null;
  } | null;
};

export type CreateMatterServiceCourtOnMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  branchName: InputMaybe<Scalars['String']['input']>;
  address: InputMaybe<MatterServiceEntityAddresses_Obj_Rel_Insert_Input>;
}>;

export type CreateMatterServiceCourtOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_courts_one: {
    __typename?: 'MatterServiceCourts';
    id: any;
    name: string;
    branchName: string | null;
  } | null;
};

export type DeleteMatterServiceCourtMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceCourtMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_courts_by_pk: {
    __typename?: 'MatterServiceCourts';
    id: any;
  } | null;
};

export type DeleteMatterServiceCourtAddressMutationVariables = Exact<{
  courtId: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceCourtAddressMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityAddresses: {
    __typename?: 'MatterServiceEntityAddresses_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMatterServiceCourtQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceCourtQuery = {
  __typename?: 'Query';
  matterServiceCourts: Array<{
    __typename?: 'MatterServiceCourts';
    id: any;
    name: string;
    branchName: string | null;
    county: string;
    addressBlock: string | null;
    address: {
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    };
  }>;
};

export type GetMatterServiceCourtsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMatterServiceCourtsQuery = {
  __typename?: 'Query';
  matterServiceCourts: Array<{
    __typename?: 'MatterServiceCourts';
    id: any;
    name: string;
    branchName: string | null;
    county: string;
    addressBlock: string | null;
    address: {
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    };
  }>;
};

export type MatterCourtDataFragment = {
  __typename?: 'MatterServiceCourts';
  id: any;
  name: string;
  branchName: string | null;
  county: string;
  addressBlock: string | null;
  address: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    streetAddressBothLines: string | null;
    mailingAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    cityAndZipCode: string | null;
    fullAddressSingleLine: string | null;
  };
};

export type RemoveMatterServiceCourtFromMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
}>;

export type RemoveMatterServiceCourtFromMatterMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateMatterServiceCourtMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  addressId: Scalars['MatterServiceUuid']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  branchName: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  mailingAddress: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServiceCourtMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_courts_by_pk: {
    __typename?: 'MatterServiceCourts';
    id: any;
    name: string;
    branchName: string | null;
  } | null;
  matterServiceUpdate_entityAddresses_by_pk: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
  } | null;
};

export type CreateMatterServiceDomesticPartnershipOnMatterMutationVariables =
  Exact<{
    matterId: Scalars['MatterServiceUuid']['input'];
    dateOfRegistration: InputMaybe<Scalars['MatterServiceDate']['input']>;
    dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  }>;

export type CreateMatterServiceDomesticPartnershipOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_domesticPartnerships_one: {
    __typename?: 'MatterServiceDomesticPartnerships';
    id: any;
  } | null;
};

export type DeleteMatterServiceDomesticPartnershipsOnMatterMutationVariables =
  Exact<{
    id: Scalars['MatterServiceUuid']['input'];
  }>;

export type DeleteMatterServiceDomesticPartnershipsOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_domesticPartnerships_by_pk: {
    __typename?: 'MatterServiceDomesticPartnerships';
    id: any;
  } | null;
};

export type MatterServiceDomesticPartnershipFragment = {
  __typename?: 'MatterServiceDomesticPartnerships';
  id: any;
  dateOfRegistration: any | null;
  dateOfSeparation: any | null;
  formattedDateOfRegistration: string | null;
  formattedDateOfSeparation: string | null;
  yearsOfPartnership: string | null;
  monthsOfPartnership: string | null;
};

export type UpdateMatterServiceDomesticPartnershipOnMatterMutationVariables =
  Exact<{
    id: Scalars['MatterServiceUuid']['input'];
    dateOfRegistration: InputMaybe<Scalars['MatterServiceDate']['input']>;
    dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
  }>;

export type UpdateMatterServiceDomesticPartnershipOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_domesticPartnerships_by_pk: {
    __typename?: 'MatterServiceDomesticPartnerships';
    id: any;
    dateOfRegistration: any | null;
    dateOfSeparation: any | null;
    formattedDateOfRegistration: string | null;
    formattedDateOfSeparation: string | null;
    yearsOfPartnership: string | null;
    monthsOfPartnership: string | null;
  } | null;
};

export type AddMatterServiceFolderItemToFolderMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  folderId: Scalars['MatterServiceUuid']['input'];
  itemIds: Scalars['MatterServiceJsonb']['input'];
}>;

export type AddMatterServiceFolderItemToFolderMutation = {
  __typename?: 'Mutation';
  matterServiceAddItemsToFolder: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type CreateMatterServiceFolderMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  folderName: Scalars['String']['input'];
  itemIds: Scalars['MatterServiceJsonb']['input'];
}>;

export type CreateMatterServiceFolderMutation = {
  __typename?: 'Mutation';
  matterServiceCreateFolderAddItems: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type DuplicateMatterServiceFolderItemToFolderMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  folderId: Scalars['MatterServiceUuid']['input'];
  itemIds: Scalars['MatterServiceJsonb']['input'];
}>;

export type DuplicateMatterServiceFolderItemToFolderMutation = {
  __typename?: 'Mutation';
  matterServiceDuplicateItemsToFolder: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type DuplicateMatterServiceFolderItemToNewFolderMutationVariables =
  Exact<{
    matterId: Scalars['MatterServiceUuid']['input'];
    folderName: Scalars['String']['input'];
    itemIds: Scalars['MatterServiceJsonb']['input'];
  }>;

export type DuplicateMatterServiceFolderItemToNewFolderMutation = {
  __typename?: 'Mutation';
  matterServiceCreateFolderDuplicateItems: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type MoveMatterServiceFolderItemMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  folderId: Scalars['MatterServiceUuid']['input'];
  newPosition: Scalars['Int']['input'];
  itemId: Scalars['MatterServiceUuid']['input'];
}>;

export type MoveMatterServiceFolderItemMutation = {
  __typename?: 'Mutation';
  matterServicePositionItemInFolder: Array<{
    __typename?: 'MatterServiceMatterDocsAndForms';
    id: any;
    folderId: any | null;
    position: number | null;
  }>;
};

export type RenameMatterServiceFolderMutationVariables = Exact<{
  folderId: Scalars['MatterServiceUuid']['input'];
  name: Scalars['String']['input'];
}>;

export type RenameMatterServiceFolderMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matterDocsAndFormsFolders_by_pk: {
    __typename?: 'MatterServiceMatterDocsAndFormsFolders';
    id: any;
    name: string;
  } | null;
};

export type UpdateMatterServiceFormDataMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  data: InputMaybe<Scalars['MatterServiceJsonb']['input']>;
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
  isDuplicate: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateMatterServiceFormDataMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matterDocsAndForms_by_pk: {
    __typename?: 'MatterServiceMatterDocsAndForms';
    data: any;
    status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
  } | null;
};

export type UpdateMatterServiceFormStatusMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  status: InputMaybe<MatterServiceMatterDocsAndFormsStatuses_Enum>;
}>;

export type UpdateMatterServiceFormStatusMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matterDocsAndForms_by_pk: {
    __typename?: 'MatterServiceMatterDocsAndForms';
    status: MatterServiceMatterDocsAndFormsStatuses_Enum | null;
  } | null;
};

export type CreateMatterServiceMarriageOnMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  dateOfMarriage: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
}>;

export type CreateMatterServiceMarriageOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_marriages_one: {
    __typename?: 'MatterServiceMarriages';
    id: any;
  } | null;
};

export type DeleteMatterServiceMarriageMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServiceMarriageMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_marriages_by_pk: {
    __typename?: 'MatterServiceMarriages';
    id: any;
  } | null;
};

export type GetMatterServiceMarriagesOnMatterQueryVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServiceMarriagesOnMatterQuery = {
  __typename?: 'Query';
  matterServiceMarriages: Array<{
    __typename?: 'MatterServiceMarriages';
    id: any;
    dateOfMarriage: any | null;
    dateOfSeparation: any | null;
    formattedDateOfMarriage: string | null;
    formattedDateOfSeparation: string | null;
    yearsOfMarriage: string | null;
    monthsOfMarriage: string | null;
  }>;
};

export type MatterServiceMarriageFragment = {
  __typename?: 'MatterServiceMarriages';
  id: any;
  dateOfMarriage: any | null;
  dateOfSeparation: any | null;
  formattedDateOfMarriage: string | null;
  formattedDateOfSeparation: string | null;
  yearsOfMarriage: string | null;
  monthsOfMarriage: string | null;
};

export type UpdateMatterServiceMarriageMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  dateOfMarriage: InputMaybe<Scalars['MatterServiceDate']['input']>;
  dateOfSeparation: InputMaybe<Scalars['MatterServiceDate']['input']>;
}>;

export type UpdateMatterServiceMarriageMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_marriages_by_pk: {
    __typename?: 'MatterServiceMarriages';
    id: any;
    dateOfMarriage: any | null;
    dateOfSeparation: any | null;
    formattedDateOfMarriage: string | null;
    formattedDateOfSeparation: string | null;
    yearsOfMarriage: string | null;
    monthsOfMarriage: string | null;
  } | null;
};

export type RemoveMatterServicePartiesFromMatterMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type RemoveMatterServicePartiesFromMatterMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    filingFor: MatterServiceMatterFilingForTypes_Enum | null;
  } | null;
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type AddMatterServicePersonAddressMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityAddressTypes_Enum;
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServicePersonAddressMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityAddresses: {
    __typename?: 'MatterServiceEntityAddresses_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityAddresses_one: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
  } | null;
};

export type AddMatterServicePersonEmailMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityEmailTypes_Enum;
  email: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServicePersonEmailMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityEmails: {
    __typename?: 'MatterServiceEntityEmails_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityEmails_one: {
    __typename?: 'MatterServiceEntityEmails';
    id: any;
  } | null;
};

export type AddMatterServicePersonPhoneNumberMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityPhoneNumberTypes_Enum;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMatterServicePersonPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
  matterServiceInsert_entityPhoneNumbers_one: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type AddMatterServicePersonToRoleMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  personRole: MatterServiceMatterPersonRoleTypes_Enum;
  personId: Scalars['MatterServiceUuid']['input'];
}>;

export type AddMatterServicePersonToRoleMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_matterEntities_one: {
    __typename?: 'MatterServiceMatterEntities';
    id: any;
  } | null;
};

export type ClearMatterServiceAllPersonRolesMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  personId: Scalars['MatterServiceUuid']['input'];
}>;

export type ClearMatterServiceAllPersonRolesMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type ClearMatterServicePersonRoleMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  personRole: MatterServiceMatterPersonRoleTypes_Enum;
}>;

export type ClearMatterServicePersonRoleMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type ClearMatterServicePersonRoleForPersonMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  personId: Scalars['MatterServiceUuid']['input'];
  personRole: MatterServiceMatterPersonRoleTypes_Enum;
}>;

export type ClearMatterServicePersonRoleForPersonMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_matterEntities: {
    __typename?: 'MatterServiceMatterEntities_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateMatterServicePersonMutationVariables = Exact<{
  clientId: Scalars['MatterServiceUuid']['input'];
  matterId: Scalars['MatterServiceUuid']['input'];
  personRole: InputMaybe<MatterServiceMatterPersonRoleTypes_Enum>;
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  nickname: InputMaybe<Scalars['String']['input']>;
  formerName: InputMaybe<Scalars['String']['input']>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum>;
  dateOfBirth: InputMaybe<Scalars['MatterServiceDate']['input']>;
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
  socialSecurityNumber: InputMaybe<Scalars['String']['input']>;
  driversLicense: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['String']['input']>;
  weight: InputMaybe<Scalars['String']['input']>;
  hairColor: InputMaybe<Scalars['String']['input']>;
  eyeColor: InputMaybe<Scalars['String']['input']>;
  race: InputMaybe<Scalars['String']['input']>;
  occupation: InputMaybe<Scalars['String']['input']>;
  employerName: InputMaybe<Scalars['String']['input']>;
  phoneNumbers: InputMaybe<MatterServiceEntityPhoneNumbers_Arr_Rel_Insert_Input>;
  emails: InputMaybe<MatterServiceEntityEmails_Arr_Rel_Insert_Input>;
  addresses: InputMaybe<MatterServiceEntityAddresses_Arr_Rel_Insert_Input>;
}>;

export type CreateMatterServicePersonMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_people_one: {
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
  } | null;
};

export type DeleteMatterServicePersonMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteMatterServicePersonMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_people_by_pk: {
    __typename?: 'MatterServicePeople';
    id: any;
  } | null;
};

export type DeleteMatterServicePersonAddressMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityAddressTypes_Enum;
}>;

export type DeleteMatterServicePersonAddressMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityAddresses: {
    __typename?: 'MatterServiceEntityAddresses_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMatterServicePersonEmailMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityEmailTypes_Enum;
}>;

export type DeleteMatterServicePersonEmailMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityEmails: {
    __typename?: 'MatterServiceEntityEmails_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteMatterServicePersonPhoneNumberMutationVariables = Exact<{
  personId: Scalars['MatterServiceUuid']['input'];
  type: MatterServiceEntityPhoneNumberTypes_Enum;
}>;

export type DeleteMatterServicePersonPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_entityPhoneNumbers: {
    __typename?: 'MatterServiceEntityPhoneNumbers_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetMatterServicePeopleQueryVariables = Exact<{
  clientId: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServicePeopleQuery = {
  __typename?: 'Query';
  matterServicePeople: Array<{
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  }>;
};

export type GetMatterServicePeopleForMatterQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServicePeopleForMatterQuery = {
  __typename?: 'Query';
  matterServiceMatters_by_pk: {
    __typename?: 'MatterServiceMatters';
    filingFor: MatterServiceMatterFilingForTypes_Enum | null;
    plaintiffOrPetitioner: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
    defendantOrRespondent: Array<{
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    }> | null;
  } | null;
};

export type GetMatterServicePeopleWithRolesQueryVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServicePeopleWithRolesQuery = {
  __typename?: 'Query';
  matterServiceMatterEntities: Array<{
    __typename?: 'MatterServiceMatterEntities';
    id: any;
    personRole: MatterServiceMatterPersonRoleTypes_Enum | null;
    person: {
      __typename?: 'MatterServicePeople';
      id: any;
      fullName: string | null;
      firstName: string;
      middleName: string | null;
      lastName: string;
      suffix: string | null;
      formerName: string | null;
      nickname: string | null;
      race: string | null;
      gender: MatterServicePeopleGenders_Enum | null;
      formattedGender: string | null;
      isMale: boolean | null;
      isFemale: boolean | null;
      isNonBinary: boolean | null;
      dateOfBirth: any | null;
      formattedDateOfBirth: string | null;
      age: string | null;
      estimatedAge: number | null;
      socialSecurityNumber: string | null;
      driversLicense: string | null;
      height: string | null;
      weight: string | null;
      hairColor: string | null;
      eyeColor: string | null;
      occupation: string | null;
      employerName: string | null;
      personalPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      homeAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      personalEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
      workPhone: Array<{
        __typename?: 'MatterServiceEntityPhoneNumbers';
        id: any;
        phoneNumber: string | null;
      }>;
      workAddress: Array<{
        __typename?: 'MatterServiceEntityAddresses';
        id: any;
        streetAddress: string | null;
        streetAddressLineTwo: string | null;
        streetAddressBothLines: string | null;
        mailingAddress: string | null;
        city: string | null;
        state: string | null;
        zipCode: string | null;
        cityAndZipCode: string | null;
        fullAddressSingleLine: string | null;
      }>;
      workEmail: Array<{
        __typename?: 'MatterServiceEntityEmails';
        id: any;
        email: string;
      }>;
    } | null;
  }>;
};

export type GetMatterServicePersonQueryVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServicePersonQuery = {
  __typename?: 'Query';
  matterServicePeople_by_pk: {
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    suffix: string | null;
    formerName: string | null;
    nickname: string | null;
    race: string | null;
    gender: MatterServicePeopleGenders_Enum | null;
    formattedGender: string | null;
    isMale: boolean | null;
    isFemale: boolean | null;
    isNonBinary: boolean | null;
    dateOfBirth: any | null;
    formattedDateOfBirth: string | null;
    age: string | null;
    estimatedAge: number | null;
    socialSecurityNumber: string | null;
    driversLicense: string | null;
    height: string | null;
    weight: string | null;
    hairColor: string | null;
    eyeColor: string | null;
    occupation: string | null;
    employerName: string | null;
    personalPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    homeAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    personalEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
    workPhone: Array<{
      __typename?: 'MatterServiceEntityPhoneNumbers';
      id: any;
      phoneNumber: string | null;
    }>;
    workAddress: Array<{
      __typename?: 'MatterServiceEntityAddresses';
      id: any;
      streetAddress: string | null;
      streetAddressLineTwo: string | null;
      streetAddressBothLines: string | null;
      mailingAddress: string | null;
      city: string | null;
      state: string | null;
      zipCode: string | null;
      cityAndZipCode: string | null;
      fullAddressSingleLine: string | null;
    }>;
    workEmail: Array<{
      __typename?: 'MatterServiceEntityEmails';
      id: any;
      email: string;
    }>;
  } | null;
};

export type GetMatterServicePersonRolesQueryVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
  personId: Scalars['MatterServiceUuid']['input'];
}>;

export type GetMatterServicePersonRolesQuery = {
  __typename?: 'Query';
  matterServiceMatterEntities: Array<{
    __typename?: 'MatterServiceMatterEntities';
    id: any;
    personRole: MatterServiceMatterPersonRoleTypes_Enum | null;
  }>;
};

export type MatterServicePersonFragment = {
  __typename?: 'MatterServicePeople';
  id: any;
  fullName: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  suffix: string | null;
  formerName: string | null;
  nickname: string | null;
  race: string | null;
  gender: MatterServicePeopleGenders_Enum | null;
  formattedGender: string | null;
  isMale: boolean | null;
  isFemale: boolean | null;
  isNonBinary: boolean | null;
  dateOfBirth: any | null;
  formattedDateOfBirth: string | null;
  age: string | null;
  estimatedAge: number | null;
  socialSecurityNumber: string | null;
  driversLicense: string | null;
  height: string | null;
  weight: string | null;
  hairColor: string | null;
  eyeColor: string | null;
  occupation: string | null;
  employerName: string | null;
  personalPhone: Array<{
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
    phoneNumber: string | null;
  }>;
  homeAddress: Array<{
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    streetAddressBothLines: string | null;
    mailingAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    cityAndZipCode: string | null;
    fullAddressSingleLine: string | null;
  }>;
  personalEmail: Array<{
    __typename?: 'MatterServiceEntityEmails';
    id: any;
    email: string;
  }>;
  workPhone: Array<{
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
    phoneNumber: string | null;
  }>;
  workAddress: Array<{
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
    streetAddress: string | null;
    streetAddressLineTwo: string | null;
    streetAddressBothLines: string | null;
    mailingAddress: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    cityAndZipCode: string | null;
    fullAddressSingleLine: string | null;
  }>;
  workEmail: Array<{
    __typename?: 'MatterServiceEntityEmails';
    id: any;
    email: string;
  }>;
};

export type UpdateMatterServiceFilingForMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  filingFor: InputMaybe<MatterServiceMatterFilingForTypes_Enum>;
}>;

export type UpdateMatterServiceFilingForMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_matters_by_pk: {
    __typename?: 'MatterServiceMatters';
    filingFor: MatterServiceMatterFilingForTypes_Enum | null;
  } | null;
};

export type UpdateMatterServicePersonMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  firstName: InputMaybe<Scalars['String']['input']>;
  middleName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  suffix: InputMaybe<Scalars['String']['input']>;
  nickname: InputMaybe<Scalars['String']['input']>;
  formerName: InputMaybe<Scalars['String']['input']>;
  gender: InputMaybe<MatterServicePeopleGenders_Enum>;
  dateOfBirth: InputMaybe<Scalars['MatterServiceDate']['input']>;
  estimatedAge: InputMaybe<Scalars['Int']['input']>;
  socialSecurityNumber: InputMaybe<Scalars['String']['input']>;
  driversLicense: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['String']['input']>;
  weight: InputMaybe<Scalars['String']['input']>;
  hairColor: InputMaybe<Scalars['String']['input']>;
  eyeColor: InputMaybe<Scalars['String']['input']>;
  race: InputMaybe<Scalars['String']['input']>;
  occupation: InputMaybe<Scalars['String']['input']>;
  employerName: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServicePersonMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_people_by_pk: {
    __typename?: 'MatterServicePeople';
    id: any;
    fullName: string | null;
  } | null;
};

export type UpdateMatterServicePersonAddressMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  streetAddress: InputMaybe<Scalars['String']['input']>;
  streetAddressLineTwo: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServicePersonAddressMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityAddresses_by_pk: {
    __typename?: 'MatterServiceEntityAddresses';
    id: any;
  } | null;
};

export type UpdateMatterServicePersonEmailMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  email: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServicePersonEmailMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityEmails_by_pk: {
    __typename?: 'MatterServiceEntityEmails';
    id: any;
  } | null;
};

export type UpdateMatterServicePersonPhoneNumberMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
  phoneNumber: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateMatterServicePersonPhoneNumberMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_entityPhoneNumbers_by_pk: {
    __typename?: 'MatterServiceEntityPhoneNumbers';
    id: any;
  } | null;
};

export type CreateTemporaryRestrainingOrderOnMatterMutationVariables = Exact<{
  matterId: Scalars['MatterServiceUuid']['input'];
}>;

export type CreateTemporaryRestrainingOrderOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceInsert_temporaryRestrainingOrders_one: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
  } | null;
};

export type DeleteTemporaryRestrainingOrdersOnMatterMutationVariables = Exact<{
  id: Scalars['MatterServiceUuid']['input'];
}>;

export type DeleteTemporaryRestrainingOrdersOnMatterMutation = {
  __typename?: 'Mutation';
  matterServiceDelete_temporaryRestrainingOrders_by_pk: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
  } | null;
};

export type UpdateTemporaryRestrainingOrderProtectedPersonMutationVariables =
  Exact<{
    temporaryRestrainingOrderId: Scalars['MatterServiceUuid']['input'];
    personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  }>;

export type UpdateTemporaryRestrainingOrderProtectedPersonMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_temporaryRestrainingOrders_by_pk: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
  } | null;
};

export type UpdateTemporaryRestrainingOrderRelationshipMutationVariables =
  Exact<{
    temporaryRestrainingOrderId: Scalars['MatterServiceUuid']['input'];
    relationshipToProtectedPerson: InputMaybe<Scalars['String']['input']>;
  }>;

export type UpdateTemporaryRestrainingOrderRelationshipMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_temporaryRestrainingOrders_by_pk: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
  } | null;
};

export type UpdateTemporaryRestrainingOrderRestrictedPersonMutationVariables =
  Exact<{
    temporaryRestrainingOrderId: Scalars['MatterServiceUuid']['input'];
    personId: InputMaybe<Scalars['MatterServiceUuid']['input']>;
  }>;

export type UpdateTemporaryRestrainingOrderRestrictedPersonMutation = {
  __typename?: 'Mutation';
  matterServiceUpdate_temporaryRestrainingOrders_by_pk: {
    __typename?: 'MatterServiceTemporaryRestrainingOrders';
    id: any;
  } | null;
};

export type ContentfulContributorFragment = {
  __typename?: 'ContentfulContributor';
  webDisplayName: string | null;
  shortBio: string | null;
  headshot: { __typename?: 'ContentfulAsset'; url: string | null } | null;
};

export type ContentfulCurrentAwarenessFragment = {
  __typename?: 'ContentfulCurrentAwareness';
  featuredImageCaption: string | null;
  originalPublishDate: any | null;
  slug: string | null;
  title: string | null;
  updatedDate: any | null;
  authorsCollection: {
    __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
    items: Array<{
      __typename?: 'ContentfulContributor';
      webDisplayName: string | null;
      shortBio: string | null;
      headshot: { __typename?: 'ContentfulAsset'; url: string | null } | null;
    } | null>;
  } | null;
  content: {
    __typename?: 'ContentfulCurrentAwarenessContent';
    json: any;
  } | null;
  featuredImage: { __typename?: 'ContentfulAsset'; url: string | null } | null;
  practiceAreasCollection: {
    __typename?: 'ContentfulCurrentAwarenessPracticeAreasCollection';
    items: Array<{
      __typename?: 'ContentfulPracticeArea';
      slug: string | null;
      name: string | null;
      sys: { __typename?: 'ContentfulSys'; id: string };
    } | null>;
  } | null;
  relatedContentCollection: {
    __typename?: 'ContentfulCurrentAwarenessRelatedContentCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      originalPublishDate: any | null;
      slug: string | null;
      title: string | null;
      authorsCollection: {
        __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
        } | null>;
      } | null;
      content: {
        __typename?: 'ContentfulCurrentAwarenessContent';
        json: any;
      } | null;
    } | null>;
  } | null;
  topicsCollection: {
    __typename?: 'ContentfulCurrentAwarenessTopicsCollection';
    items: Array<{
      __typename?: 'ContentfulTopic';
      name: string | null;
    } | null>;
  } | null;
};

export type RelatedContentfulCurrentAwarenessFragment = {
  __typename?: 'ContentfulCurrentAwareness';
  originalPublishDate: any | null;
  slug: string | null;
  title: string | null;
  authorsCollection: {
    __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
    items: Array<{
      __typename?: 'ContentfulContributor';
      webDisplayName: string | null;
    } | null>;
  } | null;
  content: {
    __typename?: 'ContentfulCurrentAwarenessContent';
    json: any;
  } | null;
};

export type GetNextAndPreviousPostQueryVariables = Exact<{
  currentDate: Scalars['ContentfulDateTime']['input'];
  postDate: Scalars['ContentfulDateTime']['input'];
  resourceTypes:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type GetNextAndPreviousPostQuery = {
  __typename?: 'Query';
  nextPost: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      slug: string | null;
      title: string | null;
    } | null>;
  } | null;
  previousPost: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      slug: string | null;
      title: string | null;
    } | null>;
  } | null;
};

export type GetPostQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  date: Scalars['ContentfulDateTime']['input'];
}>;

export type GetPostQuery = {
  __typename?: 'Query';
  post: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      featuredImageCaption: string | null;
      originalPublishDate: any | null;
      slug: string | null;
      title: string | null;
      updatedDate: any | null;
      authorsCollection: {
        __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
          shortBio: string | null;
          headshot: {
            __typename?: 'ContentfulAsset';
            url: string | null;
          } | null;
        } | null>;
      } | null;
      content: {
        __typename?: 'ContentfulCurrentAwarenessContent';
        json: any;
      } | null;
      featuredImage: {
        __typename?: 'ContentfulAsset';
        url: string | null;
      } | null;
      practiceAreasCollection: {
        __typename?: 'ContentfulCurrentAwarenessPracticeAreasCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          slug: string | null;
          name: string | null;
          sys: { __typename?: 'ContentfulSys'; id: string };
        } | null>;
      } | null;
      relatedContentCollection: {
        __typename?: 'ContentfulCurrentAwarenessRelatedContentCollection';
        items: Array<{
          __typename?: 'ContentfulCurrentAwareness';
          originalPublishDate: any | null;
          slug: string | null;
          title: string | null;
          authorsCollection: {
            __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
            items: Array<{
              __typename?: 'ContentfulContributor';
              webDisplayName: string | null;
            } | null>;
          } | null;
          content: {
            __typename?: 'ContentfulCurrentAwarenessContent';
            json: any;
          } | null;
        } | null>;
      } | null;
      topicsCollection: {
        __typename?: 'ContentfulCurrentAwarenessTopicsCollection';
        items: Array<{
          __typename?: 'ContentfulTopic';
          name: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
  recentPosts: {
    __typename?: 'ContentfulCurrentAwarenessCollection';
    items: Array<{
      __typename?: 'ContentfulCurrentAwareness';
      originalPublishDate: any | null;
      slug: string | null;
      title: string | null;
      authorsCollection: {
        __typename?: 'ContentfulCurrentAwarenessAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
        } | null>;
      } | null;
      content: {
        __typename?: 'ContentfulCurrentAwarenessContent';
        json: any;
      } | null;
    } | null>;
  } | null;
};

export type GetContentfulPractitionerDocumentsQueryVariables = Exact<{
  contentfulId: Scalars['String']['input'];
}>;

export type GetContentfulPractitionerDocumentsQuery = {
  __typename?: 'Query';
  contentfulEntryCollection: {
    __typename?: 'ContentfulEntryCollection';
    items: Array<
      | { __typename: 'ContentfulAgency' }
      | { __typename: 'ContentfulAgreementsAndLicenses' }
      | { __typename: 'ContentfulBook' }
      | { __typename: 'ContentfulChapter' }
      | { __typename: 'ContentfulContentPublisher' }
      | { __typename: 'ContentfulContributor' }
      | { __typename: 'ContentfulContributorDirectory' }
      | { __typename: 'ContentfulContributorOrganization' }
      | { __typename: 'ContentfulCourse' }
      | { __typename: 'ContentfulCourseCreditCategory' }
      | { __typename: 'ContentfulCoursesCollection' }
      | { __typename: 'ContentfulCourt' }
      | { __typename: 'ContentfulCurrentAwareness' }
      | { __typename: 'ContentfulDocumentType' }
      | { __typename: 'ContentfulEndMatter' }
      | { __typename: 'ContentfulForm' }
      | { __typename: 'ContentfulFormContainer' }
      | { __typename: 'ContentfulFormSource' }
      | { __typename: 'ContentfulFormsProvider' }
      | { __typename: 'ContentfulFrontMatter' }
      | { __typename: 'ContentfulGraphAuthentication' }
      | {
          __typename: 'ContentfulKnowHowDocument';
          slug: string | null;
          practitionerDocumentType: {
            __typename?: 'ContentfulDocumentType';
            name: string | null;
          } | null;
        }
      | { __typename: 'ContentfulKnowHowProduct' }
      | { __typename: 'ContentfulLegalTask' }
      | { __typename: 'ContentfulLooseleafBook' }
      | { __typename: 'ContentfulLooseleafChapter' }
      | { __typename: 'ContentfulLooseleafEndMatter' }
      | { __typename: 'ContentfulLooseleafFrontMatter' }
      | { __typename: 'ContentfulPlanOffering' }
      | { __typename: 'ContentfulPlanOfferingOption' }
      | { __typename: 'ContentfulPracticeArea' }
      | {
          __typename: 'ContentfulPractitionerWorkflow';
          slug: string | null;
          practitionerDocumentType: {
            __typename?: 'ContentfulDocumentType';
            name: string | null;
          } | null;
        }
      | { __typename: 'ContentfulPractitionerWorkflowSectionGroup' }
      | { __typename: 'ContentfulPractitionerWorkflowSectionItems' }
      | { __typename: 'ContentfulProgram' }
      | { __typename: 'ContentfulPublicationBook' }
      | { __typename: 'ContentfulPublicationBookType' }
      | { __typename: 'ContentfulPublicationEdition' }
      | { __typename: 'ContentfulPublicationEditionChapter' }
      | { __typename: 'ContentfulSimplePage' }
      | { __typename: 'ContentfulTopic' }
      | { __typename: 'ContentfulTrueDocsAndForms' }
      | { __typename: 'ContentfulTrueDocsFormsCollections' }
      | null
    >;
  } | null;
};

export type GetPracticeAreaSubtopicQueryVariables = Exact<{
  practiceArea: Scalars['String']['input'];
  practiceAreaSubtopic: Scalars['String']['input'];
}>;

export type GetPracticeAreaSubtopicQuery = {
  __typename?: 'Query';
  practiceArea: {
    __typename?: 'ContentfulPracticeAreaCollection';
    items: Array<{
      __typename?: 'ContentfulPracticeArea';
      name: string | null;
      slug: string | null;
    } | null>;
  } | null;
  practiceAreaSubtopic: {
    __typename?: 'ContentfulPracticeAreaCollection';
    items: Array<{
      __typename?: 'ContentfulPracticeArea';
      name: string | null;
      slug: string | null;
      description: string | null;
      linkedFrom: {
        __typename?: 'ContentfulPracticeAreaLinkingCollections';
        knowHowDocumentCollection: {
          __typename?: 'ContentfulKnowHowDocumentCollection';
          total: number;
        } | null;
      } | null;
      practiceAreaSubcategories: {
        __typename?: 'ContentfulPracticeAreaPracticeAreaSubtopicsCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          name: string | null;
          slug: string | null;
          linkedFrom: {
            __typename?: 'ContentfulPracticeAreaLinkingCollections';
            knowHowDocumentCollection: {
              __typename?: 'ContentfulKnowHowDocumentCollection';
              total: number;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type GetPractitionerDocumentQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  preview: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetPractitionerDocumentQuery = {
  __typename?: 'Query';
  contentfulKnowHowDocumentCollection: {
    __typename?: 'ContentfulKnowHowDocumentCollection';
    items: Array<{
      __typename?: 'ContentfulKnowHowDocument';
      title: string | null;
      originalPublishDate: any | null;
      updatedDate: any | null;
      processedContent: any | null;
      authorities: any | null;
      practitionerDocumentType: {
        __typename?: 'ContentfulDocumentType';
        name: string | null;
      } | null;
      authorsCollection: {
        __typename?: 'ContentfulKnowHowDocumentAuthorsCollection';
        items: Array<{
          __typename?: 'ContentfulContributor';
          webDisplayName: string | null;
          slug: string | null;
        } | null>;
      } | null;
      practiceAreasCollection: {
        __typename?: 'ContentfulKnowHowDocumentPracticeAreasCollection';
        items: Array<{
          __typename?: 'ContentfulPracticeArea';
          name: string | null;
          slug: string | null;
        } | null>;
      } | null;
      legalTasksCollection: {
        __typename?: 'ContentfulKnowHowDocumentLegalTasksCollection';
        items: Array<{
          __typename?: 'ContentfulLegalTask';
          name: string | null;
        } | null>;
      } | null;
      court: { __typename?: 'ContentfulCourt'; name: string | null } | null;
      relatedContentCollection: {
        __typename?: 'ContentfulKnowHowDocumentRelatedContentCollection';
        items: Array<{
          __typename?: 'ContentfulKnowHowDocument';
          title: string | null;
          slug: string | null;
          practitionerDocumentType: {
            __typename?: 'ContentfulDocumentType';
            name: string | null;
          } | null;
        } | null>;
      } | null;
      processedWordDocument: {
        __typename?: 'ContentfulAsset';
        url: string | null;
      } | null;
      processedWordDocumentNoNotes: {
        __typename?: 'ContentfulAsset';
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetPractitionerDocumentWorkflowQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPractitionerDocumentWorkflowQuery = {
  __typename?: 'Query';
  contentfulKnowHowDocumentCollection: {
    __typename?: 'ContentfulKnowHowDocumentCollection';
    items: Array<{
      __typename?: 'ContentfulKnowHowDocument';
      title: string | null;
      linkedFrom: {
        __typename?: 'ContentfulKnowHowDocumentLinkingCollections';
        practitionerWorkflowSectionItemsCollection: {
          __typename?: 'ContentfulPractitionerWorkflowSectionItemsCollection';
          items: Array<{
            __typename?: 'ContentfulPractitionerWorkflowSectionItems';
            linkedFrom: {
              __typename?: 'ContentfulPractitionerWorkflowSectionItemsLinkingCollections';
              practitionerWorkflowSectionGroupCollection: {
                __typename?: 'ContentfulPractitionerWorkflowSectionGroupCollection';
                items: Array<{
                  __typename?: 'ContentfulPractitionerWorkflowSectionGroup';
                  linkedFrom: {
                    __typename?: 'ContentfulPractitionerWorkflowSectionGroupLinkingCollections';
                    practitionerWorkflowCollection: {
                      __typename?: 'ContentfulPractitionerWorkflowCollection';
                      items: Array<{
                        __typename?: 'ContentfulPractitionerWorkflow';
                        title: string | null;
                        slug: string | null;
                      } | null>;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetPractitionerPracticeAreaSearchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPractitionerPracticeAreaSearchQuery = {
  __typename?: 'Query';
  searchFilter: {
    __typename?: 'SearchFilter';
    name: string | null;
    categoryId: string | null;
    children: Array<{
      __typename?: 'FilterOption';
      categoryId: string | null;
      name: string | null;
      filterValue: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        categoryId: string | null;
        name: string | null;
        filterValue: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetChapterQueryVariables = Exact<{
  onlawId: Scalars['String']['input'];
}>;

export type GetChapterQuery = {
  __typename?: 'Query';
  secondarySourcesTitleParts: Array<{
    __typename?: 'SecondarySourcesTitleParts';
    rootParent: {
      __typename?: 'SecondarySourcesTitleParts';
      rootParentChildren: Array<{
        __typename?: 'SecondarySourcesTitleParts';
        id: number;
        onlawId: string;
        depth: number | null;
        name: string;
        content: string | null;
        californiaCitationTemplate: any | null;
        standardCitationTemplate: any | null;
        hasChildren: boolean | null;
      }>;
    } | null;
  }>;
};

export type GetPracticeAreaQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetPracticeAreaQuery = {
  __typename?: 'Query';
  secondarySourcesPracticeAreas: Array<{
    __typename?: 'SecondarySourcesPracticeAreas';
    id: number;
    name: string;
    slug: string | null;
    titles: Array<{
      __typename?: 'SecondarySourcesPracticeAreaTitles';
      title: {
        __typename?: 'SecondarySourcesTitles';
        id: number;
        productTitle: string;
        productCode: string | null;
        tableOfContents: Array<{
          __typename?: 'SecondarySourcesTitleParts';
          onlawId: string;
        }>;
      };
    }>;
  }>;
};

export type GetSecondaryTitlesQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetSecondaryTitlesQuery = {
  __typename?: 'Query';
  secondarySourcesTitles: Array<{
    __typename?: 'SecondarySourcesTitles';
    id: number;
    practiceAreas: Array<{
      __typename?: 'SecondarySourcesPracticeAreaTitles';
      id: number;
      practiceArea: {
        __typename?: 'SecondarySourcesPracticeAreas';
        id: number;
        name: string;
      };
    }>;
  }>;
};

export type GetSecondarySourcesFavoritesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSecondarySourcesFavoritesQuery = {
  __typename?: 'Query';
  contentFavorites: Array<{
    __typename?: 'ContentFavorite';
    id: string;
    contentItemId: string;
  } | null> | null;
};

export type AddSecondarySourcesFavoriteMutationVariables = Exact<{
  contentItemId: Scalars['ID']['input'];
}>;

export type AddSecondarySourcesFavoriteMutation = {
  __typename?: 'Mutation';
  addContentFavorite: {
    __typename?: 'ContentFavorite';
    id: string;
    contentItemId: string;
  } | null;
};

export type DeleteSecondarySourcesFavoriteMutationVariables = Exact<{
  contentItemId: Scalars['ID']['input'];
}>;

export type DeleteSecondarySourcesFavoriteMutation = {
  __typename?: 'Mutation';
  deleteContentFavorite: boolean | null;
};

export type GetSecondarySourcesLandingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSecondarySourcesLandingQuery = {
  __typename?: 'Query';
  secondarySourcesPracticeAreas: Array<{
    __typename?: 'SecondarySourcesPracticeAreas';
    id: number;
    name: string;
  }>;
  secondarySourcesTitles: Array<{
    __typename?: 'SecondarySourcesTitles';
    id: number;
    productTitle: string;
    productCode: string | null;
    tableOfContents: Array<{
      __typename?: 'SecondarySourcesTitleParts';
      id: number;
      name: string;
      onlawId: string;
    }>;
    practiceAreas: Array<{
      __typename?: 'SecondarySourcesPracticeAreaTitles';
      primary: boolean;
      practiceArea: {
        __typename?: 'SecondarySourcesPracticeAreas';
        id: number;
        name: string;
        slug: string | null;
      };
    }>;
  }>;
};

export type GetTableOfContentsQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetTableOfContentsQuery = {
  __typename?: 'Query';
  secondarySourcesTitles: Array<{
    __typename?: 'SecondarySourcesTitles';
    id: number;
    tableOfContents: Array<{
      __typename?: 'SecondarySourcesTitleParts';
      id: number;
      onlawId: string;
      name: string;
      depth: number | null;
      order: number | null;
      hasContent: boolean | null;
      hasChildren: boolean | null;
    }>;
  }>;
};

export type PracticeAreaWithTitlesFragment = {
  __typename?: 'SecondarySourcesPracticeAreas';
  id: number;
  name: string;
  slug: string | null;
  titles: Array<{
    __typename?: 'SecondarySourcesPracticeAreaTitles';
    title: {
      __typename?: 'SecondarySourcesTitles';
      id: number;
      productTitle: string;
      productCode: string | null;
      tableOfContents: Array<{
        __typename?: 'SecondarySourcesTitleParts';
        onlawId: string;
      }>;
    };
  }>;
};

export type SecondarySourcesTitlePartsFragment = {
  __typename?: 'SecondarySourcesTitleParts';
  rootParent: {
    __typename?: 'SecondarySourcesTitleParts';
    rootParentChildren: Array<{
      __typename?: 'SecondarySourcesTitleParts';
      id: number;
      onlawId: string;
      depth: number | null;
      name: string;
      content: string | null;
      californiaCitationTemplate: any | null;
      standardCitationTemplate: any | null;
      hasChildren: boolean | null;
    }>;
  } | null;
};

export type SecondarySourcesPracticeAreasPracticeAreasFragment = {
  __typename?: 'SecondarySourcesPracticeAreas';
  id: number;
  name: string;
  slug: string | null;
};

export type CaConstitutionFragment = {
  __typename?: 'Code';
  name: string | null;
  judicataId: string | null;
  tableOfContents: Array<{
    __typename?: 'TableOfContentsEntry';
    title: string | null;
    depth: string | null;
    index: string | null;
    sectionBegin: string | null;
    sectionEnd: string | null;
    specifier: string | null;
  } | null> | null;
};

export type CodeFragment = {
  __typename?: 'Code';
  name: string | null;
  judicataId: string | null;
  tableOfContents: Array<{
    __typename?: 'TableOfContentsEntry';
    title: string | null;
    depth: string | null;
    index: string | null;
    sectionBegin: string | null;
    sectionEnd: string | null;
  } | null> | null;
};

export type CodeHeadingFragment = {
  __typename?: 'CodeHeading';
  title: string | null;
  sectionBegin: string | null;
  sectionEnd: string | null;
  codeSections: Array<{
    __typename?: 'CodeSection';
    abbreviatedSectionTitle: string | null;
    content: string | null;
    history: string | null;
  } | null> | null;
};

export type CodeSectionFragment = {
  __typename?: 'CodeSection';
  title: string | null;
  abbreviatedSectionTitle: string | null;
  content: string | null;
  history: string | null;
  citationCount: number | null;
  judicataNumericId: string | null;
  searchHighlightTerms: Array<string | null> | null;
  californiaCitationFormat: Array<{
    __typename?: 'CitationTemplateEntry';
    format: string | null;
    text: string | null;
    typeName: string | null;
  } | null> | null;
  standardCitationFormat: Array<{
    __typename?: 'CitationTemplateEntry';
    format: string | null;
    text: string | null;
    typeName: string | null;
  } | null> | null;
  parentTableOfContents: Array<{
    __typename?: 'TableOfContentsEntry';
    title: string | null;
    depth: string | null;
    index: string | null;
    sectionBegin: string | null;
    sectionEnd: string | null;
  } | null> | null;
};

export type GetCaConstitutionQueryVariables = Exact<{ [key: string]: never }>;

export type GetCaConstitutionQuery = {
  __typename?: 'Query';
  code: {
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
    tableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
      specifier: string | null;
    } | null> | null;
  } | null;
};

export type GetCaConstitutionHeadingQueryVariables = Exact<{
  article: Scalars['String']['input'];
}>;

export type GetCaConstitutionHeadingQuery = {
  __typename?: 'Query';
  code: {
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
    tableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
      specifier: string | null;
    } | null> | null;
  } | null;
  caConstitutionHeading: {
    __typename?: 'CaConstitutionHeading';
    title: string | null;
    sectionBegin: string | null;
    sectionEnd: string | null;
    parentTableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      name: string | null;
      specifier: string | null;
    } | null> | null;
    codeSections: Array<{
      __typename?: 'CaConstitutionSection';
      abbreviatedSectionTitle: string | null;
      content: string | null;
      history: string | null;
      judicataId: string | null;
    } | null> | null;
  } | null;
};

export type GetCaConstitutionSectionQueryVariables = Exact<{
  article: Scalars['String']['input'];
  judicataId: Scalars['String']['input'];
}>;

export type GetCaConstitutionSectionQuery = {
  __typename?: 'Query';
  caConstitutionSection: {
    __typename?: 'CaConstitutionSection';
    title: string | null;
    abbreviatedSectionTitle: string | null;
    content: string | null;
    history: string | null;
    citationCount: number | null;
    judicataNumericId: string | null;
    searchHighlightTerms: Array<string | null> | null;
    parentTableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
    } | null> | null;
  } | null;
};

export type GetCodeQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
  codeJudicataId: Scalars['String']['input'];
}>;

export type GetCodeQuery = {
  __typename?: 'Query';
  statute: {
    __typename?: 'Statute';
    name: string | null;
    codes: Array<{
      __typename?: 'Code';
      name: string | null;
      judicataId: string | null;
    } | null> | null;
  } | null;
  code: {
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
    tableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
    } | null> | null;
  } | null;
};

export type GetCodeHeadingQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
  codeJudicataId: Scalars['String']['input'];
  judicataIdBegin: Scalars['String']['input'];
  judicataIdEnd: Scalars['String']['input'];
}>;

export type GetCodeHeadingQuery = {
  __typename?: 'Query';
  code: {
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
    tableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
    } | null> | null;
  } | null;
  codeSection: {
    __typename?: 'CodeSection';
    title: string | null;
    abbreviatedSectionTitle: string | null;
    content: string | null;
    history: string | null;
    citationCount: number | null;
    judicataNumericId: string | null;
    searchHighlightTerms: Array<string | null> | null;
    californiaCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
    standardCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
    parentTableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
    } | null> | null;
  } | null;
  codeHeading: {
    __typename?: 'CodeHeading';
    title: string | null;
    sectionBegin: string | null;
    sectionEnd: string | null;
    codeSections: Array<{
      __typename?: 'CodeSection';
      abbreviatedSectionTitle: string | null;
      content: string | null;
      history: string | null;
    } | null> | null;
  } | null;
};

export type GetCodeReferenceCountQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
  codeJudicataId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
}>;

export type GetCodeReferenceCountQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    searchFilter: {
      __typename?: 'SearchFilter';
      name: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCodeReferencesQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
  codeJudicataId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
  optionFilters: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetCodeReferencesQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchResultConnection';
    totalCount: number | null;
    searchFilter: {
      __typename?: 'SearchFilter';
      name: string | null;
      children: Array<{
        __typename?: 'FilterOption';
        name: string | null;
        count: number | null;
      } | null> | null;
    } | null;
    edges: Array<
      | { __typename?: 'CaseSearchEdge' }
      | { __typename?: 'GenericSearchEdge' }
      | { __typename?: 'PractitionerSearchEdge' }
      | {
          __typename?: 'PublicationSectionSearchEdge';
          breadcrumbs: Array<string | null> | null;
          ordering: number | null;
          node: {
            __typename?: 'PublicationSearchNode';
            id: number | null;
            secondarySourcesTitleParts: Array<{
              __typename?: 'SecondarySourcesTitleParts';
              id: number;
              name: string;
              onlawId: string;
              title: {
                __typename?: 'SecondarySourcesTitles';
                onlawId: string;
                productCode: string | null;
                productTitle: string;
                firstTitlePart: Array<{
                  __typename?: 'SecondarySourcesTitleParts';
                  onlawId: string;
                }>;
                practiceAreas: Array<{
                  __typename?: 'SecondarySourcesPracticeAreaTitles';
                  primary: boolean;
                  practiceArea: {
                    __typename?: 'SecondarySourcesPracticeAreas';
                    name: string;
                    slug: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
          };
        }
      | { __typename?: 'StatuteSearchEdge' }
      | null
    > | null;
  } | null;
};

export type GetCodeSectionQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
  codeJudicataId: Scalars['String']['input'];
  judicataId: Scalars['String']['input'];
  fromQuery: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCodeSectionQuery = {
  __typename?: 'Query';
  codeSection: {
    __typename?: 'CodeSection';
    title: string | null;
    abbreviatedSectionTitle: string | null;
    content: string | null;
    history: string | null;
    citationCount: number | null;
    judicataNumericId: string | null;
    searchHighlightTerms: Array<string | null> | null;
    californiaCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
    standardCitationFormat: Array<{
      __typename?: 'CitationTemplateEntry';
      format: string | null;
      text: string | null;
      typeName: string | null;
    } | null> | null;
    parentTableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
    } | null> | null;
  } | null;
  statute: {
    __typename?: 'Statute';
    name: string | null;
    codes: Array<{
      __typename?: 'Code';
      name: string | null;
      judicataId: string | null;
    } | null> | null;
  } | null;
  code: {
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
    tableOfContents: Array<{
      __typename?: 'TableOfContentsEntry';
      title: string | null;
      depth: string | null;
      index: string | null;
      sectionBegin: string | null;
      sectionEnd: string | null;
    } | null> | null;
  } | null;
};

export type GetStatuteQueryVariables = Exact<{
  jurisdictionId: Scalars['String']['input'];
}>;

export type GetStatuteQuery = {
  __typename?: 'Query';
  statute: {
    __typename?: 'Statute';
    name: string | null;
    codes: Array<{
      __typename?: 'Code';
      name: string | null;
      judicataId: string | null;
    } | null> | null;
  } | null;
};

export type StatuteFragment = {
  __typename?: 'Statute';
  name: string | null;
  codes: Array<{
    __typename?: 'Code';
    name: string | null;
    judicataId: string | null;
  } | null> | null;
};

export const CaseSearchEdgeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CaseSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'citingTreatment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'levelOfDiscussion' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attitudeName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cite' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'citationCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastCitedOn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentAttitudeName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exampleTreatingCase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'treatmentDescription' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseSearchEdgeFragment, unknown>;
export const ContentfulTrueDocsAndFormsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulTrueDocsAndForms' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulTrueDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cebGuidance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContentfulTrueDocsAndFormsFragment, unknown>;
export const CurrentAwarenessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postResourceType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentAwarenessFragment, unknown>;
export const AuthorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Author' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Author' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'webDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortBio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headshotUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorFragment, unknown>;
export const SearchResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchResultFragment, unknown>;
export const PublicationSearchEdgeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicationSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicationSectionSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondarySourcesTitleParts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'title' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'practiceAreas' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'primary',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'practiceArea',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleProductCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleOnlawId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicationSearchEdgeFragment, unknown>;
export const GenericSearchEdgeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenericSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GenericSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Generic' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'secondarySourcesDocumentParts',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'documentTitle',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenericSearchEdgeFragment, unknown>;
export const StatuteSearchEdgeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatuteSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StatuteSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CodeSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeJudicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jurisdictionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CaConstitutionSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeJudicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'article' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jurisdictionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatuteSearchEdgeFragment, unknown>;
export const TrueDocsAndFormsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TrueDocsAndForms' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLETrueDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrueDocsAndFormsFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsConditionsLastAcceptance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isFirmAdmin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isBillingAccessOnlyFirmAdmin' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'engagementLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productTypesPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productPracticeAreasPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPracticeAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'needsEmailUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsEmailUpdateReason' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complianceGroupId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'complianceGroupOverrideId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const UserCleCreditAreaSummaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserCLECreditAreaSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primarySpecialty' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySpecialty' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditAreaSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredCredits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCredits' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cleCreditSubArea' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredCredits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCredits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completed' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleSpecializationSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredCredits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCredits' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCleCreditAreaSummaryFragment, unknown>;
export const CleCourseCreditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLECourseCredit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourseCredit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesforceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCreditHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditAreaHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditAreaName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CleCourseCreditFragment, unknown>;
export const CreditsEarnedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsEarned' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentPeriodCredits' },
            name: { kind: 'Name', value: 'cleCourseCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CLECourseCredit' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousPeriodsCredits' },
            name: { kind: 'Name', value: 'cleCourseCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compliancePeriod' },
                value: { kind: 'EnumValue', value: 'PREVIOUS' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CLECourseCredit' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLECourseCredit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourseCredit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesforceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCreditHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditAreaHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditAreaName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditsEarnedFragment, unknown>;
export const LearningProgressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearningProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditRequirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditsEarnedFromCeb' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LearningProgressFragment, unknown>;
export const UserPracticeAreasFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPracticeAreas' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPracticeAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedContentfulIds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPracticeAreasFragment, unknown>;
export const ContentfulPracticeAreasFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulPracticeAreas' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulPracticeArea' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreaSubtopicsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentAwarenessCollection',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContentfulPracticeAreasFragment, unknown>;
export const FirmUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFirmAdmin' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirmUserFragment, unknown>;
export const FirmInvitationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirmInvitationFragment, unknown>;
export const FirmInvitationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Firm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pendingOnly' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirmInvitationsFragment, unknown>;
export const FirmFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Firm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Firm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmUser' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FirmInvitations' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFirmAdmin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Firm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pendingOnly' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirmFragment, unknown>;
export const CourseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateRecorded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panoptoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCredits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsFileName' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRequirementsCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'speakers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliancePackageProductCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CourseDataFragment, unknown>;
export const RuleDocumentPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RuleDocumentParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesDocumentParts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentPartId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChildren' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RuleDocumentPartsFragment, unknown>;
export const CleCourseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLECourse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateRecorded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panoptoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCredits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsFileName' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRequirementsCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'speakers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliancePackageProductCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CleCourseFragment, unknown>;
export const CourseProgressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseProgress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulCourseId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highestPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceCourseResultId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CourseProgressFragment, unknown>;
export const MatterServiceEntityAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceEntityAddressFragment, unknown>;
export const MatterServiceCourtFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceCourt' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceCourts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'county' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addressBlock' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceCourtFragment, unknown>;
export const MatterServiceMatterDocsAndFormsFolderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceMatterDocsAndFormsFolderFragment,
  unknown
>;
export const MatterServiceAttorneyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceAttorneyFragment, unknown>;
export const MatterServicePersonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServicePersonFragment, unknown>;
export const MatterServiceMarriageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceMarriageFragment, unknown>;
export const MatterServiceClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceClientFragment, unknown>;
export const MatterServiceMatterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingForName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includesMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doesNotIncludeMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherParentClaimant' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attorneyForPlaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'attorneyForForDefendantOrRespondent',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingAttorney' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opposingCounsel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'court' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'county' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressBlock' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MatterServiceEntityAddress',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defendantOrRespondent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingForPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minorChildren' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMarriage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domesticPartnership' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearsOfPartnership' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthsOfPartnership' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temporaryRestrainingOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'relationshipToProtectedPerson',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restrainedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protectedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceMatterFragment, unknown>;
export const MatterServiceMatterAndFoldersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterAndFolders' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceMatterAndFoldersFragment, unknown>;
export const MatterServiceMatterDocsAndFormFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDuplicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceMatterDocsAndFormFragment, unknown>;
export const MatterServiceMatterDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceMatterDataFragment, unknown>;
export const MatterAttorneyDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterAttorneyData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddressLineTwo' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterAttorneyDataFragment, unknown>;
export const MatterCourtDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterCourtData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceCourts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'county' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addressBlock' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterCourtDataFragment, unknown>;
export const MatterServiceDomesticPartnershipFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceDomesticPartnership' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceDomesticPartnerships' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateOfRegistration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfRegistration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsOfPartnership' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsOfPartnership' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatterServiceDomesticPartnershipFragment, unknown>;
export const ContentfulContributorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulContributor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulContributor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'webDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortBio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'FACE' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContentfulContributorFragment, unknown>;
export const RelatedContentfulCurrentAwarenessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webDisplayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RelatedContentfulCurrentAwarenessFragment,
  unknown
>;
export const ContentfulCurrentAwarenessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContentfulContributor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '680' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '511' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'CENTER' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImageCaption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreasCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedContentCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RelatedContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulContributor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulContributor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'webDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortBio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'FACE' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webDisplayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContentfulCurrentAwarenessFragment, unknown>;
export const PracticeAreaWithTitlesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PracticeAreaWithTitles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesPracticeAreas' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productTitle' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'title' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tableOfContents' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'path' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onlawId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PracticeAreaWithTitlesFragment, unknown>;
export const SecondarySourcesTitlePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SecondarySourcesTitleParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesTitleParts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rootParent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rootParentChildren' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'path' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'californiaCitationTemplate',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'standardCitationTemplate',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasChildren' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SecondarySourcesTitlePartsFragment, unknown>;
export const SecondarySourcesPracticeAreasPracticeAreasFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SecondarySourcesPracticeAreasPracticeAreas',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesPracticeAreas' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SecondarySourcesPracticeAreasPracticeAreasFragment,
  unknown
>;
export const CaConstitutionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CAConstitution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specifier' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaConstitutionFragment, unknown>;
export const CodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Code' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CodeFragment, unknown>;
export const CodeHeadingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeHeading' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CodeHeading' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionBegin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'history' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CodeHeadingFragment, unknown>;
export const CodeSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeSection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CodeSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'history' } },
          { kind: 'Field', name: { kind: 'Name', value: 'citationCount' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'californiaCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'standardCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentTableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataNumericId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchHighlightTerms' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CodeSectionFragment, unknown>;
export const StatuteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Statute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatuteFragment, unknown>;
export const CaseSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'citedCaseJudicataId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'citedCodeSectionJudicataId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'published' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CasePublicationStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateGte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateLte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'CASE' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'casePublicationStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'published' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'citedCodeSectionJudicataId',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'citedCodeSectionJudicataId',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_gte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateGte' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateLte' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CaseSearchEdge' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaseSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CaseSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'citingTreatment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'levelOfDiscussion' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attitudeName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cite' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'citationCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastCitedOn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentAttitudeName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exampleTreatingCase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'treatmentDescription' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseSearchQuery, CaseSearchQueryVariables>;
export const ContentfulTrueDocsAndFormsBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'contentfulTrueDocsAndFormsBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'contentfulTrueDocsAndFormsCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ContentfulTrueDocsAndForms',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulTrueDocsAndForms' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulTrueDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cebGuidance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ContentfulTrueDocsAndFormsBySlugQuery,
  ContentfulTrueDocsAndFormsBySlugQueryVariables
>;
export const GetAllPracticeAreasWithCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPracticeAreasWithCourse' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulPracticeArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'StringValue',
                  value: '2IJw5gDFab7ZY5e7dNpLyL',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'practiceAreaSubtopicsCollection',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sys' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedFrom' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'courseCollection',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'total',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllPracticeAreasWithCourseQuery,
  GetAllPracticeAreasWithCourseQueryVariables
>;
export const GetAllSpecializationCreditCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllSpecializationCreditCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'contentfulCourseCreditCategoryCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'StringValue',
                        value: 'LSCLE Specialization Category',
                        block: false,
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'name_ASC' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'childCategoriesCollection',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSpecializationCreditCategoriesQuery,
  GetAllSpecializationCreditCategoriesQueryVariables
>;
export const GetAllSpecializationRequirementsCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetAllSpecializationRequirementsCategories',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'contentfulCourseCreditCategoryCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'StringValue',
                        value: 'Special Requirements',
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'childCategoriesCollection',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSpecializationRequirementsCategoriesQuery,
  GetAllSpecializationRequirementsCategoriesQueryVariables
>;
export const GetAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssets' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'onLawId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usageEndDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetsQuery, GetAssetsQueryVariables>;
export const GetCourseSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCourseSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentfulIds_not_in' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentfulIds_in' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreas' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'specialRequirements' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'specializationCredits' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateRecorded_gte' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateRecorded_lte' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageSize' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'search' },
            name: { kind: 'Name', value: 'courseSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageSize' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'practiceAreaIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceAreas' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentfulIds_not_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contentfulIds_not_in' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentfulIds_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contentfulIds_in' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'specialRequirementsIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'specialRequirements' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'specializationIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'specializationCredits' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateRecorded_gte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateRecorded_gte' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateRecorded_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateRecorded_lte' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentfulId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRecorded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featureImageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCredits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'courseMaterialsUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceAreas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'specialRequirementsCredits',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'parentContentfulId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'credits' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specializationCredits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'parentContentfulId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'credits' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collections' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compliancePackageProductCode',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCourseSearchQuery,
  GetCourseSearchQueryVariables
>;
export const GetCurrentAwarenessSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAwarenessSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreaIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'search' },
            name: { kind: 'Name', value: 'currentAwarenessSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'PracticeAreaIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceAreaIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'PostResourceTypes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceTypes' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CurrentAwareness' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postResourceType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentAwarenessSearchQuery,
  GetCurrentAwarenessSearchQueryVariables
>;
export const GetDailyNewsPracticeAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDailyNewsPracticeAreas' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulPracticeArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'StringValue',
                  value: '2IJw5gDFab7ZY5e7dNpLyL',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContentfulPracticeAreas' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserPracticeAreas' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulPracticeAreas' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulPracticeArea' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreaSubtopicsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentAwarenessCollection',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPracticeAreas' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPracticeAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedContentfulIds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDailyNewsPracticeAreasQuery,
  GetDailyNewsPracticeAreasQueryVariables
>;
export const GetPractitionerResourceSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerResourceSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'EnumValue',
                  value: 'PRACTITIONER_DOCUMENT_TYPE',
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'needsMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerResourceSearchQuery,
  GetPractitionerResourceSearchQueryVariables
>;
export const GetPractitionerSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'courts' },
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'EnumValue', value: 'PRACTITIONER_COURT' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'legalAreas' },
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'EnumValue', value: 'PRACTITIONER_LEGAL_TASK' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'practiceAreas' },
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'EnumValue',
                  value: 'PRACTITIONER_PRACTICE_AREA',
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchResult' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceTypes' },
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'EnumValue',
                  value: 'PRACTITIONER_DOCUMENT_TYPE',
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerSearchQuery,
  GetPractitionerSearchQueryVariables
>;
export const GetPractitionerSearchCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerSearchCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PRACTITIONER' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerSearchCountQuery,
  GetPractitionerSearchCountQueryVariables
>;
export const GetPractitionerSearchResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerSearchResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PRACTITIONER' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PractitionerSearchEdge',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snippets' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'position' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'contentfulId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'doctype' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'contentfulKnowHowDocument',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'practitionerDocumentType',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'practiceAreasCollection',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'items',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'slug',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerSearchResultsQuery,
  GetPractitionerSearchResultsQueryVariables
>;
export const GetProductCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductCollection' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulKnowHowProductCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceAreaCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductCollectionQuery,
  GetProductCollectionQueryVariables
>;
export const GetSecondarySourcesDocumentPartsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSecondarySourcesDocumentParts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refglobal' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesDocumentParts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'refglobal' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'refglobal' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentPartId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSecondarySourcesDocumentPartsQuery,
  GetSecondarySourcesDocumentPartsQueryVariables
>;
export const PublicationFiltersSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicationFiltersSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PUBLICATION' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'practiceAreas' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'PUBLICATION_PRACTICE_AREA',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'resourceTypes' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'PUBLICATION_RESOURCE_TYPE',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'publicationTitles' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'PUBLICATION_TITLE' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicationFiltersSearchQuery,
  PublicationFiltersSearchQueryVariables
>;
export const PublicationSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicationSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PUBLICATION' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PublicationSearchEdge' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicationSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicationSectionSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondarySourcesTitleParts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'title' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'practiceAreas' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'where' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'primary',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: '_eq',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'practiceArea',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleProductCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleOnlawId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicationSearchQuery,
  PublicationSearchQueryVariables
>;
export const RulesOfCourtSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RulesOfCourtSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'GENERIC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GenericSearchEdge' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GenericSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GenericSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Generic' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'secondarySourcesDocumentParts',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'documentTitle',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RulesOfCourtSearchQuery,
  RulesOfCourtSearchQueryVariables
>;
export const SearchCaseFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchCaseFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'citedCaseJudicataId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'published' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CasePublicationStatus' },
          },
          defaultValue: { kind: 'EnumValue', value: 'BOTH' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateGte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateLte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'CASE' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'casePublicationStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'published' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_gte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateGte' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateLte' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'court' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'CASE_COURT' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'filterValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'filterValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'causeOfAction' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_CAUSE_OF_ACTION',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'proceduralPosture' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_PROCEDURAL_POSTURE',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'disposition' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'CASE_DISPOSITION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'appellantTrialCourtRole' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_APPELLANT_TRIAL_COURT_ROLE',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchCaseFiltersQuery,
  SearchCaseFiltersQueryVariables
>;
export const StatuteSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StatuteSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchSortType' },
          },
          defaultValue: { kind: 'EnumValue', value: 'RELEVANCE' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'STATUTE' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StatuteSearchEdge' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatuteSearchEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StatuteSearchEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snippets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CodeSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeJudicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jurisdictionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CaConstitutionSection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeJudicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'article' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jurisdictionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatuteSearchQuery, StatuteSearchQueryVariables>;
export const StatutesFiltersSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StatutesFiltersSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'EnumValue', value: 'STATUTE_CALIFORNIA_CODE' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StatutesFiltersSearchQuery,
  StatutesFiltersSearchQueryVariables
>;
export const TrueDocsAndFormsSearchByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'trueDocsAndFormsSearchById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentfulIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trueDocsAndFormsSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentfulIds_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contentfulIds' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'IntValue', value: '1000' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TrueDocsAndForms' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TrueDocsAndForms' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLETrueDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrueDocsAndFormsSearchByIdQuery,
  TrueDocsAndFormsSearchByIdQueryVariables
>;
export const GetOrganizationTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRoles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationTypesQuery,
  GetOrganizationTypesQueryVariables
>;
export const GetProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recoveryEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRole' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'needsEmailUpdate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'needsEmailUpdateReason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondaryPracticeAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserPracticeAreas' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySpecialty' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondarySpecialty' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cleComplianceDeadline' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'complianceGroupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodEndDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportingDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complianceGroupOverrideId' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPracticeAreas' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPracticeAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedContentfulIds' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileQuery, GetProfileQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsConditionsLastAcceptance' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isFirmAdmin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isBillingAccessOnlyFirmAdmin' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'engagementLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productTypesPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productPracticeAreasPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPracticeAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'needsEmailUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsEmailUpdateReason' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complianceGroupId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'complianceGroupOverrideId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const InitiatePasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiatePasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiatePasswordReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiatePasswordResetMutation,
  InitiatePasswordResetMutationVariables
>;
export const TermsAndConditionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TermsAndConditions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulSimplePage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'StringValue',
                  value: 'eAhrKkNg1vwZGacjAbRjW',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'json' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TermsAndConditionsQuery,
  TermsAndConditionsQueryVariables
>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recoveryEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'barNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreas' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'complianceGroupOverrideId' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ComplianceGroupOverrideId' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'primarySpecialty' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSpecialty' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondarySpecialty' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserSpecialty' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recoveryEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recoveryEmail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'barNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'barNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRole' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userRole' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'complianceGroupOverrideId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'complianceGroupOverrideId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'primarySpecialty' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'primarySpecialty' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondarySpecialty' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'secondarySpecialty' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRole' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySpecialty' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondarySpecialty' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complianceGroupOverrideId' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSecondaryPracticeAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selected' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'practiceAreas' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const CancelFirmInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelFirmInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFirmInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelFirmInviteMutation,
  CancelFirmInviteMutationVariables
>;
export const GetFirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFirm' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Firm' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFirmAdmin' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FirmInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteeEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInvitations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Firm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pendingOnly' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Firm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Firm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmUser' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FirmInvitations' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFirmQuery, GetFirmQueryVariables>;
export const GetFirmInvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFirmInvoices' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'balance' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'invoiceNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'terms' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentSchedules' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFirmInvoicesQuery,
  GetFirmInvoicesQueryVariables
>;
export const GetFirmSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFirmSubscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'statuses' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'ACTIVE' },
                          { kind: 'EnumValue', value: 'PROVISIONING' },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionInterval' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activatedTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextPeriodStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'providesCLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'providesLegalResearch' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isRecurring' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFirmSubscriptionsQuery,
  GetFirmSubscriptionsQueryVariables
>;
export const GetFirmUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFirmUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFirmAdmin' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pendingOnly' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteeFirstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteeLastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteeEmail' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFirmUsersQuery, GetFirmUsersQueryVariables>;
export const ResendFirmInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendFirmInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendFirmInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendFirmInviteMutation,
  ResendFirmInviteMutationVariables
>;
export const AddFirmAdministratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addFirmAdministrator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFirmAdministrator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFirmAdministratorMutation,
  AddFirmAdministratorMutationVariables
>;
export const RemoveFirmAdministratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeFirmAdministrator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFirmAdministrator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveFirmAdministratorMutation,
  RemoveFirmAdministratorMutationVariables
>;
export const RemoveUserFromFirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserFromFirm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromFirmMutation,
  RemoveUserFromFirmMutationVariables
>;
export const GetCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'judicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'case' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromQuery' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromQuery' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cite' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentAttitudeName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'treatmentDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'citationCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastCitedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exampleTreatingCase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'treatmentDescription' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchHighlightTerms' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'californiaCitationFormat' },
                  name: { kind: 'Name', value: 'citationTemplate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'format' },
                      value: { kind: 'EnumValue', value: 'CALIFORNIA' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'format' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'standardCitationFormat' },
                  name: { kind: 'Name', value: 'citationTemplate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'format' },
                      value: { kind: 'EnumValue', value: 'STANDARD' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'format' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCaseQuery, GetCaseQueryVariables>;
export const GetSearchCaseCitationFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSearchCaseCitationFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'citedCaseJudicataId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'citedCodeSectionJudicataId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideCaseAndLevel' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'textFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'published' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CasePublicationStatus' },
          },
          defaultValue: { kind: 'EnumValue', value: 'BOTH' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateGte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseDateLte' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Year' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'CASE' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'citedCaseJudicataId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'citedCodeSectionJudicataId',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'citedCodeSectionJudicataId',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'casePublicationStatus' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'published' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'textFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'textFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_gte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateGte' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseDateLte' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'caseTreatment' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'CASE_TREATMENT' },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'hideCaseAndLevel' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'levelOfAnalysis' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_LEVEL_OF_ANALYSIS',
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'hideCaseAndLevel' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'court' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'CASE_COURT' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'filterValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'filterValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'causeOfAction' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_CAUSE_OF_ACTION',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'proceduralPosture' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_PROCEDURAL_POSTURE',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'disposition' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'EnumValue', value: 'CASE_DISPOSITION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'appellantTrialCourtRole' },
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'CASE_APPELLANT_TRIAL_COURT_ROLE',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsMore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSearchCaseCitationFiltersQuery,
  GetSearchCaseCitationFiltersQueryVariables
>;
export const GetClimateBriefImageAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClimateBriefImageAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assetIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulAssetCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sys' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'assetIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'url' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'transform' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'width' },
                                  value: { kind: 'IntValue', value: '680' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resizeStrategy',
                                  },
                                  value: { kind: 'EnumValue', value: 'FILL' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'quality' },
                                  value: { kind: 'IntValue', value: '90' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'EnumValue', value: 'JPG' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClimateBriefImageAssetsQuery,
  GetClimateBriefImageAssetsQueryVariables
>;
export const GetClimateBriefPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClimateBriefPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContentfulDateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'post' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postResourceType_in' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'StringValue',
                            value: 'ClimateBrief',
                            block: false,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'recentClimateBriefPosts' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '8' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'originalPublishDate_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postResourceType_in' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'StringValue',
                            value: 'ClimateBrief',
                            block: false,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RelatedContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulContributor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulContributor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'webDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortBio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'FACE' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webDisplayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContentfulContributor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '680' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '511' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'CENTER' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImageCaption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreasCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedContentCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RelatedContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClimateBriefPostQuery,
  GetClimateBriefPostQueryVariables
>;
export const GetClimateBriefSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClimateBriefSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageSize' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludedPostIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'featuredPosts' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'search' },
            name: { kind: 'Name', value: 'currentAwarenessSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'TopicIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'topicIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'PostResourceTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'StringValue',
                            value: 'ClimateBrief',
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentfulIds_not_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludedPostIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'FeaturedPost' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'featuredPosts' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageSize' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentfulId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featureImageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalPublishDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headshotUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'webDisplayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClimateBriefSearchQuery,
  GetClimateBriefSearchQueryVariables
>;
export const GetCleCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCleCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateRecorded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panoptoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCredits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsFileName' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRequirementsCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'speakers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliancePackageProductCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCleCourseQuery, GetCleCourseQueryVariables>;
export const GetCourseWatchlistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCourseWatchlist' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseWatchlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulCourseId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateRecorded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panoptoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCredits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsFileName' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRequirementsCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'speakers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliancePackageProductCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCourseWatchlistQuery,
  GetCourseWatchlistQueryVariables
>;
export const GetLocalRulesDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocalRulesDocuments' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_AL',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_CC',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_FR',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_KR',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_LA',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_OR',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_RI',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SAC',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SB',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SBA',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SC',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SD',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SF',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SJ',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SM',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SOL',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_SON',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_ST',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_TU',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'LRC_VE',
                                  block: false,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentTitle' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentTitle' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLocalRulesDocumentsQuery,
  GetLocalRulesDocumentsQueryVariables
>;
export const GetRulesTableOfContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRulesTableOfContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'documentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: 'CRC', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'documentId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tableOfContents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'path' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RuleDocumentParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RuleDocumentParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesDocumentParts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentPartId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChildren' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRulesTableOfContentsQuery,
  GetRulesTableOfContentsQueryVariables
>;
export const GetRulesTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRulesTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'documentPartId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesDocumentParts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentPartId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'documentPartId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rootParent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rootParentChildren' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'path' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RuleDocumentParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RuleDocumentParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesDocumentParts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentPartId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChildren' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRulesTitleQuery, GetRulesTitleQueryVariables>;
export const GetDocsAndFormsCollectionsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocsAndFormsCollectionsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreas' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageSize' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '100' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'search' },
            name: { kind: 'Name', value: 'trueDocsAndFormsCollectionsSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageSize' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'practiceAreaIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceAreas' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentfulId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceAreas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trueDocsAndForms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formNumber' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'docspringTemplateId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'documentTemplateUrl',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cebGuidance' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'practiceAreas' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'contentfulId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocsAndFormsCollectionsSearchQuery,
  GetDocsAndFormsCollectionsSearchQueryVariables
>;
export const GetDocsAndFormsCountyCourtsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocsAndFormsCountyCourts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulCourtCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'StringValue',
                        value: 'State Superior',
                        block: false,
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'name_ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '100' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'trueDocsAndFormsCollection',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'limit' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocsAndFormsCountyCourtsQuery,
  GetDocsAndFormsCountyCourtsQueryVariables
>;
export const GetDocsAndFormsPracticeAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocsAndFormsPracticeAreas' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulPracticeAreaCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sys' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'StringValue',
                                  value: '2yMXA0sBX9ZxrtqFzJclk6',
                                  block: false,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'name_ASC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'trueDocsAndFormsCollection',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'limit' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'practiceAreaSubtopicsCollection',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '30' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'name_ASC' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkedFrom' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'trueDocsAndFormsCollection',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'limit',
                                              },
                                              value: {
                                                kind: 'IntValue',
                                                value: '1',
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'total',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocsAndFormsPracticeAreasQuery,
  GetDocsAndFormsPracticeAreasQueryVariables
>;
export const GetDocsAndFormsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocsAndFormsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreas' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courtIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trueFormSubtypes' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrueFormSubtype' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageSize' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'search' },
            name: { kind: 'Name', value: 'trueDocsAndFormsSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageSize' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'practiceAreaIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceAreas' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'courtIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courtIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trueFormSubtypes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trueFormSubtypes' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentfulId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentfulId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'county' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mandatoryJcForm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trueFormSubtype' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceAreas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocsAndFormsSearchQuery,
  GetDocsAndFormsSearchQueryVariables
>;
export const CreateHistoryItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHistoryItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HistoryType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'action' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HistoryAction' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subtype' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHistoryItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'path' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'text' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'action' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subtype' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subtype' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHistoryItemMutation,
  CreateHistoryItemMutationVariables
>;
export const GetHistoryDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHistoryDates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'historyDates' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHistoryDatesQuery,
  GetHistoryDatesQueryVariables
>;
export const GetHistoryItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHistoryItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'historyItemsByDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHistoryItemsQuery,
  GetHistoryItemsQueryVariables
>;
export const UpdateHistoryItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateHistoryItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HistoryType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subtype' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHistoryItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'path' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'text' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subtype' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subtype' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHistoryItemMutation,
  UpdateHistoryItemMutationVariables
>;
export const GetPractitionerHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerHistory' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'historyItemsByType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PRACTITIONER' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'VIEW' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerHistoryQuery,
  GetPractitionerHistoryQueryVariables
>;
export const GetCourseProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCourseProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentfulCourseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseProgress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentfulCourseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contentfulCourseIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseProgress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseProgress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulCourseId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highestPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceCourseResultId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCourseProgressQuery,
  GetCourseProgressQueryVariables
>;
export const GetCoursesInProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCoursesInProgress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseProgress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CLECourse' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseProgress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulCourseId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highestPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceCourseResultId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLECourse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateRecorded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panoptoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCredits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsFileName' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMaterialsUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRequirementsCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentContentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'speakers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentfulId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webDisplayName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliancePackageProductCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCoursesInProgressQuery,
  GetCoursesInProgressQueryVariables
>;
export const GetLearningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLearning' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditsEarned' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearningProgress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLECourseCredit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CLECourseCredit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesforceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCreditHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditAreaHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditAreaName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specializationHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subcategory' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsEarned' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentPeriodCredits' },
            name: { kind: 'Name', value: 'cleCourseCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CLECourseCredit' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousPeriodsCredits' },
            name: { kind: 'Name', value: 'cleCourseCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compliancePeriod' },
                value: { kind: 'EnumValue', value: 'PREVIOUS' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CLECourseCredit' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearningProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditRequirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditsEarnedFromCeb' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleComplianceDeadline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'periodEndDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLearningQuery, GetLearningQueryVariables>;
export const GetUserCreditAreaSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserCreditAreaSummary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserCLECreditAreaSummary' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserCLECreditAreaSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primarySpecialty' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySpecialty' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleCreditAreaSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredCredits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCredits' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cleCreditSubArea' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredCredits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedCredits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completed' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cleSpecializationSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredCredits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCredits' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserCreditAreaSummaryQuery,
  GetUserCreditAreaSummaryQueryVariables
>;
export const GetUsersCourseProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersCourseProgress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseProgress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseProgress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseProgress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulCourseId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highestPlayheadInMs' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceCourseResultId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedPercentage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersCourseProgressQuery,
  GetUsersCourseProgressQueryVariables
>;
export const AddNewMatterServiceMatterToNewClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewMatterServiceMatterToNewClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientData' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceClients_obj_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceMatterTypes_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_matters_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientData' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewMatterServiceMatterToNewClientMutation,
  AddNewMatterServiceMatterToNewClientMutationVariables
>;
export const AddNewMatterServiceMatterToClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewMatterServiceMatterToClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceMatterTypes_enum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_matters_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMatterData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewMatterServiceMatterToClientMutation,
  AddNewMatterServiceMatterToClientMutationVariables
>;
export const DeleteMatterServiceMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceMatterMutation,
  DeleteMatterServiceMatterMutationVariables
>;
export const GetMatterServiceMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMatter' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingForName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includesMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doesNotIncludeMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherParentClaimant' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attorneyForPlaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'attorneyForForDefendantOrRespondent',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingAttorney' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opposingCounsel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'court' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'county' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressBlock' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MatterServiceEntityAddress',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defendantOrRespondent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingForPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minorChildren' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMarriage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domesticPartnership' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearsOfPartnership' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthsOfPartnership' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temporaryRestrainingOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'relationshipToProtectedPerson',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restrainedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protectedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceClient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceMatterQuery,
  GetMatterServiceMatterQueryVariables
>;
export const ListMattersByClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListMattersByClient' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterAndFolders',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServiceClient' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterAndFolders' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListMattersByClientQuery,
  ListMattersByClientQueryVariables
>;
export const ListMattersByRecentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListMattersByRecent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updatedAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterAndFolders',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServiceClient' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterAndFolders' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListMattersByRecentQuery,
  ListMattersByRecentQueryVariables
>;
export const MatterServiceDocumentFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatterServiceDocumentFolders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceMatterDocsAndFormsFolders',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceDocumentFoldersQuery,
  MatterServiceDocumentFoldersQueryVariables
>;
export const MatterServiceDuplicateItemsInMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MatterServiceDuplicateItemsInMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDuplicateItemsInMatter',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceDuplicateItemsInMatterMutation,
  MatterServiceDuplicateItemsInMatterMutationVariables
>;
export const MatterServiceGetDocOrFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatterServiceGetDocOrForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceMatterDocsAndForms_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndForm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDuplicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceGetDocOrFormQuery,
  MatterServiceGetDocOrFormQueryVariables
>;
export const MatterServiceMatterDocsAndFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderOrderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'MatterServiceMatterDocsAndFormsFolders_order_by',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemOrderBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'MatterServiceMatterDocsAndForms_order_by',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatterDocsAndForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'matterId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemOrderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndForm',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceMatterDocsAndFormsFolders',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'matterId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'folderOrderBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MatterServiceMatterDocsAndForm',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndForms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contentfulId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docspringTemplateId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDuplicate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceMatterDocsAndFormsQuery,
  MatterServiceMatterDocsAndFormsQueryVariables
>;
export const MatterServiceRemoveItemsFromMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MatterServiceRemoveItemsFromMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceRemoveItemsFromMatter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceRemoveItemsFromMatterMutation,
  MatterServiceRemoveItemsFromMatterMutationVariables
>;
export const MatterServiceRemoveMatterFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MatterServiceRemoveMatterFolders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'MatterServiceUuid' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_matterDocsAndFormsFolders',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'folderIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MatterServiceRemoveMatterFoldersMutation,
  MatterServiceRemoveMatterFoldersMutationVariables
>;
export const UpdateMatterCaseNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterCaseNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'caseNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'caseNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'caseNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterCaseNumberMutation,
  UpdateMatterCaseNumberMutationVariables
>;
export const UpdateMatterServiceKeepUpdatedWithMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceKeepUpdatedWithMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_matterDocsAndForms_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keepUpdatedWithMatter' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceKeepUpdatedWithMatterMutation,
  UpdateMatterServiceKeepUpdatedWithMatterMutationVariables
>;
export const UpdateMatterServiceMatterDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceMatterDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceMatterDescriptionMutation,
  UpdateMatterServiceMatterDescriptionMutationVariables
>;
export const UpdateMatterServiceMatterIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceMatterId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceMatterIdMutation,
  UpdateMatterServiceMatterIdMutationVariables
>;
export const UpdateMatterServiceMatterNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceMatterName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceMatterNameMutation,
  UpdateMatterServiceMatterNameMutationVariables
>;
export const UpdateMatterServiceOtherParentClaimantOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateMatterServiceOtherParentClaimantOnMatter',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'otherParentClaimant' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'otherParentClaimant' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'otherParentClaimant' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherParentClaimant' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceOtherParentClaimantOnMatterMutation,
  UpdateMatterServiceOtherParentClaimantOnMatterMutationVariables
>;
export const AddMatterServiceAttorneyAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceAttorneyAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityAddresses',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityAddresses_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'ATTORNEY' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'WORK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceAttorneyAddressMutation,
  AddMatterServiceAttorneyAddressMutationVariables
>;
export const AddMatterServiceAttorneyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceAttorneyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_entityEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityEmails_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'ATTORNEY' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'WORK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceAttorneyEmailMutation,
  AddMatterServiceAttorneyEmailMutationVariables
>;
export const AddMatterServiceAttorneyFaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceAttorneyFax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityPhoneNumbers_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'ATTORNEY' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'FAX' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceAttorneyFaxMutation,
  AddMatterServiceAttorneyFaxMutationVariables
>;
export const AddMatterServiceAttorneyPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceAttorneyPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityPhoneNumbers_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'ATTORNEY' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'WORK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceAttorneyPhoneNumberMutation,
  AddMatterServiceAttorneyPhoneNumberMutationVariables
>;
export const ClearMatterServiceAttorneyRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearMatterServiceAttorneyRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterAttorneyRoleTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyRole' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyRole' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearMatterServiceAttorneyRoleMutation,
  ClearMatterServiceAttorneyRoleMutationVariables
>;
export const CreateMatterServiceAttorneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServiceAttorney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suffix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'barNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firmName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workPhone' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityPhoneNumbers_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workEmail' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityEmails_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workAddress' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityAddresses_arr_rel_insert_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_attorneys_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'middleName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suffix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'barNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'barNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firmName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firmName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workPhone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emails' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workEmail' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addresses' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workAddress' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceAttorneyMutation,
  CreateMatterServiceAttorneyMutationVariables
>;
export const CreateMatterServiceAttorneyOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServiceAttorneyOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterAttorneyRoleTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suffix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'barNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firmName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workPhone' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityPhoneNumbers_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workEmail' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityEmails_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workAddress' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityAddresses_arr_rel_insert_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_attorneys_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'middleName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suffix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'barNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'barNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firmName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firmName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterEntities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'data' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'matterId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'matterId' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'type' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'ATTORNEY',
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'attorneyRole' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'attorneyRole',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workPhone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emails' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workEmail' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addresses' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workAddress' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceAttorneyOnMatterMutation,
  CreateMatterServiceAttorneyOnMatterMutationVariables
>;
export const DeleteMatterServiceAttorneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceAttorney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_attorneys_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceAttorneyMutation,
  DeleteMatterServiceAttorneyMutationVariables
>;
export const DeleteMatterServiceAttorneyAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceAttorneyAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityAddresses',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceAttorneyAddressMutation,
  DeleteMatterServiceAttorneyAddressMutationVariables
>;
export const DeleteMatterServiceAttorneyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceAttorneyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_entityEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceAttorneyEmailMutation,
  DeleteMatterServiceAttorneyEmailMutationVariables
>;
export const DeleteMatterServiceAttorneyFaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceAttorneyFax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceAttorneyFaxMutation,
  DeleteMatterServiceAttorneyFaxMutationVariables
>;
export const DeleteMatterServiceAttorneyPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceAttorneyPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'attorneyId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'ATTORNEY' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceAttorneyPhoneNumberMutation,
  DeleteMatterServiceAttorneyPhoneNumberMutationVariables
>;
export const GetMatterServiceAttorneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceAttorney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceAttorneys_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterAttorneyData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterAttorneyData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddressLineTwo' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceAttorneyQuery,
  GetMatterServiceAttorneyQueryVariables
>;
export const GetMatterServiceAttorneysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceAttorneys' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceAttorneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceAttorneysQuery,
  GetMatterServiceAttorneysQueryVariables
>;
export const GetMatterServiceFirmAttorneysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceFirmAttorneys' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceAttorneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceFirmAttorneysQuery,
  GetMatterServiceFirmAttorneysQueryVariables
>;
export const GetMatterServiceOpposingAttorneysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceOpposingAttorneys' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceAttorneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_is_null' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceOpposingAttorneysQuery,
  GetMatterServiceOpposingAttorneysQueryVariables
>;
export const UpdateMatterServiceAttorneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suffix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firmName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_attorneys_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'middleName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suffix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firmName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firmName' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyMutation,
  UpdateMatterServiceAttorneyMutationVariables
>;
export const UpdateMatterServiceAttorneyAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorneyAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityAddresses_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyAddressMutation,
  UpdateMatterServiceAttorneyAddressMutationVariables
>;
export const UpdateMatterServiceAttorneyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorneyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityEmails_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyEmailMutation,
  UpdateMatterServiceAttorneyEmailMutationVariables
>;
export const UpdateMatterServiceAttorneyFaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorneyFax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityPhoneNumbers_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyFaxMutation,
  UpdateMatterServiceAttorneyFaxMutationVariables
>;
export const UpdateMatterServiceAttorneyPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorneyPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityPhoneNumbers_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyPhoneNumberMutation,
  UpdateMatterServiceAttorneyPhoneNumberMutationVariables
>;
export const UpdateMatterServiceAttorneyToRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceAttorneyToRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterAttorneyRoleTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attorneyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_matterEntities_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'ATTORNEY' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attorneyRole' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attorneyRole' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceAttorneyToRoleMutation,
  UpdateMatterServiceAttorneyToRoleMutationVariables
>;
export const AddNewMatterServiceClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewMatterServiceClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_clients_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNewMatterServiceClientMutation,
  AddNewMatterServiceClientMutationVariables
>;
export const DeleteMatterServiceClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_clients_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceClientMutation,
  DeleteMatterServiceClientMutationVariables
>;
export const GetMatterServiceClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceClients_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceClient' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServiceMatter' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatterDocsAndFormsFolders' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceAttorney' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceAttorneys' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmNameCapitalized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'barNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullNamePlusSBN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fax' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAX' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMatter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMatters' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caseNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingForName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includesMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doesNotIncludeMinorChildren' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'otherParentClaimant' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountLastEditorId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCreatorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceMatterDocsAndFormsFolder',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attorneyForPlaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'attorneyForForDefendantOrRespondent',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingAttorney' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opposingCounsel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceAttorney' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'court' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'county' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressBlock' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MatterServiceEntityAddress',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plaintiffOrPetitioner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defendantOrRespondent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingForPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minorChildren' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMarriage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domesticPartnership' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfRegistration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formattedDateOfSeparation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yearsOfPartnership' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthsOfPartnership' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temporaryRestrainingOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'relationshipToProtectedPerson',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restrainedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protectedPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceClient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceClientQuery,
  GetMatterServiceClientQueryVariables
>;
export const GetMatterServiceClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceClients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceClients' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceClientsQuery,
  GetMatterServiceClientsQueryVariables
>;
export const RenameMatterServiceClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameMatterServiceClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_clients_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RenameMatterServiceClientMutation,
  RenameMatterServiceClientMutationVariables
>;
export const AddMatterServiceCourtToMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceCourtToMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courtId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_matterEntities_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'courtId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courtId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'COURT' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'court' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branchName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceCourtToMatterMutation,
  AddMatterServiceCourtToMatterMutationVariables
>;
export const CreateMatterServiceCourtOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServiceCourtOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'county' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'branchName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'address' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityAddresses_obj_rel_insert_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_courts_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'county' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'county' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'branchName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'branchName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterEntities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'data' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'matterId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'matterId' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'type' },
                                  value: { kind: 'EnumValue', value: 'COURT' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceCourtOnMatterMutation,
  CreateMatterServiceCourtOnMatterMutationVariables
>;
export const DeleteMatterServiceCourtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceCourt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_courts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceCourtMutation,
  DeleteMatterServiceCourtMutationVariables
>;
export const DeleteMatterServiceCourtAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceCourtAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courtId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityAddresses',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'courtId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'courtId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'COURT' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceCourtAddressMutation,
  DeleteMatterServiceCourtAddressMutationVariables
>;
export const GetMatterServiceCourtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceCourt' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceCourts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'branchName' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterCourtData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterCourtData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceCourts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'county' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addressBlock' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceCourtQuery,
  GetMatterServiceCourtQueryVariables
>;
export const GetMatterServiceCourtsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceCourts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceCourts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'branchName' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterCourtData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterCourtData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceCourts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'county' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addressBlock' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceCourtsQuery,
  GetMatterServiceCourtsQueryVariables
>;
export const RemoveMatterServiceCourtFromMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMatterServiceCourtFromMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'COURT' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMatterServiceCourtFromMatterMutation,
  RemoveMatterServiceCourtFromMatterMutationVariables
>;
export const UpdateMatterServiceCourtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceCourt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'branchName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'county' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mailingAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_courts_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'branchName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'branchName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'county' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'county' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityAddresses_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'addressId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mailingAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mailingAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceCourtMutation,
  UpdateMatterServiceCourtMutationVariables
>;
export const CreateMatterServiceDomesticPartnershipOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CreateMatterServiceDomesticPartnershipOnMatter',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfRegistration' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfSeparation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_domesticPartnerships_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfRegistration' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfRegistration' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfSeparation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfSeparation' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceDomesticPartnershipOnMatterMutation,
  CreateMatterServiceDomesticPartnershipOnMatterMutationVariables
>;
export const DeleteMatterServiceDomesticPartnershipsOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'DeleteMatterServiceDomesticPartnershipsOnMatter',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_domesticPartnerships_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceDomesticPartnershipsOnMatterMutation,
  DeleteMatterServiceDomesticPartnershipsOnMatterMutationVariables
>;
export const UpdateMatterServiceDomesticPartnershipOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateMatterServiceDomesticPartnershipOnMatter',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfRegistration' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfSeparation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_domesticPartnerships_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfRegistration' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfRegistration' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfSeparation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfSeparation' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MatterServiceDomesticPartnership',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceDomesticPartnership' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceDomesticPartnerships' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateOfRegistration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfRegistration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsOfPartnership' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsOfPartnership' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceDomesticPartnershipOnMatterMutation,
  UpdateMatterServiceDomesticPartnershipOnMatterMutationVariables
>;
export const AddMatterServiceFolderItemToFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServiceFolderItemToFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceAddItemsToFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServiceFolderItemToFolderMutation,
  AddMatterServiceFolderItemToFolderMutationVariables
>;
export const CreateMatterServiceFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServiceFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceCreateFolderAddItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceFolderMutation,
  CreateMatterServiceFolderMutationVariables
>;
export const DuplicateMatterServiceFolderItemToFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateMatterServiceFolderItemToFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDuplicateItemsToFolder',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateMatterServiceFolderItemToFolderMutation,
  DuplicateMatterServiceFolderItemToFolderMutationVariables
>;
export const DuplicateMatterServiceFolderItemToNewFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'DuplicateMatterServiceFolderItemToNewFolder',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceJsonb' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceCreateFolderDuplicateItems',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateMatterServiceFolderItemToNewFolderMutation,
  DuplicateMatterServiceFolderItemToNewFolderMutationVariables
>;
export const MoveMatterServiceFolderItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveMatterServiceFolderItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPosition' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServicePositionItemInFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folderId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'newPosition' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newPosition' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'itemId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'itemId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MoveMatterServiceFolderItemMutation,
  MoveMatterServiceFolderItemMutationVariables
>;
export const RenameMatterServiceFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameMatterServiceFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_matterDocsAndFormsFolders_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'folderId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RenameMatterServiceFolderMutation,
  RenameMatterServiceFolderMutationVariables
>;
export const UpdateMatterServiceFormDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceFormData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceJsonb' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceMatterDocsAndFormsStatuses_enum',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDuplicate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_matterDocsAndForms_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'data' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isDuplicate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isDuplicate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceFormDataMutation,
  UpdateMatterServiceFormDataMutationVariables
>;
export const UpdateMatterServiceFormStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceFormStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceMatterDocsAndFormsStatuses_enum',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_matterDocsAndForms_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceFormStatusMutation,
  UpdateMatterServiceFormStatusMutationVariables
>;
export const CreateMatterServiceMarriageOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServiceMarriageOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfMarriage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfSeparation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_marriages_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfMarriage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfMarriage' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfSeparation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfSeparation' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServiceMarriageOnMatterMutation,
  CreateMatterServiceMarriageOnMatterMutationVariables
>;
export const DeleteMatterServiceMarriageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServiceMarriage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_marriages_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServiceMarriageMutation,
  DeleteMatterServiceMarriageMutationVariables
>;
export const GetMatterServiceMarriagesOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServiceMarriagesOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMarriages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMarriage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServiceMarriagesOnMatterQuery,
  GetMatterServiceMarriagesOnMatterQueryVariables
>;
export const UpdateMatterServiceMarriageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceMarriage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfMarriage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfSeparation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_marriages_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfMarriage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfMarriage' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfSeparation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfSeparation' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceMarriage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceMarriage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceMarriages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfSeparation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfMarriage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfSeparation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfMarriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthsOfMarriage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceMarriageMutation,
  UpdateMatterServiceMarriageMutationVariables
>;
export const RemoveMatterServicePartiesFromMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMatterServicePartiesFromMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filingFor' },
                      value: { kind: 'NullValue' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personRole' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'EnumValue',
                                  value: 'PLAINTIFF_OR_PETITIONER',
                                },
                                {
                                  kind: 'EnumValue',
                                  value: 'DEFENDANT_OR_RESPONDENT',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMatterServicePartiesFromMatterMutation,
  RemoveMatterServicePartiesFromMatterMutationVariables
>;
export const AddMatterServicePersonAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServicePersonAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityAddressTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityAddresses',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityAddresses_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'PERSON' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServicePersonAddressMutation,
  AddMatterServicePersonAddressMutationVariables
>;
export const AddMatterServicePersonEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServicePersonEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityEmailTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_entityEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityEmails_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'PERSON' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServicePersonEmailMutation,
  AddMatterServicePersonEmailMutationVariables
>;
export const AddMatterServicePersonPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServicePersonPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityPhoneNumberTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_entityPhoneNumbers_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: { kind: 'EnumValue', value: 'PERSON' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServicePersonPhoneNumberMutation,
  AddMatterServicePersonPhoneNumberMutationVariables
>;
export const AddMatterServicePersonToRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMatterServicePersonToRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterPersonRoleTypes_enum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_matterEntities_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'PERSON' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personRole' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personRole' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddMatterServicePersonToRoleMutation,
  AddMatterServicePersonToRoleMutationVariables
>;
export const ClearMatterServiceAllPersonRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearMatterServiceAllPersonRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearMatterServiceAllPersonRolesMutation,
  ClearMatterServiceAllPersonRolesMutationVariables
>;
export const ClearMatterServicePersonRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearMatterServicePersonRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterPersonRoleTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personRole' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personRole' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearMatterServicePersonRoleMutation,
  ClearMatterServicePersonRoleMutationVariables
>;
export const ClearMatterServicePersonRoleForPersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearMatterServicePersonRoleForPerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personRole' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceMatterPersonRoleTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_matterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personRole' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personRole' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearMatterServicePersonRoleForPersonMutation,
  ClearMatterServicePersonRoleForPersonMutationVariables
>;
export const CreateMatterServicePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMatterServicePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personRole' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceMatterPersonRoleTypes_enum',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suffix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nickname' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'formerName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServicePeopleGenders_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'estimatedAge' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driversLicense' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'height' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'weight' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hairColor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eyeColor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'race' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'occupation' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employerName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumbers' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityPhoneNumbers_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emails' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityEmails_arr_rel_insert_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addresses' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceEntityAddresses_arr_rel_insert_input',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceInsert_people_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'middleName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suffix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'formerName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'formerName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'nickname' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfBirth' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfBirth' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'estimatedAge' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'estimatedAge' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'socialSecurityNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'socialSecurityNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'driversLicense' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driversLicense' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'height' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'weight' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hairColor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hairColor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'eyeColor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eyeColor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'race' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'race' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'occupation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'occupation' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employerName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'employerName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterEntities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'data' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'matterId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'matterId' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'type' },
                                  value: { kind: 'EnumValue', value: 'PERSON' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'personRole' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'personRole' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emails' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emails' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addresses' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'addresses' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMatterServicePersonMutation,
  CreateMatterServicePersonMutationVariables
>;
export const DeleteMatterServicePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServicePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_people_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServicePersonMutation,
  DeleteMatterServicePersonMutationVariables
>;
export const DeleteMatterServicePersonAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServicePersonAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityAddressTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityAddresses',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServicePersonAddressMutation,
  DeleteMatterServicePersonAddressMutationVariables
>;
export const DeleteMatterServicePersonEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServicePersonEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityEmailTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceDelete_entityEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServicePersonEmailMutation,
  DeleteMatterServicePersonEmailMutationVariables
>;
export const DeleteMatterServicePersonPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMatterServicePersonPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MatterServiceEntityPhoneNumberTypes_enum',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_entityPhoneNumbers',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'type' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMatterServicePersonPhoneNumberMutation,
  DeleteMatterServicePersonPhoneNumberMutationVariables
>;
export const GetMatterServicePeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServicePeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServicePeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'clientId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServicePeopleQuery,
  GetMatterServicePeopleQueryVariables
>;
export const GetMatterServicePeopleForMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServicePeopleForMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plaintiffOrPetitioner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defendantOrRespondent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServicePeopleForMatterQuery,
  GetMatterServicePeopleForMatterQueryVariables
>;
export const GetMatterServicePeopleWithRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServicePeopleWithRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personRole' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'person' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MatterServicePerson' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServicePeopleWithRolesQuery,
  GetMatterServicePeopleWithRolesQueryVariables
>;
export const GetMatterServicePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServicePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServicePeople_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServicePerson' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServiceEntityAddresses' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streetAddressBothLines' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityAndZipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAddressSingleLine' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatterServicePerson' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MatterServicePeople' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'race' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedGender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFemale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNonBinary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formattedDateOfBirth' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedAge' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'driversLicense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hairColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eyeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employerName' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'homeAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'personalEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSONAL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MatterServiceEntityAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workEmail' },
            name: { kind: 'Name', value: 'emails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'WORK' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServicePersonQuery,
  GetMatterServicePersonQueryVariables
>;
export const GetMatterServicePersonRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatterServicePersonRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceMatterEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'matterId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'personId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'PERSON' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personRole' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatterServicePersonRolesQuery,
  GetMatterServicePersonRolesQueryVariables
>;
export const UpdateMatterServiceFilingForDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServiceFilingFor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filingFor' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'MatterServiceMatterFilingForTypes_enum',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_matters_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filingFor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filingFor' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filingFor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServiceFilingForMutation,
  UpdateMatterServiceFilingForMutationVariables
>;
export const UpdateMatterServicePersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServicePerson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suffix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nickname' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'formerName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServicePeopleGenders_enum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'estimatedAge' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'socialSecurityNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driversLicense' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'height' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'weight' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hairColor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eyeColor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'race' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'occupation' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employerName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matterServiceUpdate_people_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'middleName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suffix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'formerName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'formerName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'nickname' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dateOfBirth' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateOfBirth' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'estimatedAge' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'estimatedAge' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'socialSecurityNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'socialSecurityNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'driversLicense' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driversLicense' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'height' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'weight' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hairColor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hairColor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'eyeColor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eyeColor' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'race' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'race' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'occupation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'occupation' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'employerName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'employerName' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServicePersonMutation,
  UpdateMatterServicePersonMutationVariables
>;
export const UpdateMatterServicePersonAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServicePersonAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddress' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'streetAddressLineTwo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityAddresses_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddress' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddress' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'streetAddressLineTwo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'city' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'state' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServicePersonAddressMutation,
  UpdateMatterServicePersonAddressMutationVariables
>;
export const UpdateMatterServicePersonEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServicePersonEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityEmails_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServicePersonEmailMutation,
  UpdateMatterServicePersonEmailMutationVariables
>;
export const UpdateMatterServicePersonPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMatterServicePersonPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_entityPhoneNumbers_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatterServicePersonPhoneNumberMutation,
  UpdateMatterServicePersonPhoneNumberMutationVariables
>;
export const CreateTemporaryRestrainingOrderOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTemporaryRestrainingOrderOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matterId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceInsert_temporaryRestrainingOrders_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'matterId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matterId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTemporaryRestrainingOrderOnMatterMutation,
  CreateTemporaryRestrainingOrderOnMatterMutationVariables
>;
export const DeleteTemporaryRestrainingOrdersOnMatterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTemporaryRestrainingOrdersOnMatter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceDelete_temporaryRestrainingOrders_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTemporaryRestrainingOrdersOnMatterMutation,
  DeleteTemporaryRestrainingOrdersOnMatterMutationVariables
>;
export const UpdateTemporaryRestrainingOrderProtectedPersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateTemporaryRestrainingOrderProtectedPerson',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'temporaryRestrainingOrderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceUuid' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_temporaryRestrainingOrders_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'temporaryRestrainingOrderId',
                        },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'protectedPersonId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTemporaryRestrainingOrderProtectedPersonMutation,
  UpdateTemporaryRestrainingOrderProtectedPersonMutationVariables
>;
export const UpdateTemporaryRestrainingOrderRelationshipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateTemporaryRestrainingOrderRelationship',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'temporaryRestrainingOrderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'relationshipToProtectedPerson' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_temporaryRestrainingOrders_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'temporaryRestrainingOrderId',
                        },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'relationshipToProtectedPerson',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'relationshipToProtectedPerson',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTemporaryRestrainingOrderRelationshipMutation,
  UpdateTemporaryRestrainingOrderRelationshipMutationVariables
>;
export const UpdateTemporaryRestrainingOrderRestrictedPersonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateTemporaryRestrainingOrderRestrictedPerson',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'temporaryRestrainingOrderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MatterServiceUuid' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personId' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MatterServiceUuid' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'matterServiceUpdate_temporaryRestrainingOrders_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'temporaryRestrainingOrderId',
                        },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restrainedPersonId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTemporaryRestrainingOrderRestrictedPersonMutation,
  UpdateTemporaryRestrainingOrderRestrictedPersonMutationVariables
>;
export const GetNextAndPreviousPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNextAndPreviousPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContentfulDateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContentfulDateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'nextPost' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'originalPublishDate_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_gt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postResourceType_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceTypes' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousPost' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'originalPublishDate_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postResourceType_in' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceTypes' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNextAndPreviousPostQuery,
  GetNextAndPreviousPostQueryVariables
>;
export const GetPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContentfulDateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'post' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'recentPosts' },
            name: {
              kind: 'Name',
              value: 'contentfulCurrentAwarenessCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '8' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'EnumValue', value: 'originalPublishDate_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'originalPublishDate_lte' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postResourceType_in' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'StringValue',
                            value: 'Law Alert',
                            block: false,
                          },
                          {
                            kind: 'StringValue',
                            value: 'In Practice',
                            block: false,
                          },
                          {
                            kind: 'StringValue',
                            value: 'Legal News',
                            block: false,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RelatedContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulContributor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulContributor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'webDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortBio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '56' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'FACE' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webDisplayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContentfulCurrentAwareness' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContentfulContributor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'json' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transform' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'IntValue', value: '680' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'IntValue', value: '511' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeStrategy' },
                            value: { kind: 'EnumValue', value: 'FILL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resizeFocus' },
                            value: { kind: 'EnumValue', value: 'CENTER' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'JPG' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'quality' },
                            value: { kind: 'IntValue', value: '90' },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredImageCaption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalPublishDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practiceAreasCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedContentCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RelatedContentfulCurrentAwareness',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostQuery, GetPostQueryVariables>;
export const GetContentfulPractitionerDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContentfulPractitionerDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentfulId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentfulEntryCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sys' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'contentfulId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ContentfulPractitionerWorkflow',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'practitionerDocumentType',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ContentfulKnowHowDocument',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'practitionerDocumentType',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContentfulPractitionerDocumentsQuery,
  GetContentfulPractitionerDocumentsQueryVariables
>;
export const GetPracticeAreaSubtopicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPracticeAreaSubtopic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceArea' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'practiceAreaSubtopic' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'practiceArea' },
            name: { kind: 'Name', value: 'contentfulPracticeAreaCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceArea' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'practiceAreaSubtopic' },
            name: { kind: 'Name', value: 'contentfulPracticeAreaCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practiceAreaSubtopic' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'knowHowDocumentCollection',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'limit' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'practiceAreaSubcategories',
                        },
                        name: {
                          kind: 'Name',
                          value: 'practiceAreaSubtopicsCollection',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '50' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkedFrom' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'knowHowDocumentCollection',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'limit',
                                              },
                                              value: {
                                                kind: 'IntValue',
                                                value: '1',
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'total',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPracticeAreaSubtopicQuery,
  GetPracticeAreaSubtopicQueryVariables
>;
export const GetPractitionerDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preview' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'contentfulKnowHowDocumentCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preview' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'preview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'practitionerDocumentType',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authorsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'webDisplayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalPublishDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedDate' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'practiceAreasCollection',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legalTasksCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'court' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'relatedContentCollection',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'practitionerDocumentType',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'processedContent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'processedWordDocument' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'processedWordDocumentNoNotes',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authorities' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerDocumentQuery,
  GetPractitionerDocumentQueryVariables
>;
export const GetPractitionerDocumentWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerDocumentWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '1' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'contentfulKnowHowDocumentCollection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedFrom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value:
                                  'practitionerWorkflowSectionItemsCollection',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'limit' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'linkedFrom',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'practitionerWorkflowSectionGroupCollection',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'limit',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'limit',
                                                      },
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'items',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'linkedFrom',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'practitionerWorkflowCollection',
                                                                  },
                                                                  arguments: [
                                                                    {
                                                                      kind: 'Argument',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'limit',
                                                                      },
                                                                      value: {
                                                                        kind: 'Variable',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value:
                                                                            'limit',
                                                                        },
                                                                      },
                                                                    },
                                                                  ],
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'items',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'slug',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerDocumentWorkflowQuery,
  GetPractitionerDocumentWorkflowQueryVariables
>;
export const GetPractitionerPracticeAreaSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPractitionerPracticeAreaSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'EnumValue',
                  value: 'PRACTITIONER_PRACTICE_AREA',
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categoryId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPractitionerPracticeAreaSearchQuery,
  GetPractitionerPracticeAreaSearchQueryVariables
>;
export const GetChapterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChapter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlawId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesTitleParts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlawId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'onlawId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SecondarySourcesTitleParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SecondarySourcesTitleParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesTitleParts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rootParent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rootParentChildren' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'path' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'californiaCitationTemplate',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'standardCitationTemplate',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasChildren' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetChapterQuery, GetChapterQueryVariables>;
export const GetPracticeAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPracticeArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesPracticeAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PracticeAreaWithTitles' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PracticeAreaWithTitles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SecondarySourcesPracticeAreas' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productTitle' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'title' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tableOfContents' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order_by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'path' },
                                  value: { kind: 'EnumValue', value: 'asc' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'active' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onlawId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPracticeAreaQuery,
  GetPracticeAreaQueryVariables
>;
export const GetSecondaryTitlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSecondaryTitles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesTitles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productCode' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productCode' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'practiceAreas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'primary' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceArea' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSecondaryTitlesQuery,
  GetSecondaryTitlesQueryVariables
>;
export const GetSecondarySourcesFavoritesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSecondarySourcesFavorites' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentFavorites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'SECONDARY_SOURCES_TITLE' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentItemId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSecondarySourcesFavoritesQuery,
  GetSecondarySourcesFavoritesQueryVariables
>;
export const AddSecondarySourcesFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSecondarySourcesFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addContentFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contentItemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'SECONDARY_SOURCES_TITLE' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentItemId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSecondarySourcesFavoriteMutation,
  AddSecondarySourcesFavoriteMutationVariables
>;
export const DeleteSecondarySourcesFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSecondarySourcesFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContentFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contentItemId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSecondarySourcesFavoriteMutation,
  DeleteSecondarySourcesFavoriteMutationVariables
>;
export const GetSecondarySourcesLandingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSecondarySourcesLanding' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesPracticeAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesTitles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productTitle' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tableOfContents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'path' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'practiceAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'practiceArea' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSecondarySourcesLandingQuery,
  GetSecondarySourcesLandingQueryVariables
>;
export const GetTableOfContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTableOfContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySourcesTitles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productCode' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productCode' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tableOfContents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'path' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlawId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasContent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasChildren' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTableOfContentsQuery,
  GetTableOfContentsQueryVariables
>;
export const GetCaConstitutionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCaConstitution' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'code' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: { kind: 'StringValue', value: 'ca', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: { kind: 'StringValue', value: 'const', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CAConstitution' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CAConstitution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specifier' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCaConstitutionQuery,
  GetCaConstitutionQueryVariables
>;
export const GetCaConstitutionHeadingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCaConstitutionHeading' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'article' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'code' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: { kind: 'StringValue', value: 'ca', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: { kind: 'StringValue', value: 'const', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CAConstitution' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caConstitutionHeading' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'article' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'article' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentTableOfContents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specifier' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'codeSections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'abbreviatedSectionTitle',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'history' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'judicataId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CAConstitution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specifier' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCaConstitutionHeadingQuery,
  GetCaConstitutionHeadingQueryVariables
>;
export const GetCaConstitutionSectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCaConstitutionSection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'article' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'judicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caConstitutionSection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'article' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'article' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'history' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'citationCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentTableOfContents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'judicataNumericId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchHighlightTerms' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCaConstitutionSectionQuery,
  GetCaConstitutionSectionQueryVariables
>;
export const GetCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeJudicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Statute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'code' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Code' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Statute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Code' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCodeQuery, GetCodeQueryVariables>;
export const GetCodeHeadingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCodeHeading' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeJudicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'judicataIdBegin' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'judicataIdEnd' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'code' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Code' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeSection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeJudicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataIdBegin' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CodeSection' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeHeading' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeJudicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataIdBegin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataIdBegin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataIdEnd' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataIdEnd' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CodeHeading' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Code' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeSection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CodeSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'history' } },
          { kind: 'Field', name: { kind: 'Name', value: 'citationCount' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'californiaCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'standardCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentTableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataNumericId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchHighlightTerms' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeHeading' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CodeHeading' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionBegin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'history' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCodeHeadingQuery, GetCodeHeadingQueryVariables>;
export const GetCodeReferenceCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCodeReferenceCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeJudicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PUBLICATION' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statuteReference' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegJurisdictionId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jurisdictionId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegJudicataId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'codeJudicataId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegSectionId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sectionId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'PUBLICATION_PRACTICE_AREA',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCodeReferenceCountQuery,
  GetCodeReferenceCountQueryVariables
>;
export const GetCodeReferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCodeReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeJudicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sectionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'PUBLICATION' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statuteReference' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegJurisdictionId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jurisdictionId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegJudicataId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'codeJudicataId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'citedStatuteRegSectionId',
                            },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sectionId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'optionFilters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilters' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchFilter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'category' },
                      value: {
                        kind: 'EnumValue',
                        value: 'PUBLICATION_PRACTICE_AREA',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PublicationSectionSearchEdge',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'breadcrumbs' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ordering' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'secondarySourcesTitleParts',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'onlawId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'onlawId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'productCode',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'productTitle',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                alias: {
                                                  kind: 'Name',
                                                  value: 'firstTitlePart',
                                                },
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tableOfContents',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'limit',
                                                    },
                                                    value: {
                                                      kind: 'IntValue',
                                                      value: '1',
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'where',
                                                    },
                                                    value: {
                                                      kind: 'ObjectValue',
                                                      fields: [
                                                        {
                                                          kind: 'ObjectField',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'depth',
                                                          },
                                                          value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                              {
                                                                kind: 'ObjectField',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value: '_eq',
                                                                },
                                                                value: {
                                                                  kind: 'IntValue',
                                                                  value: '1',
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'order_by',
                                                    },
                                                    value: {
                                                      kind: 'ObjectValue',
                                                      fields: [
                                                        {
                                                          kind: 'ObjectField',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'order',
                                                          },
                                                          value: {
                                                            kind: 'EnumValue',
                                                            value: 'asc',
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'onlawId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'practiceAreas',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'practiceArea',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'name',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'slug',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'primary',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCodeReferencesQuery,
  GetCodeReferencesQueryVariables
>;
export const GetCodeSectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCodeSection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeJudicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'judicataId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codeSection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeJudicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'judicataId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromQuery' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromQuery' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CodeSection' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Statute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'code' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'judicataId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeJudicataId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Code' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeSection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CodeSection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abbreviatedSectionTitle' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'history' } },
          { kind: 'Field', name: { kind: 'Name', value: 'citationCount' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'californiaCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'standardCitationFormat' },
            name: { kind: 'Name', value: 'citationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'format' },
                value: { kind: 'EnumValue', value: 'CALIFORNIA' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentTableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataNumericId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchHighlightTerms' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Statute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Code' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Code' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tableOfContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionBegin' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionEnd' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCodeSectionQuery, GetCodeSectionQueryVariables>;
export const GetStatuteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStatute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Statute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Statute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Statute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'codes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'judicataId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStatuteQuery, GetStatuteQueryVariables>;
