import React, { useMemo } from 'react';
import { useSuspenseQuery, useApolloClient } from '@apollo/client';
import { useRefetchOnRefocus } from 'hooks/useRefetchOnRefocus';
import { GetCourseProgressDocument } from '__generated__/graphql';

export function useCourseProgress(contentfulCourseIds, useCache) {
  const client = useApolloClient();

  const [isPending, startTransition] = React.useTransition();
  const { data } = useSuspenseQuery(GetCourseProgressDocument, {
    variables: {
      contentfulCourseIds: contentfulCourseIds,
    },
    ...(!useCache && { fetchPolicy: 'network-only' }),
  });

  useRefetchOnRefocus(
    () =>
      !isPending &&
      startTransition(() => {
        client.refetchQueries({
          include: [GetCourseProgressDocument],
        });
      }),
  );

  return useMemo(
    () => data && data.courseProgress && { ...data.courseProgress[0] },
    [data],
  );
}
