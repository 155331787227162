import React from 'react';
import {
  CLELibraryRequirements,
  StyledRequirementBubble,
} from '../landing/CLELibraryRequirements';
import pluralize from 'util/pluralize';
import groupBy from 'lodash/fp/groupBy';
import styled from 'styled-components';

export interface CLECourseRequirementsProps {
  totalCredits: any;
  specializationCredits: any;
  specialRequirementsCredits: any;
  className?: string;
}

export function CLECourseRequirements({
  totalCredits,
  specializationCredits,
  specialRequirementsCredits,
  className,
}: CLECourseRequirementsProps) {
  const specializationGroups = React.useMemo(
    () => Object.values(groupBy('parentContentfulId')(specializationCredits)),
    [specializationCredits],
  );
  return (
    <StyledCourseRequirements className={className}>
      <TotalCredits credits={totalCredits} />
      {specializationCredits.length > 0 && (
        <SpecializationCredits specializationGroups={specializationGroups} />
      )}
      {specialRequirementsCredits.length > 0 && (
        <SpecialRequirements requirements={specialRequirementsCredits} />
      )}
    </StyledCourseRequirements>
  );
}

const StyledCourseRequirements = styled.div`
  margin-bottom: 12px;
  font-family: 'BasisGrotesque', sans-serif;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'total specialization'
    'specialRequirements specialization';
  > div {
    &:first-child {
      grid-area: total;
    }
    &:nth-child(2),
    &:nth-child(2):last-child {
      grid-area: specialization;
    }
    &:last-child {
      grid-area: specialRequirements;
    }
  }

  h5 {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  h6 {
    font-size: 13px;
    font-size: 13px;
    font-weight: 900;
    margin-bottom: 0px;
    line-height: 1.1;
  }

  ul {
    list-style-type: none;
    li {
      margin: 5px 8px 11px 0;
      span {
        margin-bottom: 0;
      }
    }
  }
  ul li,
  .special-requirements div {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    font-weight: normal;
  }
  .special-requirements {
    div {
      display: block;
      white-space: normal;
      margin-bottom: 8px;
      span {
        margin-bottom: 0;
      }
    }
  }
`;

function SpecialRequirements({ requirements }: { requirements: any }) {
  return (
    <div className="special-requirements">
      <h5>Special Requirements</h5>
      <CLELibraryRequirements
        requirements={requirements}
        color="rgba(68, 143, 120, 0.78)"
      />
    </div>
  );
}

function SpecializationCredits({
  specializationGroups,
}: {
  specializationGroups: any;
}) {
  return (
    <div>
      <h5>LSCLE Specialization Credits</h5>
      {specializationGroups.map((group: any, i: number) => (
        <div key={`group-${i}`}>
          <h6>{group[0].parentName}</h6>
          <ul>
            {group.map((item: any, j: number) => (
              <li key={`item-${group[0].parentName}-${j}`}>
                <StyledRequirementBubble color="rgba(41, 54, 79, 0.65)">
                  {item.credits} {pluralize(item.credits, 'Hr')}
                </StyledRequirementBubble>{' '}
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

function TotalCredits({ credits }: { credits: any }) {
  return (
    <div>
      <h5>Total MCLE Credit Hours</h5>
      <StyledRequirementBubble>
        {credits} {pluralize(credits, 'Hour')}
      </StyledRequirementBubble>
    </div>
  );
}
