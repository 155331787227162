import React from 'react';

const WatchlistIcon = ({ fill = '#343434F' }: { fill?: string }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.286 5.036c0-.181-.14-.322-.322-.322h-.643c-.18 0-.321.14-.321.322V8.57H5.75c-.18 0-.321.141-.321.322v.643c0 .18.14.321.321.321h3.214c.181 0 .322-.14.322-.321v-4.5zm4.178 3.535A5.47 5.47 0 0 1 8 14.036 5.47 5.47 0 0 1 2.536 8.57 5.47 5.47 0 0 1 8 3.107a5.47 5.47 0 0 1 5.464 5.464zm2.25 0A7.716 7.716 0 0 0 8 .857 7.716 7.716 0 0 0 .286 8.571 7.716 7.716 0 0 0 8 16.286a7.716 7.716 0 0 0 7.714-7.715z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default WatchlistIcon;
