import React from 'react';
import styled, { css } from 'styled-components';
import { LayoutFooter } from 'features/layout/twentyfour/footer';
import {
  atom,
  atomFamily,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { PanelRetract } from './PanelIcon';
import { useInView } from 'react-intersection-observer';
import { LayoutContext } from 'components/layouts/LayoutContext';
import { PanelToggleButton } from './PanelToggleButton';

interface LayoutScrolledState {
  scrolled: boolean;
  headerHeight: number;
}

export const layoutScrolledStateAtom = atom<LayoutScrolledState>({
  key: 'LayoutScrolledState',
  default: { scrolled: false, headerHeight: 0 },
});

const { persistAtom } = recoilPersist();

export type PanelSidebarState = 'open' | 'closed';

export const PanelSidebarStateAtom = atomFamily<PanelSidebarState, string>({
  key: 'PanelSidebarState',
  default: 'open',
  effects_UNSTABLE: [persistAtom],
});

interface PanelLayoutProps {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  sidebarKey: string;
  sidebarWidth?: number;
  header?: React.ReactNode;
  collapsible?: boolean;
  className?: string;
  toggleOffset?: number;
}

export const PanelLayoutContext = React.createContext<{
  sidebarKey: string;
  sidebarWidth: number;
  collapsible: boolean;
  sidebarClosed: boolean;
  mainPanelRef: React.RefObject<HTMLElement>;
}>({
  sidebarKey: '',
  sidebarWidth: 360,
  collapsible: true,
  sidebarClosed: false,
  mainPanelRef: { current: null },
});

export function PanelLayout({
  children,
  sidebar,
  sidebarKey = '',
  sidebarWidth = 360,
  header,
  collapsible = true,
  className,
  toggleOffset = 55,
}: PanelLayoutProps) {
  const { layoutRef } = React.useContext(LayoutContext);
  const panelRef = React.useRef<HTMLDivElement>(null);
  const headerContainerRef = React.useRef<HTMLDivElement>(null);

  const [sidebarState, setSidebarState] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(
      PanelSidebarStateAtom(sidebarKey),
    );

  const handleButtonClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setSidebarState(sidebarState === 'open' ? 'closed' : 'open');
    },
    [sidebarState, setSidebarState],
  );

  const mainPanelRef = React.useRef<HTMLDivElement>(null);

  const { ref: scrolledDetectorRef, inView } = useInView({
    threshold: 0.05,
  });
  const setLayoutScrolled = useSetRecoilState(layoutScrolledStateAtom);

  // Update layoutScrolled state when inView changes
  React.useEffect(() => {
    setLayoutScrolled({
      scrolled: !inView,
      headerHeight:
        headerContainerRef.current?.getBoundingClientRect().height ?? 0,
    });
  }, [inView, setLayoutScrolled]);

  const sidebarClosed = collapsible && sidebarState === 'closed';

  const [$topOffset, setTopOffset] = React.useState(toggleOffset);
  const [panelTop, setPanelTop] = React.useState(0);

  const calculateTopOffset = React.useCallback(
    (el: HTMLElement | null) => {
      if (!el) return;
      const calculatedOffset = toggleOffset + panelTop;
      setTopOffset(calculatedOffset);
    },
    [toggleOffset, panelTop],
  );

  React.useEffect(() => {
    calculateTopOffset(mainPanelRef.current);
  }, [calculateTopOffset]);

  React.useEffect(() => {
    if (!mainPanelRef.current) return;
    const calculatedPanelTop =
      mainPanelRef.current.getBoundingClientRect().top +
      layoutRef.current.scrollTop;
    setPanelTop(calculatedPanelTop);
  });

  return (
    <PanelLayoutContext.Provider
      value={{
        sidebarKey,
        sidebarWidth,
        collapsible,
        sidebarClosed,
        mainPanelRef,
      }}
    >
      <div ref={panelRef}>
        <div ref={scrolledDetectorRef}></div>
        <div ref={headerContainerRef}>{header}</div>
        <StyledPanelLayout className={className || ''}>
          <StyledMainPanelContainer className="panel-main-container">
            <StyledMainPanel
              $sidebarClosed={sidebarClosed}
              $sidebarWidth={sidebarWidth}
              className="panel-main-panel"
              ref={mainPanelRef}
            >
              {children}
            </StyledMainPanel>
            <StyledLayoutFooter
              className="panel-layout-footer"
              $sidebarClosed={sidebarClosed}
            />
          </StyledMainPanelContainer>
          <StyledLayoutFooter
            className="panel-layout-footer"
            $sidebarClosed={sidebarClosed}
          />
          <StyledSidebarPanel
            $sidebarClosed={sidebarClosed}
            $sidebarWidth={sidebarWidth}
            className="panel-sidebar"
          >
            {sidebar}
            {collapsible && (
              <PanelToggleButton
                sidebarClosed={sidebarState === 'closed'}
                sidebarWidth={sidebarWidth}
                topOffset={$topOffset}
                handleButtonClick={handleButtonClick}
              />
            )}
          </StyledSidebarPanel>
        </StyledPanelLayout>
      </div>
    </PanelLayoutContext.Provider>
  );
}

const StyledPanelLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: 'panel-sidebar' 'panel-main' 'panel-footer';
  height: 100%;
  > .panel-layout-footer {
    display: flex;
    grid-area: panel-footer;
  }
  > .panel-sidebar {
    grid-area: panel-sidebar;
  }

  @media screen and (min-width: 1164px) {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'panel-sidebar' 'panel-main';
    > .panel-layout-footer {
      display: none;
    }
  }
`;

const StyledSidebarPanel = styled.div<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
}>`
  position: relative;
  @media print {
    display: none;
  }
  @media screen and (min-width: 1164px) {
    ${({ $sidebarClosed, $sidebarWidth }) =>
      $sidebarClosed
        ? css`
            width: 0;
            > *:first-child {
              display: none;
            }
          `
        : css`
            width: ${$sidebarWidth}px;
          `}
  }
`;

const StyledMainPanelContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
  min-height: 100%;
  .panel-layout-footer {
    display: none;
  }
  @media screen and (min-width: 1164px) {
    .panel-layout-footer {
      display: flex;
    }
  }
`;

const StyledMainPanel = styled.div<{
  $sidebarClosed: boolean;
  $sidebarWidth: number;
}>`
  background: white;
  padding: 0;
  border-top-left-radius: 8px;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  margin-bottom: 96px;
  ${({ $sidebarWidth, $sidebarClosed }) =>
    $sidebarClosed
      ? css`
          min-width: 960px;
        `
      : css`
          min-width: ${1164 - $sidebarWidth}px;
        `}
  ${({ $sidebarClosed }) =>
    $sidebarClosed &&
    css`
      @media screen and (min-width: 980px) {
        border-radius: 8px;
        margin: 0 auto 96px;
        max-width: 1080px;
      }
    `}
`;

const StyledLayoutFooter = styled(LayoutFooter)<{ $sidebarClosed: boolean }>`
  ${({ $sidebarClosed }) =>
    !$sidebarClosed &&
    css`
      @media screen and (min-width: 1164px) {
        border-top-left-radius: 8px;
        border: solid 1px var(--COLOR_QUILL_GRAY);
        border-right: none;
        border-bottom: none;
      }
    `}
  display: none;
`;

export const StyledMainPanelContent = styled.div`
  max-width: 1164px;
  box-sizing: border-box;
  padding: 28px 30px 48px;
`;
