import { ContentfulContent } from 'components/common/ContentfulContent';
import { FormattedTime } from 'components/common/FormattedTime';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import CLELibraryPageHeader from '../CLELibraryPageHeader';
import { usePracticeAreaRootSlugs } from '../usePracticeAreaRootSlugs';

import { CLECourseRequirements } from './CLECourseRequirements';
import { StyledPostContent } from 'components/posts/PostContent';
import qs from 'util/qs';
import TitledPage from 'components/common/TitledPage';
import { useCourseMetaData } from 'hooks/useMetaData';
import { useSuspenseQuery } from '@apollo/client';
import { GetCleCourseDocument } from '__generated__/graphql';

export default function CLELibraryCourse() {
  const { slug } = useParams();
  const {
    data: {
      course,
      course: {
        dateRecorded,
        title,
        totalCredits,
        longDescription,
        specializationCredits,
        specialRequirementsCredits,
        featureImageUrl,
        courseMaterialsFileName,
        speakers,
      },
    },
  } = useSuspenseQuery(GetCleCourseDocument, { variables: { slug } });

  const rootSlugs = usePracticeAreaRootSlugs();

  const practiceAreas = course.practiceAreas.filter(area =>
    rootSlugs.includes(area.slug),
  );
  const practiceAreaPathBuilder = React.useCallback(area => {
    return `/?${qs.stringify({
      practiceAreas: area.contentfulId,
    })}`;
  }, []);

  const pageMetaData = useCourseMetaData(course, 'CEB CLE Library');

  return (
    <div>
      <TitledPage
        title={pageMetaData.title}
        meta={pageMetaData.meta}
        link={pageMetaData.link}
        defaultTitle={pageMetaData.defaultTitle}
        titleTemplate={pageMetaData.titleTemplate}
      />
      <CLELibraryPageHeader withBackButton />
      <StyledCourse>
        <StyledCourseContent>
          <StyledRecordedDate>
            <i className="far fa-calendar" />
            Recorded&nbsp;
            <FormattedTime time={dateRecorded} flex />
          </StyledRecordedDate>
          <StyledH3>{title}</StyledH3>
          <CLECourseRequirements
            totalCredits={totalCredits}
            specializationCredits={specializationCredits}
            specialRequirementsCredits={specialRequirementsCredits}
          />
          <StyledH4>Course Description</StyledH4>
          <StyledSummary>
            <ContentfulContent document={longDescription} />
          </StyledSummary>
          <StyledH4>Practice Areas</StyledH4>
          <PracticeAreaTags
            practiceAreas={practiceAreas}
            pathBuilder={practiceAreaPathBuilder}
          />
        </StyledCourseContent>
        <StyledCourseSidebar>
          <img src={featureImageUrl} alt="" />
          <a className="button" href="https://www.ceb.com/pricing/mcle/">
            <i className="fas fa-list" />
            See Access Options
          </a>
          <StyledH4>Course Materials</StyledH4>
          <StyledCourseMaterials>
            <i className="far fa-file-pdf" />
            {courseMaterialsFileName}
          </StyledCourseMaterials>
          <StyledH4>Speakers</StyledH4>
          <StyledCourseSpeakers>
            {speakers.map(speaker => (
              <li key={speaker.webDisplayName}>{speaker.webDisplayName}</li>
            ))}
          </StyledCourseSpeakers>
          <StyledCourseClosedCaptions>
            <p>
              <i className="far fa-closed-captioning" />
              All CEB on-demand programs have human-generated captions available
              to meet or exceed ADA Section 508 requirements for accessibility.
            </p>
          </StyledCourseClosedCaptions>
        </StyledCourseSidebar>
      </StyledCourse>
    </div>
  );
}

const StyledRecordedDate = styled.div`
  color: #003475;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 14px;
  i {
    font-size: 14px;
    vertical-align: top;
    margin-right: 6px;
  }
`;

const StyledCourseClosedCaptions = styled.div`
  i {
    font-size: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  p {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    line-height: 1.71;
  }
`;

const StyledCourseSpeakers = styled.ul`
  margin-bottom: 24px;
  li {
    display: flex;
    align-items: center;
    height: 36px;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: 900;
  }
`;

const StyledCourseMaterials = styled.div`
  display: flex;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.33;
  margin-bottom: 24px;
  margin-top: 12px;
  i {
    font-size: 20px;
    margin-right: 12px;
  }
`;

const StyledCourse = styled.div`
  padding: 0 120px;
  margin-bottom: 96px;
  @media screen and (min-width: 1166px) {
    display: flex;
  }
`;

const StyledH3 = styled.h3`
  font-family: 'LyonDisplay';
  font-size: 46px;
  line-height: 1.17;
  margin-bottom: 32px;
`;

const StyledH4 = styled.h3`
  font-family: 'BasisGrotesque', san-serif;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const StyledSummary = styled(StyledPostContent)`
  margin-bottom: 36px;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  > p:first-child::first-letter {
    float: none;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  p {
    margin-bottom: 12px;
  }
  ul,
  ol {
    margin-bottom: 12px;
  }
`;

const StyledCourseContent = styled.div`
  flex: 1;
`;

const StyledCourseSidebar = styled.div`
  background-color: #f6fbff;
  padding: 22px 20px 20px 20px;
  margin-top: 20px;
  @media screen and (min-width: 1166px) {
    margin-left: 81px;
    margin-top: 0;
    max-width: 340px;
    min-width: 340px;
  }

  img {
    margin-bottom: 24px;
  }

  a.button {
    display: block;
    text-align: center;
  }
  button,
  a.button {
    background-color: #006df4;
    border-radius: 5px;
    padding: 10px 0 7px 0;
    color: white;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    width: 100%;
    i {
      margin-right: 0.5em;
      vertical-align: -1px;
    }
  }
`;
