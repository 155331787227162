import React from 'react';
import styled from 'styled-components';
import { CLECourseRequirements } from 'components/cle-library/course/CLECourseRequirements';
import { ContentfulContent } from 'components/common/ContentfulContent';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { usePracticeAreaRootSlugs } from 'components/cle-library/usePracticeAreaRootSlugs';
import { CourseSidebar } from './CourseSidebar';
import { EvaluateCourseButton, PlayCourseButton } from './PlayCourseButton';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { StyledPostContent } from 'components/posts/PostContent';
import TitledPage from 'components/common/TitledPage';
import { useCourseBreadcrumbs } from './useCourseBreadcrumbs';
import { useCourse } from './useCourse';
import { RecordedDate } from './RecordedDate';
import { useCoursePracticeAreaPathBuilder } from 'components/cle-library/landing/useCoursePracticeAreaPathBuilder';
import { AddToWatchlistButton } from '../watchlist/AddToWatchlistButton';
import { useCourseProgress } from './useCourseProgress';
import { LayoutFooter } from 'features/layout/twentyfour/footer';
import { BackNavigation } from './BackNavigation';

export default function LearningCLECourse({
  isPlayer = false,
}: {
  isPlayer?: boolean;
}) {
  const courseWithoutProgress = useCourse();
  const progress = useCourseProgress([courseWithoutProgress?.contentfulId]);

  const course = React.useMemo(() => {
    if (courseWithoutProgress && progress) {
      return { ...courseWithoutProgress, progress };
    } else {
      return courseWithoutProgress;
    }
  }, [courseWithoutProgress, progress]);

  const {
    title,
    totalCredits,
    longDescription,
    specializationCredits,
    specialRequirementsCredits,
  } = React.useMemo(() => {
    return course!;
  }, [course]);

  const rootSlugs = usePracticeAreaRootSlugs();

  const practiceAreas = React.useMemo(() => {
    return course?.practiceAreas!.filter(area =>
      rootSlugs.includes(area!.slug),
    );
  }, [course, rootSlugs]);

  useCourseBreadcrumbs(course);

  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder();

  return (
    <>
      <StyledWithRightSidebar>
        <TitledPage title={`CLE Course - ${title}`} />
        <StyledContent>
          <StyledToolbarHeader>
            <div>
              <BackNavigation to="/learning/courses">
                CLE Courses
              </BackNavigation>
            </div>
            <div>
              <AddToWatchlistButton course={course} />
            </div>
          </StyledToolbarHeader>
          <StyledContentDocument>
            <header>
              <StyledRecordedDate course={course} />
              <h3>{title}</h3>
              <CLECourseRequirements
                totalCredits={totalCredits}
                specializationCredits={specializationCredits}
                specialRequirementsCredits={specialRequirementsCredits}
              />
            </header>
            <section role="main">
              <h4>Course Description</h4>
              <StyledContentfulContent>
                <ContentfulContent document={longDescription} />
              </StyledContentfulContent>
            </section>
            <section>
              <h4>Practice Areas</h4>
              <PracticeAreaTags
                practiceAreas={practiceAreas}
                pathBuilder={practiceAreaPathBuilder}
              />
            </section>
            {!isPlayer && (
              <footer>
                <PlayCourseButton course={course} />
                <EvaluateCourseButton course={course} />
              </footer>
            )}
          </StyledContentDocument>
        </StyledContent>
        <CourseSidebar course={course!} isPlayer={isPlayer} />
      </StyledWithRightSidebar>
      <LayoutFooter />
    </>
  );
}

const StyledRecordedDate = styled(RecordedDate)`
  margin-bottom: 12px;
`;

const StyledToolbarHeader = styled.header`
  display: flex;
  div {
    flex: 0 1 auto;
    &:first-child {
      flex: 1 1 auto;
    }
  }
  button {
    text-align: center;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 12px;
    i {
      font-size: 16px;
    }
    span {
      margin-top: 5px;
      margin-left: 0;
      display: block;
    }
  }
`;

export const StyledContentfulContent = styled(StyledPostContent)`
  font-size: 17px;
  margin-bottom: 36px;
  line-height: 1.53;
  font-family: 'Zilla Slab', serif;
  > p:first-child::first-letter {
    float: none;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  p {
    margin-bottom: 12px;
  }
  ul,
  ol {
    margin-bottom: 12px;
  }
`;

const StyledContentDocument = styled.article`
  padding: 44px 42px;
  h3 {
    font-size: 32px;
    margin-bottom: 27px;
  }
  h4 {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  section {
    font-size: 17px;
    margin-bottom: 1.4em;
    line-height: 1.53;
  }
  footer {
    border-top: 1px solid ${CEB_COLOR_RGBA('BLACK', 0.1)};
    margin-top: 36px;
    padding-top: 36px;
    a {
      + a {
        margin-left: 1em;
      }
    }
  }
`;

const StyledContent = styled.div`
  padding: 23px 35px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.05);
`;

const StyledWithRightSidebar = styled.div`
  display: grid;
  grid-template-columns: 1fr 340px;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 96px auto;
  h3 {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 36px;
  }
`;
