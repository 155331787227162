import React from 'react';
import { Paginate } from 'components/common/paginate';
import { SearchResultsPageInfo } from 'components/common/search/SearchResultsPageInfo';
import styled from 'styled-components';
import { useResultsWithProgress } from './useResultsWithProgress';
import { useCourseSearch } from './useCourseSearch';
import { QueryInput } from 'components/common/query-input';
import { FormCheckbox } from 'components/common/json_form';
import { SearchContext } from 'components/layouts/research-layout/SearchContext';
import { GetUsersCourseProgressDocument } from '__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';

export function CLELibrarySearchResults({ userPracticeAreas, children }) {
  const [data, page, href] = useCourseSearch(userPracticeAreas);

  return (
    <StyledCourseList>
      <SearchResultsPageInfo
        data={data}
        type="COURSE"
        searchKey="courseSearch"
        perPage={10}
        resultTypeMessage="Course"
        color="#343434"
      />
      <ul>
        {data.courseSearch.edges.map((edge, index) => children(edge, index))}
      </ul>
      <Paginate
        href={href}
        current={page}
        pages={data?.courseSearch?.pageInfo?.totalPages || 0}
        range={10}
      />
    </StyledCourseList>
  );
}

const isCompletedCourse = progress =>
  progress.salesforceCourseResultId !== null;
const getCourseId = progress => progress.contentfulCourseId;

function useCompletedCourseIds() {
  const {
    data: { courseProgress },
  } = useSuspenseQuery(GetUsersCourseProgressDocument);

  return courseProgress.filter(isCompletedCourse).map(getCourseId);
}

export function CLELibrarySearchResultsWithProgress({
  userPracticeAreas,
  children,
}) {
  const completedCourseIds = useCompletedCourseIds();
  const {
    queryString: { completed },
  } = React.useContext(SearchContext);
  const contentfulIdFilter = React.useMemo(
    () => (completed === 'no' ? completedCourseIds : []),
    [completed, completedCourseIds],
  );
  const [data, page, href] = useCourseSearch(userPracticeAreas, {
    contentfulIds_not_in: contentfulIdFilter,
  });
  const results = useResultsWithProgress(data);
  const totalPages = React.useMemo(
    () => results?.pageInfo?.totalPages || 0,
    [results],
  );

  return (
    <StyledCourseList>
      <StyledCourseListHeader>
        <div>
          <h2>CLE Catalog</h2>
        </div>
        <div>
          <SearchResultsPageInfo
            data={data}
            type="COURSE"
            searchKey="courseSearch"
            perPage={10}
            resultTypeMessage="Course"
            color="#343434"
          >
            <QueryInput
              through={FormCheckbox}
              type="checkbox"
              name="completed"
              placeholder="Show Completed Courses"
              value="no"
              className="show-completed"
              mutate={() => ({ page: undefined })}
              invert
            />
          </SearchResultsPageInfo>
        </div>
      </StyledCourseListHeader>
      <ul>{results.edges.map((edge, index) => children(edge, index))}</ul>
      <Paginate href={href} current={page} pages={totalPages} range={10} />
    </StyledCourseList>
  );
}

const StyledCourseListHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 24px;
  > div {
    h2 {
      font-size: 26px;
    }
    h4 {
      margin-bottom: 0;
      label {
        padding-bottom: 0;
        margin-left: 10px;
      }
    }
  }
`;

export const StyledCourseList = styled.div`
  margin: 0 35px 24px;
  ul {
    list-style-type: none;
  }

  .show-completed {
    display: inline-block;
    margin-left: 10px;
  }
`;
