import React from 'react';
import styled from 'styled-components';
import { FormattedTime } from 'components/common/FormattedTime';

export interface RecordedDateProps {
  course: any;
  className?: string;
}

export function RecordedDate({ course, className }: RecordedDateProps) {
  return (
    <CourseDate
      date={course.dateRecorded}
      format="MMM D, YYYY"
      flex
      className={className}
    >
      Recorded
    </CourseDate>
  );
}

export interface CourseDateProps {
  date: string;
  format: string;
  flex: boolean;
  className?: string;
  children?: React.ReactNode;
}

export function CourseDate({
  date,
  children,
  format,
  flex,
  ...props
}: CourseDateProps) {
  return (
    <StyledRecordedDate {...props}>
      <i className="far fa-calendar" />
      {children}&nbsp;
      <FormattedTime time={date} utc={false} format={format} flex={flex} />
    </StyledRecordedDate>
  );
}
const StyledRecordedDate = styled.div`
  font-family: 'Zilla Slab', serif;
  font-size: 13px;
  i {
    font-size: 14px;
    vertical-align: top;
    margin-right: 6px;
  }
  time {
    font-weight: bold;
  }
`;
