import React from 'react';
import { isActive } from 'util/isActive';
import { LinkItem } from './LinkItem';
import { useLocation } from 'react-router-dom';
import uriStringify from 'util/uriStringify';

interface IsActiveExactProps {
  pathname: string;
  to: string;
}

const isActiveExact = ({ pathname, to }: IsActiveExactProps): boolean =>
  to === pathname.replace(/\/$/, '');

interface ActiveLinkItemProps {
  to: string;
  children: React.ReactNode;
  exact?: boolean;
  exclude?: string[];
  query?: Record<string, string>;
}

export const ActiveLinkItem: React.FC<ActiveLinkItemProps> = ({
  to,
  children,
  exact,
  exclude,
  query,
}) => {
  const { pathname } = useLocation();
  return (
    <LinkItem
      to={uriStringify(to, { query })}
      active={(exact ? isActiveExact : isActive)({ pathname, to, exclude })}
    >
      {children}
    </LinkItem>
  );
};
